import { locale } from 'devextreme/localization';
import nlMessages from '../../assets/localization/nl.json';
import enMessages from '../../assets/localization/en.json';

const Visi = (window as any).Visi || {
    Constants: {
        CORE: false,
        SESSION: '',
        TOKEN: '',
        VERSION: '',
        USER: null,
        PROJECTROLES: null,
        BASE_FOLDER: '',
        backendUrl: '',
        openLink: (el: any) => {},
        DownloadFile: null,
        renderNavigation: () => HTMLElement,
        PROJECT: {
            Id: null,
            Name: null,
            SharePointActive: null,
            ThinkProjectActive: null,
        },
    },
    Taal: {
        label: (w: string) => {
            const loc = locale();
            switch (true) {
                case loc.includes('nl'):
                    return nlMessages.nl[w];
                case loc.includes('en'):
                    return enMessages.en[w];
                default:
                    return nlMessages.nl[w];
            }
        },
    },
};

const Ext = (window as any).Ext || {
    String: null,
    getCmp: function (n: any) {
        return {
            maxWidth: 0,
            minWidth: 45,
            setWidth: (w: any) => {},
            createReact: function (p: any) {},
            getController: () => {
                return {
                    navigatieSelect: (i: any, n: any) => {},
                    showProjectPicker: () => {},
                };
            },
            close: () => {},
            removeReact: () => {},
        };
    },
    Msg: null,
};

export { Visi, Ext };
