import ReactDOM from 'react-dom/client';

/**
 * A function to unmount the react instance which was created in a new tab or by selecting another navigation item from the type 'transactionOverivew'
 * @param {string} htmlElementId The id of the HTML element to be closed
 * @return {void} void
 */
export const unmountReactTabInstance = (htmlElementId: string): void => {
    const elementToBeClosed = document.getElementById(htmlElementId);

    if (elementToBeClosed == null) return;

    const reactInstanceToBeClosed: ReactDOM.Root = elementToBeClosed['data-reactroot'];

    // This is needed here because React cannot finish unmounting the root until the current render has completed.
    setTimeout(() => reactInstanceToBeClosed.unmount(), 1000);
};
