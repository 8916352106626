import { TabItem, TabsContextProps } from '../context/TabsContext';

// Utils
import { unmountReactTabInstance } from './tabsReducer.utils';

interface reducerActionProps {
    type: string;
    payload: any;
}

export const tabsReducer = (state: TabsContextProps, action: reducerActionProps) => {
    let returnObject: TabsContextProps;
    let foundTab: TabItem;
    let initialTabId: string = '';

    const { payload, type } = action;

    switch (type) {
        case 'TABS':
            sessionStorage.setItem('tabsData', JSON.stringify({ ...state }));
            return {
                ...state,
            };
        case 'NAV_CHANGE_TAB_NAME':
            // Initial tab value is undifined, no react instance should be deleted yet.
            initialTabId = state.TabList[0].value;

            if (initialTabId != null && initialTabId !== payload.id) {
                unmountReactTabInstance(initialTabId);
            }

            state.TabList[0].text = payload.name;
            state.TabList[0].value = payload.id;

            returnObject = {
                ...state,
                TabList: [...state.TabList],
            };
            sessionStorage.setItem('tabsData', JSON.stringify({ ...returnObject }));
            return returnObject;
        case 'ADD_TAB':
            // kijk of hij al bestaat
            if (
                state?.TabList?.find((p) => {
                    return p.value === payload.value;
                }) == null
            ) {
                // bestaat niet, voeg hem toe aan de TabList
                returnObject = {
                    ...state,
                    SelectedTab: { ...payload } as TabItem,
                    TabList: [...state.TabList, { ...payload } as TabItem],
                };
            } else {
                returnObject = {
                    ...state,
                    SelectedTab: { ...payload } as TabItem,
                };
            }

            sessionStorage.setItem('tabsData', JSON.stringify({ ...returnObject }));
            return returnObject;
        case 'LINKID_TAB_OPENED':
            // kijk of hij al bestaat
            foundTab = state?.TabList.find((p) => {
                return p?.rootTransaction?.ID && p.isLinkIdOpened;
            });

            if (foundTab == null) return null;
            foundTab.isLinkIdOpened = false;
            state.SelectedTab.isLinkIdOpened = false;

            // bestaat niet, voeg hem toe aan de TabList
            returnObject = {
                ...state,
            };

            sessionStorage.setItem('tabsData', JSON.stringify({ ...returnObject }));
            return returnObject;
        case 'CHANGE_TAB':
            returnObject = {
                ...state,
                SelectedTab: { ...payload } as TabItem,
            };
            sessionStorage.setItem('tabsData', JSON.stringify({ ...returnObject }));
            return returnObject;
        case 'CLOSE_TAB':
            unmountReactTabInstance(payload);

            returnObject = {
                ...state,
                TabList: [...state?.TabList?.filter((p) => p?.value !== payload)],
            };

            sessionStorage.setItem('tabsData', JSON.stringify({ ...returnObject }));
            return returnObject;
        case 'SET_MESSAGE_CONTENT':
            foundTab = state.TabList.find((p) => p.value === payload.tabHolder?.value);
            if (foundTab != null) {
                foundTab.messageContent = payload?.messageContent;
                state.SelectedTab.messageContent = payload?.messageContent;
            }

            returnObject = {
                ...state,
            };

            sessionStorage.setItem('tabsData', JSON.stringify({ ...returnObject }));
            return returnObject;
        case 'RESET_TABS':
            returnObject = {
                ...state,
                TabList: [{ ...state.TabList[0], name: null }],
            };
            sessionStorage.setItem('tabsData', JSON.stringify({ ...returnObject }));
            return returnObject;
        case 'Update_TAB_TOTAL':
            foundTab = state.TabList.find((p) => p.value === payload.value);
            if (foundTab != null) {
                foundTab.totalCount = payload?.totalCount;
                state.SelectedTab = { ...state.SelectedTab, totalCount: payload?.totalCount };
            }

            returnObject = {
                ...state,
            };
            sessionStorage.setItem('tabsData', JSON.stringify({ ...returnObject }));
            return returnObject;
        default:
            return state;
    }
};
