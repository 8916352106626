export const setLocalStorage = (setting: string, value: any) => {
    value = JSON.stringify(value);
    localStorage.setItem(setting, value);
};

export const getLocalStorage = (setting: string) => {
    let value = localStorage.getItem(setting);
    value = JSON.parse(value);
    return value;
};
