import React, { createContext } from 'react';

// REFERENCES
import { MessageLink } from '../../pages/Login/OpenMessageFromEmailLink';
import { ContextMenuItem } from '../../panels/TransactionOverview/TransactionContextMenu/contextMenuItems';
import { SharepointObject } from '../../utils/Service/ServiceReference/reference';
import { ThinkprojectObject } from '../../pages/Settings/thinkproject.reference';

interface Role {
    ID: string;
    Name: string;
    Organisation: string;
}

export interface UserInfoProps {
    Applicatiebeheerder: boolean;
    CanStartTransaction: boolean;
    Crm: boolean;
    DateFormat: string;
    DetailedView: boolean;
    Email: string;
    EmailNotification: false;
    InlogName: string;
    Name: string;
    Project: string;
    ProjectID: number;
    Rols: Role[];
    SharePointConfig?: SharepointObject;
    ThinkProjectConfig?: ThinkprojectObject;
}

export interface AuthContextProps {
    AllowMultipleTransactionExpand: boolean;
    CopyFields: object;
    DefaultMessage?: MessageLink;
    DisplayComplexElementHeaders: boolean;
    ExportToExcelVisible: boolean;
    HasCopiedMessage: boolean;
    InterfaceLanguage: string;
    IsLoading: boolean;
    IsLoggedIn: boolean;
    LastOpenedProjectID: number;
    LoginName: string;
    ModifyPassword: boolean;
    NumberOfProjects: number;
    OpenedProject: UserInfoProps;
    OpenLastProject: boolean;
    PersonalFolders: ContextMenuItem[];
    ReportPagination: boolean;
    SelectedTransaction: number | null;
    SessionId: string | null;
    SharePointActive: boolean | null;
    SimpleView: boolean | null;
    ThinkProjectActive: boolean | null;
    TokenID: string | null;
    TransactionMultiSelect: boolean;
    Username: string;
}

const sessionStorageData = JSON.parse(sessionStorage.getItem('userData'));

export const INITIAL_AUTH_STATE: AuthContextProps = {
    AllowMultipleTransactionExpand: sessionStorageData ? sessionStorageData?.AllowMultipleTransactionExpand : false,
    CopyFields: sessionStorageData ? sessionStorageData?.CopyFields : {},
    DefaultMessage: sessionStorageData ? sessionStorageData?.DefaultMessage : null,
    DisplayComplexElementHeaders: sessionStorageData ? sessionStorageData?.DisplayComplexElementHeaders : true,
    ExportToExcelVisible: sessionStorageData ? sessionStorageData?.ExportToExcelVisible : false,
    HasCopiedMessage: sessionStorageData ? sessionStorageData?.HasCopiedMessage : false,
    InterfaceLanguage: sessionStorageData ? sessionStorageData?.InterfaceLanguage : null,
    IsLoading: false,
    IsLoggedIn: sessionStorageData ? sessionStorageData?.IsLoggedIn : false,
    LastOpenedProjectID: sessionStorageData ? sessionStorageData?.LastOpenedProjectID : 0,
    LoginName: sessionStorageData ? sessionStorageData?.LoginName : null,
    ModifyPassword: sessionStorageData ? sessionStorageData?.ModifyPassword : false,
    NumberOfProjects: sessionStorageData ? sessionStorageData?.NumberOfProjects : null,
    OpenLastProject: sessionStorageData ? sessionStorageData?.OpenLastProject : false,
    OpenedProject: sessionStorageData ? sessionStorageData?.OpenedProject : null,
    PersonalFolders: sessionStorageData ? sessionStorageData?.PersonalFolders : [],
    ReportPagination: sessionStorageData ? sessionStorageData?.ReportPagination : false,
    SelectedTransaction: sessionStorageData ? sessionStorageData?.SelectedTransaction : null,
    SessionId: sessionStorageData ? sessionStorageData?.SessionId : null,
    SharePointActive: sessionStorageData ? sessionStorageData?.SharePointActive : null,
    SimpleView: sessionStorageData ? sessionStorageData?.SimpleView : true,
    ThinkProjectActive: sessionStorageData ? sessionStorageData?.ThinkProjectActive : null,
    TokenID: sessionStorageData ? sessionStorageData?.TokenID : null,
    TransactionMultiSelect: sessionStorageData ? sessionStorageData?.TransactionMultiSelect : false,
    Username: sessionStorageData ? sessionStorageData?.Username : null,
};

export const dispatchAuthState: React.Dispatch<any> = () => {};

export const AuthContext = createContext<[AuthContextProps, React.Dispatch<any>]>([INITIAL_AUTH_STATE, dispatchAuthState]);
