import { useState, useCallback, useEffect } from 'react';

export interface ScreenSizeProps {
    isXSmall: boolean;
    isSmall: boolean;
    isMedium: boolean;
    isLarge: boolean;
}

export const useScreenSize = () => {
    const [screenSize, setScreenSize] = useState(getScreenSize());
    const onSizeChanged = useCallback(() => {
        setScreenSize(getScreenSize());
    }, []);

    useEffect(() => {
        subscribe(onSizeChanged as any);

        return () => {
            unsubscribe(onSizeChanged as any);
        };
    }, [onSizeChanged]);

    // console.log('screenSize', screenSize);

    return screenSize;
};

export const useScreenSizeClass = () => {
    const screenSize = useScreenSize();

    if (screenSize.isLarge) {
        return 'screen-large';
    }

    if (screenSize.isMedium) {
        return 'screen-medium';
    }

    if (screenSize.isSmall) {
        return 'screen-small';
    }

    return 'screen-x-small';
};

let handlers = [] as MediaQueryList[];
const xSmallMedia = window.matchMedia('(max-width: 599.99px)');
const smallMedia = window.matchMedia('(min-width: 600px) and (max-width: 959.99px)');
const mediumMedia = window.matchMedia('(min-width: 960px) and (max-width: 1279.99px)');
const largeMedia = window.matchMedia('(min-width: 1280px)');

[xSmallMedia, smallMedia, mediumMedia, largeMedia].forEach((media) => {
    media.addEventListener('change', (e) => {
        e.matches &&
            handlers.forEach((handler) => {
                (handler as any)();
            });
    });
});

const subscribe = (handler: MediaQueryList) => handlers.push(handler);

const unsubscribe = (handler: MediaQueryList) => {
    handlers = handlers.filter((item) => item !== handler);
};

function getScreenSize() {
    return {
        isXSmall: xSmallMedia.matches,
        isSmall: smallMedia.matches,
        isMedium: mediumMedia.matches,
        isLarge: largeMedia.matches,
    };
}
