// SERVICE
import { Service } from '../../utils/Service/Service';

// REFERENCES
import { ContainerHandle } from './Container';
import { Dispatch, MutableRefObject } from 'react';
import { MessageContent } from '../../utils/Service/ServiceReference/MessageContent';
import { RowClickEvent } from 'devextreme/ui/data_grid_types';
import { TabItem } from '../../state/context/TabsContext';
import { TransactionRecord } from '../../utils/Service/ServiceReference/TransactionRecord';

// UTILS
import { definingReadpanelMessageContent } from '../../pages/Home/tabs.utils';

/**
 * Retrieves invoice data for a given message.
 *
 * @param {MessageContent} data - The message content object containing the message ID.
 * @param {string} SessionId - The session ID for authentication.
 * @param {string} TokenId - The token Id for authorization.
 * @returns {Promise<MessageContent>} - A promise that resolves to the invoice data as a MessageContent object.
 */
export const getInvoiceData = async (data: MessageContent, SessionId: string, TokenId: string) => {
    try {
        const messageID = await Service.getMessageId(parseInt(data?.ID), SessionId, TokenId).then((response) => response.data);
        return await Service.messageContent.initData({ messageID }, SessionId, TokenId);
    } catch (error) {
        console.error({ error });
    }
};

/**
 * Automatically opens a single item in a new tab if there is only one item in the data source.
 *
 * @param {RowClickEvent} e - The row click event from the data grid.
 * @param {Dispatch<any>} dispatchTabsState - The dispatch function for managing the tabs state.
 * @param {(e: RowClickEvent) => Promise<void>} handleRowClick - The function to handle the row click event.
 * @param {MutableRefObject<ContainerHandle>} refMessageContainer - A reference to the message container.
 * @param {TabItem} SelectedTab - The currently selected tab.
 * @param {TabItem[]} TabList - The list of all tabs.
 * @returns {Promise<void>} Return Promise<void>
 */
export const autoOpenSingleItemInTab = async (
    e: RowClickEvent,
    dispatchTabsState: Dispatch<any>,
    handleRowClick: (e: RowClickEvent) => Promise<void>,
    refMessageContainer: MutableRefObject<ContainerHandle>,
    SelectedTab: TabItem,
    TabList: TabItem[]
) => {
    const totalCount = e.component.getDataSource().totalCount();
    if (totalCount === 1) {
        const singleItem: TransactionRecord = e.component.getDataSource().items()[0];

        try {
            const { ID } = singleItem;

            // Check if the auto open was already called and there is an open tab.
            const isSingleItemTabDestinationOpen = TabList.find(({ rootTransaction }) => rootTransaction?.ID === ID);

            if (!isSingleItemTabDestinationOpen) {
                await handleRowClick({ data: singleItem } as RowClickEvent);
            } else {
                // Change first to the tab containing the destination transaction
                dispatchTabsState({ type: 'CHANGE_TAB', payload: isSingleItemTabDestinationOpen });

                // Update the data to be displayed in the read panel
                definingReadpanelMessageContent(
                    { transnr: singleItem?.ID, messnr: singleItem?.MessageID },
                    SelectedTab,
                    dispatchTabsState,
                    refMessageContainer
                );
            }
        } catch (error) {
            console.error({ error });
        }
    }
};
