interface Prefixes {
    [key: string]: string;
}

const CHARACTERS = 'abcdefghijklmnopqrstuvwxyz0123456789';
const ID_PREFIXES: Prefixes = {
    File: '',
    ORG: 'or_',
    PRSN: 'pe_',
    ROLE: 'ro_',
    SOAP: 'm_',
};
const SEGMENTS_LENGTHS = [8, 4, 4, 4, 12];

/**
 * Get a random integer inside the range of a string length
 *
 * @function getRandomInt
 * @param {number} max - The max number to be given
 * @returns {number} A random integer
 */
const getRandomInt = (max: number): number => Math.floor(Math.random() * max);

/**
 * Create a sring segment of an given length
 *
 * @function generateSegment
 * @param {number} length - The length of the expected segment
 * @returns {string} A segement string
 */
const generateSegment = (length: number): string => [...Array(length)].map(() => CHARACTERS[getRandomInt(CHARACTERS.length)]).join('');

/**
 * Create a string ID accourding to the standards with or without a given prefix
 *
 * @function generateID
 * @param {string} idPrefixType - An optional prefix string, available options:  - 'SOAP', 'ROLE', 'PRSN', 'ORG', 'File'
 * @returns {string} Generated string ID
 */
export const generateID = (idPrefixType: string = ''): string => {
    const prefix = ID_PREFIXES[idPrefixType] || '';

    const generatedSegments = SEGMENTS_LENGTHS.reduce((acc, segmentLength, index) => {
        const segment = generateSegment(segmentLength);
        return acc + (index > 0 ? '-' : '') + segment;
    }, '');

    return prefix + generatedSegments;
};
