/**
 * @public
 * arraysEqual: compares two arrays and returns true if they are equal
 * @param {Array} a1 array 1, the array should not contain {object}
 * @param {Array} a2 array 2, the array should not contain {object}
 * @returns {boolean} returns true if a1 and a2 are equal, false otherwise
 */
export const arraysEqual = (a1: Array<any>, a2: Array<any>) => JSON.stringify(a1) === JSON.stringify(a2);

/**
 * @public
 * objectsEqual: compares two objects and returns true if they are equal
 * @param o1 object 1
 * @param o2 object 2
 * @returns {boolean} returns true if o1 and o2 are equal, false otherwise
 */
export const objectsEqual = (o1: Object, o2: Object) => {
    const s = (o) =>
        Object.entries(o)
            .sort()
            .map((i) => {
                if (i[1] instanceof Object) i[1] = s(i[1]);
                return i;
            });
    return JSON.stringify(s(o1)) === JSON.stringify(s(o2));
};

/**
 * Regular expression pattern for matching Dutch formatted currency strings.
 * This pattern enforces the period after every group of three digits.
 *
 * The format includes:
 * - An optional negative sign at the beginning.
 * - A number from 1 to 999 followed by one or more groups of three digits, each preceded by a period.
 * - The number zero ("0").
 * - A comma followed by exactly two digits to represent the decimal part.
 *
 * Example matches:
 * - "1.234,00"
 * - "1.234.567,89"
 * - "-1.234,56"
 * - "0,99"
 *
 * Example non-matches:
 * - "123,45" (missing grouping for thousands)
 * - "1.234,567" (wrong format for decimals)
 * - "1234,56" (missing period separator for thousands)
 *
 * @type {RegExp}
 */
const CURRENCY_STRING_NL_PATTERN = /-?([1-9]\d{0,2}(\.\d{3})+|0),\d{2}/;

/**
 * Regular expression pattern for matching currency strings.
 * This pattern allows optional periods after groups of three digits.
 *
 * The format includes:
 * - An optional negative sign at the beginning.
 * - A number that can be:
 *   - From 1 to 999 followed by zero or more groups of three digits, each preceded by a period.
 *   - Any number with one or more digits not starting with zero.
 *   - The number zero ("0").
 * - A comma followed by exactly two digits to represent the decimal part.
 *
 * Example matches:
 * - "1.234,00"
 * - "1.234.567,89"
 * - "1234,56"
 * - "0,99"
 *
 * Example non-matches:
 * - "abc1.234,00" (extra characters at the start)
 * - "1.234,00xyz" (extra characters at the end)
 * - " 1.234,00 " (spaces at the start or end)
 *
 * @type {string}
 */
const CURRENCY_STRING_PATTERN = '(-?([1-9]\\d{0,2}(\\.\\d{3})*|[1-9]\\d*|0),\\d{2})?';

/**
 * Regular expression pattern for strictly matching Dutch formatted currency strings.
 * This pattern enforces that the entire string must match the currency format.
 *
 * The format includes:
 * - An optional negative sign at the beginning.
 * - A number that can be:
 *   - From 1 to 999 followed by zero or more groups of three digits, each preceded by a period.
 *   - Any number with one or more digits not starting with zero.
 *   - The number zero ("0").
 * - A comma followed by exactly two digits to represent the decimal part.
 *
 * Anchors (`^` and `$`) are used to ensure the entire string matches this pattern.
 *
 * Example matches:
 * - "1.234,00"
 * - "1.234.567,89"
 * - "-1.234,56"
 * - "0,99"
 *
 * Example non-matches:
 * - "123,45" (missing grouping for thousands)
 * - "1.234,567" (wrong format for decimals)
 * - "abc1.234,00" (extra characters at the start)
 * - "1.234,00xyz" (extra characters at the end)
 * - " 1.234,00 " (spaces at the start or end)
 *
 * @type {string}
 */
const STRICT_CURRENCY_STRING_PATTERN_NL = '^(-?([1-9]\\d{0,2}(\\.\\d{3})*|[1-9]\\d*|0),\\d{2})?$';

/**
 * Regular expression pattern for matching optional decimal strings.
 * This pattern allows:
 * - One or more digits followed by an optional comma and up to two decimal digits.
 * - An empty string.
 *
 * Anchors (`^` and `$`) are used to ensure the entire string matches this pattern.
 *
 * Example matches:
 * - "123"
 * - "123,45"
 * - "123,"
 * - ""
 *
 * Example non-matches:
 * - "123.45" (uses a period instead of a comma)
 * - "abc" (contains non-numeric characters)
 *
 * @type {string}
 */
const OPTIONAL_DECIMAL_STRING = '^(\\d+,?\\d{0,2})?$';

type PatternType = 'currencyNL' | 'strictCurrencyFormatNL' | 'optionalDecimalString';

/**
 * Tests if the provided input string matches the specified pattern type.
 *
 * @param {string} regExInput - The input string to test.
 * @param {PatternType} patternType - The type of pattern to test against. Currently supports 'optionalDecimalString' and 'strictCurrencyFormatNL'.
 * @returns {boolean} - Returns true if the input matches the specified pattern type, false otherwise.
 */
export const regExMatch = (regExInput: string, patternType: PatternType) => {
    switch (patternType) {
        case 'optionalDecimalString':
            return new RegExp(OPTIONAL_DECIMAL_STRING).test(regExInput);
        case 'strictCurrencyFormatNL':
            return CURRENCY_STRING_NL_PATTERN.test(regExInput);
        default:
            return false;
    }
};

/**
 * Checks if the provided regular expression string matches the given pattern type.
 *
 * @param {string} regExInput - The regular expression string to check.
 * @param {PatternType} patternType - The type of pattern to check against.
 * Currently supports 'currencyNL', 'optionalDecimalString' and 'strictCurrencyFormatNL'.
 * @returns {boolean} - Returns true if the input matches the specified pattern type, false otherwise.
 */
export const regExPatternEqual = (regExInput: string, patternType: PatternType) => {
    switch (patternType) {
        case 'currencyNL':
            return regExInput === CURRENCY_STRING_PATTERN;
        case 'optionalDecimalString':
            return regExInput === OPTIONAL_DECIMAL_STRING;
        case 'strictCurrencyFormatNL':
            return regExInput === STRICT_CURRENCY_STRING_PATTERN_NL;
        default:
            return false;
    }
};
