const transactieoverzicht = {
    IdColumnName: null,
    OutboxCount: 0,
    Page: 1,
    TotalTransactions: 620,
    TransactionRecords: [
        {
            Attachments: false,
            CansetReady: false,
            ConceptID: 0,
            DateTimeSend: '2021-05-31T11:56:15.891691',
            Executor: '2',
            GemachtigdNamens: 0,
            Generated: false,
            Geometry: { X: 0.0, Y: 0.0 },
            ID: 313869,
            IdColumn: null,
            Initiator: '1',
            IsLate: false,
            IsLeaf: false,
            IsRevoked: false,
            Name: 'Test',
            Number: '10839',
            Parent: '',
            ParentId: 0,
            ReactionPeriod: null,
            Read: true,
            Receiver: '2',
            Replypossible: true,
            Sender: '1',
            State: 1,
            Status: '',
            SubTransactions: 0,
            SubTransactionsIsLate: false,
            SubTransactionsState: 0,
            Transaction: null,
            TransactionType: 'WF7: Hoofdtransactie',
            TransactionTypeSID: 'tra_11',
            UnreadSubTransactions: false,
            VerzendError: null,
            VerzendStatus: 0,
        },
        {
            Attachments: false,
            CansetReady: true,
            ConceptID: 0,
            DateTimeSend: '2021-05-06T14:12:47',
            Executor: '2',
            GemachtigdNamens: 0,
            Generated: false,
            Geometry: { X: 0.0, Y: 0.0 },
            ID: 448782,
            IdColumn: null,
            Initiator: '1',
            IsLate: false,
            IsLeaf: false,
            IsRevoked: false,
            Name: 'test 6-5',
            Number: '11027',
            Parent: '',
            ParentId: 0,
            ReactionPeriod: null,
            Read: true,
            Receiver: '1',
            Replypossible: true,
            Sender: '2',
            State: 2,
            Status: '',
            SubTransactions: 0,
            SubTransactionsIsLate: false,
            SubTransactionsState: 1,
            Transaction: null,
            TransactionType: 'Doorstuur1 Veeltraps: T1 Hoofdtransactie',
            TransactionTypeSID: 'tra17_3',
            UnreadSubTransactions: false,
            VerzendError: null,
            VerzendStatus: 0,
        },
        {
            Attachments: false,
            CansetReady: false,
            ConceptID: 0,
            DateTimeSend: '2021-04-30T12:24:52',
            Executor: '2',
            GemachtigdNamens: 0,
            Generated: false,
            Geometry: { X: 0.0, Y: 0.0 },
            ID: 441309,
            IdColumn: null,
            Initiator: '1',
            IsLate: false,
            IsLeaf: false,
            IsRevoked: false,
            Name: 'hoooi 🧨',
            Number: '11021',
            Parent: '',
            ParentId: 0,
            ReactionPeriod: null,
            Read: true,
            Receiver: '1',
            Replypossible: true,
            Sender: '2',
            State: 0,
            Status: '',
            SubTransactions: 0,
            SubTransactionsIsLate: false,
            SubTransactionsState: 0,
            Transaction: null,
            TransactionType: 'Inhoud 1: base type test',
            TransactionTypeSID: 'tra_10',
            UnreadSubTransactions: false,
            VerzendError: null,
            VerzendStatus: 0,
        },
        {
            Attachments: false,
            CansetReady: false,
            ConceptID: 0,
            DateTimeSend: '2021-04-13T11:29:23',
            Executor: '2',
            GemachtigdNamens: 0,
            Generated: false,
            Geometry: { X: 0.0, Y: 0.0 },
            ID: 431772,
            IdColumn: null,
            Initiator: '1',
            IsLate: false,
            IsLeaf: false,
            IsRevoked: false,
            Name: 'y',
            Number: '11020',
            Parent: '',
            ParentId: 0,
            ReactionPeriod: null,
            Read: true,
            Receiver: '2',
            Replypossible: true,
            Sender: '1',
            State: 0,
            Status: '',
            SubTransactions: 0,
            SubTransactionsIsLate: false,
            SubTransactionsState: 0,
            Transaction: null,
            TransactionType: 'Inhoud 2: Vr學系mde tekeи bericht  !@#$%^&*();:\'" ,.<>|\\/?`~',
            TransactionTypeSID: 'tra_9',
            UnreadSubTransactions: false,
            VerzendError: null,
            VerzendStatus: 0,
        },
        {
            Attachments: false,
            CansetReady: false,
            ConceptID: 0,
            DateTimeSend: '2021-04-13T11:23:45',
            Executor: '2',
            GemachtigdNamens: 0,
            Generated: false,
            Geometry: { X: 0.0, Y: 0.0 },
            ID: 431770,
            IdColumn: null,
            Initiator: '1',
            IsLate: false,
            IsLeaf: false,
            IsRevoked: false,
            Name: 'rsd',
            Number: '11019',
            Parent: '',
            ParentId: 0,
            ReactionPeriod: null,
            Read: true,
            Receiver: '2',
            Replypossible: true,
            Sender: '1',
            State: 0,
            Status: '',
            SubTransactions: 0,
            SubTransactionsIsLate: false,
            SubTransactionsState: 0,
            Transaction: null,
            TransactionType: 'Inhoud 2: Vr學系mde tekeи bericht  !@#$%^&*();:\'" ,.<>|\\/?`~',
            TransactionTypeSID: 'tra_9',
            UnreadSubTransactions: false,
            VerzendError: null,
            VerzendStatus: 0,
        },
        {
            Attachments: false,
            CansetReady: false,
            ConceptID: 0,
            DateTimeSend: '2021-04-13T11:19:34',
            Executor: '2',
            GemachtigdNamens: 0,
            Generated: false,
            Geometry: { X: 0.0, Y: 0.0 },
            ID: 431769,
            IdColumn: null,
            Initiator: '1',
            IsLate: false,
            IsLeaf: false,
            IsRevoked: false,
            Name: 'r',
            Number: '11018',
            Parent: '',
            ParentId: 0,
            ReactionPeriod: null,
            Read: true,
            Receiver: '2',
            Replypossible: true,
            Sender: '1',
            State: 0,
            Status: '',
            SubTransactions: 0,
            SubTransactionsIsLate: false,
            SubTransactionsState: 0,
            Transaction: null,
            TransactionType: 'Inhoud 2: Vr學系mde tekeи bericht  !@#$%^&*();:\'" ,.<>|\\/?`~',
            TransactionTypeSID: 'tra_9',
            UnreadSubTransactions: false,
            VerzendError: null,
            VerzendStatus: 0,
        },
        {
            Attachments: false,
            CansetReady: false,
            ConceptID: 0,
            DateTimeSend: '2021-04-13T11:14:31',
            Executor: '2',
            GemachtigdNamens: 0,
            Generated: false,
            Geometry: { X: 0.0, Y: 0.0 },
            ID: 431768,
            IdColumn: null,
            Initiator: '1',
            IsLate: false,
            IsLeaf: false,
            IsRevoked: false,
            Name: 'rweq',
            Number: '11017',
            Parent: '',
            ParentId: 0,
            ReactionPeriod: null,
            Read: true,
            Receiver: '2',
            Replypossible: true,
            Sender: '1',
            State: 0,
            Status: '',
            SubTransactions: 0,
            SubTransactionsIsLate: false,
            SubTransactionsState: 0,
            Transaction: null,
            TransactionType: 'Inhoud 2: Vr學系mde tekeи bericht  !@#$%^&*();:\'" ,.<>|\\/?`~',
            TransactionTypeSID: 'tra_9',
            UnreadSubTransactions: false,
            VerzendError: null,
            VerzendStatus: 0,
        },
        {
            Attachments: false,
            CansetReady: false,
            ConceptID: 0,
            DateTimeSend: '2021-04-13T11:14:04',
            Executor: '2',
            GemachtigdNamens: 0,
            Generated: false,
            Geometry: { X: 0.0, Y: 0.0 },
            ID: 431767,
            IdColumn: null,
            Initiator: '1',
            IsLate: false,
            IsLeaf: false,
            IsRevoked: false,
            Name: 'r43',
            Number: '11016',
            Parent: '',
            ParentId: 0,
            ReactionPeriod: null,
            Read: true,
            Receiver: '2',
            Replypossible: true,
            Sender: '1',
            State: 0,
            Status: '',
            SubTransactions: 0,
            SubTransactionsIsLate: false,
            SubTransactionsState: 0,
            Transaction: null,
            TransactionType: 'Inhoud 2: Vr學系mde tekeи bericht  !@#$%^&*();:\'" ,.<>|\\/?`~',
            TransactionTypeSID: 'tra_9',
            UnreadSubTransactions: false,
            VerzendError: null,
            VerzendStatus: 0,
        },
        {
            Attachments: false,
            CansetReady: false,
            ConceptID: 0,
            DateTimeSend: '2021-04-13T11:12:40',
            Executor: '2',
            GemachtigdNamens: 0,
            Generated: false,
            Geometry: { X: 0.0, Y: 0.0 },
            ID: 431766,
            IdColumn: null,
            Initiator: '1',
            IsLate: false,
            IsLeaf: false,
            IsRevoked: false,
            Name: 'tes',
            Number: '11015',
            Parent: '',
            ParentId: 0,
            ReactionPeriod: null,
            Read: true,
            Receiver: '2',
            Replypossible: true,
            Sender: '1',
            State: 0,
            Status: '',
            SubTransactions: 0,
            SubTransactionsIsLate: false,
            SubTransactionsState: 0,
            Transaction: null,
            TransactionType: 'Inhoud 2: Vr學系mde tekeи bericht  !@#$%^&*();:\'" ,.<>|\\/?`~',
            TransactionTypeSID: 'tra_9',
            UnreadSubTransactions: false,
            VerzendError: null,
            VerzendStatus: 0,
        },
        {
            Attachments: false,
            CansetReady: false,
            ConceptID: 0,
            DateTimeSend: '2021-04-13T11:08:09',
            Executor: '2',
            GemachtigdNamens: 0,
            Generated: false,
            Geometry: { X: 0.0, Y: 0.0 },
            ID: 431765,
            IdColumn: null,
            Initiator: '1',
            IsLate: false,
            IsLeaf: false,
            IsRevoked: false,
            Name: 'g',
            Number: '11014',
            Parent: '',
            ParentId: 0,
            ReactionPeriod: null,
            Read: true,
            Receiver: '2',
            Replypossible: true,
            Sender: '1',
            State: 0,
            Status: '',
            SubTransactions: 0,
            SubTransactionsIsLate: false,
            SubTransactionsState: 0,
            Transaction: null,
            TransactionType: 'Inhoud 2: Vr學系mde tekeи bericht  !@#$%^&*();:\'" ,.<>|\\/?`~',
            TransactionTypeSID: 'tra_9',
            UnreadSubTransactions: false,
            VerzendError: null,
            VerzendStatus: 0,
        },
        {
            Attachments: false,
            CansetReady: false,
            ConceptID: 0,
            DateTimeSend: '2021-04-13T11:07:23',
            Executor: '2',
            GemachtigdNamens: 0,
            Generated: false,
            Geometry: { X: 0.0, Y: 0.0 },
            ID: 431763,
            IdColumn: null,
            Initiator: '1',
            IsLate: false,
            IsLeaf: false,
            IsRevoked: false,
            Name: 'dhat',
            Number: '11013',
            Parent: '',
            ParentId: 0,
            ReactionPeriod: null,
            Read: true,
            Receiver: '2',
            Replypossible: true,
            Sender: '1',
            State: 0,
            Status: '',
            SubTransactions: 0,
            SubTransactionsIsLate: false,
            SubTransactionsState: 0,
            Transaction: null,
            TransactionType: 'Inhoud 2: Vr學系mde tekeи bericht  !@#$%^&*();:\'" ,.<>|\\/?`~',
            TransactionTypeSID: 'tra_9',
            UnreadSubTransactions: false,
            VerzendError: null,
            VerzendStatus: 0,
        },
        {
            Attachments: false,
            CansetReady: false,
            ConceptID: 0,
            DateTimeSend: '2021-04-13T11:06:54',
            Executor: '2',
            GemachtigdNamens: 0,
            Generated: false,
            Geometry: { X: 0.0, Y: 0.0 },
            ID: 431762,
            IdColumn: null,
            Initiator: '1',
            IsLate: false,
            IsLeaf: false,
            IsRevoked: false,
            Name: 'srud',
            Number: '11012',
            Parent: '',
            ParentId: 0,
            ReactionPeriod: null,
            Read: true,
            Receiver: '2',
            Replypossible: true,
            Sender: '1',
            State: 0,
            Status: '',
            SubTransactions: 0,
            SubTransactionsIsLate: false,
            SubTransactionsState: 0,
            Transaction: null,
            TransactionType: 'Inhoud 2: Vr學系mde tekeи bericht  !@#$%^&*();:\'" ,.<>|\\/?`~',
            TransactionTypeSID: 'tra_9',
            UnreadSubTransactions: false,
            VerzendError: null,
            VerzendStatus: 0,
        },
        {
            Attachments: false,
            CansetReady: false,
            ConceptID: 0,
            DateTimeSend: '2021-04-13T11:04:53',
            Executor: '2',
            GemachtigdNamens: 0,
            Generated: false,
            Geometry: { X: 0.0, Y: 0.0 },
            ID: 431761,
            IdColumn: null,
            Initiator: '1',
            IsLate: false,
            IsLeaf: false,
            IsRevoked: false,
            Name: 'ydr',
            Number: '11011',
            Parent: '',
            ParentId: 0,
            ReactionPeriod: null,
            Read: true,
            Receiver: '2',
            Replypossible: true,
            Sender: '1',
            State: 0,
            Status: '',
            SubTransactions: 0,
            SubTransactionsIsLate: false,
            SubTransactionsState: 0,
            Transaction: null,
            TransactionType: 'Inhoud 2: Vr學系mde tekeи bericht  !@#$%^&*();:\'" ,.<>|\\/?`~',
            TransactionTypeSID: 'tra_9',
            UnreadSubTransactions: false,
            VerzendError: null,
            VerzendStatus: 0,
        },
        {
            Attachments: false,
            CansetReady: false,
            ConceptID: 0,
            DateTimeSend: '2021-04-09T00:51:45',
            Executor: '2',
            GemachtigdNamens: 0,
            Generated: false,
            Geometry: { X: 0.0, Y: 0.0 },
            ID: 431611,
            IdColumn: null,
            Initiator: '1',
            IsLate: false,
            IsLeaf: false,
            IsRevoked: false,
            Name: 'Test',
            Number: '11010',
            Parent: '',
            ParentId: 0,
            ReactionPeriod: null,
            Read: true,
            Receiver: '2',
            Replypossible: true,
            Sender: '1',
            State: 0,
            Status: '',
            SubTransactions: 0,
            SubTransactionsIsLate: false,
            SubTransactionsState: 0,
            Transaction: null,
            TransactionType: 'Inhoud3: Element conditions',
            TransactionTypeSID: 'tra18',
            UnreadSubTransactions: false,
            VerzendError: null,
            VerzendStatus: 0,
        },
        {
            Attachments: false,
            CansetReady: false,
            ConceptID: 0,
            DateTimeSend: '2021-04-02T15:58:01',
            Executor: '2',
            GemachtigdNamens: 0,
            Generated: false,
            Geometry: { X: 0.0, Y: 0.0 },
            ID: 430863,
            IdColumn: null,
            Initiator: '1',
            IsLate: false,
            IsLeaf: false,
            IsRevoked: false,
            Name: 'zshd',
            Number: '11009',
            Parent: '',
            ParentId: 0,
            ReactionPeriod: null,
            Read: true,
            Receiver: '2',
            Replypossible: true,
            Sender: '1',
            State: 0,
            Status: '',
            SubTransactions: 0,
            SubTransactionsIsLate: false,
            SubTransactionsState: 0,
            Transaction: null,
            TransactionType: 'Inhoud 2: Vr學系mde tekeи bericht  !@#$%^&*();:\'" ,.<>|\\/?`~',
            TransactionTypeSID: 'tra_9',
            UnreadSubTransactions: false,
            VerzendError: null,
            VerzendStatus: 0,
        },
        {
            Attachments: false,
            CansetReady: false,
            ConceptID: 0,
            DateTimeSend: '2021-04-01T13:58:53',
            Executor: '2',
            GemachtigdNamens: 0,
            Generated: false,
            Geometry: { X: 0.0, Y: 0.0 },
            ID: 430672,
            IdColumn: null,
            Initiator: '1',
            IsLate: false,
            IsLeaf: false,
            IsRevoked: false,
            Name: 'test 1 april',
            Number: '11008',
            Parent: '',
            ParentId: 0,
            ReactionPeriod: null,
            Read: true,
            Receiver: '2',
            Replypossible: true,
            Sender: '1',
            State: 0,
            Status: '',
            SubTransactions: 0,
            SubTransactionsIsLate: false,
            SubTransactionsState: 0,
            Transaction: null,
            TransactionType: 'Inhoud3: Element conditions',
            TransactionTypeSID: 'tra18',
            UnreadSubTransactions: false,
            VerzendError: null,
            VerzendStatus: 0,
        },
        {
            Attachments: false,
            CansetReady: false,
            ConceptID: 0,
            DateTimeSend: '2021-03-23T14:02:47',
            Executor: '2z',
            GemachtigdNamens: 0,
            Generated: false,
            Geometry: { X: 0.0, Y: 0.0 },
            ID: 401833,
            IdColumn: null,
            Initiator: '1',
            IsLate: false,
            IsLeaf: false,
            IsRevoked: false,
            Name: 'srjd',
            Number: '11007',
            Parent: '',
            ParentId: 0,
            ReactionPeriod: null,
            Read: true,
            Receiver: '2z',
            Replypossible: true,
            Sender: '1',
            State: 0,
            Status: '',
            SubTransactions: 0,
            SubTransactionsIsLate: false,
            SubTransactionsState: 0,
            Transaction: null,
            TransactionType: 'Inhoud 2: Vr學系mde tekeи bericht  !@#$%^&*();:\'" ,.<>|\\/?`~',
            TransactionTypeSID: 'tra_9',
            UnreadSubTransactions: false,
            VerzendError: null,
            VerzendStatus: 0,
        },
        {
            Attachments: false,
            CansetReady: false,
            ConceptID: 0,
            DateTimeSend: '2021-03-23T14:02:47',
            Executor: '2a',
            GemachtigdNamens: 0,
            Generated: false,
            Geometry: { X: 0.0, Y: 0.0 },
            ID: 401832,
            IdColumn: null,
            Initiator: '1',
            IsLate: false,
            IsLeaf: false,
            IsRevoked: false,
            Name: 'srjd',
            Number: '11006',
            Parent: '',
            ParentId: 0,
            ReactionPeriod: null,
            Read: true,
            Receiver: '2a',
            Replypossible: true,
            Sender: '1',
            State: 0,
            Status: '',
            SubTransactions: 0,
            SubTransactionsIsLate: false,
            SubTransactionsState: 0,
            Transaction: null,
            TransactionType: 'Inhoud 2: Vr學系mde tekeи bericht  !@#$%^&*();:\'" ,.<>|\\/?`~',
            TransactionTypeSID: 'tra_9',
            UnreadSubTransactions: false,
            VerzendError: null,
            VerzendStatus: 0,
        },
        {
            Attachments: false,
            CansetReady: false,
            ConceptID: 0,
            DateTimeSend: '2021-03-23T13:59:56',
            Executor: '2z',
            GemachtigdNamens: 0,
            Generated: false,
            Geometry: { X: 0.0, Y: 0.0 },
            ID: 401831,
            IdColumn: null,
            Initiator: '1',
            IsLate: false,
            IsLeaf: false,
            IsRevoked: false,
            Name: 'hjsdyfugj',
            Number: '11005',
            Parent: '',
            ParentId: 0,
            ReactionPeriod: null,
            Read: true,
            Receiver: '2z',
            Replypossible: true,
            Sender: '1',
            State: 0,
            Status: '',
            SubTransactions: 0,
            SubTransactionsIsLate: false,
            SubTransactionsState: 0,
            Transaction: null,
            TransactionType: 'Inhoud 2: Vr學系mde tekeи bericht  !@#$%^&*();:\'" ,.<>|\\/?`~',
            TransactionTypeSID: 'tra_9',
            UnreadSubTransactions: false,
            VerzendError: null,
            VerzendStatus: 0,
        },
        {
            Attachments: false,
            CansetReady: false,
            ConceptID: 0,
            DateTimeSend: '2021-03-22T17:02:15',
            Executor: '2',
            GemachtigdNamens: 0,
            Generated: false,
            Geometry: { X: 0.0, Y: 0.0 },
            ID: 401822,
            IdColumn: null,
            Initiator: '1',
            IsLate: false,
            IsLeaf: false,
            IsRevoked: false,
            Name: 'spam 1123',
            Number: '11004',
            Parent: '',
            ParentId: 0,
            ReactionPeriod: null,
            Read: true,
            Receiver: '2',
            Replypossible: true,
            Sender: '1',
            State: 0,
            Status: '',
            SubTransactions: 0,
            SubTransactionsIsLate: false,
            SubTransactionsState: 0,
            Transaction: null,
            TransactionType: 'Inhoud3: Element conditions',
            TransactionTypeSID: 'tra18',
            UnreadSubTransactions: false,
            VerzendError: null,
            VerzendStatus: 0,
        },
        {
            Attachments: false,
            CansetReady: false,
            ConceptID: 0,
            DateTimeSend: '2021-03-22T16:38:50',
            Executor: '2',
            GemachtigdNamens: 0,
            Generated: false,
            Geometry: { X: 0.0, Y: 0.0 },
            ID: 401821,
            IdColumn: null,
            Initiator: '1',
            IsLate: false,
            IsLeaf: false,
            IsRevoked: false,
            Name: 'spam voor arne',
            Number: '11003',
            Parent: '',
            ParentId: 0,
            ReactionPeriod: null,
            Read: true,
            Receiver: '2',
            Replypossible: true,
            Sender: '1',
            State: 0,
            Status: '',
            SubTransactions: 0,
            SubTransactionsIsLate: false,
            SubTransactionsState: 0,
            Transaction: null,
            TransactionType: 'Inhoud3: Element conditions',
            TransactionTypeSID: 'tra18',
            UnreadSubTransactions: false,
            VerzendError: null,
            VerzendStatus: 0,
        },
        {
            Attachments: false,
            CansetReady: false,
            ConceptID: 0,
            DateTimeSend: '2021-03-17T16:14:02',
            Executor: '2',
            GemachtigdNamens: 0,
            Generated: false,
            Geometry: { X: 0.0, Y: 0.0 },
            ID: 401787,
            IdColumn: null,
            Initiator: '1',
            IsLate: false,
            IsLeaf: false,
            IsRevoked: false,
            Name: 'test2',
            Number: '11002',
            Parent: '',
            ParentId: 0,
            ReactionPeriod: null,
            Read: true,
            Receiver: '2',
            Replypossible: true,
            Sender: '1',
            State: 0,
            Status: '',
            SubTransactions: 0,
            SubTransactionsIsLate: false,
            SubTransactionsState: 0,
            Transaction: null,
            TransactionType: 'Inhoud3: Element conditions',
            TransactionTypeSID: 'tra18',
            UnreadSubTransactions: false,
            VerzendError: null,
            VerzendStatus: 0,
        },
        {
            Attachments: false,
            CansetReady: false,
            ConceptID: 0,
            DateTimeSend: '2021-03-17T16:12:53',
            Executor: '2',
            GemachtigdNamens: 0,
            Generated: false,
            Geometry: { X: 0.0, Y: 0.0 },
            ID: 401786,
            IdColumn: null,
            Initiator: '1',
            IsLate: false,
            IsLeaf: false,
            IsRevoked: false,
            Name: 'test 1',
            Number: '11001',
            Parent: '',
            ParentId: 0,
            ReactionPeriod: null,
            Read: true,
            Receiver: '2',
            Replypossible: true,
            Sender: '1',
            State: 0,
            Status: '',
            SubTransactions: 0,
            SubTransactionsIsLate: false,
            SubTransactionsState: 0,
            Transaction: null,
            TransactionType: 'Inhoud3: Element conditions',
            TransactionTypeSID: 'tra18',
            UnreadSubTransactions: false,
            VerzendError: null,
            VerzendStatus: 0,
        },
        {
            Attachments: false,
            CansetReady: false,
            ConceptID: 0,
            DateTimeSend: '2021-03-16T11:25:06',
            Executor: '2',
            GemachtigdNamens: 0,
            Generated: false,
            Geometry: { X: 0.0, Y: 0.0 },
            ID: 401490,
            IdColumn: null,
            Initiator: '1',
            IsLate: false,
            IsLeaf: false,
            IsRevoked: false,
            Name: '~!@#$%^&*()<>,./?\\',
            Number: '11000',
            Parent: '',
            ParentId: 0,
            ReactionPeriod: null,
            Read: true,
            Receiver: '2',
            Replypossible: true,
            Sender: '1',
            State: 0,
            Status: '',
            SubTransactions: 0,
            SubTransactionsIsLate: false,
            SubTransactionsState: 0,
            Transaction: null,
            TransactionType: 'Inhoud 2: Vr學系mde tekeи bericht  !@#$%^&*();:\'" ,.<>|\\/?`~',
            TransactionTypeSID: 'tra_9',
            UnreadSubTransactions: false,
            VerzendError: null,
            VerzendStatus: 0,
        },
        {
            Attachments: false,
            CansetReady: false,
            ConceptID: 0,
            DateTimeSend: '2021-03-16T09:56:10',
            Executor: '2',
            GemachtigdNamens: 0,
            Generated: false,
            Geometry: { X: 0.0, Y: 0.0 },
            ID: 401332,
            IdColumn: null,
            Initiator: '1',
            IsLate: false,
            IsLeaf: false,
            IsRevoked: false,
            Name: 'Voorbeeld tbv react werk',
            Number: '10999',
            Parent: '',
            ParentId: 0,
            ReactionPeriod: null,
            Read: true,
            Receiver: '2',
            Replypossible: true,
            Sender: '1',
            State: 0,
            Status: '',
            SubTransactions: 0,
            SubTransactionsIsLate: false,
            SubTransactionsState: 0,
            Transaction: null,
            TransactionType: 'Inhoud4: User Defined Types (UDT)',
            TransactionTypeSID: 'tra18_2',
            UnreadSubTransactions: false,
            VerzendError: null,
            VerzendStatus: 0,
        },
        {
            Attachments: false,
            CansetReady: false,
            ConceptID: 0,
            DateTimeSend: '2021-03-12T10:49:59',
            Executor: '2',
            GemachtigdNamens: 0,
            Generated: false,
            Geometry: { X: 0.0, Y: 0.0 },
            ID: 400871,
            IdColumn: null,
            Initiator: '1',
            IsLate: false,
            IsLeaf: false,
            IsRevoked: false,
            Name: 'test',
            Number: '10993',
            Parent: '',
            ParentId: 0,
            ReactionPeriod: null,
            Read: true,
            Receiver: '2',
            Replypossible: true,
            Sender: '1',
            State: 0,
            Status: '',
            SubTransactions: 0,
            SubTransactionsIsLate: false,
            SubTransactionsState: 0,
            Transaction: null,
            TransactionType: 'Inhoud3: Element conditions',
            TransactionTypeSID: 'tra18',
            UnreadSubTransactions: false,
            VerzendError: null,
            VerzendStatus: 0,
        },
        {
            Attachments: false,
            CansetReady: false,
            ConceptID: 0,
            DateTimeSend: '2021-03-12T10:45:54',
            Executor: '2',
            GemachtigdNamens: 0,
            Generated: false,
            Geometry: { X: 0.0, Y: 0.0 },
            ID: 400870,
            IdColumn: null,
            Initiator: '1',
            IsLate: false,
            IsLeaf: false,
            IsRevoked: false,
            Name: 'atest rollback',
            Number: '10992',
            Parent: '',
            ParentId: 0,
            ReactionPeriod: null,
            Read: true,
            Receiver: '2',
            Replypossible: true,
            Sender: '1',
            State: 0,
            Status: '',
            SubTransactions: 0,
            SubTransactionsIsLate: false,
            SubTransactionsState: 0,
            Transaction: null,
            TransactionType: 'Inhoud3: Element conditions',
            TransactionTypeSID: 'tra18',
            UnreadSubTransactions: false,
            VerzendError: null,
            VerzendStatus: 0,
        },
        {
            Attachments: false,
            CansetReady: false,
            ConceptID: 0,
            DateTimeSend: '2021-03-12T09:47:33',
            Executor: '2a',
            GemachtigdNamens: 0,
            Generated: false,
            Geometry: { X: 0.0, Y: 0.0 },
            ID: 400863,
            IdColumn: null,
            Initiator: '1',
            IsLate: false,
            IsLeaf: false,
            IsRevoked: false,
            Name: 'haaaai',
            Number: '10990',
            Parent: '',
            ParentId: 0,
            ReactionPeriod: null,
            Read: true,
            Receiver: '2a',
            Replypossible: true,
            Sender: '1',
            State: 1,
            Status: '',
            SubTransactions: 0,
            SubTransactionsIsLate: false,
            SubTransactionsState: 0,
            Transaction: null,
            TransactionType: 'Backoffice 2: Gebruik keuzelijsten',
            TransactionTypeSID: 'tra24',
            UnreadSubTransactions: false,
            VerzendError: null,
            VerzendStatus: 0,
        },
        {
            Attachments: false,
            CansetReady: false,
            ConceptID: 0,
            DateTimeSend: '2021-03-08T11:35:11',
            Executor: '2',
            GemachtigdNamens: 0,
            Generated: false,
            Geometry: { X: 0.0, Y: 0.0 },
            ID: 400470,
            IdColumn: null,
            Initiator: '1',
            IsLate: false,
            IsLeaf: false,
            IsRevoked: false,
            Name: 'inhoud 2 attach',
            Number: '10987',
            Parent: '',
            ParentId: 0,
            ReactionPeriod: null,
            Read: true,
            Receiver: '2',
            Replypossible: true,
            Sender: '1',
            State: 0,
            Status: '',
            SubTransactions: 0,
            SubTransactionsIsLate: false,
            SubTransactionsState: 0,
            Transaction: null,
            TransactionType: 'Inhoud3: Element conditions',
            TransactionTypeSID: 'tra18',
            UnreadSubTransactions: false,
            VerzendError: null,
            VerzendStatus: 0,
        },
        {
            Attachments: false,
            CansetReady: false,
            ConceptID: 0,
            DateTimeSend: '2021-03-04T13:53:40',
            Executor: '2',
            GemachtigdNamens: 0,
            Generated: false,
            Geometry: { X: 0.0, Y: 0.0 },
            ID: 400409,
            IdColumn: null,
            Initiator: '1',
            IsLate: false,
            IsLeaf: false,
            IsRevoked: false,
            Name: 'adsdsa attachment',
            Number: '10986',
            Parent: '',
            ParentId: 0,
            ReactionPeriod: null,
            Read: true,
            Receiver: '2',
            Replypossible: true,
            Sender: '1',
            State: 0,
            Status: '',
            SubTransactions: 0,
            SubTransactionsIsLate: false,
            SubTransactionsState: 0,
            Transaction: null,
            TransactionType: 'Inhoud3: Element conditions',
            TransactionTypeSID: 'tra18',
            UnreadSubTransactions: false,
            VerzendError: null,
            VerzendStatus: 0,
        },
        {
            Attachments: false,
            CansetReady: false,
            ConceptID: 0,
            DateTimeSend: '2021-03-01T16:04:16',
            Executor: '2',
            GemachtigdNamens: 0,
            Generated: false,
            Geometry: { X: 0.0, Y: 0.0 },
            ID: 400234,
            IdColumn: null,
            Initiator: '1',
            IsLate: false,
            IsLeaf: false,
            IsRevoked: false,
            Name: '1',
            Number: '10985',
            Parent: '',
            ParentId: 0,
            ReactionPeriod: null,
            Read: true,
            Receiver: '2',
            Replypossible: true,
            Sender: '1',
            State: 0,
            Status: '',
            SubTransactions: 0,
            SubTransactionsIsLate: false,
            SubTransactionsState: 0,
            Transaction: null,
            TransactionType: 'Inhoud3: Element conditions',
            TransactionTypeSID: 'tra18',
            UnreadSubTransactions: false,
            VerzendError: null,
            VerzendStatus: 0,
        },
        {
            Attachments: false,
            CansetReady: false,
            ConceptID: 0,
            DateTimeSend: '2021-01-27T12:46:12',
            Executor: '2',
            GemachtigdNamens: 0,
            Generated: false,
            Geometry: { X: 0.0, Y: 0.0 },
            ID: 382789,
            IdColumn: null,
            Initiator: '1',
            IsLate: false,
            IsLeaf: false,
            IsRevoked: false,
            Name: 'lucene',
            Number: '10984',
            Parent: '',
            ParentId: 0,
            ReactionPeriod: null,
            Read: true,
            Receiver: '2',
            Replypossible: true,
            Sender: '1',
            State: 0,
            Status: '',
            SubTransactions: 0,
            SubTransactionsIsLate: false,
            SubTransactionsState: 0,
            Transaction: null,
            TransactionType: 'Inhoud 1: base type test',
            TransactionTypeSID: 'tra_10',
            UnreadSubTransactions: false,
            VerzendError: null,
            VerzendStatus: 0,
        },
        {
            Attachments: false,
            CansetReady: false,
            ConceptID: 0,
            DateTimeSend: '2021-01-15T20:17:59',
            Executor: '1a',
            GemachtigdNamens: 0,
            Generated: false,
            Geometry: { X: 0.0, Y: 0.0 },
            ID: 382210,
            IdColumn: null,
            Initiator: '1',
            IsLate: false,
            IsLeaf: false,
            IsRevoked: false,
            Name: 'a',
            Number: '10964',
            Parent: '',
            ParentId: 0,
            ReactionPeriod: null,
            Read: true,
            Receiver: '1a',
            Replypossible: true,
            Sender: '1',
            State: 0,
            Status: '',
            SubTransactions: 0,
            SubTransactionsIsLate: false,
            SubTransactionsState: 0,
            Transaction: null,
            TransactionType: 'BA1: Voorbereidingstransactie die door sendbefore de goedkeuring tegenhoudt',
            TransactionTypeSID: 'tra_13_2',
            UnreadSubTransactions: false,
            VerzendError: null,
            VerzendStatus: 0,
        },
        {
            Attachments: false,
            CansetReady: false,
            ConceptID: 0,
            DateTimeSend: '2021-01-06T15:35:29',
            Executor: '1a',
            GemachtigdNamens: 0,
            Generated: false,
            Geometry: { X: 0.0, Y: 0.0 },
            ID: 381035,
            IdColumn: null,
            Initiator: '1',
            IsLate: false,
            IsLeaf: false,
            IsRevoked: false,
            Name: 'test behandelen en nieuw',
            Number: '10963',
            Parent: '',
            ParentId: 0,
            ReactionPeriod: null,
            Read: true,
            Receiver: '1a',
            Replypossible: true,
            Sender: '1',
            State: 0,
            Status: '',
            SubTransactions: 0,
            SubTransactionsIsLate: false,
            SubTransactionsState: 0,
            Transaction: null,
            TransactionType: 'Inhoud 1: base type test',
            TransactionTypeSID: 'tra_10',
            UnreadSubTransactions: false,
            VerzendError: null,
            VerzendStatus: 0,
        },
        {
            Attachments: false,
            CansetReady: false,
            ConceptID: 0,
            DateTimeSend: '2021-01-06T10:11:21',
            Executor: '2a',
            GemachtigdNamens: 0,
            Generated: false,
            Geometry: { X: 0.0, Y: 0.0 },
            ID: 380942,
            IdColumn: null,
            Initiator: '1',
            IsLate: false,
            IsLeaf: false,
            IsRevoked: false,
            Name: 'hai',
            Number: '10962',
            Parent: '',
            ParentId: 0,
            ReactionPeriod: null,
            Read: true,
            Receiver: '2a',
            Replypossible: true,
            Sender: '1',
            State: 0,
            Status: '',
            SubTransactions: 0,
            SubTransactionsIsLate: false,
            SubTransactionsState: 1,
            Transaction: null,
            TransactionType: 'Doorstuur2 Condities: T1 Hoofdtransactie (zie helptekst)',
            TransactionTypeSID: 'tra17_3a',
            UnreadSubTransactions: false,
            VerzendError: null,
            VerzendStatus: 0,
        },
        {
            Attachments: false,
            CansetReady: false,
            ConceptID: 0,
            DateTimeSend: '2021-01-05T17:46:41',
            Executor: '1a',
            GemachtigdNamens: 0,
            Generated: false,
            Geometry: { X: 0.0, Y: 0.0 },
            ID: 380932,
            IdColumn: null,
            Initiator: '1',
            IsLate: false,
            IsLeaf: false,
            IsRevoked: false,
            Name: 'a',
            Number: '10958',
            Parent: '',
            ParentId: 0,
            ReactionPeriod: null,
            Read: true,
            Receiver: '1a',
            Replypossible: true,
            Sender: '1',
            State: 0,
            Status: '',
            SubTransactions: 0,
            SubTransactionsIsLate: false,
            SubTransactionsState: 0,
            Transaction: null,
            TransactionType: 'BA1: Voorbereidingstransactie die door sendbefore de goedkeuring tegenhoudt',
            TransactionTypeSID: 'tra_13_2',
            UnreadSubTransactions: false,
            VerzendError: null,
            VerzendStatus: 0,
        },
        {
            Attachments: false,
            CansetReady: false,
            ConceptID: 0,
            DateTimeSend: '2021-01-04T19:07:37',
            Executor: '1a',
            GemachtigdNamens: 0,
            Generated: false,
            Geometry: { X: 0.0, Y: 0.0 },
            ID: 380882,
            IdColumn: null,
            Initiator: '1',
            IsLate: false,
            IsLeaf: false,
            IsRevoked: false,
            Name: 'a',
            Number: '10950',
            Parent: '',
            ParentId: 0,
            ReactionPeriod: null,
            Read: true,
            Receiver: '1a',
            Replypossible: true,
            Sender: '1',
            State: 0,
            Status: '',
            SubTransactions: 0,
            SubTransactionsIsLate: false,
            SubTransactionsState: 0,
            Transaction: null,
            TransactionType: 'BA1: Voorbereidingstransactie die door sendbefore de goedkeuring tegenhoudt',
            TransactionTypeSID: 'tra_13_2',
            UnreadSubTransactions: false,
            VerzendError: null,
            VerzendStatus: 0,
        },
        {
            Attachments: false,
            CansetReady: false,
            ConceptID: 0,
            DateTimeSend: '2021-01-04T18:55:19',
            Executor: '1a',
            GemachtigdNamens: 0,
            Generated: false,
            Geometry: { X: 0.0, Y: 0.0 },
            ID: 380881,
            IdColumn: null,
            Initiator: '1',
            IsLate: false,
            IsLeaf: false,
            IsRevoked: false,
            Name: 'a',
            Number: '10949',
            Parent: '',
            ParentId: 0,
            ReactionPeriod: null,
            Read: true,
            Receiver: '1a',
            Replypossible: true,
            Sender: '1',
            State: 0,
            Status: '',
            SubTransactions: 0,
            SubTransactionsIsLate: false,
            SubTransactionsState: 0,
            Transaction: null,
            TransactionType: 'BA1: Voorbereidingstransactie die door sendbefore de goedkeuring tegenhoudt',
            TransactionTypeSID: 'tra_13_2',
            UnreadSubTransactions: false,
            VerzendError: null,
            VerzendStatus: 0,
        },
        {
            Attachments: false,
            CansetReady: false,
            ConceptID: 0,
            DateTimeSend: '2021-01-04T18:42:50',
            Executor: '1a',
            GemachtigdNamens: 0,
            Generated: false,
            Geometry: { X: 0.0, Y: 0.0 },
            ID: 380880,
            IdColumn: null,
            Initiator: '1',
            IsLate: false,
            IsLeaf: false,
            IsRevoked: false,
            Name: 'a',
            Number: '10948',
            Parent: '',
            ParentId: 0,
            ReactionPeriod: null,
            Read: true,
            Receiver: '1a',
            Replypossible: true,
            Sender: '1',
            State: 0,
            Status: '',
            SubTransactions: 0,
            SubTransactionsIsLate: false,
            SubTransactionsState: 0,
            Transaction: null,
            TransactionType: 'BA1: Voorbereidingstransactie die door sendbefore de goedkeuring tegenhoudt',
            TransactionTypeSID: 'tra_13_2',
            UnreadSubTransactions: false,
            VerzendError: null,
            VerzendStatus: 0,
        },
        {
            Attachments: false,
            CansetReady: false,
            ConceptID: 0,
            DateTimeSend: '2021-01-04T18:38:54',
            Executor: '1a',
            GemachtigdNamens: 0,
            Generated: false,
            Geometry: { X: 0.0, Y: 0.0 },
            ID: 380879,
            IdColumn: null,
            Initiator: '1',
            IsLate: false,
            IsLeaf: false,
            IsRevoked: false,
            Name: 'a',
            Number: '10947',
            Parent: '',
            ParentId: 0,
            ReactionPeriod: null,
            Read: true,
            Receiver: '1a',
            Replypossible: true,
            Sender: '1',
            State: 0,
            Status: '',
            SubTransactions: 0,
            SubTransactionsIsLate: false,
            SubTransactionsState: 0,
            Transaction: null,
            TransactionType: 'BA1: Voorbereidingstransactie die door sendbefore de goedkeuring tegenhoudt',
            TransactionTypeSID: 'tra_13_2',
            UnreadSubTransactions: false,
            VerzendError: null,
            VerzendStatus: 0,
        },
        {
            Attachments: false,
            CansetReady: false,
            ConceptID: 0,
            DateTimeSend: '2021-01-04T18:35:23',
            Executor: '1a',
            GemachtigdNamens: 0,
            Generated: false,
            Geometry: { X: 0.0, Y: 0.0 },
            ID: 380878,
            IdColumn: null,
            Initiator: '1',
            IsLate: false,
            IsLeaf: false,
            IsRevoked: false,
            Name: 'a',
            Number: '10946',
            Parent: '',
            ParentId: 0,
            ReactionPeriod: null,
            Read: true,
            Receiver: '1a',
            Replypossible: true,
            Sender: '1',
            State: 0,
            Status: '',
            SubTransactions: 0,
            SubTransactionsIsLate: false,
            SubTransactionsState: 0,
            Transaction: null,
            TransactionType: 'BA1: Voorbereidingstransactie die door sendbefore de goedkeuring tegenhoudt',
            TransactionTypeSID: 'tra_13_2',
            UnreadSubTransactions: false,
            VerzendError: null,
            VerzendStatus: 0,
        },
        {
            Attachments: false,
            CansetReady: false,
            ConceptID: 0,
            DateTimeSend: '2021-01-04T18:08:59',
            Executor: '1a',
            GemachtigdNamens: 0,
            Generated: false,
            Geometry: { X: 0.0, Y: 0.0 },
            ID: 380876,
            IdColumn: null,
            Initiator: '1',
            IsLate: false,
            IsLeaf: false,
            IsRevoked: false,
            Name: 'a',
            Number: '10944',
            Parent: '',
            ParentId: 0,
            ReactionPeriod: null,
            Read: true,
            Receiver: '1a',
            Replypossible: true,
            Sender: '1',
            State: 0,
            Status: '',
            SubTransactions: 0,
            SubTransactionsIsLate: false,
            SubTransactionsState: 0,
            Transaction: null,
            TransactionType: 'BA1: Voorbereidingstransactie die door sendbefore de goedkeuring tegenhoudt',
            TransactionTypeSID: 'tra_13_2',
            UnreadSubTransactions: false,
            VerzendError: null,
            VerzendStatus: 0,
        },
        {
            Attachments: false,
            CansetReady: false,
            ConceptID: 0,
            DateTimeSend: '2021-01-04T18:06:09',
            Executor: '1a',
            GemachtigdNamens: 0,
            Generated: false,
            Geometry: { X: 0.0, Y: 0.0 },
            ID: 380875,
            IdColumn: null,
            Initiator: '1',
            IsLate: false,
            IsLeaf: false,
            IsRevoked: false,
            Name: 'a',
            Number: '10943',
            Parent: '',
            ParentId: 0,
            ReactionPeriod: null,
            Read: true,
            Receiver: '1a',
            Replypossible: true,
            Sender: '1',
            State: 0,
            Status: '',
            SubTransactions: 0,
            SubTransactionsIsLate: false,
            SubTransactionsState: 0,
            Transaction: null,
            TransactionType: 'BA1: Voorbereidingstransactie die door sendbefore de goedkeuring tegenhoudt',
            TransactionTypeSID: 'tra_13_2',
            UnreadSubTransactions: false,
            VerzendError: null,
            VerzendStatus: 0,
        },
        {
            Attachments: false,
            CansetReady: false,
            ConceptID: 0,
            DateTimeSend: '2021-01-04T17:29:34',
            Executor: '1a',
            GemachtigdNamens: 0,
            Generated: false,
            Geometry: { X: 0.0, Y: 0.0 },
            ID: 380870,
            IdColumn: null,
            Initiator: '1',
            IsLate: false,
            IsLeaf: false,
            IsRevoked: false,
            Name: 'a',
            Number: '10938',
            Parent: '',
            ParentId: 0,
            ReactionPeriod: null,
            Read: true,
            Receiver: '1a',
            Replypossible: true,
            Sender: '1',
            State: 0,
            Status: '',
            SubTransactions: 0,
            SubTransactionsIsLate: false,
            SubTransactionsState: 0,
            Transaction: null,
            TransactionType: 'BA1: Voorbereidingstransactie die door sendbefore de goedkeuring tegenhoudt',
            TransactionTypeSID: 'tra_13_2',
            UnreadSubTransactions: false,
            VerzendError: null,
            VerzendStatus: 0,
        },
        {
            Attachments: false,
            CansetReady: false,
            ConceptID: 0,
            DateTimeSend: '2021-01-04T15:25:18',
            Executor: '1a',
            GemachtigdNamens: 0,
            Generated: false,
            Geometry: { X: 0.0, Y: 0.0 },
            ID: 380860,
            IdColumn: null,
            Initiator: '1',
            IsLate: false,
            IsLeaf: false,
            IsRevoked: false,
            Name: 'a',
            Number: '10935',
            Parent: '',
            ParentId: 0,
            ReactionPeriod: null,
            Read: true,
            Receiver: '1a',
            Replypossible: true,
            Sender: '1',
            State: 0,
            Status: '',
            SubTransactions: 0,
            SubTransactionsIsLate: false,
            SubTransactionsState: 0,
            Transaction: null,
            TransactionType: 'BA1: Voorbereidingstransactie die door sendbefore de goedkeuring tegenhoudt',
            TransactionTypeSID: 'tra_13_2',
            UnreadSubTransactions: false,
            VerzendError: null,
            VerzendStatus: 0,
        },
        {
            Attachments: false,
            CansetReady: false,
            ConceptID: 0,
            DateTimeSend: '2021-01-04T14:51:15',
            Executor: '1a',
            GemachtigdNamens: 0,
            Generated: false,
            Geometry: { X: 0.0, Y: 0.0 },
            ID: 380851,
            IdColumn: null,
            Initiator: '1',
            IsLate: false,
            IsLeaf: false,
            IsRevoked: false,
            Name: 'a',
            Number: '10931',
            Parent: '',
            ParentId: 0,
            ReactionPeriod: null,
            Read: true,
            Receiver: '1a',
            Replypossible: true,
            Sender: '1',
            State: 0,
            Status: '',
            SubTransactions: 0,
            SubTransactionsIsLate: false,
            SubTransactionsState: 0,
            Transaction: null,
            TransactionType: 'BA1: Voorbereidingstransactie die door sendbefore de goedkeuring tegenhoudt',
            TransactionTypeSID: 'tra_13_2',
            UnreadSubTransactions: false,
            VerzendError: null,
            VerzendStatus: 0,
        },
        {
            Attachments: false,
            CansetReady: false,
            ConceptID: 0,
            DateTimeSend: '2021-01-04T14:48:49',
            Executor: '1a',
            GemachtigdNamens: 0,
            Generated: false,
            Geometry: { X: 0.0, Y: 0.0 },
            ID: 380850,
            IdColumn: null,
            Initiator: '1',
            IsLate: false,
            IsLeaf: false,
            IsRevoked: false,
            Name: 'a',
            Number: '10930',
            Parent: '',
            ParentId: 0,
            ReactionPeriod: null,
            Read: true,
            Receiver: '1a',
            Replypossible: true,
            Sender: '1',
            State: 0,
            Status: '',
            SubTransactions: 0,
            SubTransactionsIsLate: false,
            SubTransactionsState: 0,
            Transaction: null,
            TransactionType: 'BA1: Voorbereidingstransactie die door sendbefore de goedkeuring tegenhoudt',
            TransactionTypeSID: 'tra_13_2',
            UnreadSubTransactions: false,
            VerzendError: null,
            VerzendStatus: 0,
        },
        {
            Attachments: false,
            CansetReady: false,
            ConceptID: 0,
            DateTimeSend: '2021-01-04T14:37:48',
            Executor: '1a',
            GemachtigdNamens: 0,
            Generated: false,
            Geometry: { X: 0.0, Y: 0.0 },
            ID: 380849,
            IdColumn: null,
            Initiator: '1',
            IsLate: false,
            IsLeaf: false,
            IsRevoked: false,
            Name: 'a',
            Number: '10929',
            Parent: '',
            ParentId: 0,
            ReactionPeriod: null,
            Read: true,
            Receiver: '1a',
            Replypossible: true,
            Sender: '1',
            State: 0,
            Status: '',
            SubTransactions: 0,
            SubTransactionsIsLate: false,
            SubTransactionsState: 0,
            Transaction: null,
            TransactionType: 'BA1: Voorbereidingstransactie die door sendbefore de goedkeuring tegenhoudt',
            TransactionTypeSID: 'tra_13_2',
            UnreadSubTransactions: false,
            VerzendError: null,
            VerzendStatus: 0,
        },
        {
            Attachments: false,
            CansetReady: false,
            ConceptID: 0,
            DateTimeSend: '2020-12-29T13:32:59',
            Executor: '1a',
            GemachtigdNamens: 0,
            Generated: false,
            Geometry: { X: 0.0, Y: 0.0 },
            ID: 380338,
            IdColumn: null,
            Initiator: '1',
            IsLate: false,
            IsLeaf: false,
            IsRevoked: false,
            Name: 'a',
            Number: '10923',
            Parent: '',
            ParentId: 0,
            ReactionPeriod: null,
            Read: true,
            Receiver: '1a',
            Replypossible: true,
            Sender: '1',
            State: 0,
            Status: '',
            SubTransactions: 0,
            SubTransactionsIsLate: false,
            SubTransactionsState: 0,
            Transaction: null,
            TransactionType: 'BA1: Voorbereidingstransactie die door sendbefore de goedkeuring tegenhoudt',
            TransactionTypeSID: 'tra_13_2',
            UnreadSubTransactions: false,
            VerzendError: null,
            VerzendStatus: 0,
        },
        {
            Attachments: false,
            CansetReady: false,
            ConceptID: 0,
            DateTimeSend: '2020-12-29T13:18:16',
            Executor: '2a',
            GemachtigdNamens: 0,
            Generated: false,
            Geometry: { X: 0.0, Y: 0.0 },
            ID: 380336,
            IdColumn: null,
            Initiator: '1',
            IsLate: false,
            IsLeaf: false,
            IsRevoked: false,
            Name: 'a',
            Number: '10922',
            Parent: '',
            ParentId: 0,
            ReactionPeriod: null,
            Read: true,
            Receiver: '2a',
            Replypossible: true,
            Sender: '1',
            State: 0,
            Status: '',
            SubTransactions: 0,
            SubTransactionsIsLate: false,
            SubTransactionsState: 0,
            Transaction: null,
            TransactionType: 'BA1: Voorbereidingstransactie die door sendbefore de goedkeuring tegenhoudt',
            TransactionTypeSID: 'tra_13_2',
            UnreadSubTransactions: false,
            VerzendError: null,
            VerzendStatus: 0,
        },
    ],
    TransactionsPerPage: 50,
};

export const expand = (parentID) => [
    {
        Attachments: true,
        CansetReady: false,
        ConceptID: 0,
        DateTimeSend: '2021-05-31T11:56:15.846469',
        Executor: '2',
        GemachtigdNamens: 0,
        Generated: false,
        Geometry: { X: 0.0, Y: 0.0 },
        ID: 674331,
        IdColumn: null,
        Initiator: '1',
        IsLate: false,
        IsLeaf: true,
        IsRevoked: false,
        Name: 'WF7 T1: Goedkeuring uitwerking hoofdtransactie',
        Number: null,
        Parent: parentID.toString(),
        ParentId: parentID,
        ReactionPeriod: null,
        Read: true,
        Receiver: '2',
        Replypossible: true,
        Sender: '1',
        State: 0,
        Status: '',
        SubTransactions: 0,
        SubTransactionsIsLate: false,
        SubTransactionsState: 0,
        Transaction: null,
        TransactionType: null,
        TransactionTypeSID: null,
        UnreadSubTransactions: false,
        VerzendError: null,
        VerzendStatus: 0,
    },
    {
        Attachments: false,
        CansetReady: false,
        ConceptID: 0,
        DateTimeSend: '2020-05-07T14:56:17',
        Executor: '2',
        GemachtigdNamens: 0,
        Generated: false,
        Geometry: { X: 0.0, Y: 0.0 },
        ID: 473519,
        IdColumn: null,
        Initiator: '1',
        IsLate: false,
        IsLeaf: true,
        IsRevoked: false,
        Name: 'WF7 T1: Indiening uitwerking hoofdtransactie',
        Number: null,
        Parent: parentID.toString(),
        ParentId: parentID,
        ReactionPeriod: null,
        Read: true,
        Receiver: '1',
        Replypossible: true,
        Sender: '2',
        State: 0,
        Status: '',
        SubTransactions: 0,
        SubTransactionsIsLate: false,
        SubTransactionsState: 0,
        Transaction: null,
        TransactionType: null,
        TransactionTypeSID: null,
        UnreadSubTransactions: false,
        VerzendError: null,
        VerzendStatus: 0,
    },
    {
        Attachments: true,
        CansetReady: false,
        ConceptID: 0,
        DateTimeSend: '2020-05-05T12:35:02',
        Executor: '2',
        GemachtigdNamens: 0,
        Generated: false,
        Geometry: { X: 0.0, Y: 0.0 },
        ID: 473115,
        IdColumn: null,
        Initiator: '1',
        IsLate: false,
        IsLeaf: true,
        IsRevoked: false,
        Name: 'WF7 T1: Verzoek tot indienen uitwerking',
        Number: null,
        Parent: parentID.toString(),
        ParentId: parentID,
        ReactionPeriod: null,
        Read: true,
        Receiver: '2',
        Replypossible: true,
        Sender: '1',
        State: 0,
        Status: '',
        SubTransactions: 0,
        SubTransactionsIsLate: false,
        SubTransactionsState: 0,
        Transaction: null,
        TransactionType: null,
        TransactionTypeSID: null,
        UnreadSubTransactions: false,
        VerzendError: null,
        VerzendStatus: 0,
    },
];

export const records = [
    {
        Attachments: false,
        CansetReady: false,
        ConceptID: 0,
        DateTimeSend: '2015-10-20T15:28:17',
        Executor: 'software_aanpassing_beslisser',
        GemachtigdNamens: 0,
        Generated: false,
        Geometry: {
            X: 0.0,
            Y: 0.0,
        },
        ID: 79189,
        IdColumn: null,
        Initiator: 'Maarten Tromp',
        IsLate: false,
        IsLeaf: false,
        IsRevoked: false,
        Name: 'Open - not read - not late',
        Number: '74150',
        Parent: '',
        ParentId: 0,
        ReactionPeriod: '2015-09-24T09:01:39',
        Read: false,
        Receiver: 'Maarten Tromp',
        Replypossible: true,
        Sender: 'Arne Bruinse',
        State: 0,
        Status: '',
        SubTransactions: 0,
        SubTransactionsIsLate: false,
        SubTransactionsState: 0,
        Transaction: null,
        TransactionType: 'Software aanpassing verzoek',
        TransactionTypeSID: 'Tr_Software_aanpassing_verzoek',
        UnreadSubTransactions: false,
        VerzendError: null,
        VerzendStatus: 0,
    },
    {
        Attachments: false,
        CansetReady: false,
        ConceptID: 0,
        DateTimeSend: '2015-09-24T09:01:39',
        Executor: 'software_aanpassing_beslisser',
        GemachtigdNamens: 0,
        Generated: false,
        Geometry: {
            X: 0.0,
            Y: 0.0,
        },
        ID: 77635,
        IdColumn: null,
        Initiator: 'Elwin de Gooijer',
        IsLate: false,
        IsLeaf: false,
        IsRevoked: false,
        Name: 'Closed - not read - not late',
        Number: '72704',
        Parent: '',
        ParentId: 0,
        ReactionPeriod: '2015-09-24T09:01:39',
        Read: false,
        Receiver: 'Elwin de Gooijer',
        Replypossible: true,
        Sender: 'Michiel Banus',
        State: 1,
        Status: '',
        SubTransactions: 0,
        SubTransactionsIsLate: false,
        SubTransactionsState: 0,
        Transaction: null,
        TransactionType: 'Software aanpassing verzoek',
        TransactionTypeSID: 'Tr_Software_aanpassing_verzoek',
        UnreadSubTransactions: false,
        VerzendError: null,
        VerzendStatus: 0,
    },
    {
        Attachments: false,
        CansetReady: false,
        ConceptID: 0,
        DateTimeSend: '2015-09-23T14:33:55',
        Executor: 'software_aanpassing_beslisser',
        GemachtigdNamens: 0,
        Generated: false,
        Geometry: {
            X: 0.0,
            Y: 0.0,
        },
        ID: 77554,
        IdColumn: null,
        Initiator: 'Michiel Banus',
        IsLate: false,
        IsLeaf: false,
        IsRevoked: false,
        Name: 'Closed not ready - not read - not late',
        Number: '72629',
        Parent: '',
        ParentId: 0,
        ReactionPeriod: '2015-09-24T09:01:39',
        Read: false,
        Receiver: 'Michiel Banus',
        Replypossible: true,
        Sender: 'Arne Bruinse',
        State: 2,
        Status: '',
        SubTransactions: 0,
        SubTransactionsIsLate: false,
        SubTransactionsState: 0,
        Transaction: null,
        TransactionType: 'Software aanpassing verzoek',
        TransactionTypeSID: 'Tr_Software_aanpassing_verzoek',
        UnreadSubTransactions: false,
        VerzendError: null,
        VerzendStatus: 0,
    },
    {
        Attachments: false,
        CansetReady: false,
        ConceptID: 0,
        DateTimeSend: '2015-09-23T11:48:06',
        Executor: 'software_aanpassing_beslisser',
        GemachtigdNamens: 0,
        Generated: false,
        Geometry: {
            X: 0.0,
            Y: 0.0,
        },
        ID: 77404,
        IdColumn: null,
        Initiator: 'Olaf Kok',
        IsLate: true,
        IsLeaf: false,
        IsRevoked: false,
        Name: 'Open - Late',
        Number: '72489',
        Parent: '',
        ParentId: 0,
        ReactionPeriod: '2015-09-24T09:01:39',
        Read: true,
        Receiver: 'Olaf Kok',
        Replypossible: true,
        Sender: 'Arne Bruinse',
        State: 0,
        Status: '',
        SubTransactions: 0,
        SubTransactionsIsLate: false,
        SubTransactionsState: 0,
        Transaction: null,
        TransactionType: 'Software aanpassing verzoek',
        TransactionTypeSID: 'Tr_Software_aanpassing_verzoek',
        UnreadSubTransactions: false,
        VerzendError: null,
        VerzendStatus: 0,
    },
    {
        Attachments: false,
        CansetReady: false,
        ConceptID: 0,
        DateTimeSend: '2015-09-23T11:48:06',
        Executor: 'software_aanpassing_beslisser',
        GemachtigdNamens: 0,
        Generated: false,
        Geometry: {
            X: 0.0,
            Y: 0.0,
        },
        ID: 97404,
        IdColumn: null,
        Initiator: 'Olaf Kok',
        IsLate: true,
        IsLeaf: false,
        IsRevoked: false,
        Name: 'Open - Late - Not read',
        Number: '72489',
        Parent: '',
        ParentId: 0,
        ReactionPeriod: '2015-09-24T09:01:39',
        Read: false,
        Receiver: 'Olaf Kok',
        Replypossible: true,
        Sender: 'Arne Bruinse',
        State: 0,
        Status: '',
        SubTransactions: 0,
        SubTransactionsIsLate: false,
        SubTransactionsState: 0,
        Transaction: null,
        TransactionType: 'Software aanpassing verzoek',
        TransactionTypeSID: 'Tr_Software_aanpassing_verzoek',
        UnreadSubTransactions: false,
        VerzendError: null,
        VerzendStatus: 0,
    },
    {
        Attachments: false,
        CansetReady: false,
        ConceptID: 0,
        DateTimeSend: '2015-09-22T14:12:23',
        Executor: 'software_aanpassing_beslisser',
        GemachtigdNamens: 0,
        Generated: false,
        Geometry: {
            X: 0.0,
            Y: 0.0,
        },
        ID: 77536,
        IdColumn: null,
        Initiator: 'Elwin de Gooijer',
        IsLate: true,
        IsLeaf: false,
        IsRevoked: false,
        Name: 'Closed - Late',
        Number: '72612',
        Parent: '',
        ParentId: 0,
        ReactionPeriod: '2015-09-24T09:01:39',
        Read: true,
        Receiver: 'Elwin de Gooijer',
        Replypossible: true,
        Sender: 'Michiel Banus',
        State: 1,
        Status: '',
        SubTransactions: 0,
        SubTransactionsIsLate: false,
        SubTransactionsState: 0,
        Transaction: null,
        TransactionType: 'Software aanpassing verzoek',
        TransactionTypeSID: 'Tr_Software_aanpassing_verzoek',
        UnreadSubTransactions: false,
        VerzendError: null,
        VerzendStatus: 0,
    },
    {
        Attachments: false,
        CansetReady: false,
        ConceptID: 0,
        DateTimeSend: '2015-09-18T11:30:02',
        Executor: 'software_aanpassing_beslisser',
        GemachtigdNamens: 0,
        Generated: false,
        Geometry: {
            X: 0.0,
            Y: 0.0,
        },
        ID: 77180,
        IdColumn: null,
        Initiator: 'Bjorn Vermazen',
        IsLate: true,
        IsLeaf: false,
        IsRevoked: false,
        Name: 'Closed not ready - late',
        Number: '72277',
        Parent: '',
        ParentId: 0,
        ReactionPeriod: '2015-09-24T09:01:39',
        Read: true,
        Receiver: 'Bjorn Vermazen',
        Replypossible: true,
        Sender: 'Michiel Banus',
        State: 2,
        Status: '',
        SubTransactions: 0,
        SubTransactionsIsLate: false,
        SubTransactionsState: 0,
        Transaction: null,
        TransactionType: 'Software aanpassing verzoek',
        TransactionTypeSID: 'Tr_Software_aanpassing_verzoek',
        UnreadSubTransactions: false,
        VerzendError: null,
        VerzendStatus: 0,
    },
    {
        Attachments: false,
        CansetReady: false,
        ConceptID: 77229,
        DateTimeSend: '2015-09-18T11:28:25',
        Executor: 'software_aanpassing_beslisser',
        GemachtigdNamens: 0,
        Generated: false,
        Geometry: {
            X: 0.0,
            Y: 0.0,
        },
        ID: -1,
        IdColumn: null,
        Initiator: 'Rik Bansberg',
        IsLate: false,
        IsLeaf: false,
        IsRevoked: false,
        Name: 'Concept',
        Number: '72325',
        Parent: '',
        ParentId: 0,
        ReactionPeriod: '2015-09-24T09:01:39',
        Read: true,
        Receiver: 'Rik Bansberg',
        Replypossible: true,
        Sender: 'Michiel Banus',
        State: 0,
        Status: '',
        SubTransactions: 0,
        SubTransactionsIsLate: false,
        SubTransactionsState: 0,
        Transaction: null,
        TransactionType: 'Software aanpassing verzoek',
        TransactionTypeSID: 'Tr_Software_aanpassing_verzoek',
        UnreadSubTransactions: false,
        VerzendError: null,
        VerzendStatus: 0,
    },
    {
        Attachments: false,
        CansetReady: false,
        ConceptID: 0,
        DateTimeSend: '2015-09-09T14:09:59',
        Executor: 'software_aanpassing_beslisser',
        GemachtigdNamens: 0,
        Generated: false,
        Geometry: {
            X: 0.0,
            Y: 0.0,
        },
        ID: 75328,
        IdColumn: null,
        Initiator: 'Hanna Stoop',
        IsLate: false,
        IsLeaf: false,
        IsRevoked: false,
        Name: 'Verzoek 682',
        Number: '70578',
        Parent: '',
        ParentId: 0,
        ReactionPeriod: '2015-09-24T09:01:39',
        Read: true,
        Receiver: 'Hanna Stoop',
        Replypossible: true,
        Sender: 'Michiel Banus',
        State: 0,
        Status: '',
        SubTransactions: 0,
        SubTransactionsIsLate: false,
        SubTransactionsState: 0,
        Transaction: null,
        TransactionType: 'Software aanpassing verzoek',
        TransactionTypeSID: 'Tr_Software_aanpassing_verzoek',
        UnreadSubTransactions: false,
        VerzendError: null,
        VerzendStatus: 0,
    },
    {
        Attachments: false,
        CansetReady: false,
        ConceptID: 0,
        DateTimeSend: '2015-01-30T17:16:15',
        Executor: 'software_aanpassing_beslisser',
        GemachtigdNamens: 0,
        Generated: false,
        Geometry: {
            X: 0.0,
            Y: 0.0,
        },
        ID: 69496,
        IdColumn: null,
        Initiator: 'Joyce van Soest',
        IsLate: false,
        IsLeaf: false,
        IsRevoked: false,
        Name: "Bij verwijderen SE mogelijkheid om in één keer uit de CE's te halen",
        Number: '65096',
        Parent: '',
        ParentId: 0,
        ReactionPeriod: '2015-09-24T09:01:39',
        Read: true,
        Receiver: 'Joyce van Soest',
        Replypossible: true,
        Sender: 'Arne Bruinse',
        State: 0,
        Status: '',
        SubTransactions: 0,
        SubTransactionsIsLate: false,
        SubTransactionsState: 0,
        Transaction: null,
        TransactionType: 'Software aanpassing verzoek',
        TransactionTypeSID: 'Tr_Software_aanpassing_verzoek',
        UnreadSubTransactions: false,
        VerzendError: null,
        VerzendStatus: 0,
    },
    {
        Attachments: false,
        CansetReady: false,
        ConceptID: 0,
        DateTimeSend: '2015-01-12T16:40:55',
        Executor: 'Rick Cardol',
        GemachtigdNamens: 0,
        Generated: false,
        Geometry: {
            X: 0.0,
            Y: 0.0,
        },
        ID: 69001,
        IdColumn: null,
        Initiator: 'software_aanpassing_beslisser',
        IsLate: false,
        IsLeaf: false,
        IsRevoked: false,
        Name: 'bij 1.3 en hoger raamwerken checken of de raamwerk namespace uniek is',
        Number: '64653',
        Parent: '',
        ParentId: 0,
        ReactionPeriod: '2015-09-24T09:01:39',
        Read: true,
        Receiver: 'Rick Cardol',
        Replypossible: true,
        Sender: 'software_aanpassing_beslisser',
        State: 0,
        Status: '',
        SubTransactions: 0,
        SubTransactionsIsLate: false,
        SubTransactionsState: 0,
        Transaction: null,
        TransactionType: 'Software aanpassing opdracht',
        TransactionTypeSID: 'Tr_Software_aanpassing_opdracht',
        UnreadSubTransactions: false,
        VerzendError: null,
        VerzendStatus: 0,
    },
    {
        Attachments: false,
        CansetReady: false,
        ConceptID: 0,
        DateTimeSend: '2014-12-09T14:14:33',
        Executor: 'Rick Cardol',
        GemachtigdNamens: 0,
        Generated: false,
        Geometry: {
            X: 0.0,
            Y: 0.0,
        },
        ID: 68142,
        IdColumn: null,
        Initiator: 'software_aanpassing_beslisser',
        IsLate: false,
        IsLeaf: false,
        IsRevoked: false,
        Name: 'crm offerte genereren met lua script naar ODT van Arne/Annebe',
        Number: '63881',
        Parent: '',
        ParentId: 0,
        ReactionPeriod: '2015-09-24T09:01:39',
        Read: true,
        Receiver: 'Rick Cardol',
        Replypossible: true,
        Sender: 'software_aanpassing_beslisser',
        State: 0,
        Status: '',
        SubTransactions: 0,
        SubTransactionsIsLate: false,
        SubTransactionsState: 0,
        Transaction: null,
        TransactionType: 'Software aanpassing opdracht',
        TransactionTypeSID: 'Tr_Software_aanpassing_opdracht',
        UnreadSubTransactions: false,
        VerzendError: null,
        VerzendStatus: 0,
    },
    {
        Attachments: false,
        CansetReady: false,
        ConceptID: 0,
        DateTimeSend: '2014-11-27T00:00:00',
        Executor: 'Michiel Banus',
        GemachtigdNamens: 0,
        Generated: false,
        Geometry: {
            X: 0.0,
            Y: 0.0,
        },
        ID: 67625,
        IdColumn: null,
        Initiator: 'software_aanpassing_beslisser',
        IsLate: false,
        IsLeaf: false,
        IsRevoked: false,
        Name: 'Refresh van de aaneemsom gaat niet altijd goed',
        Number: '62827-4',
        Parent: '',
        ParentId: 0,
        ReactionPeriod: '2015-09-24T09:01:39',
        Read: true,
        Receiver: 'Michiel Banus',
        Replypossible: true,
        Sender: 'software_aanpassing_beslisser',
        State: 0,
        Status: '',
        SubTransactions: 0,
        SubTransactionsIsLate: false,
        SubTransactionsState: 0,
        Transaction: null,
        TransactionType: 'Software aanpassing test',
        TransactionTypeSID: 'Tr_Software_aanpassing_test',
        UnreadSubTransactions: false,
        VerzendError: null,
        VerzendStatus: 0,
    },
    {
        Attachments: false,
        CansetReady: false,
        ConceptID: 0,
        DateTimeSend: '2014-11-12T16:21:24',
        Executor: 'Rick Cardol',
        GemachtigdNamens: 0,
        Generated: false,
        Geometry: {
            X: 0.0,
            Y: 0.0,
        },
        ID: 67027,
        IdColumn: null,
        Initiator: 'software_aanpassing_beslisser',
        IsLate: false,
        IsLeaf: false,
        IsRevoked: false,
        Name: 'Fout: vensters schikken gaat niet goed',
        Number: '62194-2',
        Parent: '',
        ParentId: 0,
        ReactionPeriod: '2015-09-24T09:01:39',
        Read: true,
        Receiver: 'Rick Cardol',
        Replypossible: true,
        Sender: 'software_aanpassing_beslisser',
        State: 0,
        Status: '',
        SubTransactions: 0,
        SubTransactionsIsLate: false,
        SubTransactionsState: 0,
        Transaction: null,
        TransactionType: 'Software aanpassing opdracht',
        TransactionTypeSID: 'Tr_Software_aanpassing_opdracht',
        UnreadSubTransactions: false,
        VerzendError: null,
        VerzendStatus: 0,
    },
    {
        Attachments: false,
        CansetReady: false,
        ConceptID: 0,
        DateTimeSend: '2015-10-20T15:28:17',
        Executor: 'software_aanpassing_beslisser',
        GemachtigdNamens: 0,
        Generated: false,
        Geometry: {
            X: 0.0,
            Y: 0.0,
        },
        ID: 179189,
        IdColumn: null,
        Initiator: 'Maarten Tromp',
        IsLate: false,
        IsLeaf: false,
        IsRevoked: false,
        Name: 'Open - not read - not late',
        Number: '74150',
        Parent: '',
        ParentId: 0,
        ReactionPeriod: '2015-09-24T09:01:39',
        Read: false,
        Receiver: 'Maarten Tromp',
        Replypossible: true,
        Sender: 'Arne Bruinse',
        State: 0,
        Status: '',
        SubTransactions: 0,
        SubTransactionsIsLate: false,
        SubTransactionsState: 0,
        Transaction: null,
        TransactionType: 'Software aanpassing verzoek',
        TransactionTypeSID: 'Tr_Software_aanpassing_verzoek',
        UnreadSubTransactions: false,
        VerzendError: null,
        VerzendStatus: 0,
    },
    {
        Attachments: false,
        CansetReady: false,
        ConceptID: 0,
        DateTimeSend: '2015-09-24T09:01:39',
        Executor: 'software_aanpassing_beslisser',
        GemachtigdNamens: 0,
        Generated: false,
        Geometry: {
            X: 0.0,
            Y: 0.0,
        },
        ID: 177635,
        IdColumn: null,
        Initiator: 'Elwin de Gooijer',
        IsLate: false,
        IsLeaf: false,
        IsRevoked: false,
        Name: 'Closed - not read - not late',
        Number: '72704',
        Parent: '',
        ParentId: 0,
        ReactionPeriod: '2015-09-24T09:01:39',
        Read: false,
        Receiver: 'Elwin de Gooijer',
        Replypossible: true,
        Sender: 'Michiel Banus',
        State: 1,
        Status: '',
        SubTransactions: 0,
        SubTransactionsIsLate: false,
        SubTransactionsState: 0,
        Transaction: null,
        TransactionType: 'Software aanpassing verzoek',
        TransactionTypeSID: 'Tr_Software_aanpassing_verzoek',
        UnreadSubTransactions: false,
        VerzendError: null,
        VerzendStatus: 0,
    },
    {
        Attachments: false,
        CansetReady: false,
        ConceptID: 0,
        DateTimeSend: '2015-09-23T14:33:55',
        Executor: 'software_aanpassing_beslisser',
        GemachtigdNamens: 0,
        Generated: false,
        Geometry: {
            X: 0.0,
            Y: 0.0,
        },
        ID: 177554,
        IdColumn: null,
        Initiator: 'Michiel Banus',
        IsLate: false,
        IsLeaf: false,
        IsRevoked: false,
        Name: 'Closed not ready - not read - not late',
        Number: '72629',
        Parent: '',
        ParentId: 0,
        ReactionPeriod: '2015-09-24T09:01:39',
        Read: false,
        Receiver: 'Michiel Banus',
        Replypossible: true,
        Sender: 'Arne Bruinse',
        State: 2,
        Status: '',
        SubTransactions: 0,
        SubTransactionsIsLate: false,
        SubTransactionsState: 0,
        Transaction: null,
        TransactionType: 'Software aanpassing verzoek',
        TransactionTypeSID: 'Tr_Software_aanpassing_verzoek',
        UnreadSubTransactions: false,
        VerzendError: null,
        VerzendStatus: 0,
    },
    {
        Attachments: false,
        CansetReady: false,
        ConceptID: 0,
        DateTimeSend: '2015-09-23T11:48:06',
        Executor: 'software_aanpassing_beslisser',
        GemachtigdNamens: 0,
        Generated: false,
        Geometry: {
            X: 0.0,
            Y: 0.0,
        },
        ID: 177404,
        IdColumn: null,
        Initiator: 'Olaf Kok',
        IsLate: true,
        IsLeaf: false,
        IsRevoked: false,
        Name: 'Open - Late',
        Number: '72489',
        Parent: '',
        ParentId: 0,
        ReactionPeriod: '2015-09-24T09:01:39',
        Read: true,
        Receiver: 'Olaf Kok',
        Replypossible: true,
        Sender: 'Arne Bruinse',
        State: 0,
        Status: '',
        SubTransactions: 0,
        SubTransactionsIsLate: false,
        SubTransactionsState: 0,
        Transaction: null,
        TransactionType: 'Software aanpassing verzoek',
        TransactionTypeSID: 'Tr_Software_aanpassing_verzoek',
        UnreadSubTransactions: false,
        VerzendError: null,
        VerzendStatus: 0,
    },
    {
        Attachments: false,
        CansetReady: false,
        ConceptID: 0,
        DateTimeSend: '2015-09-23T11:48:06',
        Executor: 'software_aanpassing_beslisser',
        GemachtigdNamens: 0,
        Generated: false,
        Geometry: {
            X: 0.0,
            Y: 0.0,
        },
        ID: 197404,
        IdColumn: null,
        Initiator: 'Olaf Kok',
        IsLate: true,
        IsLeaf: false,
        IsRevoked: false,
        Name: 'Open - Late - Not read',
        Number: '72489',
        Parent: '',
        ParentId: 0,
        ReactionPeriod: '2015-09-24T09:01:39',
        Read: false,
        Receiver: 'Olaf Kok',
        Replypossible: true,
        Sender: 'Arne Bruinse',
        State: 0,
        Status: '',
        SubTransactions: 0,
        SubTransactionsIsLate: false,
        SubTransactionsState: 0,
        Transaction: null,
        TransactionType: 'Software aanpassing verzoek',
        TransactionTypeSID: 'Tr_Software_aanpassing_verzoek',
        UnreadSubTransactions: false,
        VerzendError: null,
        VerzendStatus: 0,
    },
    {
        Attachments: false,
        CansetReady: false,
        ConceptID: 0,
        DateTimeSend: '2015-09-22T14:12:23',
        Executor: 'software_aanpassing_beslisser',
        GemachtigdNamens: 0,
        Generated: false,
        Geometry: {
            X: 0.0,
            Y: 0.0,
        },
        ID: 177536,
        IdColumn: null,
        Initiator: 'Elwin de Gooijer',
        IsLate: true,
        IsLeaf: false,
        IsRevoked: false,
        Name: 'Closed - Late',
        Number: '72612',
        Parent: '',
        ParentId: 0,
        ReactionPeriod: '2015-09-24T09:01:39',
        Read: true,
        Receiver: 'Elwin de Gooijer',
        Replypossible: true,
        Sender: 'Michiel Banus',
        State: 1,
        Status: '',
        SubTransactions: 0,
        SubTransactionsIsLate: false,
        SubTransactionsState: 0,
        Transaction: null,
        TransactionType: 'Software aanpassing verzoek',
        TransactionTypeSID: 'Tr_Software_aanpassing_verzoek',
        UnreadSubTransactions: false,
        VerzendError: null,
        VerzendStatus: 0,
    },
    {
        Attachments: false,
        CansetReady: false,
        ConceptID: 0,
        DateTimeSend: '2015-09-18T11:30:02',
        Executor: 'software_aanpassing_beslisser',
        GemachtigdNamens: 0,
        Generated: false,
        Geometry: {
            X: 0.0,
            Y: 0.0,
        },
        ID: 177180,
        IdColumn: null,
        Initiator: 'Bjorn Vermazen',
        IsLate: true,
        IsLeaf: false,
        IsRevoked: false,
        Name: 'Closed not ready - late',
        Number: '72277',
        Parent: '',
        ParentId: 0,
        ReactionPeriod: '2015-09-24T09:01:39',
        Read: true,
        Receiver: 'Bjorn Vermazen',
        Replypossible: true,
        Sender: 'Michiel Banus',
        State: 2,
        Status: '',
        SubTransactions: 0,
        SubTransactionsIsLate: false,
        SubTransactionsState: 0,
        Transaction: null,
        TransactionType: 'Software aanpassing verzoek',
        TransactionTypeSID: 'Tr_Software_aanpassing_verzoek',
        UnreadSubTransactions: false,
        VerzendError: null,
        VerzendStatus: 0,
    },
    {
        Attachments: false,
        CansetReady: false,
        ConceptID: 0,
        DateTimeSend: '2015-09-18T11:28:25',
        Executor: 'software_aanpassing_beslisser',
        GemachtigdNamens: 0,
        Generated: false,
        Geometry: {
            X: 0.0,
            Y: 0.0,
        },
        ID: 177229,
        IdColumn: null,
        Initiator: 'Rik Bansberg',
        IsLate: false,
        IsLeaf: false,
        IsRevoked: false,
        Name: 'Verzoek 735',
        Number: '72325',
        Parent: '',
        ParentId: 0,
        ReactionPeriod: '2015-09-24T09:01:39',
        Read: true,
        Receiver: 'Rik Bansberg',
        Replypossible: true,
        Sender: 'Michiel Banus',
        State: 0,
        Status: '',
        SubTransactions: 0,
        SubTransactionsIsLate: false,
        SubTransactionsState: 0,
        Transaction: null,
        TransactionType: 'Software aanpassing verzoek',
        TransactionTypeSID: 'Tr_Software_aanpassing_verzoek',
        UnreadSubTransactions: false,
        VerzendError: null,
        VerzendStatus: 0,
    },
    {
        Attachments: false,
        CansetReady: false,
        ConceptID: 0,
        DateTimeSend: '2015-09-09T14:09:59',
        Executor: 'software_aanpassing_beslisser',
        GemachtigdNamens: 0,
        Generated: false,
        Geometry: {
            X: 0.0,
            Y: 0.0,
        },
        ID: 175328,
        IdColumn: null,
        Initiator: 'Hanna Stoop',
        IsLate: false,
        IsLeaf: false,
        IsRevoked: false,
        Name: 'Verzoek 682',
        Number: '70578',
        Parent: '',
        ParentId: 0,
        ReactionPeriod: '2015-09-24T09:01:39',
        Read: true,
        Receiver: 'Hanna Stoop',
        Replypossible: true,
        Sender: 'Michiel Banus',
        State: 0,
        Status: '',
        SubTransactions: 0,
        SubTransactionsIsLate: false,
        SubTransactionsState: 0,
        Transaction: null,
        TransactionType: 'Software aanpassing verzoek',
        TransactionTypeSID: 'Tr_Software_aanpassing_verzoek',
        UnreadSubTransactions: false,
        VerzendError: null,
        VerzendStatus: 0,
    },
    {
        Attachments: false,
        CansetReady: false,
        ConceptID: 0,
        DateTimeSend: '2015-01-30T17:16:15',
        Executor: 'software_aanpassing_beslisser',
        GemachtigdNamens: 0,
        Generated: false,
        Geometry: {
            X: 0.0,
            Y: 0.0,
        },
        ID: 169496,
        IdColumn: null,
        Initiator: 'Joyce van Soest',
        IsLate: false,
        IsLeaf: false,
        IsRevoked: false,
        Name: "Bij verwijderen SE mogelijkheid om in één keer uit de CE's te halen",
        Number: '65096',
        Parent: '',
        ParentId: 0,
        ReactionPeriod: '2015-09-24T09:01:39',
        Read: true,
        Receiver: 'Joyce van Soest',
        Replypossible: true,
        Sender: 'Arne Bruinse',
        State: 0,
        Status: '',
        SubTransactions: 0,
        SubTransactionsIsLate: false,
        SubTransactionsState: 0,
        Transaction: null,
        TransactionType: 'Software aanpassing verzoek',
        TransactionTypeSID: 'Tr_Software_aanpassing_verzoek',
        UnreadSubTransactions: false,
        VerzendError: null,
        VerzendStatus: 0,
    },
    {
        Attachments: false,
        CansetReady: false,
        ConceptID: 0,
        DateTimeSend: '2015-01-12T16:40:55',
        Executor: 'Rick Cardol',
        GemachtigdNamens: 0,
        Generated: false,
        Geometry: {
            X: 0.0,
            Y: 0.0,
        },
        ID: 169001,
        IdColumn: null,
        Initiator: 'software_aanpassing_beslisser',
        IsLate: false,
        IsLeaf: false,
        IsRevoked: false,
        Name: 'bij 1.3 en hoger raamwerken checken of de raamwerk namespace uniek is',
        Number: '64653',
        Parent: '',
        ParentId: 0,
        ReactionPeriod: '2015-09-24T09:01:39',
        Read: true,
        Receiver: 'Rick Cardol',
        Replypossible: true,
        Sender: 'software_aanpassing_beslisser',
        State: 0,
        Status: '',
        SubTransactions: 0,
        SubTransactionsIsLate: false,
        SubTransactionsState: 0,
        Transaction: null,
        TransactionType: 'Software aanpassing opdracht',
        TransactionTypeSID: 'Tr_Software_aanpassing_opdracht',
        UnreadSubTransactions: false,
        VerzendError: null,
        VerzendStatus: 0,
    },
    {
        Attachments: false,
        CansetReady: false,
        ConceptID: 0,
        DateTimeSend: '2014-12-09T14:14:33',
        Executor: 'Rick Cardol',
        GemachtigdNamens: 0,
        Generated: false,
        Geometry: {
            X: 0.0,
            Y: 0.0,
        },
        ID: 168142,
        IdColumn: null,
        Initiator: 'software_aanpassing_beslisser',
        IsLate: false,
        IsLeaf: false,
        IsRevoked: false,
        Name: 'crm offerte genereren met lua script naar ODT van Arne/Annebe',
        Number: '63881',
        Parent: '',
        ParentId: 0,
        ReactionPeriod: '2015-09-24T09:01:39',
        Read: true,
        Receiver: 'Rick Cardol',
        Replypossible: true,
        Sender: 'software_aanpassing_beslisser',
        State: 0,
        Status: '',
        SubTransactions: 0,
        SubTransactionsIsLate: false,
        SubTransactionsState: 0,
        Transaction: null,
        TransactionType: 'Software aanpassing opdracht',
        TransactionTypeSID: 'Tr_Software_aanpassing_opdracht',
        UnreadSubTransactions: false,
        VerzendError: null,
        VerzendStatus: 0,
    },
    {
        Attachments: false,
        CansetReady: false,
        ConceptID: 0,
        DateTimeSend: '2014-11-27T00:00:00',
        Executor: 'Michiel Banus',
        GemachtigdNamens: 0,
        Generated: false,
        Geometry: {
            X: 0.0,
            Y: 0.0,
        },
        ID: 167625,
        IdColumn: null,
        Initiator: 'software_aanpassing_beslisser',
        IsLate: false,
        IsLeaf: false,
        IsRevoked: false,
        Name: 'Refresh van de aaneemsom gaat niet altijd goed',
        Number: '62827-4',
        Parent: '',
        ParentId: 0,
        ReactionPeriod: '2015-09-24T09:01:39',
        Read: true,
        Receiver: 'Michiel Banus',
        Replypossible: true,
        Sender: 'software_aanpassing_beslisser',
        State: 0,
        Status: '',
        SubTransactions: 0,
        SubTransactionsIsLate: false,
        SubTransactionsState: 0,
        Transaction: null,
        TransactionType: 'Software aanpassing test',
        TransactionTypeSID: 'Tr_Software_aanpassing_test',
        UnreadSubTransactions: false,
        VerzendError: null,
        VerzendStatus: 0,
    },
    {
        Attachments: false,
        CansetReady: false,
        ConceptID: 0,
        DateTimeSend: '2014-11-12T16:21:24',
        Executor: 'Rick Cardol',
        GemachtigdNamens: 0,
        Generated: false,
        Geometry: {
            X: 0.0,
            Y: 0.0,
        },
        ID: 167027,
        IdColumn: null,
        Initiator: 'software_aanpassing_beslisser',
        IsLate: false,
        IsLeaf: false,
        IsRevoked: false,
        Name: 'Fout: vensters schikken gaat niet goed',
        Number: '62194-2',
        Parent: '',
        ParentId: 0,
        ReactionPeriod: '2015-09-24T09:01:39',
        Read: true,
        Receiver: 'Rick Cardol',
        Replypossible: true,
        Sender: 'software_aanpassing_beslisser',
        State: 0,
        Status: '',
        SubTransactions: 0,
        SubTransactionsIsLate: false,
        SubTransactionsState: 0,
        Transaction: null,
        TransactionType: 'Software aanpassing opdracht',
        TransactionTypeSID: 'Tr_Software_aanpassing_opdracht',
        UnreadSubTransactions: false,
        VerzendError: null,
        VerzendStatus: 0,
    },
];

export default transactieoverzicht;
