import React, { useCallback, useContext } from 'react';
import Autolinker from 'autolinker';

// CONTEXT
import { AuthContext } from '../../state/context/AuthContext';
import { TabsContext } from '../../state/context/TabsContext';

// UTILS
import { createTabCompleteTransaction } from '../../pages/Home/tabs.utils';

// STYLE
import './CreateLink.scss';

interface DataProps {
    Content: string;
    Intern: boolean;
    Value: string;
}

export const makeURL = function httpHtml(text: string) {
    let link = text;
    try {
        link = Autolinker.link(text ? `${text}` : '');
    } catch (ex) {
        console.log(ex);
    }
    return link;
};

const openInternLink = async (Content: string, Value: string, dispatchTabsState: React.Dispatch<any>, SessionId: string, TokenID: string) => {
    await createTabCompleteTransaction(Value, SessionId, TokenID, dispatchTabsState, false, false, false, Content);
};

const CreateLink: React.FC<DataProps> = ({ Content, Value, Intern }) => {
    const [{ SessionId, TokenID }] = useContext(AuthContext);
    const [, dispatchTabsState] = useContext(TabsContext);

    const handleClick = useCallback(
        (el) => {
            const Content = el.target.innerText;
            const Value = el.target.dataset.value;

            openInternLink(Content, Value, dispatchTabsState, SessionId, TokenID);
        },
        [Content, Intern, SessionId, TokenID, Value]
    );

    if (Intern) {
        return (
            <>
                <div className='link'>
                    <a href='#' data-value={Value} onClick={handleClick}>
                        {Content}
                    </a>
                </div>
            </>
        );
    } else {
        if (typeof Content === 'string') Content = makeURL(Content);
        return (
            <>
                <span className='createlink-textarea-in-readpage' dangerouslySetInnerHTML={{ __html: Content }} />
            </>
        );
    }
};

export default CreateLink;
