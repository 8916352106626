export const data = {
    success: true,
    result: {
        Appendices: [
            {
                AutoUnzip: false,
                AutoUnzipPathElement: null,
                ButtonCaption: null,
                ButtonDetails: null,
                Columns: [
                    {
                        BaseType: 'string',
                        ComboboxLocked: false,
                        DefaultValue: null,
                        DisplayValue: null,
                        FillWithFileDate: false,
                        Help: '',
                        Hidden: false,
                        ID: 3,
                        IsCalculation: false,
                        IsEmpty: false,
                        IsHTML: false,
                        LayoutSettings: null,
                        Locked: false,
                        MaxLength: null,
                        MinLength: null,
                        Name: 'Bestandsnaam',
                        Refresh: null,
                        RegExPattern: null,
                        ReloadPossible: false,
                        Required: true,
                        RowNumber: null,
                        SID: 'SE_Bestandsnaam',
                        TableCellLocked: false,
                        Tag: null,
                        TransactionListBasedOn: null,
                        Value: '',
                        Values: {},
                    },
                    {
                        BaseType: 'string',
                        ComboboxLocked: false,
                        DefaultValue: null,
                        DisplayValue: null,
                        FillWithFileDate: false,
                        Help: 'De naam van het document',
                        Hidden: false,
                        ID: 980616,
                        IsCalculation: false,
                        IsEmpty: false,
                        IsHTML: false,
                        LayoutSettings: null,
                        Locked: false,
                        MaxLength: null,
                        MinLength: null,
                        Name: 'Documenttitel',
                        Refresh: null,
                        RegExPattern: null,
                        ReloadPossible: false,
                        Required: false,
                        RowNumber: null,
                        SID: 'SE_Documenttitel',
                        TableCellLocked: false,
                        Tag: null,
                        TransactionListBasedOn: null,
                        Value: '',
                        Values: {},
                    },
                    {
                        BaseType: 'select',
                        ComboboxLocked: false,
                        DefaultValue: null,
                        DisplayValue: null,
                        FillWithFileDate: false,
                        Help: 'Een keuzelijst met verschillende typen documenten',
                        Hidden: false,
                        ID: 980631,
                        IsCalculation: false,
                        IsEmpty: false,
                        IsHTML: false,
                        LayoutSettings: null,
                        Locked: false,
                        MaxLength: null,
                        MinLength: null,
                        Name: 'Soort document',
                        Refresh: null,
                        RegExPattern: null,
                        ReloadPossible: false,
                        Required: true,
                        RowNumber: null,
                        SID: 'SE_Soort_document',
                        TableCellLocked: false,
                        Tag: null,
                        TransactionListBasedOn: null,
                        Value: '',
                        Values: {
                            'Berekening ': 'Berekening ',
                            Brief: 'Brief',
                            Buigstaat: 'Buigstaat',
                            Certificaat: 'Certificaat',
                            Draaiboek: 'Draaiboek',
                            Email: 'Email',
                            Factuur: 'Factuur',
                            Fax: 'Fax',
                            Foto: 'Foto',
                            Garanties: 'Garanties',
                            Metingen: 'Metingen',
                            Notitie: 'Notitie',
                            Offerte: 'Offerte',
                            Overeenkomst: 'Overeenkomst',
                            Plan: 'Plan',
                            Planning: 'Planning',
                            Presentatie: 'Presentatie',
                            Prestatieverklaring: 'Prestatieverklaring',
                            'Proces verbaal': 'Proces verbaal',
                            Raming: 'Raming',
                            Rapport: 'Rapport',
                            Rekenblad: 'Rekenblad',
                            Tekening: 'Tekening',
                            Termijn: 'Termijn',
                            'Toetsingsformulier/toetsverslag': 'Toetsingsformulier/toetsverslag',
                            Vergunning: 'Vergunning',
                            Verslag: 'Verslag',
                            Verzendlijst: 'Verzendlijst',
                            Werkpakket: 'Werkpakket',
                            Overig: 'Overig',
                        },
                    },
                    {
                        BaseType: 'string',
                        ComboboxLocked: false,
                        DefaultValue: null,
                        DisplayValue: null,
                        FillWithFileDate: false,
                        Help: '',
                        Hidden: false,
                        ID: 980644,
                        IsCalculation: false,
                        IsEmpty: false,
                        IsHTML: false,
                        LayoutSettings: null,
                        Locked: false,
                        MaxLength: null,
                        MinLength: null,
                        Name: 'Kenmerk test',
                        Refresh: null,
                        RegExPattern: null,
                        ReloadPossible: false,
                        Required: false,
                        RowNumber: null,
                        SID: 'TestKenmerk',
                        TableCellLocked: false,
                        Tag: null,
                        TransactionListBasedOn: null,
                        Value: '',
                        Values: {},
                    },
                    {
                        BaseType: 'string',
                        ComboboxLocked: false,
                        DefaultValue: null,
                        DisplayValue: null,
                        FillWithFileDate: false,
                        Help: '',
                        Hidden: false,
                        ID: 980636,
                        IsCalculation: false,
                        IsEmpty: false,
                        IsHTML: false,
                        LayoutSettings: null,
                        Locked: false,
                        MaxLength: null,
                        MinLength: null,
                        Name: 'Versie van document',
                        Refresh: null,
                        RegExPattern: null,
                        ReloadPossible: false,
                        Required: false,
                        RowNumber: null,
                        SID: 'SE_Versie_document',
                        TableCellLocked: false,
                        Tag: null,
                        TransactionListBasedOn: null,
                        Value: '',
                        Values: {},
                    },
                    {
                        BaseType: 'date',
                        ComboboxLocked: false,
                        DefaultValue: null,
                        DisplayValue: null,
                        FillWithFileDate: false,
                        Help: '',
                        Hidden: false,
                        ID: 980607,
                        IsCalculation: false,
                        IsEmpty: false,
                        IsHTML: false,
                        LayoutSettings: null,
                        Locked: false,
                        MaxLength: null,
                        MinLength: null,
                        Name: 'dd bestand',
                        Refresh: null,
                        RegExPattern: null,
                        ReloadPossible: false,
                        Required: true,
                        RowNumber: null,
                        SID: 'Date_Bestand',
                        TableCellLocked: false,
                        Tag: null,
                        TransactionListBasedOn: null,
                        Value: '',
                        Values: {},
                    },
                    {
                        BaseType: 'string',
                        ComboboxLocked: false,
                        DefaultValue: null,
                        DisplayValue: null,
                        FillWithFileDate: false,
                        Help: 'Hier moet automatischh de waarde uit het raamwerk staan',
                        Hidden: false,
                        ID: 980661,
                        IsCalculation: false,
                        IsEmpty: false,
                        IsHTML: false,
                        LayoutSettings: null,
                        Locked: true,
                        MaxLength: null,
                        MinLength: null,
                        Name: 'UDT Keuzelijst 1 optie',
                        Refresh: null,
                        RegExPattern: null,
                        ReloadPossible: false,
                        Required: false,
                        RowNumber: null,
                        SID: 'se155_3_2',
                        TableCellLocked: false,
                        Tag: null,
                        TransactionListBasedOn: null,
                        Value: 'Automatisch gevuld',
                        Values: {},
                    },
                    {
                        BaseType: 'string',
                        ComboboxLocked: false,
                        DefaultValue: null,
                        DisplayValue: null,
                        FillWithFileDate: false,
                        Help: 'Contractnummer van de opdrachtgever waar het product of levering onder bekend staat',
                        Hidden: false,
                        ID: 980615,
                        IsCalculation: false,
                        IsEmpty: false,
                        IsHTML: false,
                        LayoutSettings: null,
                        Locked: true,
                        MaxLength: null,
                        MinLength: 1,
                        Name: 'UDT Keuzelijst 1 optie andere vorm',
                        Refresh: null,
                        RegExPattern: null,
                        ReloadPossible: false,
                        Required: true,
                        RowNumber: null,
                        SID: 'SE_Contractnummer',
                        TableCellLocked: false,
                        Tag: null,
                        TransactionListBasedOn: null,
                        Value: 'is ingevuld door wizard',
                        Values: {},
                    },
                    {
                        BaseType: 'select',
                        ComboboxLocked: false,
                        DefaultValue: null,
                        DisplayValue: null,
                        FillWithFileDate: false,
                        Help: 'Je moet hier een lijst met straatnamen zien waar je uit kunt kiezen. Als je een deel van de naam typt, moet er automatisch gefilterd worden op die letters. Een niet bestaande straatnaam mag niet verstuurd worden.',
                        Hidden: false,
                        ID: 980643,
                        IsCalculation: false,
                        IsEmpty: false,
                        IsHTML: false,
                        LayoutSettings: null,
                        Locked: false,
                        MaxLength: null,
                        MinLength: null,
                        Name: 'UDT lange keuzelijst: Straatnaam',
                        Refresh: null,
                        RegExPattern: null,
                        ReloadPossible: false,
                        Required: true,
                        RowNumber: null,
                        SID: 'Se_Straatnaam',
                        TableCellLocked: false,
                        Tag: null,
                        TransactionListBasedOn: null,
                        Value: '',
                        Values: {
                            'Niet van toepassing': 'Niet van toepassing',
                            '€ 99 deze testen aub': '€ 99 deze testen aub',
                            'Straatnaam met spatie erachter ': 'Straatnaam met spatie erachter ',
                            '11 Bunder': '11 Bunder',
                            '24 Bunder': '24 Bunder',
                            'Aagje Dekenstraat': 'Aagje Dekenstraat',
                            Aakendonk: 'Aakendonk',
                            Aardenburgsesteeg: 'Aardenburgsesteeg',
                            Acaciastraat: 'Acaciastraat',
                            Achterdijk: 'Achterdijk',
                            'Achterste Hermalen': 'Achterste Hermalen',
                            Akkermunthof: 'Akkermunthof',
                            Akkerstraat: 'Akkerstraat',
                            'Aletta Jacobshof': 'Aletta Jacobshof',
                            Aloysiusstraat: 'Aloysiusstraat',
                            Ambachtstraat: 'Ambachtstraat',
                            Andoornstraat: 'Andoornstraat',
                            Anemoonstraat: 'Anemoonstraat',
                            Ankerpad: 'Ankerpad',
                            'Anna Blamanstraat': 'Anna Blamanstraat',
                            Asterhof: 'Asterhof',
                            Avantilaan: 'Avantilaan',
                            Bachhof: 'Bachhof',
                            Baksdijk: 'Baksdijk',
                            Baroniestraat: 'Baroniestraat',
                            Bartokstraat: 'Bartokstraat',
                            Baselaarshoef: 'Baselaarshoef',
                            Beatrixstraat: 'Beatrixstraat',
                            Beek: 'Beek',
                            Beemdstraat: 'Beemdstraat',
                            Begoniastraat: 'Begoniastraat',
                            'Ben Peterspad': 'Ben Peterspad',
                            Berliozhof: 'Berliozhof',
                            Bernhardstraat: 'Bernhardstraat',
                            Betelgeuze: 'Betelgeuze',
                            'Betje Wolffstraat': 'Betje Wolffstraat',
                            Beukenstraat: 'Beukenstraat',
                            Biezenstraat: 'Biezenstraat',
                            Binnenweg: 'Binnenweg',
                            Bizetstraat: 'Bizetstraat',
                            Bloemenpad: 'Bloemenpad',
                            'Bodem van Elde': 'Bodem van Elde',
                            Bogaard: 'Bogaard',
                            Boogschutter: 'Boogschutter',
                            Boomstraat: 'Boomstraat',
                            Borne: 'Borne',
                            Boschweg: 'Boschweg',
                            Boskoopstraat: 'Boskoopstraat',
                            Boterbloemstraat: 'Boterbloemstraat',
                            Boterweg: 'Boterweg',
                            Boxtelseweg: 'Boxtelseweg',
                            Brahmsstraat: 'Brahmsstraat',
                            Bremert: 'Bremert',
                            Bremweg: 'Bremweg',
                            Broekkant: 'Broekkant',
                            Broekkantsedijk: 'Broekkantsedijk',
                            Broekseweg: 'Broekseweg',
                            Brouwhuisstraat: 'Brouwhuisstraat',
                            Brouwketel: 'Brouwketel',
                            Brucknerstraat: 'Brucknerstraat',
                            Buisselpad: 'Buisselpad',
                            Bundershoeve: 'Bundershoeve',
                            Bunderstraat: 'Bunderstraat',
                            Buntweg: 'Buntweg',
                            'Burg. Janssensstraat': 'Burg. Janssensstraat',
                            Busselsteeg: 'Busselsteeg',
                            Catharinaplein: 'Catharinaplein',
                            Chopinstraat: 'Chopinstraat',
                            Chrysanthof: 'Chrysanthof',
                            'Claes van Griensvenstraat': 'Claes van Griensvenstraat',
                            Clematisstraat: 'Clematisstraat',
                            Conventstraat: 'Conventstraat',
                            'Cornelis Trompstraat': 'Cornelis Trompstraat',
                            Crocushof: 'Crocushof',
                            Dakotaweg: 'Dakotaweg',
                            Daliastraat: 'Daliastraat',
                            'De Bus': 'De Bus',
                            'de Cock van Neerijnenstraat': 'de Cock van Neerijnenstraat',
                            'De Glazen Boerderij': 'De Glazen Boerderij',
                            'De Heikampen': 'De Heikampen',
                            'De Pegstukken': 'De Pegstukken',
                            'De Schild': 'De Schild',
                            'De Steenen Kamer': 'De Steenen Kamer',
                            'De Weerelt': 'De Weerelt',
                            Debussystraat: 'Debussystraat',
                            'Deken Baekershof': 'Deken Baekershof',
                            'Deken Baekersstraat': 'Deken Baekersstraat',
                            'Deken van Dijkstraat': 'Deken van Dijkstraat',
                            Dennensteeg: 'Dennensteeg',
                            Diepenbrockstraat: 'Diepenbrockstraat',
                            Dierenriem: 'Dierenriem',
                            Dinthersedijk: 'Dinthersedijk',
                            Distelpad: 'Distelpad',
                            Distelstraat: 'Distelstraat',
                            Dolfijn: 'Dolfijn',
                            Dopheiweg: 'Dopheiweg',
                            'Dr. Ariënsstraat': 'Dr. Ariënsstraat',
                            'Dr. Poelsstraat': 'Dr. Poelsstraat',
                            'Dr. Schaepmanlaan': 'Dr. Schaepmanlaan',
                            'Dr. van Beurdenstraat': 'Dr. van Beurdenstraat',
                            Duinweg: 'Duinweg',
                            Dungensesteeg: 'Dungensesteeg',
                            Dwarsweg: 'Dwarsweg',
                            Edisonweg: 'Edisonweg',
                            Eekelhof: 'Eekelhof',
                            Eenhoorn: 'Eenhoorn',
                            Eerdsebaan: 'Eerdsebaan',
                            Eikenstraat: 'Eikenstraat',
                            Elde: 'Elde',
                            Elschotseweg: 'Elschotseweg',
                            Emmalaan: 'Emmalaan',
                            Ericastraat: 'Ericastraat',
                            Esdoornstraat: 'Esdoornstraat',
                            Europalaan: 'Europalaan',
                            'Evert van Delftstraat': 'Evert van Delftstraat',
                            Evertsenstraat: 'Evertsenstraat',
                            Floralaan: 'Floralaan',
                            Fresiahof: 'Fresiahof',
                            Galvaniweg: 'Galvaniweg',
                            Gasthuisstraat: 'Gasthuisstraat',
                            Gemondseweg: 'Gemondseweg',
                            Gerstakker: 'Gerstakker',
                            Gestelseweg: 'Gestelseweg',
                            Gladiolenhof: 'Gladiolenhof',
                            Goudenregenstraat: 'Goudenregenstraat',
                            Goudsbloemhof: 'Goudsbloemhof',
                            Gounodhof: 'Gounodhof',
                            Grevekeur: 'Grevekeur',
                            Griegstraat: 'Griegstraat',
                            Griendstraat: 'Griendstraat',
                            Groeneweg: 'Groeneweg',
                            Haagsebosch: 'Haagsebosch',
                            Händelstraat: 'Händelstraat',
                            Hardekamp: 'Hardekamp',
                            Hardveld: 'Hardveld',
                            'Harry Bolsiuslaan': 'Harry Bolsiuslaan',
                            'Harry Jansenlaan': 'Harry Jansenlaan',
                            Haydnhof: 'Haydnhof',
                            Haydnstraat: 'Haydnstraat',
                            Heertveldseweg: 'Heertveldseweg',
                            Heidebloemstraat: 'Heidebloemstraat',
                            Heikantstraat: 'Heikantstraat',
                            Heiveld: 'Heiveld',
                            Heiweg: 'Heiweg',
                            Hermalen: 'Hermalen',
                            'Hertog Jan II Laan': 'Hertog Jan II Laan',
                            'Het Hooghekke': 'Het Hooghekke',
                            Heuvelstraat: 'Heuvelstraat',
                            Hoevenbraaksestraat: 'Hoevenbraaksestraat',
                            Hoeves: 'Hoeves',
                            Hoofdstraat: 'Hoofdstraat',
                            Hopbelpad: 'Hopbelpad',
                            Hopkuil: 'Hopkuil',
                            Hopstakenpad: 'Hopstakenpad',
                            Hopstamper: 'Hopstamper',
                            Hopstraat: 'Hopstraat',
                            Hopveld: 'Hopveld',
                            Hopveldpad: 'Hopveldpad',
                            Houterd: 'Houterd',
                            Houterdsedijk: 'Houterdsedijk',
                            Houtpad: 'Houtpad',
                            Houtstraat: 'Houtstraat',
                            Houtweg: 'Houtweg',
                            Huygensweg: 'Huygensweg',
                            Hyacinthof: 'Hyacinthof',
                            'Ina Boudierstraat': 'Ina Boudierstraat',
                            Industrieweg: 'Industrieweg',
                            Irenestraat: 'Irenestraat',
                            'Jan van Amstelstraat': 'Jan van Amstelstraat',
                            'Jan van Cuykstraat': 'Jan van Cuykstraat',
                            'Jan van Galenstraat': 'Jan van Galenstraat',
                            Jansenpark: 'Jansenpark',
                            'Jet Geerkensplein': 'Jet Geerkensplein',
                            'Johannis Groenewaltstraat': 'Johannis Groenewaltstraat',
                            'Jos van de Schoorstraat': 'Jos van de Schoorstraat',
                            Julianastraat: 'Julianastraat',
                            Jupiter: 'Jupiter',
                            'Kanaaldijk-Zuid': 'Kanaaldijk-Zuid',
                            'Kapelaan Schröderstraat': 'Kapelaan Schröderstraat',
                            'Kapelaan Woestenburgstraat': 'Kapelaan Woestenburgstraat',
                            Kapeleind: 'Kapeleind',
                            Kapittelpad: 'Kapittelpad',
                            'Karel Doormanstraat': 'Karel Doormanstraat',
                            Kastanjestraat: 'Kastanjestraat',
                            Kerkendijk: 'Kerkendijk',
                            Kerkstraat: 'Kerkstraat',
                            Keur: 'Keur',
                            Klaprooshof: 'Klaprooshof',
                            Klaverwei: 'Klaverwei',
                            'Kleine Borne': 'Kleine Borne',
                            Kloosterstraat: 'Kloosterstraat',
                            Kluisstraat: 'Kluisstraat',
                            Koeveringsedijk: 'Koeveringsedijk',
                            Korenbloemstraat: 'Korenbloemstraat',
                            Korenmolen: 'Korenmolen',
                            'Korte Heikantstraat': 'Korte Heikantstraat',
                            Kortenaerstraat: 'Kortenaerstraat',
                            Korteweg: 'Korteweg',
                            Kruissteeg: 'Kruissteeg',
                            Lambertushof: 'Lambertushof',
                            Landingsweg: 'Landingsweg',
                            'Lange Hegghe': 'Lange Hegghe',
                            Langeweg: 'Langeweg',
                            Langstraat: 'Langstraat',
                            Lariestraat: 'Lariestraat',
                            Lavendelhof: 'Lavendelhof',
                            Leemputtenweg: 'Leemputtenweg',
                            Leemweg: 'Leemweg',
                            Leliestraat: 'Leliestraat',
                            Lidwinahof: 'Lidwinahof',
                            Lidwinastraat: 'Lidwinastraat',
                            Liekendonksesteeg: 'Liekendonksesteeg',
                            Lieseind: 'Lieseind',
                            'Lieseinds heike': 'Lieseinds heike',
                            Lieseindsedijk: 'Lieseindsedijk',
                            Lieseindsestraat: 'Lieseindsestraat',
                            Lijsterbeslaan: 'Lijsterbeslaan',
                            Lindenstraat: 'Lindenstraat',
                            Lobbenhoef: 'Lobbenhoef',
                            Locht: 'Locht',
                            Lochtenburg: 'Lochtenburg',
                            Looierij: 'Looierij',
                            Loosbraak: 'Loosbraak',
                            Lorentzweg: 'Lorentzweg',
                            'Madame Curieweg': 'Madame Curieweg',
                            Madeliefstraat: 'Madeliefstraat',
                            Mandelalaan: 'Mandelalaan',
                            Mandersstraat: 'Mandersstraat',
                            Marijkestraat: 'Marijkestraat',
                            Marjoleinhof: 'Marjoleinhof',
                            Markt: 'Markt',
                            Mars: 'Mars',
                            Martemanshurk: 'Martemanshurk',
                            Mauritsstraat: 'Mauritsstraat',
                            'Meester Michelsstraat': 'Meester Michelsstraat',
                            Meidoornstraat: 'Meidoornstraat',
                            Meierijstraat: 'Meierijstraat',
                            Meijgraaf: 'Meijgraaf',
                            Meijldoorn: 'Meijldoorn',
                            Melkstraat: 'Melkstraat',
                            Mercurius: 'Mercurius',
                            'Mgr. Bekkersplein': 'Mgr. Bekkersplein',
                            'Mgr. Bekkersstraat': 'Mgr. Bekkersstraat',
                            'Mgr. van de Venstraat': 'Mgr. van de Venstraat',
                            'Mgr. Zwijsenstraat': 'Mgr. Zwijsenstraat',
                            'Michiel de Ruijterstraat': 'Michiel de Ruijterstraat',
                            'Mieke de Brefstraat': 'Mieke de Brefstraat',
                            'Molendijk-Noord': 'Molendijk-Noord',
                            'Molendijk-Zuid': 'Molendijk-Zuid',
                            Molenhof: 'Molenhof',
                            Molenstraat: 'Molenstraat',
                            Mouterij: 'Mouterij',
                            Mozartpad: 'Mozartpad',
                            Mozartstraat: 'Mozartstraat',
                            'Mr. Goselingstraat': 'Mr. Goselingstraat',
                            Narcishof: 'Narcishof',
                            Neerlandstraat: 'Neerlandstraat',
                            Neptunus: 'Neptunus',
                            Newtonweg: 'Newtonweg',
                            'Nieuwe Eerdsebaan': 'Nieuwe Eerdsebaan',
                            'Nieuwe Molenheide': 'Nieuwe Molenheide',
                            'Nieuwe Slender': 'Nieuwe Slender',
                            Nieuwegaard: 'Nieuwegaard',
                            Nobelweg: 'Nobelweg',
                            Nonnenbos: 'Nonnenbos',
                            Noorderkroon: 'Noorderkroon',
                            Oetelaar: 'Oetelaar',
                            Oetelaarsestraat: 'Oetelaarsestraat',
                            Offenbachstraat: 'Offenbachstraat',
                            Olieëindsestraat: 'Olieëindsestraat',
                            Oliemolen: 'Oliemolen',
                            Ollandseweg: 'Ollandseweg',
                            Orion: 'Orion',
                            'Oude Dijk': 'Oude Dijk',
                            'Oude Molenheide': 'Oude Molenheide',
                            'Oude Steeg': 'Oude Steeg',
                            Papaverstraat: 'Papaverstraat',
                            Parallelweg: 'Parallelweg',
                            'Pastoor Dondersstraat': 'Pastoor Dondersstraat',
                            'Pastoor Van den Brandhof': 'Pastoor Van den Brandhof',
                            'Pastoor van Erpstraat': 'Pastoor van Erpstraat',
                            'Pastoor van Geldropstraat': 'Pastoor van Geldropstraat',
                            'Pastoor van Luijtelaarstraat': 'Pastoor van Luijtelaarstraat',
                            'Pastoor van Vroonhovenstraat': 'Pastoor van Vroonhovenstraat',
                            'Pater van den Elsenstraat': 'Pater van den Elsenstraat',
                            Pegasus: 'Pegasus',
                            Pesserslaan: 'Pesserslaan',
                            'Piet Heijnstraat': 'Piet Heijnstraat',
                            Planetenlaan: 'Planetenlaan',
                            Plataanstraat: 'Plataanstraat',
                            Plein: 'Plein',
                            'Plein 1944': 'Plein 1944',
                            Pluto: 'Pluto',
                            Poelkensdijk: 'Poelkensdijk',
                            Pompstraat: 'Pompstraat',
                            Poolster: 'Poolster',
                            Populierstraat: 'Populierstraat',
                            Primulaplein: 'Primulaplein',
                            Primulastraat: 'Primulastraat',
                            'Prins Hendrikstraat': 'Prins Hendrikstraat',
                            'Prinses Margrietstraat': 'Prinses Margrietstraat',
                            Putsteeg: 'Putsteeg',
                            Ranonkelstraat: 'Ranonkelstraat',
                            Ravelstraat: 'Ravelstraat',
                            'Renate Rubinsteinstraat': 'Renate Rubinsteinstraat',
                            Rietbeemdweg: 'Rietbeemdweg',
                            Rietstraat: 'Rietstraat',
                            Rooiseheide: 'Rooiseheide',
                            Rooiseweg: 'Rooiseweg',
                            Rossinistraat: 'Rossinistraat',
                            Rozemarijnhof: 'Rozemarijnhof',
                            Rozenplein: 'Rozenplein',
                            Rozenstraat: 'Rozenstraat',
                            Salviahof: 'Salviahof',
                            Saturnus: 'Saturnus',
                            Schoolstraat: 'Schoolstraat',
                            Schootsehoef: 'Schootsehoef',
                            Schootsestraat: 'Schootsestraat',
                            Schorpioen: 'Schorpioen',
                            Schrijvershoef: 'Schrijvershoef',
                            Schubertpad: 'Schubertpad',
                            Schubertstraat: 'Schubertstraat',
                            Schutsboom: 'Schutsboom',
                            Schutskooi: 'Schutskooi',
                            'Sint Jorisplein': 'Sint Jorisplein',
                            Sliperman: 'Sliperman',
                            Smaldonkstraat: 'Smaldonkstraat',
                            Sophiastraat: 'Sophiastraat',
                            Spierkesbos: 'Spierkesbos',
                            Spoorlaan: 'Spoorlaan',
                            Spoorpad: 'Spoorpad',
                            'St. Servatiusstraat': 'St. Servatiusstraat',
                            'St. Willibrordusstraat': 'St. Willibrordusstraat',
                            Stamhuis: 'Stamhuis',
                            Steeg: 'Steeg',
                            Steenbok: 'Steenbok',
                            Sterrenlaan: 'Sterrenlaan',
                            Structuurweg: 'Structuurweg',
                            Sweelinckstraat: 'Sweelinckstraat',
                            Teeuwishoek: 'Teeuwishoek',
                            Tiboschlaan: 'Tiboschlaan',
                            Tijmhof: 'Tijmhof',
                            Tolakker: 'Tolakker',
                            'Toon Bolsiusstraat': 'Toon Bolsiusstraat',
                            Tredmolen: 'Tredmolen',
                            Truijenheide: 'Truijenheide',
                            Tulpstraat: 'Tulpstraat',
                            Tweelingen: 'Tweelingen',
                            Uranus: 'Uranus',
                            'Van Beethovenstraat': 'Van Beethovenstraat',
                            'Van Berghenstraat': 'Van Berghenstraat',
                            'Van Beverwijkstraat': 'Van Beverwijkstraat',
                            'Van der Leckstraat': 'Van der Leckstraat',
                            'Van Doorenstraat': 'Van Doorenstraat',
                            'Van Heemskerckstraat': 'Van Heemskerckstraat',
                            'Van Helmontstraat': 'Van Helmontstraat',
                            'Van Leeuwenhoekweg': 'Van Leeuwenhoekweg',
                            'Van Oost-Frieslandstraat': 'Van Oost-Frieslandstraat',
                            'Van Tuijllaan': 'Van Tuijllaan',
                            Veghelsedijk: 'Veghelsedijk',
                            Venushoek: 'Venushoek',
                            Verdipad: 'Verdipad',
                            Verdistraat: 'Verdistraat',
                            Verhagenstraat: 'Verhagenstraat',
                            Verhoevenlaan: 'Verhoevenlaan',
                            Verkensstraatje: 'Verkensstraatje',
                            'Verlengde Kloosterpad': 'Verlengde Kloosterpad',
                            Veronicapark: 'Veronicapark',
                            'Vicaris Aertsweg': 'Vicaris Aertsweg',
                            'Vicaris van Alphenstraat': 'Vicaris van Alphenstraat',
                            'Vier Gemalen': 'Vier Gemalen',
                            Vijverweg: 'Vijverweg',
                            Vinkenpad: 'Vinkenpad',
                            Vissen: 'Vissen',
                            Vivaldistraat: 'Vivaldistraat',
                            Vlagheide: 'Vlagheide',
                            Vliegveldweg: 'Vliegveldweg',
                            Voltaweg: 'Voltaweg',
                            Voortstraat: 'Voortstraat',
                            Vossenberg: 'Vossenberg',
                            Vossenbergsesteeg: 'Vossenbergsesteeg',
                            Vosseneind: 'Vosseneind',
                            Vossenstraat: 'Vossenstraat',
                            Wagenaarstraat: 'Wagenaarstraat',
                            Walvis: 'Walvis',
                            Watermolen: 'Watermolen',
                            Weegschaal: 'Weegschaal',
                            Weidonk: 'Weidonk',
                            'Wielse Kamp': 'Wielse Kamp',
                            Wijbosscheweg: 'Wijbosscheweg',
                            Wijsstraat: 'Wijsstraat',
                            Wilgenstraat: 'Wilgenstraat',
                            Wilhelminalaan: 'Wilhelminalaan',
                            Wilhelminaplein: 'Wilhelminaplein',
                            'Willem III straat': 'Willem III straat',
                            'Willem van Oranjelaan': 'Willem van Oranjelaan',
                            Wort: 'Wort',
                            Woudseweg: 'Woudseweg',
                            Zaagmolen: 'Zaagmolen',
                            Zandkantsestraat: 'Zandkantsestraat',
                            Zeilkampjes: 'Zeilkampjes',
                            'Zijweg Dungen': 'Zijweg Dungen',
                            Zonnebloemhof: 'Zonnebloemhof',
                            Zonnebloemstraat: 'Zonnebloemstraat',
                            Zwaardvis: 'Zwaardvis',
                            Zwembadweg: 'Zwembadweg',
                        },
                    },
                    {
                        BaseType: 'string',
                        ComboboxLocked: false,
                        DefaultValue: null,
                        DisplayValue: null,
                        FillWithFileDate: false,
                        Help: '',
                        Hidden: true,
                        ID: 2,
                        IsCalculation: false,
                        IsEmpty: false,
                        IsHTML: false,
                        LayoutSettings: null,
                        Locked: false,
                        MaxLength: null,
                        MinLength: null,
                        Name: 'BestandsID',
                        Refresh: null,
                        RegExPattern: null,
                        ReloadPossible: false,
                        Required: true,
                        RowNumber: null,
                        SID: 'SE_BestandsID',
                        TableCellLocked: false,
                        Tag: null,
                        TransactionListBasedOn: null,
                        Value: '',
                        Values: {},
                    },
                ],
                Data: [],
                ID: 3798,
                IgnoreLock: false,
                IsCleared: false,
                LayoutSettings: null,
                Locked: false,
                MaxOccurs: null,
                MinOccurs: null,
                Name: 'Standaard bijlage',
                RefreshElementSIDs: null,
                SID: 'StandaardAppendix',
                Tag: null,
                TransactionList: null,
            },
        ],
        AppendixMandatory: false,
        ApplicationButtons: [],
        CalculationSettings: null,
        ComplexElements: [
            {
                Help: '',
                Hidden: false,
                ID: 980713,
                LayoutSettings: null,
                Name: 'Inhoud1: BaseType verzameling',
                SID: 'ce23',
                SimpleElements: [
                    {
                        BaseType: 'boolean',
                        ComboboxLocked: false,
                        DefaultValue: null,
                        DisplayValue: '',
                        FillWithFileDate: false,
                        Help: 'Boolean testveld: er moet hier een checkbox in de applicatie getoond worden',
                        Hidden: false,
                        ID: 980647,
                        IsCalculation: false,
                        IsEmpty: true,
                        IsHTML: false,
                        LayoutSettings: null,
                        Locked: false,
                        MaxLength: null,
                        MinLength: null,
                        Name: 'BTT:  BOOLEAN',
                        Refresh: null,
                        RegExPattern: 'true|1|TRUE|True',
                        ReloadPossible: false,
                        Required: true,
                        RowNumber: null,
                        SID: 'se152_2',
                        TableCellLocked: false,
                        Tag: null,
                        TransactionListBasedOn: null,
                        Value: '',
                        Values: {},
                    },
                    {
                        BaseType: 'integer',
                        ComboboxLocked: false,
                        DefaultValue: null,
                        DisplayValue: '',
                        FillWithFileDate: false,
                        Help: 'Integer testveld: er mogen hier alleen maar getallen zonder decimalen en dergelijke ingevoerd worden.',
                        Hidden: false,
                        ID: 980652,
                        IsCalculation: false,
                        IsEmpty: true,
                        IsHTML: false,
                        LayoutSettings: null,
                        Locked: false,
                        MaxLength: null,
                        MinLength: null,
                        Name: 'BTT:  INTEGER',
                        Refresh: null,
                        RegExPattern: null,
                        ReloadPossible: false,
                        Required: true,
                        RowNumber: null,
                        SID: 'se152_7',
                        TableCellLocked: false,
                        Tag: null,
                        TransactionListBasedOn: null,
                        Value: '',
                        Values: {},
                    },
                    {
                        BaseType: 'time',
                        ComboboxLocked: false,
                        DefaultValue: null,
                        DisplayValue: '',
                        FillWithFileDate: false,
                        Help: 'TIME testveld: de applicatie toont een invoerveld waarin UU:MM ingevoerd kan worden',
                        Hidden: false,
                        ID: 980650,
                        IsCalculation: false,
                        IsEmpty: true,
                        IsHTML: false,
                        LayoutSettings: null,
                        Locked: false,
                        MaxLength: null,
                        MinLength: null,
                        Name: 'BTT:  TIME',
                        Refresh: null,
                        RegExPattern: null,
                        ReloadPossible: false,
                        Required: true,
                        RowNumber: null,
                        SID: 'se152_5',
                        TableCellLocked: false,
                        Tag: null,
                        TransactionListBasedOn: null,
                        Value: '',
                        Values: {},
                    },
                    {
                        BaseType: 'date',
                        ComboboxLocked: false,
                        DefaultValue: null,
                        DisplayValue: '',
                        FillWithFileDate: false,
                        Help: 'DATE testveld: een datumkiezer/veld wordt door de applicatie getoond',
                        Hidden: false,
                        ID: 980648,
                        IsCalculation: false,
                        IsEmpty: true,
                        IsHTML: false,
                        LayoutSettings: null,
                        Locked: false,
                        MaxLength: null,
                        MinLength: null,
                        Name: 'BTT: DATE',
                        Refresh: null,
                        RegExPattern: null,
                        ReloadPossible: false,
                        Required: true,
                        RowNumber: null,
                        SID: 'se152_3',
                        TableCellLocked: false,
                        Tag: null,
                        TransactionListBasedOn: null,
                        Value: '',
                        Values: {},
                    },
                    {
                        BaseType: 'datetime',
                        ComboboxLocked: false,
                        DefaultValue: null,
                        DisplayValue: '',
                        FillWithFileDate: false,
                        Help: 'DATETIME testveld: een invoerveld wordt getoond waarin een datum met een tijdstip ingevuld kan worden.',
                        Hidden: false,
                        ID: 980649,
                        IsCalculation: false,
                        IsEmpty: true,
                        IsHTML: false,
                        LayoutSettings: null,
                        Locked: false,
                        MaxLength: null,
                        MinLength: null,
                        Name: 'BTT: DATETIME',
                        Refresh: null,
                        RegExPattern: null,
                        ReloadPossible: false,
                        Required: true,
                        RowNumber: null,
                        SID: 'se152_4',
                        TableCellLocked: false,
                        Tag: null,
                        TransactionListBasedOn: null,
                        Value: '',
                        Values: {},
                    },
                    {
                        BaseType: 'decimal',
                        ComboboxLocked: false,
                        DefaultValue: null,
                        DisplayValue: '',
                        FillWithFileDate: false,
                        Help: 'DECIMAL testveld: beschrijvinmg zoeken',
                        Hidden: false,
                        ID: 980651,
                        IsCalculation: false,
                        IsEmpty: true,
                        IsHTML: false,
                        LayoutSettings: null,
                        Locked: false,
                        MaxLength: null,
                        MinLength: null,
                        Name: 'BTT: DECIMAL',
                        Refresh: null,
                        RegExPattern: null,
                        ReloadPossible: false,
                        Required: true,
                        RowNumber: null,
                        SID: 'se152_6',
                        TableCellLocked: false,
                        Tag: null,
                        TransactionListBasedOn: null,
                        Value: '',
                        Values: {},
                    },
                    {
                        BaseType: 'string',
                        ComboboxLocked: false,
                        DefaultValue: null,
                        DisplayValue: '',
                        FillWithFileDate: false,
                        Help: 'STRING testveld: je mag hier alles invullen, voer getallen, vreemde tekens enz. in.',
                        Hidden: false,
                        ID: 980646,
                        IsCalculation: false,
                        IsEmpty: true,
                        IsHTML: false,
                        LayoutSettings: null,
                        Locked: false,
                        MaxLength: null,
                        MinLength: null,
                        Name: 'BTT: STRING',
                        Refresh: null,
                        RegExPattern: null,
                        ReloadPossible: false,
                        Required: false,
                        RowNumber: null,
                        SID: 'se152',
                        TableCellLocked: false,
                        Tag: null,
                        TransactionListBasedOn: null,
                        Value: '',
                        Values: {},
                    },
                ],
                Tables: [],
            },
            {
                Help: '',
                Hidden: false,
                ID: 980714,
                LayoutSettings: null,
                Name: 'Inhoud 1: base type tabel',
                SID: 'ce24',
                SimpleElements: [],
                Tables: [
                    {
                        AutoUnzip: false,
                        AutoUnzipPathElement: null,
                        ButtonCaption: null,
                        ButtonDetails: null,
                        Columns: [
                            {
                                BaseType: 'boolean',
                                ComboboxLocked: false,
                                DefaultValue: null,
                                DisplayValue: '',
                                FillWithFileDate: false,
                                Help: 'Boolean testveld: er moet hier een checkbox in de applicatie getoond worden',
                                Hidden: false,
                                ID: 980647,
                                IsCalculation: false,
                                IsEmpty: true,
                                IsHTML: false,
                                LayoutSettings: null,
                                Locked: false,
                                MaxLength: null,
                                MinLength: null,
                                Name: 'BTT:  BOOLEAN',
                                Refresh: null,
                                RegExPattern: 'true|1|TRUE|True',
                                ReloadPossible: false,
                                Required: true,
                                RowNumber: null,
                                SID: 'se152_2',
                                TableCellLocked: false,
                                Tag: null,
                                TransactionListBasedOn: null,
                                Value: '',
                                Values: {},
                            },
                            {
                                BaseType: 'integer',
                                ComboboxLocked: false,
                                DefaultValue: null,
                                DisplayValue: '',
                                FillWithFileDate: false,
                                Help: 'Integer testveld: er mogen hier alleen maar getallen zonder decimalen en dergelijke ingevoerd worden.',
                                Hidden: false,
                                ID: 980652,
                                IsCalculation: false,
                                IsEmpty: true,
                                IsHTML: false,
                                LayoutSettings: null,
                                Locked: false,
                                MaxLength: null,
                                MinLength: null,
                                Name: 'BTT:  INTEGER',
                                Refresh: null,
                                RegExPattern: null,
                                ReloadPossible: false,
                                Required: true,
                                RowNumber: null,
                                SID: 'se152_7',
                                TableCellLocked: false,
                                Tag: null,
                                TransactionListBasedOn: null,
                                Value: '',
                                Values: {},
                            },
                            {
                                BaseType: 'time',
                                ComboboxLocked: false,
                                DefaultValue: null,
                                DisplayValue: '',
                                FillWithFileDate: false,
                                Help: 'TIME testveld: de applicatie toont een invoerveld waarin UU:MM ingevoerd kan worden',
                                Hidden: false,
                                ID: 980650,
                                IsCalculation: false,
                                IsEmpty: true,
                                IsHTML: false,
                                LayoutSettings: null,
                                Locked: false,
                                MaxLength: null,
                                MinLength: null,
                                Name: 'BTT:  TIME',
                                Refresh: null,
                                RegExPattern: null,
                                ReloadPossible: false,
                                Required: true,
                                RowNumber: null,
                                SID: 'se152_5',
                                TableCellLocked: false,
                                Tag: null,
                                TransactionListBasedOn: null,
                                Value: '',
                                Values: {},
                            },
                            {
                                BaseType: 'date',
                                ComboboxLocked: false,
                                DefaultValue: null,
                                DisplayValue: '',
                                FillWithFileDate: false,
                                Help: 'DATE testveld: een datumkiezer/veld wordt door de applicatie getoond',
                                Hidden: false,
                                ID: 980648,
                                IsCalculation: false,
                                IsEmpty: true,
                                IsHTML: false,
                                LayoutSettings: null,
                                Locked: false,
                                MaxLength: null,
                                MinLength: null,
                                Name: 'BTT: DATE',
                                Refresh: null,
                                RegExPattern: null,
                                ReloadPossible: false,
                                Required: true,
                                RowNumber: null,
                                SID: 'se152_3',
                                TableCellLocked: false,
                                Tag: null,
                                TransactionListBasedOn: null,
                                Value: '',
                                Values: {},
                            },
                            {
                                BaseType: 'datetime',
                                ComboboxLocked: false,
                                DefaultValue: null,
                                DisplayValue: '',
                                FillWithFileDate: false,
                                Help: 'DATETIME testveld: een invoerveld wordt getoond waarin een datum met een tijdstip ingevuld kan worden.',
                                Hidden: false,
                                ID: 980649,
                                IsCalculation: false,
                                IsEmpty: true,
                                IsHTML: false,
                                LayoutSettings: null,
                                Locked: false,
                                MaxLength: null,
                                MinLength: null,
                                Name: 'BTT: DATETIME',
                                Refresh: null,
                                RegExPattern: null,
                                ReloadPossible: false,
                                Required: true,
                                RowNumber: null,
                                SID: 'se152_4',
                                TableCellLocked: false,
                                Tag: null,
                                TransactionListBasedOn: null,
                                Value: '',
                                Values: {},
                            },
                            {
                                BaseType: 'decimal',
                                ComboboxLocked: false,
                                DefaultValue: null,
                                DisplayValue: '',
                                FillWithFileDate: false,
                                Help: 'DECIMAL testveld: beschrijvinmg zoeken',
                                Hidden: false,
                                ID: 980651,
                                IsCalculation: false,
                                IsEmpty: true,
                                IsHTML: false,
                                LayoutSettings: null,
                                Locked: false,
                                MaxLength: null,
                                MinLength: null,
                                Name: 'BTT: DECIMAL',
                                Refresh: null,
                                RegExPattern: null,
                                ReloadPossible: false,
                                Required: true,
                                RowNumber: null,
                                SID: 'se152_6',
                                TableCellLocked: false,
                                Tag: null,
                                TransactionListBasedOn: null,
                                Value: '',
                                Values: {},
                            },
                            {
                                BaseType: 'string',
                                ComboboxLocked: false,
                                DefaultValue: null,
                                DisplayValue: '',
                                FillWithFileDate: false,
                                Help: 'STRING testveld: je mag hier alles invullen, voer getallen, vreemde tekens enz. in.',
                                Hidden: false,
                                ID: 980646,
                                IsCalculation: false,
                                IsEmpty: true,
                                IsHTML: false,
                                LayoutSettings: null,
                                Locked: false,
                                MaxLength: null,
                                MinLength: null,
                                Name: 'BTT: STRING',
                                Refresh: null,
                                RegExPattern: null,
                                ReloadPossible: false,
                                Required: false,
                                RowNumber: null,
                                SID: 'se152',
                                TableCellLocked: false,
                                Tag: null,
                                TransactionListBasedOn: null,
                                Value: '',
                                Values: {},
                            },
                        ],
                        Data: [],
                        ID: 980713,
                        IgnoreLock: false,
                        IsCleared: false,
                        LayoutSettings: null,
                        Locked: false,
                        MaxOccurs: null,
                        MinOccurs: null,
                        Name: 'Inhoud1: BaseType verzameling',
                        RefreshElementSIDs: null,
                        SID: 'ce23',
                        Tag: null,
                        TransactionList: null,
                    },
                ],
            },
            {
                Help: '',
                Hidden: false,
                ID: 980708,
                LayoutSettings: null,
                Name: 'Opmerkingen',
                SID: 'CE_Opmerkingen',
                SimpleElements: [
                    {
                        BaseType: 'string',
                        ComboboxLocked: false,
                        DefaultValue: null,
                        DisplayValue: '',
                        FillWithFileDate: false,
                        Help: 'Te maken opmerking per bericht invulbaar. Bij een nieuw bericht wordt deze steeds leeggemaakt.',
                        Hidden: false,
                        ID: 980627,
                        IsCalculation: false,
                        IsEmpty: true,
                        IsHTML: false,
                        LayoutSettings: null,
                        Locked: false,
                        MaxLength: null,
                        MinLength: null,
                        Name: 'Eenmalige opmerkingen',
                        Refresh: null,
                        RegExPattern: null,
                        ReloadPossible: false,
                        Required: false,
                        RowNumber: null,
                        SID: 'SE_Opmerkingen',
                        TableCellLocked: false,
                        Tag: null,
                        TransactionListBasedOn: null,
                        Value: '',
                        Values: {},
                    },
                ],
                Tables: [],
            },
        ],
        CurrentLineNumberInTable: 0,
        Executors: [],
        ExecutorsFromSimpleElementSID: '',
        ExecutorsHidden: true,
        FromRoleToRole: 'van 2 naar 1',
        IncludeAppendices: false,
        Initiators: [],
        InitiatorsVisible: false,
        LabelBericht: 'Antwoord',
        LabelExecutors: 'Versturen naar',
        LuaSettings: null,
        MITID: 2164304,
        MessageTypeID: 1549761,
        Notification: null,
        Receiver: '1',
        ReplyToMessageID: 850216,
        RestoreContent: true,
        SettingTransaction: null,
        ShowFlowchartButton: true,
        Subject: 'test',
        SubjectEnabled: false,
        SubjectGeneration: null,
        SubjectHidden: false,
        TransactionID: 610243,
        TransactionTypeID: 387798,
        UpdateToLatestFramework: false,
    },
};

export const messageContentData = [
    {
        Appendices: [
            {
                Columns: [
                    {
                        BaseType: 'string',
                        DisplayValue: null,
                        ExtraValue: null,
                        HTMLValue: null,
                        Help: null,
                        Hidden: false,
                        ID: 3,
                        IsLink: false,
                        LayoutSettings: null,
                        Name: 'Bestandsnaam',
                        SID: 'SE_Bestandsnaam',
                        Tag: null,
                        UriScheme: null,
                        Value: null,
                    },
                    {
                        BaseType: 'STRING',
                        DisplayValue: null,
                        ExtraValue: null,
                        HTMLValue: null,
                        Help: null,
                        Hidden: false,
                        ID: 980616,
                        IsLink: false,
                        LayoutSettings: null,
                        Name: 'Documenttitel',
                        SID: 'SE_Documenttitel',
                        Tag: null,
                        UriScheme: null,
                        Value: null,
                    },
                    {
                        BaseType: 'STRING',
                        DisplayValue: null,
                        ExtraValue: null,
                        HTMLValue: null,
                        Help: null,
                        Hidden: false,
                        ID: 980631,
                        IsLink: false,
                        LayoutSettings: null,
                        Name: 'Soort document',
                        SID: 'SE_Soort_document',
                        Tag: null,
                        UriScheme: null,
                        Value: null,
                    },
                    {
                        BaseType: 'STRING',
                        DisplayValue: null,
                        ExtraValue: null,
                        HTMLValue: null,
                        Help: null,
                        Hidden: false,
                        ID: 980644,
                        IsLink: false,
                        LayoutSettings: null,
                        Name: 'Kenmerk test',
                        SID: 'TestKenmerk',
                        Tag: null,
                        UriScheme: null,
                        Value: null,
                    },
                    {
                        BaseType: 'STRING',
                        DisplayValue: null,
                        ExtraValue: null,
                        HTMLValue: null,
                        Help: null,
                        Hidden: false,
                        ID: 980636,
                        IsLink: false,
                        LayoutSettings: null,
                        Name: 'Versie van document',
                        SID: 'SE_Versie_document',
                        Tag: null,
                        UriScheme: null,
                        Value: null,
                    },
                    {
                        BaseType: 'DATE',
                        DisplayValue: null,
                        ExtraValue: null,
                        HTMLValue: null,
                        Help: null,
                        Hidden: false,
                        ID: 980607,
                        IsLink: false,
                        LayoutSettings: null,
                        Name: 'dd bestand',
                        SID: 'Date_Bestand',
                        Tag: null,
                        UriScheme: null,
                        Value: null,
                    },
                    {
                        BaseType: 'STRING',
                        DisplayValue: null,
                        ExtraValue: null,
                        HTMLValue: null,
                        Help: null,
                        Hidden: false,
                        ID: 980661,
                        IsLink: false,
                        LayoutSettings: null,
                        Name: 'UDT Keuzelijst 1 optie',
                        SID: 'se155_3_2',
                        Tag: null,
                        UriScheme: null,
                        Value: null,
                    },
                    {
                        BaseType: 'STRING',
                        DisplayValue: null,
                        ExtraValue: null,
                        HTMLValue: null,
                        Help: null,
                        Hidden: false,
                        ID: 980615,
                        IsLink: false,
                        LayoutSettings: null,
                        Name: 'UDT Keuzelijst 1 optie andere vorm',
                        SID: 'SE_Contractnummer',
                        Tag: null,
                        UriScheme: null,
                        Value: null,
                    },
                    {
                        BaseType: 'STRING',
                        DisplayValue: null,
                        ExtraValue: null,
                        HTMLValue: null,
                        Help: null,
                        Hidden: false,
                        ID: 980643,
                        IsLink: false,
                        LayoutSettings: null,
                        Name: 'UDT lange keuzelijst: Straatnaam',
                        SID: 'Se_Straatnaam',
                        Tag: null,
                        UriScheme: null,
                        Value: null,
                    },
                    {
                        BaseType: 'string',
                        DisplayValue: null,
                        ExtraValue: null,
                        HTMLValue: null,
                        Help: null,
                        Hidden: false,
                        ID: 2,
                        IsLink: false,
                        LayoutSettings: null,
                        Name: 'BestandsID',
                        SID: 'SE_BestandsID',
                        Tag: null,
                        UriScheme: null,
                        Value: null,
                    },
                ],
                Data: [
                    [
                        {
                            BaseType: 'string',
                            DisplayValue: 'cachingfout.txt',
                            ExtraValue: null,
                            HTMLValue: null,
                            Help: null,
                            Hidden: false,
                            ID: 3,
                            IsLink: false,
                            LayoutSettings: null,
                            Name: 'Bestandsnaam',
                            SID: 'SE_Bestandsnaam',
                            Tag: null,
                            UriScheme: null,
                            Value: 'cachingfout.txt',
                        },
                        {
                            BaseType: 'STRING',
                            DisplayValue: '',
                            ExtraValue: null,
                            HTMLValue: null,
                            Help: null,
                            Hidden: false,
                            ID: 980616,
                            IsLink: false,
                            LayoutSettings: null,
                            Name: 'Documenttitel',
                            SID: 'SE_Documenttitel',
                            Tag: null,
                            UriScheme: null,
                            Value: '',
                        },
                        {
                            BaseType: 'STRING',
                            DisplayValue: 'Berekening ',
                            ExtraValue: null,
                            HTMLValue: null,
                            Help: null,
                            Hidden: false,
                            ID: 980631,
                            IsLink: false,
                            LayoutSettings: null,
                            Name: 'Soort document',
                            SID: 'SE_Soort_document',
                            Tag: null,
                            UriScheme: null,
                            Value: 'Berekening ',
                        },
                        {
                            BaseType: 'STRING',
                            DisplayValue: '',
                            ExtraValue: null,
                            HTMLValue: null,
                            Help: null,
                            Hidden: false,
                            ID: 980644,
                            IsLink: false,
                            LayoutSettings: null,
                            Name: 'Kenmerk test',
                            SID: 'TestKenmerk',
                            Tag: null,
                            UriScheme: null,
                            Value: '',
                        },
                        {
                            BaseType: 'STRING',
                            DisplayValue: '',
                            ExtraValue: null,
                            HTMLValue: null,
                            Help: null,
                            Hidden: false,
                            ID: 980636,
                            IsLink: false,
                            LayoutSettings: null,
                            Name: 'Versie van document',
                            SID: 'SE_Versie_document',
                            Tag: null,
                            UriScheme: null,
                            Value: '',
                        },
                        {
                            BaseType: 'DATE',
                            DisplayValue: '2021-10-01',
                            ExtraValue: null,
                            HTMLValue: null,
                            Help: null,
                            Hidden: false,
                            ID: 980607,
                            IsLink: false,
                            LayoutSettings: null,
                            Name: 'dd bestand',
                            SID: 'Date_Bestand',
                            Tag: null,
                            UriScheme: null,
                            Value: '2021-10-01',
                        },
                        {
                            BaseType: 'STRING',
                            DisplayValue: 'Automatisch gevuld',
                            ExtraValue: null,
                            HTMLValue: null,
                            Help: null,
                            Hidden: false,
                            ID: 980661,
                            IsLink: false,
                            LayoutSettings: null,
                            Name: 'UDT Keuzelijst 1 optie',
                            SID: 'se155_3_2',
                            Tag: null,
                            UriScheme: null,
                            Value: 'Automatisch gevuld',
                        },
                        {
                            BaseType: 'STRING',
                            DisplayValue: 'is ingevuld door wizard',
                            ExtraValue: null,
                            HTMLValue: null,
                            Help: null,
                            Hidden: false,
                            ID: 980615,
                            IsLink: false,
                            LayoutSettings: null,
                            Name: 'UDT Keuzelijst 1 optie andere vorm',
                            SID: 'SE_Contractnummer',
                            Tag: null,
                            UriScheme: null,
                            Value: 'is ingevuld door wizard',
                        },
                        {
                            BaseType: 'STRING',
                            DisplayValue: '€ 99 deze testen aub',
                            ExtraValue: null,
                            HTMLValue: null,
                            Help: null,
                            Hidden: false,
                            ID: 980643,
                            IsLink: false,
                            LayoutSettings: null,
                            Name: 'UDT lange keuzelijst: Straatnaam',
                            SID: 'Se_Straatnaam',
                            Tag: null,
                            UriScheme: null,
                            Value: '€ 99 deze testen aub',
                        },
                        {
                            BaseType: 'string',
                            DisplayValue: '335015',
                            ExtraValue: null,
                            HTMLValue: null,
                            Help: null,
                            Hidden: false,
                            ID: 2,
                            IsLink: false,
                            LayoutSettings: null,
                            Name: 'BestandsID',
                            SID: 'SE_BestandsID',
                            Tag: null,
                            UriScheme: null,
                            Value: '335015',
                        },
                    ],
                    [
                        {
                            BaseType: 'string',
                            DisplayValue: "'Voorlopig mag je deze afdeling niet af' - de Volkskrant - Blendle.pdf",
                            ExtraValue: null,
                            HTMLValue: null,
                            Help: null,
                            Hidden: false,
                            ID: 3,
                            IsLink: false,
                            LayoutSettings: null,
                            Name: 'Bestandsnaam',
                            SID: 'SE_Bestandsnaam',
                            Tag: null,
                            UriScheme: null,
                            Value: "'Voorlopig mag je deze afdeling niet af' - de Volkskrant - Blendle.pdf",
                        },
                        {
                            BaseType: 'STRING',
                            DisplayValue: '',
                            ExtraValue: null,
                            HTMLValue: null,
                            Help: null,
                            Hidden: false,
                            ID: 980616,
                            IsLink: false,
                            LayoutSettings: null,
                            Name: 'Documenttitel',
                            SID: 'SE_Documenttitel',
                            Tag: null,
                            UriScheme: null,
                            Value: '',
                        },
                        {
                            BaseType: 'STRING',
                            DisplayValue: 'Brief',
                            ExtraValue: null,
                            HTMLValue: null,
                            Help: null,
                            Hidden: false,
                            ID: 980631,
                            IsLink: false,
                            LayoutSettings: null,
                            Name: 'Soort document',
                            SID: 'SE_Soort_document',
                            Tag: null,
                            UriScheme: null,
                            Value: 'Brief',
                        },
                        {
                            BaseType: 'STRING',
                            DisplayValue: '',
                            ExtraValue: null,
                            HTMLValue: null,
                            Help: null,
                            Hidden: false,
                            ID: 980644,
                            IsLink: false,
                            LayoutSettings: null,
                            Name: 'Kenmerk test',
                            SID: 'TestKenmerk',
                            Tag: null,
                            UriScheme: null,
                            Value: '',
                        },
                        {
                            BaseType: 'STRING',
                            DisplayValue: '',
                            ExtraValue: null,
                            HTMLValue: null,
                            Help: null,
                            Hidden: false,
                            ID: 980636,
                            IsLink: false,
                            LayoutSettings: null,
                            Name: 'Versie van document',
                            SID: 'SE_Versie_document',
                            Tag: null,
                            UriScheme: null,
                            Value: '',
                        },
                        {
                            BaseType: 'DATE',
                            DisplayValue: '2021-10-08',
                            ExtraValue: null,
                            HTMLValue: null,
                            Help: null,
                            Hidden: false,
                            ID: 980607,
                            IsLink: false,
                            LayoutSettings: null,
                            Name: 'dd bestand',
                            SID: 'Date_Bestand',
                            Tag: null,
                            UriScheme: null,
                            Value: '2021-10-08',
                        },
                        {
                            BaseType: 'STRING',
                            DisplayValue: 'Automatisch gevuld',
                            ExtraValue: null,
                            HTMLValue: null,
                            Help: null,
                            Hidden: false,
                            ID: 980661,
                            IsLink: false,
                            LayoutSettings: null,
                            Name: 'UDT Keuzelijst 1 optie',
                            SID: 'se155_3_2',
                            Tag: null,
                            UriScheme: null,
                            Value: 'Automatisch gevuld',
                        },
                        {
                            BaseType: 'STRING',
                            DisplayValue: 'is ingevuld door wizard',
                            ExtraValue: null,
                            HTMLValue: null,
                            Help: null,
                            Hidden: false,
                            ID: 980615,
                            IsLink: false,
                            LayoutSettings: null,
                            Name: 'UDT Keuzelijst 1 optie andere vorm',
                            SID: 'SE_Contractnummer',
                            Tag: null,
                            UriScheme: null,
                            Value: 'is ingevuld door wizard',
                        },
                        {
                            BaseType: 'STRING',
                            DisplayValue: 'Straatnaam met spatie erachter ',
                            ExtraValue: null,
                            HTMLValue: null,
                            Help: null,
                            Hidden: false,
                            ID: 980643,
                            IsLink: false,
                            LayoutSettings: null,
                            Name: 'UDT lange keuzelijst: Straatnaam',
                            SID: 'Se_Straatnaam',
                            Tag: null,
                            UriScheme: null,
                            Value: 'Straatnaam met spatie erachter ',
                        },
                        {
                            BaseType: 'string',
                            DisplayValue: '335016',
                            ExtraValue: null,
                            HTMLValue: null,
                            Help: null,
                            Hidden: false,
                            ID: 2,
                            IsLink: false,
                            LayoutSettings: null,
                            Name: 'BestandsID',
                            SID: 'SE_BestandsID',
                            Tag: null,
                            UriScheme: null,
                            Value: '335016',
                        },
                    ],
                ],
                ID: 0,
                LayoutSettings: null,
                Name: 'Standaard bijlage',
                SID: 'StandaardAppendix',
                Tag: null,
            },
        ],
        ButtonReply: true,
        ComplexElements: [
            {
                Help: '',
                Hidden: false,
                ID: 980713,
                LayoutSettings: null,
                Name: 'Inhoud1: BaseType verzameling',
                SID: 'ce23',
                SimpleElements: [
                    {
                        BaseType: 'BOOLEAN',
                        DisplayValue: 'true',
                        ExtraValue: null,
                        HTMLValue: '',
                        Help: 'Boolean testveld: er moet hier een checkbox in de applicatie getoond worden',
                        Hidden: false,
                        ID: 980647,
                        IsLink: false,
                        LayoutSettings: null,
                        Name: 'BTT:  BOOLEAN',
                        SID: 'se152_2',
                        Tag: null,
                        UriScheme: null,
                        Value: 'true',
                    },
                    {
                        BaseType: 'INTEGER',
                        DisplayValue: '1',
                        ExtraValue: null,
                        HTMLValue: '',
                        Help: 'Integer testveld: er mogen hier alleen maar getallen zonder decimalen en dergelijke ingevoerd worden.',
                        Hidden: false,
                        ID: 980652,
                        IsLink: false,
                        LayoutSettings: null,
                        Name: 'BTT:  INTEGER',
                        SID: 'se152_7',
                        Tag: null,
                        UriScheme: null,
                        Value: '1',
                    },
                    {
                        BaseType: 'TIME',
                        DisplayValue: '07:15',
                        ExtraValue: null,
                        HTMLValue: '',
                        Help: 'TIME testveld: de applicatie toont een invoerveld waarin UU:MM ingevoerd kan worden',
                        Hidden: false,
                        ID: 980650,
                        IsLink: false,
                        LayoutSettings: null,
                        Name: 'BTT:  TIME',
                        SID: 'se152_5',
                        Tag: null,
                        UriScheme: null,
                        Value: '07:15',
                    },
                    {
                        BaseType: 'DATE',
                        DisplayValue: '2021-04-26',
                        ExtraValue: null,
                        HTMLValue: '',
                        Help: 'DATE testveld: een datumkiezer/veld wordt door de applicatie getoond',
                        Hidden: false,
                        ID: 980648,
                        IsLink: false,
                        LayoutSettings: null,
                        Name: 'BTT: DATE',
                        SID: 'se152_3',
                        Tag: null,
                        UriScheme: null,
                        Value: '2021-04-26',
                    },
                    {
                        BaseType: 'DATETIME',
                        DisplayValue: '',
                        ExtraValue: null,
                        HTMLValue: '',
                        Help: 'DATETIME testveld: een invoerveld wordt getoond waarin een datum met een tijdstip ingevuld kan worden.',
                        Hidden: false,
                        ID: 980649,
                        IsLink: false,
                        LayoutSettings: null,
                        Name: 'BTT: DATETIME',
                        SID: 'se152_4',
                        Tag: null,
                        UriScheme: null,
                        Value: '',
                    },
                    {
                        BaseType: 'DECIMAL',
                        DisplayValue: '3',
                        ExtraValue: null,
                        HTMLValue: '',
                        Help: 'DECIMAL testveld: beschrijvinmg zoeken',
                        Hidden: false,
                        ID: 980651,
                        IsLink: false,
                        LayoutSettings: null,
                        Name: 'BTT: DECIMAL',
                        SID: 'se152_6',
                        Tag: null,
                        UriScheme: null,
                        Value: '3',
                    },
                    {
                        BaseType: 'STRING',
                        DisplayValue: 'dfsd',
                        ExtraValue: null,
                        HTMLValue: '',
                        Help: 'STRING testveld: je mag hier alles invullen, voer getallen, vreemde tekens enz. in.',
                        Hidden: false,
                        ID: 980646,
                        IsLink: false,
                        LayoutSettings: null,
                        Name: 'BTT: STRING',
                        SID: 'se152',
                        Tag: null,
                        UriScheme: null,
                        Value: 'dfsd',
                    },
                ],
                Tables: [],
                Tag: null,
            },
            {
                Help: '',
                Hidden: false,
                ID: 980714,
                LayoutSettings: null,
                Name: 'Inhoud 1: base type tabel',
                SID: 'ce24',
                SimpleElements: [],
                Tables: [
                    {
                        Columns: [
                            {
                                BaseType: 'BOOLEAN',
                                DisplayValue: null,
                                ExtraValue: null,
                                HTMLValue: null,
                                Help: null,
                                Hidden: false,
                                ID: 980647,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'BTT:  BOOLEAN',
                                SID: 'se152_2',
                                Tag: null,
                                UriScheme: null,
                                Value: null,
                            },
                            {
                                BaseType: 'INTEGER',
                                DisplayValue: null,
                                ExtraValue: null,
                                HTMLValue: null,
                                Help: null,
                                Hidden: false,
                                ID: 980652,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'BTT:  INTEGER',
                                SID: 'se152_7',
                                Tag: null,
                                UriScheme: null,
                                Value: null,
                            },
                            {
                                BaseType: 'TIME',
                                DisplayValue: null,
                                ExtraValue: null,
                                HTMLValue: null,
                                Help: null,
                                Hidden: false,
                                ID: 980650,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'BTT:  TIME',
                                SID: 'se152_5',
                                Tag: null,
                                UriScheme: null,
                                Value: null,
                            },
                            {
                                BaseType: 'DATE',
                                DisplayValue: null,
                                ExtraValue: null,
                                HTMLValue: null,
                                Help: null,
                                Hidden: false,
                                ID: 980648,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'BTT: DATE',
                                SID: 'se152_3',
                                Tag: null,
                                UriScheme: null,
                                Value: null,
                            },
                            {
                                BaseType: 'DATETIME',
                                DisplayValue: null,
                                ExtraValue: null,
                                HTMLValue: null,
                                Help: null,
                                Hidden: false,
                                ID: 980649,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'BTT: DATETIME',
                                SID: 'se152_4',
                                Tag: null,
                                UriScheme: null,
                                Value: null,
                            },
                            {
                                BaseType: 'DECIMAL',
                                DisplayValue: null,
                                ExtraValue: null,
                                HTMLValue: null,
                                Help: null,
                                Hidden: false,
                                ID: 980651,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'BTT: DECIMAL',
                                SID: 'se152_6',
                                Tag: null,
                                UriScheme: null,
                                Value: null,
                            },
                            {
                                BaseType: 'STRING',
                                DisplayValue: null,
                                ExtraValue: null,
                                HTMLValue: null,
                                Help: null,
                                Hidden: false,
                                ID: 980646,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'BTT: STRING',
                                SID: 'se152',
                                Tag: null,
                                UriScheme: null,
                                Value: null,
                            },
                        ],
                        Data: [
                            [
                                {
                                    BaseType: 'BOOLEAN',
                                    DisplayValue: 'false',
                                    ExtraValue: null,
                                    HTMLValue: '',
                                    Help: 'Boolean testveld: er moet hier een checkbox in de applicatie getoond worden',
                                    Hidden: false,
                                    ID: 980647,
                                    IsLink: false,
                                    LayoutSettings: null,
                                    Name: 'BTT:  BOOLEAN',
                                    SID: 'se152_2',
                                    Tag: null,
                                    UriScheme: null,
                                    Value: 'false',
                                },
                                {
                                    BaseType: 'INTEGER',
                                    DisplayValue: '4',
                                    ExtraValue: null,
                                    HTMLValue: '',
                                    Help: 'Integer testveld: er mogen hier alleen maar getallen zonder decimalen en dergelijke ingevoerd worden.',
                                    Hidden: false,
                                    ID: 980652,
                                    IsLink: false,
                                    LayoutSettings: null,
                                    Name: 'BTT:  INTEGER',
                                    SID: 'se152_7',
                                    Tag: null,
                                    UriScheme: null,
                                    Value: '4',
                                },
                                {
                                    BaseType: 'TIME',
                                    DisplayValue: '07:15',
                                    ExtraValue: null,
                                    HTMLValue: '',
                                    Help: 'TIME testveld: de applicatie toont een invoerveld waarin UU:MM ingevoerd kan worden',
                                    Hidden: false,
                                    ID: 980650,
                                    IsLink: false,
                                    LayoutSettings: null,
                                    Name: 'BTT:  TIME',
                                    SID: 'se152_5',
                                    Tag: null,
                                    UriScheme: null,
                                    Value: '07:15',
                                },
                                {
                                    BaseType: 'DATE',
                                    DisplayValue: '2021-04-09',
                                    ExtraValue: null,
                                    HTMLValue: '',
                                    Help: 'DATE testveld: een datumkiezer/veld wordt door de applicatie getoond',
                                    Hidden: false,
                                    ID: 980648,
                                    IsLink: false,
                                    LayoutSettings: null,
                                    Name: 'BTT: DATE',
                                    SID: 'se152_3',
                                    Tag: null,
                                    UriScheme: null,
                                    Value: '2021-04-09',
                                },
                                {
                                    BaseType: 'DATETIME',
                                    DisplayValue: '2021-04-15T10:20:00.000Z',
                                    ExtraValue: null,
                                    HTMLValue: '',
                                    Help: 'DATETIME testveld: een invoerveld wordt getoond waarin een datum met een tijdstip ingevuld kan worden.',
                                    Hidden: false,
                                    ID: 980649,
                                    IsLink: false,
                                    LayoutSettings: null,
                                    Name: 'BTT: DATETIME',
                                    SID: 'se152_4',
                                    Tag: null,
                                    UriScheme: null,
                                    Value: '2021-04-15T10:20:00.000Z',
                                },
                                {
                                    BaseType: 'DECIMAL',
                                    DisplayValue: '44',
                                    ExtraValue: null,
                                    HTMLValue: '',
                                    Help: 'DECIMAL testveld: beschrijvinmg zoeken',
                                    Hidden: false,
                                    ID: 980651,
                                    IsLink: false,
                                    LayoutSettings: null,
                                    Name: 'BTT: DECIMAL',
                                    SID: 'se152_6',
                                    Tag: null,
                                    UriScheme: null,
                                    Value: '44',
                                },
                                {
                                    BaseType: 'STRING',
                                    DisplayValue: 'dfsdf',
                                    ExtraValue: null,
                                    HTMLValue: '',
                                    Help: 'STRING testveld: je mag hier alles invullen, voer getallen, vreemde tekens enz. in.',
                                    Hidden: false,
                                    ID: 980646,
                                    IsLink: false,
                                    LayoutSettings: null,
                                    Name: 'BTT: STRING',
                                    SID: 'se152',
                                    Tag: null,
                                    UriScheme: null,
                                    Value: 'dfsdf',
                                },
                            ],
                            [
                                {
                                    BaseType: 'BOOLEAN',
                                    DisplayValue: 'false',
                                    ExtraValue: null,
                                    HTMLValue: '',
                                    Help: 'Boolean testveld: er moet hier een checkbox in de applicatie getoond worden',
                                    Hidden: false,
                                    ID: 980647,
                                    IsLink: false,
                                    LayoutSettings: null,
                                    Name: 'BTT:  BOOLEAN',
                                    SID: 'se152_2',
                                    Tag: null,
                                    UriScheme: null,
                                    Value: 'false',
                                },
                                {
                                    BaseType: 'INTEGER',
                                    DisplayValue: '55',
                                    ExtraValue: null,
                                    HTMLValue: '',
                                    Help: 'Integer testveld: er mogen hier alleen maar getallen zonder decimalen en dergelijke ingevoerd worden.',
                                    Hidden: false,
                                    ID: 980652,
                                    IsLink: false,
                                    LayoutSettings: null,
                                    Name: 'BTT:  INTEGER',
                                    SID: 'se152_7',
                                    Tag: null,
                                    UriScheme: null,
                                    Value: '55',
                                },
                                {
                                    BaseType: 'TIME',
                                    DisplayValue: '07:15',
                                    ExtraValue: null,
                                    HTMLValue: '',
                                    Help: 'TIME testveld: de applicatie toont een invoerveld waarin UU:MM ingevoerd kan worden',
                                    Hidden: false,
                                    ID: 980650,
                                    IsLink: false,
                                    LayoutSettings: null,
                                    Name: 'BTT:  TIME',
                                    SID: 'se152_5',
                                    Tag: null,
                                    UriScheme: null,
                                    Value: '07:15',
                                },
                                {
                                    BaseType: 'DATE',
                                    DisplayValue: '2021-04-30',
                                    ExtraValue: null,
                                    HTMLValue: '',
                                    Help: 'DATE testveld: een datumkiezer/veld wordt door de applicatie getoond',
                                    Hidden: false,
                                    ID: 980648,
                                    IsLink: false,
                                    LayoutSettings: null,
                                    Name: 'BTT: DATE',
                                    SID: 'se152_3',
                                    Tag: null,
                                    UriScheme: null,
                                    Value: '2021-04-30',
                                },
                                {
                                    BaseType: 'DATETIME',
                                    DisplayValue: '2021-04-20T10:21:00.000Z',
                                    ExtraValue: null,
                                    HTMLValue: '',
                                    Help: 'DATETIME testveld: een invoerveld wordt getoond waarin een datum met een tijdstip ingevuld kan worden.',
                                    Hidden: false,
                                    ID: 980649,
                                    IsLink: false,
                                    LayoutSettings: null,
                                    Name: 'BTT: DATETIME',
                                    SID: 'se152_4',
                                    Tag: null,
                                    UriScheme: null,
                                    Value: '2021-04-20T10:21:00.000Z',
                                },
                                {
                                    BaseType: 'DECIMAL',
                                    DisplayValue: '55',
                                    ExtraValue: null,
                                    HTMLValue: '',
                                    Help: 'DECIMAL testveld: beschrijvinmg zoeken',
                                    Hidden: false,
                                    ID: 980651,
                                    IsLink: false,
                                    LayoutSettings: null,
                                    Name: 'BTT: DECIMAL',
                                    SID: 'se152_6',
                                    Tag: null,
                                    UriScheme: null,
                                    Value: '55',
                                },
                                {
                                    BaseType: 'STRING',
                                    DisplayValue: 'jee',
                                    ExtraValue: null,
                                    HTMLValue: '',
                                    Help: 'STRING testveld: je mag hier alles invullen, voer getallen, vreemde tekens enz. in.',
                                    Hidden: false,
                                    ID: 980646,
                                    IsLink: false,
                                    LayoutSettings: null,
                                    Name: 'BTT: STRING',
                                    SID: 'se152',
                                    Tag: null,
                                    UriScheme: null,
                                    Value: 'jee',
                                },
                            ],
                        ],
                        ID: 980713,
                        LayoutSettings: null,
                        Name: 'Inhoud1: BaseType verzameling',
                        SID: 'ce23',
                        Tag: null,
                    },
                ],
                Tag: null,
            },
            {
                Help: '',
                Hidden: false,
                ID: 980708,
                LayoutSettings: null,
                Name: 'Opmerkingen',
                SID: 'CE_Opmerkingen',
                SimpleElements: [
                    {
                        BaseType: 'STRING',
                        DisplayValue: '',
                        ExtraValue: null,
                        HTMLValue: '',
                        Help: 'Te maken opmerking per bericht invulbaar. Bij een nieuw bericht wordt deze steeds leeggemaakt.',
                        Hidden: false,
                        ID: 980627,
                        IsLink: false,
                        LayoutSettings: null,
                        Name: 'Eenmalige opmerkingen',
                        SID: 'SE_Opmerkingen',
                        Tag: null,
                        UriScheme: null,
                        Value: '',
                    },
                ],
                Tables: [],
                Tag: null,
            },
        ],
        ConceptID: 0,
        ConceptIsTakenOver: false,
        ConceptReplyToMessageID: 0,
        DateSend: '2021-10-01T12:20:58',
        Debug: false,
        DeletePossible: false,
        Direction: 'van 1 naar 2',
        DirectionAddress: { From: '1', OnBehalf: null, To: '2' },
        DirectionVisible: true,
        GebruikersID: 'pe_164bd47a-7789-463b-aded-0d56f6ab95a8',
        ID: 'Msg_47_2_2',
        Information: null,
        IsConcept: false,
        IsSetting: false,
        MITID: 2164306,
        MessageDirection: 0,
        MessageID: 777788,
        MessageNameVisible: true,
        Name: 'Inhoud 1: Harde FIXED reactie op Basetypes',
        ReplyPossible: false,
        Revoke: null,
        SetReady: true,
        ShowCreateProject: false,
        ShowUpdateProject: false,
        Sjablonen: [],
        SubTransPossible: false,
        Subject: 'hoooi 🧨',
        Tabs: {},
        Tag: null,
        TransactionID: 441309,
        TransactionInZip: false,
        TransactionNumber: '11021',
        TranstypeName: 'Inhoud 1: base type test',
        TranstypeSID: 'tra_10',
    },
    {
        Appendices: [],
        ButtonReply: true,
        ComplexElements: [
            {
                Help: '',
                Hidden: false,
                ID: 575502,
                LayoutSettings: null,
                Name: 'Inhoud1: BaseType verzameling',
                SID: 'ce23',
                SimpleElements: [
                    {
                        BaseType: 'BOOLEAN',
                        DisplayValue: 'false',
                        ExtraValue: null,
                        HTMLValue: '',
                        Help: 'Boolean testveld: er moet hier een checkbox in de applicatie getoond worden',
                        Hidden: false,
                        ID: 575437,
                        IsLink: false,
                        LayoutSettings: null,
                        Name: 'BTT:  BOOLEAN',
                        SID: 'se152_2',
                        Tag: null,
                        UriScheme: null,
                        Value: 'false',
                    },
                    {
                        BaseType: 'INTEGER',
                        DisplayValue: '1',
                        ExtraValue: null,
                        HTMLValue: '',
                        Help: 'Integer testveld: er mogen hier alleen maar getallen zonder decimalen en dergelijke ingevoerd worden.',
                        Hidden: false,
                        ID: 575442,
                        IsLink: false,
                        LayoutSettings: null,
                        Name: 'BTT:  INTEGER',
                        SID: 'se152_7',
                        Tag: null,
                        UriScheme: null,
                        Value: '1',
                    },
                    {
                        BaseType: 'TIME',
                        DisplayValue: '05:15',
                        ExtraValue: null,
                        HTMLValue: '',
                        Help: 'TIME testveld: de applicatie toont een invoerveld waarin UU:MM ingevoerd kan worden',
                        Hidden: false,
                        ID: 575440,
                        IsLink: false,
                        LayoutSettings: null,
                        Name: 'BTT:  TIME',
                        SID: 'se152_5',
                        Tag: null,
                        UriScheme: null,
                        Value: '05:15',
                    },
                    {
                        BaseType: 'DATE',
                        DisplayValue: '2020-10-14',
                        ExtraValue: null,
                        HTMLValue: '',
                        Help: 'DATE testveld: een datumkiezer/veld wordt door de applicatie getoond',
                        Hidden: false,
                        ID: 575438,
                        IsLink: false,
                        LayoutSettings: null,
                        Name: 'BTT: DATE',
                        SID: 'se152_3',
                        Tag: null,
                        UriScheme: null,
                        Value: '2020-10-14',
                    },
                    {
                        BaseType: 'DATETIME',
                        DisplayValue: '2020-10-23T00:00:00+02:00',
                        ExtraValue: null,
                        HTMLValue: '',
                        Help: 'DATETIME testveld: een invoerveld wordt getoond waarin een datum met een tijdstip ingevuld kan worden.',
                        Hidden: false,
                        ID: 575439,
                        IsLink: false,
                        LayoutSettings: null,
                        Name: 'BTT: DATETIME',
                        SID: 'se152_4',
                        Tag: null,
                        UriScheme: null,
                        Value: '2020-10-23T00:00:00+02:00',
                    },
                    {
                        BaseType: 'DECIMAL',
                        DisplayValue: '23123123',
                        ExtraValue: null,
                        HTMLValue: '',
                        Help: 'DECIMAL testveld: beschrijvinmg zoeken',
                        Hidden: false,
                        ID: 575441,
                        IsLink: false,
                        LayoutSettings: null,
                        Name: 'BTT: DECIMAL',
                        SID: 'se152_6',
                        Tag: null,
                        UriScheme: null,
                        Value: '23123123',
                    },
                    {
                        BaseType: 'STRING',
                        DisplayValue: '32123addas',
                        ExtraValue: null,
                        HTMLValue: '',
                        Help: 'STRING testveld: je mag hier alles invullen, voer getallen, vreemde tekens enz. in.',
                        Hidden: false,
                        ID: 575436,
                        IsLink: false,
                        LayoutSettings: null,
                        Name: 'BTT: STRING',
                        SID: 'se152',
                        Tag: null,
                        UriScheme: null,
                        Value: '32123addas',
                    },
                ],
                Tables: [],
                Tag: null,
            },
            {
                Help: '',
                Hidden: false,
                ID: 575503,
                LayoutSettings: null,
                Name: 'Inhoud 1: base type tabel',
                SID: 'ce24',
                SimpleElements: [],
                Tables: [
                    {
                        Columns: [
                            {
                                BaseType: 'BOOLEAN',
                                DisplayValue: null,
                                ExtraValue: null,
                                HTMLValue: null,
                                Help: null,
                                Hidden: false,
                                ID: 575437,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'BTT:  BOOLEAN',
                                SID: 'se152_2',
                                Tag: null,
                                UriScheme: null,
                                Value: null,
                            },
                            {
                                BaseType: 'INTEGER',
                                DisplayValue: null,
                                ExtraValue: null,
                                HTMLValue: null,
                                Help: null,
                                Hidden: false,
                                ID: 575442,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'BTT:  INTEGER',
                                SID: 'se152_7',
                                Tag: null,
                                UriScheme: null,
                                Value: null,
                            },
                            {
                                BaseType: 'TIME',
                                DisplayValue: null,
                                ExtraValue: null,
                                HTMLValue: null,
                                Help: null,
                                Hidden: false,
                                ID: 575440,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'BTT:  TIME',
                                SID: 'se152_5',
                                Tag: null,
                                UriScheme: null,
                                Value: null,
                            },
                            {
                                BaseType: 'DATE',
                                DisplayValue: null,
                                ExtraValue: null,
                                HTMLValue: null,
                                Help: null,
                                Hidden: false,
                                ID: 575438,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'BTT: DATE',
                                SID: 'se152_3',
                                Tag: null,
                                UriScheme: null,
                                Value: null,
                            },
                            {
                                BaseType: 'DATETIME',
                                DisplayValue: null,
                                ExtraValue: null,
                                HTMLValue: null,
                                Help: null,
                                Hidden: false,
                                ID: 575439,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'BTT: DATETIME',
                                SID: 'se152_4',
                                Tag: null,
                                UriScheme: null,
                                Value: null,
                            },
                            {
                                BaseType: 'DECIMAL',
                                DisplayValue: null,
                                ExtraValue: null,
                                HTMLValue: null,
                                Help: null,
                                Hidden: false,
                                ID: 575441,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'BTT: DECIMAL',
                                SID: 'se152_6',
                                Tag: null,
                                UriScheme: null,
                                Value: null,
                            },
                            {
                                BaseType: 'STRING',
                                DisplayValue: null,
                                ExtraValue: null,
                                HTMLValue: null,
                                Help: null,
                                Hidden: false,
                                ID: 575436,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'BTT: STRING',
                                SID: 'se152',
                                Tag: null,
                                UriScheme: null,
                                Value: null,
                            },
                        ],
                        Data: [
                            [
                                {
                                    BaseType: 'BOOLEAN',
                                    DisplayValue: 'false',
                                    ExtraValue: null,
                                    HTMLValue: '',
                                    Help: 'Boolean testveld: er moet hier een checkbox in de applicatie getoond worden',
                                    Hidden: false,
                                    ID: 575437,
                                    IsLink: false,
                                    LayoutSettings: null,
                                    Name: 'BTT:  BOOLEAN',
                                    SID: 'se152_2',
                                    Tag: null,
                                    UriScheme: null,
                                    Value: 'false',
                                },
                                {
                                    BaseType: 'INTEGER',
                                    DisplayValue: '1',
                                    ExtraValue: null,
                                    HTMLValue: '',
                                    Help: 'Integer testveld: er mogen hier alleen maar getallen zonder decimalen en dergelijke ingevoerd worden.',
                                    Hidden: false,
                                    ID: 575442,
                                    IsLink: false,
                                    LayoutSettings: null,
                                    Name: 'BTT:  INTEGER',
                                    SID: 'se152_7',
                                    Tag: null,
                                    UriScheme: null,
                                    Value: '1',
                                },
                                {
                                    BaseType: 'TIME',
                                    DisplayValue: '06:30',
                                    ExtraValue: null,
                                    HTMLValue: '',
                                    Help: 'TIME testveld: de applicatie toont een invoerveld waarin UU:MM ingevoerd kan worden',
                                    Hidden: false,
                                    ID: 575440,
                                    IsLink: false,
                                    LayoutSettings: null,
                                    Name: 'BTT:  TIME',
                                    SID: 'se152_5',
                                    Tag: null,
                                    UriScheme: null,
                                    Value: '06:30',
                                },
                                {
                                    BaseType: 'DATE',
                                    DisplayValue: '2020-10-15',
                                    ExtraValue: null,
                                    HTMLValue: '',
                                    Help: 'DATE testveld: een datumkiezer/veld wordt door de applicatie getoond',
                                    Hidden: false,
                                    ID: 575438,
                                    IsLink: false,
                                    LayoutSettings: null,
                                    Name: 'BTT: DATE',
                                    SID: 'se152_3',
                                    Tag: null,
                                    UriScheme: null,
                                    Value: '2020-10-15',
                                },
                                {
                                    BaseType: 'DATETIME',
                                    DisplayValue: '2020-10-22T07:53:00.000Z',
                                    ExtraValue: null,
                                    HTMLValue: '',
                                    Help: 'DATETIME testveld: een invoerveld wordt getoond waarin een datum met een tijdstip ingevuld kan worden.',
                                    Hidden: false,
                                    ID: 575439,
                                    IsLink: false,
                                    LayoutSettings: null,
                                    Name: 'BTT: DATETIME',
                                    SID: 'se152_4',
                                    Tag: null,
                                    UriScheme: null,
                                    Value: '2020-10-22T07:53:00.000Z',
                                },
                                {
                                    BaseType: 'DECIMAL',
                                    DisplayValue: '123',
                                    ExtraValue: null,
                                    HTMLValue: '',
                                    Help: 'DECIMAL testveld: beschrijvinmg zoeken',
                                    Hidden: false,
                                    ID: 575441,
                                    IsLink: false,
                                    LayoutSettings: null,
                                    Name: 'BTT: DECIMAL',
                                    SID: 'se152_6',
                                    Tag: null,
                                    UriScheme: null,
                                    Value: '123',
                                },
                                {
                                    BaseType: 'STRING',
                                    DisplayValue: '123adsasd',
                                    ExtraValue: null,
                                    HTMLValue: '',
                                    Help: 'STRING testveld: je mag hier alles invullen, voer getallen, vreemde tekens enz. in.',
                                    Hidden: false,
                                    ID: 575436,
                                    IsLink: false,
                                    LayoutSettings: null,
                                    Name: 'BTT: STRING',
                                    SID: 'se152',
                                    Tag: null,
                                    UriScheme: null,
                                    Value: '123adsasd',
                                },
                            ],
                            [
                                {
                                    BaseType: 'BOOLEAN',
                                    DisplayValue: 'true',
                                    ExtraValue: null,
                                    HTMLValue: '',
                                    Help: 'Boolean testveld: er moet hier een checkbox in de applicatie getoond worden',
                                    Hidden: false,
                                    ID: 575437,
                                    IsLink: false,
                                    LayoutSettings: null,
                                    Name: 'BTT:  BOOLEAN',
                                    SID: 'se152_2',
                                    Tag: null,
                                    UriScheme: null,
                                    Value: 'true',
                                },
                                {
                                    BaseType: 'INTEGER',
                                    DisplayValue: '2',
                                    ExtraValue: null,
                                    HTMLValue: '',
                                    Help: 'Integer testveld: er mogen hier alleen maar getallen zonder decimalen en dergelijke ingevoerd worden.',
                                    Hidden: false,
                                    ID: 575442,
                                    IsLink: false,
                                    LayoutSettings: null,
                                    Name: 'BTT:  INTEGER',
                                    SID: 'se152_7',
                                    Tag: null,
                                    UriScheme: null,
                                    Value: '2',
                                },
                                {
                                    BaseType: 'TIME',
                                    DisplayValue: '06:45',
                                    ExtraValue: null,
                                    HTMLValue: '',
                                    Help: 'TIME testveld: de applicatie toont een invoerveld waarin UU:MM ingevoerd kan worden',
                                    Hidden: false,
                                    ID: 575440,
                                    IsLink: false,
                                    LayoutSettings: null,
                                    Name: 'BTT:  TIME',
                                    SID: 'se152_5',
                                    Tag: null,
                                    UriScheme: null,
                                    Value: '06:45',
                                },
                                {
                                    BaseType: 'DATE',
                                    DisplayValue: '2020-10-27',
                                    ExtraValue: null,
                                    HTMLValue: '',
                                    Help: 'DATE testveld: een datumkiezer/veld wordt door de applicatie getoond',
                                    Hidden: false,
                                    ID: 575438,
                                    IsLink: false,
                                    LayoutSettings: null,
                                    Name: 'BTT: DATE',
                                    SID: 'se152_3',
                                    Tag: null,
                                    UriScheme: null,
                                    Value: '2020-10-27',
                                },
                                {
                                    BaseType: 'DATETIME',
                                    DisplayValue: '2020-10-12T07:56:00.000Z',
                                    ExtraValue: null,
                                    HTMLValue: '',
                                    Help: 'DATETIME testveld: een invoerveld wordt getoond waarin een datum met een tijdstip ingevuld kan worden.',
                                    Hidden: false,
                                    ID: 575439,
                                    IsLink: false,
                                    LayoutSettings: null,
                                    Name: 'BTT: DATETIME',
                                    SID: 'se152_4',
                                    Tag: null,
                                    UriScheme: null,
                                    Value: '2020-10-12T07:56:00.000Z',
                                },
                                {
                                    BaseType: 'DECIMAL',
                                    DisplayValue: '322',
                                    ExtraValue: null,
                                    HTMLValue: '',
                                    Help: 'DECIMAL testveld: beschrijvinmg zoeken',
                                    Hidden: false,
                                    ID: 575441,
                                    IsLink: false,
                                    LayoutSettings: null,
                                    Name: 'BTT: DECIMAL',
                                    SID: 'se152_6',
                                    Tag: null,
                                    UriScheme: null,
                                    Value: '322',
                                },
                                {
                                    BaseType: 'STRING',
                                    DisplayValue: '123asd',
                                    ExtraValue: null,
                                    HTMLValue: '',
                                    Help: 'STRING testveld: je mag hier alles invullen, voer getallen, vreemde tekens enz. in.',
                                    Hidden: false,
                                    ID: 575436,
                                    IsLink: false,
                                    LayoutSettings: null,
                                    Name: 'BTT: STRING',
                                    SID: 'se152',
                                    Tag: null,
                                    UriScheme: null,
                                    Value: '123asd',
                                },
                            ],
                        ],
                        ID: 575502,
                        LayoutSettings: null,
                        Name: 'Inhoud1: BaseType verzameling',
                        SID: 'ce23',
                        Tag: null,
                    },
                ],
                Tag: null,
            },
            {
                Help: '',
                Hidden: false,
                ID: 575497,
                LayoutSettings: null,
                Name: 'Opmerkingen',
                SID: 'CE_Opmerkingen',
                SimpleElements: [
                    {
                        BaseType: 'STRING',
                        DisplayValue: 'asdasd',
                        ExtraValue: null,
                        HTMLValue: '',
                        Help: 'Te maken opmerking per bericht invulbaar. Bij een nieuw bericht wordt deze steeds leeggemaakt.',
                        Hidden: false,
                        ID: 575417,
                        IsLink: false,
                        LayoutSettings: null,
                        Name: 'Eenmalige opmerkingen',
                        SID: 'SE_Opmerkingen',
                        Tag: null,
                        UriScheme: null,
                        Value: 'asdasd',
                    },
                ],
                Tables: [],
                Tag: null,
            },
        ],
        ConceptID: 0,
        ConceptIsTakenOver: false,
        ConceptReplyToMessageID: 0,
        DateSend: '2021-12-20T14:08:34',
        Debug: false,
        DeletePossible: false,
        Direction: 'van 1 naar 2',
        DirectionAddress: { From: '1', OnBehalf: null, To: '2' },
        DirectionVisible: true,
        GebruikersID: 'pe_164bd47a-7789-463b-aded-0d56f6ab95a8',
        ID: 'Msg_47_2_2',
        Information: null,
        IsConcept: false,
        IsSetting: false,
        MITID: 1226448,
        MessageDirection: 0,
        MessageID: 850123,
        MessageNameVisible: true,
        Name: 'Inhoud 1: Harde FIXED reactie op Basetypes',
        ReplyPossible: false,
        Revoke: null,
        SetReady: true,
        ShowCreateProject: false,
        ShowUpdateProject: false,
        Sjablonen: [],
        SubTransPossible: false,
        Subject: 'test s',
        Tabs: {},
        Tag: null,
        TransactionID: 342299,
        TransactionInZip: false,
        TransactionNumber: '10893',
        TranstypeName: 'Inhoud 1: base type test',
        TranstypeSID: 'tra_10',
    },
    {
        Appendices: [],
        ButtonReply: true,
        ComplexElements: [
            {
                Help: '',
                Hidden: false,
                ID: 980720,
                LayoutSettings: null,
                Name: 'Inhoud 4: User defined types basis',
                SID: 'ce26',
                SimpleElements: [
                    {
                        BaseType: 'STRING',
                        DisplayValue: 'Niet van toepassing',
                        ExtraValue: null,
                        HTMLValue: '',
                        Help: 'Je moet hier een lijst met straatnamen zien waar je uit kunt kiezen. Als je een deel van de naam typt, moet er automatisch gefilterd worden op die letters. Een niet bestaande straatnaam mag niet verstuurd worden.',
                        Hidden: false,
                        ID: 980643,
                        IsLink: false,
                        LayoutSettings: null,
                        Name: 'UDT lange keuzelijst: Straatnaam',
                        SID: 'Se_Straatnaam',
                        Tag: null,
                        UriScheme: null,
                        Value: 'Niet van toepassing',
                    },
                    {
                        BaseType: 'STRING',
                        DisplayValue: 'Automatisch gevuld',
                        ExtraValue: null,
                        HTMLValue: '',
                        Help: 'Hier moet automatischh de waarde uit het raamwerk staan',
                        Hidden: false,
                        ID: 980661,
                        IsLink: false,
                        LayoutSettings: null,
                        Name: 'UDT Keuzelijst 1 optie',
                        SID: 'se155_3_2',
                        Tag: null,
                        UriScheme: null,
                        Value: 'Automatisch gevuld',
                    },
                    {
                        BaseType: 'STRING',
                        DisplayValue: 'is ingevuld door wizard',
                        ExtraValue: null,
                        HTMLValue: '',
                        Help: 'Contractnummer van de opdrachtgever waar het product of levering onder bekend staat',
                        Hidden: false,
                        ID: 980615,
                        IsLink: false,
                        LayoutSettings: null,
                        Name: 'UDT Keuzelijst 1 optie andere vorm',
                        SID: 'SE_Contractnummer',
                        Tag: null,
                        UriScheme: null,
                        Value: 'is ingevuld door wizard',
                    },
                    {
                        BaseType: 'STRING',
                        DisplayValue: '',
                        ExtraValue: null,
                        HTMLValue: '',
                        Help: '',
                        Hidden: false,
                        ID: 980687,
                        IsLink: false,
                        LayoutSettings: null,
                        Name: 'UDT Optionele keuzelijst',
                        SID: 'se168',
                        Tag: null,
                        UriScheme: null,
                        Value: '',
                    },
                    {
                        BaseType: 'STRING',
                        DisplayValue: '2000',
                        ExtraValue: null,
                        HTMLValue: '',
                        Help: 'je mag alleen maar jaarnummers invullen tussen 2000 en 2099',
                        Hidden: false,
                        ID: 980658,
                        IsLink: false,
                        LayoutSettings: null,
                        Name: 'UDT Jaarnummer  optioneel',
                        SID: 'se155',
                        Tag: null,
                        UriScheme: null,
                        Value: '2000',
                    },
                    {
                        BaseType: 'STRING',
                        DisplayValue: '2000',
                        ExtraValue: null,
                        HTMLValue: '',
                        Help: 'je moet hier een jaarnummer invullen tussen 2000 en 2099',
                        Hidden: false,
                        ID: 980659,
                        IsLink: false,
                        LayoutSettings: null,
                        Name: 'UDT Jaarnummer verplicht',
                        SID: 'se155_2',
                        Tag: null,
                        UriScheme: null,
                        Value: '2000',
                    },
                    {
                        BaseType: 'STRING',
                        DisplayValue: '',
                        ExtraValue: null,
                        HTMLValue: '',
                        Help: 'Je mag hier een getal tussen de 1 en 53 invullen',
                        Hidden: false,
                        ID: 980660,
                        IsLink: false,
                        LayoutSettings: null,
                        Name: 'UDT Weeknummer optioneel',
                        SID: 'se155_3',
                        Tag: null,
                        UriScheme: null,
                        Value: '',
                    },
                    {
                        BaseType: 'STRING',
                        DisplayValue: '11',
                        ExtraValue: null,
                        HTMLValue: '',
                        Help: 'Je moet hier een getal tussen de 1 en 53 invullen',
                        Hidden: false,
                        ID: 980662,
                        IsLink: false,
                        LayoutSettings: null,
                        Name: 'UDT Weeknummer verplicht',
                        SID: 'se155_4',
                        Tag: null,
                        UriScheme: null,
                        Value: '11',
                    },
                    {
                        BaseType: 'STRING',
                        DisplayValue: '',
                        ExtraValue: null,
                        HTMLValue: '',
                        Help: 'je mag hier niets invullen of gehele getallen van 5 posities',
                        Hidden: false,
                        ID: 980663,
                        IsLink: false,
                        LayoutSettings: null,
                        Name: 'UDT Lengte in meters optioneel',
                        SID: 'se155_4_2',
                        Tag: null,
                        UriScheme: null,
                        Value: '',
                    },
                    {
                        BaseType: 'STRING',
                        DisplayValue: '11',
                        ExtraValue: null,
                        HTMLValue: '',
                        Help: 'je moet hier gehele getallen van 5 posities',
                        Hidden: false,
                        ID: 980664,
                        IsLink: false,
                        LayoutSettings: null,
                        Name: 'UDT Lengte in meters verplicht',
                        SID: 'se155_4_2_2',
                        Tag: null,
                        UriScheme: null,
                        Value: '11',
                    },
                    {
                        BaseType: 'STRING',
                        DisplayValue: '',
                        ExtraValue: null,
                        HTMLValue: '',
                        Help: 'Je mag hier een geldbedrag invullen, waarbij duizendtalscheidingen optioneel zijn, maar een komma en twee decimalen verplicht',
                        Hidden: false,
                        ID: 980666,
                        IsLink: false,
                        LayoutSettings: null,
                        Name: "UDT Euro's optioneel",
                        SID: 'se155_4_2_2_2_2',
                        Tag: null,
                        UriScheme: null,
                        Value: '',
                    },
                    {
                        BaseType: 'STRING',
                        DisplayValue: '11,00',
                        ExtraValue: null,
                        HTMLValue: '',
                        Help: 'Je mag hier een geldbedrag invullen, waarbij duizendtalscheidingen optioneel zijn, maar een komma en twee decimalen verplicht',
                        Hidden: false,
                        ID: 980665,
                        IsLink: false,
                        LayoutSettings: null,
                        Name: "UDT Euro's verplicht",
                        SID: 'se155_4_2_2_2',
                        Tag: null,
                        UriScheme: null,
                        Value: '11,00',
                    },
                    {
                        BaseType: 'STRING',
                        DisplayValue: '11,00',
                        ExtraValue: null,
                        HTMLValue: '',
                        Help: 'Je mag hier een geldbedrag invullen, waarbij duizendtalscheidingen optioneel zijn, maar een komma en twee decimalen verplicht',
                        Hidden: false,
                        ID: 980669,
                        IsLink: false,
                        LayoutSettings: null,
                        Name: 'UDT Dollars verplicht',
                        SID: 'se155_4_2_2_2_3',
                        Tag: null,
                        UriScheme: null,
                        Value: '11,00',
                    },
                    {
                        BaseType: 'STRING',
                        DisplayValue: '',
                        ExtraValue: null,
                        HTMLValue: '',
                        Help: 'je mag hier vanalles intypen',
                        Hidden: false,
                        ID: 980667,
                        IsLink: false,
                        LayoutSettings: null,
                        Name: 'UDT Optionele tekst',
                        SID: 'se155_4_2_2_2_2_2',
                        Tag: null,
                        UriScheme: null,
                        Value: '',
                    },
                    {
                        BaseType: 'STRING',
                        DisplayValue: '11',
                        ExtraValue: null,
                        HTMLValue: '',
                        Help: 'je moet hier iets intypen, maar bent vrij in wat je typt.',
                        Hidden: false,
                        ID: 980668,
                        IsLink: false,
                        LayoutSettings: null,
                        Name: 'UDT Verplichte tekst',
                        SID: 'se155_4_2_2_2_2_2_2',
                        Tag: null,
                        UriScheme: null,
                        Value: '11',
                    },
                    {
                        BaseType: 'STRING',
                        DisplayValue: '',
                        ExtraValue: null,
                        HTMLValue: '',
                        Help: '',
                        Hidden: false,
                        ID: 980670,
                        IsLink: false,
                        LayoutSettings: null,
                        Name: 'UDT Kilometrering optioneel (#,###)',
                        SID: 'se155_4_3',
                        Tag: null,
                        UriScheme: null,
                        Value: '',
                    },
                    {
                        BaseType: 'STRING',
                        DisplayValue: '11,123',
                        ExtraValue: null,
                        HTMLValue: '',
                        Help: '',
                        Hidden: false,
                        ID: 980671,
                        IsLink: false,
                        LayoutSettings: null,
                        Name: 'UDT Kilometrering verplicht (#,###)',
                        SID: 'se155_4_3_2',
                        Tag: null,
                        UriScheme: null,
                        Value: '11,123',
                    },
                    {
                        BaseType: 'STRING',
                        DisplayValue: '11,123',
                        ExtraValue: null,
                        HTMLValue: '',
                        Help: '',
                        Hidden: false,
                        ID: 980688,
                        IsLink: false,
                        LayoutSettings: null,
                        Name: 'UDT min4 max 8',
                        SID: 'se168_2',
                        Tag: null,
                        UriScheme: null,
                        Value: '11,123',
                    },
                    {
                        BaseType: 'STRING',
                        DisplayValue: '',
                        ExtraValue: null,
                        HTMLValue: '',
                        Help: '',
                        Hidden: false,
                        ID: 980610,
                        IsLink: false,
                        LayoutSettings: null,
                        Name: 'UDT Optioneel datumveld',
                        SID: 'SE_8',
                        Tag: null,
                        UriScheme: null,
                        Value: '',
                    },
                    {
                        BaseType: 'STRING',
                        DisplayValue: '',
                        ExtraValue: null,
                        HTMLValue:
                            '<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">\r\n<html xmlns="http://www.w3.org/1999/xhtml">\r\n\t<head>\r\n\t\t<meta http-equiv="Content-Type" content="text/html; charset=utf-8" /><title>\r\n\t\t</title>\r\n\t\t<style type="text/css">\r\n\t\t\t.cs95E872D0{text-align:left;text-indent:0pt;margin:0pt 0pt 0pt 0pt}\r\n\t\t\t.csFB7D5B92{color:#000000;background-color:transparent;font-family:Tahoma;font-size:8pt;font-weight:normal;font-style:normal;}\r\n\t\t</style>\r\n\t</head>\r\n\t<body>\r\n\t\t<p class="cs95E872D0"><span class="csFB7D5B92">&nbsp;</span></p></body>\r\n</html>\r\n',
                        Help: '',
                        Hidden: false,
                        ID: 980672,
                        IsLink: false,
                        LayoutSettings: null,
                        Name: 'UDT HTML veld',
                        SID: 'se155_4_4',
                        Tag: null,
                        UriScheme: null,
                        Value: '',
                    },
                ],
                Tables: [
                    {
                        Columns: [
                            {
                                BaseType: 'STRING',
                                DisplayValue: null,
                                ExtraValue: null,
                                HTMLValue: null,
                                Help: null,
                                Hidden: false,
                                ID: 980643,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'UDT lange keuzelijst: Straatnaam',
                                SID: 'Se_Straatnaam',
                                Tag: null,
                                UriScheme: null,
                                Value: null,
                            },
                            {
                                BaseType: 'STRING',
                                DisplayValue: null,
                                ExtraValue: null,
                                HTMLValue: null,
                                Help: null,
                                Hidden: false,
                                ID: 980661,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'UDT Keuzelijst 1 optie',
                                SID: 'se155_3_2',
                                Tag: null,
                                UriScheme: null,
                                Value: null,
                            },
                            {
                                BaseType: 'STRING',
                                DisplayValue: null,
                                ExtraValue: null,
                                HTMLValue: null,
                                Help: null,
                                Hidden: false,
                                ID: 980615,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'UDT Keuzelijst 1 optie andere vorm',
                                SID: 'SE_Contractnummer',
                                Tag: null,
                                UriScheme: null,
                                Value: null,
                            },
                            {
                                BaseType: 'STRING',
                                DisplayValue: null,
                                ExtraValue: null,
                                HTMLValue: null,
                                Help: null,
                                Hidden: false,
                                ID: 980687,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'UDT Optionele keuzelijst',
                                SID: 'se168',
                                Tag: null,
                                UriScheme: null,
                                Value: null,
                            },
                            {
                                BaseType: 'STRING',
                                DisplayValue: null,
                                ExtraValue: null,
                                HTMLValue: null,
                                Help: null,
                                Hidden: false,
                                ID: 980658,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'UDT Jaarnummer  optioneel',
                                SID: 'se155',
                                Tag: null,
                                UriScheme: null,
                                Value: null,
                            },
                            {
                                BaseType: 'STRING',
                                DisplayValue: null,
                                ExtraValue: null,
                                HTMLValue: null,
                                Help: null,
                                Hidden: false,
                                ID: 980659,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'UDT Jaarnummer verplicht',
                                SID: 'se155_2',
                                Tag: null,
                                UriScheme: null,
                                Value: null,
                            },
                            {
                                BaseType: 'STRING',
                                DisplayValue: null,
                                ExtraValue: null,
                                HTMLValue: null,
                                Help: null,
                                Hidden: false,
                                ID: 980660,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'UDT Weeknummer optioneel',
                                SID: 'se155_3',
                                Tag: null,
                                UriScheme: null,
                                Value: null,
                            },
                            {
                                BaseType: 'STRING',
                                DisplayValue: null,
                                ExtraValue: null,
                                HTMLValue: null,
                                Help: null,
                                Hidden: false,
                                ID: 980662,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'UDT Weeknummer verplicht',
                                SID: 'se155_4',
                                Tag: null,
                                UriScheme: null,
                                Value: null,
                            },
                            {
                                BaseType: 'STRING',
                                DisplayValue: null,
                                ExtraValue: null,
                                HTMLValue: null,
                                Help: null,
                                Hidden: false,
                                ID: 980663,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'UDT Lengte in meters optioneel',
                                SID: 'se155_4_2',
                                Tag: null,
                                UriScheme: null,
                                Value: null,
                            },
                            {
                                BaseType: 'STRING',
                                DisplayValue: null,
                                ExtraValue: null,
                                HTMLValue: null,
                                Help: null,
                                Hidden: false,
                                ID: 980664,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'UDT Lengte in meters verplicht',
                                SID: 'se155_4_2_2',
                                Tag: null,
                                UriScheme: null,
                                Value: null,
                            },
                            {
                                BaseType: 'STRING',
                                DisplayValue: null,
                                ExtraValue: null,
                                HTMLValue: null,
                                Help: null,
                                Hidden: false,
                                ID: 980666,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: "UDT Euro's optioneel",
                                SID: 'se155_4_2_2_2_2',
                                Tag: null,
                                UriScheme: null,
                                Value: null,
                            },
                            {
                                BaseType: 'STRING',
                                DisplayValue: null,
                                ExtraValue: null,
                                HTMLValue: null,
                                Help: null,
                                Hidden: false,
                                ID: 980665,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: "UDT Euro's verplicht",
                                SID: 'se155_4_2_2_2',
                                Tag: null,
                                UriScheme: null,
                                Value: null,
                            },
                            {
                                BaseType: 'STRING',
                                DisplayValue: null,
                                ExtraValue: null,
                                HTMLValue: null,
                                Help: null,
                                Hidden: false,
                                ID: 980669,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'UDT Dollars verplicht',
                                SID: 'se155_4_2_2_2_3',
                                Tag: null,
                                UriScheme: null,
                                Value: null,
                            },
                            {
                                BaseType: 'STRING',
                                DisplayValue: null,
                                ExtraValue: null,
                                HTMLValue: null,
                                Help: null,
                                Hidden: false,
                                ID: 980667,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'UDT Optionele tekst',
                                SID: 'se155_4_2_2_2_2_2',
                                Tag: null,
                                UriScheme: null,
                                Value: null,
                            },
                            {
                                BaseType: 'STRING',
                                DisplayValue: null,
                                ExtraValue: null,
                                HTMLValue: null,
                                Help: null,
                                Hidden: false,
                                ID: 980668,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'UDT Verplichte tekst',
                                SID: 'se155_4_2_2_2_2_2_2',
                                Tag: null,
                                UriScheme: null,
                                Value: null,
                            },
                            {
                                BaseType: 'STRING',
                                DisplayValue: null,
                                ExtraValue: null,
                                HTMLValue: null,
                                Help: null,
                                Hidden: false,
                                ID: 980670,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'UDT Kilometrering optioneel (#,###)',
                                SID: 'se155_4_3',
                                Tag: null,
                                UriScheme: null,
                                Value: null,
                            },
                            {
                                BaseType: 'STRING',
                                DisplayValue: null,
                                ExtraValue: null,
                                HTMLValue: null,
                                Help: null,
                                Hidden: false,
                                ID: 980671,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'UDT Kilometrering verplicht (#,###)',
                                SID: 'se155_4_3_2',
                                Tag: null,
                                UriScheme: null,
                                Value: null,
                            },
                            {
                                BaseType: 'STRING',
                                DisplayValue: null,
                                ExtraValue: null,
                                HTMLValue: null,
                                Help: null,
                                Hidden: false,
                                ID: 980688,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'UDT min4 max 8',
                                SID: 'se168_2',
                                Tag: null,
                                UriScheme: null,
                                Value: null,
                            },
                            {
                                BaseType: 'STRING',
                                DisplayValue: null,
                                ExtraValue: null,
                                HTMLValue: null,
                                Help: null,
                                Hidden: false,
                                ID: 980672,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'UDT HTML veld',
                                SID: 'se155_4_4',
                                Tag: null,
                                UriScheme: null,
                                Value: null,
                            },
                        ],
                        Data: [
                            [
                                {
                                    BaseType: 'STRING',
                                    DisplayValue: 'Niet van toepassing',
                                    ExtraValue: null,
                                    HTMLValue: '',
                                    Help: 'Je moet hier een lijst met straatnamen zien waar je uit kunt kiezen. Als je een deel van de naam typt, moet er automatisch gefilterd worden op die letters. Een niet bestaande straatnaam mag niet verstuurd worden.',
                                    Hidden: false,
                                    ID: 980643,
                                    IsLink: false,
                                    LayoutSettings: null,
                                    Name: 'UDT lange keuzelijst: Straatnaam',
                                    SID: 'Se_Straatnaam',
                                    Tag: null,
                                    UriScheme: null,
                                    Value: 'Niet van toepassing',
                                },
                                {
                                    BaseType: 'STRING',
                                    DisplayValue: 'Automatisch gevuld',
                                    ExtraValue: null,
                                    HTMLValue: '',
                                    Help: 'Hier moet automatischh de waarde uit het raamwerk staan',
                                    Hidden: false,
                                    ID: 980661,
                                    IsLink: false,
                                    LayoutSettings: null,
                                    Name: 'UDT Keuzelijst 1 optie',
                                    SID: 'se155_3_2',
                                    Tag: null,
                                    UriScheme: null,
                                    Value: 'Automatisch gevuld',
                                },
                                {
                                    BaseType: 'STRING',
                                    DisplayValue: 'is ingevuld door wizard',
                                    ExtraValue: null,
                                    HTMLValue: '',
                                    Help: 'Contractnummer van de opdrachtgever waar het product of levering onder bekend staat',
                                    Hidden: false,
                                    ID: 980615,
                                    IsLink: false,
                                    LayoutSettings: null,
                                    Name: 'UDT Keuzelijst 1 optie andere vorm',
                                    SID: 'SE_Contractnummer',
                                    Tag: null,
                                    UriScheme: null,
                                    Value: 'is ingevuld door wizard',
                                },
                                {
                                    BaseType: 'STRING',
                                    DisplayValue: '',
                                    ExtraValue: null,
                                    HTMLValue: '',
                                    Help: '',
                                    Hidden: false,
                                    ID: 980687,
                                    IsLink: false,
                                    LayoutSettings: null,
                                    Name: 'UDT Optionele keuzelijst',
                                    SID: 'se168',
                                    Tag: null,
                                    UriScheme: null,
                                    Value: '',
                                },
                                {
                                    BaseType: 'STRING',
                                    DisplayValue: '2000',
                                    ExtraValue: null,
                                    HTMLValue: '',
                                    Help: 'je mag alleen maar jaarnummers invullen tussen 2000 en 2099',
                                    Hidden: false,
                                    ID: 980658,
                                    IsLink: false,
                                    LayoutSettings: null,
                                    Name: 'UDT Jaarnummer  optioneel',
                                    SID: 'se155',
                                    Tag: null,
                                    UriScheme: null,
                                    Value: '2000',
                                },
                                {
                                    BaseType: 'STRING',
                                    DisplayValue: '2000',
                                    ExtraValue: null,
                                    HTMLValue: '',
                                    Help: 'je moet hier een jaarnummer invullen tussen 2000 en 2099',
                                    Hidden: false,
                                    ID: 980659,
                                    IsLink: false,
                                    LayoutSettings: null,
                                    Name: 'UDT Jaarnummer verplicht',
                                    SID: 'se155_2',
                                    Tag: null,
                                    UriScheme: null,
                                    Value: '2000',
                                },
                                {
                                    BaseType: 'STRING',
                                    DisplayValue: '2',
                                    ExtraValue: null,
                                    HTMLValue: '',
                                    Help: 'Je mag hier een getal tussen de 1 en 53 invullen',
                                    Hidden: false,
                                    ID: 980660,
                                    IsLink: false,
                                    LayoutSettings: null,
                                    Name: 'UDT Weeknummer optioneel',
                                    SID: 'se155_3',
                                    Tag: null,
                                    UriScheme: null,
                                    Value: '2',
                                },
                                {
                                    BaseType: 'STRING',
                                    DisplayValue: '11',
                                    ExtraValue: null,
                                    HTMLValue: '',
                                    Help: 'Je moet hier een getal tussen de 1 en 53 invullen',
                                    Hidden: false,
                                    ID: 980662,
                                    IsLink: false,
                                    LayoutSettings: null,
                                    Name: 'UDT Weeknummer verplicht',
                                    SID: 'se155_4',
                                    Tag: null,
                                    UriScheme: null,
                                    Value: '11',
                                },
                                {
                                    BaseType: 'STRING',
                                    DisplayValue: '',
                                    ExtraValue: null,
                                    HTMLValue: '',
                                    Help: 'je mag hier niets invullen of gehele getallen van 5 posities',
                                    Hidden: false,
                                    ID: 980663,
                                    IsLink: false,
                                    LayoutSettings: null,
                                    Name: 'UDT Lengte in meters optioneel',
                                    SID: 'se155_4_2',
                                    Tag: null,
                                    UriScheme: null,
                                    Value: '',
                                },
                                {
                                    BaseType: 'STRING',
                                    DisplayValue: '1',
                                    ExtraValue: null,
                                    HTMLValue: '',
                                    Help: 'je moet hier gehele getallen van 5 posities',
                                    Hidden: false,
                                    ID: 980664,
                                    IsLink: false,
                                    LayoutSettings: null,
                                    Name: 'UDT Lengte in meters verplicht',
                                    SID: 'se155_4_2_2',
                                    Tag: null,
                                    UriScheme: null,
                                    Value: '1',
                                },
                                {
                                    BaseType: 'STRING',
                                    DisplayValue: '',
                                    ExtraValue: null,
                                    HTMLValue: '',
                                    Help: 'Je mag hier een geldbedrag invullen, waarbij duizendtalscheidingen optioneel zijn, maar een komma en twee decimalen verplicht',
                                    Hidden: false,
                                    ID: 980666,
                                    IsLink: false,
                                    LayoutSettings: null,
                                    Name: "UDT Euro's optioneel",
                                    SID: 'se155_4_2_2_2_2',
                                    Tag: null,
                                    UriScheme: null,
                                    Value: '',
                                },
                                {
                                    BaseType: 'STRING',
                                    DisplayValue: '1,00',
                                    ExtraValue: null,
                                    HTMLValue: '',
                                    Help: 'Je mag hier een geldbedrag invullen, waarbij duizendtalscheidingen optioneel zijn, maar een komma en twee decimalen verplicht',
                                    Hidden: false,
                                    ID: 980665,
                                    IsLink: false,
                                    LayoutSettings: null,
                                    Name: "UDT Euro's verplicht",
                                    SID: 'se155_4_2_2_2',
                                    Tag: null,
                                    UriScheme: null,
                                    Value: '1,00',
                                },
                                {
                                    BaseType: 'STRING',
                                    DisplayValue: '1,23',
                                    ExtraValue: null,
                                    HTMLValue: '',
                                    Help: 'Je mag hier een geldbedrag invullen, waarbij duizendtalscheidingen optioneel zijn, maar een komma en twee decimalen verplicht',
                                    Hidden: false,
                                    ID: 980669,
                                    IsLink: false,
                                    LayoutSettings: null,
                                    Name: 'UDT Dollars verplicht',
                                    SID: 'se155_4_2_2_2_3',
                                    Tag: null,
                                    UriScheme: null,
                                    Value: '1,23',
                                },
                                {
                                    BaseType: 'STRING',
                                    DisplayValue: '',
                                    ExtraValue: null,
                                    HTMLValue: '',
                                    Help: 'je mag hier vanalles intypen',
                                    Hidden: false,
                                    ID: 980667,
                                    IsLink: false,
                                    LayoutSettings: null,
                                    Name: 'UDT Optionele tekst',
                                    SID: 'se155_4_2_2_2_2_2',
                                    Tag: null,
                                    UriScheme: null,
                                    Value: '',
                                },
                                {
                                    BaseType: 'STRING',
                                    DisplayValue: 'fdfds\r\n',
                                    ExtraValue: null,
                                    HTMLValue: '',
                                    Help: 'je moet hier iets intypen, maar bent vrij in wat je typt.',
                                    Hidden: false,
                                    ID: 980668,
                                    IsLink: false,
                                    LayoutSettings: null,
                                    Name: 'UDT Verplichte tekst',
                                    SID: 'se155_4_2_2_2_2_2_2',
                                    Tag: null,
                                    UriScheme: null,
                                    Value: 'fdfds\r\n',
                                },
                                {
                                    BaseType: 'STRING',
                                    DisplayValue: '1234,123',
                                    ExtraValue: null,
                                    HTMLValue: '',
                                    Help: '',
                                    Hidden: false,
                                    ID: 980670,
                                    IsLink: false,
                                    LayoutSettings: null,
                                    Name: 'UDT Kilometrering optioneel (#,###)',
                                    SID: 'se155_4_3',
                                    Tag: null,
                                    UriScheme: null,
                                    Value: '1234,123',
                                },
                                {
                                    BaseType: 'STRING',
                                    DisplayValue: '1,234',
                                    ExtraValue: null,
                                    HTMLValue: '',
                                    Help: '',
                                    Hidden: false,
                                    ID: 980671,
                                    IsLink: false,
                                    LayoutSettings: null,
                                    Name: 'UDT Kilometrering verplicht (#,###)',
                                    SID: 'se155_4_3_2',
                                    Tag: null,
                                    UriScheme: null,
                                    Value: '1,234',
                                },
                                {
                                    BaseType: 'STRING',
                                    DisplayValue: '5',
                                    ExtraValue: null,
                                    HTMLValue: '',
                                    Help: '',
                                    Hidden: false,
                                    ID: 980688,
                                    IsLink: false,
                                    LayoutSettings: null,
                                    Name: 'UDT min4 max 8',
                                    SID: 'se168_2',
                                    Tag: null,
                                    UriScheme: null,
                                    Value: '5',
                                },
                                {
                                    BaseType: 'STRING',
                                    DisplayValue: '',
                                    ExtraValue: null,
                                    HTMLValue:
                                        '<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">\r\n<html xmlns="http://www.w3.org/1999/xhtml">\r\n\t<head>\r\n\t\t<meta http-equiv="Content-Type" content="text/html; charset=utf-8" /><title>\r\n\t\t</title>\r\n\t\t<style type="text/css">\r\n\t\t\t.cs95E872D0{text-align:left;text-indent:0pt;margin:0pt 0pt 0pt 0pt}\r\n\t\t\t.cs1B16EEB5{color:#000000;background-color:transparent;font-family:Calibri;font-size:11pt;font-weight:normal;font-style:normal;}\r\n\t\t</style>\r\n\t</head>\r\n\t<body>\r\n\t\t<p class="cs95E872D0"><span class="cs1B16EEB5">&nbsp;</span></p></body>\r\n</html>\r\n',
                                    Help: '',
                                    Hidden: false,
                                    ID: 980672,
                                    IsLink: false,
                                    LayoutSettings: null,
                                    Name: 'UDT HTML veld',
                                    SID: 'se155_4_4',
                                    Tag: null,
                                    UriScheme: null,
                                    Value: '',
                                },
                            ],
                        ],
                        ID: 980721,
                        LayoutSettings: null,
                        Name: 'Inhoud 4: User defined types tabel',
                        SID: 'ce26_2',
                        Tag: null,
                    },
                ],
                Tag: null,
            },
            {
                Help: '',
                Hidden: false,
                ID: 980708,
                LayoutSettings: null,
                Name: 'Opmerkingen',
                SID: 'CE_Opmerkingen',
                SimpleElements: [
                    {
                        BaseType: 'STRING',
                        DisplayValue: '',
                        ExtraValue: null,
                        HTMLValue: '',
                        Help: 'Te maken opmerking per bericht invulbaar. Bij een nieuw bericht wordt deze steeds leeggemaakt.',
                        Hidden: false,
                        ID: 980627,
                        IsLink: false,
                        LayoutSettings: null,
                        Name: 'Eenmalige opmerkingen',
                        SID: 'SE_Opmerkingen',
                        Tag: null,
                        UriScheme: null,
                        Value: '',
                    },
                ],
                Tables: [],
                Tag: null,
            },
        ],
        ConceptID: 0,
        ConceptIsTakenOver: false,
        ConceptReplyToMessageID: 0,
        DateSend: '2021-10-26T09:54:18',
        Debug: false,
        DeletePossible: false,
        Direction: 'van 1 naar 2',
        DirectionAddress: { From: '1', OnBehalf: null, To: '2' },
        DirectionVisible: true,
        GebruikersID: 'pe_164bd47a-7789-463b-aded-0d56f6ab95a8',
        ID: 'Msg_49',
        Information: null,
        IsConcept: false,
        IsSetting: false,
        MITID: 2164314,
        MessageDirection: 0,
        MessageID: 833251,
        MessageNameVisible: true,
        Name: 'Inhoud 4: User defined types',
        ReplyPossible: true,
        Revoke: null,
        SetReady: false,
        ShowCreateProject: false,
        ShowUpdateProject: false,
        Sjablonen: [],
        SubTransPossible: false,
        Subject: 'test',
        Tabs: {},
        Tag: null,
        TransactionID: 599620,
        TransactionInZip: false,
        TransactionNumber: '11131',
        TranstypeName: 'Inhoud4: User Defined Types (UDT)',
        TranstypeSID: 'tra18_2',
    },
    {
        Appendices: [],
        ButtonReply: true,
        ComplexElements: [
            {
                Help: '',
                Hidden: false,
                ID: 90383,
                LayoutSettings: null,
                Name: 'Inhoud1: BaseType verzameling',
                SID: 'ce23',
                SimpleElements: [
                    {
                        BaseType: 'BOOLEAN',
                        DisplayValue: 'false',
                        ExtraValue: null,
                        HTMLValue: '',
                        Help: 'Boolean testveld: er moet hier een checkbox in de applicatie getoond worden',
                        Hidden: false,
                        ID: 90343,
                        IsLink: false,
                        LayoutSettings: null,
                        Name: 'BTT:  BOOLEAN',
                        SID: 'se152_2',
                        Tag: null,
                        UriScheme: null,
                        Value: 'false',
                    },
                    {
                        BaseType: 'INTEGER',
                        DisplayValue: '13',
                        ExtraValue: null,
                        HTMLValue: '',
                        Help: 'Integer testveld: er mogen hier alleen maar getallen zonder decimalen en dergelijke ingevoerd worden.',
                        Hidden: false,
                        ID: 90348,
                        IsLink: false,
                        LayoutSettings: null,
                        Name: 'BTT:  INTEGER',
                        SID: 'se152_7',
                        Tag: null,
                        UriScheme: null,
                        Value: '13',
                    },
                    {
                        BaseType: 'TIME',
                        DisplayValue: '01:00',
                        ExtraValue: null,
                        HTMLValue: '',
                        Help: 'TIME testveld: de applicatie toont een invoerveld waarin UU:MM ingevoerd kan worden',
                        Hidden: false,
                        ID: 90346,
                        IsLink: false,
                        LayoutSettings: null,
                        Name: 'BTT:  TIME',
                        SID: 'se152_5',
                        Tag: null,
                        UriScheme: null,
                        Value: '01:00',
                    },
                    {
                        BaseType: 'DATE',
                        DisplayValue: '2016-11-13',
                        ExtraValue: null,
                        HTMLValue: '',
                        Help: 'DATE testveld: een datumkiezer/veld wordt door de applicatie getoond',
                        Hidden: false,
                        ID: 90344,
                        IsLink: false,
                        LayoutSettings: null,
                        Name: 'BTT: DATE',
                        SID: 'se152_3',
                        Tag: null,
                        UriScheme: null,
                        Value: '2016-11-13',
                    },
                    {
                        BaseType: 'DATETIME',
                        DisplayValue: '2016-11-13T12:00:00',
                        ExtraValue: null,
                        HTMLValue: '',
                        Help: 'DATETIME testveld: een invoerveld wordt getoond waarin een datum met een tijdstip ingevuld kan worden.',
                        Hidden: false,
                        ID: 90345,
                        IsLink: false,
                        LayoutSettings: null,
                        Name: 'BTT: DATETIME',
                        SID: 'se152_4',
                        Tag: null,
                        UriScheme: null,
                        Value: '2016-11-13T12:00:00',
                    },
                    {
                        BaseType: 'DECIMAL',
                        DisplayValue: '13',
                        ExtraValue: null,
                        HTMLValue: '',
                        Help: 'DECIMAL testveld: beschrijvinmg zoeken',
                        Hidden: false,
                        ID: 90347,
                        IsLink: false,
                        LayoutSettings: null,
                        Name: 'BTT: DECIMAL',
                        SID: 'se152_6',
                        Tag: null,
                        UriScheme: null,
                        Value: '13',
                    },
                    {
                        BaseType: 'STRING',
                        DisplayValue: '13',
                        ExtraValue: null,
                        HTMLValue: '',
                        Help: 'STRING testveld: je mag hier alles invullen, voer getallen, vreemde tekens enz. in.',
                        Hidden: false,
                        ID: 90342,
                        IsLink: false,
                        LayoutSettings: null,
                        Name: 'BTT: STRING',
                        SID: 'se152',
                        Tag: null,
                        UriScheme: null,
                        Value: '13',
                    },
                ],
                Tables: [],
                Tag: null,
            },
            {
                Help: '',
                Hidden: false,
                ID: 90384,
                LayoutSettings: null,
                Name: 'Inhoud 1: base type tabel',
                SID: 'ce24',
                SimpleElements: [],
                Tables: [
                    {
                        Columns: [
                            {
                                BaseType: 'BOOLEAN',
                                DisplayValue: null,
                                ExtraValue: null,
                                HTMLValue: null,
                                Help: null,
                                Hidden: false,
                                ID: 90343,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'BTT:  BOOLEAN',
                                SID: 'se152_2',
                                Tag: null,
                                UriScheme: null,
                                Value: null,
                            },
                            {
                                BaseType: 'INTEGER',
                                DisplayValue: null,
                                ExtraValue: null,
                                HTMLValue: null,
                                Help: null,
                                Hidden: false,
                                ID: 90348,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'BTT:  INTEGER',
                                SID: 'se152_7',
                                Tag: null,
                                UriScheme: null,
                                Value: null,
                            },
                            {
                                BaseType: 'TIME',
                                DisplayValue: null,
                                ExtraValue: null,
                                HTMLValue: null,
                                Help: null,
                                Hidden: false,
                                ID: 90346,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'BTT:  TIME',
                                SID: 'se152_5',
                                Tag: null,
                                UriScheme: null,
                                Value: null,
                            },
                            {
                                BaseType: 'DATE',
                                DisplayValue: null,
                                ExtraValue: null,
                                HTMLValue: null,
                                Help: null,
                                Hidden: false,
                                ID: 90344,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'BTT: DATE',
                                SID: 'se152_3',
                                Tag: null,
                                UriScheme: null,
                                Value: null,
                            },
                            {
                                BaseType: 'DATETIME',
                                DisplayValue: null,
                                ExtraValue: null,
                                HTMLValue: null,
                                Help: null,
                                Hidden: false,
                                ID: 90345,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'BTT: DATETIME',
                                SID: 'se152_4',
                                Tag: null,
                                UriScheme: null,
                                Value: null,
                            },
                            {
                                BaseType: 'DECIMAL',
                                DisplayValue: null,
                                ExtraValue: null,
                                HTMLValue: null,
                                Help: null,
                                Hidden: false,
                                ID: 90347,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'BTT: DECIMAL',
                                SID: 'se152_6',
                                Tag: null,
                                UriScheme: null,
                                Value: null,
                            },
                            {
                                BaseType: 'STRING',
                                DisplayValue: null,
                                ExtraValue: null,
                                HTMLValue: null,
                                Help: null,
                                Hidden: false,
                                ID: 90342,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'BTT: STRING',
                                SID: 'se152',
                                Tag: null,
                                UriScheme: null,
                                Value: null,
                            },
                        ],
                        Data: [
                            [
                                {
                                    BaseType: 'BOOLEAN',
                                    DisplayValue: 'false',
                                    ExtraValue: null,
                                    HTMLValue: '',
                                    Help: 'Boolean testveld: er moet hier een checkbox in de applicatie getoond worden',
                                    Hidden: false,
                                    ID: 90343,
                                    IsLink: false,
                                    LayoutSettings: null,
                                    Name: 'BTT:  BOOLEAN',
                                    SID: 'se152_2',
                                    Tag: null,
                                    UriScheme: null,
                                    Value: 'false',
                                },
                                {
                                    BaseType: 'INTEGER',
                                    DisplayValue: '13',
                                    ExtraValue: null,
                                    HTMLValue: '',
                                    Help: 'Integer testveld: er mogen hier alleen maar getallen zonder decimalen en dergelijke ingevoerd worden.',
                                    Hidden: false,
                                    ID: 90348,
                                    IsLink: false,
                                    LayoutSettings: null,
                                    Name: 'BTT:  INTEGER',
                                    SID: 'se152_7',
                                    Tag: null,
                                    UriScheme: null,
                                    Value: '13',
                                },
                                {
                                    BaseType: 'TIME',
                                    DisplayValue: '01:00:00',
                                    ExtraValue: null,
                                    HTMLValue: '',
                                    Help: 'TIME testveld: de applicatie toont een invoerveld waarin UU:MM ingevoerd kan worden',
                                    Hidden: false,
                                    ID: 90346,
                                    IsLink: false,
                                    LayoutSettings: null,
                                    Name: 'BTT:  TIME',
                                    SID: 'se152_5',
                                    Tag: null,
                                    UriScheme: null,
                                    Value: '01:00:00',
                                },
                                {
                                    BaseType: 'DATE',
                                    DisplayValue: '2016-11-13',
                                    ExtraValue: null,
                                    HTMLValue: '',
                                    Help: 'DATE testveld: een datumkiezer/veld wordt door de applicatie getoond',
                                    Hidden: false,
                                    ID: 90344,
                                    IsLink: false,
                                    LayoutSettings: null,
                                    Name: 'BTT: DATE',
                                    SID: 'se152_3',
                                    Tag: null,
                                    UriScheme: null,
                                    Value: '2016-11-13',
                                },
                                {
                                    BaseType: 'DATETIME',
                                    DisplayValue: '2016-11-13T12:00:00',
                                    ExtraValue: null,
                                    HTMLValue: '',
                                    Help: 'DATETIME testveld: een invoerveld wordt getoond waarin een datum met een tijdstip ingevuld kan worden.',
                                    Hidden: false,
                                    ID: 90345,
                                    IsLink: false,
                                    LayoutSettings: null,
                                    Name: 'BTT: DATETIME',
                                    SID: 'se152_4',
                                    Tag: null,
                                    UriScheme: null,
                                    Value: '2016-11-13T12:00:00',
                                },
                                {
                                    BaseType: 'DECIMAL',
                                    DisplayValue: '13',
                                    ExtraValue: null,
                                    HTMLValue: '',
                                    Help: 'DECIMAL testveld: beschrijvinmg zoeken',
                                    Hidden: false,
                                    ID: 90347,
                                    IsLink: false,
                                    LayoutSettings: null,
                                    Name: 'BTT: DECIMAL',
                                    SID: 'se152_6',
                                    Tag: null,
                                    UriScheme: null,
                                    Value: '13',
                                },
                                {
                                    BaseType: 'STRING',
                                    DisplayValue: '13',
                                    ExtraValue: null,
                                    HTMLValue: '',
                                    Help: 'STRING testveld: je mag hier alles invullen, voer getallen, vreemde tekens enz. in.',
                                    Hidden: false,
                                    ID: 90342,
                                    IsLink: false,
                                    LayoutSettings: null,
                                    Name: 'BTT: STRING',
                                    SID: 'se152',
                                    Tag: null,
                                    UriScheme: null,
                                    Value: '13',
                                },
                            ],
                        ],
                        ID: 90383,
                        LayoutSettings: null,
                        Name: 'Inhoud1: BaseType verzameling',
                        SID: 'ce23',
                        Tag: null,
                    },
                ],
                Tag: null,
            },
            {
                Help: '',
                Hidden: false,
                ID: 90379,
                LayoutSettings: null,
                Name: 'Opmerkingen',
                SID: 'CE_Opmerkingen',
                SimpleElements: [
                    {
                        BaseType: 'STRING',
                        DisplayValue: '',
                        ExtraValue: null,
                        HTMLValue: '',
                        Help: 'Te maken opmerking per bericht invulbaar. Bij een nieuw bericht wordt deze steeds leeggemaakt.',
                        Hidden: false,
                        ID: 90336,
                        IsLink: false,
                        LayoutSettings: null,
                        Name: 'Eenmalige opmerkingen',
                        SID: 'SE_Opmerkingen',
                        Tag: null,
                        UriScheme: null,
                        Value: '',
                    },
                ],
                Tables: [],
                Tag: null,
            },
        ],
        ConceptID: 0,
        ConceptIsTakenOver: false,
        ConceptReplyToMessageID: 0,
        DateSend: '2016-11-08T16:07:37',
        Debug: false,
        DeletePossible: false,
        Direction: 'van 1 naar 2',
        DirectionAddress: { From: '1', OnBehalf: null, To: '2' },
        DirectionVisible: true,
        GebruikersID: 'pe_164bd47a-7789-463b-aded-0d56f6ab95a8',
        ID: 'Msg_47_2_2',
        Information: null,
        IsConcept: false,
        IsSetting: false,
        MITID: 68656,
        MessageDirection: 0,
        MessageID: 127746,
        MessageNameVisible: true,
        Name: 'Inhoud 1: Harde FIXED reactie op Basetypes',
        ReplyPossible: false,
        Revoke: null,
        SetReady: true,
        ShowCreateProject: false,
        ShowUpdateProject: false,
        Sjablonen: [],
        SubTransPossible: false,
        Subject: 'Test 1: Basetype 2',
        Tabs: {},
        Tag: null,
        TransactionID: 84384,
        TransactionInZip: false,
        TransactionNumber: '10071',
        TranstypeName: 'Inhoud 1: base type test',
        TranstypeSID: 'tra_10',
    },
];

const NewMessageData = {
    data: {
        Appendices: [
            {
                AutoUnzip: false,
                AutoUnzipPathElement: null,
                ButtonCaption: null,
                ButtonDetails: null,
                Columns: [
                    {
                        BaseType: 'string',
                        ComboboxLocked: false,
                        DefaultValue: null,
                        DisplayValue: null,
                        FillWithFileDate: false,
                        Help: '',
                        Hidden: false,
                        ID: 3,
                        IsCalculation: false,
                        IsEmpty: false,
                        IsHTML: false,
                        Locked: false,
                        MaxLength: null,
                        MinLength: null,
                        Name: 'Bestandsnaam',
                        Refresh: null,
                        RegExPattern: null,
                        ReloadPossible: false,
                        Required: true,
                        RowNumber: null,
                        SID: 'SE_Bestandsnaam',
                        TableCellLocked: false,
                        Tag: null,
                        TransactionListBasedOn: null,
                        Value: '',
                        Values: {},
                    },
                    {
                        BaseType: 'select',
                        ComboboxLocked: false,
                        DefaultValue: null,
                        DisplayValue: null,
                        FillWithFileDate: false,
                        Help: 'Keuzelijst met de type documenten',
                        Hidden: false,
                        ID: 1079963,
                        IsCalculation: false,
                        IsEmpty: false,
                        IsHTML: false,
                        Locked: false,
                        MaxLength: null,
                        MinLength: null,
                        Name: 'Type document',
                        Refresh: null,
                        RegExPattern: null,
                        ReloadPossible: false,
                        Required: true,
                        RowNumber: null,
                        SID: 'TypeDocument',
                        TableCellLocked: false,
                        Tag: null,
                        TransactionListBasedOn: null,
                        Value: '',
                        Values: {
                            Aantekening: 'Aantekening',
                            Afbeelding: 'Afbeelding',
                            Afspraak: 'Afspraak',
                            Brief: 'Brief',
                            'CD / DVD': 'CD / DVD',
                            Demonstratie: 'Demonstratie',
                            'Declaratie (scan/PDF)': 'Declaratie (scan/PDF)',
                            Documentatie: 'Documentatie',
                            Emailbericht: 'Emailbericht',
                            Factuur: 'Factuur',
                            Figuur: 'Figuur',
                            Handleiding: 'Handleiding',
                            Inkooporder: 'Inkooporder',
                            Licentie: 'Licentie',
                            Notitie: 'Notitie',
                            'Offerte (PDF)': 'Offerte (PDF)',
                            'Offerte (Word)': 'Offerte (Word)',
                            Onderhoudscontract: 'Onderhoudscontract',
                            'Opdrachtbevestiging (PDF)': 'Opdrachtbevestiging (PDF)',
                            'Opdrachtbevestiging (Word)': 'Opdrachtbevestiging (Word)',
                            'Opdrachtbevestiging getekend': 'Opdrachtbevestiging getekend',
                            'Opdrachtbon / Inkoopbon': 'Opdrachtbon / Inkoopbon',
                            Presentatie: 'Presentatie',
                            'Projectspecifiek bericht': 'Projectspecifiek bericht',
                            Raamwerk: 'Raamwerk',
                            Raamwerkuitbreiding: 'Raamwerkuitbreiding',
                            Rapportage: 'Rapportage',
                            Schemadefinitie: 'Schemadefinitie',
                            SLA: 'SLA',
                            Software: 'Software',
                            Telefoongesprek: 'Telefoongesprek',
                            Transactieschema: 'Transactieschema',
                            Verslag: 'Verslag',
                            Verwerkersovereenkomst: 'Verwerkersovereenkomst',
                            Werkbon: 'Werkbon',
                            Overig: 'Overig',
                        },
                    },
                    {
                        BaseType: 'DATE',
                        ComboboxLocked: false,
                        DefaultValue: null,
                        DisplayValue: null,
                        FillWithFileDate: true,
                        Help: 'De datum waarop het document is aangemaakt',
                        Hidden: false,
                        ID: 1079434,
                        IsCalculation: false,
                        IsEmpty: false,
                        IsHTML: false,
                        Locked: false,
                        MaxLength: null,
                        MinLength: null,
                        Name: 'Datum document',
                        Refresh: null,
                        RegExPattern: null,
                        ReloadPossible: false,
                        Required: true,
                        RowNumber: null,
                        SID: 'DatumDocument',
                        TableCellLocked: false,
                        Tag: null,
                        TransactionListBasedOn: null,
                        Value: '',
                        Values: {},
                    },
                    {
                        BaseType: 'string',
                        ComboboxLocked: false,
                        DefaultValue: null,
                        DisplayValue: null,
                        FillWithFileDate: false,
                        Help: '',
                        Hidden: true,
                        ID: 2,
                        IsCalculation: false,
                        IsEmpty: false,
                        IsHTML: false,
                        Locked: false,
                        MaxLength: null,
                        MinLength: null,
                        Name: 'BestandsID',
                        Refresh: null,
                        RegExPattern: null,
                        ReloadPossible: false,
                        Required: true,
                        RowNumber: null,
                        SID: 'SE_BestandsID',
                        TableCellLocked: false,
                        Tag: null,
                        TransactionListBasedOn: null,
                        Value: '',
                        Values: {},
                    },
                ],
                Data: [],
                ID: 4506,
                IgnoreLock: false,
                IsCleared: false,
                Locked: false,
                MaxOccurs: null,
                MinOccurs: null,
                Name: 'Bijlage(n)',
                RefreshElementSIDs: null,
                SID: 'apx1',
                Tag: null,
                TransactionList: null,
            },
        ],
        AppendixMandatory: false,
        ApplicationButtons: [],
        CalculationSettings: null,
        ComplexElements: [
            {
                Hidden: false,
                ID: 1080298,
                Name: 'Basisgegevens software aanpassing',
                SID: 'CE_Basisgegevens_software_aanpassing',
                SimpleElements: [
                    {
                        BaseType: 'STRING',
                        ComboboxLocked: false,
                        DefaultValue: null,
                        DisplayValue: null,
                        FillWithFileDate: false,
                        Help: 'Nummer van de software aanpassing',
                        Hidden: false,
                        ID: 1079779,
                        IsCalculation: false,
                        IsEmpty: false,
                        IsHTML: false,
                        Locked: true,
                        MaxLength: null,
                        MinLength: 1,
                        Name: 'Volgnummer',
                        Refresh: null,
                        RegExPattern: null,
                        ReloadPossible: false,
                        Required: true,
                        RowNumber: null,
                        SID: 'SE_Software_aanpassing_nummer',
                        TableCellLocked: false,
                        Tag: null,
                        TransactionListBasedOn: null,
                        Value: '1382',
                        Values: {},
                    },
                    {
                        BaseType: 'STRING',
                        ComboboxLocked: false,
                        DefaultValue: null,
                        DisplayValue: null,
                        FillWithFileDate: false,
                        Help: 'Onderwerp van de software aanpassing',
                        Hidden: false,
                        ID: 1079780,
                        IsCalculation: false,
                        IsEmpty: false,
                        IsHTML: false,
                        Locked: false,
                        MaxLength: null,
                        MinLength: 1,
                        Name: 'Onderwerp',
                        Refresh: null,
                        RegExPattern: null,
                        ReloadPossible: false,
                        Required: true,
                        RowNumber: null,
                        SID: 'SE_Software_aanpassing_omschrijving',
                        TableCellLocked: false,
                        Tag: null,
                        TransactionListBasedOn: null,
                        Value: '',
                        Values: {},
                    },
                    {
                        BaseType: 'STRING',
                        ComboboxLocked: false,
                        DefaultValue: null,
                        DisplayValue: null,
                        FillWithFileDate: false,
                        Help: 'De (verwachting van de) prioriteit van de software aanpassing - neem ons mee in de beleving waarom dit een bepaalde prioriteit heeft',
                        Hidden: false,
                        ID: 1079742,
                        IsCalculation: false,
                        IsEmpty: false,
                        IsHTML: false,
                        Locked: false,
                        MaxLength: null,
                        MinLength: null,
                        Name: 'Impact / belang',
                        Refresh: null,
                        RegExPattern: null,
                        ReloadPossible: false,
                        Required: false,
                        RowNumber: null,
                        SID: 'SE_Prioriteit_toelichting',
                        TableCellLocked: false,
                        Tag: null,
                        TransactionListBasedOn: null,
                        Value: '',
                        Values: {},
                    },
                ],
                Tables: [],
            },
            {
                Hidden: false,
                ID: 1080299,
                Name: 'Basisgegevens software verzoek',
                SID: 'CE_Basisgegevens_software_verzoek',
                SimpleElements: [
                    {
                        BaseType: 'STRING',
                        ComboboxLocked: false,
                        DefaultValue: null,
                        DisplayValue: null,
                        FillWithFileDate: false,
                        Help: '',
                        Hidden: false,
                        ID: 1080124,
                        IsCalculation: false,
                        IsEmpty: false,
                        IsHTML: false,
                        Locked: false,
                        MaxLength: null,
                        MinLength: 1,
                        Name: 'Product Backlog ',
                        Refresh: null,
                        RegExPattern: null,
                        ReloadPossible: false,
                        Required: true,
                        RowNumber: null,
                        SID: 'seProductBacklogLijst',
                        TableCellLocked: false,
                        Tag: 'product',
                        TransactionListBasedOn: null,
                        Value: '',
                        Values: {},
                    },
                    {
                        BaseType: 'STRING',
                        ComboboxLocked: false,
                        DefaultValue: null,
                        DisplayValue: null,
                        FillWithFileDate: false,
                        Help: 'Uitgebreide omschrijving van de software aanpassing',
                        Hidden: false,
                        ID: 1079781,
                        IsCalculation: false,
                        IsEmpty: false,
                        IsHTML: false,
                        Locked: false,
                        MaxLength: null,
                        MinLength: null,
                        Name: 'Uitgebreide omschrijving software aanpassing',
                        Refresh: null,
                        RegExPattern: null,
                        ReloadPossible: false,
                        Required: false,
                        RowNumber: null,
                        SID: 'SE_Software_aanpassing_omschrijving_uitgebreid',
                        TableCellLocked: false,
                        Tag: null,
                        TransactionListBasedOn: null,
                        Value: '',
                        Values: {},
                    },
                ],
                Tables: [],
            },
            {
                Hidden: false,
                ID: 1080442,
                Name: "Deze SE's vervallen binnenkort (Test software aanpassing)",
                SID: 'CE_Test_software_aanpassing',
                SimpleElements: [
                    {
                        BaseType: 'BOOLEAN',
                        ComboboxLocked: false,
                        DefaultValue: null,
                        DisplayValue: null,
                        FillWithFileDate: false,
                        Help: 'Moet er automatisch een mail gestuurd worden naar de klant als het opgelost en getest is?',
                        Hidden: false,
                        ID: 1079679,
                        IsCalculation: false,
                        IsEmpty: false,
                        IsHTML: false,
                        Locked: false,
                        MaxLength: null,
                        MinLength: null,
                        Name: 'Mail naar klant (vervalt, naar contactgegevens)',
                        Refresh: null,
                        RegExPattern: null,
                        ReloadPossible: false,
                        Required: false,
                        RowNumber: null,
                        SID: 'SE_Mail_naar_klant_software_aangepast',
                        TableCellLocked: false,
                        Tag: null,
                        TransactionListBasedOn: null,
                        Value: '',
                        Values: {},
                    },
                    {
                        BaseType: 'STRING',
                        ComboboxLocked: false,
                        DefaultValue: null,
                        DisplayValue: null,
                        FillWithFileDate: false,
                        Help: 'Geef hier aan welke stappen je moet doorlopen om de software aanpassing te reproduceren. Vergeet niet te vermelden welke omgeving, welke inlognaam, welk project.',
                        Hidden: false,
                        ID: 1079818,
                        IsCalculation: false,
                        IsEmpty: false,
                        IsHTML: false,
                        Locked: false,
                        MaxLength: null,
                        MinLength: null,
                        Name: 'Test script (reproductie) (Vervalt, inhoud naar acceptatiecriteria)',
                        Refresh: null,
                        RegExPattern: null,
                        ReloadPossible: false,
                        Required: false,
                        RowNumber: null,
                        SID: 'SE_Test_script',
                        TableCellLocked: false,
                        Tag: null,
                        TransactionListBasedOn: null,
                        Value: '',
                        Values: {},
                    },
                ],
                Tables: [],
            },
            {
                Hidden: false,
                ID: 1080323,
                Name: 'Contactgegevens (software aanpassing)',
                SID: 'CE_ContactGegevens_software_aanpassing',
                SimpleElements: [],
                Tables: [
                    {
                        AutoUnzip: false,
                        AutoUnzipPathElement: null,
                        ButtonCaption: null,
                        ButtonDetails: null,
                        Columns: [
                            {
                                BaseType: 'select',
                                ComboboxLocked: false,
                                DefaultValue: null,
                                DisplayValue: null,
                                FillWithFileDate: false,
                                Help: 'Naam organisatie van werklocatie',
                                Hidden: false,
                                ID: 1080101,
                                IsCalculation: false,
                                IsEmpty: false,
                                IsHTML: false,
                                Locked: false,
                                MaxLength: null,
                                MinLength: 1,
                                Name: 'Naam organisatie (keuzelijst)',
                                Refresh: null,
                                RegExPattern: null,
                                ReloadPossible: true,
                                Required: true,
                                RowNumber: null,
                                SID: 'seNaamOrganisatie',
                                TableCellLocked: false,
                                Tag: null,
                                TransactionListBasedOn: null,
                                Value: '',
                                Values: {
                                    1126: '',
                                    118: '',
                                    16727: '',
                                    469: '',
                                    16730: '',
                                    957: '',
                                    1477: '',
                                    1754: '',
                                    1125: '',
                                    540: '',
                                    52949: ' CE Bouwmanagement',
                                    26749: ' N.v.t.',
                                    1635: "'s Heeren Loo Midden-Nederland",
                                    422: '1200 BD',
                                    12659: '12drie.com Advies & ingenieursdiensten civiele techniek',
                                    40346: '2 Move bv',
                                    23: '2ba',
                                    88658: '314',
                                    1199: '3197 KJ',
                                    462: '3503 RA',
                                    7316: '3D Bouwteam NV',
                                    24: '3D-Construct B.V.',
                                    25: '3D-Geo B.V.',
                                    144: '4190 CD',
                                    8478: '4Infra B.V.',
                                    69025: '4launch',
                                    26: '4People',
                                    27328: '4PS',
                                    63324: '7S Ingenieurs',
                                    758: '8861 BD',
                                    1119: '9747 AS',
                                    63971: 'A & N Luiten Technisch Advies B.V. // Saarlucon B.V ',
                                    135: 'A-Garden bv',
                                    143: 'A-Hak Beheer B.V.',
                                    20064: 'A. de Reus Aannemersbedrijf B.V.',
                                    29338: 'A. den Hartog en Zonen B.V.',
                                    8669: 'A. Faber B.V.',
                                    29: 'A. Helmer B.V. ',
                                    26162: 'A. Hollander BV',
                                    40077: 'A. Kok Loon en Grondverzetbedrijf',
                                    30: 'A. Schellings BV',
                                    31: 'A. van Hage B.V.',
                                    32: 'A.H. Vrij Groenvoorziening',
                                    22990: 'A.Hak Electron B.V.',
                                    30880: 'A.Hak Infranet regio Noord-Oost',
                                    45263: 'A.Hak Leidingbouw B.V.',
                                    33: 'A.Hak Rijnmond B.V.',
                                    27995: 'A.L. v.d. Wetering B.V. ',
                                    48076: 'A.M. STIKKERS Consultancy B.V.',
                                    36: 'A.V.T. Grond en straatwerk',
                                    28: 'A&M Wegenbouw B.V.',
                                    58195: 'Aan de Stegge Bouw & Werktuigbouw',
                                    63806: 'Aanemingsbedrijf JBK B.V.',
                                    44585: 'Aannemers- en verhuurbedrijf Bart Vugts B.V.      ',
                                    39: 'Aannemers- en verhuurbedrijf Winkel VOF',
                                    45567: 'Aannemersbedrijf  H.van Haarst',
                                    75506: 'Aannemersbedrijf A. Blom en Zonen',
                                    72446: 'Aannemersbedrijf A. van Ooijen en Zn. B.V.',
                                    40: 'Aannemersbedrijf Berlicum',
                                    43: 'Aannemersbedrijf Gebr. Van den Dries B.V.',
                                    16404: 'Aannemersbedrijf gebroeders Min BV',
                                    44: 'Aannemersbedrijf Grondman BV',
                                    45: 'Aannemersbedrijf J. van den Bosch B.V.',
                                    10229: 'Aannemersbedrijf J.C. DIKKES B.V.        ',
                                    13723: 'Aannemersbedrijf Koen Meijer BV',
                                    46: 'Aannemersbedrijf Kranen  b.v.',
                                    73484: 'Aannemersbedrijf Kwakkenbos',
                                    50: 'Aannemersbedrijf M.J. Smits B.V.',
                                    49: 'Aannemersbedrijf Roseboom B.V.',
                                    9272: 'Aannemersbedrijf Tijssen V.O.F.',
                                    72574: 'Aannemersbedrijf van Alphen',
                                    51: 'Aannemersbedrijf Van den Broek B.V.',
                                    52: 'Aannemersbedrijf Van den Nagel',
                                    54: 'Aannemersbedrijf Van Hemert BV',
                                    55: 'Aannemersbedrijf van Wijk, Nieuwegein',
                                    56: 'Aannemersbedrijf Van Wijlen B.V.',
                                    75118: 'Aannemersbedrijf Van Zoelen BV',
                                    1920: 'Aannemersbedrijf Verdam B.V.',
                                    8246: 'Aannemersbedrijf W. Zielman BV',
                                    64872: 'Aannemersbedrijf Wagelaar B.V. ',
                                    48470: 'Aannemersbedrijf Zijsling en Zonen B.V.',
                                    32024: 'Aannemingbedrijf Roy Verstegen',
                                    59: 'Aannemings en Verhuurbedrijf Fuhler BV',
                                    58: 'Aannemings- en Baggerbedrijf Ekelschot BV',
                                    53268: 'Aannemings- en Transportbedrijf Roel van der Stoel B.V.',
                                    60: 'Aannemingsbedrijf A. Goedegebuure',
                                    61: 'Aannemingsbedrijf A.V.K. BV',
                                    62: 'Aannemingsbedrijf Beije B.V.',
                                    16038: 'Aannemingsbedrijf Beka Rail + Infra BV',
                                    63: 'Aannemingsbedrijf Borger',
                                    77: 'Aannemingsbedrijf Bouma B.V.',
                                    64: 'Aannemingsbedrijf BTZ B.V.',
                                    37296: 'Aannemingsbedrijf C. de Kwaadsteniet B.V.',
                                    73947: 'Aannemingsbedrijf C. Hoogvliet & Zn.',
                                    64452: 'Aannemingsbedrijf De Bokx B.V.',
                                    66: 'Aannemingsbedrijf De Klerk BV',
                                    30847: 'Aannemingsbedrijf De Voogd-Grijpskerke',
                                    69460: 'Aannemingsbedrijf de Vries Werkendam B.V',
                                    67: 'Aannemingsbedrijf Dekker Krabbendam',
                                    67287: 'Aannemingsbedrijf EJ Bouwmeester',
                                    68: 'Aannemingsbedrijf G.Van der Holst',
                                    72536: 'Aannemingsbedrijf Gebr. Blokland B.V.',
                                    63985: 'Aannemingsbedrijf GEBRO bv ',
                                    595: 'Aannemingsbedrijf Geluk BV',
                                    69: 'Aannemingsbedrijf Goedebuure',
                                    44684: 'Aannemingsbedrijf Grond- Sloop & Waterwerken B.V.',
                                    72449: 'Aannemingsbedrijf H.J. Jurriëns bv',
                                    70: 'Aannemingsbedrijf Hoondert',
                                    71144: 'aannemingsbedrijf Huub Verlouw BV',
                                    71: 'Aannemingsbedrijf J.C. Koevoets B.V.',
                                    72: 'Aannemingsbedrijf Jan Mulder BV',
                                    74: 'Aannemingsbedrijf Johan de Bie BV',
                                    75: 'Aannemingsbedrijf Klaas Kooiker',
                                    76: 'Aannemingsbedrijf Koole BV',
                                    37287: 'Aannemingsbedrijf Kruse B.V.',
                                    10145: 'Aannemingsbedrijf KVDM B.V.',
                                    78: 'Aannemingsbedrijf M. van Ooijen en Zn. BV',
                                    47: 'Aannemingsbedrijf M.C. v.d. Spek',
                                    16172: 'Aannemingsbedrijf M.J. van Uijtert & Zn ',
                                    9769: 'Aannemingsbedrijf Molhoek BV',
                                    79: 'Aannemingsbedrijf Nico de Bont BV',
                                    27030: 'Aannemingsbedrijf Osinga BV',
                                    80: 'Aannemingsbedrijf P. Looijen en Zn BV',
                                    81: 'Aannemingsbedrijf Peerenboom Uden BV',
                                    82: 'Aannemingsbedrijf Reimerswaal bv',
                                    45680: 'Aannemingsbedrijf Simon B.V.',
                                    65: 'Aannemingsbedrijf Stuit B.V.',
                                    83: 'Aannemingsbedrijf Suykerbuyk B.V.',
                                    84: 'Aannemingsbedrijf Tatenhove BV',
                                    65260: 'Aannemingsbedrijf Th. en A. Hachmang B.V. ',
                                    85: 'Aannemingsbedrijf Van den Berk B.V.',
                                    53: 'Aannemingsbedrijf Van der Lee B.V.',
                                    86: 'Aannemingsbedrijf van der Meer B.V.',
                                    50615: 'Aannemingsbedrijf Van Gameren Jr. B.V. ',
                                    88: 'Aannemingsbedrijf Van Herwijnen B.V.',
                                    1833: 'Aannemingsbedrijf Verkagrond B.V.',
                                    89: 'Aannemingsbedrijf Visscher B.V.',
                                    67698: 'Aannemingsbedrijf W. Schipper B.V',
                                    30276: 'Aannemingsmaatschappij Markus B.V.',
                                    90: 'Aannemingsmaatschappij van Gelder (Amsterdam)',
                                    87: 'Aannemingsmaatschappij van Gelder (Elburg)',
                                    63254: 'Aannemingsmaatschappij van Gelder (Elburg)',
                                    30918: 'Aannemingsmaatschappij van Gelder (Hattem)',
                                    91: 'Aannemingsmaatschappij van Gelder (hoofdkantoor)',
                                    8069: 'Aannemingsmaatschappij Van Gelder B.V. (Almere)',
                                    37270: 'Aannemingsmaatschappij Van Gelder B.V. (Nieuw-Vennep)',
                                    72859: 'Aannemingsmaatschappij Van Gelder B.V. (Uden)',
                                    92: 'Aannemingsmaatschappij van Gelder bv',
                                    38593: 'Aannemingsmaatschappij Van Gelder BV ( Lisse)',
                                    76068: 'Aannemingsmaatschappij van Gelder Hardenberg',
                                    30672: 'Aannemingsmij Thomassen Akkrum',
                                    10341: 'Aannemingsmij. H.M. van Scheppingen b.v.',
                                    93: 'Aannemingsmij. Van Leeuwen Vijfhuizen BV (failliet)',
                                    8862: 'AARIO bv',
                                    94: 'Aarts Wegenbouw',
                                    95: 'AB Oost Bedrijfsverzorging',
                                    59816: 'Abbink Boekelo Wegenbouw B.V.',
                                    96: 'Abbink Groep B.V.',
                                    41001: 'ABCiviel',
                                    72405: 'Abeko',
                                    97: 'Aben',
                                    70340: 'Ability',
                                    8128: 'ABM GlobalConsult BV',
                                    55489: 'ABM-Belbouw',
                                    98: 'ABN AMRO',
                                    100: 'ABO-milieuconsult BV',
                                    99: 'Aboma',
                                    101: 'ABT Adviesbureau voor Bouwtechniek',
                                    102: 'AC de Groot bv',
                                    103: 'ACE Ingenieurs en adviseurs',
                                    104: 'Achterhoekadvies',
                                    105: 'ACN Infra',
                                    106: 'ACO B.V.',
                                    25216: 'ACON beton en waterbouw bv',
                                    108: 'Active Engineering',
                                    109: 'Acto Informatisering B.V.',
                                    110: 'ACW Aannemers Combinatie Waardenburg BV',
                                    111: 'Ad Hofmans Hedel BV',
                                    6994: 'Adapte',
                                    112: 'Adcim BV',
                                    15874: 'Adegeest Advies',
                                    113: 'Admicom',
                                    15178: 'Admicom Systems B.V. ',
                                    114: 'Admicom Systems Oldenzaal B.V.',
                                    115: 'Administratiekantoor Klomp',
                                    116: 'Adonin B.V.',
                                    117: 'Adrem Groen Management',
                                    78642: 'Advicom-Engineering Group',
                                    30412: 'Adviesbureau A.S. van Geel',
                                    119: 'Adviesbureau Brouwers BV',
                                    31036: 'Adviesbureau Colijnsplaat',
                                    50913: 'Adviesbureau de Gronckel',
                                    120: 'Adviesbureau Haver Droeze BNT',
                                    32241: 'Adviesbureau Keizer',
                                    23912: 'Adviesbureau Nefertum',
                                    123: 'Adviesbureau Reurslag',
                                    124: 'Adviesbureau Rivierenland',
                                    46564: 'Adviesbureau Schrijvers B.V.',
                                    125: 'Adviesbureau VDNV',
                                    126: 'Adviesbureau voor Bouwconstructies Wagemaker B.V.',
                                    69613: 'Adviesbureau Wakam',
                                    18127: 'Adviesburo BEJAN ',
                                    127: 'Adviesburo CGC',
                                    128: 'Adviesburo Civiele Techniek Voorwinden',
                                    28188: 'Adviesburo GMC',
                                    29761: 'Adviesburo M. Wiarda',
                                    29800: 'Adviesburo Marcel Uriot',
                                    37303: 'Adviesburo Pronk BV',
                                    130: 'Adviesburo Riet',
                                    131: 'Advin',
                                    132: 'Advin (Barendrecht)',
                                    133: 'Advin Zuid',
                                    134: 'Advisor ICT Solutions',
                                    69530: 'Aebi Schmidt Nederland',
                                    16874: 'Æneas',
                                    54593: 'Aerolux Nederland BV',
                                    30215: 'Afvalzorg Deponie ',
                                    136: 'Agel Adviseurs',
                                    42595: 'Agile Software',
                                    137: 'Agmi',
                                    138: 'Agrarisch loonbedrijf G. van Etten b.v.',
                                    139: 'Agro IT',
                                    140: 'Agro Media Consult',
                                    141: 'Agro Tuinen',
                                    142: 'Agterberg BV',
                                    48805: 'AHA-Adviseurs',
                                    38654: 'AHaitsma',
                                    55291: 'AHCO Weg- en Waterbouw',
                                    145: "Ahoy' Rotterdam",
                                    146: 'AHW Vorden',
                                    65132: 'Ajax Chubb Varel',
                                    149: 'Akertech  B.V.',
                                    150: 'Akkermans B.V.',
                                    151: 'Akkermans Beheer BV',
                                    152: 'Aladdin Software Security Benelux',
                                    23311: 'Albeda college',
                                    43373: 'Alberts & Kluft',
                                    68848: 'Alblas Infra BV',
                                    153: 'Alescon',
                                    71154: 'Alewijnse Zwolle B.V.',
                                    16508: 'Alfa College',
                                    154: 'Algemene Bomendienst Limburg B.V. (OHC voor een jaar)',
                                    60108: 'Allard van Zijderveld Tuin- en Boomverzorging',
                                    156: 'Alles over Groenbeheer',
                                    15761: 'Alliander NV Afd. Shared Service',
                                    157: 'Allinco Software B.V.',
                                    158: 'Allsat water consult GmbH',
                                    37249: 'Alltec Engineering & Partners B.V.',
                                    159: 'Alpine Bau Deutschland AG',
                                    8717: 'Alsema B.V.',
                                    69756: 'Alstom Wind France SAS',
                                    160: 'Altijd Goed Bestratingen',
                                    161: 'AM&P B.V.',
                                    162: 'Amerongen Aannemings- en Wegenbouwbedrijf B.V. ',
                                    16995: 'AMI civieltechnisch adviesbureau',
                                    72045: 'Ammerlaan Loon-, Verhuur- & Lasbedrijf',
                                    28618: 'Amsterdam Airport Schiphol',
                                    32523: 'Amsterdamse Bos',
                                    20309: 'Anacon Infra',
                                    48362: 'Anholt Infrasupport',
                                    164: 'Anpako-Spadon',
                                    1234: 'Antea Group',
                                    49350: 'Antea Group | Assen',
                                    69533: 'Antea Group | Capelle aan den IJssel',
                                    68450: 'Antea Group Oosterhout',
                                    21768: 'Anton Constructiewerken',
                                    39318: 'Anton Rail & Infra',
                                    31175: 'AOC Oost',
                                    38780: 'AOC Oost Almelo',
                                    165: 'AOC Terra',
                                    35: 'AORTA 2004 B.V.',
                                    15516: 'APcon Adviesbureau BV',
                                    12938: 'APM Advies',
                                    30218: 'Apollo',
                                    166: 'Aprecico',
                                    63725: 'Arbokwaliteitsgroep',
                                    169: 'Arcadis (Amersfoort)',
                                    45549: 'ARCADIS Nederland BV (Assen)',
                                    168: 'ARCADIS Nederland BV (Den Bosch)',
                                    60546: 'ARCADIS Nederland BV (Rotterdam)',
                                    31659: 'ARCADIS Nederland BV (Rotterdam)',
                                    170: 'Arcadis Regio Noordoost (Apeldoorn)',
                                    63917: 'Archangel Bouw',
                                    171: 'Archidat Bouwkosten',
                                    172: 'ArchiLogiQ',
                                    16887: 'Architectenweb',
                                    16888: 'Architectenwerk',
                                    25313: 'Arcus College',
                                    173: 'Areal Services B.V.',
                                    40293: 'Arend de Winter Combi-Groen BV',
                                    69525: 'Arendsen Inframanagement',
                                    70865: 'Arfman Hekwerk',
                                    37284: 'Argus Inspectie en Onderhoud',
                                    174: 'Ark en Reau Sloopwerken en Asbestverwijdering',
                                    175: 'Arma Infra',
                                    74545: 'Arma Tankbouw B.V.',
                                    77264: 'Armada Mobility BV',
                                    176: 'Arnicon BV',
                                    34351: 'Arriva',
                                    34695: 'Arriva Nederland B.V. (Heerenveen)',
                                    177: 'Artificial Industry',
                                    6841: 'Artigreen',
                                    178: 'Artis',
                                    72566: 'Asbest & Combi Services',
                                    179: 'ASC Sport & Water',
                                    107: 'Asfalt Combinatie Oost-Nederland (A.C.O.N.) B.V.',
                                    181: 'Asito',
                                    34471: 'ASSET Rail',
                                    41551: 'ASTOciviel ',
                                    63812: 'Astralift B.V. ',
                                    22787: 'ATABNL.com',
                                    182: 'ATENSUS Advies en Projectmanagement',
                                    167: 'ATKB',
                                    183: 'Atsma Loon- en Aannemersbedrijf',
                                    30830: 'Attero',
                                    16427: 'Attero Zuid BV',
                                    184: 'Attis',
                                    33048: 'Aurio ICT B.V.',
                                    185: 'Autron BV',
                                    186: 'Avans Hogeschool (Den Bosch)',
                                    13285: 'Avans Hogeschool (Tilburg)',
                                    28997: 'Aveco de Bondt | Driebergen',
                                    187: 'Aveco de Bondt | Rijssen',
                                    188: 'Avi Fauna',
                                    189: 'Avviato',
                                    1842: 'AW Vessies Infra',
                                    55305: 'B Rutjes Dienstverlening & Projectondersteuning GWW',
                                    246: 'B-Infra',
                                    10854: 'B. Reer Sloop- en Grondwerken B.V.',
                                    1645: 'B. Schepens BV',
                                    192: 'B. van Haastere B.V.',
                                    193: 'B.A.S. Begeleiding en Advies Sportterreinen',
                                    194: 'B.P.Bijman',
                                    195: 'B.V. Aannemingsbedrijf P. Poeles',
                                    37105: 'B.V. Schildersbedrijf “Holland”',
                                    53656: 'B&B Binnen- en Buitenbouw',
                                    25039: 'B&I Arbeidsbemiddeling',
                                    37404: 'B&I BV Raadgevende  Ingenieurs',
                                    190: 'B&K Grond- en Straatwerken',
                                    191: 'B&T aannemingsbedrijf',
                                    56628: 'B&V Advies B.V.',
                                    197: 'B3 Aannemings- en verhuurbedrijf (FAILLIET)',
                                    198: 'Baars Infra B.V.',
                                    199: 'Baas B.V.',
                                    34579: 'Bagger- en Groenservice B.V.',
                                    55427: 'Bagger- en Waterwerken Oosterwolde BV',
                                    200: 'Baggerbedrijf West Friesland',
                                    66801: 'Bakermans Cultuurtechniek',
                                    202: 'Bakker & Spees Sollicitanten',
                                    201: 'Bakker&Spees BV',
                                    18694: 'Balance',
                                    77981: 'Balfour Beatty Rail GmbH',
                                    203: 'Ballast Nedam',
                                    27049: 'Ballast Nedam Beheer B.V.',
                                    32193: 'Ballast Nedam Geluidwering',
                                    37894: 'Ballast Nedam ICT  ',
                                    8501: 'Ballast Nedam Infra Midden Zuid B.V. ',
                                    19550: 'Ballast Nedam Infra Midden-Zuid (Arnhem)',
                                    204: 'Ballast Nedam Infra Zuid-Oost',
                                    205: 'Ballast Nedam Infra Zuid-West',
                                    206: 'Ballast Nedam IPM (Leerdam)',
                                    14546: 'Balm Uitwendige Wapening bv',
                                    20705: 'BAM (Groningen)',
                                    207: 'BAM Civiel BV',
                                    12823: 'BAM Civiel Noordoost',
                                    31219: 'BAM Civiel Zuidoost',
                                    208: 'BAM Civiel Zuidwest',
                                    209: 'BAM ICT Services bv',
                                    45553: 'BAM Infra (Zuidbroek)',
                                    69342: 'BAM Infra Energie & Water West bv',
                                    210: 'BAM Infraconsult BV',
                                    19119: 'BAM Infratechniek BV Culemborg',
                                    29369: 'BAM Infratechniek Midden West b.v.',
                                    19988: 'BAM Infratechniek Mobiliteit (VTN Besturingstechniek)',
                                    211: 'BAM Infratechniek Noordoost BV',
                                    53411: 'BAM Milieu',
                                    212: 'BAM Rail BV',
                                    69646: 'BAM Schakel & Schrale',
                                    17247: 'BAM Utiliteitsbouw BV Regio Arnhem',
                                    213: 'BAM Utiliteitsbouw BV Regio Maastricht',
                                    214: 'BAM Wegen',
                                    215: 'BAM Wegen BV (Utrecht)',
                                    216: 'BAM Wegen BV, regio NoordWest',
                                    37300: 'BAM Wegen Noord BV',
                                    217: 'BAM Wegen Regio Midden',
                                    218: 'BAM Wegen Regio Midden Nootenboom Sport BV',
                                    37316: 'BAM Wegen Regio Oost BV ',
                                    219: 'BAM Wegen Regio West',
                                    220: 'BAM Wegen Zuid-Oost',
                                    221: 'BAM Wegen Zuid-West',
                                    222: 'Bartels B.V.',
                                    14991: 'Bartels Consulting Engineers',
                                    223: 'Barth Drainage BV',
                                    224: 'BAS Infra',
                                    225: 'Basalt Wegenbouw bv',
                                    72154: 'Bats Adviesbureau',
                                    11720: 'Baumschulen Lappen',
                                    226: 'Bavro B.V.',
                                    10153: 'BAZ',
                                    227: 'BBM Restauratiearchitectuur',
                                    36353: 'BBT Supportancy',
                                    69536: 'BC Materiaalbeheer B.V.',
                                    13124: 'BCA Advies',
                                    228: 'BCC Ingenieursbureau',
                                    229: 'Bebrina',
                                    85543: 'Bedrijf X',
                                    97951: 'Bedrijf X',
                                    75648: 'Bedrijfsbureau Infra Van Meer ',
                                    230: 'Beekmans Recycling',
                                    231: 'Beelen Sloopwerken',
                                    65307: 'Beens Groep',
                                    232: 'Beentjes Gebr. GWW BV',
                                    34672: 'Beerten Grondverzetbedrijf B.V.',
                                    233: 'Beheeraccent',
                                    23573: 'BeheerNet',
                                    234: 'Beijer Tuin- en Landschapsinrichting',
                                    235: 'Beindorff Civieltechnische Werkzaamheden',
                                    236: 'BEJO Civiele Techniek',
                                    42653: 'Bekhuis Advies',
                                    237: 'BEL Combinatie (Gemeente Blaricum, Eemnes en Laren)',
                                    22045: 'Ben Hoogenhoud',
                                    7982: 'BEN&WIL B.V.',
                                    1880: 'Benecke Aannemingsmij BV',
                                    37964: 'Benelux Road Trade Center B.V.',
                                    238: 'Bentley Benelux',
                                    239: 'Bentvelzen en Jacobs Demontage B.V.',
                                    78429: 'Bercon IT Oplossingen',
                                    48158: 'Berdi B.V.',
                                    16711: 'bereikdebouw.nl',
                                    240: 'Berenschot Groep B.V.',
                                    50067: 'Berkvens & Bliekendaal Two-B engineering B.V.',
                                    242: 'Berry Noyen Sierbestratingen',
                                    50600: 'Besekk B.V.',
                                    243: 'Besix N.V.',
                                    23285: 'Besix Nederland Branch BV',
                                    244: 'Bestratingsbedrijf Boonstra Harkema BV',
                                    11135: 'Bestratingsbedrijf Koetzier',
                                    71944: 'Bestratingsbedrijf S&B',
                                    38474: 'Bestuursdienst Ommen-Hardenberg',
                                    64336: 'Betonboringsbedrijf de Jong B.V.',
                                    18664: 'Beugel Infrastructuur BV',
                                    60611: 'Beukema Grondwerken BV',
                                    75563: 'Bevanos',
                                    245: 'Biggelaar Groen BV',
                                    52459: 'Bijzen & Dekker Enschede',
                                    65380: 'BIM Media B.V.',
                                    20078: 'BioMaxx CV',
                                    39412: 'BioSoil',
                                    23539: 'BIRD Invest BV',
                                    24526: "Bits 'n Bolts",
                                    27434: 'BJ Computers',
                                    60703: 'BK Arbo & Veiligheid',
                                    247: 'BK Ingenieurs B.V.',
                                    46563: 'BKS Infra',
                                    80525: 'blablaorganisatie2test',
                                    80522: 'blablatestorganisatie',
                                    248: 'Bladel Asfalt',
                                    23316: 'Blankendaal Opdam',
                                    65119: 'Blanksma Bouwbedrijf',
                                    63988: "Blansjaar 's-Gravenhage B.V. \t",
                                    249: 'Bloem civieltechniek bv',
                                    251: 'BMM Groen BV',
                                    89430: 'Bob de Bouwer Inc.',
                                    252: 'BoDo Energie & Infra bv',
                                    63961: 'Boeg B.V. ',
                                    253: 'Boekhorst',
                                    16014: 'BoerEL Telecom',
                                    254: 'Boerhof Groenvoorzieningen',
                                    255: 'Bogaard Infra BV (GEEN OHC)',
                                    256: 'Bogers Bestratingen',
                                    29974: 'Boiten Raadgevende Ingenieurs',
                                    52600: 'Bombardier Transportation Netherlands B.V.',
                                    65267: 'Bomenrooibedrijf Kuijpers V.O.F.',
                                    257: 'Bomenwacht Nederland',
                                    69487: 'Boogaard Infra b.v.',
                                    42974: 'Booiman Tuinen B.V.',
                                    18220: 'Boom-KCB',
                                    258: 'Boomrooierij Weijtmans',
                                    259: 'Boonstoppel Groen  BV',
                                    260: 'Boontjes Projectmanagement',
                                    43641: 'Boos Bestratingen B.V.',
                                    262: 'Boralit',
                                    18884: 'Borchwerf II C.V.',
                                    8117: 'Bos en Natuur',
                                    263: 'Bos Hoveniers',
                                    264: 'Bosch & Slabbers',
                                    265: 'Bosgroep Midden Nederland',
                                    266: 'Boskalis bv',
                                    267: 'Boskalis Infra bv',
                                    52646: 'Boskalis Nederland Infra B.V. (v/h MNO Vervat Middenmeer)',
                                    1451: 'Boskalis Nederland Infra B.V. (v/h MNO Vervat Noord)',
                                    1416: 'Boskalis Nederland Infra B.V. (v/h MNO Vervat Rotterdam BV)',
                                    7074: 'Boskalis Nederland Infra BV (v/h MNO Vervat Nw Vennep)',
                                    29648: 'Bosland Adviesbureau',
                                    77526: 'Bosman Watermanagement',
                                    50086: 'Boulevard Infra',
                                    17554: 'Bouma Sport en Groen',
                                    268: 'Bouw & Management Nederland BV (MB Net)',
                                    63967: 'Bouw- en aannemersbedrijf v.d. Voort B.V. ',
                                    65125: 'Bouw- en Aannemingsbedrijf P.G.M. Bakker',
                                    269: 'Bouw- en Managementservice Nederland bv',
                                    58547: 'Bouwbedrijf Boot',
                                    270: 'Bouwbedrijf DVL',
                                    65121: 'Bouwbedrijf Selie B.V.',
                                    88361: 'Bouwcorporatie Stoop',
                                    69716: 'Bouwhuis Aannemingsmaatschappij Bouwmij B.V.',
                                    271: 'BouwInfosys BV',
                                    63813: 'Bouwmij P.W. Schipper b.v.',
                                    72582: 'Bouwonderneming Stout',
                                    10436: 'Bouwtalent',
                                    16894: 'bouwtrefpunt.nl',
                                    272: 'Boverhoff BV',
                                    274: 'Bovisie B.V.',
                                    275: 'Bowie Projecten',
                                    17668: 'Boxtel Rioolbeheer bv',
                                    276: 'Boy Limmen Sloopwerken',
                                    26536: 'Braam Civieltechniek bv',
                                    50377: 'Braam General Contracting',
                                    278: 'Brabotech Aannemingsbedrijf B.V.',
                                    97904: 'Bram infra',
                                    279: 'Brand VOF J A J van den',
                                    41875: 'Brauer Grondverzet en Transport B.V.',
                                    44054: 'Breda Bouw',
                                    280: 'Breed',
                                    74611: 'Breijer',
                                    281: 'Breijn b.v. Roermond',
                                    282: 'Breijn b.v. Roosendaal',
                                    283: 'Breijn B.V. Rosmalen ',
                                    74251: 'Bresser Civiel & Industrie B.V.',
                                    284: 'Breure Grondwerken B.V.',
                                    66120: 'Brienissen Bomen B.V',
                                    29903: 'Brink Management & Advies B.V.',
                                    54376: 'BRM Infrasupport',
                                    286: 'Broekema Wegenbouw BV',
                                    41148: 'Brondijk B.V. ',
                                    288: 'BroNij Infra B.V.',
                                    289: 'Brons & Partners',
                                    290: 'Brouwers Adviesburo BV',
                                    70473: 'Brouwers Groenprojecten',
                                    291: 'BRUIL infra (Regio Zuid-Oost)',
                                    292: 'Brunel',
                                    293: 'BSI Bomenservice',
                                    15729: 'BT Geoconsult B.V.',
                                    41978: 'BTAdvies B.V.',
                                    294: 'BTL  Realisatie  B.V./Tuinstijl Hengelo',
                                    296: 'BTL Advies  BV',
                                    297: 'BTL Advies BV (Oisterwijk)',
                                    298: 'BTL B.V. Bomendienst',
                                    317: 'BTL Nederland B.V.',
                                    300: 'BTL Realisatie  B.V. Emmen',
                                    299: 'BTL Realisatie  B.V. Joure',
                                    301: 'BTL Realisatie  B.V. Oss',
                                    302: 'BTL Realisatie  B.V. Roosendaal',
                                    303: 'BTL Realisatie  B.V. Stein',
                                    304: 'BTL Realisatie  B.V. Steunpunt Arnhem',
                                    305: 'BTL Realisatie  B.V. Tuinstijl Roosendaal',
                                    306: 'BTL Realisatie  B.V. Vriese Infra',
                                    307: 'BTL Realisatie B.V. Bedrijfsbureau',
                                    308: 'BTL Realisatie B.V. Bruinisse',
                                    309: 'BTL Realisatie B.V. Haaren',
                                    63447: 'BTL Realisatie B.V. Heerenveen',
                                    310: 'BTL Realisatie B.V. Maarssen',
                                    311: 'BTL Realisatie B.V. Roermond',
                                    312: 'BTL Realisatie B.V. Ulrum',
                                    63600: 'BTL Realisatie B.V. Utrecht',
                                    295: 'BTL Realisatie B.V. vestiging Veldhoven',
                                    313: 'BTL Realisatie B.V.Hellevoetssluis',
                                    314: 'BTL Realisatie B.V.Veldhoven',
                                    315: 'BTL Realisatie BV Hengelo',
                                    316: 'BTL Realisatie BV Venray',
                                    9789: 'Buijert Zwartendijk Kampen B.V.',
                                    65205: 'Buijtenhuis Nijkerk B.V.',
                                    28950: 'Buisleidingenstraat Nederland',
                                    71272: 'Buiten-Ruimte',
                                    59611: 'Buitengewoon Adviseurs',
                                    319: 'BuitenGewoon Groen',
                                    320: 'Buitenhuis Boskoop B.V.',
                                    321: 'Buitenhuis Bouwgroep',
                                    43821: 'Buko Infrasupport',
                                    72575: 'Buko Verhuur B.V.',
                                    322: 'Bunnik BV',
                                    39375: 'Burdock',
                                    323: 'Bureau [Ssse]',
                                    324: 'Bureau 123',
                                    27208: 'Bureau Bouwkunde',
                                    7291: 'Bureau Bouwmeester',
                                    325: 'Bureau DS',
                                    30202: 'Bureau Groenadvies',
                                    326: 'Bureau Jos van de Lindeloof',
                                    1429: 'Bureau Maris B.V.',
                                    37418: 'Bureau Milieu & Werk',
                                    327: 'Bureau Poppe',
                                    39400: 'Bureau Schmidt',
                                    37398: 'Burger Civiel Consultancy',
                                    328: 'Buro Adrichem',
                                    32341: 'Buro Antares',
                                    329: 'Buro Appelman',
                                    261: 'Buro Boot',
                                    364: 'Buro Cite',
                                    331: 'Buro Hollema',
                                    332: 'Buro Hoogstraat BV',
                                    333: 'Buro Karres en Brands (oud)',
                                    334: 'Buro Lubbers',
                                    22657: 'Buro Maissan',
                                    29216: 'Buro Mien Ruys ',
                                    22687: 'Buro Mink',
                                    8223: 'Buro Noord BV',
                                    1533: 'Buro Poelmans Reesink Landschapsarchitectuur',
                                    335: 'Buro Ruimte & Groen',
                                    337: 'BuroBlad',
                                    338: 'Buroinfra',
                                    1853: 'BuroScope Civil Engineering bv ',
                                    56537: 'BuSCon',
                                    69299: 'Business Building',
                                    62992: 'Buunk-Metze Hoveniers',
                                    11370: 'BV Nederlands WegenMarkeerbedrijf',
                                    339: 'BV Sport Leeuwarden',
                                    340: 'BWZ Ingenieurs',
                                    7787: 'C-Infra bv.',
                                    399: 'C-T-K B.V.',
                                    75043: 'C. Qualm verhuur en aannemingsbedrijf',
                                    56117: 'C.L.van Paridon loon en verhuurbedrijf',
                                    341: 'C.S.C. Ceelen Sport Constructies',
                                    342: 'C.van den Adel Bergum BV',
                                    28032: 'C2 B.V.',
                                    37607: 'CA Civiel bv',
                                    343: 'Cad & Company',
                                    344: 'Cadac',
                                    345: 'Cadix Automatisering',
                                    16857: 'cadmagazine',
                                    346: 'CADMagazine (CMedia)',
                                    18667: 'Cai Coffie',
                                    18890: 'CalcMatic',
                                    37593: 'Caljé Infra Advies',
                                    347: 'Candelux',
                                    348: 'Cane',
                                    349: 'CANTAB',
                                    44642: 'Cap Gemini',
                                    350: 'Cape Groep',
                                    72589: 'Capelse Streekbouw B.V.',
                                    351: 'CardolSoft',
                                    352: 'Career Factory',
                                    72888: 'Carex Ecologisch Beheer',
                                    63830: 'Carrier Airconditioning Benelux B.V.',
                                    353: 'Carve',
                                    354: 'Caspers Infra Service',
                                    40160: 'Castersgroep',
                                    18310: 'CC Infra',
                                    121: 'CCAK',
                                    47270: 'CCW De Gier B.V.',
                                    27863: 'CDC/Bedrijfsgroep Informatievoorziening en -technologie (IVENT) ',
                                    356: 'CdMR Terminal Vlissingen',
                                    8418: 'Cees Boonzaaijer v.o.f.',
                                    357: 'Cees Voeten Holding B.V.',
                                    358: 'Cegeka',
                                    75137: 'Cegelec Fire Solutions B.V.',
                                    359: 'Cenosco',
                                    8310: 'Centraal Bureau Bouwtoezicht',
                                    360: 'Centric IT Solutions',
                                    64428: 'Certhon',
                                    56066: 'CFE Nederland B.V.',
                                    8704: 'CH Consultancy BV',
                                    37313: 'Chinook Projectadviesbureau',
                                    76086: 'Cibocon',
                                    10214: 'CiCom Engineering B.V.',
                                    32072: 'CiCom Infra B.V.',
                                    361: 'Cienan B.V.',
                                    38793: 'Cijbergen GWW B.V.',
                                    362: 'CinCera',
                                    72129: 'Citago Civiel Technisch Advies',
                                    363: 'Citaverde College',
                                    7272: 'Citec Adviesburo',
                                    365: 'Citek Infra',
                                    39747: 'CITEKO civiele techniek',
                                    366: 'CityTec B.V.',
                                    367: 'CIVCOM',
                                    368: 'Civicon BV',
                                    67765: 'Civiel Extra',
                                    18402: 'Civiel Technisch Buro VOF',
                                    369: 'Civiel Technisch Tekenburo Nas',
                                    18828: 'Civiel- & Cultuurtechnische werken Verwoerdt',
                                    30230: 'Civieltechnisch Adviesbureau Vreeven B.V.',
                                    370: 'Civieltechnisch Projectbureau Eppinga',
                                    15030: 'Civielwerk BV',
                                    371: 'Civil at Hart',
                                    372: 'Civil Management',
                                    28502: 'Civil Projects',
                                    373: 'Civil Services & Engineering bv',
                                    376: 'Civil Support',
                                    30060: 'Civil+ B.V.',
                                    375: 'Civilink Engineering BV',
                                    377: 'Civitas Advies',
                                    378: 'Civitech B.V.',
                                    12306: 'Clafis Engineering ',
                                    380: 'Clusius College ',
                                    66147: 'CML Civiel Management Lichtenvoorde',
                                    16712: 'Cobouw',
                                    382: 'Cobra Adviseurs B.V.',
                                    26276: 'Cobra Beheer B.V.',
                                    383: 'Cobra Planadviseurs B.V.',
                                    64224: 'Coen Hagedoorn Bouwgroep B.V.',
                                    12971: 'Cofely Energy & Infra BV',
                                    29529: 'Cofely Energy Solutions BV',
                                    63974: 'Cofra B.V. ',
                                    20009: 'Cogas Facilitair BV ',
                                    384: 'Colijn Aannemersbedrijf B.V.',
                                    385: 'Colsen b.v.',
                                    7000: 'Combinatie CrommeLijn v.o.f.',
                                    52881: 'Combinatie Olasfa VOF',
                                    7639: 'Combinatie Waalkoppel v.o.f.',
                                    386: 'Communikaatie',
                                    44884: 'COMPAREX Nederland B.V.',
                                    11995: 'Compass Infrastructuur Nederland (CIN) B.V.',
                                    22952: 'Compeer B.V.',
                                    70790: 'computerland',
                                    387: 'CONCEPTIVE',
                                    62605: 'Concreet Projectmanagement',
                                    15048: 'ConneXiT',
                                    388: 'Consilium',
                                    389: 'Construct Civiel Ingenieurs B.V.',
                                    390: 'Constructief, Bouw- en Tekenbureau',
                                    46326: 'Content strategy',
                                    391: 'Continu Engineering',
                                    72374: 'Coolblue',
                                    17170: 'Copier Advies Oost B.V.',
                                    15886: 'Copier Advies Utrecht BV',
                                    17151: 'Copier Advies Zuid BV',
                                    15595: 'Copijn',
                                    392: 'Cor Nab B.V.',
                                    72586: 'Cordeel Nederland B.V.',
                                    570: 'Coremans Groep',
                                    31025: 'Corovum Advies B.V.',
                                    58057: 'Cortissos Kostenmanagement',
                                    42359: 'CPL Duiven B.V.',
                                    393: 'CR Projectbureau B.V.',
                                    394: 'CRH Clay Solutions',
                                    23931: 'Croon Electrotechniek ',
                                    395: 'CROW',
                                    20934: 'CSO Adviesbureau BV',
                                    396: 'CT  Boshuis & Zn CV',
                                    70451: 'CT-Plus B.V.',
                                    38990: 'CTB Almere',
                                    397: 'CTB Haaglanden',
                                    398: 'CTB ICT Bouw BV',
                                    65393: 'CTBB B.V.',
                                    40205: 'Culto',
                                    400: 'Cultuurtechniek H.G. van Dorresteijn B.V.',
                                    22676: 'CUMELA Nederland',
                                    401: 'CUR Building and infrastructure',
                                    402: 'Curcacao-gov',
                                    403: 'CWI Amsterdam Centrum/Oost',
                                    405: 'Cyber BV',
                                    406: 'Cyclus N.V.',
                                    407: 'D & D Groenadvies',
                                    408: 'D & D networks B.V.',
                                    19672: 'D-Plus Civil Consultants ',
                                    409: 'D. den Besten Groep',
                                    410: 'D. van der Steen B.V. Aannemingsbedrijf GWW',
                                    411: 'D. van Geemen & Zn bv',
                                    412: 'D.C. Molendijk BV',
                                    413: 'D.J. van Dijk Civiele Techniek',
                                    69469: 'Da Vinci College',
                                    414: 'Daallin',
                                    75572: 'Dael',
                                    44171: 'Dalo B.V.',
                                    54536: 'Dammen VOF',
                                    415: 'Damsteegt Aannemersbedrijf',
                                    416: 'Danny Boers',
                                    417: 'Dar N.V.',
                                    418: 'Dassen W Gronsveld bv',
                                    419: 'DATO-bv',
                                    68951: 'DB-Infra BV',
                                    10185: 'DCB Civil Engineering B.V.',
                                    420: 'De Achterse Hoef',
                                    421: 'de Alliantie',
                                    21999: 'De Alliantie',
                                    423: 'De Aquanoom Water & Management',
                                    16716: 'De Architect',
                                    424: 'De Beer Tuin & Landschap',
                                    48054: 'De Bie Wegenbouw BV',
                                    48146: 'De Boer en De Groot B.V.',
                                    425: 'De Boer Infra BV',
                                    426: 'De Bonth van Hulten bv',
                                    427: 'De Bruggen',
                                    428: 'De Bruyn & Nelisse b.v.',
                                    429: 'De Covik Recycling',
                                    938: 'De Fryske Marren',
                                    40762: 'De Gouden Ploeg',
                                    1703: 'De Graaf Hoveniers V.O.F. ',
                                    29983: 'De Groene Ruimte',
                                    431: 'De Groene Weelde',
                                    69112: 'De Groeningenieurs',
                                    432: 'De Groot & Schagen BV',
                                    62880: 'De Groot Pioniers B.V.',
                                    60245: 'De Haan Consult bv',
                                    433: 'De heer Cor van Beek',
                                    1134: 'De Heer Land en Water',
                                    16896: 'De Ingenieur',
                                    29448: 'De Jong',
                                    434: 'De Jong & Breunis  BV',
                                    435: 'De Jong Grûn en Grien',
                                    436: 'De Jong Zuid-Oost B.V.',
                                    37291: 'De Klinker BV',
                                    51496: 'De Kompanjie',
                                    13603: 'De Koning Straal- en Schilderwerken B.V.',
                                    42639: 'De Krom Bestratingen B.V.',
                                    15865: 'De Kruijter Openbare Verlichting',
                                    14942: 'De Meerlanden',
                                    437: 'De Nekker BV',
                                    73070: 'De Nieuwe Norm B.V.',
                                    438: 'De Nooij Pompinstallaties',
                                    46621: 'De Noordhollandsche Aal',
                                    20244: 'De Omleiding BV',
                                    1185: 'De Overhaag B.V.',
                                    439: 'De Putter',
                                    31045: 'De Risse Groep',
                                    29945: 'De Romein Bouw & Ontwikkeling BV',
                                    8491: 'De Romein Infra & Milieu B.V.',
                                    440: 'De Roo Drente BV',
                                    441: 'De Roo Wegenbouw en Milieutechniek',
                                    22881: 'De Slijpkruik BV',
                                    8192: 'De Vries & van de Wiel',
                                    442: 'De Vroed Infra',
                                    65313: 'De Waard Grondverzet b.v.',
                                    63566: 'De WaterWolf',
                                    75987: 'De Wilde Grond- Weg- en Waterbouw',
                                    31679: 'De Wilde Infra- & Cultuurtechniek B.V.',
                                    25589: 'De Wilde NL BV',
                                    443: 'De Zingende Zaag Aannemer',
                                    38400: 'De Zorgcirkel | Molentocht',
                                    69462: 'Debuco',
                                    56023: 'DECO N.V.',
                                    30149: 'Deerns Raadgevende Ingenieurs',
                                    10322: 'Dejo Projectondersteuning',
                                    444: 'Dekker BV',
                                    445: 'Delft Ingenieursbureau',
                                    446: 'Dell',
                                    447: 'Delta Infra',
                                    448: 'DELTA Lichttechniek',
                                    449: 'Delta Team',
                                    450: 'Delta Vorm Groep',
                                    8820: 'Deltion College',
                                    32660: 'DEMA Systems',
                                    451: 'DEN BOER CCI B.V.',
                                    566: 'Den Boon Projectbestratingen B.V.',
                                    196: 'Den Breejen Infra B.V.',
                                    75141: 'Den Hoed Aannemers B.V.',
                                    15478: 'Den Ouden Aannemingsbedrijf',
                                    63978: 'Desman Pumps B.V. ',
                                    452: 'Deventer Groenbedrijf',
                                    29668: 'Devis BV',
                                    16030: 'Devri Infra BV ',
                                    53536: 'DFA Sneek ',
                                    453: 'DG Groep',
                                    18944: 'DHV | Den Haag',
                                    454: 'DHV | Maastricht',
                                    458: 'DHV B.V. Crediteurenadministratie',
                                    459: 'Dick Klok Cultuurtechniek VOF',
                                    28856: 'Dienst Landelijk Gebied',
                                    460: 'Dienst Landelijk Gebied (regio Zuid)',
                                    30305: 'Dienst Metro',
                                    30065: 'Dienst Onderwijs, Cultuur en Welzijn (OCW)',
                                    461: 'Dienst Vastgoed Defensie',
                                    463: 'Dienst Vastgoed Defensie Centrale Directie / Afdeling projecten',
                                    464: 'Digitaal Schrijven BV',
                                    14304: 'digITeam network',
                                    74522: 'Dijkstaal',
                                    465: 'Dijkstra Groep',
                                    466: 'Dijkstra Koudum',
                                    467: 'Dinn BV',
                                    24514: 'Dirk Jan Jelsma',
                                    70336: 'Dirk Uil',
                                    468: 'DISIGN Civiel Adviesburo',
                                    11915: 'DIVV Dienst Infrastructuur Verkeer en Vervoer',
                                    470: 'DLA + Landscape Architects',
                                    63888: 'Doedijns B.V. Hydraulics',
                                    43274: 'Doeleman Hoveniers & Groenvoorzieners',
                                    471: 'Dohmen Schoonmaak',
                                    473: 'Dolmans Landscaping',
                                    39924: 'Don van der Meer Loon & Kraanverhuur',
                                    58676: 'Donk Groenadviseur',
                                    15642: 'Donker Groen (Tiel)',
                                    474: 'Donkergroen b.v.',
                                    475: 'Dons Hoveniersbedrijf',
                                    476: 'Dordtse Weg- en Waterbouw',
                                    477: 'Dorwaal',
                                    478: 'Dosco Waterwerken B.V.',
                                    479: 'Dostal Wegenbouw',
                                    480: 'Dover Verkeersmaatregelen B.V.',
                                    8581: 'Draad Hoveniers B.V.',
                                    481: 'Draijer Makelaar',
                                    482: 'Drenthe College',
                                    21956: 'DSW Zorgverzekeraar',
                                    483: 'DTV Consultants B.V.',
                                    30867: 'Ducot Engineering & Advies',
                                    7299: 'Ducot Engineering & Advies bv',
                                    484: 'Duijndam B.V.',
                                    76470: 'Duik- & Bergingsbedrijf W. Smit B.V.',
                                    19485: 'Duncan Lodders Grond en Straatwerken ',
                                    485: 'Dunea Duin & Water',
                                    73439: 'Dura Vermeer Beton- en Waterbouw BV Regio Noord West',
                                    7834: 'Dura Vermeer Beton- en Waterbouw BV regio Zuid-West (Barendrecht)',
                                    9686: 'Dura Vermeer Divisie Infra BV Project Hanzelijn',
                                    34810: 'Dura Vermeer Groep NV (Leidschendam)',
                                    486: 'Dura Vermeer Infrastructuur BV',
                                    487: 'Dura Vermeer Infrastructuur BV Midden / Hogenbirk',
                                    30545: 'Dura Vermeer Infrastructuur BV Midden West',
                                    7838: 'Dura Vermeer Infrastructuur BV Zuid West (Klundert-Moerdijk)',
                                    488: 'Dura Vermeer Infrastructuur BV Zuid West (Rotterdam)',
                                    26073: 'Dura Vermeer Infrastructuur BV, Oost',
                                    7653: 'Dura Vermeer Milieu BV',
                                    489: 'Dura Vermeer Ondergrondse infra BV',
                                    15040: 'Dura Vermeer Railinfra BV (Hoofddorp)',
                                    8256: 'Dura Vermeer Railinfra BV (vestiging Utrecht)',
                                    490: 'Dusseldorp  Infra, Sloop en Milieutechniek B.V.',
                                    491: 'Duursema Marion',
                                    15508: 'Duwabo b.v.',
                                    29546: 'DVH-advies',
                                    10881: 'DVJ',
                                    58177: 'DVL Milieuservice BV ',
                                    29831: 'DW Facilitygroep',
                                    492: 'DWW, Driebergse Weg- en Waterbouw b.v.',
                                    1885: 'DynamicHands',
                                    493: 'Dynamicom',
                                    33310: 'DZB Leiden',
                                    31247: 'E. Combrink - Civieltechnisch advies',
                                    494: 'E.B.A. BV',
                                    18397: 'Ebbelaar Engineering ',
                                    495: 'ECHT!',
                                    37459: 'Eco Care Milieutechniek',
                                    498: 'ECO-job bv (west)',
                                    497: 'EcoGroen Advies B.V.',
                                    499: 'Ecoverde',
                                    39226: 'ECT | Europe Container Terminal',
                                    500: 'Ed Maarseveen',
                                    16336: 'Edilon)(Sedra Contracting BV',
                                    501: 'Edwin Snaterse Installatie en Onderhoudsbedrijf',
                                    65140: 'Eekhout Bouw',
                                    502: 'Eelerwoude Noord',
                                    503: 'Eemfors BV',
                                    20745: 'Eemsned',
                                    504: 'Eendracht Werkendam B.V.',
                                    27188: 'Eendracht Zeeland BV',
                                    67083: 'Eerland Certification',
                                    31002: 'Eerland Milieutechniek',
                                    505: 'Eerland Services B.V.',
                                    30820: 'Efteling',
                                    506: 'Egalisee Bestratingen',
                                    507: 'Eijkelboom BV',
                                    30890: 'Eilandgebied Sint Maarten',
                                    16886: 'Eisma Bouwmedia',
                                    57621: 'EKH Hekwerk & Deurtechniek',
                                    48166: 'El Flaco Scherminstallaties bv',
                                    66067: 'Electro-Technisch BureauCas Sombroek B.V. ',
                                    36840: 'Elefant Projecten',
                                    26406: 'Elektrotechniek Hoeksewaard',
                                    508: 'Elektrotechnisch Adviesbureau Janze BV',
                                    509: 'ELINGS',
                                    59587: 'ELJA Beton- en Waterbouw bv',
                                    510: 'Elkro BV',
                                    511: 'Elliote',
                                    512: 'Elshout bv Wegenbouwmij',
                                    41670: 'Elzinga Aannemingsbedrijf',
                                    18894: 'EMC Techniek',
                                    56595: 'Emiel Wiering Ingenieurs bureau',
                                    513: 'Emmtec Services BV',
                                    514: 'Endinet B.V.',
                                    515: 'Eneco energie',
                                    56632: 'Energy Solutions B.V.',
                                    40756: 'ENEXIS (s-hertogenbosch)',
                                    521: 'Enexis b.v.',
                                    30255: 'Enexis BV (Arnhem)',
                                    66864: 'Engelen Groen Uden B.V.',
                                    20366: 'Environ Systems B.V.',
                                    516: 'Enzerink BV',
                                    517: 'Equalit',
                                    72844: 'Erasmus MC - Vastgoedbeheer',
                                    518: 'ErfGoed BV',
                                    73205: 'Ermeco Digitale Communicatie',
                                    24893: 'Erwin Gibcus (zzp) ',
                                    29897: 'Erzet Projectmanagement',
                                    30720: 'Esha Infra Solutions ',
                                    519: 'ESN Automatisering B.V.',
                                    65046: 'Essenstam Loon- en Grondverzetbedrijf',
                                    520: 'Essent Kabelcom',
                                    522: 'Etrusk B.V',
                                    523: 'Ettu',
                                    524: 'Eures',
                                    525: 'Evenementenhal Hardenberg',
                                    30180: 'Evers Adviesburo B.V.',
                                    70069: 'EverSafe',
                                    17917: 'EversPartners',
                                    54658: 'Evert Hageman',
                                    77207: 'Everts Winsum Aannemings- & Grondverzetbedrijf  ',
                                    526: 'Evides Waterbedrijf',
                                    527: 'EVS B.V.',
                                    528: 'EVVM Infra BV',
                                    529: 'Ewoud Visser',
                                    37616: 'Exante',
                                    64199: 'Excelsum Industrial & Marine Services B.V.',
                                    38902: 'Experis | Den Haag',
                                    38923: 'Explosive Clearance Group B.V.',
                                    530: 'ExpoProof',
                                    531: 'Eyework Solutions',
                                    71823: 'F Kennes vof Agro & Cultuurtechniek',
                                    42136: 'F. van Craenenbroeck en Zn',
                                    532: 'F. van Gool Bestratingen',
                                    23905: 'F. Vereijken & Zn. B.V.',
                                    533: 'F.J.A. van der Velde Bestratingen B.V.',
                                    70517: 'F.L. Liebregts B.V.',
                                    534: 'F.P.H. Ploegmakers',
                                    535: 'Fa. A. A. TOLBOOM',
                                    68526: 'Fa. de Peinder & Co',
                                    536: 'Fa. Evenboer',
                                    538: 'Fa.A.Mennega',
                                    69561: 'Faam Asset Management',
                                    539: 'Facilitairbedrijf Rd4 (GEEN OHC)',
                                    30732: 'Facility Services Nederland',
                                    57831: 'Facta',
                                    541: 'Fagus Cost Engineering Consultants',
                                    18114: 'Fagus Projectmanagement & Advies',
                                    65049: 'Famitec Prefab betonsystemen',
                                    69479: 'FDH Calculaties',
                                    71352: 'Feijter Agri',
                                    1066: 'Ferm Werk',
                                    543: 'Ferry Benjamins student',
                                    544: 'Fiber4All (Schuuring Harderwijk)',
                                    78074: 'Fika Hoveniers',
                                    545: 'Filmmore',
                                    546: 'FinIT',
                                    73780: 'Firma Hoogendijk',
                                    547: 'Firma Jonker',
                                    43519: 'Fivelingo ',
                                    75126: 'FixPlan B.V.\t',
                                    7169: 'FlexCiviel',
                                    548: 'Flexurance',
                                    22625: 'Flora Nova Hoveniers & Groenvoorziening BV',
                                    74301: 'FMH Pompservice B.V.',
                                    38944: 'Focus-AVC',
                                    549: 'Foreest Groen Consult',
                                    7735: 'Fortuinlijk Beheer B.V.',
                                    550: 'Frank Musters',
                                    73795: 'Franki Grondtechnieken',
                                    552: 'Freesverhuur Ommen B.V.',
                                    75354: 'Fri-Jado Groep',
                                    25964: 'Friso Bouwgroep',
                                    37068: 'Frissen & Zohren',
                                    554: 'FSA Adviesbureau',
                                    555: 'Fugro GeoServices B.V.',
                                    556: 'Fugro GeoServices B.V.',
                                    557: 'Fugro GeoServices B.V.',
                                    558: 'Fugro GeoServices B.V.',
                                    559: 'Fuhler BV',
                                    560: 'Fundeon',
                                    32814: 'Fundus adviesbureau',
                                    561: 'Furman B&D',
                                    73759: 'Furmanite B.V.',
                                    64111: 'G & S Bouw B. V. ',
                                    562: 'G Kleine Weg en Waterbouw V.O.F',
                                    29617: 'G-84 ',
                                    54200: 'G. de Jong & Zonen b.v.',
                                    42493: 'G. Hellinga B.V.',
                                    564: 'G. Noor B.V.',
                                    18877: 'G. Pellegrom VOF',
                                    7089: 'G. van der Ven BV',
                                    565: 'G. van Dijk en Zn. Midden Nederland B.V.',
                                    64089: 'G.C. Verweij en Zn. Loon- en Grondverzetbedrijf ',
                                    563: 'G.J. Crienen & Zn. B.V.',
                                    567: 'G.J. Oosterhuis',
                                    37982: 'G&A Computers',
                                    568: 'GA&P',
                                    42385: 'Gabriëls Bestrating',
                                    60124: 'Gacon',
                                    31181: 'GACT V.O.F.',
                                    54421: 'Gebiedsmanagers B.V.',
                                    569: 'Gebr. Buyk Betontechnieken b.v.',
                                    571: 'Gebr. de Leeuw B.V.',
                                    572: 'Gebr. Griekspoor B.V.',
                                    58479: 'Gebr. Kanters',
                                    573: 'Gebr. Kloens B.V.',
                                    574: 'Gebr. Kolen BV',
                                    575: 'Gebr. Martens Bestrat.bedrNieuw-Ginneken',
                                    16477: 'Gebr. Olieman B.V.',
                                    27535: 'Gebr. Poot-Labots',
                                    576: 'Gebr. Poppink Daarle',
                                    577: 'Gebr. Remmits BV',
                                    37319: 'Gebr. Rijneveld BV',
                                    29775: 'Gebr. Schouls BV',
                                    58284: 'Gebr. Swinkels BV',
                                    588: 'Gebr. v.d. Berk BV',
                                    36823: 'Gebr. van der Steen bedrijven',
                                    8564: 'Gebr. van Kessel Speciale Technieken en Producten B.V. (Tilburg)',
                                    578: 'Gebr. van Leeuwen Boringen B.V. ',
                                    579: 'Gebr. van Venrooij b.v.',
                                    55153: 'Gebr. vd Oord Bommelerwaard BV',
                                    74534: 'Gebr. Verschoor',
                                    580: 'Gebra-Infra (GEEN OHC)',
                                    27510: 'Gebroeders Bouwman B.V.',
                                    581: 'Gebroeders Dalmulder',
                                    582: 'Gebroeders De Boer',
                                    583: 'Gebroeders De Hollander',
                                    584: 'Gebroeders De Koning BV',
                                    55220: 'Gebroeders Kok',
                                    585: 'Gebroeders Kok',
                                    75636: 'Gebroeders Pol B.V.',
                                    42: 'Gebroeders Schep B.V.',
                                    587: 'Gebroeders van Bemmel',
                                    12435: 'Gebroeders van der Lee V.O.F.',
                                    73303: 'Gebroeders Van der Veekens B.V.',
                                    589: 'Gebroeders van Kessel',
                                    590: 'Gebroeders van Kessel Wegenbouw BV',
                                    591: 'Gebroeders van Vliet',
                                    72749: 'Geerdink Aannemersbedrijf',
                                    65116: 'Geerlofs Koeltechniek',
                                    69220: 'Geka Bouw',
                                    37604: 'Gekatech',
                                    8459: 'Gelders Adviesbureau voor Infrastructuur & Milieu B.V.',
                                    593: 'Geldersch Landschap',
                                    932: "Gemeente 's-Hertogenbosch",
                                    38: 'Gemeente Aa en Hunze',
                                    596: 'Gemeente Aalburg',
                                    597: 'Gemeente Aalsmeer',
                                    598: 'Gemeente Aalten',
                                    600: 'Gemeente Achtkarspelen',
                                    601: 'Gemeente Alblasserdam',
                                    602: 'Gemeente Albrandswaard',
                                    603: 'Gemeente Alkmaar',
                                    604: 'Gemeente Almelo',
                                    605: 'Gemeente Almere',
                                    606: 'Gemeente Alphen aan de Rijn',
                                    607: 'Gemeente Alphen-Chaam',
                                    608: 'Gemeente Ameland',
                                    609: 'Gemeente Amersfoort',
                                    610: 'Gemeente Amstelveen',
                                    29457: 'Gemeente Amsterdam || Dienst Infrastructuur Verkeer en Vervoer',
                                    1228: 'Gemeente Amsterdam Ingenieursbureau (IBA)',
                                    49051: 'Gemeente Amsterdam Stadsarchief',
                                    612: 'Gemeente Amsterdam Stadsdeel Centrum',
                                    614: 'Gemeente Amsterdam Stadsdeel Nieuw-West',
                                    615: 'Gemeente Amsterdam Stadsdeel Noord',
                                    616: 'Gemeente Amsterdam Stadsdeel Oost',
                                    611: 'Gemeente Amsterdam Stadsdeel West',
                                    619: 'Gemeente Amsterdam Stadsdeel Zuid',
                                    623: 'Gemeente Amsterdam Stadsdeel Zuidoost',
                                    33534: 'Gemeente Amsterdam, dienst ruimtelijke ordening',
                                    626: 'Gemeente Apeldoorn',
                                    627: 'Gemeente Appingedam',
                                    629: 'Gemeente Arnhem',
                                    630: 'Gemeente Assen',
                                    631: 'Gemeente Asten',
                                    632: 'Gemeente Baarle-Nassau',
                                    633: 'Gemeente Baarn',
                                    634: 'Gemeente Barendrecht',
                                    635: 'Gemeente Barneveld',
                                    636: 'Gemeente Bedum',
                                    637: 'Gemeente Beek',
                                    62578: 'Gemeente Beemster',
                                    639: 'Gemeente Beesel',
                                    640: 'Gemeente Bellingwedde',
                                    642: 'Gemeente Bergambacht',
                                    643: 'Gemeente Bergeijk',
                                    644: 'Gemeente Bergen',
                                    15007: 'Gemeente Bergen (nh)',
                                    645: 'Gemeente Bergen op Zoom',
                                    646: 'Gemeente Berkelland',
                                    647: 'Gemeente Bernheze',
                                    648: 'Gemeente Bernisse',
                                    649: 'Gemeente Best',
                                    650: 'Gemeente Beuningen',
                                    651: 'Gemeente Beverwijk',
                                    652: 'Gemeente Binnenmaas',
                                    653: 'Gemeente Bladel',
                                    654: 'Gemeente Blaricum',
                                    655: 'Gemeente Bloemendaal',
                                    656: 'Gemeente Boarnsterhim',
                                    657: 'Gemeente Bodegraven Reeuwijk',
                                    658: 'Gemeente Boekel',
                                    660: 'Gemeente Borger-Odoorn',
                                    661: 'Gemeente Borne',
                                    662: 'Gemeente Borsele',
                                    663: 'Gemeente Boskoop',
                                    664: 'Gemeente Boxmeer',
                                    665: 'Gemeente Boxtel',
                                    666: 'Gemeente Breda',
                                    669: 'Gemeente Brielle',
                                    670: 'Gemeente Bronckhorst',
                                    671: 'Gemeente Brummen',
                                    672: 'Gemeente Brunssum',
                                    673: 'Gemeente Bunnik',
                                    674: 'Gemeente Bunschoten',
                                    675: 'Gemeente Buren',
                                    676: 'Gemeente Bussum',
                                    677: 'Gemeente Capelle aan den IJssel',
                                    678: 'Gemeente Castricum',
                                    679: 'Gemeente Coevorden',
                                    680: 'Gemeente Cranendonck',
                                    681: 'Gemeente Cromstrijen',
                                    682: 'Gemeente Cuijk',
                                    683: 'Gemeente Culemborg',
                                    684: 'Gemeente Dalfsen',
                                    685: 'Gemeente Dantumadiel',
                                    686: 'Gemeente De Bilt',
                                    687: 'Gemeente De Marne',
                                    688: 'Gemeente De Ronde Venen',
                                    689: 'Gemeente De Wolden',
                                    690: 'Gemeente Delft',
                                    691: 'Gemeente Delfzijl',
                                    39039: 'Gemeente Den Haag IDC ',
                                    692: 'Gemeente Den Helder',
                                    693: 'Gemeente Deurne',
                                    694: 'Gemeente Deventer',
                                    695: 'Gemeente Diemen',
                                    696: 'Gemeente Dinkelland',
                                    698: 'Gemeente Doesburg',
                                    699: 'Gemeente Doetinchem',
                                    700: 'Gemeente Dongen',
                                    701: 'Gemeente Dongeradeel',
                                    702: 'Gemeente Dordrecht',
                                    704: 'Gemeente Drimmelen',
                                    705: 'Gemeente Dronten',
                                    706: 'Gemeente Druten',
                                    707: 'Gemeente Duiven',
                                    708: 'Gemeente Echt-Susteren',
                                    709: 'Gemeente Edam-Volendam',
                                    710: 'Gemeente Ede',
                                    711: 'Gemeente Eemnes',
                                    712: 'Gemeente Eemsmond',
                                    713: 'Gemeente Eersel',
                                    714: 'Gemeente Eijsden-Margraten',
                                    715: 'Gemeente Eindhoven',
                                    716: 'Gemeente Elburg',
                                    717: 'Gemeente Emmen',
                                    719: 'Gemeente Enschede',
                                    720: 'Gemeente Epe',
                                    721: 'Gemeente Ermelo',
                                    722: 'Gemeente Etten-Leur',
                                    723: 'Gemeente Ferwerderadiel',
                                    724: 'Gemeente Franekeradeel',
                                    726: 'Gemeente Geertruidenberg',
                                    727: 'Gemeente Geldermalsen',
                                    728: 'Gemeente Geldrop-Mierlo',
                                    729: 'Gemeente Gemert-Bakel',
                                    730: 'Gemeente Gennep',
                                    731: 'Gemeente Giessenlanden',
                                    732: 'Gemeente Gilze en Rijen',
                                    49443: 'Gemeente Goeree-Overflakkee',
                                    734: 'Gemeente Goes',
                                    735: 'Gemeente Goirle',
                                    736: 'Gemeente Gorinchem',
                                    737: 'Gemeente Gouda',
                                    739: 'Gemeente Graft-De Rijp',
                                    740: 'Gemeente Grave',
                                    741: 'Gemeente Groesbeek',
                                    742: 'Gemeente Groningen',
                                    743: 'Gemeente Grootegast',
                                    744: 'Gemeente Gulpen-Wittem',
                                    745: 'Gemeente Haaksbergen',
                                    746: 'Gemeente Haaren',
                                    747: 'Gemeente Haarlem',
                                    748: 'Gemeente Haarlemmerliede en Spaarnwoude',
                                    749: 'Gemeente Haarlemmermeer',
                                    751: 'Gemeente Halderberge',
                                    752: 'Gemeente Hardenberg',
                                    753: 'Gemeente Harderwijk',
                                    754: 'Gemeente Hardinxveld-Giessendam',
                                    755: 'Gemeente Haren',
                                    757: 'Gemeente Harlingen',
                                    759: 'Gemeente Hattem',
                                    760: 'Gemeente Heemskerk',
                                    761: 'Gemeente Heemstede',
                                    762: 'Gemeente Heerde',
                                    763: 'Gemeente Heerenveen',
                                    764: 'Gemeente Heerhugowaard',
                                    765: 'Gemeente Heerlen',
                                    766: 'Gemeente Heeze-Leende',
                                    767: 'Gemeente Heiloo',
                                    769: 'Gemeente Hellendoorn',
                                    770: 'Gemeente Hellevoetsluis',
                                    771: 'Gemeente Helmond',
                                    772: 'Gemeente Hendrik-Ido-Ambacht',
                                    773: 'Gemeente Hengelo',
                                    774: 'Gemeente Het Bildt',
                                    14756: 'Gemeente Heumen',
                                    775: 'Gemeente Heusden',
                                    777: 'Gemeente Hillegom',
                                    778: 'Gemeente Hilvarenbeek',
                                    779: 'Gemeente Hilversum',
                                    780: 'Gemeente Hof van Twente',
                                    625: 'Gemeente Hollands Kroon',
                                    781: 'Gemeente Hoogeveen',
                                    782: 'Gemeente Hoogezand-Sappemeer',
                                    783: 'Gemeente Hoorn',
                                    784: 'Gemeente Horst aan de Maas',
                                    785: 'Gemeente Houten',
                                    786: 'Gemeente Huizen',
                                    787: 'Gemeente Hulst',
                                    788: 'Gemeente IJsselstein',
                                    790: 'Gemeente Kaag en Braassem',
                                    791: 'Gemeente Kampen',
                                    792: 'Gemeente Kapelle',
                                    793: 'Gemeente Katwijk',
                                    794: 'Gemeente Kerkrade',
                                    796: 'Gemeente Koggenland',
                                    798: 'Gemeente Kollumerland c.a.',
                                    799: 'Gemeente Korendijk',
                                    800: 'Gemeente Krimpen aan den IJssel',
                                    801: 'Gemeente Laarbeek',
                                    802: 'Gemeente Landerd',
                                    803: 'Gemeente Landgraaf',
                                    804: 'Gemeente Landsmeer',
                                    805: 'Gemeente Langedijk',
                                    806: 'Gemeente Lansingerland',
                                    807: 'Gemeente Laren',
                                    808: 'Gemeente Leek',
                                    809: 'Gemeente Leerdam',
                                    810: 'Gemeente Leeuwarden',
                                    811: 'Gemeente Leeuwarderadeel',
                                    812: 'Gemeente Leiden',
                                    813: 'Gemeente Leiden (BOWO)',
                                    815: 'Gemeente Leiderdorp',
                                    816: 'Gemeente Leidschendam-Voorburg',
                                    817: 'Gemeente Lelystad ',
                                    819: 'Gemeente Leudal',
                                    820: 'Gemeente Leusden',
                                    821: 'Gemeente Liesveld',
                                    822: 'Gemeente Lingewaal',
                                    823: 'Gemeente Lingewaard',
                                    824: 'Gemeente Lisse',
                                    826: 'Gemeente Littenseradiel',
                                    827: 'Gemeente Lochem',
                                    829: 'Gemeente Loon op Zand',
                                    830: 'Gemeente Lopik',
                                    831: 'Gemeente Loppersum',
                                    832: 'Gemeente Losser',
                                    836: 'Gemeente Maasdriel',
                                    837: 'Gemeente Maasgouw',
                                    838: 'Gemeente Maassluis',
                                    839: 'Gemeente Maastricht',
                                    841: 'Gemeente Marum',
                                    842: 'Gemeente Medemblik',
                                    844: 'Gemeente Meerssen',
                                    846: 'Gemeente Menaldumadeel',
                                    847: 'Gemeente Menterwolde',
                                    848: 'Gemeente Meppel',
                                    849: 'Gemeente Middelburg',
                                    851: 'Gemeente Midden-Delfland',
                                    852: 'Gemeente Midden-Drenthe',
                                    854: 'Gemeente Millingen aan de Rijn',
                                    855: 'Gemeente Moerdijk',
                                    1042: 'Gemeente Molenwaard',
                                    856: 'Gemeente Montferland',
                                    857: 'Gemeente Montfoort',
                                    858: 'Gemeente Mook en Middelaar',
                                    859: 'Gemeente Muiden',
                                    860: 'Gemeente Naarden',
                                    861: 'Gemeente Neder-Betuwe',
                                    14499: 'Gemeente Nederlek',
                                    862: 'Gemeente Nederweert',
                                    863: 'Gemeente Neerijnen',
                                    865: 'Gemeente Nieuwegein',
                                    866: 'Gemeente Nieuwkoop',
                                    869: 'Gemeente Nijkerk',
                                    870: 'Gemeente Nijmegen',
                                    945: 'Gemeente Nissewaard',
                                    871: 'Gemeente Noord-Beveland',
                                    872: 'Gemeente Noordenveld',
                                    874: 'Gemeente Noordoostpolder',
                                    875: 'Gemeente Noordwijk',
                                    876: 'Gemeente Noordwijkerhout',
                                    877: 'Gemeente Nuenen',
                                    878: 'Gemeente Nunspeet',
                                    879: 'Gemeente Nuth',
                                    880: 'Gemeente Oegstgeest',
                                    881: 'Gemeente Oirschot',
                                    882: 'Gemeente Oisterwijk',
                                    883: 'Gemeente Oldambt',
                                    884: 'Gemeente Oldebroek',
                                    885: 'Gemeente Oldenzaal',
                                    886: 'Gemeente Olst-Wijhe',
                                    887: 'Gemeente Ommen',
                                    888: 'Gemeente Onderbanken',
                                    889: 'Gemeente Oost Gelre',
                                    8255: 'Gemeente Oosterhout',
                                    891: 'Gemeente Ooststellingwerf',
                                    892: 'Gemeente Oostzaan',
                                    893: 'Gemeente Opmeer',
                                    894: 'Gemeente Opsterland',
                                    7335: 'Gemeente Oss',
                                    895: 'Gemeente Oud-Beijerland',
                                    896: 'Gemeente Oude IJsselstreek',
                                    897: 'Gemeente Ouder-Amstel',
                                    14544: 'Gemeente Ouderkerk',
                                    898: 'Gemeente Oudewater',
                                    899: 'Gemeente Overbetuwe',
                                    900: 'Gemeente Papendrecht',
                                    845: 'Gemeente Peel en Maas',
                                    901: 'Gemeente Pekela',
                                    902: 'Gemeente Pijnacker-Nootdorp',
                                    903: 'Gemeente Purmerend',
                                    904: 'Gemeente Putten',
                                    905: 'Gemeente Raalte',
                                    906: 'Gemeente Reimerswaal',
                                    907: 'Gemeente Renkum',
                                    908: 'Gemeente Renswoude',
                                    909: 'Gemeente Reusel de Mierden',
                                    910: 'Gemeente Rheden',
                                    911: 'Gemeente Rhenen',
                                    912: 'Gemeente Ridderkerk',
                                    1603: 'Gemeente Rijnwaarden',
                                    913: 'Gemeente Rijnwoude',
                                    914: 'Gemeente Rijssen-Holten',
                                    915: 'Gemeente Rijswijk',
                                    916: 'Gemeente Roerdalen',
                                    918: 'Gemeente Roosendaal',
                                    919: 'Gemeente Rotterdam Deelgemeente Feijenoord',
                                    1624: 'Gemeente Rotterdam Sport en Recreatie (GEEN OHC)',
                                    920: 'Gemeente Rozendaal',
                                    921: 'Gemeente Rucphen',
                                    922: 'Gemeente Schagen',
                                    923: 'Gemeente Schermer',
                                    924: 'Gemeente Scherpenzeel',
                                    925: 'Gemeente Schiedam',
                                    926: 'Gemeente Schiermonnikoog',
                                    927: 'Gemeente Schijndel',
                                    928: 'Gemeente Schinnen',
                                    929: 'Gemeente Schoonhoven',
                                    930: 'Gemeente Schouwen-Duiveland',
                                    933: 'Gemeente Simpelveld',
                                    934: 'Gemeente Sint Anthonis',
                                    14783: 'Gemeente Sint-Michielsgestel',
                                    936: 'Gemeente Sint-Oedenrode',
                                    937: 'Gemeente Sittard-Geleen',
                                    1657: 'Gemeente Sliedrecht ',
                                    939: 'Gemeente Slochteren',
                                    940: 'Gemeente Sluis',
                                    941: 'Gemeente Smallingerland',
                                    943: 'Gemeente Soest',
                                    14770: 'Gemeente Someren',
                                    944: 'Gemeente Son en Breugel',
                                    946: 'Gemeente Stadskanaal',
                                    948: 'Gemeente Staphorst',
                                    950: 'Gemeente Steenbergen',
                                    1686: 'Gemeente Steenwijkerland',
                                    951: 'Gemeente Stein',
                                    8817: 'Gemeente Stichtse Vecht (GEEN OHC)',
                                    952: 'Gemeente Strijen',
                                    7714: 'Gemeente Sudwest-Fryslan',
                                    953: 'Gemeente Ten Boer',
                                    954: 'Gemeente Terneuzen',
                                    955: 'Gemeente Terschelling',
                                    956: 'Gemeente Texel',
                                    958: 'Gemeente Teylingen',
                                    959: 'Gemeente Tholen',
                                    960: 'Gemeente Tiel',
                                    961: 'Gemeente Tilburg',
                                    962: 'Gemeente Tubbergen',
                                    963: 'Gemeente Twenterand',
                                    964: 'Gemeente Tynaarlo',
                                    965: 'Gemeente Tytsjerksteradiel',
                                    966: 'Gemeente Ubbergen',
                                    967: 'Gemeente Uden',
                                    968: 'Gemeente Uitgeest',
                                    969: 'Gemeente Uithoorn',
                                    970: 'Gemeente Urk',
                                    971: 'Gemeente Utrecht',
                                    972: 'Gemeente Utrecht Stadswerken',
                                    973: 'Gemeente Utrechtse Heuvelrug',
                                    974: 'Gemeente Vaals',
                                    975: 'Gemeente Valkenburg aan de Geul',
                                    976: 'Gemeente Valkenswaard',
                                    977: 'Gemeente Veendam',
                                    978: 'Gemeente Veenendaal',
                                    979: 'Gemeente Veere',
                                    980: 'Gemeente Veghel',
                                    981: 'Gemeente Veldhoven',
                                    982: 'Gemeente Velsen',
                                    983: 'Gemeente Venlo',
                                    984: 'Gemeente Venray',
                                    985: 'Gemeente Vianen',
                                    986: 'Gemeente Vlaardingen',
                                    987: 'Gemeente Vlagtwedde',
                                    988: 'Gemeente Vlieland',
                                    989: 'Gemeente Vlissingen',
                                    990: 'Gemeente Vlist',
                                    991: 'Gemeente Voerendaal',
                                    992: 'Gemeente Voorschoten',
                                    993: 'Gemeente Voorst',
                                    994: 'Gemeente Vught',
                                    995: 'Gemeente Waalre',
                                    996: 'Gemeente Waalwijk',
                                    997: 'Gemeente Waddinxveen',
                                    998: 'Gemeente Wageningen',
                                    1000: 'Gemeente Waterland',
                                    1001: 'Gemeente Weert',
                                    1002: 'Gemeente Weesp',
                                    1003: 'Gemeente Werkendam',
                                    1005: 'Gemeente West Maas en Waal',
                                    1006: 'Gemeente Westerveld',
                                    1007: 'Gemeente Westervoort',
                                    1008: 'Gemeente Westland',
                                    1009: 'Gemeente Weststellingwerf',
                                    1010: 'Gemeente Westvoorne',
                                    1011: 'Gemeente Wierden',
                                    1014: 'Gemeente Wijchen',
                                    1015: 'Gemeente Wijdemeren',
                                    1016: 'Gemeente Wijk bij Duurstede',
                                    1017: 'Gemeente Winsum',
                                    1018: 'Gemeente Winterswijk',
                                    1019: 'Gemeente Woensdrecht',
                                    1020: 'Gemeente Woerden',
                                    63476: 'Gemeente Wormerland',
                                    1022: 'Gemeente Woudenberg',
                                    1023: 'Gemeente Woudrichem',
                                    1026: 'Gemeente Zaanstad',
                                    1027: 'Gemeente Zaltbommel',
                                    1028: 'Gemeente Zandvoort',
                                    14658: 'Gemeente Zederik',
                                    638: 'Gemeente Zeevang (voorheen Technische Dienst Beemster en Zeevang)',
                                    1029: 'Gemeente Zeewolde',
                                    1030: 'Gemeente Zeist',
                                    1031: 'Gemeente Zevenaar',
                                    1033: 'Gemeente Zoetermeer',
                                    1034: 'Gemeente Zoeterwoude',
                                    1035: 'Gemeente Zuidhorn',
                                    1036: 'Gemeente Zuidplas',
                                    1037: 'Gemeente Zundert',
                                    1038: 'Gemeente Zutphen',
                                    1039: 'Gemeente Zwartewaterland',
                                    1040: 'Gemeente Zwijndrecht',
                                    1041: 'Gemeente Zwolle',
                                    1043: 'Gemeentewerken Rotterdam (OBu)',
                                    33141: 'Geo Infra',
                                    18980: 'Geofox-Lexmond Milieuadviesbureau B.V.',
                                    65539: 'Geonique Group B.V.',
                                    1044: 'Geonius Civiel BV',
                                    1045: 'Geoplus',
                                    1046: 'Gerald Assink Civiele Techniek',
                                    73708: 'Gerco Brandpreventie B.V.',
                                    26261: 'Gerves Advies',
                                    38953: 'Geurts Civiele Techniek',
                                    59825: 'Geveke Bouw & Ontwikkeling b.v. Groningen',
                                    59823: 'Geveke Bouw & Ontwikkeling b.v. Hoofdkantoor Eelde',
                                    64222: 'GF Deko B.V. ',
                                    33456: 'Gijsbers Hoveniers B.V.',
                                    1048: 'Gilde Infra',
                                    25103: 'Gilde Opleidingen',
                                    13717: 'Gilde Software bv',
                                    1049: 'Giljam',
                                    16861: 'GISmagazine',
                                    1050: 'GIZA',
                                    1051: 'GJM Bestratingen',
                                    1052: 'GKB Realisatie B.V. ',
                                    52760: 'GL Infra',
                                    1053: 'GMB Beheer B.V.',
                                    13484: 'GMB Civiel IJhorst',
                                    13482: 'GMB Civiel Ridderkerk',
                                    1054: 'GMB Crienen',
                                    7428: 'GMB Infra BV',
                                    1055: 'GMG Groenmanagement',
                                    1056: 'GOA Infra Groep',
                                    1057: 'Gobar Adviseurs',
                                    1058: 'Gommers Grondwerken B.V.',
                                    1505: 'Gotink civiele techniek',
                                    1059: 'Goudappel Coffeng',
                                    1060: 'Gouwens GWW BV',
                                    38964: 'Graafmachineverhuur L.A.W. Tol B.V.',
                                    37377: 'Graaumans Aannemersbedrijf BV',
                                    51438: 'Graef Grond- & Straatwerk',
                                    1061: 'Grasveld Civiele Techniek',
                                    70454: 'Grasveld Tuin- en Landschapsarchitecten',
                                    1062: 'Gravier',
                                    1063: 'Green Consult',
                                    59835: 'Green Trust Consultancy B.V.',
                                    29643: 'Greenengineers',
                                    1064: 'GreenMax',
                                    1065: 'Greenpoint Advies',
                                    43439: 'Groen Aannemingsbedrijf Punt B.V.',
                                    1067: 'Groen²',
                                    19996: 'Groendoordacht',
                                    74704: 'GroeneTechnieken BV',
                                    1068: 'Groeneveld & Zn Schilderwerken',
                                    73163: 'Groeneveld GWW',
                                    1069: 'Groenewoud Infra Advies',
                                    1070: 'Groenhorst College',
                                    48843: 'Groenkels B.V.',
                                    1071: 'Groenplanning Maastricht bv',
                                    1072: "GroenRijk 't Haantje",
                                    15781: 'Groenservice Zuid-Holland',
                                    34: 'Groenvoorziening A.J. van der Werf B.V.',
                                    55318: 'GroenWerk XL BV',
                                    31176: 'Grondbewerkingsbedrijf Blijdorp B.V.',
                                    1074: 'Grondslag B.V.',
                                    1075: 'Grondtechniek Kuttschruiter',
                                    1076: 'Grondverbetering Hay Aerts',
                                    1077: 'Grondverzet en loonbedrijf Hoekstra & Zoon',
                                    1078: 'Grondverzet Kiggen Ospel',
                                    1079: 'Grondverzet- en aannemingsbedrijf Van der Meer B.V.',
                                    1266: 'Grondverzetbedrijf Van Werven B.V. -  locatie Ouderkerk',
                                    8856: 'Grondweg B.V.',
                                    26057: 'Grondwerkbedrijf Westland V.O.F.',
                                    31096: 'Grondwerken Rouwmaat ',
                                    1080: 'Groningen Seaports',
                                    1081: 'Grontmij | Afdeling bouw Haren',
                                    1082: 'Grontmij | Arnhem',
                                    1083: 'Grontmij | Drachten | Civiel',
                                    18897: 'Grontmij | Haren',
                                    1085: 'Grontmij | Houten',
                                    9005: 'Grontmij | Middelburg',
                                    1084: 'Grontmij | Regio Randstad Zuid',
                                    1088: 'Grontmij | Roosendaal',
                                    52321: 'Grontmij Alkmaar',
                                    1087: 'Grontmij Capital Consultants B.V.',
                                    52422: 'Grontmij Eindhoven',
                                    1089: 'Grontmij Infrastructuur & Infra',
                                    8691: 'Grontmij Lelystad',
                                    62467: 'Grontmij Nederland BV (De Bilt)',
                                    1090: 'Grontmij Waterbouw & Tunneltechniek',
                                    1091: 'Groot H. de  Bestratingen',
                                    1092: 'Groot Lemmer BV',
                                    13399: 'Groote Infra BV',
                                    55521: 'Grootgroener b.v.',
                                    13859: 'Groundwater Technology',
                                    1093: 'Group 4 Securicor',
                                    1094: 'Grundfos Nederland B.V.',
                                    74636: 'Grutte Fier V.O.F.',
                                    1095: 'GS1 Nederland B.V.',
                                    67556: 'GSNED',
                                    1096: 'Gustaaf Geldrop BV',
                                    70262: 'Guus de Vriend Civiele Techniek',
                                    28863: 'GVB Infra',
                                    68541: 'GW Leidingtechniek BV',
                                    1097: 'GWW Adviesbureau',
                                    16734: 'GWW Totaal',
                                    1098: 'GWW-Ingenieurs B.V.',
                                    42695: 'GWWTech',
                                    1099: 'GWY Infra BV',
                                    1100: 'H. van Dongelen Aannemersbedrijf',
                                    1101: 'H. van Wijk Transport en Aannemingsbedrijf',
                                    43795: 'H. Verhoef B.V.',
                                    1102: 'H. Wagenaars Aanneming & Transport',
                                    29792: 'H.A. van Boxtel & Zn B.V. ',
                                    1103: 'H.A.B.O. Loonbedrijf',
                                    1104: 'H.H. van Egmond',
                                    1106: 'H.J. van Nierop en zn',
                                    63773: 'H.v. Steenwijk b.v.',
                                    43118: 'H.V.D. bestratingen & sierbestratingen',
                                    72772: 'H&D Civiel B.V.',
                                    8641: 'H2riO B.V. ',
                                    1107: 'H4A (Holding de Vier Ambachten BV)',
                                    1108: 'Haagse Hogeschool',
                                    27273: 'Haagse Wapenings Centrale B.V.',
                                    49363: 'Haak Infra B.V.',
                                    39859: 'Haak Projectbegeleiding',
                                    31620: 'Haarsma Infra & Milieu BV',
                                    39591: 'Haasnoot bruggen BV',
                                    1109: 'HABO GWW BV',
                                    27481: 'Hacron V.O.F.',
                                    1111: 'Hak b.v.',
                                    72382: 'Hak Civiel Advies',
                                    74250: 'Hak Kraanverhuur en Grondverzet B.V.',
                                    1112: 'Hakkers BV ',
                                    1113: 'Halm G.W.W.',
                                    1114: 'Ham Van  Bestratingen',
                                    20836: 'Hamer Installatietechniek BV',
                                    1115: 'Hamilton Consult',
                                    68782: 'Hanenbergh GWW',
                                    1116: 'Hanne Leijsen',
                                    1117: 'Hans van der Bom Groenvoorziening',
                                    35185: 'HanzaRailTeam',
                                    16889: 'Hanze Media Groep B.V.',
                                    1118: 'Hanzehogeschool Groningen',
                                    1120: 'Harteman Weg- en Waterbouw BV',
                                    18841: 'HAS KennisTransfer ',
                                    1121: 'HaskoningDHV Nederland B.V.',
                                    31090: 'Hatab Tekenadviesbureau',
                                    1122: 'Haterd Aannemingsbedrijf T. v.d.  B.V.',
                                    37306: 'Hattink Advies BV',
                                    1123: 'Haveco Plancoördinatie',
                                    28391: 'Havenbedrijf Amsterdam ',
                                    1124: 'Havenbedrijf Rotterdam (Port of Rotterdam)',
                                    1127: 'Haverkort Voormolen Capelle aan den IJssel',
                                    75652: 'HAZ Groen Grond Infra',
                                    63717: 'Hazebroek en Tamerus Wegenbouw BV',
                                    1128: 'Hazenberg Grond-,Weg-,en Waterbouw',
                                    1129: 'HB Adviesbureau bv',
                                    1130: 'HB Technical Services BV (GEEN OHC)',
                                    37631: 'HBL Adviesgroep BV',
                                    1131: 'HCPO',
                                    1132: 'HD Infratechniek B.V.',
                                    27216: 'HD verhuur & advies',
                                    1133: 'Head Engineering BV',
                                    65136: 'Heddes Bouw & Ontwikkeling B.V.',
                                    1135: 'Heerema Fabrication Group SE',
                                    1136: 'Heesakkers aannemingsbedrijf BV',
                                    11762: 'Hegeman Beton- en Industriebouw B.V.',
                                    29789: 'Hegeman Bouw Chemie',
                                    70926: 'Hegeman+ Belbouw Twente',
                                    1137: 'Heijdra Aannemingsbedrijven B.V.',
                                    1138: 'Heijdra Milieu Service B.V.',
                                    8130: 'Heijmans Beton- en Waterbouw B.V. (Nijkerk)',
                                    38851: 'Heijmans Civiel BV Grote projecten',
                                    1139: 'Heijmans Infrastructuur',
                                    41814: 'Heijmans Integrale Projecten',
                                    7037: 'Heijmans Nederland B.V.',
                                    1140: 'Heijmans Restauratiewerken',
                                    30807: 'Heijmans Sport en Groen',
                                    42833: 'Heijmans Wegen, Bodemspecialismen (Assendelft)',
                                    23682: 'Heijmans Wegen, Regio Noord',
                                    13648: 'Heijmans Wegenbouw B.V. (Veenendaal)',
                                    1142: 'Heijmans Wegenbouw BV, Regio Eindhoven',
                                    42183: 'Heijmans Wegenbouw Regio Zuidwest',
                                    1143: 'Heijting Aannemersbedrijf',
                                    1144: 'Hein van Gastel',
                                    1145: 'Hejoti',
                                    24303: 'Helcon A&B BV',
                                    1146: 'Helicon MBO Den Bosch, locatie Boxtel',
                                    430: 'Helicon MBO Helmond',
                                    1147: 'Helicon MBO Velp',
                                    60930: 'Helicon VMBO Eindhoven',
                                    1148: 'Hemmen BV Aanneming Wegenbouw',
                                    64117: 'Hemubo Holding B.V. ',
                                    56925: 'Hendrickx bouwwerken ',
                                    60656: 'Hendriks Infra BV',
                                    7303: 'Hendriks Projectbegeleiding',
                                    68093: 'Hengelo',
                                    1149: 'Henk Venekamp Bouw- en Advisering',
                                    1150: 'Hennephof-Zeevang',
                                    58144: 'Heras Security Systems (Oirschot)',
                                    49635: 'Heras Zwolle',
                                    20182: 'Hercuton b.v.',
                                    1151: 'Herke Technology',
                                    39848: 'Herman van der Heiden BV',
                                    1152: 'Hermans Bestratingen & Wegenbouw (GEEN OHC)',
                                    10056: 'Herms B.V.',
                                    23900: 'Herruer GWW',
                                    31130: 'Het Miland',
                                    1153: 'Het NIC BV',
                                    68312: 'Het Waterschapshuis',
                                    9119: 'Heukelom Verbeek landschapsarchitectuur',
                                    50446: 'Heuvelman Ibis B.V.',
                                    65143: 'Hexta Hekwerk',
                                    1154: 'HGH',
                                    1105: 'HIG Traffic Systems B.V.',
                                    89948: 'Hijmachine Inc.',
                                    64186: 'Hillen & Roosen Bouw B.V.',
                                    78604: 'HM Civiel & Afkoppelen',
                                    1155: 'Hobelman - Halle BV',
                                    1156: 'Hoeflake Electrotechniek BV',
                                    28651: 'Hoeflake Infratechniek BV',
                                    1157: 'Hoefnagels Civiel Techniek',
                                    33154: 'Hoefnagels Civiel Techniek B.V.',
                                    33158: 'Hoefnagels Civiel Techniek B.V.',
                                    52743: 'Hoeksche Waard Infra',
                                    1158: 'Hoekstra GWW Advies',
                                    57617: 'Hofman Advies',
                                    250: 'Hofmeijer Civiel- en Cultuurtechniek',
                                    47287: 'Hofstaede Infra B.V.',
                                    75297: 'Hofstede',
                                    1159: 'Hofstee GWW Hofstee Service RB',
                                    1160: 'Hogeboom aannemersbedrijf Rijpwetering bv',
                                    1161: 'Hogenes Infra',
                                    13299: 'Hogeschool Inholland (Alkmaar)',
                                    1162: 'Hogeschool Inholland (Delft)',
                                    13302: 'Hogeschool Inholland (Haarlem)',
                                    76806: 'Hogeschool PXL',
                                    12694: 'Hogeschool Rotterdam',
                                    13309: 'Hogeschool Utrecht',
                                    13330: 'Hogeschool van Amsterdam',
                                    13333: 'Hogeschool van Arnhem en Nijmegen',
                                    1163: 'Hogeschool Zeeland',
                                    1164: 'Hogeschool Zuyd',
                                    37414: 'HOKA Verkeerstechniek',
                                    17476: 'Holding H. van den Beuken B.V.',
                                    1167: 'Holding H.W. Tuenter B.V.',
                                    64108: 'Holland Riooltechniek B.V. - Den Haag',
                                    68118: 'Holland Scherm bv',
                                    10939: 'Hollandia Services B.V.',
                                    1168: 'Homans Infra',
                                    77043: 'Hommelink Groenlo',
                                    74479: 'Hompe en Taselaar',
                                    1169: 'Hompert - Renes BV',
                                    63211: 'Hoofdkantoor Barendrecht',
                                    37477: 'Hoogeboom Raalte',
                                    60620: 'Hoogendoorn Boomadvies B.V.',
                                    1171: 'Hoogeveen',
                                    1172: 'Hoogheemraadschap Amstel, Gooi en Vecht',
                                    1173: 'Hoogheemraadschap De Stichtse Rijnlanden',
                                    1174: 'Hoogheemraadschap Hollands Noorderkwartier',
                                    1175: 'Hoogheemraadschap van Delfland',
                                    1176: 'Hoogheemraadschap van Rijnland',
                                    1177: 'Hoogheemraadschap van Schieland en de Krimpenerwaard',
                                    1178: 'Hoogstraten Wegenbouw BV',
                                    37429: 'Hoogveld Technical Support BV',
                                    20930: 'Hooijer Wegenbouw BV',
                                    1179: 'Hoornstra Infrabouw BV',
                                    1180: 'Hop Bedrijven Groep',
                                    1181: 'Horeyezon Cadsourcing bv',
                                    17505: 'Horizoncollege Heerhugowaard',
                                    17515: 'Horizoncollege Hoorn',
                                    43186: 'Houta Groep ',
                                    1182: 'Hovenier Van Der Heijden',
                                    56978: 'Hoveniersbedrijf  Graveland',
                                    67935: "Hoveniersbedrijf 't Hofland",
                                    27567: 'Hoveniersbedrijf B. de Kok',
                                    1183: 'Hoveniersbedrijf Bosch',
                                    1184: 'Hoveniersbedrijf Chris van Schaik',
                                    20953: 'Hoveniersbedrijf Diederen',
                                    1186: 'Hoveniersbedrijf Hollanders BV',
                                    1187: 'Hoveniersbedrijf HPG',
                                    9854: 'Hoveniersbedrijf Jac Kolsters',
                                    1188: 'Hoveniersbedrijf Jef Spees BV',
                                    47160: 'Hoveniersbedrijf Luke Bouwman',
                                    65406: 'Hoveniersbedrijf Mark Kuhlkamp',
                                    40507: 'Hoveniersbedrijf René Schutte',
                                    1189: 'Hoveniersbedrijf Rob v/d Steen',
                                    1190: 'Hoveniersbedrijf Ton van den Hurk B.V.',
                                    37988: 'Hoveniersbedrijf van Triest B.V.',
                                    1191: 'Hoveniersbedrijf Vermeeren',
                                    78110: 'HR Groep',
                                    1192: 'HS Advies',
                                    18844: 'HSO Civieltechnisch Bureau',
                                    28943: 'HTM Personenvervoer NV',
                                    53131: 'HU!G Consultancy',
                                    12356: 'Huijskes VRT B.V.',
                                    25875: 'Huiskamp Enschede',
                                    1193: 'Hulsegge International',
                                    26920: 'Hulsen-Leende',
                                    1194: 'Hülskens Wasserbau GmbH & Co. KG',
                                    47615: 'Hunneman Milieu-Advies Raalte BV',
                                    51346: 'Hurks bouw ',
                                    1195: 'Huybens Haarlem',
                                    42382: 'HvR Speeltotaal',
                                    40894: 'Hyva Nederland',
                                    29080: 'i4projects',
                                    12656: 'IA Groep',
                                    7949: 'IB Braak',
                                    1196: 'IBN Facilitair',
                                    15137: 'IBOR-Advies B.V. ',
                                    30728: 'IBZH Raadgevend Ingenieursbureau',
                                    1197: 'ICM Groep',
                                    27582: 'ICM Holding B.V.',
                                    1198: 'ICM Infraconsult BV',
                                    41616: 'ICS Nederland Specialistische Reiniging BV ',
                                    1200: 'ICT-Dienstverlening',
                                    1201: 'Idd-WE',
                                    15869: 'IDDS (Breda)',
                                    51546: 'IDDS Infra B.V.',
                                    8620: 'IEDERZ',
                                    1202: 'Iemants NV',
                                    57223: 'IGG ',
                                    1203: 'IHC Hytop',
                                    37624: 'IJK Advies',
                                    29880: 'Ijsselgroen',
                                    1204: 'IJzerman BV',
                                    1205: 'Ilektro C.V.',
                                    1206: 'ImProvia B.V.',
                                    8784: 'Imtech | B.U. Imtech Infra Nederland ',
                                    69900: 'Imtech Industrial Services',
                                    1207: 'Imtech Infra B.V. (Wormerveer)',
                                    1208: 'Imtech Nettenbouw Infra',
                                    7622: 'Imtech Traffic & Infra (Amersfoort)',
                                    73173: 'IMTO Benelux Vastgoedonderhoud',
                                    1209: 'Incite Projects bv',
                                    1210: 'Inclusief Gresbo',
                                    30894: 'Independent Consulting Engineers N.V.',
                                    1211: 'InfoGroen',
                                    1212: 'InfoStrait',
                                    29387: 'Infoworks',
                                    1213: 'Infra Active',
                                    18436: 'Infra Assist',
                                    72056: 'Infra Consultancy B.V.',
                                    30915: 'Infra Engineering',
                                    54986: 'Infra Gemak',
                                    59178: 'Infra Groep Nederland BV (IGN bv)',
                                    16286: 'Infra Light Engineering',
                                    12455: 'Infra Plus Civiele Techniek & Landmeetkunde',
                                    30107: 'Infra PMC',
                                    1215: 'Infra-Advies GJ van Schoonhoven',
                                    42767: 'infra-calculator.com',
                                    1221: 'Infra-Lux B.V.',
                                    30208: 'Infra3 ID',
                                    73841: 'InfraCalc',
                                    1216: 'InfraDEM',
                                    1218: 'Infrafuture',
                                    1219: 'InfraGilde Intermediair',
                                    1220: 'Infralight',
                                    551: 'Inframea B.V. ',
                                    1222: 'iNFRANEA',
                                    1223: 'Infrascoop BV',
                                    72038: 'Infraservicebureau ISB',
                                    16731: 'Infrasite',
                                    1224: 'Infrasoil BV',
                                    39529: 'Infraspeed',
                                    29562: 'Infrateam ',
                                    1225: 'Infratech Ahoy',
                                    1214: 'Infratotaal (GEEN OHC)',
                                    16041: 'InfraVisie',
                                    1226: 'ING Bank',
                                    1227: 'ING Car Lease',
                                    1233: 'Ingenieursbureau  Gemeente Hilversum',
                                    30994: 'Ingenieursbureau Alfons Schuurmans',
                                    8289: 'Ingenieursbureau Amsterdam (IBA), Dienst Infrastructuur Verkeer en Vervoer (DIVV)',
                                    29749: 'Ingenieursbureau Boorsma',
                                    30200: 'Ingenieursbureau C.M.L.',
                                    1229: 'Ingenieursbureau Den Haag (IbDH)',
                                    1230: 'Ingenieursbureau Den Haag RanstadRail',
                                    1231: 'Ingenieursbureau Drechtsteden',
                                    1232: 'Ingenieursbureau Gemeente Rotterdam',
                                    75554: 'Ingenieursbureau Klink',
                                    15489: 'Ingenieursbureau Land B.V.',
                                    37395: 'Ingenieursbureau M.U.C. BV',
                                    33132: 'Ingenieursbureau Maters en de Koning',
                                    1235: 'Ingenieursbureau Rodewijk',
                                    1236: 'Ingenieursbureau Techcon',
                                    1237: 'Ingenieursbureau Utrecht (IBU)',
                                    52481: 'Ingenieursbureau Walhout Civil',
                                    29191: 'Ingenieursbureau Wegnet',
                                    18033: 'Ingenieursbureau Westenberg B.V.',
                                    1239: 'Ingenieursburo MUC BV',
                                    1240: 'InnerSite training, coaching en advies',
                                    61293: 'Innovative Water Contractors B.V.',
                                    1241: 'InRIO advies- en ingenieursbureau B.V.',
                                    1242: 'Insight B.V.',
                                    64865: 'Insituform Rioolrenovatietechnieken',
                                    1243: 'inSon BV',
                                    1244: 'inSon BV',
                                    37486: 'Inspec Nederland BV',
                                    1245: 'Inspectrum',
                                    12654: 'Installatie Advies Groep ',
                                    16728: 'Installatie Journaal',
                                    74095: 'Installatiebedrijf Kruit B.V.',
                                    73798: 'Installatietechniek Louwer B.V.',
                                    73809: 'Installatietechniek Thermos B.V.',
                                    1246: 'Int. Agrarische Hogeschool Larenstein',
                                    37461: 'InTech Ingenieurs & Technisch Adviesbureau ',
                                    35232: 'Intechgraal',
                                    65578: 'Inter Boat Marinas B.V.',
                                    42323: 'Inter Producten Bureau B.V.',
                                    7649: 'Inter Techniek B.V.',
                                    1247: 'Interra Adviseurs en Ingenieurs',
                                    74890: 'Intersafe',
                                    1248: 'Intos',
                                    1250: 'Intrum Justitia',
                                    8666: 'Invantive',
                                    56070: 'Investment Engineering',
                                    1251: 'InVra plus Civieltechnisch Advies & Management',
                                    29902: 'IOB Hellevoetsluis',
                                    1252: 'IP Lighting',
                                    1253: 'IPC Groen',
                                    54990: 'Ippel Civiele Betonbouw\nJanssen de Jong Infra',
                                    74816: 'Ippel Dredging B.V.',
                                    1254: 'IPV Delft',
                                    1255: 'iQ Bouwkostenadvies',
                                    1256: 'Irado NV',
                                    55439: 'IRIS Anticorrosion Geel  ',
                                    74089: 'Isolatiebedrijf C.J. van Staveren',
                                    1258: 'ISSUE Information Technology B.V.',
                                    64293: 'Istimewa Elektrotechniek ',
                                    1259: 'IT&T',
                                    51610: 'Itannex',
                                    1261: 'Iv-Infra B.V.',
                                    8278: 'IV-Water B.V.',
                                    66489: 'J. & C.J. Baars Hoofddorp B.V.',
                                    537: 'J. Bos en Zn. B.V.',
                                    37009: 'J. de Ridder B.V.',
                                    1262: 'J. den Breejen & Zn BV',
                                    15501: 'J. Glijnis B.V.',
                                    1263: 'J. Jonker & Zn.',
                                    1264: 'J. Koper & Zn BV',
                                    1265: 'J. Markusse BV',
                                    66867: 'J. Smits Bouw en Aannemingsbedrijf',
                                    29073: 'J. van Dorp & Zn',
                                    30120: 'J. van Esch BV',
                                    72761: 'J. van Huizen Groenvoorzieningen',
                                    1748: 'J. van Uijthoven & Zn. B.V.',
                                    53185: 'J. Westveer',
                                    1268: 'J.A. Goris & Zonen BV',
                                    7213: 'J.A. Zuidam Transport- en Aannemingsbedrijf B.V.',
                                    1269: 'J.A.B. Bestratingen',
                                    1270: 'J.B. Iris',
                                    38444: 'J.C. Krans Aannemingswerken BV',
                                    22646: 'J.H. van Elferen Groenvoorziening',
                                    29774: 'J.H.F. van Elk Wegenbouw Adviesbureau ',
                                    73801: 'J.J. van der Speld en Zonen B.V.',
                                    1271: 'J.M. de Wit Groenvoorziening BV',
                                    1272: 'J.M. Scheerhoorn Bestratingen',
                                    64226: 'J.P. Isolatie B.V.',
                                    1273: 'J.P. Koolmoes Infra B.V.',
                                    1303: 'J.P. Schilder B.V.',
                                    1274: 'J.P. Van der Wal V.O.F.',
                                    56313: 'J.P. van Nieuwkoop Gouda B.V.  ',
                                    1275: 'Jaartsveld BV',
                                    1276: 'Jac. Barendregt BV',
                                    1277: 'Jager Midwolde',
                                    1278: 'Jama sierbestrating',
                                    31695: 'Jan de Nul N.V.',
                                    1279: 'Jan Jongedijk',
                                    1310: 'Jan Kamminga Projectbegeleiding',
                                    70914: 'Jan Knijnenburg bv',
                                    51260: 'Jan Kuipers Nunspeet',
                                    38960: 'Jan van den Boomen B.V.',
                                    1281: 'Jansen Bosbouw',
                                    9130: 'Jansen Heeten',
                                    1282: 'Jansen Venneboer B.V.',
                                    18141: 'Jansma Drachten B.V.',
                                    1283: 'Janssen Cultuur Techniek',
                                    1284: 'Janssen de Jong Groep B.V.',
                                    1285: 'Janssen de Jong Infra bv',
                                    1286: 'Janssen de Jong Infra bv Regio Oost',
                                    1287: 'Janssen Grondverzet en Transport BV',
                                    18320: 'Janze Projectmanagement BV',
                                    1288: 'JD Infra',
                                    1289: 'JDB Bestratingen',
                                    68366: 'Jens D&P B.V.',
                                    1290: 'Jeras Project Management BV',
                                    1291: 'Jeris Bouwkundig Tekenburo',
                                    1292: "Jerry's Tegelwerken",
                                    79197: 'Jethec',
                                    65648: 'JETHEC',
                                    1293: 'JHSW',
                                    42995: 'JINC',
                                    10981: 'JJ Schenk',
                                    1294: 'JMB Aannemersbedrijf',
                                    1295: 'Jochems Bouwburo',
                                    1840: 'Joh. Versteeg B.V.',
                                    1296: 'John Volk Boomverzorging',
                                    1297: 'Jongman Infra',
                                    68555: 'Jonk Advies',
                                    1298: 'Jonk b.v.',
                                    1299: 'Joost Visser Infra BV',
                                    1300: 'Jos Kanters Groenvoorziening',
                                    28541: 'Jos Krekels Management Consultancy & Training',
                                    1301: 'Jos Scholman b.v. (niet meer gebruiken)',
                                    48632: 'Jos Scholman Infra B.V.',
                                    21343: 'Jos Smeenge',
                                    49319: 'Jos van den Bersselaar Constructie B.V.',
                                    26386: 'Jos van der Graaf bv',
                                    10848: 'Jos van Laar Wegen en Grond',
                                    1302: 'Joulz B.V.',
                                    64266: 'JP Van der Schoot',
                                    7738: 'JPB Projectbegeleiding',
                                    73846: 'JSB Infra',
                                    1304: 'JT Bestratingen',
                                    47441: 'Juust',
                                    1305: 'JVA Stolwijk bv',
                                    33043: 'JVA Stolwijk BV',
                                    15861: 'JWV-Civiel',
                                    1307: 'K.A. van Daalen en Zn B.V.',
                                    1308: 'K2-B',
                                    60571: 'KAI Advies',
                                    38469: 'Kaim Infra Consultancy',
                                    1309: 'Kamer van Koophandel',
                                    1311: 'Kant Engineering',
                                    37281: 'Kanters Bedrijven BV',
                                    7412: 'Kanters BV',
                                    79248: 'Karres en Brands Landschapsarchitecten BV',
                                    1312: 'Kastelijn Fryslân BV',
                                    7873: 'KC Business Solutions',
                                    10940: 'KCN Industrial',
                                    8889: 'Kees de Bruin BV',
                                    42210: 'Kees van Winden ',
                                    41441: 'KEI Advies B.V.',
                                    67541: 'Keij & Stefels',
                                    1313: 'Kempen SSC De',
                                    10479: 'KEN Infra',
                                    10945: 'Keper Infra Advies',
                                    73812: 'Keppel Verolme B.V.',
                                    1314: 'Kernel IT',
                                    49081: 'Kessels Wegenbouw B.V.',
                                    1315: 'Kesting Notariskantoor Mr A C B',
                                    1316: 'Keyzer & De Ruiter',
                                    1317: 'KHC Klaassen Home Connecting BV',
                                    1318: 'Kijkshop-ict',
                                    59168: 'Kim Kogelman Ruimtelijk Advies',
                                    1319: 'Kippersluis & Zn BV',
                                    60845: 'Kjeld B.V.',
                                    76283: 'Klaver Infratechniek',
                                    1321: 'Kleywegen BV',
                                    1322: 'Klijn Infra B.V.',
                                    8726: 'Klink-Nijland B.V.',
                                    73064: 'Kloet Onderhoud B.V.',
                                    55991: 'Kloosterman BV',
                                    1323: 'Klussenbedrijf Bonsai',
                                    1324: 'Klussenbedrijf Takens',
                                    1325: 'Knipscheer BV',
                                    1326: 'Knol Akkrum',
                                    1327: 'Knollmann Ingenieurgesellschaft mbH',
                                    1328: 'Knook Staal en Machinebouw (KSM)',
                                    1329: 'Knoop Baggerwerken',
                                    73578: 'Ko Hartog Elektrotechniek BV.',
                                    43937: 'Ko Hartog Verkeerstechtniek',
                                    1330: 'KOAC•NPC',
                                    1331: 'Koekoek Aannemersbedrijf',
                                    15719: 'Koenders & Partners adviseurs en procesmanagers bv',
                                    1332: 'Koenraad Groenvoorziening (GEEN OHC)',
                                    1333: 'Koerier SVP',
                                    1334: 'Koers Aannemingen BV',
                                    21773: 'Kok-Oosthuizen',
                                    1335: 'Kompan BV',
                                    56119: 'Konijnen Berg Bos en Groen V.O.F',
                                    17434: 'Koning Willem 1 College',
                                    17821: 'Koningsstal InfraCare BV',
                                    1336: 'Koninklijke Ginkel Groep',
                                    27504: 'Koninklijke Sjouke Dijkstra b.v.',
                                    1337: 'Koninklijke Woudenberg Ameide b.v.',
                                    1338: 'Konstruktiebedrijf Hillebrand B.V.',
                                    1339: 'Kooiker Infra BV',
                                    69542: 'Koopman Infra',
                                    30853: 'Koopmans Project Ondersteuning',
                                    1340: 'KPD',
                                    62047: 'KPG Verhuur',
                                    7559: 'Kpieto!',
                                    41805: 'KPM civiel',
                                    1341: 'KPN',
                                    37601: 'Kraaijeveld Investments',
                                    1342: 'Kraaijeveld Projectadvisering b.v.',
                                    1343: "Kraaijeveld's Aannemingsbedrijf b.v.",
                                    1344: 'Kraak Bouwtechnisch Managementburo B.V.',
                                    1345: 'Kraan Bouwcomputing',
                                    59348: 'Kraft Civiele Techniek',
                                    1347: 'Kragten',
                                    52314: 'Kramer Bedrijfsvoering BV',
                                    52768: 'Krämer Groep',
                                    1348: 'Kranen Aannemersbedrijf b.v.',
                                    64516: 'Kreeft Infra B.V.',
                                    1349: "Krijco Casino's and Leisure",
                                    1350: 'Krimpens Adviesbureau',
                                    48603: 'Krimpens Stratenmakers Bedrijf V.O.F.',
                                    62236: 'Krinkels (Alkmaar)',
                                    62818: 'Krinkels B.V. (Zuidwolde)',
                                    13991: 'Krinkels B.V. Heerlen',
                                    1351: 'Krinkels B.V. Wouw (Venlo)',
                                    12495: 'Krinkels B.V. Zoeterwoude',
                                    61519: 'Kroes Aannemingsbedrijf B.V.',
                                    1352: 'Kroeze Aannemersbedrijf grond & wegen B.V.',
                                    8314: 'Kroeze Betonbouw BV',
                                    72578: 'Kroon en de Koning',
                                    1353: 'Kruijer Onderhoud & Verhuur',
                                    1354: 'Krusegroep (GEEN OHC)',
                                    43080: 'KSM Wegenbouw BV',
                                    1355: 'KSP Kunstgras B.V.',
                                    30241: 'Kuijpers Civiel Infra',
                                    1356: 'Kuijzer Installatietechniek',
                                    1357: 'Kuipers Hengelo B.V.',
                                    73061: 'Kuipers Infra',
                                    1358: 'Kuitert',
                                    70615: 'Kuppens Infra',
                                    55464: 'KURSTJENS BV',
                                    43072: 'Kuurman West b.v.',
                                    76039: 'KV Bouw B.V. ',
                                    56873: 'KVM Beheer',
                                    1359: 'Kwakernaak B.V.',
                                    33026: 'Kwant Betonboringen',
                                    1360: 'Kwinfra',
                                    1361: 'KWS Eindhoven',
                                    1368: 'KWS Infra  BV (Zwijndrecht)',
                                    30302: 'KWS Infra B.V. (Amsterdam)',
                                    1362: 'KWS Infra B.V. (Duivendrecht)',
                                    62554: 'KWS Infra BV (Diemen)',
                                    25535: 'KWS Infra bv (Groningen)',
                                    1363: 'KWS Infra BV (Heerhugowaard)',
                                    25537: 'KWS Infra bv (Leek)',
                                    32679: 'KWS Infra bv (Leerdam)',
                                    22938: 'KWS Infra BV (Roosendaal)',
                                    33784: 'KWS Infra BV (Rotterdam)',
                                    1364: 'KWS Infra BV (Utrecht)',
                                    1365: 'KWS Infra bv (Vianen)',
                                    1366: 'KWS Infra Sas van Gent',
                                    1367: 'KWS Infra Zwolle',
                                    129: 'KYBYS',
                                    38954: 'L. van der Meer Recreatie- en Watermanagement',
                                    1369: 'L. van Leeuwen BV',
                                    30070: 'L+ P Groep ',
                                    1370: 'Laarse',
                                    68842: 'Labrujère Staalbouw BV ',
                                    41183: 'Laeven Infra B.V.',
                                    71053: 'Lagerwey Wind BV',
                                    54287: 'Lambert van Venrooij BV',
                                    65262: 'Lammertink Groep \t',
                                    1371: 'Lamsma Veldstra & Lobe, Advocaten en procureurs',
                                    16897: 'Land + Water',
                                    22148: 'LANDER Sector Groen',
                                    1372: 'Landheer Infra B.V.',
                                    1373: 'Landontwerpers',
                                    1374: 'Landscape Studio ',
                                    1375: 'Landschappartners BV',
                                    1376: 'Landschapsbeheer Drenthe',
                                    9116: 'Landschapsbeheer Friesland',
                                    1377: 'Landschapsbeheer Gelderland',
                                    1378: 'Landschapsbeheer Groningen',
                                    35402: 'Landustrie Sneek BV',
                                    69625: 'Langenhuizen',
                                    39266: 'Langenhuizen GWW ondersteuning ',
                                    38931: 'Langstraat Projecten',
                                    1379: 'Lans & Van Duijvenbode',
                                    1380: 'Lans Advies',
                                    7876: 'Lareco Nederland BV ',
                                    65076: 'Laurant Groep',
                                    1382: 'Laurens Schilder GWW Begeleiding',
                                    9631: 'Lawijn Advies & Management (Polsbroek)',
                                    9630: 'Lawijn Advies & Management (Zeist)',
                                    15889: 'LBA bv ',
                                    1383: 'LBITA B.V.',
                                    1384: 'Leenaerts Exploitatie Born BV',
                                    23305: 'Leeuwenborgh Opleidingen',
                                    1597: 'Leisurelands (voorheen RGV Holding)',
                                    63848: 'Lek-Sloopwerken B.V.',
                                    56488: 'Lentiz Life College Schiedam',
                                    36476: 'Leon Bastiaans Grondzuigtechniek',
                                    52292: 'Leon van der Heijden Bestratingen',
                                    1385: 'Leusink Inframanagement',
                                    1386: 'Levade ICT',
                                    1387: 'Liandyn',
                                    1388: 'Liemers infragroep',
                                    1389: 'Lijesen',
                                    31134: 'Lilian van Oosterhoudt',
                                    1390: 'LIMASS Outdoor Lighting',
                                    7527: 'Limes Landschapsadvies',
                                    69866: 'Limitec BV',
                                    37490: 'Limus',
                                    1391: 'Lindeloof BV',
                                    1392: 'Lindeman',
                                    1393: 'Linea Infra',
                                    16471: 'Lizura B.V.',
                                    1394: 'LML projectvoorbereiding',
                                    1395: 'Lodders',
                                    1396: 'Loggia BV, Interim & Supporting Management',
                                    1397: 'Logitech BV',
                                    68550: 'LOLA Landscape Architects',
                                    1398: 'Lolkema Aannemingsbedrijf BV',
                                    31139: 'Loo Plan',
                                    1399: 'LooHorst Landscaping B.V.',
                                    1400: 'Loohuizen',
                                    1401: 'Loon- en grondverzetbedrijf Altena vof',
                                    38930: 'Loon- en grondverzetbedrijf Middelbrink B.V.',
                                    41416: 'Loon- en grondverzetbedrijf R. de Regt',
                                    58486: 'Loon- en grondverzetbedrijf Smits B.V.',
                                    72506: 'Loon- en kraan verhuurbedrijf M. de Bruijn',
                                    25595: 'Loon- en Maaibedrijf de Struunhoeve BV',
                                    73879: 'Loon- en verhuurbedrijf Aantjes BV\t',
                                    73101: 'Loon- en Verhuurbedrijf Gebr. De Kreij',
                                    31724: 'Loon, Maai en Grondwerken J. Bouw BV',
                                    1402: 'Loonbedrijf A.H.J. den Boer',
                                    70855: 'Loonbedrijf Aart den Hollander B.V.',
                                    1958: 'Loonbedrijf Firma Zwaan',
                                    43177: 'Loonbedrijf Gebroeders Borg B.V.',
                                    23330: 'Loonbedrijf Gebroeders Plat',
                                    1165: 'Loonbedrijf Hokke en Zn.',
                                    44359: 'Loonbedrijf Jan van der Lee BV',
                                    1403: 'Loonbedrijf Klein Texas B.V.',
                                    1404: 'Loonbedrijf L.A. Hiemstra v.o.f.',
                                    61859: 'Loonbedrijf Lucas Stuut & Zn. V.O.F.',
                                    1405: 'Loonbedrijf Oudwoude',
                                    17801: 'Loonbedrijf Pelle',
                                    1406: 'Loonbedrijf Roodenburg BV',
                                    1407: 'Loonbedrijf Schellekens B.V.',
                                    40641: 'Loonbedrijf Theo de Wit',
                                    65951: 'Loonbedrijf van Etten b.v.',
                                    1408: 'Loonbedrijf W.P. De Jong',
                                    17591: 'Louter B.V. ',
                                    34958: 'LSNed Leidingenstraat Nederland ',
                                    1409: 'LTA Techniek en Advies',
                                    52299: 'Lumee B.V.',
                                    18753: 'Lumidee BV',
                                    51018: 'Lurfs Engineering B.V.',
                                    1411: 'Luxaro',
                                    60806: 'LvB Civiele Projectbegeleiding',
                                    1412: 'LVO Uitvoeringsvoorbereiding',
                                    25579: 'Lybrae Consultants',
                                    1413: 'M. de Mooij',
                                    64229: 'M. v.d. Spek Hoveniersbedrijf B.V.',
                                    1414: 'M.C.A. Calculatie- en Adviesburo',
                                    1415: 'M.J. Oomen Groep',
                                    31717: 'M.J. van Aalst',
                                    1417: 'Maas & Nienhuis B.V.',
                                    14922: 'MABE Infra Advies',
                                    85537: 'Mac Donalds',
                                    51267: 'Macadam BV',
                                    22601: 'Macces BV Grondverzet',
                                    1418: 'MACG',
                                    33824: 'Machinefabriek Emmen B.V.',
                                    1419: 'Machinefabriek Rusthoven',
                                    1420: 'Maessen Bedrijven bv',
                                    21759: 'MaGebro Hoveniers',
                                    1421: 'Make bv',
                                    1422: 'Maliepaard H.M.',
                                    59439: 'Malsen Sport en Civiel',
                                    75132: 'Mampaey Dordrecht Beheer B.V.\t',
                                    1423: 'Man Consultants',
                                    43800: 'ManEngenius',
                                    1424: 'Manschot Timmerwerken',
                                    1425: 'Marc Goudswaard',
                                    1426: 'Marcelis Wolak Landschapsarchitectuur (GEEN OHC)',
                                    16420: 'Marco Koenen GWW Advies',
                                    66659: 'Marconi Oranje BV',
                                    1428: 'Marijn De Sierbestrating',
                                    57796: 'Marijnissen schilderwerken',
                                    1430: 'Marius Bakker b.v.',
                                    70180: 'Mark Moes Hoveniers',
                                    17449: 'Markiezaat College',
                                    1431: 'Marmol Internacional',
                                    1432: 'Martens Bestratingen',
                                    1433: 'Martens en Van Oord Groep BV',
                                    21496: 'Martijn & Paul',
                                    1434: 'Martina Project Management',
                                    29995: 'Materiaaldienst (DIVV)',
                                    52999: 'Max Boegl',
                                    1435: 'MCB Moerdijk BV',
                                    1436: 'MCR Bouw',
                                    10649: 'Md2W project-verander- en interimmanagement',
                                    15894: 'MeBe Advisering B.V. ',
                                    122: 'Meer Infra B.V.',
                                    75139: 'Meerbouw Rotterdam B.V.',
                                    50164: 'Meester in Techniek',
                                    1437: 'Meeuwisse Nederland BV',
                                    1438: 'Megaborn Traffic Development BV',
                                    1439: 'MegaHome.nl',
                                    1440: 'Megron BV',
                                    1441: 'Meijer Infra Advies',
                                    50466: 'Meijerink Wegenbouw B.V.',
                                    43535: 'Meles Projecten',
                                    1442: 'Mens VerkeersTechniek',
                                    70217: 'Mercom',
                                    13953: 'Metameric Civiele Techniek',
                                    1443: 'Metiri ( GEEN OHC)',
                                    1444: 'Metrum (GEEN OHC)',
                                    59434: 'Meulendijks Ronthout BV.',
                                    66660: 'MGciviel',
                                    9132: 'MGX Advies',
                                    73104: 'MH Hydraulics B.V.',
                                    18030: 'MH Poly Environmental & Infrastructure Zwijndrecht',
                                    26704: 'Mica IT B.V.',
                                    1445: 'Michel Doorn Riool Beheer BV',
                                    1446: 'Middelkoop Advies',
                                    1447: 'Miedema & Zijlstra GWW Service',
                                    1448: 'Mihlteck infra & onderhoud',
                                    1449: 'Mijnsen Dynamische Mobiliteit',
                                    29886: 'Mile17',
                                    31050: 'Milieu Adviesbureau Adverbo',
                                    8063: 'Ministerie van Economische Zaken (Agentschap NL)',
                                    1450: 'MISCO',
                                    64381: 'MIT Installatietechniek B.V. \t',
                                    24540: 'Miwacom ',
                                    32918: 'MOA-BS',
                                    1452: 'Mobilis (v/h Haverkort Voormolen Apeldoorn)',
                                    1707: 'Mobilis B.V.',
                                    22588: 'Möhringer liften b.v.',
                                    1453: 'Molema Advies',
                                    1454: 'Molijn Sales Support',
                                    18484: 'Mondavi Projectburo ',
                                    7753: 'Mondo Luxembourg S.A.',
                                    22980: 'Monsdal Limburg Zuid B.V.',
                                    1455: 'Montad Elektrotechnisch Adviesbureau BV',
                                    1456: 'MOON Infra BV',
                                    1457: 'Morssinkhof Groep Hengelo B.V.',
                                    15925: 'Mostert de Winter BV',
                                    66237: 'Mott MacDonald B.V.',
                                    1458: 'Mourik Groot-Ammers B.V.',
                                    1459: 'Mous Pompenbouw BV',
                                    64382: 'Mouton Bouw B.V. ',
                                    1460: 'Mouwen Stratenmakersbedrijf',
                                    70285: 'Mouwrik Waardenburg',
                                    1461: 'Movares',
                                    1462: 'MT&V Werving & Selectie Amsterdam',
                                    20090: 'MTB Regio Maastricht NV',
                                    1463: 'MTD  Landschapsarchitecten',
                                    7319: 'MUG Ingenieursbureau',
                                    27071: 'Muishuis',
                                    19687: 'Mulder Loonbedrijf v.o.f.',
                                    30044: 'Multi-Light',
                                    53494: 'Multicopy',
                                    73876: 'Multituin B.V.',
                                    64362: 'Murmex B.V.',
                                    73360: 'Murre Green 4 You',
                                    1464: 'MWH B.V.',
                                    1465: 'N. Kraaijeveld B.V.',
                                    46504: 'N. Kruiswijk Loon- & Verhuurbedrijf',
                                    28418: 'N. van Es Management & Advies',
                                    13616: 'N.C. Zwart Verhuur B.V.',
                                    74056: 'NA Infra B.V.',
                                    1466: 'NACAP B.V. (GEEN OHC)',
                                    29008: 'NACO BV',
                                    33228: 'Nagtegaal Ameland',
                                    1467: 'Nationale Bomenbank BV',
                                    49209: 'Nb2n B.V.',
                                    1468: 'NCOD',
                                    69470: 'NCOI Opleidingsgroep',
                                    1469: 'Nebest B.V.',
                                    50233: 'Nederlands Licht Instituut',
                                    31010: 'Nederlandse Aardolie Mij',
                                    69761: 'Nederlandse Ambassade in Brussel',
                                    69763: 'Nederlandse Ambassade in Kopenhagen',
                                    1470: 'Nederweardt, Joost',
                                    1471: 'NEDEXIMPO Bouwtechniek',
                                    1472: 'NedGraphics B.V.',
                                    62009: 'NedMobiel',
                                    14181: 'Nelis Infra BV',
                                    24483: 'Neo Arc B.V.',
                                    40145: 'Neovl',
                                    37422: 'Nepocon Adviesbureau B.V.',
                                    1473: 'Nering Bogel',
                                    1474: 'Netten Bestratingen',
                                    1475: 'Ney & Partners',
                                    1487: 'NHL Hogeschool Leeuwarden',
                                    1476: 'Nico Koolmoes BV',
                                    15561: 'Niebeek Milieumanagement bv ',
                                    1478: 'Niek Roozen BV',
                                    69501: 'Niek van Deuzen Groenverzorging',
                                    1479: 'Nienhuis Rietmolen',
                                    1480: 'Niersman Project Consult (GEEN OHC)',
                                    1481: 'Nieuwland Advies',
                                    8307: 'Nijssen Management en Advies',
                                    1483: 'Nimas B.V.',
                                    1484: 'Nissen Tuindesign & Construct',
                                    37467: 'Nobra Technisch Adviesbureau B.V.',
                                    1485: 'Nomagro B.V.',
                                    1486: 'Nooijen Terreininrichting BV',
                                    22684: 'Noorderdiep Advies B.V.',
                                    1488: 'Noordhoek Diving Company B.V.',
                                    1489: 'Noordlease',
                                    10582: 'NoordWestGroep N.V.',
                                    1490: 'Norbert Grondwerk en Bestratingen',
                                    1491: 'Norbert M.  Bestratingen',
                                    55243: 'Nota Straatmakers BV',
                                    26089: 'Nova College',
                                    37388: 'NPB Beheer BV',
                                    30663: 'NPC',
                                    9502: 'NTP Enschede',
                                    8485: 'NTP Hattem',
                                    7612: 'NTP Zevenaar',
                                    74190: 'Nuon Rotterdam',
                                    1593: 'NV Rendo Infra',
                                    9128: 'NXTLANDSCAPES',
                                    1492: 'OBB Ingenieursbureau',
                                    1493: 'OBN',
                                    64371: 'Octatube Holding B.V.',
                                    1494: 'Oenema B.V.',
                                    1495: 'Officier GWW B.V.',
                                    30856: 'Oko Care ',
                                    77363: 'Olde Hanter Bouwconstructies BV',
                                    1496: 'Oldenkamp B.V.',
                                    16044: 'Oldenkamp BV',
                                    64394: 'Olsthoorn B.V. \t',
                                    1868: 'OmniformEngineering BV',
                                    14607: 'OmniVerde B.V.',
                                    46682: 'Onderwijscoöperatie',
                                    1497: 'One IP BV',
                                    18493: 'Ontwikkelingsbedrijf Gemeente Amsterdam',
                                    38797: 'Ontwikkelingsbedrijf Spoorzone Delft bv',
                                    43856: 'Ooms Construction bv',
                                    1498: 'Oosterhof Holman',
                                    33166: 'Oosterhuis B.V.',
                                    1508: 'Oosthoek-Groep B.V.',
                                    1499: 'OPG landscape bv',
                                    1500: 'Optima-C',
                                    1501: 'OR else Public Space Solutions',
                                    66862: 'Oranje B.V.',
                                    15392: 'Orbis Engineering',
                                    80527: 'org1234testbla6789',
                                    89442: 'Organisatie 2',
                                    90225: 'organisatie met maar 1 contact',
                                    1503: 'Oskam',
                                    1504: 'Osta B.V.',
                                    16901: 'OTAR',
                                    36360: 'Otten Infra BV',
                                    77085: 'Outdoor Furniture Services',
                                    18067: 'Outline Consultancy BV ',
                                    76355: 'Output Infra Advies',
                                    66843: 'Outside View B.V.',
                                    64367: 'Ouwehand Bouw Katwijk B.V.',
                                    66429: 'Ouwejan & F. de Bruijn Infra B.V.',
                                    1021: 'OVER-gemeenten',
                                    1506: 'Overlease',
                                    70278: 'P. Campschreur Beheer B.V.',
                                    17337: 'P. Hussaarts BV',
                                    1507: 'P.B. Asfalt & Bestratingen',
                                    1509: 'P.B. Pijnacker B.V.',
                                    1510: 'P.H.B. Bestratingen',
                                    38898: 'P.M. Bakker Bestratingen ',
                                    19730: 'P.Wagenmakers Dienstverlening',
                                    68175: 'PAB-infra bv',
                                    1511: 'Pannekoek GWW B.V.',
                                    71198: 'Parcum B.V.',
                                    13204: 'Particulier Natuurbeheer Nederland BV',
                                    30477: 'Partitio Ontwerphuis',
                                    1513: 'Pater Consultants',
                                    38291: 'Patrick Verhoeven B.V.',
                                    1514: 'Patrick Voeten Bestratingen',
                                    28984: 'Paul Bouman',
                                    46191: 'Paul Melis Boomverzorging',
                                    30554: 'PBO Adviesbureau',
                                    1516: 'PBR advies en managementbureau',
                                    1517: 'PC van der Wiel',
                                    1518: 'Peek B.V. Bouw & Infra',
                                    1519: 'Peek Traffic B.V.',
                                    15984: 'Peer Groep BV',
                                    18125: 'Peter Sebregts Groenmanagement \t',
                                    10565: 'Peter van Daal VOF',
                                    13108: 'Peter van Welsem ',
                                    60102: 'Peterse Tuintechniek',
                                    7295: 'Petersen Civiele Techniek',
                                    1520: 'Petersen Onderhoudsbedrijf M.',
                                    25263: "Petro's Consultans",
                                    1521: 'Pieter Roefs Graszoden',
                                    73177: 'Pietersen Elektriciteit B.V.',
                                    66094: 'Piguillet & Zonen B.V. ',
                                    1522: 'Pins / Icmc',
                                    73180: 'Pinta Nieuwberg-Unisol',
                                    18317: 'PK Waterbouw VOF',
                                    8058: 'PKM Solutions B.V.',
                                    1523: 'Plaat Reklame BV',
                                    1524: 'Planbase',
                                    1525: 'Planburo Infra Groen BV',
                                    32357: 'Planburo Oosterink',
                                    18338: 'Plancompagnons',
                                    18988: 'Plangroep Heggen B.V. ',
                                    29590: 'Plankracht',
                                    1526: 'PlanningIT B.V.',
                                    1527: 'Plat Grond Weg en Waterbouw b.v. (Failliet)',
                                    49258: 'Plegt Vos Oost BV',
                                    1528: 'Plegt-Vos Infra en Milieu B.V.',
                                    75601: 'Plein B',
                                    60453: 'PleinPlan B.V.',
                                    9364: 'Ploegam bv Aannemingsmaatschappij in de grond-, weg- en waterbouw',
                                    1529: 'Ploegmakers Cultuurtechniek BV',
                                    1530: 'Plum Infra BV',
                                    1532: 'Pluswaarde Management',
                                    66098: 'Point2Point Consultancy b.v.',
                                    50043: 'Poland GWW B.V.',
                                    66070: 'Polygon Nederland B.V. \t',
                                    76098: 'Pompen Service Limburg',
                                    7641: 'Portiva',
                                    1534: 'Postma GWW BV',
                                    1535: 'PragMania BV',
                                    23895: 'Presikhaaf Groenvoorziening',
                                    1536: 'Primaplan',
                                    1537: 'Prins Dokkum B.V.',
                                    1538: 'Prinsentuin College',
                                    1539: 'Printing People',
                                    22516: 'Pro Infra Ingenieursbureau',
                                    11230: 'Pro N329 (projectbureau opdrachtnemer)',
                                    1540: 'Pro4All',
                                    1541: 'ProCensus BV (FAILLIET)',
                                    1542: 'Procision Instrument BV',
                                    72265: 'ProCiviTech',
                                    50054: 'Prodis Boekingcentrale',
                                    15842: 'Project Profession Engineering Support B.V.',
                                    1543: 'Projectbureau De Zuidlanden',
                                    37325: 'Projectbureau Delfi',
                                    1544: 'Projectbureau van Damme',
                                    12528: 'Projectburo B.V.',
                                    1545: 'ProjectColor',
                                    1546: 'Projecten Management Intermediair',
                                    37380: 'Projektburo Impact',
                                    1547: 'Promae engineering',
                                    1548: 'Promax',
                                    28849: 'Promeco BV',
                                    1549: 'Prommenz',
                                    1550: 'Prorail',
                                    1551: 'Prorail/Railinfrabeheer regio midden',
                                    1552: 'Prorail/Railinfrabeheer regio Randstad Zuid',
                                    1553: 'Prorail/Railinfrabeheer regio zuid',
                                    16062: 'Prostijl Civiel Advies BV ',
                                    1554: 'Proviel BV',
                                    1555: 'Provincie Drenthe',
                                    7284: 'Provincie Flevoland',
                                    1556: 'Provincie Fryslân',
                                    1557: 'Provincie Gelderland',
                                    1558: 'Provincie Groningen',
                                    1559: 'Provincie Limburg',
                                    1560: 'Provincie Noord-Brabant',
                                    1561: 'Provincie Noord-Brabant Wegbeheer- en onderhoud Regio West',
                                    1562: 'Provincie Noord-Holland',
                                    1563: 'Provincie Overijssel',
                                    1564: 'Provincie Utrecht',
                                    1565: 'Provincie Zeeland',
                                    12288: 'Provincie Zuid-Holland',
                                    1566: 'ProZicht',
                                    7540: 'PTR Projectengineering',
                                    1567: 'Punter Grondwerken BV',
                                    54000: 'Putman Groep  ',
                                    1568: 'Puts Groenbeheer en Loonwerk',
                                    29469: 'PWN Waterleidingbedrijf NH',
                                    29515: 'Q Civiele Projecten',
                                    1569: 'QA4U (GEEN OHC)',
                                    73183: 'QBuild Reco Herstel',
                                    1570: 'QFS',
                                    23576: 'Qua Wonen',
                                    7307: 'Quadrant Ingenieurs',
                                    1571: 'Qualitivity Engineering B.V.',
                                    1572: 'Quint & v. Ginkel',
                                    1573: 'Qurius',
                                    47511: 'Quyntess BV',
                                    1574: 'R & B Infrastructuren',
                                    1575: 'R. van Hien Project Management B.V.',
                                    1577: 'R.A. van der Clingel Bestratingen BV',
                                    27544: 'R.P.S. Rob Penson Systems',
                                    1578: 'R.V.W. Buitendijk',
                                    25886: 'R&B Civiel- en Cultuurtechniek B.V.',
                                    35053: 'R&L Consultants B.V.',
                                    8591: 'RA Infra B.V.',
                                    50463: 'Raacc adviesbureau',
                                    39939: 'Raadgevend Bureau Speelman',
                                    29163: 'Raadgevend Ingenieursbureau Lievense',
                                    15573: 'Raadgevend Ingenieursburo F. KOCH BV',
                                    1579: 'Raadgevers De',
                                    1580: 'Raakwerk',
                                    1581: 'RABC',
                                    17443: 'Radius College',
                                    16093: 'RAE advies en engineering',
                                    46590: 'RailCom BV',
                                    8066: 'Railinfra Solutions',
                                    72048: 'Raoudi Bestratingen',
                                    38777: 'Rasenberg bouw B.V.',
                                    1582: 'Rasenberg Kabels & Leidingen B.V.',
                                    1583: 'Rasenberg Milieutechniek B.V.',
                                    31716: 'Rasenberg Wagenberg BV',
                                    1584: 'Rasenberg Wegenbouw B.V.',
                                    1585: 'Ravenhorst Renswoude',
                                    25968: 'Rayleco B.V.',
                                    1586: 'RBsupport',
                                    1587: 'Recreatie Noord Holland',
                                    55815: 'Redubel',
                                    1588: 'Reed Business Information',
                                    15880: 'Reef Infra BV',
                                    23303: 'Regio College',
                                    36816: 'Regionale ICT-Dienst Utrecht',
                                    1589: 'Regus',
                                    1590: 'Reijmer Pannerden B.V.',
                                    23689: 'Reimert Bouw en Infrastructuur',
                                    51996: 'Reinigingsbedrijf Midden Nederland',
                                    1591: 'Reinigingspolitie',
                                    1592: 'Reinunie',
                                    43423: 'Reko Grondverzet- en Wegenbouwbedrijf Raalte B.V.',
                                    16866: 'RekreaVakkrant',
                                    36119: 'Remko Barmentloo ',
                                    47886: 'Remote IT Services',
                                    1594: 'Rene de Bie',
                                    53385: 'Repcon B.V.',
                                    1595: 'ReplaceDirect',
                                    23008: 'Restaurant Paerz',
                                    6810: 'RET',
                                    37483: 'Reurink Infratech',
                                    1596: 'Reyrink Diessen',
                                    1598: 'RIBW Projecten B.V.',
                                    1599: 'Richard Rockx',
                                    24948: 'RID De Liemers',
                                    9135: 'Riede, Mol en Donkers',
                                    1600: 'Rijksgebouwendienst (VROM)',
                                    37328: 'Rijksuniversiteit Groningen',
                                    1601: 'Rijkswaterstaat',
                                    1602: 'Rijn IJssel Opleidingen',
                                    47367: 'Rini Scheer Loon & Graafmachineverhuurbedrijf v.o.f.',
                                    1604: 'Rions Riooltechniek',
                                    52761: 'Riool EnZo B.V.',
                                    67043: 'Riool Reinigings Service (RRS) - Duiven',
                                    1606: 'RM Groep Didam BV',
                                    30296: 'ROA Twente',
                                    32121: 'Roadinfra/Reijn Beheer BV',
                                    1607: 'ROB Buitenruimte',
                                    1608: 'ROB Solutions BV',
                                    1609: 'ROC Aventus Apeldoorn',
                                    17430: 'ROC Eindhoven',
                                    16990: 'ROC Flevoland',
                                    553: 'ROC Friese Poort',
                                    60100: 'ROC Leiden',
                                    17509: 'ROC Midden Nederland',
                                    23301: 'ROC Mondriaan',
                                    71729: 'ROC Nijmegen',
                                    16694: 'ROC Noorderpoort',
                                    17414: 'ROC Ter AA',
                                    17427: 'ROC Tilburg',
                                    7252: 'ROC Twente',
                                    16976: 'ROC van Amsterdam',
                                    1611: 'Roc Zeeland (Scalda)',
                                    30404: "Rod'or Technisch Adviesbureau",
                                    43774: 'Roefs Advies- en Ingenieursbureau',
                                    1612: 'Roelands Onderhoudsbedrijf',
                                    1613: 'Roelofs',
                                    6834: 'RoelofsGroen',
                                    917: 'Roermond Gemeente',
                                    1615: 'Roijakkers Bouw & Infra',
                                    1616: 'Rokx Bestrating',
                                    1617: 'Ronald Jacobs Groenadvies',
                                    1427: 'Rond Ruimte ',
                                    1618: 'Rooden Landscape Solutions BV',
                                    18120: 'Rooij Waterbodem Advies',
                                    1619: 'Rooise Wegenbouw BV',
                                    1620: 'Rook BV',
                                    1621: 'Roos-Licht BV',
                                    29554: 'Roos+Bijl B.V.',
                                    404: 'Roscalc',
                                    1622: 'Rosmalen Advies B.V.',
                                    1623: 'Rotterdam Engineering',
                                    66838: 'Rotterdams Steenzetbedrijf B.V.',
                                    1625: 'Rovecom',
                                    20130: 'Royal Haskoning (Goes)',
                                    30378: 'Royal Haskoning || Amsterdam',
                                    17309: 'Royal HaskoningDHV (Rotterdam)',
                                    456: 'Royal HaskoningDHV | Deventer',
                                    30668: 'Royal HaskoningDHV | Eindhoven',
                                    455: 'Royal HaskoningDHV | Groningen',
                                    30978: 'Royal HaskoningDHV | Zaandam',
                                    457: 'RoyalHaskoningDHV (Amersfoort)',
                                    1626: 'Rozenburg (deelgemeente Rotterdam)',
                                    65457: 'RPS advies- en ingenieursbureau bv (Breda)',
                                    14931: 'RPS advies- en ingenieursbureau bv (Delft)',
                                    1627: 'RPS advies- en ingenieursbureau bv (Leerdam)',
                                    1628: 'Rudy Jacobs Bestratingen',
                                    56744: 'Ruud Burggraaff Landschapsverzorging',
                                    1629: 'RVA GROEP',
                                    11489: 'RvB Infra Harderwijk B.V.',
                                    1631: 'RVR Hoofddorp B.V.',
                                    9553: 'RVS Finance',
                                    1632: 'RW Advies BV',
                                    1633: 'RWD Vastgoed B.V.',
                                    1634: 'Ryan Tuinen',
                                    1636: 'S.M. Visser Calculatie & Kostenadvies GWW',
                                    35453: 'S&Z Consultancy',
                                    51000: 'SAAone',
                                    71585: 'Sagro Aannemingsmaatschappij Zeeland B.V.',
                                    30875: 'Sagro Milieu Advies Zeeland',
                                    39023: 'Sales Improvement Group',
                                    1637: 'Sallandse Wegenbouw B.V.',
                                    38269: 'Salpa ICDC',
                                    67486: 'Salverda B.V.',
                                    37309: 'SAME Advise',
                                    1638: 'Sanders Civil Projects',
                                    7507: 'Saxion Enschede',
                                    1639: 'SBNL, organisatie voor particulier en agrarisch natuurbeheerinderd',
                                    1640: 'SBR (Stichting bouwresearch)',
                                    29908: 'Schaal Ingenieurs',
                                    30223: 'Schagen infra BV',
                                    37829: 'Schapers BV',
                                    1641: 'Scharenborg Loonbedrijf en Grondverzet',
                                    1642: 'Scheerhoorn G.J.A.  Bestratingen',
                                    1643: 'Schel Infra',
                                    1644: 'Schelvis Infra B.V.',
                                    1646: 'Schering-Plough',
                                    73187: 'Schildersbedrijf Midden Nederland B.V.',
                                    1647: 'Scholtens Groep',
                                    1648: 'Schonewille Infra BV',
                                    15405: 'Schoonveld Civiele Techniek',
                                    16394: 'Schotgroep Aannemingsbedrijf B.V.',
                                    73255: 'Schotte',
                                    58713: 'Schreuder BV',
                                    1649: 'Schutte ICT Infra',
                                    10403: 'Schutte Informatisering',
                                    61059: 'Schuurmans Betonbouw B.V.',
                                    22905: 'Sdu Uitgevers',
                                    7120: 'Seconet',
                                    718: 'SED organisatie -  lokatie Enkhuizen',
                                    703: 'SED organisatie - locatie Drechterland',
                                    949: 'SED organisatie - locatie Stede Broec',
                                    1650: 'Senden, Grondverzet & Hoveniersbedrijf',
                                    70211: 'Sensu',
                                    1651: 'SenterNovem',
                                    24069: 'Servicepunt71',
                                    1249: 'SGS INTRON BV',
                                    43773: 'SH+ E group Nederland',
                                    1652: 'Siers Groep Oldenzaal BV',
                                    1257: 'SIGHT Landscaping B.V.',
                                    30542: 'SIGHT Landscaping B.V. | Nieuwerkerk a/d IJssel',
                                    66453: 'Signa Terra B.V.',
                                    22297: 'Silenus Projectleiding',
                                    31713: 'Silt BV',
                                    1653: 'Sinkegroep',
                                    69629: 'Sint-Nieklaas Civiele Techniek BV',
                                    29951: 'Sintec',
                                    61238: 'SITA Remediation BE',
                                    61242: 'SITA Remediation NL',
                                    20084: 'SITA Riool Services ',
                                    1654: 'Sjoerd Tjepkema Grondverzet & Machineverhuur',
                                    1655: 'SLAG',
                                    1656: 'Sleutelspecialist',
                                    1658: 'Sloopbedrijf Teteringen',
                                    1659: 'Sloos & Zoon BV',
                                    69952: 'SMA milieu en Ruimte',
                                    34294: 'Smart Dutch',
                                    1660: 'Smeulders',
                                    1661: 'Smit Groenadvies',
                                    28955: 'Smit-Infra',
                                    73265: 'Smits Neuchâtel Infrastructuur',
                                    73262: 'Smits Vastgoedzorg B.V.',
                                    1662: 'SmitsRinsma',
                                    1663: 'Snijder bestratingen',
                                    1664: 'Snoek Hoveniers',
                                    52800: 'Snoobi B.V.',
                                    49904: 'Societeit InfraNoord',
                                    1665: 'Softmakers BV',
                                    25569: 'Solid Services BV',
                                    1666: 'SOMA-College',
                                    1667: 'Soms Software Tools',
                                    1668: 'Sonny van Zundert Bestratingen',
                                    1669: 'Sophia Engineering BV',
                                    42202: 'Sophimil Civiel B.V.',
                                    8080: 'SPA Groep',
                                    63606: 'SPA Groep',
                                    1670: 'Spandauw Sloop en Recycling BV',
                                    1671: 'Spectrum Advies & Design B.V.',
                                    70310: 'Spees CCP',
                                    1672: 'Spie Belgium',
                                    1673: 'Spie Controlec Engineering BV',
                                    42047: 'SPIE Nederland',
                                    1674: 'Spiek & De Vries',
                                    29567: 'Spijkerkalk',
                                    6967: 'Spitzke Spoorbouw BV',
                                    1675: 'Sponselee',
                                    73473: 'SpoorTotaal BV',
                                    76522: 'SportveldenAdvies.nl',
                                    23319: 'Sprenkels bestratingen bv ',
                                    15965: 'Staal Engineering B.V.',
                                    1677: 'Staatsbosbeheer centrale organisatie te Driebergen',
                                    1678: 'Staatsbosbeheer Directie Noord',
                                    1679: 'Staatsbosbeheer Directie Oost',
                                    1680: 'Staatsbosbeheer Directie Oost (oud?)',
                                    1681: 'Staatsbosbeheer Directie West',
                                    49777: 'Staatsbosbeheer Horsterwold ',
                                    1682: 'Stabu',
                                    16059: 'Stad & Groen',
                                    40582: 'Stad & Groen Roosendaal',
                                    34031: 'Stad Sint-Niklaas Dienst Groen',
                                    1683: 'StadLandWater',
                                    33544: 'Stadsgewest Haaglanden',
                                    1684: 'Staro Natuur en Buitengebied',
                                    29200: 'Stedelijk Groen BV',
                                    1685: 'Stedin B.V.',
                                    22522: 'Steenadvies',
                                    25115: 'Steenhuis Sloopwerken BV',
                                    54144: 'Stegehuis Infra',
                                    1688: 'Steggink Infra',
                                    16606: 'Stevens Civiele Techniek',
                                    1689: 'Stichting Adelante',
                                    1690: 'Stichting ATN-Groep',
                                    32527: 'Stichting Bodemsanering NS',
                                    1925: 'Stichting Inkoopbureau West-Brabant',
                                    1691: 'Stienstra en van der Wal',
                                    1692: 'Stijlgroep',
                                    25742: 'Stiveco B.V. ',
                                    43138: 'Stoas Wageningen|Vilentum Hogeschool',
                                    1693: 'Stoop Groenvoorziening',
                                    287: 'Straatmakersbedrijf Broeren B.V.',
                                    66874: 'Straatmakersbedrijf de Jong B.V.',
                                    1793: 'Straatmakersbedrijf Van Egmond B.V.',
                                    42207: 'Straatwerk.com',
                                    14629: 'Strada Engineering',
                                    78321: 'Stratenmakersbedrijf Gebr. Vos B.V.',
                                    39236: 'Structurae',
                                    56098: 'Struijk Sloop- en Grondwerken b.v.',
                                    9574: 'Strukton Bouw',
                                    16430: 'Strukton Civiel',
                                    8566: 'Strukton Civiele Projecten',
                                    1694: 'Strukton Infratechnieken B.V.',
                                    1695: 'Strukton Railinfra',
                                    11006: 'Strukton Systems BV',
                                    1696: 'Studenten',
                                    37620: 'Studio Maaiveld',
                                    19993: 'Stuiver Civiele Techniek ',
                                    1697: 'Sturm & Van Vuuren',
                                    61005: 'Sturm B.V.',
                                    1698: 'Sturris BV',
                                    77012: 'Sunflower IT',
                                    1699: 'SWA',
                                    1700: 'SWB Midden Twente',
                                    39092: 'Swietelsky Rail Benelux B.V.',
                                    1701: 'Sylow',
                                    8710: 'Syntax Infra Services',
                                    25805: 'Syntera',
                                    44880: 'Syntrophos',
                                    7798: 'Syntus B.V.',
                                    34863: 'Syntus Gelderland',
                                    1704: 'T. van de Pol',
                                    1702: 'T&G Terrein-en Groenvoorziening b.v.',
                                    16637: 'Taken adviseurs en ingenieurs',
                                    64434: 'Tanktechniek B.V. \t',
                                    59052: 'Tao Elektrotechniek B.V.',
                                    36507: 'Tauw (Amsterdam)',
                                    10440: 'Tauw (Assen)',
                                    7661: 'Tauw (Deventer)',
                                    1705: 'Tauw (Utrecht)',
                                    18392: 'TB Infra',
                                    1706: 'TBH B.V.',
                                    1708: 'TBK',
                                    1709: 'TBK Duisburg',
                                    39011: 'tcbouw',
                                    18724: 'TCN Infra',
                                    1710: 'Tcomb',
                                    1711: 'TD Civiel',
                                    9145: 'Tebodin Netherlands B.V. ',
                                    1712: 'Technisch Adviesbureau Oskam B.V.',
                                    32498: 'Technisch Adviesbureau Sliedrecht ',
                                    74261: 'Technisch Bureau Zuidema',
                                    65111: 'Technische Handelsonderneming Jonker',
                                    31014: 'TechnoConsult',
                                    1713: 'Techworks - techniek en interieur (GEEN OHC)',
                                    1714: 'Teela bv',
                                    55751: 'Tegelzetbedrijf Grooteman',
                                    1715: 'Telescore',
                                    70877: 'Temmink Bouwprojekten B.V.',
                                    1716: 'Temmink Groep B.V. (FAILLIET)',
                                    1717: 'Tempels Projectmanagement & Advies',
                                    1718: 'TenneT',
                                    22418: 'Ter Horst Sanering en Infra B.V.',
                                    24481: 'Ter Riele B.V.',
                                    1719: 'Terrascan BV',
                                    1720: 'TerraSpect',
                                    1721: 'Terrastab Nederland B.V. (GEEN OHC)',
                                    88657: 'test',
                                    97989: 'Test A',
                                    128631: 'test org 1234',
                                    80526: 'test1234org',
                                    84133: 'testorganisatie_123',
                                    1723: 'Teunisse en Schoones',
                                    1724: 'Th Verbree BV',
                                    10275: 'THC Automatisering BV',
                                    1512: 'The Missing Link',
                                    1725: 'The Three Engineers bv',
                                    55157: 'The Wiendels Group ',
                                    54995: 'Theo Klever BV',
                                    74092: 'Theo Pouw',
                                    65776: 'Thermon Benelux B.V.',
                                    25833: 'Think Project',
                                    64807: 'Thunnissen Onderhoud Cruquius',
                                    1726: 'Thuur Grond-, Straat- en Rioolwerken',
                                    26394: 'Tiemen Gerris',
                                    1727: 'Tigchelaar Oosterhout',
                                    1728: 'Tijhuis Ingenieurs BV',
                                    73026: 'Tilgrup GWW BV',
                                    70709: 'Tim van Oosterbos',
                                    7627: 'Timmer Lelystad BV',
                                    32505: 'Timmer-Verzijl BV',
                                    72016: 'Timmerhuis Groep',
                                    1576: 'Timmerman Groenvoorzieningen',
                                    1729: 'Timmerman Houtproducten',
                                    29262: 'TLU Landschapsarchitecten',
                                    54678: 'TMC Civiele Techniek',
                                    1730: 'TNO Delft',
                                    1731: 'TNT',
                                    1732: 'Tolsma Loonbedrijf VOF',
                                    65501: 'Tomingroep BV ',
                                    1733: 'Top-Craft BV',
                                    42249: 'TopGrass B.V.',
                                    37434: 'Topotech Advies',
                                    47656: 'Torenbosch Project- en Contractmanagement',
                                    1734: 'Tracé Capelle a/d IJssel',
                                    1735: 'Tracé Utrecht',
                                    1736: 'Trackline BV',
                                    15934: 'Traffic Service Nederland B.V.',
                                    15933: 'Traffic Service Nederland B.V.',
                                    67447: 'Trafigo',
                                    64656: 'Transportbedrijf J. Rutte B.V \t',
                                    33978: 'Trigonus BV / IT4TEL Consulting B.V.',
                                    1738: 'Trimp & van Tartwijk',
                                    1739: 'TS Groep',
                                    1740: 'TSD Automatisering BV',
                                    13344: 'TU Delft',
                                    38819: 'Tuin & Groenverzorging Bruisten B.V.',
                                    60105: 'Tuinadviesbureau A.J. van Vugt ',
                                    1742: 'Tuinarch. buro Arend Jan van der Horst BNT',
                                    1743: 'Tuinvormgeving Lucie Nijsen',
                                    16885: 'TVU Advertising',
                                    1744: 'TW- timmerwerken',
                                    1745: 'Twence BV afvalverwerking',
                                    1746: 'Twente Milieu',
                                    13540: 'Twentse Weg- en Waterbouw BV',
                                    52309: 'TX Infra',
                                    1758: 'U-tec',
                                    1747: 'UGS',
                                    49102: 'Ultraware',
                                    14927: 'Uneto-Vni',
                                    1749: 'Unihorn',
                                    1751: 'Universiteit Eindhoven (TUE)',
                                    1752: 'Universiteit Twente',
                                    1753: 'Universiteit Utrecht',
                                    34621: 'UPC Nederland',
                                    1756: 'Urban Jazz',
                                    1757: 'USG Innotiv',
                                    72897: 'Uticon',
                                    64660: 'UTS Nederland B.V. ',
                                    30233: 'UW Groen B.V.',
                                    1759: 'UW Reintegratie',
                                    1760: 'V & S Milieu Adviseurs BV',
                                    63528: 'V.O.F. G.N. Guyt & Zn.',
                                    1761: 'V&T Kring bv',
                                    55558: 'VADO Straatreiniging B.V.',
                                    1762: 'Vaessen Groenvoorziening ',
                                    7732: 'Van Aalsburg Griendhouthandel B.V.',
                                    1763: 'Van Amerongen v.o.f.',
                                    1764: 'Van Antwerpen',
                                    31080: 'Van Asch Infrastructuur',
                                    72754: 'Van Baaren Aannemers',
                                    37597: 'Van Baarsen Buisleidingen BV',
                                    1765: 'Van Beijnen Bestratingen',
                                    241: 'Van Berkel Landschap & Infra B.V.',
                                    1766: 'Van Biene Grond- en Straatwerk',
                                    1767: 'Van Boekel Zeeland BV',
                                    9409: 'Van Breda Advies & Support',
                                    20370: 'Van Caam Asbestverwijdering en Sloopwerken B.V.',
                                    60114: 'Van Daalen GWW B.V.',
                                    37278: 'Van Dalen BV',
                                    1768: 'Van de Bijl & Heierman b.v.',
                                    17479: 'Van de Brug Civiel Techniek',
                                    33739: 'van de Bunt bestratingen',
                                    1769: 'Van de Haar Groep',
                                    16399: 'Van de Kreeke',
                                    1883: 'Van de Wal Grondwerken',
                                    48716: 'Van Delft Infra B.V.',
                                    1770: 'Van den Akker BV Cleaning en Inspection Services',
                                    55517: 'Van den Anker Infra',
                                    1771: 'Van den Berg Infrastructuren',
                                    32055: 'Van den Berg Nijmegen BV',
                                    1773: 'Van den Biggelaar Grond- en waterbouw',
                                    1775: 'van den Elshout & de Bont Waalwijk B.V.',
                                    73258: 'Van den Hadelkamp',
                                    1776: 'Van den Herik Sliedrecht',
                                    37401: 'Van den Heuvel Aannemingsbedrijf',
                                    1777: 'Van den Heuvel Werkendam b.v.',
                                    18817: 'Van den Manacker Transport',
                                    57803: 'Van den Reek Tuinen',
                                    75366: 'Van der Ende Infrastructure B.V.',
                                    23566: 'Van der Grift en Valkenburg',
                                    1778: 'Van der Horst Holding NV',
                                    1779: 'Van der Krogt B.V.',
                                    1780: 'Van der Linden Groen BV',
                                    34582: 'Van der Linden pomptechniek B.V.',
                                    33897: 'Van der Made',
                                    1781: 'Van der Meijden - Groenadvies',
                                    1782: 'Van der Meulen Woudsend BV',
                                    1783: 'Van der Spek Loon- en Grondwerk',
                                    1784: 'Van der Stelt v.o.f.',
                                    30093: 'Van der Tol Groep ',
                                    58837: 'Van der Valk + De Groot',
                                    1785: 'Van der Valk Vastgoed',
                                    1786: 'Van der Waal & Partners',
                                    47136: 'Van der Waal Infra',
                                    30158: 'Van der Wal Sloopwerken B.V.',
                                    40155: 'Van der Weerd Grafhorst BV',
                                    17820: 'Van der Wiel Infra en Milieu BV ',
                                    1788: 'Van der Zwaan bodem en waterbodemprojecten',
                                    6895: 'Van Dijck & Jenneskens',
                                    13211: 'Van Dijk Achterhoek',
                                    1789: 'Van Dijk Adviesbureau',
                                    49326: 'Van Dijk Employment Services ',
                                    1790: 'Van Dijk Maasland BV',
                                    43388: 'Van Dijk Zakelijk',
                                    49746: 'Van Donkersgoed B.V. ',
                                    41649: 'Van Doorn Geldermalsen B.V.',
                                    18336: 'Van Drempt Groen & Cultuur techniek',
                                    1791: 'Van Driel Advies en Verhuur GWW',
                                    1792: 'Van Egmond Wegenbouw Z- H',
                                    10635: 'Van Eijk Infra- en Milieutechniek',
                                    1794: 'Van Eijk Infra- en Milieutechniek',
                                    1795: 'Van Elk',
                                    1796: 'Van Elteren Hoveniers BV & Meker Tuinen BV',
                                    18697: 'Van Empelen van Aalderen Partners B.V. ',
                                    19109: 'Van Esch cultuur- en civieltechniek',
                                    25420: 'Van Esch Infra Technical Support',
                                    27: 'Van Gansewinkel Milieutechniek',
                                    18822: 'Van Gelder TUINEN',
                                    68373: 'Van Gurp B.V.',
                                    1798: 'Van Hattum & Blankenvoort Combinatie Westpoort VOF',
                                    1797: 'Van Hattum en Blankenvoort (Woerden)',
                                    16776: 'Van Hattum en Blankevoort Regio Noord',
                                    1799: 'Van Hees tuin- en landschapsarchitectuur',
                                    23899: 'Van Helvoirt Groenprojecten BV',
                                    37391: 'Van Helvoort Verkeerstechniek',
                                    1801: 'Van Hese Infra BV',
                                    57001: 'Van Heteren Weg- en Waterbouw BV',
                                    1802: 'Van Hillo Advies',
                                    1803: 'Van Kampen Groenprojecten',
                                    49955: 'Van Kessel Sport & Cultuurtechniek bv',
                                    72054: 'Van Kessel Wegenbouw',
                                    1804: 'Van Keulen Infra',
                                    53093: 'Van Klaveren Riooltechniek B.V.',
                                    1805: 'Van Leeuwen Valkenburg Infra BV',
                                    1806: 'Van Meijel Automatisering BV',
                                    22815: 'Van Niel Safety en Management Service',
                                    74886: 'Van Noordenne Projecten',
                                    1807: 'Van Oord Nederland b.v.',
                                    1808: 'Van Oostrum Westbroek B.V.',
                                    36828: 'VAN RAAIJ groenverzorging B.V.',
                                    1809: 'Van Rens B.V.',
                                    57100: 'Van Rijn Service',
                                    1810: 'Van Rijnsoever BV',
                                    64633: 'Van Roode Infra B.V. ',
                                    7280: 'Van Roon B.V.',
                                    1811: 'Van Rosmalen aanneming en Wegenbouw',
                                    16068: 'Van Rossum Infra BV ',
                                    1280: 'Van Schie B.V.',
                                    1812: 'Van Spijker Infrabouw BV',
                                    65232: 'Van Splunder Civiel en Bouw B.V.',
                                    1813: 'Van Staveren Civiele Techniek',
                                    73000: 'Van Straaten Post',
                                    1722: 'Van Terwisga BV',
                                    8854: 'Van Verseveld ',
                                    1814: 'Van Vliet Aannemingsbedrijf B.V.',
                                    1815: 'Van Vliet Waarder B.V.',
                                    9928: 'Van Voskuilen Woudenberg B.V.',
                                    37968: 'Van Vroonhoven Groenprojecten',
                                    30262: 'Van Vulpen B.V.',
                                    1816: 'Van Vuuren Elektrotechniek BV',
                                    57614: 'Van Werven B.V.',
                                    64640: 'Van Wijk Wegenbouw B.V. \t',
                                    15899: 'Van Zetten Holding B.V.',
                                    1817: 'Vande Hoek-Coenegracht',
                                    1818: 'VanderHelm Bedrijven',
                                    1267: 'Vanderplas',
                                    1819: 'VANDERPOL Omgevingsvormgever',
                                    55866: 'Vandersanden Group',
                                    69152: 'VanPaul',
                                    48601: 'Vario Grass BV',
                                    26605: 'Varistone BV ',
                                    1820: 'VB Projects B.V.',
                                    7647: 'VBK Groep',
                                    64644: 'VCK B.V.',
                                    1821: 'VDBURG Infra BV',
                                    57529: 'VDM Groen',
                                    1822: 'Vedder BV',
                                    1823: 'Vedder Infra Management B.V.',
                                    26167: 'Veegservice BB',
                                    40458: 'Veegservicenijland BV',
                                    74598: 'Veldkamp Infra Totaal B.V. ',
                                    19034: 'Velthuis grond- en sloopwerken',
                                    1824: 'Veluwse Bouw Apeldoorn BV',
                                    43237: 'Venko Schilderwerken B.V.',
                                    1825: 'Venraygroen',
                                    8283: 'Verbakel - Bomkas',
                                    1827: 'Verboon Groen B.V.',
                                    1826: 'Verboon Maasland BV',
                                    66281: 'Verduin Civiele Techniek',
                                    7971: 'Verebus Engineering B.V. ',
                                    51467: 'Verhagen Kaatsheuvel',
                                    1828: 'Verheij  Infra B.V.',
                                    1829: 'Verheij Integrale groenzorg',
                                    48478: 'Verhoef Infra B.V.',
                                    61582: 'Verhoeve & Faber',
                                    1830: 'Verhoeve Infra',
                                    34081: 'Verhoeve Junior Participatie',
                                    1831: 'Verhoeven Infra BV',
                                    1832: 'Veringmeier Verkeersmanagement B.V.',
                                    16865: 'Verkeer in beeld',
                                    1834: 'Verkley BV',
                                    1835: 'Verkuil & Moree',
                                    8543: 'Vermeulen Groep',
                                    17810: 'VersA Bodemadvies BV',
                                    1836: 'Verschuure, Independent Civil Engineer',
                                    1838: 'Versloot Projectondersteuning B.V.',
                                    1839: 'Versluys & Zoon B.V.',
                                    64647: 'Versluys Verkeerstechniek B.V. ',
                                    51069: 'Verstegen B.V.',
                                    20081: 'Vertisol',
                                    1841: 'Verweij Civiele Techniek',
                                    1843: 'VEYM Wegenbouw',
                                    1844: 'VH Infra bv',
                                    16898: 'VI Matrix',
                                    1845: 'Via Infra v.o.f. projecten & advies',
                                    1846: 'Via Nova Civiele Techniek',
                                    50277: 'Via-Idea',
                                    72453: 'ViadenBerg',
                                    1847: 'Viadesk BV',
                                    1848: 'Vialis BV',
                                    14920: 'Vialis BV Integrale Projecten',
                                    1849: 'Vialis Traffic bv',
                                    1850: 'Vialis-TPA bv',
                                    1851: 'Viatech-gww Projectondersteuning',
                                    1852: 'Victor Geugjes Onderhoud & Verhuur',
                                    34394: 'ViForis B.V. ',
                                    1854: 'Vink Aannemingsmaatschappij BV',
                                    65506: 'Vink Bouw Nieuwkoop B.V. \t',
                                    75120: 'VIOS Bouwgroep B.V.',
                                    55162: 'VIOSS B.V.',
                                    69556: 'Vis Groep',
                                    79611: 'Visser & Smit Bouw ',
                                    1855: 'Visser & Smit Hanab',
                                    1856: 'Visser & Smit Hanab Distributie bv',
                                    30711: 'Visser & Smit Papendrecht',
                                    1857: 'Visser Tuinen',
                                    1858: 'Vissers-ploegmakers BV',
                                    18517: 'Vitae',
                                    1859: 'Vitae b.v.',
                                    1860: 'Vitens',
                                    35162: 'VITENS (Zwolle)',
                                    1861: 'Vixia bv',
                                    32013: 'Vizierict',
                                    1862: 'Vkconsultancy',
                                    7545: 'VOBI Beton-en Waterbouw',
                                    65792: 'Voestalpine SST NL',
                                    1863: 'Voeten & Leppens',
                                    1864: 'Voeten Straatmakersbedr. Planten/Tuindec. BV',
                                    1865: 'Voets Wegenbouw',
                                    48866: 'VOKW',
                                    41378: 'Volker Staal en Funderingen',
                                    1866: 'Volkerrail Contracting',
                                    7077: 'VolkerRail Nederland bv',
                                    71146: 'VolkerWessels Integraal',
                                    32017: 'VolkerWessels Telecom',
                                    1867: 'Voor-Rioo',
                                    10662: 'Voorbij Betonsystemen',
                                    33936: 'Vos | PSH',
                                    1869: 'Vos BV',
                                    1870: 'Vos Civiele Techniek',
                                    1871: 'Voskuil & Partners',
                                    38700: 'Vossebeld C. en C. Techniek B.V.',
                                    1872: 'VR - Van der Velden Rioleringsbeheer',
                                    76192: 'Vrijborg',
                                    1873: 'Vroom Funderingstechnieken BV',
                                    1874: 'VSM Sloopwerken BV',
                                    69623: 'VT Consultants',
                                    37407: 'VW Netwerkbouw',
                                    1875: 'W vd Heuvel & zn b.v.',
                                    9185: 'W. Kool Grondverzet BV',
                                    30037: 'W. van den Heuvel & Zn. B.V.',
                                    1876: 'W.F. de Jong',
                                    18271: 'W.J. van den Broek Bestratingen',
                                    17773: 'W.J. van den Broek Bestratingen',
                                    1877: 'W.L. van der Wel Grondwerk-Bestratingen',
                                    57226: 'W.Moonen En Zonen B.V',
                                    1878: 'W.N. de Jong & Zn',
                                    1879: 'W.Stienstra & Zn. BV',
                                    24289: 'W&A Service',
                                    14375: 'W2N engineers',
                                    1881: 'Wacom Civiel Technisch ingenieursbureau',
                                    1882: 'Wageningen Academic Publishers',
                                    6938: 'Wallaard Noordeloos B.V.',
                                    20739: 'Wareco Ingenieurs BV',
                                    37613: 'Wastewater Solutions',
                                    16895: 'Water Vakblad',
                                    1886: 'Waterbedrijf Groningen',
                                    70677: 'Waterblock',
                                    26544: 'Waterleidingmaatschappij Drenthe',
                                    1887: 'WaterNet',
                                    1888: 'Waterpas Civiel Adviesbureau',
                                    1889: 'Waterschap Aa en Maas',
                                    1890: 'Waterschap Blija Buitendijks',
                                    1891: 'Waterschap Brabantse Delta',
                                    1892: 'Waterschap De Dommel',
                                    1893: 'Waterschap Fryslân',
                                    1894: 'Waterschap Groot Salland',
                                    1895: 'Waterschap Hollandse Delta',
                                    1896: "Waterschap Hunze en Aa's",
                                    1897: 'Waterschap Noorderzijlvest',
                                    1898: 'Waterschap Peel en Maasvallei',
                                    1899: 'Waterschap Reest en Wieden',
                                    1901: 'Waterschap Rijn en IJssel',
                                    1902: 'Waterschap Rivierenland',
                                    1614: 'Waterschap Roer en Overmaas',
                                    1903: 'Waterschap Scheldestromen',
                                    52886: 'Waterschap Vallei en Veluwe',
                                    1900: 'Waterschap Vechtstromen',
                                    1905: 'Waterschap Velt en Vecht',
                                    1907: 'Waterschap Zeeuws-Vlaanderen',
                                    1908: 'Waterschap Zuiderzeeland',
                                    1909: 'Waterschapsbedrijf Limburg',
                                    1910: 'Waterwerken Lelystad',
                                    8122: 'Wattel Verkeerstechniek B.V.',
                                    64161: 'Wauben Productie B.V.',
                                    1911: 'Wavin Nederland B.V.',
                                    1912: 'Wavin NV',
                                    62303: 'WBU Bouwgroep BV ',
                                    1913: 'WEBO Wegenbouwbedrijf BV',
                                    66089: 'Weboma Bouw Holding B.V. \t',
                                    1914: 'WEDEKA',
                                    63328: 'Weener XL',
                                    336: 'Wegbouwkundig Buro van den Boom B.V.',
                                    1915: 'Wegen Bouw Marijnissen',
                                    53532: 'Wegenbouw Brune B.V.',
                                    16824: 'Wegenbouw De Wilde bv',
                                    57593: 'Wegenbouw Lansink b.v.',
                                    1687: 'Wegenbouw Steevens B.V. ',
                                    1917: 'Wegenbouw van der Werff B.V.',
                                    1918: 'Wegenbouwbedrijf de Rooij en Zonen BV',
                                    29136: 'Wegenbouwbedrijf J.M. vd Broek',
                                    1919: 'Wegenbouwbedrijf Oost BV',
                                    7634: 'Wegenbouwmaatschappij J. Heijmans B.V. (Roermond)',
                                    16867: 'weka-bouw',
                                    71414: 'WELD Infra & Milieu BV',
                                    1921: 'Wellantcollege Houten',
                                    53981: 'Wellantcollege Rijnsburg ',
                                    1922: 'Welling BV Aannemingsmaatschappij',
                                    70185: 'Wenz Infra Advies',
                                    1923: 'Werk in Uitvoering',
                                    853: 'Werkorganisatie CGM',
                                    999: 'Werkorganisatie Duivenvoorde',
                                    1924: 'WEST 8 urban design & landscape architecture B.V.',
                                    1926: 'Westerlaken BV',
                                    1928: 'Westerscheldetunnel',
                                    45761: 'Westland Infra',
                                    63767: 'Westra Franeker',
                                    1929: 'Westrom',
                                    1930: 'Westvoort BV Aannemers Helmond',
                                    13956: 'Wetterskip Fryslân',
                                    1932: 'Weverling Groenprojekten',
                                    1933: 'Wezo groep bv',
                                    30715: 'WH Techniek',
                                    1934: 'Wiel Bestratingen, P. van de',
                                    48519: 'Wielstra Project Support',
                                    1935: 'Wieringen Prins',
                                    20907: 'Wijnhoven Groenadvies',
                                    1936: 'WiJo BV',
                                    29811: 'Wilco van Lent Infra B.V.',
                                    1937: 'Wilde De B.V.Ingenieurs- & Adviesburo',
                                    55994: 'Will2sustain',
                                    1938: 'Willems Winssen BV',
                                    1939: 'Wim Boelens',
                                    1940: 'Wim Verwijmeren Stratenmakersbedrijf B.V.',
                                    12430: 'Windesheim',
                                    80262: 'Windpark Nijmegen-Betuwe B.V.',
                                    65512: 'Wisselwerk BV',
                                    1941: 'Witteveen + Bos',
                                    28722: 'Witteveen + Bos | Deventer',
                                    48659: 'Witteveen Groenprojecten en Advies',
                                    1943: 'WML weg- en waterbouw midden-limburg b.v.',
                                    70252: 'WMR Rinsumageest B.V.',
                                    26297: 'wonenCentraal',
                                    9864: 'Woningstichting Barneveld',
                                    70904: 'Woningstichting Maasvallei Maastricht',
                                    32123: 'Wopa',
                                    37410: 'Woudenberg Projectbureau in Weg- en Waterbouw',
                                    1944: 'WSdienstverlening',
                                    1945: 'WTCB (Demonstratie- en informatiecentrum)',
                                    1946: 'WTCB (Kantoren)',
                                    75568: 'WTH Vloerverwarming B.V.',
                                    37417: 'WTOP infra BV',
                                    1947: 'Wulfraat B.V.',
                                    1948: 'WV Hoveniers',
                                    21406: 'Xenosite',
                                    1949: 'XS4ALL',
                                    1260: 'Xylem Water Solutions',
                                    1950: 'Yucat bv',
                                    9111: 'Yuri Advies',
                                    1951: 'Zadkine',
                                    28791: 'Zeeboer BV',
                                    37322: 'Zeehaven IJmuiden NV',
                                    1952: 'Zeeland Engineering Consultants B.V.',
                                    29721: 'Zeeland Seaports',
                                    1953: 'Zeeman Civiel',
                                    6890: 'Zeeuwse Asfalt Onderneming',
                                    1954: 'Zeeuwse stromen BV',
                                    1955: 'Ziut B.V.',
                                    25616: 'Ziut B.V. (Noord)',
                                    25619: 'Ziut B.V. (Oost)',
                                    8546: 'Ziut B.V. (Team Zuid-West)',
                                    25617: 'Ziut B.V. (West)',
                                    25620: 'Ziut B.V. (Zuid)',
                                    1956: 'Zoetekouw Civiel',
                                    1957: 'Zoutman Loon- en Grondverzetbedrijf',
                                    74526: 'Zublin Nederland',
                                    37384: 'Zuidema Aannemers BV',
                                    39712: 'Zwier Bestratingen',
                                },
                            },
                            {
                                BaseType: 'select',
                                ComboboxLocked: false,
                                DefaultValue: null,
                                DisplayValue: null,
                                FillWithFileDate: false,
                                Help: '',
                                Hidden: false,
                                ID: 1080042,
                                IsCalculation: false,
                                IsEmpty: false,
                                IsHTML: false,
                                Locked: false,
                                MaxLength: null,
                                MinLength: 1,
                                Name: 'Contactpersoon (keuzelijst)',
                                Refresh:
                                    'getElementListBasedOn(1080101,WaardeSE,trContactpersoon,seVoornaam,seVoornaam SE_Tussenvoegsel seAchternaam)',
                                RegExPattern: null,
                                ReloadPossible: false,
                                Required: true,
                                RowNumber: null,
                                SID: 'seContactpersoon',
                                TableCellLocked: false,
                                Tag: null,
                                TransactionListBasedOn: {
                                    BasedOnFilters: [
                                        {
                                            BasedOnSimpleElementID: 1080101,
                                            BasedOnTransactionID: false,
                                            FilterElementDisplayValue: null,
                                            FilterElementValue: null,
                                            FilterSimpleElementID: 1080101,
                                        },
                                    ],
                                    DisplayElementSID: 'seVoornaam SE_Tussenvoegsel seAchternaam',
                                    FunctionXml:
                                        '<?xml version="1.0" encoding="utf-16"?>\n<TransactionList xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns="http://www.bakkerspees.nl/visi/aux/4.0">\n  <TransactionTypeSID>trContactpersoon</TransactionTypeSID>\n  <DisplaySimpleElementSID>seVoornaam SE_Tussenvoegsel seAchternaam</DisplaySimpleElementSID>\n  <BasedOnFilters>\n    <BasedOnFilter>\n      <FilterSimpleElementSID>seNaamOrganisatie</FilterSimpleElementSID>\n      <BasedOnSimpleElementSID>seNaamOrganisatie</BasedOnSimpleElementSID>\n    </BasedOnFilter>\n  </BasedOnFilters>\n  <IgnoreMessageTypeSID>msgVerwijderContactpersoon</IgnoreMessageTypeSID>\n</TransactionList>',
                                    IgnoreMessageTypeSID: 'msgVerwijderContactpersoon',
                                    NotRequired: false,
                                    ProjectSID: null,
                                    TransactionTypeSID: 'trContactpersoon',
                                },
                                Value: '',
                                Values: {},
                            },
                            {
                                BaseType: 'BOOLEAN',
                                ComboboxLocked: false,
                                DefaultValue: null,
                                DisplayValue: null,
                                FillWithFileDate: false,
                                Help: 'Moet er automatisch een mail gestuurd worden naar de klant als het opgelost en getest is?',
                                Hidden: false,
                                ID: 1079681,
                                IsCalculation: false,
                                IsEmpty: false,
                                IsHTML: false,
                                Locked: false,
                                MaxLength: null,
                                MinLength: null,
                                Name: 'Mail bij mutaties',
                                Refresh: null,
                                RegExPattern: null,
                                ReloadPossible: false,
                                Required: false,
                                RowNumber: null,
                                SID: 'SE_Mail_naar_klant_software_aangepast_bij_mutaties',
                                TableCellLocked: false,
                                Tag: null,
                                TransactionListBasedOn: null,
                                Value: '',
                                Values: {},
                            },
                            {
                                BaseType: 'BOOLEAN',
                                ComboboxLocked: false,
                                DefaultValue: null,
                                DisplayValue: null,
                                FillWithFileDate: false,
                                Help: 'Moet er automatisch een mail gestuurd worden naar de klant als het opgelost en getest is?',
                                Hidden: false,
                                ID: 1079680,
                                IsCalculation: false,
                                IsEmpty: false,
                                IsHTML: false,
                                Locked: false,
                                MaxLength: null,
                                MinLength: null,
                                Name: 'Mail bij afronding',
                                Refresh: null,
                                RegExPattern: null,
                                ReloadPossible: false,
                                Required: false,
                                RowNumber: null,
                                SID: 'SE_Mail_naar_klant_software_aangepast_bij_afroding',
                                TableCellLocked: false,
                                Tag: null,
                                TransactionListBasedOn: null,
                                Value: '',
                                Values: {},
                            },
                        ],
                        Data: [],
                        ID: 1080324,
                        IgnoreLock: false,
                        IsCleared: false,
                        Locked: false,
                        MaxOccurs: null,
                        MinOccurs: null,
                        Name: 'Contactgegevens tabel',
                        RefreshElementSIDs: null,
                        SID: 'CE_ContactGegevens_tabel',
                        Tag: null,
                        TransactionList: null,
                    },
                ],
            },
        ],
        CurrentLineNumberInTable: 0,
        Executors: [
            {
                ID: 'pir_758a4092-c1fd-448c-b13a-f91c4fa707da',
                InitiatorSID: null,
                Name: 'software_aanpassing_beslisser',
            },
        ],
        ExecutorsFromSimpleElementSID: null,
        ExecutorsHidden: false,
        FromRoleToRole: 'van opsteller_software_aanpassing naar beslisser_software_aanpassing',
        IncludeAppendices: false,
        Initiators: [
            {
                Executors: [
                    {
                        ID: 'pir_758a4092-c1fd-448c-b13a-f91c4fa707da',
                        InitiatorSID: null,
                        Name: 'software_aanpassing_beslisser',
                    },
                ],
                ID: 'pir_1f9fff15-44ea-46b1-a6f9-d03c70d00f8d',
                Name: 'bs_jeroenk',
                Selected: false,
            },
        ],
        InitiatorsVisible: false,
        LabelBericht: 'Bericht',
        LabelExecutors: 'Versturen naar',
        LuaSettings: null,
        MITID: 2316655,
        MessageTypeID: 1663757,
        Notification: null,
        Receiver: null,
        ReplyToMessageID: 0,
        RestoreContent: true,
        SettingTransaction: null,
        ShowFlowchartButton: true,
        Subject: '1382:',
        SubjectEnabled: false,
        SubjectGeneration: '[SE:SE_Software_aanpassing_nummer]: [SE:SE_Software_aanpassing_omschrijving]',
        SubjectHidden: true,
        TransactionID: 0,
        TransactionTypeID: 416897,
        UpdateToLatestFramework: false,
    },
    header: [
        {
            ButtonCaption: null,
            ButtonIsSubtransaction: false,
            Direction: 0,
            HelpInfo: 'Transactie voor het verzoeken van software aanpassingen',
            ID: 416897,
            IsButton: false,
            Messages: [
                {
                    Disabled: false,
                    DisabledTooltip: null,
                    HelpInfo: null,
                    ID: 1663757,
                    IsEditableByEveryone: false,
                    MitID: 2316655,
                    MitSID: 'mit22421',
                    Name: 'Verzoek: Software aanpassing',
                    SID: 'Msg_Verzoek_software_aanpassing',
                    Tooltip:
                        'Verstuur het bericht:\n<B>Verzoek: Software aanpassing</B>\nnaar:\n<B>software_aanpassing_beslisser</B>\nTransactiebeschrijving:\n<B>Transactie voor het verzoeken van software aanpassingen</B>',
                    TransactionTypeID: 416897,
                },
            ],
            Name: '_(vervallen, gebruik Backlog Item Voorstel) Software aanpassing verzoek',
            OneExecutor: false,
            SID: 'Tr_Software_aanpassing_verzoek',
            Tooltip: 'Verstuur het bericht:\n<B>Verzoek: Software aanpassing</B>\nnaar:\n<B>software_aanpassing_beslisser</B>',
        },

        {
            ButtonCaption: null,
            ButtonIsSubtransaction: false,
            Direction: 0,
            HelpInfo: null,
            ID: 416856,
            IsButton: false,
            Messages: [
                {
                    Disabled: false,
                    DisabledTooltip: null,
                    HelpInfo: null,
                    ID: 1663564,
                    IsEditableByEveryone: false,
                    MitID: 2316186,
                    MitSID: 'MT_21',
                    Name: 'Apparatuur aanmaken',
                    SID: 'MS_Apparatuur_aanmaken',
                    Tooltip: 'Verstuur het bericht:\n<B>Apparatuur aanmaken</B>\nnaar:\n<B>Beheerder</B> of <B>Peter van den Berg</B>',
                    TransactionTypeID: 416856,
                },
            ],
            Name: 'Apparatuur',
            OneExecutor: false,
            SID: 'TR_Apparatuur',
            Tooltip: 'Verstuur het bericht:\n<B>Apparatuur aanmaken</B>\nnaar:\n<B>Beheerder</B> of <B>Peter van den Berg</B>',
        },
        {
            ButtonCaption: null,
            ButtonIsSubtransaction: false,
            Direction: 0,
            HelpInfo: null,
            ID: 416857,
            IsButton: false,
            Messages: [
                {
                    Disabled: false,
                    DisabledTooltip: null,
                    HelpInfo: null,
                    ID: 1663565,
                    IsEditableByEveryone: false,
                    MitID: 2316212,
                    MitSID: 'MT_26',
                    Name: 'Apparatuur aan medewerker aanmaken',
                    SID: 'MS_Apparatuur_medewerker_aanmaken',
                    Tooltip: 'Verstuur het bericht:\n<B>Apparatuur aan medewerker aanmaken</B>\nnaar:\n<B>Beheerder</B> of <B>Peter van den Berg</B>',
                    TransactionTypeID: 416857,
                },
            ],
            Name: 'Apparatuur aan medewerker',
            OneExecutor: false,
            SID: 'TR_Apparatuur_medewerker',
            Tooltip: 'Verstuur het bericht:\n<B>Apparatuur aan medewerker aanmaken</B>\nnaar:\n<B>Beheerder</B> of <B>Peter van den Berg</B>',
        },

        {
            ButtonCaption: null,
            ButtonIsSubtransaction: false,
            Direction: 0,
            HelpInfo: '',
            ID: 416912,
            IsButton: false,
            Messages: [
                {
                    Disabled: false,
                    DisabledTooltip: null,
                    HelpInfo: '',
                    ID: 1663900,
                    IsEditableByEveryone: false,
                    MitID: 2316729,
                    MitSID: 'mit24026',
                    Name: 'Toevoegen Theme',
                    SID: 'msgToevoegenTheme',
                    Tooltip: 'Verstuur het bericht:\n<B>Toevoegen Theme</B>\nnaar:\n<B>Beheerder</B>',
                    TransactionTypeID: 416912,
                },
            ],
            Name: 'Backlog Theme',
            OneExecutor: false,
            SID: 'trBacklogTheme',
            Tooltip: 'Verstuur het bericht:\n<B>Toevoegen Theme</B>\nnaar:\n<B>Beheerder</B>',
        },

        {
            ButtonCaption: null,
            ButtonIsSubtransaction: false,
            Direction: 0,
            HelpInfo: null,
            ID: 7,
            IsButton: false,
            Messages: [
                {
                    Disabled: false,
                    DisabledTooltip: null,
                    HelpInfo: null,
                    ID: 37,
                    IsEditableByEveryone: false,
                    MitID: 29,
                    MitSID: 'ID_63D5C3D45DA246FEAC8A90DF1EF400B3',
                    Name: 'Nieuw persoon',
                    SID: 'MSG_NewProjectPerson',
                    Tooltip: null,
                    TransactionTypeID: 7,
                },
            ],
            Name: 'Persoon',
            OneExecutor: false,
            SID: 'TR_Person',
            Tooltip: null,
        },
        {
            ButtonCaption: null,
            ButtonIsSubtransaction: false,
            Direction: 0,
            HelpInfo: null,
            ID: 8,
            IsButton: false,
            Messages: [
                {
                    Disabled: false,
                    DisabledTooltip: null,
                    HelpInfo: null,
                    ID: 35,
                    IsEditableByEveryone: false,
                    MitID: 17,
                    MitSID: 'ID_3AB77F57474B4AFA902E6844694F84D1',
                    Name: 'Nieuw project',
                    SID: 'MSG_NewProject',
                    Tooltip: null,
                    TransactionTypeID: 8,
                },
            ],
            Name: 'Project',
            OneExecutor: false,
            SID: 'TR_Project',
            Tooltip: null,
        },
        {
            ButtonCaption: null,
            ButtonIsSubtransaction: false,
            Direction: 0,
            HelpInfo: null,
            ID: 13,
            IsButton: false,
            Messages: [
                {
                    Disabled: false,
                    DisabledTooltip: null,
                    HelpInfo: null,
                    ID: 40,
                    IsEditableByEveryone: false,
                    MitID: 72,
                    MitSID: 'ID_F4D7AA9DB7254526849100BD30097F2A',
                    Name: 'Nieuwe sjabloon',
                    SID: 'MSG_NewSjabloon',
                    Tooltip: null,
                    TransactionTypeID: 13,
                },
            ],
            Name: 'Sjabloon',
            OneExecutor: false,
            SID: 'TR_Sjabloon',
            Tooltip: null,
        },
        {
            ButtonCaption: null,
            ButtonIsSubtransaction: false,
            Direction: 0,
            HelpInfo: null,
            ID: 2,
            IsButton: false,
            Messages: [
                {
                    Disabled: false,
                    DisabledTooltip: null,
                    HelpInfo: null,
                    ID: 31,
                    IsEditableByEveryone: false,
                    MitID: 22,
                    MitSID: 'ID_4510F99B8BA54A0FB165187459484A57',
                    Name: 'Nieuw veldgedrag',
                    SID: 'MSG_NewFieldBehaviour',
                    Tooltip: null,
                    TransactionTypeID: 2,
                },
            ],
            Name: 'Veldgedrag',
            OneExecutor: false,
            SID: 'TR_FieldBehaviour',
            Tooltip: null,
        },
        {
            ButtonCaption: null,
            ButtonIsSubtransaction: false,
            Direction: 0,
            HelpInfo: null,
            ID: 16,
            IsButton: false,
            Messages: [
                {
                    Disabled: false,
                    DisabledTooltip: null,
                    HelpInfo: null,
                    ID: 43,
                    IsEditableByEveryone: false,
                    MitID: 15,
                    MitSID: 'ID_361C4D6D8A954E7FB3DBF6AA387F7F8C',
                    Name: 'Nieuw versienummer',
                    SID: 'MSG_NewVersion',
                    Tooltip: null,
                    TransactionTypeID: 16,
                },
            ],
            Name: 'Versienummer',
            OneExecutor: false,
            SID: 'TR_Version',
            Tooltip: null,
        },
        {
            ButtonCaption: null,
            ButtonIsSubtransaction: false,
            Direction: 0,
            HelpInfo: null,
            ID: 12,
            IsButton: false,
            Messages: [
                {
                    Disabled: false,
                    DisabledTooltip: null,
                    HelpInfo: null,
                    ID: 39,
                    IsEditableByEveryone: false,
                    MitID: 36,
                    MitSID: 'ID_8E0D210A3F454012A4DE8734DD1CB534',
                    Name: 'Nieuw volgnummer',
                    SID: 'MSG_NewSequence',
                    Tooltip: null,
                    TransactionTypeID: 12,
                },
            ],
            Name: 'Volgnummer',
            OneExecutor: false,
            SID: 'TR_Sequence',
            Tooltip: null,
        },
    ],
};

export default NewMessageData;
