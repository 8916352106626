// Theme
import './assets/scss/dx.material.visi-light-green.scss';
import './assets/scss/visi-global.scss';
import './assets/scss/visi-fonts.scss';
import './assets/scss/global/buttons.scss';
import './assets/scss/global/tabs.scss';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { httpClientIntegration } from '@sentry/integrations';

import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import App from './App';

// REFS
import { ProjectConfiguration } from './utils/Service/ServiceReference/ProjectConfiguration';

// UTILS
import { setLanguage } from './utils/Locale/Locale';
import { getSessionStorageProperty } from './utils/JsonHelper/sessionStorageUtils';
import { getLocalStorage } from './utils/JsonHelper/localStorageUtils';
import { pasteExcelToTable } from './utils/ClipBoard/PasteExcelToTable';
import { config, Service } from './utils/Service/Service';

// CSS
import './index.scss';

const {
    REACT_APP_ENVIRONMENT,
    REACT_APP_SENTRY_DEBUG,
    REACT_APP_SENTRY_DSN,
    REACT_APP_SENTRY_PROJECT_ID,
    REACT_APP_SENTRY_RELEASE,
    REACT_APP_SENTRY_TRAFFIC_RATE,
} = process.env;

const userAppLanguage: string = getLocalStorage('interface_language') || getSessionStorageProperty('InterfaceLanguage');

const getCurrentSessionOpenedProject = (): ProjectConfiguration | undefined => {
    return getSessionStorageProperty('OpenedProject') ? getSessionStorageProperty('OpenedProject') : undefined;
};

setLanguage(userAppLanguage);

Sentry.init({
    dsn: `${REACT_APP_SENTRY_DSN}/${REACT_APP_SENTRY_PROJECT_ID}`,
    initialScope: {
        tags: {
            bs_app_lang: userAppLanguage,
            bs_window_resolution: `${window.screen.width}x${window.screen.height}`,
            bs_project_id: getCurrentSessionOpenedProject()?.ProjectID,
        },
    },
    // PERFORMANCE MONITORING
    // This sets the sample rate to be 10% in production.
    // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
    tracesSampleRate:
        REACT_APP_SENTRY_TRAFFIC_RATE && REACT_APP_SENTRY_TRAFFIC_RATE?.length > 0
            ? parseFloat(REACT_APP_SENTRY_TRAFFIC_RATE)
            : REACT_APP_ENVIRONMENT === 'production'
              ? 0.1
              : 1.0,

    // SESSION REPLAY
    // This sets the sample rate to be 10% in production.
    // You may want this to be 100% while in development
    replaysSessionSampleRate:
        REACT_APP_SENTRY_TRAFFIC_RATE && REACT_APP_SENTRY_TRAFFIC_RATE?.length > 0
            ? parseFloat(REACT_APP_SENTRY_TRAFFIC_RATE)
            : REACT_APP_ENVIRONMENT === 'production'
              ? 0.1
              : 1.0,

    // If the entire session is not sampled, because user was not targeted in the 0.1 of replaysSessionSampleRate,
    // the below sample rate determines that the session will be sampled for replay when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    debug: REACT_APP_SENTRY_DEBUG === 'true',
    environment: REACT_APP_ENVIRONMENT,
    release: REACT_APP_SENTRY_RELEASE,
    integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect: React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
            enableInp: true,
        }),
        Sentry.replayIntegration(),
        Sentry.replayCanvasIntegration(),
        httpClientIntegration(),
    ],
    tracePropagationTargets: [/^\/api\//],
    beforeSend: (event) => {
        return event;
    },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

function Root() {
    return <App key='app-component' />;
}

function render() {
    root.render(<Root key='app-root-component' />);
}

window.addEventListener('resize', render);
window.addEventListener('paste', pasteExcelToTable);
config()
    .then((configJson) => {
        //  pak de custom config data uit de config.json
        Service.config = configJson.data;
    })
    .finally(() => {
        // indien geen config render alsnog
        render();
    });
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
