import React, { useContext, useReducer } from 'react';
import { TabsContext, INITIAL_TABS_STATE } from '../context/TabsContext';
import { tabsReducer } from '../reducers/tabsReducer';

interface TabsProviderProps {
    children: React.ReactNode;
}

export const TabsProvider: React.FC<TabsProviderProps> = ({ children }) => {
    const [tabsState, dispatchTabsState] = useReducer(tabsReducer, INITIAL_TABS_STATE);

    return (
        <TabsContext.Provider key='tabsprovider-context' value={[tabsState, dispatchTabsState]}>
            {children}
        </TabsContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(TabsContext);
};
