import dateLocale from 'devextreme/localization/date';
import { formatMessage, formatNumber } from 'devextreme/localization';

// SERVICES
import APIService from './APIService';
import axios, { AxiosResponse } from 'axios';
import SOAPService from './SOAPService';
import MockService from './MockService';

// UTILS
import {
    containsDangerousHTML,
    prepareInitiatorPersoonRolSIDs,
    preparePostAppendices,
    preparePostContent,
    processMessagePostCalculationData,
    setExecutorsFromSimpleElements,
} from './Service.utils';
import { formatToNLLocale, parseToLocaleString } from '../ParseToLocaleString/parseToLocaleString';
import { generateID } from '../GenerateID/generateID';
import { parseDate } from '../../templates/BaseTypeSwitch/BaseTypeSwitch';
import { parseToNumber } from '../ParseToNumber/parseToNumber';
import { regExMatch, regExPatternEqual } from '../Comparison/Comparison';
import toDataFormat from '../DataFormat/DataFormat';
import { unmountReactTabInstance } from '../../state/reducers/tabsReducer.utils';

// REFERENCES
import { MessagePostContent, MessagePostContentSimpleElement } from './ServiceReference/MessagePostContent';
import { NewMessageContent, NewMessageContentSimpleElement } from './ServiceReference/NewMessageContent';
import { NewMessageContentRequest } from './ServiceReference/NewMessageContentRequest';
import { ReplyTransaction } from './ServiceReference/ReplyTransaction';
import { MessageContent, Sjabloon } from './ServiceReference/MessageContent';
import { TransactionRecord } from './ServiceReference/TransactionList';
import { AuxTransactionList, ReportDocument } from './ServiceReference/reference';
import { CompleteTransaction } from './ServiceReference/TransactionRecord';
import { TabItem } from '../../state/context/TabsContext';

export const dotcore = true;
// export let dotcore = false;
const isStoryBook = location.href.indexOf('story') > -1;
// SOAPService.setUrl(location.href);

// !isStoryBook &&
//     SOAPService.checkCORE().then((response: any) => {
//         console.log('isCore', response?.data);
//         dotcore = response?.data;
//     });

const reguliereExpressieInterprator = (el: NewMessageContentSimpleElement) => {
    // GS 10-07-2023 check reguliere expressie
    // momenteel alleen een check om te kijken of de ^ en $ anchor zijn toegevoegd
    // in de winfront is de implementatie van reguliere expressie interpreteren anders en werkt het zonder anchors
    // om het te laten werken met de devextreme webcontrols wordt er een ^ en $ toegevoegd wanneer deze niet bestaan

    if (typeof el.RegExPattern === 'string' && el.RegExPattern.length > 0) {
        // deze logica is overgenomen van BSVisiWinfront4\VisiWinControls\User Controls\XtraUcMessageControlTextBox.cs:120
        // hier wordt een regex gebruikt als Mask, dit is niet mogelijk in web
        // we zetten hier het basetype om naar decimal / integer gebasseerd op de regex

        // patroon detectie
        // zie userstory "10212: WEB: Invoer van getallen", deze lijst van reguliere expressies wordt hierin gebruikt

        // GS deze code is uitgezet omdat het problemen opleverd bij Prorail
        // voorlopig laat ik deze code even staan zodat we het weer snel kunnen implementeren
        // vanuit bepaalde stakeholders is het wel gewenst en dit kan terugkomen

        switch (el.RegExPattern) {
            //     case '(([0-4]\\d)|(5[0123])|\\d)?':
            //     case '(([0-4]\\d)|(5[0123])|\\d)':
            //         // Reg UDT Weeknummer optioneel (dit wordt al geset door Required)
            //         // Reg UDT Weeknummer verplicht
            //         el.BaseType = 'integer';
            //         el.MaxLength = 53;
            //         el.MinLength = 1;
            //         break;
            case '(-?([1-9]\\d{0,2}(\\.\\d{3})*|[1-9]\\d*|0),\\d{2})?':
                // UDT Euro's optioneel
                // UDT Euro's verplicht (is dezelfde)
                // UDT Dollars verplicht (is dezelfde) (Required wordt ergens anders gezet)
                el.Decimals = 2;
                el.BaseType = 'decimal';

                // bij deze regexp moet de minlength op null, we willen juist negatieve velden toelaten
                el.MinLength = null;
                if (el.Value === '') el.Value = null;
                break;
            //     case '(\\d?\\d?\\d?\\d?\\d?)':
            //     case '(\\d?\\d?\\d?\\d?\\d)':
            //         // UDT Lengte in meters optioneel
            //         // UDT Lengte in meters verplicht  (Required wordt ergens anders gezet)
            //         el.BaseType = 'integer';
            //         // bij deze regexp kan je geen - invullen minlength is dus 0
            //         el.MinLength = 0;
            //         // max 5 karakters invullen dus een maxlength van
            //         el.MaxLength = 99999;
            //         break;
            //     case '(\\d*\\d{1},\\d{3})?':
            //     case '\\d*\\d{1},\\d{3}':
            //         // Reg UDT Kilometrering optioneel (#,###)
            //         // UDT Kilometrering verplicht(#,###)  (Required wordt ergens anders gezet)
            //         el.BaseType = 'decimal';
            //         el.Decimals = 3;

            //         // bij deze regexp kan je geen - invullen minlength is dus 0
            //         el.MinLength = 0;
            //         break;
        }

        // Voeg de anchors toe zodat ze werken met devextreme controls
        let sb = '';
        if (el.RegExPattern.substring(0, 1) !== '^') {
            sb += '^';
        }
        sb += el.RegExPattern;

        if (el.RegExPattern.substring(el.RegExPattern.length - 1) !== '$') {
            sb += '$';
        }
        el.RegExPattern = sb;
    }
};

const Service = {
    getAdditionalContent: async (url: string) => {
        if (isStoryBook) {
            return await MockService.getAdditionalContent(url);
        } else {
            return await APIService.getAdditionalContent(url);
            // return await SOAPService.getAdditionalContent();
        }
    },
    addSetting: async (settingName: string, settingValue: string, session: string, token: string) => {
        if (dotcore) {
            return await APIService.addSetting(settingName, settingValue, session, token);
        } else {
            return await SOAPService.addSetting(settingName, settingValue, session, token);
        }
    },
    getAdditionalMedia: async (url: string) => {
        if (isStoryBook) {
            return await MockService.getAdditionalMedia(url);
        } else {
            if (dotcore) {
                return await APIService.getAdditionalMedia(url);
            } else {
                // return await SOAPService.getAdditionalMedia();
            }
        }
    },
    addUserSetting: async (settingName: string, settingValue: string, session: string, token: string) => {
        if (isStoryBook) {
            return await MockService.addUserSetting();
        } else {
            if (dotcore) {
                return await APIService.addUserSetting(settingName, settingValue, session, token);
            } else {
                return await SOAPService.addUserSetting(settingName, settingValue, session, token);
            }
        }
    },
    getBsToken: async (url: string) => {
        return await APIService.getBsToken(url);
    },
    addProjectSetting: async (settingName: string, settingValue: string, session: string, token: string) => {
        return await APIService.addProjectSetting(settingName, settingValue, session, token);
    },
    logon: async (username: string, password: string) => {
        if (dotcore) {
            return await APIService.logon(username, password);
        } else {
            return await SOAPService.logon(username, password);
        }
    },
    getProjectSetting: async (settingName: string, session: string, token: string) => {
        return await APIService.getProjectSetting(settingName, session, token);
    },
    logon2fa: async (username: string, password: string, token2FA: string) => {
        if (dotcore) {
            return await APIService.logon2fa(username, password, token2FA);
        } else {
            return await SOAPService.logon(username, password); // 2fa wordt in appsettings ingesteld
        }
    },
    addToFavorites: async (projectID: number, session: string, token: string) => {
        if (isStoryBook) {
            // Niks doen
        } else {
            if (dotcore) {
                return await APIService.addToFavorites(projectID, session, token);
            } else {
                return await SOAPService.addToFavorites(projectID, session, token);
            }
        }
    },
    setCOREBackendURL: function (url: string) {
        APIService.setUrl(url);
    },
    deleteFromFavorites: async (projectID: number, session: string, token: string) => {
        if (isStoryBook) {
            // Niks doen
        } else {
            if (dotcore) {
                return await APIService.deleteFromFavorites(projectID, session, token);
            } else {
                return await SOAPService.deleteFromFavorites(projectID, session, token);
            }
        }
    },
    logonTwoStepTimebasedOneTimePassword: async (userName: string, password: string, token2FA: string) => {
        if (dotcore) {
            return await APIService.loginTwoStepTimebasedOneTimePassword(userName, password, token2FA);
        } else {
            throw new Error('Niet geimplementeerd met 4.5 backend');
        }
    },
    getFavorites: async (session: string, token: string) => {
        if (isStoryBook) {
            // Niks doen
        } else {
            if (dotcore) {
                return await APIService.getFavorites(session, token);
            } else {
                return await SOAPService.getFavorites(session, token);
            }
        }
    },
    logonssodirect: async (code: string, state: string) => {
        // if (dotcore) {
        return await APIService.logonssodirect(code, state);
        // } else {
        //     return await SOAPService.logon(username, password);
        // }
    },
    addNavItemToFavorites: async (branchId: string, leafId: string, leafName: string, session: string, token: string) => {
        if (isStoryBook) {
            // Niks doen
        } else {
            if (dotcore) {
                return await APIService.addNavItemToFavorites(branchId, leafId, leafName, session, token);
            } else {
                return await SOAPService.addNavItemToFavorites(branchId, leafId, leafName, session, token);
            }
        }
    },
    mailNewPassword: async (username: string) => {
        if (dotcore) {
            return await APIService.mailNewPassword(username);
        } else {
            return await SOAPService.mailNewPassword(username);
        }
    },
    addPersonalFolder: async (folderName: string, session: string, token: string) => {
        if (isStoryBook) {
            return await MockService.addPersonalFolder(folderName, session, token);
        } else {
            if (dotcore) {
                return await APIService.addPersonalFolder(folderName, session, token);
            } else {
                return await SOAPService.addPersonalFolder(folderName, session, token);
            }
        }
    },
    mailTwoStepTimebasedOneTimePasswordSecret: async (userName: string) => {
        if (dotcore) {
            return await APIService.mailTwoStepTimebasedOneTimePasswordSecret(userName);
        } else {
            throw new Error('Niet geimplementeerd met 4.5 backend');
        }
    },
    addToPersonalFolder: async (transactionID: number, personalFolderID: string, session: string, token: string) => {
        if (isStoryBook) {
            return await MockService.addToPersonalFolder(transactionID, personalFolderID);
        } else {
            if (dotcore) {
                return await APIService.addToPersonalFolder(transactionID, personalFolderID, session, token);
            } else {
                return await SOAPService.addToPersonalFolder(transactionID, personalFolderID, session, token);
            }
        }
    },
    getSetting: async (settingName: string, session: string, token: string) => {
        if (isStoryBook) {
            return await MockService.getSetting();
        } else {
            if (dotcore) {
                return await APIService.getSetting(settingName, session, token);
            } else {
                return await SOAPService.getSetting(settingName, session, token);
            }
        }
    },
    addRootFolder: async (branchId: string, leafId: string, leafName: string, session: string, token: string) => {
        if (isStoryBook) {
            // Niks doen
        } else {
            if (dotcore) {
                return await APIService.addRootFolder(branchId, leafId, leafName, session, token);
            } else {
                return await SOAPService.addRootFolder(branchId, leafId, leafName, session, token);
            }
        }
    },
    getUserSetting: async (settingName: string, session: string, token: string) => {
        if (isStoryBook) {
            return await MockService.getUserSetting();
        } else {
            if (dotcore) {
                return await APIService.getUserSetting(settingName, session, token);
            } else {
                return await SOAPService.getUserSetting(settingName, session, token);
            }
        }
    },
    openProject: async (projectID: string, session: string, token: string) => {
        if (isStoryBook) {
            // Niks doen
        } else {
            if (dotcore) {
                return await APIService.openProject(projectID, session, token);
            } else {
                return await SOAPService.openProject(projectID, session, token);
            }
        }
    },
    fileUpload: {
        finalizeUpload: (id: string, session: string, token: string) => {
            return new Promise((resolve, reject) => {
                APIService.finalizeUpload(id, session, token)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        startUpload: (md5String: any, session: string, token: string) => {
            return new Promise((resolve, reject) => {
                APIService.startUpload(md5String, session, token)
                    .then((response: any) => {
                        resolve(response?.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        uploadChunk: (id: string, chunk: File, session: string, token: string) => {
            return new Promise((resolve, reject) => {
                APIService.uploadChunk(id, chunk, session, token)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
    },
    projectPicker: {
        data: {},
        get projectData() {
            return this.data;
        },
        initData: (session: string, token: string) => {
            return new Promise<any>((resolve, reject) => {
                if (isStoryBook) {
                    MockService.projectList()
                        .then((response: any) => {
                            Service.projectPicker.data = response?.data;
                            resolve(response?.data);
                        })
                        .catch((error) => {
                            reject(error);
                        });
                } else {
                    if (dotcore) {
                        APIService.projectList(session, token)
                            .then((response: any) => {
                                Service.projectPicker.data = response?.data;

                                resolve(response?.data);
                            })
                            .catch((error) => {
                                reject(error);
                            });
                    } else {
                        SOAPService.projectList(session, token)
                            .then((response: any) => {
                                Service.projectPicker.data = response?.data;

                                resolve(response?.data);
                            })
                            .catch((error) => {
                                reject(error);
                            });
                    }
                }
            });
        },
        set projectData(data) {
            this.data = data;
        },
    },
    getReport: async (props: any) => {
        // if (isStoryBook) {
        //     return await MockService.getReport(params);
        // }
        if (dotcore) {
            if (/^applicationreport_\d+/gi.test(props.reportID)) return await APIService.getApplicationReport(props);
            return await APIService.getReport(props);
        } else {
            return await SOAPService.getReport(props);
        }
    },
    receiveOverviewPeriodically: async (transactionId: string, period: string, session: string, token: string) => {
        if (isStoryBook) {
            // Niks doen
        } else {
            if (dotcore) {
                return await APIService.receiveOverviewPeriodically(transactionId, period, session, token);
            } else {
                return await SOAPService.receiveOverviewPeriodically(transactionId, period, session, token);
            }
        }
    },
    getReportExport: async (data: any, params: any) => {
        // if (isStoryBook) {
        //     return await MockService.getReport(params);
        // }
        if (dotcore) {
            return await APIService.getReportExport(data, params);
        } else {
            // return await SOAPService.getReportExport(params, session, token);
        }
    },
    treeExpand: async (branchId: string, session: string, token: string) => {
        if (isStoryBook) {
            return await MockService.treeExpand(branchId);
        } else {
            if (dotcore) {
                return await APIService.treeExpand(branchId, session, token);
            } else {
                return await SOAPService.treeExpand(branchId, session, token);
            }
        }
    },
    getPdfExport: async (id: number, params: any) => {
        if (dotcore) {
            return await APIService.getPdfExport(id, params);
        } else {
            // return await SOAPService: number.getReportExport(params, session, token);
        }
    },
    treeInitialize: async (session: string, token: string) => {
        if (isStoryBook) {
            return await MockService.treeInitialize();
        } else {
            if (dotcore) {
                return await APIService.treeInitialize(session, token);
            } else {
                return await SOAPService.treeInitialize(session, token);
            }
        }
    },
    getReportMessage: async (messageID: number, session: string, token: string) => {
        if (dotcore) {
            return await APIService.getReportMessage(messageID, session, token);
        } else {
            // return await SOAPService.getReportExport(params, session, token);
        }
    },
    removeNavItemFromFavorites: async (itemId: string, session: string, token: string) => {
        if (isStoryBook) {
            // Niks doen
        } else {
            if (dotcore) {
                return await APIService.removeNavItemFromFavorites(itemId, session, token);
            } else {
                return await SOAPService.removeNavItemFromFavorites(itemId, session, token);
            }
        }
    },
    getReportProcessReportQuestions: async (
        rapportID: number,
        transactionId: number,
        messageId: number,
        questions: string,
        SessionID: string,
        TokenID: string
    ) => {
        // if (isStoryBook) {
        //     return await MockService.getReport(params);
        // }
        if (dotcore) {
            return await APIService.getReportProcessReportQuestions(rapportID, transactionId, messageId, questions, SessionID, TokenID);
        } else {
            // return await SOAPService.getReportProcessReportQuestions(props);
        }
    },
    renameNavItemInFavorites: async (favoriteId: string, newName: string, session: string, token: string) => {
        if (isStoryBook) {
            // Niks doen
        } else {
            if (dotcore) {
                return await APIService.renameNavItemInFavorites(favoriteId, newName, session, token);
            } else {
                return await SOAPService.renameNavItemInFavorites(favoriteId, newName, session, token);
            }
        }
    },
    getMessageDiffernce: async (messageID: number, session: string, token: string) => {
        if (isStoryBook) {
            // Niks doen
        } else {
            if (dotcore) {
                return await APIService.getMessageDifference(messageID, session, token);
            } else {
                // return await SOAPService.getMessageDifference(messageID, session, token);
            }
        }
    },
    removeFromPersonalFolder: async (transactionID: number, personalFolderID: string, session: string, token: string) => {
        if (isStoryBook) {
            return await MockService.removeFromPersonalFolder(transactionID, personalFolderID);
        } else {
            return await APIService.removeFromPersonalFolder(transactionID, personalFolderID, session, token);
        }
    },
    _createMessagePostSimpleElement: (se: NewMessageContentSimpleElement): MessagePostContentSimpleElement => {
        const returnSE = {} as MessagePostContentSimpleElement;
        returnSE.ID = se.ID;
        returnSE.SID = se.SID;
        returnSE.BaseType = se.BaseType;
        returnSE.DisplayValue = null;

        const basetype = returnSE.BaseType?.toUpperCase();
        const format = {
            precision: basetype === 'INTEGER' ? 0 : (se.Decimals ?? 2),
            type: 'fixedPoint',
        };

        if (se.RegExPattern != null && regExPatternEqual(se.RegExPattern, 'strictCurrencyFormatNL')) {
            // indien een se de euro regex heeft maak er dan weer een string van zodat hij wordt opgeslagen als 100.000,00 ipv 100000.00 zoals bij decimal gebeurd
            returnSE.BaseType = 'string';
            if (se.Value == null) {
                se.Value = '';
            } else {
                // hier wordt van een number een string gemaakt, dit om conversie te voorkomen in het string gedeelte op regel 759
                // als het al een string is en niet een lege string, converteren we het string getal naar een NL notatie met formatToNLLocale() helper
                se.Value = typeof se.Value === 'number' ? formatNumber(se.Value, format) : se.Value === '' ? se.Value : formatToNLLocale(se.Value);
            }
        } else {
            returnSE.BaseType = se.BaseType;
        }

        let value: string;
        let display: string;

        switch (basetype) {
            case 'DATE':
                if (se.Value !== '' && se.Value !== null) {
                    try {
                        const d = new Date(parseDate(se.Value, 'date')); // probeer de datum te parsen
                        returnSE.Value = dateLocale.format(d, 'yyyy-MM-dd') as string;
                    } catch (ex) {
                        try {
                            const d = new Date(parseDate(se.DisplayValue, 'date')); // probeer de datum te parsen
                            returnSE.Value = dateLocale.format(d, 'yyyy-MM-dd') as string;
                        } catch (exx) {
                            console.error(exx);
                        }
                    }
                } else {
                    returnSE.Value = '';
                }

                break;
            case 'BOOLEAN':
                returnSE.Value = (se.Value?.toString() === 'true').toString();
                break;
            case 'SELECT':
                returnSE.Value = se.Value;
                returnSE.DisplayValue = se.DisplayValue;

                break;
            case 'INTEGER':
            case 'DECIMAL':
                try {
                    // vanuit een tabel rij komt hij over als string
                    if (typeof se.Value === 'string')
                        value = se.Value === '' ? '0' : basetype === 'INTEGER' ? parseToNumber(se.Value, format) : formatToNLLocale(se.Value);
                    // vanuit een simpel element komt hij over als nummer
                    if (typeof se.Value === 'number') value = se.Value;

                    // if there is no value, set a string value of 0 (zero)
                    // prevents a NaN error
                    returnSE.Value = se.Value ? `${value}` : basetype === 'DECIMAL' ? '0,00' : '0';

                    display = returnSE.Value;

                    // extra check
                    if (display === 'NaN') display = basetype === 'DECIMAL' ? '0,00' : '0';

                    // TODO: Dit gaat nu stuk als de gebruiker de taal op EN heeft staan
                    returnSE.Value = basetype === 'INTEGER' ? display.replaceAll('.', '') : display; // hij gaat niet lekker om met de duizendtal scheiding
                } catch (ex) {
                    console.log('kan nummer niet omzetten?', ex);
                }

                break;
            case 'STRING':
                // There is also a situation that a CalculationColumn is a String with no Regex pattern (This is also rendered in a NumberBox)
                if (typeof se.Value === 'number') {
                    value = parseToLocaleString(se.Value);
                }

                if (
                    typeof se.Value === 'string' &&
                    regExPatternEqual(se.RegExPattern, 'optionalDecimalString') &&
                    !regExMatch(se.Value, 'optionalDecimalString')
                ) {
                    value = se.Value?.indexOf(',') === -1 ? se.Value?.replace('.', ',') : se.Value?.replaceAll('.', '');
                }

                returnSE.Value = typeof value === 'string' ? value : se.Value?.toString();

                break;
            default:
                returnSE.Value = se.Value == null ? '' : `${se.Value}`;

                // GS we sturen display value alleen mee als het een select is.
                // returnSE.DisplayValue = se.DisplayValue != null ? `${se.DisplayValue}` : returnSE.Value;
                break;
        }

        if (se.IsHTML) {
            returnSE.HTMLValue = se.DisplayValue;
            // AP:
            // The Value needs to be the same as the HTMLValue so the backend will process it correctly.
            returnSE.Value = se.HTMLValue ?? ''; // For calculation, it's important to have Value key (Als if it;s empty).
        }
        delete returnSE.BaseType;

        return returnSE;
    },
    removePersonalFolder: async (itemId: string, session: string, token: string) => {
        if (isStoryBook) {
            // Do nothing
        } else {
            if (dotcore) {
                return await APIService.removePersonalFolder(itemId, session, token);
            } else {
                return await SOAPService.removePersonalFolder(itemId, session, token);
            }
        }
    },
    get createMessagePostSimpleElement() {
        return this._createMessagePostSimpleElement;
    },

    removeRootFolder: async (leafId: string, session: string, token: string) => {
        if (isStoryBook) {
            // Niks doen
        } else {
            if (dotcore) {
                return await APIService.removeRootFolder(leafId, session, token);
            } else {
                return await SOAPService.removeRootFolder(leafId, session, token);
            }
        }
    },
    createMessagePost: (
        content: NewMessageContent,
        request: NewMessageContentRequest,
        initiator: string,
        subject: string,
        executor: string[],
        SharePointActive?: boolean,
        ThinkProjectActive?: boolean,
        lastCheckBeforeSend: boolean = false
    ): Promise<MessagePostContent> => {
        return new Promise<MessagePostContent>((resolve, reject) => {
            // maak messagepostcontent aan
            const messagePostContent = {} as MessagePostContent;

            if (containsDangerousHTML(subject)) {
                const FIELD_NAME = formatMessage('vs-TransactionOverview-Name');
                const ERROR_MESSAGE = {
                    response: {
                        data: formatMessage('vs-Message-Error-SendMessage-Contains-Dangerous-Characters-Field', FIELD_NAME),
                    },
                };

                APIService.handleErrors(ERROR_MESSAGE);
                return reject(ERROR_MESSAGE);
            }

            const { sharepointFileInfos, targetAppendices, thinkprojectFileInfos, uploadedFilesIds } = preparePostAppendices(
                content?.Appendices,
                reject
            );

            // We need to send the Initiators' SIDs based on the selected Executors if the InitiatorSID is not null
            const preparedInitiatorsArray = prepareInitiatorPersoonRolSIDs(content?.Executors, executor);

            messagePostContent.Appendices = targetAppendices;
            messagePostContent.CalculationSettings = content?.CalculationSettings || null;
            messagePostContent.ConceptID = content?.ConceptID ?? 0;
            messagePostContent.Content = preparePostContent(content?.ComplexElements, lastCheckBeforeSend, reject);
            messagePostContent.ExecutorPersoonRolSIDs =
                content?.ExecutorsFromSimpleElementSID != null && lastCheckBeforeSend ? setExecutorsFromSimpleElements(content) : executor;
            messagePostContent.Files = uploadedFilesIds;
            messagePostContent.Gemachtigde = null; // Old implementation, not needed
            messagePostContent.Geometry = content?.Geometry || null;
            messagePostContent.InitiatorPersoonRolSID = initiator || null;
            messagePostContent.InitiatorPersoonRolSIDs = preparedInitiatorsArray;
            messagePostContent.IsSharePointActive = SharePointActive;
            messagePostContent.IsSubTransaction = false; // It is always false, only true if the transaction should be created outside the workflow e.g. CRM => BS Backoffice => Organisatie => button `subtransactie`.
            messagePostContent.IsThinkprojectActive = ThinkProjectActive;
            messagePostContent.LuaSettings = content?.LuaSettings || null;
            messagePostContent.MessageDirection = request?.MessageDirection;
            messagePostContent.MITID = content?.MITID; // MITID must be coming from the /api/message/new
            messagePostContent.ReplyToMessageID = request?.ReplyToMessageID ?? 0;
            messagePostContent.SharepointFileInfos = sharepointFileInfos;
            messagePostContent.ThinkprojectFileInfos = thinkprojectFileInfos;
            messagePostContent.SoapID = lastCheckBeforeSend ? generateID('SOAP') : null; // self generated ID
            messagePostContent.Subject = subject;
            messagePostContent.TransactionID = content?.TransactionID ?? 0;
            messagePostContent.UpdateToLatestFramework = content?.UpdateToLatestFramework;

            return resolve(messagePostContent);
        });
    },
    set createMessagePostSimpleElement(value) {
        this._createMessagePostSimpleElement = value;
    },
    newMessageContent: (params: any, session: string, token: string) => {
        return new Promise((resolve, reject) => {
            if (isStoryBook) {
                MockService.newMessageContent(params).then((resp: any) => {
                    resolve(resp.data);
                });
            } else {
                if ((dotcore as unknown) === true) {
                    APIService.newMessageContent(params, session, token)
                        .then((response: any) => {
                            if (response?.data) {
                                const mc = response?.data as NewMessageContent;

                                mc.Appendices.forEach((ap) => {
                                    const decimalsKolommen = [];
                                    const integerKolommen = [];

                                    ap.Columns.forEach((c) => {
                                        // tabellen kunnen nog niet lekker met omgezette velden omgaan
                                        reguliereExpressieInterprator(c);
                                        if (c.BaseType.toUpperCase() === 'DECIMAL') decimalsKolommen.push(c.SID);
                                        if (c.BaseType.toUpperCase() === 'INTEGER') integerKolommen.push(c.SID);
                                    });
                                    ap.Data?.forEach((dataRij) => {
                                        dataRij.forEach((dataKolom) => {
                                            if (decimalsKolommen.indexOf(dataKolom.SID) !== -1 && dataKolom.Value?.length > 0) {
                                                // Before formatting make sure the string value is formated to internationl notation for decimal numbers
                                                dataKolom.Value = dataKolom.Value.replaceAll('.', '');
                                                dataKolom.Value = dataKolom.Value.replace(',', '.');

                                                /* formatNumber geeft het terug als 100,50 (honderd komma vijftig) */
                                                dataKolom.Value = formatNumber(
                                                    parseFloat(dataKolom.Value),
                                                    toDataFormat('DECIMAL', dataKolom.Decimals ?? 2)
                                                );
                                            }
                                            if (integerKolommen.indexOf(dataKolom.SID) !== -1 && dataKolom.Value?.length > 0) {
                                                /* formatNumber geeft het terug als 100 (honderd) */
                                                dataKolom.Value = formatNumber(parseInt(dataKolom.Value), toDataFormat('DECIMAL', 0));
                                            }
                                        });
                                    });
                                });

                                mc.ComplexElements.forEach((ce) => {
                                    ce.Tables.forEach((t) => {
                                        const decimalsKolommen = [];
                                        const integerKolommen = [];

                                        t.Columns.forEach((c) => {
                                            // tabellen kunnen nog niet lekker met omgezette velden omgaan
                                            reguliereExpressieInterprator(c);
                                            if (c.BaseType.toUpperCase() === 'DECIMAL') decimalsKolommen.push(c.SID);
                                            if (c.BaseType.toUpperCase() === 'INTEGER') integerKolommen.push(c.SID);
                                        });
                                        t.Data?.forEach((dataRij) => {
                                            dataRij.forEach((dataKolom) => {
                                                if (decimalsKolommen.indexOf(dataKolom.SID) !== -1 && dataKolom.Value?.length > 0) {
                                                    // Before formatting make sure the string value is formated to internationl notation for decimal numbers
                                                    dataKolom.Value = dataKolom.Value.replaceAll('.', '');
                                                    dataKolom.Value = dataKolom.Value.replace(',', '.');

                                                    /* formatNumber geeft het terug als 100,50 (honderd komma vijftig) */
                                                    dataKolom.Value = formatNumber(
                                                        parseFloat(dataKolom.Value),
                                                        toDataFormat('DECIMAL', dataKolom.Decimals ?? 2)
                                                    );
                                                }
                                                if (integerKolommen.indexOf(dataKolom.SID) !== -1 && dataKolom.Value?.length > 0) {
                                                    /* formatNumber geeft het terug als 100 (honderd) */
                                                    dataKolom.Value = formatNumber(parseInt(dataKolom.Value), toDataFormat('DECIMAL', 0));
                                                }
                                            });
                                        });
                                    });
                                    ce.SimpleElements.forEach((se) => {
                                        reguliereExpressieInterprator(se);
                                    });
                                });
                                resolve(mc);
                            }
                        })
                        .catch((error) => {
                            reject(error);
                        });
                } else {
                    SOAPService.newMessageContent(params, session, token)
                        .then((response: any) => {
                            resolve(response?.data?.result);
                        })
                        .catch((error) => {
                            reject(error);
                        });
                }
            }
        });
    },
    conceptWrite: async (ConceptID: number, session: string, token: string) => {
        return new Promise((resolve, reject) => {
            if (isStoryBook) {
                return new Promise((resolve, reject) => {
                    resolve(true); /* geen probleem voor mock */
                });
            } else {
                if (dotcore) {
                    APIService.getConcept({ ConceptID }, session, token)
                        .then((response: any) => {
                            resolve(response?.data);
                        })
                        .catch((error) => {
                            reject(error);
                        });
                } else {
                    SOAPService.messageConceptWrite({ ConceptID }, session, token)
                        .then((response: any) => {
                            resolve(response?.data);
                        })
                        .catch((error) => {
                            reject(error);
                        });
                }
            }
        });
    },
    getConceptId: async (replyMessageID: number, session: string, token: string) => {
        if (isStoryBook) {
            // Niks doen
        }

        if (dotcore) {
            return await APIService.getConceptId(replyMessageID, session, token);
        } else {
            return await SOAPService.getConceptId(replyMessageID, session, token);
        }
    },
    getReportTransaction: async (TransactionID: number, session: string, token: string) => {
        if (dotcore) {
            return await APIService.getReportTransaction(TransactionID, session, token);
        } else {
            // return await SOAPService.getReportExport(params, session, token);
        }
    },
    getMessageId: async (transactionId: number, session: string, token: string): Promise<AxiosResponse<number, any>> => {
        if (dotcore) {
            return await APIService.getMessageId(transactionId, session, token);
        }
    },
    messageContent: {
        data: {
            message: { MessageID: 0, TransactionID: 0 },
        },
        initData: async function (params: any, session: string, token: string) {
            return new Promise<MessageContent>((resolve, reject) => {
                if (isStoryBook) {
                    MockService.messageContent(params).then((resp: any) => {
                        resolve(resp);
                    });
                } else {
                    if ((dotcore as unknown) === true) {
                        if (params.ConceptID != null) {
                            APIService.messageView(params, session, token).then((resp: any) => {
                                resolve(resp?.data);
                            });
                        } else {
                            APIService.paramsData(params, session, token).then((response) => {
                                APIService.messageView(
                                    params.messnr ? { ...params, messageID: params.messnr } : { ...params, messageID: response?.messageID },
                                    session,
                                    token
                                ).then((resp: any) => {
                                    resolve(resp?.data);
                                });
                            });
                        }
                    } else {
                        if (params == null) return;

                        if (params.ConceptID != null) {
                            SOAPService.messageConcept(params, session, token).then((response) => {
                                console.log('SOAPService.messageContent(params).then', response);
                                if ((response.data as any).success === false) {
                                    reject((response.data as any).message);
                                } else {
                                    resolve(response.data);
                                }
                            });
                        } else {
                            SOAPService.messageContent(params, session, token).then((response) => {
                                if ((response?.data as any).success === undefined) {
                                    resolve(response.data);
                                } else if ((response.data as any).success === false) {
                                    reject((response.data as any).message);
                                } else {
                                    reject(new Error('Onbekende fout'));
                                }
                            });
                        }
                    }
                }
            });
        },
        get messageData() {
            return this.data;
        },
        set messageData(data) {
            if (data.result) {
                this.data.message = data.result.Message;
            } else {
                this.data.message = data;
            }
        },
    },
    getTransactionListBasedOn: async (basedOnData: AuxTransactionList, session: string, token: string) => {
        if (isStoryBook) {
            return await MockService.getTransactionListBasedOn();
        } else {
            if (dotcore) {
                return await APIService.getTransactionListBasedOn(basedOnData, session, token);
            } else {
                return await SOAPService.getTransactionListBasedOn(basedOnData, session, token);
            }
        }
    },
    getElementListBasedOn: async (
        sourceElementBasedOn: string[],
        filterElementID: number,
        filterElementValue: string,
        transactionSID: string,
        session: string,
        token: string
    ) => {
        if (!isStoryBook && dotcore) {
            return await APIService.getElementListBasedOn(sourceElementBasedOn, filterElementID, filterElementValue, transactionSID, session, token);
        }
    },

    getReportTemplateFiles: async ({
        messageID,
        rapportID,
        SessionID,
        TokenID,
        transactionID,
    }: {
        messageID: number;
        rapportID: Sjabloon['Id'];
        SessionID: string;
        TokenID: string;
        transactionID: number;
    }): Promise<{ data: ReportDocument | null }> => {
        if (dotcore) {
            try {
                return await APIService.getReportTemplateFiles({ messageID, rapportID, SessionID, TokenID, transactionID });
            } catch (error) {
                throw new Error(`Failed to get report template files: ${error}`);
            }
        }
        return null;
    },
    messageList: async (params: any, session: string, token: string) => {
        // console.log(params);
        return new Promise<ReplyTransaction[]>((resolve, reject) => {
            if (isStoryBook) {
                MockService.messageList(params).then((resp: any) => {
                    resolve(resp?.data);
                });
            } else {
                if (dotcore) {
                    APIService.messageList(params, session, token)
                        .then((response: any) => {
                            resolve(response?.data as ReplyTransaction[]);
                        })
                        .catch((error) => {
                            reject(error);
                        });
                } else {
                    SOAPService.messageList(params, session, token)
                        .then((response: any) => {
                            resolve(response?.data as ReplyTransaction[]);
                        })
                        .catch((error) => {
                            reject(error);
                        });
                }
            }
        });
    },
    calculateLuaScript: async (
        LuaScriptId: string,
        messageData: MessagePostContent,
        sourceNewMessageContentData: NewMessageContent,
        session: string,
        token: string
    ) => {
        // prepare message data for calculation api
        const processedMessageData = await processMessagePostCalculationData(messageData, sourceNewMessageContentData);

        if (isStoryBook) {
            // Niks doen
        } else {
            if (dotcore) {
                return await APIService.calculateLuaScript(LuaScriptId, processedMessageData, session, token);
            } else {
                return await SOAPService.calculateLuaScript(LuaScriptId, processedMessageData, session, token);
            }
        }
    },
    removeConcept: async (ConceptID: number, session: string, token: string) => {
        return new Promise((resolve, reject) => {
            if (isStoryBook) {
                return new Promise((resolve, reject) => {
                    resolve(true); /* geen probleem voor mock */
                });
            } else {
                if (dotcore) {
                    APIService.removeConcept({ ConceptID }, session, token)
                        .then((response: any) => {
                            resolve(response.data);
                        })
                        .catch((error) => {
                            reject(error);
                        });
                } else {
                    SOAPService.removeConcept({ ConceptID }, session, token)
                        .then((response: any) => {
                            resolve(response.data);
                        })
                        .catch((error) => {
                            reject(error);
                        });
                }
            }
        });
    },
    calculateMessage: async (
        lineNo: number,
        elementSID: string,
        messageData: MessagePostContent,
        sourceNewMessageContentData: NewMessageContent,
        session: string,
        token: string
    ) => {
        // prepare message data for calculation api
        const processedMessageData = await processMessagePostCalculationData(messageData, sourceNewMessageContentData);

        if (isStoryBook) {
            // Niks doen
        } else {
            if (dotcore) {
                return await APIService.calculateMessage(lineNo, elementSID, processedMessageData, session, token);
            } else {
                return await SOAPService.calculateMessage(lineNo, elementSID, processedMessageData, session, token);
            }
        }
    },
    tabTransactions: (params: any, session: string, token: string) => {
        return new Promise((resolve, reject) => {
            if ((dotcore as unknown) === true) {
                APIService.tabTransactions(params, session, token)
                    .then((response: any) => {
                        resolve(response?.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            } else {
                SOAPService.tabTransactions(params, session, token)
                    .then((response) => {
                        resolve(response?.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            }
        });
    },
    changePassword: async (username: string, oldPassword: string, newPassword: string, sessoin: string, token: string) => {
        if (dotcore) {
            return await APIService.changePassword(username, oldPassword, newPassword, sessoin, token);
        } else {
            return await SOAPService.changePassword(username, oldPassword, newPassword, sessoin, token);
        }
    },
    downloadFile: (bestandsID: string, bestandsnaam: string, toPDF = false, inline = false, inNewTab = false) => {
        if (isStoryBook) return;
        let url = '';
        if (dotcore) {
            url =
                location.hostname === 'localhost'
                    ? `${APIService.getUrl()}/api/v1/file/downloadbyid?inline=${inline}&toPDF=${toPDF}&visifileid=${bestandsID}`
                    : `${APIService.getUrl()}/download/${bestandsID}/${inline}/${toPDF}/${bestandsnaam}`;
        } else {
            url = '/Download.ashx?id=' + btoa(bestandsnaam) + '&file=' + bestandsID;
        }

        if (inNewTab) {
            window.open(url, bestandsnaam.replaceAll(/[^a-zA-Z0-9]/g, ''));
        } else {
            window.location.href = url;
        }
    },
    saveConcept: async (
        content: NewMessageContent,
        request: NewMessageContentRequest,
        initiator: string,
        subject: string,
        executor: string[],
        ConceptID: number,
        session: string,
        token: string,
        SharePointActive?: boolean,
        ThinkProjectActive?: boolean
    ) => {
        return await Service.createMessagePost(content, request, initiator, subject, executor, SharePointActive, ThinkProjectActive)
            .then((messagePostContent) => {
                messagePostContent.ConceptID = content?.ConceptID ? content?.ConceptID : ConceptID;
                // Do not save attachments in concept
                messagePostContent.Appendices = [];

                return new Promise((resolve, reject) => {
                    if (isStoryBook) {
                        return new Promise((resolve, reject) => {
                            resolve(true); /* geen probleem voor mock */
                        });
                    } else {
                        if (dotcore) {
                            return APIService.saveConcept({ bericht: messagePostContent }, session, token)
                                .then((response: any) => {
                                    return resolve(response?.data);
                                })
                                .catch((error) => {
                                    return reject(error);
                                });
                        } else {
                            SOAPService.saveConcept({ bericht: messagePostContent }, session, token)
                                .then((response: any) => {
                                    resolve(response?.data);
                                })
                                .catch((error) => {
                                    reject(error);
                                });
                        }
                    }
                });
            })
            .catch((error) => {
                throw error;
            });
    },
    downloadTmpFile: (downloadID: string, fileName: string, inNewTab = true) => {
        if (isStoryBook) return;
        let url = '';
        if (dotcore) {
            url =
                location.hostname === 'localhost'
                    ? `${APIService.getUrl()}/api/v1/file/downloadtempfile?downloadId=${downloadID}&fileName=${fileName}`
                    : `${APIService.getUrl()}/downloadtempfile/${downloadID}/${fileName}`;
        }

        if (inNewTab) {
            return new Promise((resolve) => {
                window.open(url, fileName.replaceAll(/[^a-zA-Z0-9]/g, ''));
                // JA 30-7-2024: The setTimeout and Promise resolve were added to ensure the new tab is fully opened before the promise is resolved. This way, finishDownload is not called prematurely in the downloadMessage function in toolbarbuttons.utils.ts, which would result in the file being removed/finished and then it cannot be found.
                setTimeout(() => {
                    resolve('New tab has been opened');
                }, 500);
            });
        } else {
            window.location.href = url;
        }
    },

    sendMessage: async (
        content: NewMessageContent,
        request: NewMessageContentRequest,
        initiator: string,
        subject: string,
        executor: string[],
        session: string,
        token: string,
        SharePointActive?: boolean,
        ThinkProjectActive?: boolean
    ) => {
        const lastCheckBeforeSend = true;
        return await Service.createMessagePost(
            content,
            request,
            initiator,
            subject,
            executor,
            SharePointActive,
            ThinkProjectActive,
            lastCheckBeforeSend
        )
            .then((messagePostContent) => {
                // console.log('sendMessage payload: ', { messagePostContent });

                return new Promise((resolve, reject) => {
                    if (isStoryBook) {
                        return new Promise((resolve, reject) => {
                            resolve(true); /* geen probleem voor mock */
                        });
                    } else {
                        if (dotcore) {
                            // Als we een bericht versturen wat niet werkt in de web en wel in de windows versie
                            // dan kunnen we het verschil bekijken tussen wat we versturen door het winfront te lokaal te draaien
                            // en een breakpunt zetten op \BSVisiWinfront4\VisiCommunication\RestBackend.cs
                            // regel 124:
                            // public int PostMessage(MessagePostContent messagePostContent)
                            // {
                            //     return post<int>("message/post", messagePostContent);
                            // }
                            // bericht zou hetzelfde moeten zijn als messagePostContent
                            return APIService.sendMessage({ bericht: messagePostContent }, session, token)
                                .then((response: any) => {
                                    return resolve(response?.data);
                                })
                                .catch((error) => {
                                    return reject(error);
                                });
                        } else {
                            SOAPService.sendMessage({ bericht: messagePostContent }, session, token)
                                .then((response: any) => {
                                    resolve(response?.data);
                                })
                                .catch((error) => {
                                    reject(error);
                                });
                        }
                    }
                });
            })
            .catch((error) => {
                throw error;
            });
    },
    downloadAttachmentsInZip: (transactionId: number, appendixId: number) => {
        if (isStoryBook) return;

        if (dotcore) {
            const url =
                location.hostname === 'localhost'
                    ? `${APIService.getUrl()}/api/v1/attachmentsinzip?transactionIds=${transactionId}&appendixid=${appendixId}`
                    : `${APIService.getUrl()}/downloadAll/${transactionId}/${appendixId}`;

            window.location.href = url;
        } else {
            // niks doen
        }
    },
    getBackendInfo: async (session: string, token: string) => {
        if (isStoryBook) {
            return await MockService.getBackendInfo();
        }
        if (dotcore) {
            return await APIService.getBackendInfo(session, token);
        } else {
            return await SOAPService.getBackendInfo(session, token);
        }
    },
    setMessageRead: (params: any, session: string, token: string) => {
        return new Promise((resolve, reject) => {
            if ((dotcore as unknown) === true) {
                // transactionRead api
                APIService.setMessageRead(params, session, token)
                    .then(() => {
                        resolve('transaction is set as read');
                    })
                    .catch((error) => {
                        reject(error);
                    });
            } else {
                SOAPService.setMessageRead(params, session, token)
                    .then(() => {
                        resolve('transaction is set as read');
                    })
                    .catch((error) => {
                        reject(error);
                    });
            }
        });
    },
    convertCsvToTable: async <NewMessageContentTable>(bestand: any, params: any) => {
        try {
            const zipBase64 = await APIService.convertCsvToTable(bestand, params);

            if (zipBase64 != null && typeof zipBase64.status === 'number' && zipBase64.status === 200) {
                return zipBase64 as NewMessageContentTable;
            } else {
                const errorMessage = typeof zipBase64 === 'string' ? zipBase64 : 'Fout bij het converteren van tabel';
                throw errorMessage;
            }
        } catch (ex) {
            console.error('error', ex);
            throw ex;
        }
    },
    transactieOverzicht: {
        getOverzicht: async (navigatieLeafID: string, filterString: any, session: string, token: string, pageNumber?: number) => {
            // console.log({ filterString, navigatieLeafID });
            let result = null as any;
            if (isStoryBook) {
                result = await MockService.transaction_list(navigatieLeafID, filterString);
            } else {
                // bij CORE alijd api/search
                if (dotcore) {
                    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                    // !!                           LET OP
                    // !! het orderby attribuut wordt misbruikt om de filter data mee te geven
                    // !! hier wordt dus NIET transaction_filterlist gebruikt, die is om globaal te zoeken
                    // !!
                    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

                    result = ((await APIService.transaction_list(navigatieLeafID, filterString, session, token, pageNumber, true)) as any)?.data;
                } else {
                    result = ((await SOAPService.transaction_list(navigatieLeafID, filterString, session, token)) as any)?.data;
                }
            }

            // zie https://git.bakkerspees.nl/dev/Visiweb4.5/-/wikis/Backend-optimalisations
            // concept overzicht die geeft records terug met ID = -1

            if (navigatieLeafID === 'ConceptenLeaf') {
                (result as any)?.TransactionRecords.forEach((record: TransactionRecord) => {
                    record.ID = record.ConceptID;
                    record.IsLeaf = true;
                });
            }

            return result;
        },
        expandBranch: async (TransactionRecordBranchID: number, session: string, token: string) => {
            if (isStoryBook) {
                return await MockService.transaction_expand(TransactionRecordBranchID);
            } else {
                if ((dotcore as unknown) === true) {
                    return await APIService.transaction_expand(TransactionRecordBranchID, session, token);
                } else {
                    return await SOAPService.transaction_expand(TransactionRecordBranchID, session, token);
                }
            }
        },
        canSetTransactionUnread: async (transactionID: number, session: string, token: string) => {
            if (isStoryBook) {
                // Niks doen
            } else {
                if (dotcore) {
                    return await APIService.canSetTransactionUnread(transactionID, session, token);
                } else {
                    return await SOAPService.canSetTransactionUnread(transactionID, session, token);
                }
            }
        },
        setTransactionUnread: async (selectedRecordID: number, session: string, token: string) => {
            if (isStoryBook) {
                return await MockService.setTransactionUnread();
            } else {
                if ((dotcore as unknown) === true) {
                    return await APIService.setTransactionUnread(selectedRecordID, session, token);
                } else {
                    return await SOAPService.setTransactionUnread(selectedRecordID, session, token);
                }
            }
        },
    },
    config: null,
    setTransactionReady: (params: any, session: string, token: string) => {
        return new Promise((resolve, reject) => {
            if ((dotcore as unknown) === true) {
                // transactionReady api
                APIService.setTransactionReady(params, session, token)
                    .then(() => {
                        resolve(formatMessage('vs-Service-SetAsReady'));
                    })
                    .catch((error) => {
                        reject(error);
                    });
            } else {
                SOAPService.setTransactionReady(params, session, token)
                    .then(() => {
                        resolve(formatMessage('vs-Service-SetAsReady'));
                    })
                    .catch((error) => {
                        reject(error);
                    });
            }
        });
    },
    convertTableToExcel: async (data: any, params: any) => {
        try {
            const zipBase64 = await APIService.convertTableToExcel(data, params);

            if (zipBase64 != null && typeof zipBase64.status === 'number' && zipBase64.status === 200) {
                return zipBase64;
            } else {
                const errorMessage = typeof zipBase64 === 'string' ? zipBase64 : 'Fout bij het converteren van tabel';
                throw errorMessage;
            }
        } catch (ex) {
            console.error('error', ex);
            throw ex;
        }
    },
    getDownloadFile: async (fileID: string, session: string, token: string) => {
        if (isStoryBook) return;
        const resp = await APIService.getDownloadFile(fileID, session, token);
        return resp;
    },
    getFlowChar: async (transactionID: number, isCompact: boolean, session: string, token: string) => {
        try {
            const flowChartBASE64 = dotcore
                ? await APIService.getFlowChar(transactionID, isCompact, session, token)
                : await SOAPService.getFlowChar(transactionID, isCompact, session, token);

            if (flowChartBASE64 != null && typeof flowChartBASE64.status === 'number' && flowChartBASE64.status === 200) {
                return flowChartBASE64.data;
            } else {
                const errorMessage = typeof flowChartBASE64 === 'string' ? flowChartBASE64 : 'Fout bij het ophalen van Flowchart';
                throw errorMessage;
            }
        } catch (ex) {
            console.error('error', ex);
            throw ex;
        }
    },
    showCompleteTransaction: async (transactionID: number, session: string, token: string, isRootTransaction: boolean) => {
        if (isStoryBook) {
            // Nog niks doen
        }

        if (dotcore) {
            try {
                const completeTransactionRes = await APIService.showCompleteTransaction(transactionID, session, token, isRootTransaction);

                if (completeTransactionRes === undefined) {
                    return false;
                }

                const completeTransaction: CompleteTransaction = {
                    TransactionRecords: [{ ...completeTransactionRes?.data }],
                };

                return completeTransaction;
            } catch (error) {
                return error;
            }
        } else {
            return await SOAPService.showCompleteTransaction(transactionID, session, token);
        }
    },
    getFlowChart: async (transactionTypeID: number, isCompact: boolean, session: string, token: string) => {
        try {
            const flowChartBASE64 = dotcore
                ? await APIService.getFlowChart(transactionTypeID, isCompact, session, token)
                : await SOAPService.getFlowChart(transactionTypeID, isCompact, session, token);

            if (flowChartBASE64 != null && typeof flowChartBASE64.status === 'number' && flowChartBASE64.status === 200) {
                return flowChartBASE64.data;
            } else {
                const errorMessage = typeof flowChartBASE64 === 'string' ? flowChartBASE64 : 'Fout bij het ophalen van Flowchart';
                throw errorMessage;
            }
        } catch (ex) {
            console.error('error', ex);
            throw ex;
        }
    },
    logout: async (session: string, token: string) => {
        try {
            if (isStoryBook) return;

            const projectPickerSize = sessionStorage.getItem('projectPickerSize');

            if (projectPickerSize?.length > 0) {
                await Service.addUserSetting('projectPickerSize', projectPickerSize, session, token);
            }

            const selectionListPopupSize = sessionStorage.getItem('selectionListPopupSize');

            if (selectionListPopupSize?.length > 0) {
                await Service.addUserSetting('selectionListPopupSize', selectionListPopupSize, session, token);
            }

            if (dotcore) {
                return await APIService.logout(session, token);
            } else {
                return await SOAPService.logout(session, token);
            }
        } catch (error) {
            console.error({ error });
        } finally {
            const tabsData = sessionStorage.getItem('tabsData');

            if (tabsData?.length > 0) {
                const TabList: TabItem[] = JSON.parse(tabsData)?.TabList;

                // Unmount aleady opened react instances before logout
                for (const tab of TabList) {
                    unmountReactTabInstance(tab.value);
                }
            }

            sessionStorage.clear();
        }
    },
    saveTransactionInZip: async (messageID: number, sessionId: string, tokenId: string) => {
        if (dotcore) {
            return await APIService.saveTransactionInZip(messageID, sessionId, tokenId);
        } else {
            const url = '/Download.ashx?messageid=' + messageID;
            window.location.href = url;
        }
    },
    // relogin: async () => {
    //     return await SOAPService.relogin();
    // },
};
const configParam: any = {
    method: 'get',
    url: './config.json',
};
let configBack = null;
const config = async () => {
    if (configBack !== null) return configBack;
    configBack = await axios(configParam);
    return configBack;
};
export { Service, config };
