import axios from 'axios';

// #region -------------------------  COMMENT THESE LINES BEFORE PRODUCTION BUILD ----------------------------
import { longList } from '../../components/ProjectPicker/data/data';
import { records, expand } from '../../panels/TransactionOverview/TransactionOverviewAdvanced/data/data';
import { data, messageContentData } from '../../panels/NewMessage/data/data';
import { expandTree, mockData } from '../../panels/Navigation/data/data';
import { messageListData } from '../../panels/MessageContainer/data/data';
// #endregion -------------------------  COMMENT THE ABOVE LINES BEFORE PRODUCTION BUILD ---------------------

import { Visi } from '../Globals/Globals';
import { HelpContent } from './ServiceReference/HelpContent';

const url = '';

const MockService = {
    getAdditionalContent: async (url) => {
        // return new Promise(function (resolve, reject) {
        //     // #region -------------------------  COMMENT THESE LINES BEFORE PRODUCTION BUILD ----------------------------
        //     resolve({ TransactionRecords: records });
        //     // #endregion -------------------------  COMMENT THE ABOVE LINES BEFORE PRODUCTION BUILD ---------------------
        // });
    },
    getAdditionalMedia: async (url) => {
        // return new Promise(function (resolve, reject) {
        //     // #region -------------------------  COMMENT THESE LINES BEFORE PRODUCTION BUILD ----------------------------
        //     resolve({ TransactionRecords: records });
        //     // #endregion -------------------------  COMMENT THE ABOVE LINES BEFORE PRODUCTION BUILD ---------------------
        // });
    },
    transaction_list: async (TransactionListID: string, orderby: string, paginaInteger?: number) => {
        return new Promise(function (resolve, reject) {
            // #region -------------------------  COMMENT THESE LINES BEFORE PRODUCTION BUILD ----------------------------
            resolve({ TransactionRecords: records });
            // #endregion -------------------------  COMMENT THE ABOVE LINES BEFORE PRODUCTION BUILD ---------------------
        });
    },
    transaction_expand: async (TransactionRecordBranchID) => {
        return new Promise(function (resolve, reject) {
            resolve({
                // # startregion -------------------------  COMMENT THESE LINES BEFORE PRODUCTION BUILD ----------------------
                TransactionRecords: expand(TransactionRecordBranchID),
                // #endregion -------------------------  COMMENT THE ABOVE LINES BEFORE PRODUCTION BUILD ---------------------
            });
        });
    },
    setTransactionUnread: async () => {
        return new Promise(function (resolve, reject) {
            resolve({});
        });
    },
    addToFavorites: async (ID) => {
        const requestParams = {
            params: {
                method: 'AddToFavorites',
                projectID: ID,
                BackendSessionId: Visi.Constants.SESSION,
                BackendTokenID: Visi.Constants.TOKEN,
            },
            headers: {
                'Content-Type': 'application/json',
            },
        };

        try {
            return await axios.get(url, requestParams);
        } catch (error) {
            console.log(error);
        }
    },
    deleteFromFavorites: async (ID) => {
        const requestParams = {
            params: {
                method: 'DeleteFromFavorites',
                projectID: ID,
                BackendSessionId: Visi.Constants.SESSION,
                BackendTokenID: Visi.Constants.TOKEN,
            },
            headers: {
                'Content-Type': 'application/json',
            },
        };

        try {
            return await axios.get(url, requestParams);
        } catch (error) {
            console.log(error);
        }
    },
    projectList: async () => {
        return new Promise(function (resolve, reject) {
            // #region -------------------------  COMMENT THESE LINES BEFORE PRODUCTION BUILD ----------------------------
            resolve({ data: longList });
            // #endregion -------------------------  COMMENT THE ABOVE LINES BEFORE PRODUCTION BUILD ---------------------
        });
    },

    tabTransactions: async (params) => {
        const requestParams = {
            params: {
                method: 'get_tabtransactions',
                BackendSessionID: Visi.Constants.SESSION,
                BackendTokenID: Visi.Constants.TOKEN,
                transnr: params.transnr,
                transactiontypeid: params.transactiontypeid[0],
            },
            headers: {
                'Content-Type': 'application/json',
            },
        };

        try {
            return await axios.get(url, requestParams);
        } catch (error) {
            console.log(error);
        }
    },
    transactionRead: async (params) => {
        const requestParams = {
            params: {
                method: 'message_setread',
                messnr: params,
                BackendSessionID: Visi.Constants.SESSION,
                BackendTokenID: Visi.Constants.TOKEN,
            },
        };

        try {
            return await axios.post(url, requestParams);
        } catch (error) {
            console.log(error);
        }
    },

    transactionReady: async (params) => {
        const requestParams = {
            params: {
                transnr: 'TransactionNumber',
                method: 'transaction_setready',
                BackendSessionID: Visi.Constants.SESSION,
                BackendTokenID: Visi.Constants.TOKEN,
            },
        };

        try {
            return await axios.post(url, requestParams);
        } catch (error) {
            console.log(error);
        }
    },
    newMessageContent: async (params) => {
        return new Promise(function (resolve, reject) {
            // #region -------------------------  COMMENT THESE LINES BEFORE PRODUCTION BUILD ----------------------------
            resolve({ data: data.result });
            // #endregion -------------------------  COMMENT THE ABOVE LINES BEFORE PRODUCTION BUILD ---------------------
        });
    },
    sendMessage: async (params) => {
        if (params.bericht == null) return 'geen bericht param meegegeven';

        const requestParams: any = {
            method: 'post',
            data: {
                method: 'post_typed_message',
                BackendSessionId: Visi.Constants.SESSION,
                BackendTokenID: Visi.Constants.TOKEN,
                bericht: JSON.stringify(params.bericht),
            },
            headers: {
                'Content-Type': 'application/json',
            },
            url: url + '?method=post_typed_message',
        };

        try {
            return await axios(requestParams);
        } catch (error) {
            console.log(error);
        }
    },
    messageContent: async (params) => {
        // geef een random record terug
        return new Promise((resolve, reject) => {
            // #region -------------------------  COMMENT THESE LINES BEFORE PRODUCTION BUILD ----------------------------
            setTimeout(
                () => {
                    resolve(messageContentData[Math.floor(Math.random() * messageContentData?.length)]);
                },
                Math.floor(Math.random() * 2200) + 700
            );
            // #endregion -------------------------  COMMENT THE ABOVE LINES BEFORE PRODUCTION BUILD ---------------------
        });
    },
    getTransactionListBasedOn: async () => {
        const data = {
            '75152': 'Algemeen IT',
            '74909': 'Glen de Man',
            '1962': 'Peter Jansen',
        };

        return new Promise(function (resolve, reject) {
            resolve({ data });
        });
    },
    getBackendInfo: async () => {
        const data: HelpContent = {
            Version: '4.5.0.146',
            Server: 'test45a.bakkerspees.nl (visi01)',
            DatabaseVersion: '182',
            Bits: 32,
            IsValidSession: true,
            InfoLine1: null,
            InfoLine2: null,
            InfoLine3: null,
            AllowLogon: 'False',
        };

        return new Promise(function (resolve, reject) {
            resolve({ data });
        });
    },
    treeInitialize: async () => {
        return new Promise(function (resolve, reject) {
            // #region -------------------------  COMMENT THESE LINES BEFORE PRODUCTION BUILD ----------------------------
            resolve({ data: mockData });
            // #endregion -------------------------  COMMENT THE ABOVE LINES BEFORE PRODUCTION BUILD ---------------------
        });
    },
    treeExpand: async (branchId) => {
        // #region -------------------------  COMMENT THESE LINES BEFORE PRODUCTION BUILD ----------------------------
        const data = expandTree(branchId);
        // #endregion -------------------------  COMMENT THE ABOVE LINES BEFORE PRODUCTION BUILD ---------------------

        return new Promise(function (resolve, reject) {
            // #region -------------------------  COMMENT THESE LINES BEFORE PRODUCTION BUILD ----------------------------
            resolve({ data });
            // #endregion -------------------------  COMMENT THE ABOVE LINES BEFORE PRODUCTION BUILD ---------------------
        });
    },
    messageList: async (params) => {
        return new Promise(function (resolve, reject) {
            // #region -------------------------  COMMENT THESE LINES BEFORE PRODUCTION BUILD ----------------------------
            resolve({ data: messageListData });
            // #endregion -------------------------  COMMENT THE ABOVE LINES BEFORE PRODUCTION BUILD ---------------------
        });
    },
    getUserSetting: async () => {
        const data = { result: 'nl-NL' };

        return new Promise(function (resolve, reject) {
            resolve({ data });
        });
    },
    addUserSetting: async () => {
        const data = { result: 'nl-NL' };

        return new Promise(function (resolve, reject) {
            resolve({ data });
        });
    },
    getSetting: async () => {
        const data = '["BerichtenBranch","AllTransactionsLeaf"]';

        return new Promise(function (resolve, reject) {
            resolve({ data });
        });
    },
    addPersonalFolder: async (folderNam: string, session: string, token: string) => {
        return new Promise(function (resolve, reject) {
            resolve({
                TransactionRecords: records[Math.floor(Math.random() * records.length)],
            });
        });
    },
    addToPersonalFolder: async (transactionID: any, persoonlijkMapID: any) => {
        return new Promise(function (resolve, reject) {
            resolve({
                TransactionRecords: records[Math.floor(Math.random() * records.length)],
            });
        });
    },
    removeFromPersonalFolder: async (transactionID: any, persoonlijkMapID: any) => {
        return new Promise(function (resolve) {
            resolve({
                TransactionRecords: records[Math.floor(Math.random() * records.length)],
            });
        });
    },
};

export default MockService;
