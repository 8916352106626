const toDataFormat = (baseType: string, decimals?: number) => {
    switch (baseType.toLowerCase()) {
        case 'time':
            return 'HH:mm';

        case 'datetime':
            return 'dd-MM-yyyy HH:mm';

        case 'date':
            return 'dd-MM-yyyy';

        case 'decimal':
            return {
                type: 'fixedPoint',
                precision: decimals,
            };

        default:
            return undefined;
    }
};

export default toDataFormat;
