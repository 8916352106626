// specifieke isIpad detectie, deze pakt ook  iPad ios 13, zie https://stackoverflow.com/questions/57776001/how-to-detect-ipad-pro-as-ipad-using-javascript#answer-74543423

export const isIpad = () => {
    return (
        /* GS dit is deprecated maar wordt wel als oplossing gegeven @https://developer.mozilla.org/en-US/docs/Web/API/Navigator/platform voor apple detectie */
        ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
};
