import { Dispatch, MutableRefObject } from 'react';
import { formatMessage } from 'devextreme/localization';

// REFS
import { CompleteTransaction } from '../../utils/Service/ServiceReference/TransactionRecord';
import { ContainerHandle } from '../../panels/MessageContainer/Container';
import { TabItem } from '../../state/context/TabsContext';

// SERVICES
import { Service } from '../../utils/Service/Service';

// UTILS
import { getInvoiceData } from '../../panels/MessageContainer/RenderMiscTab.utils';

const IS_STORYBOOK = location.href.indexOf('story') > -1;

/**
 * Creates a new tab for a complete transaction scenario.
 *
 * @param {string} targetRootID - The ID of the target root transaction.
 * @param {string} sessionId - The session ID.
 * @param {string} tokenId - The token ID.
 * @param {Dispatch<any>} dispatchTabsState - A dispatch function for managing tabs.
 * @param {boolean} [isLinkIdOpened=false] - Indicates whether the tab is opened by a hyperlink.
 * @param {boolean} [isRootTransaction=false] - A flag indicating whether the transaction is a root transaction.
 * @param {boolean} [rootTransactionName] - A flag indicating whether to use the root transaction name.
 * @param {string} [contentName] - Holds the name that should be used for the tab text.
 * @returns {void} Return void
 */
export const createTabCompleteTransaction = async (
    targetRootID: string,
    sessionId: string,
    tokenId: string,
    dispatchTabsState: Dispatch<any>,
    isLinkIdOpened: boolean = false,
    isRootTransaction: boolean = false,
    rootTransactionName?: boolean,
    contentName?: string
) => {
    try {
        const response: CompleteTransaction = await Service.showCompleteTransaction(parseInt(targetRootID), sessionId, tokenId, isRootTransaction);
        const { ID, Name: rootName, Parent } = response.TransactionRecords[0];

        const parentID = Parent.length ? Parent : ID;

        const text = IS_STORYBOOK
            ? 'Complete transactie'
            : rootTransactionName
              ? rootName
              : contentName?.length > 0
                ? contentName
                : formatMessage('vs-NavigationItem-Transacton-Complete');

        const newTab: TabItem = {
            closeable: true,
            fullSize: false,
            icon: 'vs-icon-message-open',
            id: `tab-item-transaction-complete-${parentID}`,
            isLinkIdOpened,
            rootTransaction: response.TransactionRecords[0],
            text,
            type: 'transactionComplete',
            value: parentID.toString(),
        };

        dispatchTabsState({
            type: 'ADD_TAB',
            payload: newTab,
        });
    } catch (error) {
        console.error({ error });
    }
};

/**
 * Creates and adds a new tab for an invoice transaction.
 *
 * @param {any} data - The data required to fetch the invoice information.
 * @param {string} SessionId - The session identifier.
 * @param {string} TokenId - The token identifier.
 * @param {Dispatch<any>} dispatchTabsState - The state dispatcher function for tabs.
 * @returns {Promise<void>} A Promise that resolves when the tab is created and added successfully.
 */
export const createTabInvoiceTransaction = async (data: any, SessionId: string, TokenId: string, dispatchTabsState: Dispatch<any>): Promise<void> => {
    try {
        // Fetch invoice data
        const invoiceDataResponse = await getInvoiceData(data, SessionId, TokenId);

        // destrucutre the message data response
        const { ID, Subject, TransactionID } = invoiceDataResponse;

        const newTab: TabItem = {
            closeable: true,
            fullSize: true,
            icon: 'vs-icon-new-tab',
            id: `tab-item-invoice-transaction-${ID}`,
            messageContent: invoiceDataResponse,
            text: Subject,
            type: 'messageInNewTab',
            value: `messageInNewTab_${TransactionID}`,
        };

        dispatchTabsState({
            type: 'ADD_TAB',
            payload: newTab,
        });
    } catch (error) {
        console.error({ error });
    }
};

/**
 * Updates the message content in the read panel.
 *
 * @param {any} loadMessageParams - The parameters required to load the message.
 * @param {TabItem} tabHolder - The tab item that holds the message content.
 * @param {Dispatch<any>} dispatchTabsState - The state dispatcher function for tabs.
 * @param {MutableRefObject<ContainerHandle>} refMessageContainer - The reference to the message container.
 * @returns {void} - Return void
 */
export const definingReadpanelMessageContent = (
    loadMessageParams: any,
    tabHolder: TabItem,
    dispatchTabsState: Dispatch<any>,
    refMessageContainer: MutableRefObject<ContainerHandle>
) => {
    // reset the message tab panel
    if (loadMessageParams === null) {
        dispatchTabsState({
            type: 'SET_MESSAGE_CONTENT',
            payload: { tabHolder, messageContent: null },
        });
    } else {
        // update the message tab panel
        refMessageContainer?.current
            ?.loadMessage(loadMessageParams)
            .then((response) => {
                dispatchTabsState({
                    type: 'SET_MESSAGE_CONTENT',
                    payload: { tabHolder, messageContent: response },
                });
            })
            .catch((error) => console.error(error));
    }
};
