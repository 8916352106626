export const setSessionStorageProperty = (setting: string, value: any) => {
    const userData = JSON.parse(sessionStorage.getItem('userData')) ? JSON.parse(sessionStorage.getItem('userData')) : {};
    userData[setting] = value;
    return sessionStorage.setItem('userData', JSON.stringify(userData));
};

export const getSessionStorageProperty = (setting: string) => {
    let userData = sessionStorage.getItem('userData');

    if (!userData) return console.log('userData sessionStorage is still empty');

    userData = JSON.parse(userData);
    return userData[setting];
};
