/**
 * parseToLocaleString
 * @param {number} value e.g. 12.3 "12,30"
 * @returns {string} converts number to a string with a nl-NL representation of the number e.g. 12.3 => '12,30'
 */
export const parseToLocaleString = (value: number, locale: string = 'nl-NL'): string => {
    if (Number.isNaN(value)) return '';

    return value?.toLocaleString(locale, { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 });
};

/**
 * Formats a given string number to the nl-NL locale format.
 * The input string can be in international notation or already in nl-NL notation.
 *
 * @param {string} input - The input string representing a (decimal) number.
 * @returns {string} - The formatted number in nl-NL locale.
 * @throws {Error} - Throws an error if the input string cannot be converted to a valid number.
 */
export const formatToNLLocale = (input: string) => {
    /**
     * Normalizes the input string by handling different decimal and thousands separators.
     *
     * @param {string} str - The input string to normalize.
     * @returns {string} - The normalized string suitable for number conversion.
     */
    const normalizeInput = (str) => {
        // Handle numbers with commas as decimal separators and periods as thousands separators
        if (str.includes(',')) {
            if (str.match(/\.\d{3},/)) {
                // Replace periods with nothing (thousands separators)
                str = str.replace(/\./g, '');
            }
            // Replace comma with a period (decimal separator)
            str = str.replace(/,/g, '.');
        }
        return str;
    };

    /**
     * Formats the normalized number to nl-NL locale.
     *
     * @param {number} number - The number to format.
     * @returns {string} - The formatted number in nl-NL locale.
     */
    const formatNumber = (number) => {
        return number.toLocaleString('nl-NL', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    };

    // Normalize the input string
    const normalizedInput = normalizeInput(input);

    // Convert the normalized string to a number
    const number = parseFloat(normalizedInput);

    // Check if the conversion is successful
    if (isNaN(number)) {
        throw new Error('Invalid input string for number conversion');
    }

    // Format the number to nl-NL locale
    return formatNumber(number);
};
