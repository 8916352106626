export const shortList = {
    ProjectsToDo: true,
    ProjectsUnread: true,
    LastOpenedProjectID: 49,
    List: [
        {
            Favorite: true,
            ID: 329,
            Name: '_IAS - testomgeving nieuw raamwerk',
            SID: '_IAS_testomgeving_nieuw_raamwerk',
            TotalTeBehandelen: 0,
            TotalUnread: 0,
        },
        {
            Favorite: false,
            ID: 305,
            Name: '45oct',
            SID: 'v45oct',
            TotalTeBehandelen: 8,
            TotalUnread: 3,
        },
        {
            Favorite: false,
            ID: 152,
            Name: 'Amsterdam - AI 2016-334 NDSM - West dempen kop NDSM kanaal',
            SID: 'Amsterdam_AI_2016_-_334_NDSM_West_dempen_kop_NDSM_kanaal',
            TotalTeBehandelen: 1,
            TotalUnread: 0,
        },
        {
            Favorite: true,
            ID: 283,
            Name: 'Amsterdam - AI 2017-0193 - Bouwrijpmaken Centrumeiland IJburg',
            SID: 'Amsterdam_AI_2017-0193_-_Bouwrijpmaken_Centrumeiland_IJburg',
            TotalTeBehandelen: 6,
            TotalUnread: 2,
        },
        {
            Favorite: false,
            ID: 324,
            Name: 'april2019',
            SID: 'april2019',
            TotalTeBehandelen: 16,
            TotalUnread: 10,
        },
        {
            Favorite: false,
            ID: 384,
            Name: 'ArneSoapGeneriekGCTestNummer',
            SID: 'ArneSoapGeneriekGCTestNummer',
            TotalTeBehandelen: 4,
            TotalUnread: 4,
        },
        {
            Favorite: false,
            ID: 6,
            Name: 'asdf',
            SID: 'Project134',
            TotalTeBehandelen: 0,
            TotalUnread: 0,
        },
        {
            Favorite: true,
            ID: 304,
            Name: 'asdfqwer',
            SID: 'asdfqwer',
            TotalTeBehandelen: 0,
            TotalUnread: 0,
        },
        {
            Favorite: false,
            ID: 228,
            Name: 'Assen - Herinrichting Veemarktterrein',
            SID: 'Assen_-_Herinrichting_Veemarktterrein',
            TotalTeBehandelen: 0,
            TotalUnread: 0,
        },
        {
            Favorite: false,
            ID: 400,
            Name: 'Bakker&Spees Soap controle omgeving',
            SID: 'BakkerSpeesSoapControleOmgeving',
            TotalTeBehandelen: 1,
            TotalUnread: 0,
        },
        {
            Favorite: false,
            ID: 429,
            Name: 'BakkerSpees - SystemsEngineering',
            SID: 'Roosendaal_-_testprojectSystemsEngineering_Test_9',
            TotalTeBehandelen: 1,
            TotalUnread: 1,
        },
    ],
};

export const longList = {
    ProjectsToDo: true,
    ProjectsUnread: true,
    LastOpenedProjectID: 49,
    List: [
        {
            Favorite: true,
            ID: 329,
            Name: '_IAS - testomgeving nieuw raamwerk',
            SID: '_IAS_testomgeving_nieuw_raamwerk',
            TotalTeBehandelen: 0,
            TotalUnread: 0,
        },
        {
            Favorite: false,
            ID: 305,
            Name: '45oct',
            SID: 'v45oct',
            TotalTeBehandelen: 8,
            TotalUnread: 3,
        },
        {
            Favorite: false,
            ID: 152,
            Name: 'Amsterdam - AI 2016-334 NDSM - West dempen kop NDSM kanaal',
            SID: 'Amsterdam_AI_2016_-_334_NDSM_West_dempen_kop_NDSM_kanaal',
            TotalTeBehandelen: 1,
            TotalUnread: 0,
        },
        {
            Favorite: true,
            ID: 283,
            Name: 'Amsterdam - AI 2017-0193 - Bouwrijpmaken Centrumeiland IJburg',
            SID: 'Amsterdam_AI_2017-0193_-_Bouwrijpmaken_Centrumeiland_IJburg',
            TotalTeBehandelen: 6,
            TotalUnread: 2,
        },
        {
            Favorite: false,
            ID: 324,
            Name: 'april2019',
            SID: 'april2019',
            TotalTeBehandelen: 16,
            TotalUnread: 10,
        },
        {
            Favorite: false,
            ID: 384,
            Name: 'ArneSoapGeneriekGCTestNummer',
            SID: 'ArneSoapGeneriekGCTestNummer',
            TotalTeBehandelen: 4,
            TotalUnread: 4,
        },
        {
            Favorite: false,
            ID: 6,
            Name: 'asdf',
            SID: 'Project134',
            TotalTeBehandelen: 0,
            TotalUnread: 0,
        },
        {
            Favorite: true,
            ID: 304,
            Name: 'asdfqwer',
            SID: 'asdfqwer',
            TotalTeBehandelen: 0,
            TotalUnread: 0,
        },
        {
            Favorite: false,
            ID: 228,
            Name: 'Assen - Herinrichting Veemarktterrein',
            SID: 'Assen_-_Herinrichting_Veemarktterrein',
            TotalTeBehandelen: 0,
            TotalUnread: 0,
        },
        {
            Favorite: false,
            ID: 400,
            Name: 'Bakker&Spees Soap controle omgeving',
            SID: 'BakkerSpeesSoapControleOmgeving',
            TotalTeBehandelen: 1,
            TotalUnread: 0,
        },
        {
            Favorite: false,
            ID: 429,
            Name: 'BakkerSpees - SystemsEngineering',
            SID: 'Roosendaal_-_testprojectSystemsEngineering_Test_9',
            TotalTeBehandelen: 1,
            TotalUnread: 1,
        },
        {
            Favorite: false,
            ID: 439,
            Name: 'Beheerraamwerk Test over Soap2',
            SID: 'Test_Beheerraamwerk_Soap2',
            TotalTeBehandelen: 3,
            TotalUnread: 3,
        },
        {
            Favorite: false,
            ID: 157,
            Name: 'Breda - Reconstructie Lunetstraat',
            SID: 'Breda_-_Reconstructie_Lunetstraat_nieuwe_versie',
            TotalTeBehandelen: 0,
            TotalUnread: 0,
        },
        {
            Favorite: false,
            ID: 126,
            Name: 'Breda - Sjabloonproject UAV 2017',
            SID: 'Breda_-_Sjabloonproject_UAV2012_2017',
            TotalTeBehandelen: 1,
            TotalUnread: 0,
        },
        {
            Favorite: false,
            ID: 161,
            Name: 'BSinternKijkRechtenTest - geen BERICHTEN IN VERSTUREN!!!!!!!!!!!!!!!!',
            SID: 'BSinternKijkRechtenTest',
            TotalTeBehandelen: 2,
            TotalUnread: 1,
        },
        {
            Favorite: false,
            ID: 49,
            Name: 'BSinterntestraamwerk1.4',
            SID: 'BSinterntestraamwerk1_4',
            TotalTeBehandelen: 268,
            TotalUnread: 132,
        },
        {
            Favorite: false,
            ID: 105,
            Name: 'BStestProRail - M-004334 BBV Oost Brabant 2016xBStest',
            SID: 'BStestProRail_-_M-004334_BBV_Oost_Brabant_2016_BStest',
            TotalTeBehandelen: 30,
            TotalUnread: 25,
        },
        {
            Favorite: false,
            ID: 110,
            Name: 'BSTestProRail NL Stabiel RANDSTAD ZUIDBSTest',
            SID: 'BSTestProRail_NL_Stabiel_RANDSTAD_ZUIDBSTest',
            TotalTeBehandelen: 10,
            TotalUnread: 0,
        },
        {
            Favorite: false,
            ID: 217,
            Name: 'DEMO_VISI_16_pizzeria_test_1',
            SID: 'DEMO_VISI_16_pizzeria_test_1',
            TotalTeBehandelen: 0,
            TotalUnread: 0,
        },
        {
            Favorite: false,
            ID: 210,
            Name: 'DenHaag - UAV - OMOP - Groot Asfaltonderhoud 2017',
            SID: 'DenHaag_-_UAV_OMOP_Groot_Asfaltonderhoud_2017',
            TotalTeBehandelen: 1,
            TotalUnread: 0,
        },
        {
            Favorite: false,
            ID: 431,
            Name: 'Gemeente Roosendaal 2021-SPIE OVL Beheercontract - testproject',
            SID: 'Gemeente_Roosendaal_000000_OVL_Beheercontract_testproject',
            TotalTeBehandelen: 0,
            TotalUnread: 0,
        },
        {
            Favorite: false,
            ID: 156,
            Name: 'Gortstraat test project',
            SID: 'Gortstraat',
            TotalTeBehandelen: 0,
            TotalUnread: 0,
        },
        {
            Favorite: false,
            ID: 254,
            Name: 'Havenbedrijf Moerdijk - AM - proefproject',
            SID: 'Moerdijk_AM_proefproject',
            TotalTeBehandelen: 2,
            TotalUnread: 2,
        },
        {
            Favorite: false,
            ID: 142,
            Name: 'HHRijnland - UAVGC - VIJG',
            SID: 'HHR_UAVGC_VIJG',
            TotalTeBehandelen: 11,
            TotalUnread: 5,
        },
        {
            Favorite: false,
            ID: 151,
            Name: 'kistdam',
            SID: 'kistdam',
            TotalTeBehandelen: 2,
            TotalUnread: 1,
        },
        {
            Favorite: false,
            ID: 116,
            Name: 'Lekkanaal/ 3e kolk Beatrixsluis',
            SID: 'PRO_e8759195-bfb7-442e-a551-96d8e073599c',
            TotalTeBehandelen: 0,
            TotalUnread: 0,
        },
        {
            Favorite: false,
            ID: 77,
            Name: 'Metaproject VISI 1.6 test',
            SID: 'Metaproject1_6test2',
            TotalTeBehandelen: 8,
            TotalUnread: 4,
        },
        {
            Favorite: false,
            ID: 374,
            Name: 'MiniSoap',
            SID: 'MiniSoap',
            TotalTeBehandelen: 16,
            TotalUnread: 15,
        },
        {
            Favorite: false,
            ID: 108,
            Name: 'N18 Groenlo - Enschede',
            SID: 'PRO1aa5d404-fa26-4965-a8e8-66ea1ff4c00b',
            TotalTeBehandelen: 0,
            TotalUnread: 0,
        },
        {
            Favorite: false,
            ID: 330,
            Name: 'netcore_19_juni_2019',
            SID: 'netcore_19_juni_2019',
            TotalTeBehandelen: 16,
            TotalUnread: 15,
        },
        {
            Favorite: false,
            ID: 174,
            Name: 'OberKokPeter16test',
            SID: 'OberKokPeter16test',
            TotalTeBehandelen: 2,
            TotalUnread: 1,
        },
        {
            Favorite: false,
            ID: 355,
            Name: 'OpvolgSoapFout2',
            SID: 'OpvolgSoapFout2',
            TotalTeBehandelen: 2,
            TotalUnread: 1,
        },
        {
            Favorite: false,
            ID: 362,
            Name: 'OpvolgSoapFoutB',
            SID: 'OpvolgSoapFoutB',
            TotalTeBehandelen: 0,
            TotalUnread: 0,
        },
        {
            Favorite: false,
            ID: 85,
            Name: 'PNH - HOV - Schiphol-Oost',
            SID: 'PNH_-_HOV_-_Schiphol_-_Oost',
            TotalTeBehandelen: 1,
            TotalUnread: 1,
        },
        {
            Favorite: false,
            ID: 202,
            Name: 'Port_of_Rotterdam_Offshore_terminal_SIF_Verbrugge_Diepzeekade',
            SID: 'Port_of_Rotterdam_Offshore_terminal_SIF_Verbrugge_Diepzeekade',
            TotalTeBehandelen: 0,
            TotalUnread: 0,
        },
        {
            Favorite: false,
            ID: 106,
            Name: 'PROCES 00055 ProRail Acc',
            SID: 'proces_00055_BSdemo',
            TotalTeBehandelen: 1,
            TotalUnread: 0,
        },
        {
            Favorite: false,
            ID: 118,
            Name: 'Project Top Kok 12',
            SID: 'Project12',
            TotalTeBehandelen: 1,
            TotalUnread: 1,
        },
        {
            Favorite: false,
            ID: 263,
            Name: 'ProRail - R-537000 - Amsterdam Amstel e.o',
            SID: 'ProRail_-_R-537000_-_Amsterdam_AmstelEO',
            TotalTeBehandelen: 2,
            TotalUnread: 0,
        },
        {
            Favorite: false,
            ID: 346,
            Name: 'ProRail Project  L-123456 Spoorverdubbeling station Amsterdam Centraal',
            SID: 'ProRail_Proces77_testomgeving9BSsoap',
            TotalTeBehandelen: 8,
            TotalUnread: 6,
        },
        {
            Favorite: false,
            ID: 147,
            Name: 'ProRail VISI 4 oefenproject',
            SID: 'ProRail_-_R-497600_-_AAK_Keermuur_Amsterdam_Amstel',
            TotalTeBehandelen: 0,
            TotalUnread: 0,
        },
        {
            Favorite: false,
            ID: 194,
            Name: 'Prov. Friesland - 17-28-WN - N369 Harkema - Kootstertille -  Oversteken - GO test',
            SID: 'Prov_Friesland_-_17-28-WN_-_N369_Harkema_-_Kootstertille_test',
            TotalTeBehandelen: 0,
            TotalUnread: 0,
        },
        {
            Favorite: false,
            ID: 248,
            Name: 'ProvosImportTest',
            SID: 'ProvosImportTest',
            TotalTeBehandelen: 4,
            TotalUnread: 4,
        },
        {
            Favorite: false,
            ID: 169,
            Name: 'PSB1_2OverschrijfTest',
            SID: 'PSB1_2OverschrijfTest',
            TotalTeBehandelen: 0,
            TotalUnread: 0,
        },
        {
            Favorite: false,
            ID: 225,
            Name: 'rdam_maastunnel_relatics_test',
            SID: 'rdam_maastunnel_relatics_test',
            TotalTeBehandelen: 2,
            TotalUnread: 1,
        },
        {
            Favorite: false,
            ID: 411,
            Name: 'regmaart2020',
            SID: 'regmaart2020',
            TotalTeBehandelen: 15,
            TotalUnread: 15,
        },
        {
            Favorite: false,
            ID: 300,
            Name: 'RVB - Keuringen en Onderhoud Sportaccommodaties - Perceel 1 (regio Noord) - test coordinaten',
            SID: 'RVB_Keuringen_Sportaccomodaties_Noord_oefenomgeving5_coordinaten_test',
            TotalTeBehandelen: 7,
            TotalUnread: 3,
        },
        {
            Favorite: false,
            ID: 317,
            Name: 'Schiphol - NOK - testomgeving - 20190313',
            SID: 'Schiphol_UAV_NOK_testomgevnig',
            TotalTeBehandelen: 0,
            TotalUnread: 0,
        },
        {
            Favorite: false,
            ID: 316,
            Name: 'Schiphol - UAVGC - NOK - testproject - BakkerSpees',
            SID: 'Schiphol_UAVGC_testproject_BakkerSpees',
            TotalTeBehandelen: 0,
            TotalUnread: 0,
        },
        {
            Favorite: false,
            ID: 285,
            Name: 'TenneT - Wintrack II NW',
            SID: 'TenneT_Wintrack_II_NW',
            TotalTeBehandelen: 0,
            TotalUnread: 0,
        },
        {
            Favorite: false,
            ID: 83,
            Name: 'TEST NOV2016',
            SID: 'TEST_NOV2016',
            TotalTeBehandelen: 2,
            TotalUnread: 1,
        },
        {
            Favorite: false,
            ID: 418,
            Name: 'Test123Rijst',
            SID: 'Test123',
            TotalTeBehandelen: 60,
            TotalUnread: 59,
        },
        {
            Favorite: false,
            ID: 181,
            Name: 'TestCRMachtig',
            SID: 'TestCRMachtig',
            TotalTeBehandelen: 0,
            TotalUnread: 0,
        },
        {
            Favorite: false,
            ID: 141,
            Name: 'Testframework met doorstuurregels',
            SID: 'Testframework_met_doorstuurregels',
            TotalTeBehandelen: 30,
            TotalUnread: 11,
        },
        {
            Favorite: false,
            ID: 284,
            Name: 'Testproject_raamwerk_editor_training_2018_02_07',
            SID: 'Testproject_raamwerk_editor_training_2018_02_07',
            TotalTeBehandelen: 4,
            TotalUnread: 0,
        },
        {
            Favorite: false,
            ID: 99,
            Name: 'Top Koks 2016_12_23d',
            SID: 'TopKoks2016_12_23d',
            TotalTeBehandelen: 0,
            TotalUnread: 0,
        },
    ],
};

export default { shortList, longList };
