import Guid from 'devextreme/core/guid';
import dxDataGrid from 'devextreme/ui/data_grid';

export const pasteExcelToTable = async (e: ClipboardEvent) => {
    // Get pasted data via clipboard API

    const clipboardData = e.clipboardData || (window as any).clipboardData; /* safari code */

    const pastedData = clipboardData.getData('text');
    const activeElementTagName = document.activeElement?.tagName;

    // return if we are in an input or textarea or if the pasted data does not contain a tab e.g. comes from an excel file
    if (activeElementTagName === 'INPUT' || activeElementTagName === 'TEXTAREA' || !pastedData.includes('\t')) {
        return;
    }

    // BS.dropTableID wordt geset in panels\WritePage\WriteTable\WriteTable.tsx:770
    const htmlDivTable = document.getElementById((window as any)?.BS?.dropTableID);
    let dataGrid;

    if (htmlDivTable != null) {
        dataGrid = dxDataGrid.getInstance(htmlDivTable);
    }

    const activeTableStore = dataGrid ? dataGrid.getDataSource() : null;

    // Do whatever with pasteddata
    if (activeTableStore != null) {
        const table = dataGrid;
        const datagridColumns = table.getVisibleColumns().filter((C) => C.type !== 'buttons');
        const toBePastedData = pastedData.split(/\r\n/);
        const store = activeTableStore.store();

        const datagridColumnNames = datagridColumns.map((p) => p.caption);

        if (toBePastedData.length === 0) return; // als er niets inzit stop ermee

        // check of de eerste rij overeenkomt met kolomnamen
        const firstRowColumnNames = toBePastedData[0].split(/\t/);
        const columnMapper = {}; // gebruik een object als reverse lookup
        if (
            firstRowColumnNames.length === datagridColumnNames.length &&
            /* check of de kolommen overeen komen en vul de kolomMapper */
            datagridColumnNames.every((p) => {
                const index = firstRowColumnNames.findIndex((r) => r === p);
                columnMapper[index] = p;
                columnMapper[p] = index; // vul de column mapper

                return index !== -1;
            })
        ) {
            // de eerste rij bevat de kolomnamen, verwijder de eerste rij
            toBePastedData.splice(0, 1);
        } else {
            // onbekende kolommen, ga ervan uit dat de kolommen op volgorde staan
            let count = -1;
            datagridColumnNames.forEach((d) => {
                columnMapper[count++] = d;
                columnMapper[d] = count;
            });
        }

        toBePastedData.forEach(async (rowFromToBePastedData) => {
            const newRecord = { __KEY__: new Guid().valueOf() };

            const columns = rowFromToBePastedData.split(/\t/);

            // voeg de kolomnaam toe gebasseerd op volgorde (we weten niet wat de naam is, we hebben alleen waardes)
            if (columns.length > 0 && !(columns.length === 1 && columns[0] === '')) {
                for (let i = 0; i < datagridColumns.length; i++) {
                    const targetColumn = datagridColumns.find((t) => columnMapper[i] === t.caption);
                    if (targetColumn == null) continue; // als hij niet bestaat ga naar de volgende

                    if (targetColumn.dataType.toUpperCase() === 'SELECT') {
                        // check of de waarde bestaat
                        const foundValue = targetColumn.lookup.dataSource.store.data.find((c) => c.value === columns[i]);

                        if (foundValue != null) {
                            newRecord[targetColumn.dataField] = foundValue.key;
                        } else {
                            newRecord[targetColumn.dataField] = columns[i];
                        }
                    } else {
                        newRecord[targetColumn.dataField] = columns[i];
                    }
                }

                store.insert(newRecord);
            }
        });
        table.refresh(true);
    }
};
