import { Format, parseNumber, locale } from 'devextreme/localization';
import { parseToLocaleString } from '../ParseToLocaleString/parseToLocaleString';

/**
 * Parses a localized numeric string into a numeric value based on the specified format.
 *
 * @param {string} value - The localized numeric string to parse, e.g., "12,30".
 * @param {Format} [format=defaultParseFormat] - The format configuration for parsing (optional).
 * @returns {number} - The numeric representation of the input value, e.g., the input "12,30" becomes 12.30
 */
const defaultParseFormat: Format = {
    type: 'fixedPoint',
    // W0011 - Number parsing is invoked while the parser is not defined. See: http://js.devexpress.com/error/23_1/W0011
    parser: (value: string) => {
        // If value is still a number, put it in a string.
        value = `${value}`;

        // Get the current locale
        const localeSetting = locale();
        let parsedValue: number;

        switch (localeSetting) {
            // TODO: Now is working with `nl-NL`, later for other languages e.g. `en-GB` (not working yet)
            case 'en-GB':
                value = value.replaceAll(',', '');
                parsedValue = parseInt(value);
                parsedValue = parseFloat(parseToLocaleString(parsedValue, 'en-GB'));
                break;
            case 'nl-NL':
            default:
                value = value.replaceAll('.', '');
                value = value.replace(',', '.');
                parsedValue = parseFloat(value);
                break;
        }

        return parsedValue;
    },
    formatter: (value: number) => {
        return value.toString().replace('.', ',');
    },
};

export const parseToNumber = (value: string, format: Format = defaultParseFormat): number => {
    // If value is still a number, put it in a string.
    value = `${value}`;

    const nr: number = parseNumber(value, format);
    return nr;
};
