import { TreeItem } from '../../../utils/Service/ServiceReference/reference';

const data = JSON.parse(
    '{"Items":[{"Bold":false,"Children":[],"ClickID":null,"Clickable":false,"ID":"PersoonlijkeMap","IconCls":null,"IsLeaf":false,"IsRootFolder":true,"Name":"Persoonlijke mappen","NewTransactionPossible":false,"ParentID":null,"Period":null,"Selectable":false},{"Bold":false,"Children":[],"ClickID":null,"Clickable":false,"ID":"crmItemsBranch","IconCls":null,"IsLeaf":false,"IsRootFolder":false,"Name":"Items","NewTransactionPossible":false,"ParentID":null,"Period":null,"Selectable":false},{"Bold":false,"Children":[],"ClickID":null,"Clickable":false,"ID":"BerichtenBranch","IconCls":"TreeMail","IsLeaf":false,"IsRootFolder":false,"Name":"Berichten","NewTransactionPossible":false,"ParentID":null,"Period":null,"Selectable":false},{"Bold":false,"Children":[],"ClickID":null,"Clickable":false,"ID":"FavorietenBranch","IconCls":"Favorites","IsLeaf":false,"IsRootFolder":false,"Name":"Favorieten","NewTransactionPossible":false,"ParentID":null,"Period":null,"Selectable":false},{"Bold":false,"Children":[],"ClickID":null,"Clickable":false,"ID":"RapportageBranch","IconCls":"TreeReport","IsLeaf":false,"IsRootFolder":false,"Name":"Overzichten","NewTransactionPossible":false,"ParentID":null,"Period":null,"Selectable":false},{"Bold":false,"Children":[],"ClickID":null,"Clickable":false,"ID":"DossierBranch","IconCls":"TreeDossier","IsLeaf":false,"IsRootFolder":false,"Name":"Dossier","NewTransactionPossible":false,"ParentID":null,"Period":null,"Selectable":false},{"Bold":false,"Children":[],"ClickID":null,"Clickable":false,"ID":"ProjectbeheerBranch","IconCls":"Automatic","IsLeaf":false,"IsRootFolder":false,"Name":"Projectbeheer","NewTransactionPossible":false,"ParentID":null,"Period":null,"Selectable":false}]}'
);

export const mockData: { Items: TreeItem[] } = data;

export const startPath: string[] = ['crmItemsBranch', 'AllCrmItemsLeaf'];
export const startPath2: string[] = [
    'DossierBranch',
    'AlgemeneMappen',
    'AlgemeneMappen+UDT Jaarnummer  optioneel',
    'AlgemeneMappen+UDT Jaarnummer  optioneel_&_se155=2000',
];

export const expandTree = (branchId: string) => {
    let data: any[];
    const randomItems = [];
    const randomStrings = ['Item 1', 'Overzicht', 'Rapport'];

    switch (branchId) {
        case 'PersoonlijkeMap':
            data = [
                {
                    Bold: false,
                    Children: [],
                    ClickID: null,
                    Clickable: false,
                    ID: 'pers_101',
                    IconCls: null,
                    IsLeaf: true,
                    IsRootFolder: false,
                    Name: 'bijlage test',
                    NewTransactionPossible: false,
                    ParentID: 'PersoonlijkeMap',
                    Period: null,
                    Selectable: false,
                },
            ];
            break;
        case 'crmItemsBranch':
            data = [
                {
                    Bold: false,
                    Children: [],
                    ClickID: null,
                    Clickable: false,
                    ID: 'AllCrmItemsLeaf',
                    IconCls: null,
                    IsLeaf: true,
                    IsRootFolder: false,
                    Name: 'Alle transacties',
                    NewTransactionPossible: false,
                    ParentID: 'crmItemsBranch',
                    Period: null,
                    Selectable: false,
                },
                {
                    Bold: false,
                    Children: [],
                    ClickID: null,
                    Clickable: false,
                    ID: 'crmItemsBranch_tra22',
                    IconCls: null,
                    IsLeaf: true,
                    IsRootFolder: false,
                    Name: 'Backoffice 1: basis isform transactie',
                    NewTransactionPossible: true,
                    ParentID: 'crmItemsBranch',
                    Period: null,
                    Selectable: false,
                },
                {
                    Bold: false,
                    Children: [],
                    ClickID: null,
                    Clickable: false,
                    ID: 'crmItemsBranch_traBackofficeLaag1aanmaak',
                    IconCls: null,
                    IsLeaf: true,
                    IsRootFolder: false,
                    Name: 'Backoffice 2: Laag 1 keuzelijst aanmaak',
                    NewTransactionPossible: true,
                    ParentID: 'crmItemsBranch',
                    Period: null,
                    Selectable: false,
                },
                {
                    Bold: false,
                    Children: [],
                    ClickID: null,
                    Clickable: false,
                    ID: 'crmItemsBranch_traBackofficeLaag2aanmaak',
                    IconCls: null,
                    IsLeaf: true,
                    IsRootFolder: false,
                    Name: 'Backoffice 2: Laag 2 keuzelijst aanmaak',
                    NewTransactionPossible: true,
                    ParentID: 'crmItemsBranch',
                    Period: null,
                    Selectable: false,
                },
                {
                    Bold: false,
                    Children: [],
                    ClickID: null,
                    Clickable: false,
                    ID: 'crmItemsBranch_traBackofficeLaag3aanmaak',
                    IconCls: null,
                    IsLeaf: true,
                    IsRootFolder: false,
                    Name: 'Backoffice 2: Laag 3 keuzelijst aanmaak',
                    NewTransactionPossible: true,
                    ParentID: 'crmItemsBranch',
                    Period: null,
                    Selectable: false,
                },
            ];
            break;
        default:
            for (let i = 0; i < Math.random() * 10; i++) {
                randomItems.push({
                    Bold: false,
                    Children: [],
                    ClickID: null,
                    Clickable: false,
                    ID: 'random' + Math.random(),
                    IconCls: null,
                    IsLeaf: Math.random() > 0.5,
                    IsRootFolder: false,
                    Name: randomStrings[Math.floor(Math.random() * randomStrings.length)],
                    NewTransactionPossible: false,
                    ParentID: branchId,
                    Period: null,
                    Selectable: false,
                });
            }
            data = randomItems;
            break;
    }

    return data;
};
