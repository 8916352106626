import React, { useCallback, useEffect } from 'react';

interface DropDownWrapperProps {
    children: React.ReactNode;
    ref?: React.ForwardedRef<unknown>;
}

/**
 * This component is used as wrapper for dropdown SimpleElements to hide the dropdown popup by view scroll
 */
const DropDownWrapper: React.FC<DropDownWrapperProps> = React.forwardRef(({ children }: DropDownWrapperProps, ref) => {
    const childRef = ref as React.MutableRefObject<any>;

    const hidePopup = () => {
        if (typeof childRef?.current?.instance?.close === 'function') childRef?.current?.instance?.close();
    };

    const isPopupContent = (element) => {
        if (typeof childRef?.current?.instance?.content !== 'function') return false;
        return childRef?.current?.instance?.content()?.contains(element);
    };

    const scrollEvent = useCallback((event: any): void => {
        if (!isPopupContent(event.target)) {
            hidePopup();
        }
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', scrollEvent, true);

        return () => {
            window.removeEventListener('scroll', scrollEvent, true);
        };
    });

    return <>{children}</>;
});

DropDownWrapper.displayName = 'DropDownWrapper';

export default DropDownWrapper;
