import axios, { AxiosResponse } from 'axios';
import { AuxTransactionList } from './ServiceReference/reference';
import { TransactionRecord } from './ServiceReference/TransactionRecord';
import { CalculationContent } from './ServiceReference/CalculationContent';
import { MessagePostContent } from './ServiceReference/MessagePostContent';
const url = '../call.aspx';

const SOAPService = {
    setUrl: (frontendUrl) => {
        // url = frontendUrl.replace(/\/Visi\/.*/i, '/call.aspx');
    },
    checkCORE: async () => {
        return await axios.get(url + '?method=isCORE');
    },
    logon: async (username: string, password: string) => {
        const config: any = {
            url: '/handlers/Login.ashx',
            method: 'post',
            headers: {
                method: 'login',
                username,
                password,
                communicationVersion: 13,
                source: 'WEB',
                // version: '4.6.154.0',
                // culture: 'nl-NL', //overwrite wordt gebruikt door de winfront
            },
        };

        try {
            return await axios(config);
        } catch (error) {
            return error;
        }
    },
    mailNewPassword: async (username: string) => {
        const config: any = {
            params: {
                method: 'mailnewpassword',
                username,
            },
            headers: {
                'Content-Type': 'application/json',
            },
        };

        try {
            return await axios.get(url, config);
        } catch (error) {
            return error;
        }
    },
    addSetting: async (settingName: string, settingValue: string, session: string, token: string) => {
        const requestParams = {
            params: {
                method: 'setting.add',
                setting: settingName,
                value: settingValue,
                BackendSessionId: session,
                BackendTokenID: token,
            },
            headers: {
                'Content-Type': 'application/json',
            },
        };

        try {
            return await axios.get(url, requestParams);
        } catch (error) {
            console.log(error);
        }
    },
    getSetting: async (setting: string, session: string, token: string) => {
        const requestParams = {
            params: {
                method: 'setting.read',
                setting,
                BackendSessionId: session,
                BackendTokenID: token,
            },
            headers: {
                'Content-Type': 'application/json',
            },
        };

        try {
            return await axios.get(url, requestParams);
        } catch (error) {
            console.log(error);
        }
    },
    addUserSetting: async (settingName: string, settingValue: string, session: string, token: string) => {
        const requestParams = {
            params: {
                method: 'user.setting.add',
                setting: settingName,
                value: settingValue,
                BackendSessionId: session,
                BackendTokenID: token,
            },
            headers: {
                'Content-Type': 'application/json',
            },
        };

        try {
            return await axios.get(url, requestParams);
        } catch (error) {
            console.log(error);
        }
    },
    getUserSetting: async (setting: string, session: string, token: string) => {
        const requestParams = {
            params: {
                method: 'user.setting.read',
                setting,
                BackendSessionId: session,
                BackendTokenID: token,
            },
            headers: {
                'Content-Type': 'application/json',
            },
        };

        try {
            return await axios.get(url, requestParams);
        } catch (error) {
            console.log(error);
        }
    },
    projectList: async (session: string, token: string) => {
        const requestParams = {
            params: {
                method: 'GetProjectList',
                BackendSessionId: session,
                BackendTokenID: token,
            },
            headers: {
                'Content-Type': 'application/json',
            },
        };

        try {
            return await axios.get(url, requestParams);
        } catch (error) {
            console.log(error);
        }
    },
    openProject: async (projectID: string, session: string, token: string) => {
        const requestParams = {
            params: {
                method: 'OpenProject',
                projectID,
                BackendSessionID: session,
                BackendTokenID: token,
            },
            headers: {
                'Content-Type': 'application/json',
            },
        };

        try {
            return await axios.get(url, requestParams);
        } catch (error) {
            console.log(error);
        }
    },
    getFavorites: async (session: string, token: string) => {
        const requestParams = {
            params: {
                method: 'GetFavorites',
                BackendSessionId: session,
                BackendTokenID: token,
            },
            headers: {
                'Content-Type': 'application/json',
            },
        };

        try {
            return await axios.get(url, requestParams);
        } catch (error) {
            return await error;
        }
    },
    addToFavorites: async (projectID: number, session: string, token: string) => {
        const requestParams = {
            params: {
                method: 'AddToFavorites',
                projectID,
                BackendSessionId: session,
                BackendTokenID: token,
            },
            headers: {
                'Content-Type': 'application/json',
            },
        };

        try {
            return await axios.get(url, requestParams);
        } catch (error) {
            console.log(error);
        }
    },
    deleteFromFavorites: async (projectID: number, session: string, token: string) => {
        const requestParams = {
            params: {
                method: 'DeleteFromFavorites',
                projectID,
                BackendSessionId: session,
                BackendTokenID: token,
            },
            headers: {
                'Content-Type': 'application/json',
            },
        };

        try {
            return await axios.get(url, requestParams);
        } catch (error) {
            console.log(error);
        }
    },
    treeInitialize: async (session: string, token: string) => {
        const requestParams = {
            params: {
                method: 'TreeInitialize',
                BackendSessionId: session,
                BackendTokenID: token,
            },
            headers: {
                'Content-Type': 'application/json',
            },
        };

        try {
            return await axios.get(url, requestParams);
        } catch (error) {
            console.log(error);
        }
    },
    treeExpand: async (branchId: string, session: string, token: string) => {
        const requestParams = {
            params: {
                method: 'TreeExpand',
                branchID: branchId,
                BackendSessionId: session,
                BackendTokenID: token,
            },
            headers: {
                'Content-Type': 'application/json',
            },
        };

        try {
            return await axios.get(url, requestParams);
        } catch (error) {
            console.log(error);
        }
    },
    addNavItemToFavorites: async (branchId: string, leafId: string, leafName: string, session: string, token: string) => {
        const requestParams = {
            params: {
                method: 'tree.favo.add',
                brancheid: branchId,
                leafid: leafId,
                name: leafName,
                BackendSessionId: session,
                BackendTokenID: token,
            },
            headers: {
                'Content-Type': 'application/json',
            },
        };

        try {
            return await axios.get(url, requestParams);
        } catch (error) {
            console.log(error);
        }
    },
    removeNavItemFromFavorites: async (itemId: string, session: string, token: string) => {
        const requestParams = {
            params: {
                method: 'tree.favo.delete',
                id: itemId,
                BackendSessionId: session,
                BackendTokenID: token,
            },
            headers: {
                'Content-Type': 'application/json',
            },
        };

        try {
            return await axios.get(url, requestParams);
        } catch (error) {
            console.log(error);
        }
    },
    renameNavItemInFavorites: async (favoriteId: string, newName: string, session: string, token: string) => {
        const requestParams = {
            params: {
                method: 'tree.favo.change',
                leafId: favoriteId,
                name: newName,
                BackendSessionId: session,
                BackendTokenID: token,
            },
            headers: {
                'Content-Type': 'application/json',
            },
        };

        try {
            return await axios.get(url, requestParams);
        } catch (error) {
            console.log(error);
        }
    },
    addPersonalFolder: async (folderName: string, session: string, token: string) => {
        const requestParams = {
            params: {
                method: 'tree.persmap.add',
                naam: folderName,
                BackendSessionId: session,
                BackendTokenID: token,
            },
            headers: {
                'Content-Type': 'application/json',
            },
        };

        try {
            return await axios.get(url, requestParams);
        } catch (error) {
            console.log(error);
        }
    },
    addToPersonalFolder: async (transactionID: number, persoonlijkMapID: string, session: string, token: string) => {
        const requestParams = {
            params: {
                method: 'tree.persmap.add.transaction',
                transId: transactionID,
                persId: persoonlijkMapID,
                BackendSessionId: session,
                BackendTokenID: token,
            },
            headers: {
                'Content-Type': 'application/json',
            },
        };

        try {
            return await axios.get(url, requestParams);
        } catch (error) {
            console.log(error);
        }
    },
    removePersonalFolder: async (itemId: string, session: string, token: string) => {
        const requestParams = {
            params: {
                method: 'tree.persmap.delete',
                id: itemId,
                BackendSessionId: session,
                BackendTokenID: token,
            },
            headers: {
                'Content-Type': 'application/json',
            },
        };

        try {
            return await axios.get(url, requestParams);
        } catch (error) {
            console.log(error);
        }
    },
    addRootFolder: async (branchId: string, leafId: string, leafName: string, session: string, token: string) => {
        const requestParams = {
            params: {
                method: 'RootFolderAdd',
                branchId,
                leafId,
                name: leafName,
                BackendSessionId: session,
                BackendTokenID: token,
            },
            headers: {
                'Content-Type': 'application/json',
            },
        };

        try {
            return await axios.get(url, requestParams);
        } catch (error) {
            console.log(error);
        }
    },
    removeRootFolder: async (leafId: string, session: string, token: string) => {
        const requestParams = {
            params: {
                method: 'RootFolderDelete',
                treeId: leafId,
                BackendSessionId: session,
                BackendTokenID: token,
            },
            headers: {
                'Content-Type': 'application/json',
            },
        };

        try {
            return await axios.get(url, requestParams);
        } catch (error) {
            console.log(error);
        }
    },
    receiveOverviewPeriodically: async (transactionId: string, period: string, session: string, token: string) => {
        const requestParams = {
            params: {
                method: 'tree.overzicht.periodiek',
                transactionID: transactionId,
                period,
                BackendSessionId: session,
                BackendTokenID: token,
            },
            headers: {
                'Content-Type': 'application/json',
            },
        };

        try {
            return await axios.get(url, requestParams);
        } catch (error) {
            console.log(error);
        }
    },
    startUpload: async (md5String: string, session: string, token: string) => {
        const config: any = {
            url,
            method: 'POST',
            params: {
                method: 'fileexists',
                md5: md5String,
                BackendSessionId: session,
                BackendTokenID: token,
            },
            headers: {
                'Content-Type': 'application/json',
            },
        };

        try {
            return await axios(config);
        } catch (error) {
            console.log(error);
        }
    },
    getReport: async (params: any) => {
        const requestParams = {
            params: {
                BackendSessionID: params.SessionID,
                BackendTokenID: params.TokenID,
                report_id: params.reportID,
            },
            headers: {
                'Content-Type': 'application/json',
            },
        };
        // ReportHandler verwacht report_id, REST verwacht reportID
        // params.report_id = params.reportID;
        // for (const a in params) {
        //     if (params[a] !== '') requestParams.params[a] = params[a];
        // }
        // requestParams.report_id = params.reportID;
        const reportHandler = url.replace('call.aspx', 'reports/ReportHandler.ashx');
        console.log('requestParams', requestParams);

        const response = await axios.get(reportHandler, requestParams);

        return new Promise((resolve, reject) => {
            if (typeof response.data === 'object') {
                resolve(response);
            } else {
                reject(response.data);
            }
        });
    },
    newMessageContent: async (params, session: string, token: string) => {
        const requestParams = {
            params: {
                method: 'newmessage_content_reply',
                BackendSessionId: session,
                BackendTokenID: token,
                ...params,
            },
        };

        try {
            return await axios.get(url, requestParams);
        } catch (error) {
            console.log(error);
        }
    },
    messageList: async (params, session: string, token: string) => {
        const requestParams = {
            params: {
                method: 'newmessage_listtransandmessages',
                BackendSessionId: session,
                BackendTokenID: token,
                transnr: params,
            },
            headers: {
                'Content-Type': 'application/json',
            },
        };

        try {
            return await axios.get(url, requestParams);
        } catch (error) {
            console.log(error);
        }
    },
    sendMessage: async (params: any, session: string, token: string) => {
        if (params.bericht == null) return 'geen bericht param meegegeven';

        const requestParams: any = {
            method: 'post',
            data: {
                method: 'post_typed_message',
                BackendSessionId: session,
                BackendTokenID: token,
                bericht: JSON.stringify(params.bericht),
            },
            headers: {
                'Content-Type': 'application/json',
            },
            url: url + '?method=post_typed_message',
        };

        try {
            return await axios(requestParams);
        } catch (error) {
            console.log(error);
        }
    },
    saveConcept: async (params, session: string, token: string) => {
        if (params.bericht == null) return 'geen bericht param meegegeven';

        const requestParams: any = {
            method: 'POST',
            data: {
                method: 'save_typed_message',
                BackendSessionId: session,
                BackendTokenID: token,
                bericht: JSON.stringify(params.bericht),
            },
            headers: {
                'Content-Type': 'application/json',
            },
            url: url + '?method=save_typed_message',
        };

        try {
            return await axios(requestParams);
        } catch (error) {
            console.log(error);
        }

        try {
            return await axios(requestParams);
        } catch (error) {
            console.log(error);
        }
    },
    removeConcept: async (params, session: string, token: string) => {
        if (params.ConceptID == null) return new Error('Geen ConceptID meegegeven');

        const requestParams: any = {
            method: 'post',
            data: {
                method: 'removeConcept',
                BackendSessionId: session,
                BackendTokenID: token,
                conceptId: params.ConceptID,
            },
            headers: {
                'Content-Type': 'application/json',
            },
            url: url + '?method=removeConcept&conceptId=' + params.ConceptID,
        };

        try {
            return await axios(requestParams);
        } catch (error) {
            console.log(error);
        }

        try {
            return await axios(requestParams);
        } catch (error) {
            console.log(error);
        }
    },
    getConceptId: async (replyMessageID: number, session: string, token: string) => {
        const requestParams: any = {
            method: 'GET',
            params: {
                method: 'getConceptId',
                replyMessageID,
            },
            headers: {
                'Content-Type': 'application/json',
                BackendSessionId: session,
                BackendTokenID: token,
            },
            url,
        };

        try {
            return await axios(requestParams);
        } catch (error) {
            console.log(error);
        }
    },
    getMessageDifference: async (messageID: number, session: string, token: string) => {
        const config: any = {
            headers: {
                method: 'GET',
                'Content-Type': 'application/json',
                SessionID: session,
                TokenID: token,
            },
            params: {
                method: 'diff',
                messageID,
            },
        };

        try {
            return await axios(url, config);
        } catch (error) {
            console.error(error);
        }
    },
    messageContent: async (params, session: string, token: string) => {
        const requestParams: any = {
            params: {
                method: 'message.content',
                BackendSessionId: session,
                BackendTokenID: token,
                ...params,
            },
            headers: {
                'Content-Type': 'application/json',
            },
        };
        if (params != null && params.cancelToken != null) {
            requestParams.cancelToken = requestParams.params.cancelToken;

            delete requestParams.params.cancelToken;

            console.log('delete token', requestParams);
        }

        try {
            return await axios.get(url, requestParams);
        } catch (error) {
            console.log(error);
        }
    },
    messageConcept: async (params, session: string, token: string) => {
        const requestParams: any = {
            params: {
                method: 'read_concept',
                BackendSessionId: session,
                BackendTokenID: token,
                ...params,
            },
            headers: {
                'Content-Type': 'application/json',
            },
        };
        if (params != null && params.cancelToken != null) {
            requestParams.cancelToken = requestParams.params.cancelToken;

            delete requestParams.params.cancelToken;

            console.log('delete token', requestParams);
        }

        try {
            return await axios.get(url, requestParams);
        } catch (error) {
            console.log(error);
        }
    },
    messageConceptWrite: async (params, session: string, token: string) => {
        const requestParams: any = {
            params: {
                method: 'read_concept_write',
                BackendSessionId: session,
                BackendTokenID: token,
                ...params,
            },
            headers: {
                'Content-Type': 'application/json',
            },
        };
        if (params != null && params.cancelToken != null) {
            requestParams.cancelToken = requestParams.params.cancelToken;

            delete requestParams.params.cancelToken;

            console.log('delete token', requestParams);
        }

        try {
            return await axios.get(url, requestParams);
        } catch (error) {
            console.log(error);
            requestParams.cancelToken = requestParams.params.cancelToken;

            delete requestParams.params.cancelToken;

            console.log('delete token', requestParams);
        }

        try {
            return await axios.get(url, requestParams);
        } catch (error) {
            console.log(error);
        }
    },
    tabTransactions: async (params, session: string, token: string) => {
        const requestParams = {
            params: {
                method: 'get_tabtransactions',
                BackendSessionID: session,
                BackendTokenID: token,
                transnr: params.transnr,
                transactiontypeid: params.transactiontypeid[0],
            },
            headers: {
                'Content-Type': 'application/json',
            },
        };

        try {
            return await axios.get(url, requestParams);
        } catch (error) {
            console.log(error);
        }
    },
    transaction_list: async (TransactionListID: string, filterString: any, session: string, token: string) => {
        const requestParams = {
            params: {
                method: 'transaction_list',
                BackendSessionId: session,
                BackendTokenID: token,
                TransactionListID,
                filterString,
            },
            headers: {
                'Content-Type': 'application/json',
            },
        };

        try {
            return await axios.get(url, requestParams);
        } catch (error) {
            console.log(error);
        }
    },
    // TODO: filter method
    transaction_expand: async (TransactionRecordBranchID, session: string, token: string) => {
        const requestParams = {
            params: {
                method: 'transaction_list_expand',
                BackendSessionId: session,
                BackendTokenID: token,
                TransactionRecordBranchID,
            },
            headers: {
                'Content-Type': 'application/json',
            },
        };

        try {
            // return { data: expand(TransactionRecordBranchID) };
            return (await axios.get(url, requestParams)) as AxiosResponse<TransactionRecord[]>;
        } catch (error) {
            console.log(error);
        }
    },
    canSetTransactionUnread: async (transactionID: number, session: string, token: string) => {
        const requestParams = {
            params: {
                method: 'transaction_setunread',
                BackendSessionId: session,
                BackendTokenID: token,
                transnr: transactionID,
            },
            headers: {
                'Content-Type': 'application/json',
            },
        };

        try {
            return await axios.get(url, requestParams);
        } catch (error) {
            return error;
        }
    },
    setTransactionUnread: async (transactionID: number, session: string, token: string) => {
        const requestParams = {
            params: {
                method: 'transaction_setunread',
                BackendSessionId: session,
                BackendTokenID: token,
                transnr: transactionID,
            },
            headers: {
                'Content-Type': 'application/json',
            },
        };

        try {
            // return { data: expand(TransactionRecordBranchID) };
            return await axios.get(url, requestParams);
        } catch (error) {
            console.log(error);
        }
    },
    setMessageRead: async (messageID: number, session: string, token: string) => {
        console.log({ messageID });
        const requestParams = {
            params: {
                method: 'message_setread',
                messnr: messageID,
                BackendSessionID: session,
                BackendTokenID: token,
            },
        };

        try {
            return await axios.get(url, requestParams);
        } catch (error) {
            console.log(error);
        }
    },
    setTransactionReady: async (transactionID: number, session: string, token: string) => {
        const requestParams = {
            params: {
                transnr: transactionID,
                method: 'transaction_setready',
                BackendSessionID: session,
                BackendTokenID: token,
            },
        };

        try {
            return await axios.post(url, requestParams);
        } catch (error) {
            console.log(error);
        }
    },
    getTransactionListBasedOn: async (basedOnData: AuxTransactionList, session: string, token: string) => {
        const config: any = {
            method: 'POST',
            url,
            headers: {
                'Content-Type': 'application/json',
            },
            params: {
                method: 'GetTransactionListBasedOn',
                AuxTransactionList: basedOnData,
                BackendSessionId: session,
                BackendTokenID: token,
            },
        };
        try {
            return await axios(config);
        } catch (error) {
            console.error(error);
        }
    },
    calculateMessage: async (lineNo: number, elementSID: string, messageData: MessagePostContent, session: string, token: string) => {
        const config: any = {
            method: 'POST',
            url,
            headers: {
                'Content-Type': 'application/json',
            },
            params: {
                method: 'CalculateMessage',
                idname: elementSID,
                lineNr: lineNo,
                message: JSON.stringify(messageData),
                BackendSessionId: session,
                BackendTokenID: token,
            },
        };
        try {
            return (await axios(config)) as AxiosResponse<CalculationContent>;
        } catch (error) {
            console.error(error);
        }
    },
    calculateLuaScript: async (LuaScriptId: string, messageData: MessagePostContent, session: string, token: string) => {
        const config: any = {
            method: 'POST',
            url,
            headers: {
                'Content-Type': 'application/json',
            },
            params: {
                method: 'CalculateMessage',
                LuaScriptId,
                message: JSON.stringify(messageData),
                BackendSessionId: session,
                BackendTokenID: token,
            },
        };
        try {
            return (await axios(config)) as AxiosResponse<CalculationContent>;
        } catch (error) {
            console.error(error);
        }
    },
    showCompleteTransaction: async (transactionID: number, session: string, token: string) => {
        const requestParams = {
            params: {
                method: 'show_complete_transaction',
                transnr: transactionID,
            },
            headers: {
                'Content-Type': 'application/json',
                BackendSessionID: session,
                BackendTokenID: token,
            },
        };

        try {
            return (await axios.get(url, requestParams)) as AxiosResponse<TransactionRecord>;
        } catch (error) {
            console.log(error);
        }
    },
    getBackendInfo: async (session: string, token: string) => {
        const requestParams = {
            params: {
                method: 'backendInfo',
                BackendSessionId: session,
                BackendTokenID: token,
            },
            headers: {
                'Content-Type': 'application/json',
            },
        };

        try {
            return await axios.get(url, requestParams);
        } catch (error) {
            console.log(error);
        }
    },
    changePassword: async (username: string, oldPass: string, newPass: string, session: string, token: string) => {
        const requestParams = {
            params: {
                method: 'wachtwoordveranderen',
                username,
                oldpass: oldPass,
                newpass: newPass,
                BackendSessionId: session,
                BackendTokenID: token,
            },
            headers: {
                'Content-Type': 'application/json',
            },
        };

        try {
            return await axios.get(url, requestParams);
        } catch (error) {
            console.log(error);
        }
    },
    logout: async (session: string, token: string) => {
        const requestParams = {
            params: {
                method: 'logout',
                BackendSessionId: session,
                BackendTokenID: token,
            },
            headers: {
                'Content-Type': 'application/json',
            },
        };

        try {
            return await axios.get(url, requestParams);
        } catch (error) {
            console.log(error);
        }
    },
    getFlowChar: async (transactionID: number, isCompact: boolean, session: string, token: string) => {
        const config: any = {
            url,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                SessionID: session,
                TokenID: token,
            },
            params: {
                transactionID,
                isCompact,
                method: 'getFlowChar',
                BackendSessionId: session,
                BackendTokenID: token,
            },
        };

        try {
            const ret = await axios(config);
            return ret;
        } catch (error) {
            if (error.name === 'AxiosError') return error.message;
            return error;
        }
    },
    getFlowChart: async (transactionTypeID: number, isCompact: boolean, session: string, token: string) => {
        const config: any = {
            url,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                SessionID: session,
                TokenID: token,
            },
            params: {
                transactionTypeID,
                isCompact,
                method: 'getFlowChart',
                BackendSessionId: session,
                BackendTokenID: token,
            },
        };

        try {
            const ret = await axios(config);
            return ret;
        } catch (error) {
            if (error.name === 'AxiosError') return error.message;
            return error;
        }
    },
    // relogin: async () => {
    //     try {
    //         // await axios.get(Visi.Constants.BASE_FOLDER + "LogIn.aspx")
    //         return (location.href = url + 'LogIn.aspx');
    //     } catch (error) {
    //         console.log(error);
    //     }
    // },
};

export default SOAPService;
