export const Inhoud1Basetypes2Data = {
    Appendices: [],
    ButtonReply: true,
    ComplexElements: [
        {
            Help: '',
            Hidden: false,
            ID: 394009,
            LayoutSettings: null,
            Name: 'Inhoud1: BaseType verzameling',
            SID: 'ce23',
            SimpleElements: [
                {
                    BaseType: 'BOOLEAN',
                    DisplayValue: 'false',
                    ExtraValue: null,
                    HTMLValue: '',
                    Help: 'Boolean testveld: er moet hier een checkbox in de applicatie getoond worden',
                    Hidden: false,
                    ID: 393963,
                    IsLink: false,
                    LayoutSettings: null,
                    Name: 'BTT:  BOOLEAN',
                    SID: 'se152_2',
                    Tag: null,
                    UriScheme: null,
                    Value: 'false',
                },
                {
                    BaseType: 'INTEGER',
                    DisplayValue: '1',
                    ExtraValue: null,
                    HTMLValue: '',
                    Help: 'Integer testveld: er mogen hier alleen maar getallen zonder decimalen en dergelijke ingevoerd worden.',
                    Hidden: false,
                    ID: 393968,
                    IsLink: false,
                    LayoutSettings: null,
                    Name: 'BTT:  INTEGER',
                    SID: 'se152_7',
                    Tag: null,
                    UriScheme: null,
                    Value: '1',
                },
                {
                    BaseType: 'TIME',
                    DisplayValue: '05:30',
                    ExtraValue: null,
                    HTMLValue: '',
                    Help: 'TIME testveld: de applicatie toont een invoerveld waarin UU:MM ingevoerd kan worden',
                    Hidden: false,
                    ID: 393966,
                    IsLink: false,
                    LayoutSettings: null,
                    Name: 'BTT:  TIME',
                    SID: 'se152_5',
                    Tag: null,
                    UriScheme: null,
                    Value: '05:30',
                },
                {
                    BaseType: 'DATE',
                    DisplayValue: '2019-06-04',
                    ExtraValue: null,
                    HTMLValue: '',
                    Help: 'DATE testveld: een datumkiezer/veld wordt door de applicatie getoond',
                    Hidden: false,
                    ID: 393964,
                    IsLink: false,
                    LayoutSettings: null,
                    Name: 'BTT: DATE',
                    SID: 'se152_3',
                    Tag: null,
                    UriScheme: null,
                    Value: '2019-06-04',
                },
                {
                    BaseType: 'DATETIME',
                    DisplayValue: '2019-06-04T00:00:00+02:00',
                    ExtraValue: null,
                    HTMLValue: '',
                    Help: 'DATETIME testveld: een invoerveld wordt getoond waarin een datum met een tijdstip ingevuld kan worden.',
                    Hidden: false,
                    ID: 393965,
                    IsLink: false,
                    LayoutSettings: null,
                    Name: 'BTT: DATETIME',
                    SID: 'se152_4',
                    Tag: null,
                    UriScheme: null,
                    Value: '2019-06-04T00:00:00+02:00',
                },
                {
                    BaseType: 'DECIMAL',
                    DisplayValue: '123.00',
                    ExtraValue: null,
                    HTMLValue: '',
                    Help: 'DECIMAL testveld: beschrijvinmg zoeken',
                    Hidden: false,
                    ID: 393967,
                    IsLink: false,
                    LayoutSettings: null,
                    Name: 'BTT: DECIMAL',
                    SID: 'se152_6',
                    Tag: null,
                    UriScheme: null,
                    Value: '123.00',
                },
                {
                    BaseType: 'STRING',
                    DisplayValue: '123ffs',
                    ExtraValue: null,
                    HTMLValue: '',
                    Help: 'STRING testveld: je mag hier alles invullen, voer getallen, vreemde tekens enz. in.',
                    Hidden: false,
                    ID: 393962,
                    IsLink: false,
                    LayoutSettings: null,
                    Name: 'BTT: STRING',
                    SID: 'se152',
                    Tag: null,
                    UriScheme: null,
                    Value: '123ffs',
                },
            ],
            Tables: [],
            Tag: null,
        },
        {
            Help: '',
            Hidden: false,
            ID: 394010,
            LayoutSettings: null,
            Name: 'Inhoud 1: base type tabel',
            SID: 'ce24',
            SimpleElements: [],
            Tables: [
                {
                    Columns: [
                        {
                            BaseType: 'BOOLEAN',
                            DisplayValue: null,
                            ExtraValue: null,
                            HTMLValue: null,
                            Help: null,
                            Hidden: false,
                            ID: 393963,
                            IsLink: false,
                            LayoutSettings: null,
                            Name: 'BTT:  BOOLEAN',
                            SID: 'se152_2',
                            Tag: null,
                            UriScheme: null,
                            Value: null,
                        },
                        {
                            BaseType: 'INTEGER',
                            DisplayValue: null,
                            ExtraValue: null,
                            HTMLValue: null,
                            Help: null,
                            Hidden: false,
                            ID: 393968,
                            IsLink: false,
                            LayoutSettings: null,
                            Name: 'BTT:  INTEGER',
                            SID: 'se152_7',
                            Tag: null,
                            UriScheme: null,
                            Value: null,
                        },
                        {
                            BaseType: 'TIME',
                            DisplayValue: null,
                            ExtraValue: null,
                            HTMLValue: null,
                            Help: null,
                            Hidden: false,
                            ID: 393966,
                            IsLink: false,
                            LayoutSettings: null,
                            Name: 'BTT:  TIME',
                            SID: 'se152_5',
                            Tag: null,
                            UriScheme: null,
                            Value: null,
                        },
                        {
                            BaseType: 'DATE',
                            DisplayValue: null,
                            ExtraValue: null,
                            HTMLValue: null,
                            Help: null,
                            Hidden: false,
                            ID: 393964,
                            IsLink: false,
                            LayoutSettings: null,
                            Name: 'BTT: DATE',
                            SID: 'se152_3',
                            Tag: null,
                            UriScheme: null,
                            Value: null,
                        },
                        {
                            BaseType: 'DATETIME',
                            DisplayValue: null,
                            ExtraValue: null,
                            HTMLValue: null,
                            Help: null,
                            Hidden: false,
                            ID: 393965,
                            IsLink: false,
                            LayoutSettings: null,
                            Name: 'BTT: DATETIME',
                            SID: 'se152_4',
                            Tag: null,
                            UriScheme: null,
                            Value: null,
                        },
                        {
                            BaseType: 'DECIMAL',
                            DisplayValue: null,
                            ExtraValue: null,
                            HTMLValue: null,
                            Help: null,
                            Hidden: false,
                            ID: 393967,
                            IsLink: false,
                            LayoutSettings: null,
                            Name: 'BTT: DECIMAL',
                            SID: 'se152_6',
                            Tag: null,
                            UriScheme: null,
                            Value: null,
                        },
                        {
                            BaseType: 'STRING',
                            DisplayValue: null,
                            ExtraValue: null,
                            HTMLValue: null,
                            Help: null,
                            Hidden: false,
                            ID: 393962,
                            IsLink: false,
                            LayoutSettings: null,
                            Name: 'BTT: STRING',
                            SID: 'se152',
                            Tag: null,
                            UriScheme: null,
                            Value: null,
                        },
                    ],
                    Data: [
                        [
                            {
                                BaseType: 'BOOLEAN',
                                DisplayValue: 'false',
                                ExtraValue: null,
                                HTMLValue: '',
                                Help: 'Boolean testveld: er moet hier een checkbox in de applicatie getoond worden',
                                Hidden: false,
                                ID: 393963,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'BTT:  BOOLEAN',
                                SID: 'se152_2',
                                Tag: null,
                                UriScheme: null,
                                Value: 'false',
                            },
                            {
                                BaseType: 'INTEGER',
                                DisplayValue: '1',
                                ExtraValue: null,
                                HTMLValue: '',
                                Help: 'Integer testveld: er mogen hier alleen maar getallen zonder decimalen en dergelijke ingevoerd worden.',
                                Hidden: false,
                                ID: 393968,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'BTT:  INTEGER',
                                SID: 'se152_7',
                                Tag: null,
                                UriScheme: null,
                                Value: '1',
                            },
                            {
                                BaseType: 'TIME',
                                DisplayValue: '07:30',
                                ExtraValue: null,
                                HTMLValue: '',
                                Help: 'TIME testveld: de applicatie toont een invoerveld waarin UU:MM ingevoerd kan worden',
                                Hidden: false,
                                ID: 393966,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'BTT:  TIME',
                                SID: 'se152_5',
                                Tag: null,
                                UriScheme: null,
                                Value: '07:30',
                            },
                            {
                                BaseType: 'DATE',
                                DisplayValue: '04-06-2019',
                                ExtraValue: null,
                                HTMLValue: '',
                                Help: 'DATE testveld: een datumkiezer/veld wordt door de applicatie getoond',
                                Hidden: false,
                                ID: 393964,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'BTT: DATE',
                                SID: 'se152_3',
                                Tag: null,
                                UriScheme: null,
                                Value: '2019-06-04',
                            },
                            {
                                BaseType: 'DATETIME',
                                DisplayValue: '2019-06-11T15:24:00',
                                ExtraValue: null,
                                HTMLValue: '',
                                Help: 'DATETIME testveld: een invoerveld wordt getoond waarin een datum met een tijdstip ingevuld kan worden.',
                                Hidden: false,
                                ID: 393965,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'BTT: DATETIME',
                                SID: 'se152_4',
                                Tag: null,
                                UriScheme: null,
                                Value: '2019-06-11T15:24:00',
                            },
                            {
                                BaseType: 'DECIMAL',
                                DisplayValue: '12.34',
                                ExtraValue: null,
                                HTMLValue: '',
                                Help: 'DECIMAL testveld: beschrijvinmg zoeken',
                                Hidden: false,
                                ID: 393967,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'BTT: DECIMAL',
                                SID: 'se152_6',
                                Tag: null,
                                UriScheme: null,
                                Value: '12.34',
                            },
                            {
                                BaseType: 'STRING',
                                DisplayValue: 'ds23fq3fq3dw',
                                ExtraValue: null,
                                HTMLValue: '',
                                Help: 'STRING testveld: je mag hier alles invullen, voer getallen, vreemde tekens enz. in.',
                                Hidden: false,
                                ID: 393962,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'BTT: STRING',
                                SID: 'se152',
                                Tag: null,
                                UriScheme: null,
                                Value: 'ds23fq3fq3dw',
                            },
                        ],
                        [
                            {
                                BaseType: 'BOOLEAN',
                                DisplayValue: 'false',
                                ExtraValue: null,
                                HTMLValue: '',
                                Help: 'Boolean testveld: er moet hier een checkbox in de applicatie getoond worden',
                                Hidden: false,
                                ID: 393963,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'BTT:  BOOLEAN',
                                SID: 'se152_2',
                                Tag: null,
                                UriScheme: null,
                                Value: 'false',
                            },
                            {
                                BaseType: 'INTEGER',
                                DisplayValue: '2',
                                ExtraValue: null,
                                HTMLValue: '',
                                Help: 'Integer testveld: er mogen hier alleen maar getallen zonder decimalen en dergelijke ingevoerd worden.',
                                Hidden: false,
                                ID: 393968,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'BTT:  INTEGER',
                                SID: 'se152_7',
                                Tag: null,
                                UriScheme: null,
                                Value: '2',
                            },
                            {
                                BaseType: 'TIME',
                                DisplayValue: '06:45',
                                ExtraValue: null,
                                HTMLValue: '',
                                Help: 'TIME testveld: de applicatie toont een invoerveld waarin UU:MM ingevoerd kan worden',
                                Hidden: false,
                                ID: 393966,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'BTT:  TIME',
                                SID: 'se152_5',
                                Tag: null,
                                UriScheme: null,
                                Value: '06:45',
                            },
                            {
                                BaseType: 'DATE',
                                DisplayValue: '20-06-2019',
                                ExtraValue: null,
                                HTMLValue: '',
                                Help: 'DATE testveld: een datumkiezer/veld wordt door de applicatie getoond',
                                Hidden: false,
                                ID: 393964,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'BTT: DATE',
                                SID: 'se152_3',
                                Tag: null,
                                UriScheme: null,
                                Value: '2019-06-20',
                            },
                            {
                                BaseType: 'DATETIME',
                                DisplayValue: '2019-06-14T15:50:00',
                                ExtraValue: null,
                                HTMLValue: '',
                                Help: 'DATETIME testveld: een invoerveld wordt getoond waarin een datum met een tijdstip ingevuld kan worden.',
                                Hidden: false,
                                ID: 393965,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'BTT: DATETIME',
                                SID: 'se152_4',
                                Tag: null,
                                UriScheme: null,
                                Value: '2019-06-14T15:50:00',
                            },
                            {
                                BaseType: 'DECIMAL',
                                DisplayValue: '2345',
                                ExtraValue: null,
                                HTMLValue: '',
                                Help: 'DECIMAL testveld: beschrijvinmg zoeken',
                                Hidden: false,
                                ID: 393967,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'BTT: DECIMAL',
                                SID: 'se152_6',
                                Tag: null,
                                UriScheme: null,
                                Value: '2345',
                            },
                            {
                                BaseType: 'STRING',
                                DisplayValue: 'w08dh98hf3298hf32',
                                ExtraValue: null,
                                HTMLValue: '',
                                Help: 'STRING testveld: je mag hier alles invullen, voer getallen, vreemde tekens enz. in.',
                                Hidden: false,
                                ID: 393962,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'BTT: STRING',
                                SID: 'se152',
                                Tag: null,
                                UriScheme: null,
                                Value: 'w08dh98hf3298hf32',
                            },
                        ],
                        [
                            {
                                BaseType: 'BOOLEAN',
                                DisplayValue: 'false',
                                ExtraValue: null,
                                HTMLValue: '',
                                Help: 'Boolean testveld: er moet hier een checkbox in de applicatie getoond worden',
                                Hidden: false,
                                ID: 393963,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'BTT:  BOOLEAN',
                                SID: 'se152_2',
                                Tag: null,
                                UriScheme: null,
                                Value: 'false',
                            },
                            {
                                BaseType: 'INTEGER',
                                DisplayValue: '3',
                                ExtraValue: null,
                                HTMLValue: '',
                                Help: 'Integer testveld: er mogen hier alleen maar getallen zonder decimalen en dergelijke ingevoerd worden.',
                                Hidden: false,
                                ID: 393968,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'BTT:  INTEGER',
                                SID: 'se152_7',
                                Tag: null,
                                UriScheme: null,
                                Value: '3',
                            },
                            {
                                BaseType: 'TIME',
                                DisplayValue: '07:00',
                                ExtraValue: null,
                                HTMLValue: '',
                                Help: 'TIME testveld: de applicatie toont een invoerveld waarin UU:MM ingevoerd kan worden',
                                Hidden: false,
                                ID: 393966,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'BTT:  TIME',
                                SID: 'se152_5',
                                Tag: null,
                                UriScheme: null,
                                Value: '07:00',
                            },
                            {
                                BaseType: 'DATE',
                                DisplayValue: '04-06-2019',
                                ExtraValue: null,
                                HTMLValue: '',
                                Help: 'DATE testveld: een datumkiezer/veld wordt door de applicatie getoond',
                                Hidden: false,
                                ID: 393964,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'BTT: DATE',
                                SID: 'se152_3',
                                Tag: null,
                                UriScheme: null,
                                Value: '2019-06-04',
                            },
                            {
                                BaseType: 'DATETIME',
                                DisplayValue: '2019-06-12T15:48:00',
                                ExtraValue: null,
                                HTMLValue: '',
                                Help: 'DATETIME testveld: een invoerveld wordt getoond waarin een datum met een tijdstip ingevuld kan worden.',
                                Hidden: false,
                                ID: 393965,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'BTT: DATETIME',
                                SID: 'se152_4',
                                Tag: null,
                                UriScheme: null,
                                Value: '2019-06-12T15:48:00',
                            },
                            {
                                BaseType: 'DECIMAL',
                                DisplayValue: '1234.4',
                                ExtraValue: null,
                                HTMLValue: '',
                                Help: 'DECIMAL testveld: beschrijvinmg zoeken',
                                Hidden: false,
                                ID: 393967,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'BTT: DECIMAL',
                                SID: 'se152_6',
                                Tag: null,
                                UriScheme: null,
                                Value: '1234.4',
                            },
                            {
                                BaseType: 'STRING',
                                DisplayValue: 'f489h',
                                ExtraValue: null,
                                HTMLValue: '',
                                Help: 'STRING testveld: je mag hier alles invullen, voer getallen, vreemde tekens enz. in.',
                                Hidden: false,
                                ID: 393962,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'BTT: STRING',
                                SID: 'se152',
                                Tag: null,
                                UriScheme: null,
                                Value: 'f489h',
                            },
                        ],
                    ],
                    ID: 394009,
                    LayoutSettings: null,
                    Name: 'Inhoud1: BaseType verzameling',
                    SID: 'ce23',
                    Tag: null,
                },
            ],
            Tag: null,
        },
        {
            Help: '',
            Hidden: false,
            ID: 394005,
            LayoutSettings: null,
            Name: 'Opmerkingen',
            SID: 'CE_Opmerkingen',
            SimpleElements: [
                {
                    BaseType: 'STRING',
                    DisplayValue: 'test',
                    ExtraValue: null,
                    HTMLValue: '',
                    Help: 'Te maken opmerking per bericht invulbaar. Bij een nieuw bericht wordt deze steeds leeggemaakt.',
                    Hidden: false,
                    ID: 393953,
                    IsLink: false,
                    LayoutSettings: null,
                    Name: 'Eenmalige opmerkingen',
                    SID: 'SE_Opmerkingen',
                    Tag: null,
                    UriScheme: null,
                    Value: 'test',
                },
            ],
            Tables: [],
            Tag: null,
        },
    ],
    ConceptID: 0,
    ConceptIsTakenOver: false,
    ConceptReplyToMessageID: 0,
    DateSend: '2019-06-04T15:53:42',
    Debug: false,
    DeletePossible: false,
    Direction: 'van 1 naar 2',
    DirectionAddress: { From: '1', OnBehalf: null, To: '2' },
    DirectionVisible: true,
    GebruikersID: 'pe_164bd47a-7789-463b-aded-0d56f6ab95a8',
    ID: 'Msg_47',
    Information: null,
    IsConcept: false,
    IsSetting: false,
    MITID: 789824,
    MessageDirection: 0,
    MessageID: 366698,
    MessageNameVisible: true,
    Name: 'Inhoud 1: Basetypes',
    ReplyPossible: true,
    Revoke: null,
    SetReady: false,
    ShowCreateProject: false,
    ShowUpdateProject: false,
    Sjablonen: [],
    SubTransPossible: false,
    Subject: 'test tabel',
    Tabs: {},
    Tag: null,
    TransactionID: 247561,
    TransactionInZip: false,
    TransactionNumber: '10650',
    TranstypeName: 'Inhoud 1: base type test',
    TranstypeSID: 'tra_10',
};

export const Inhoud1Basetypes1Data = {
    Appendices: [],
    ButtonReply: true,
    ComplexElements: [
        {
            Help: '',
            Hidden: false,
            ID: 980713,
            LayoutSettings: null,
            Name: 'Inhoud1: BaseType verzameling',
            SID: 'ce23',
            SimpleElements: [
                {
                    BaseType: 'BOOLEAN',
                    DisplayValue: '',
                    ExtraValue: null,
                    HTMLValue: '',
                    Help: 'Boolean testveld: er moet hier een checkbox in de applicatie getoond worden',
                    Hidden: false,
                    ID: 980647,
                    IsLink: false,
                    LayoutSettings: null,
                    Name: 'BTT:  BOOLEAN',
                    SID: 'se152_2',
                    Tag: null,
                    UriScheme: null,
                    Value: '',
                },
                {
                    BaseType: 'INTEGER',
                    DisplayValue: '1',
                    ExtraValue: null,
                    HTMLValue: '',
                    Help: 'Integer testveld: er mogen hier alleen maar getallen zonder decimalen en dergelijke ingevoerd worden.',
                    Hidden: false,
                    ID: 980652,
                    IsLink: false,
                    LayoutSettings: null,
                    Name: 'BTT:  INTEGER',
                    SID: 'se152_7',
                    Tag: null,
                    UriScheme: null,
                    Value: '1',
                },
                {
                    BaseType: 'TIME',
                    DisplayValue: '1970/01/01',
                    ExtraValue: null,
                    HTMLValue: '',
                    Help: 'TIME testveld: de applicatie toont een invoerveld waarin UU:MM ingevoerd kan worden',
                    Hidden: false,
                    ID: 980650,
                    IsLink: false,
                    LayoutSettings: null,
                    Name: 'BTT:  TIME',
                    SID: 'se152_5',
                    Tag: null,
                    UriScheme: null,
                    Value: '1970/01/01',
                },
                {
                    BaseType: 'DATE',
                    DisplayValue: '2021-09-21T22:00:00.000Z',
                    ExtraValue: null,
                    HTMLValue: '',
                    Help: 'DATE testveld: een datumkiezer/veld wordt door de applicatie getoond',
                    Hidden: false,
                    ID: 980648,
                    IsLink: false,
                    LayoutSettings: null,
                    Name: 'BTT: DATE',
                    SID: 'se152_3',
                    Tag: null,
                    UriScheme: null,
                    Value: '2021-09-21',
                },
                {
                    BaseType: 'DATETIME',
                    DisplayValue: '2021/09/08',
                    ExtraValue: null,
                    HTMLValue: '',
                    Help: 'DATETIME testveld: een invoerveld wordt getoond waarin een datum met een tijdstip ingevuld kan worden.',
                    Hidden: false,
                    ID: 980649,
                    IsLink: false,
                    LayoutSettings: null,
                    Name: 'BTT: DATETIME',
                    SID: 'se152_4',
                    Tag: null,
                    UriScheme: null,
                    Value: '2021/09/08',
                },
                {
                    BaseType: 'DECIMAL',
                    DisplayValue: '3.3',
                    ExtraValue: null,
                    HTMLValue: '',
                    Help: 'DECIMAL testveld: beschrijvinmg zoeken',
                    Hidden: false,
                    ID: 980651,
                    IsLink: false,
                    LayoutSettings: null,
                    Name: 'BTT: DECIMAL',
                    SID: 'se152_6',
                    Tag: null,
                    UriScheme: null,
                    Value: '3.3',
                },
                {
                    BaseType: 'STRING',
                    DisplayValue: 'haaaai',
                    ExtraValue: null,
                    HTMLValue: '',
                    Help: 'STRING testveld: je mag hier alles invullen, voer getallen, vreemde tekens enz. in.',
                    Hidden: false,
                    ID: 980646,
                    IsLink: false,
                    LayoutSettings: null,
                    Name: 'BTT: STRING',
                    SID: 'se152',
                    Tag: null,
                    UriScheme: null,
                    Value: 'haaaai',
                },
            ],
            Tables: [],
            Tag: null,
        },
        {
            Help: '',
            Hidden: false,
            ID: 980714,
            LayoutSettings: null,
            Name: 'Inhoud 1: base type tabel',
            SID: 'ce24',
            SimpleElements: [],
            Tables: [
                {
                    Columns: [
                        {
                            BaseType: 'BOOLEAN',
                            DisplayValue: null,
                            ExtraValue: null,
                            HTMLValue: null,
                            Help: null,
                            Hidden: false,
                            ID: 980647,
                            IsLink: false,
                            LayoutSettings: null,
                            Name: 'BTT:  BOOLEAN',
                            SID: 'se152_2',
                            Tag: null,
                            UriScheme: null,
                            Value: null,
                        },
                        {
                            BaseType: 'INTEGER',
                            DisplayValue: null,
                            ExtraValue: null,
                            HTMLValue: null,
                            Help: null,
                            Hidden: false,
                            ID: 980652,
                            IsLink: false,
                            LayoutSettings: null,
                            Name: 'BTT:  INTEGER',
                            SID: 'se152_7',
                            Tag: null,
                            UriScheme: null,
                            Value: null,
                        },
                        {
                            BaseType: 'TIME',
                            DisplayValue: null,
                            ExtraValue: null,
                            HTMLValue: null,
                            Help: null,
                            Hidden: false,
                            ID: 980650,
                            IsLink: false,
                            LayoutSettings: null,
                            Name: 'BTT:  TIME',
                            SID: 'se152_5',
                            Tag: null,
                            UriScheme: null,
                            Value: null,
                        },
                        {
                            BaseType: 'DATE',
                            DisplayValue: null,
                            ExtraValue: null,
                            HTMLValue: null,
                            Help: null,
                            Hidden: false,
                            ID: 980648,
                            IsLink: false,
                            LayoutSettings: null,
                            Name: 'BTT: DATE',
                            SID: 'se152_3',
                            Tag: null,
                            UriScheme: null,
                            Value: null,
                        },
                        {
                            BaseType: 'DATETIME',
                            DisplayValue: null,
                            ExtraValue: null,
                            HTMLValue: null,
                            Help: null,
                            Hidden: false,
                            ID: 980649,
                            IsLink: false,
                            LayoutSettings: null,
                            Name: 'BTT: DATETIME',
                            SID: 'se152_4',
                            Tag: null,
                            UriScheme: null,
                            Value: null,
                        },
                        {
                            BaseType: 'DECIMAL',
                            DisplayValue: null,
                            ExtraValue: null,
                            HTMLValue: null,
                            Help: null,
                            Hidden: false,
                            ID: 980651,
                            IsLink: false,
                            LayoutSettings: null,
                            Name: 'BTT: DECIMAL',
                            SID: 'se152_6',
                            Tag: null,
                            UriScheme: null,
                            Value: null,
                        },
                        {
                            BaseType: 'STRING',
                            DisplayValue: null,
                            ExtraValue: null,
                            HTMLValue: null,
                            Help: null,
                            Hidden: false,
                            ID: 980646,
                            IsLink: false,
                            LayoutSettings: null,
                            Name: 'BTT: STRING',
                            SID: 'se152',
                            Tag: null,
                            UriScheme: null,
                            Value: null,
                        },
                    ],
                    Data: [
                        [
                            {
                                BaseType: 'BOOLEAN',
                                DisplayValue: 'true',
                                ExtraValue: null,
                                HTMLValue: '',
                                Help: 'Boolean testveld: er moet hier een checkbox in de applicatie getoond worden',
                                Hidden: false,
                                ID: 980647,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'BTT:  BOOLEAN',
                                SID: 'se152_2',
                                Tag: null,
                                UriScheme: null,
                                Value: 'true',
                            },
                            {
                                BaseType: 'INTEGER',
                                DisplayValue: '1',
                                ExtraValue: null,
                                HTMLValue: '',
                                Help: 'Integer testveld: er mogen hier alleen maar getallen zonder decimalen en dergelijke ingevoerd worden.',
                                Hidden: false,
                                ID: 980652,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'BTT:  INTEGER',
                                SID: 'se152_7',
                                Tag: null,
                                UriScheme: null,
                                Value: '1',
                            },
                            {
                                BaseType: 'TIME',
                                DisplayValue: '2021-09-23T10:12:00.000Z',
                                ExtraValue: null,
                                HTMLValue: '',
                                Help: 'TIME testveld: de applicatie toont een invoerveld waarin UU:MM ingevoerd kan worden',
                                Hidden: false,
                                ID: 980650,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'BTT:  TIME',
                                SID: 'se152_5',
                                Tag: null,
                                UriScheme: null,
                                Value: '2021-09-23T10:12:00.000Z',
                            },
                            {
                                BaseType: 'DATE',
                                DisplayValue: '2021-09-07T22:00:00.000Z',
                                ExtraValue: null,
                                HTMLValue: '',
                                Help: 'DATE testveld: een datumkiezer/veld wordt door de applicatie getoond',
                                Hidden: false,
                                ID: 980648,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'BTT: DATE',
                                SID: 'se152_3',
                                Tag: null,
                                UriScheme: null,
                                Value: '2021-09-07',
                            },
                            {
                                BaseType: 'DATETIME',
                                DisplayValue: '2021-09-14T10:12:00.000Z',
                                ExtraValue: null,
                                HTMLValue: '',
                                Help: 'DATETIME testveld: een invoerveld wordt getoond waarin een datum met een tijdstip ingevuld kan worden.',
                                Hidden: false,
                                ID: 980649,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'BTT: DATETIME',
                                SID: 'se152_4',
                                Tag: null,
                                UriScheme: null,
                                Value: '2021-09-14T10:12:00.000Z',
                            },
                            {
                                BaseType: 'DECIMAL',
                                DisplayValue: '1.2',
                                ExtraValue: null,
                                HTMLValue: '',
                                Help: 'DECIMAL testveld: beschrijvinmg zoeken',
                                Hidden: false,
                                ID: 980651,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'BTT: DECIMAL',
                                SID: 'se152_6',
                                Tag: null,
                                UriScheme: null,
                                Value: '1.2',
                            },
                            {
                                BaseType: 'STRING',
                                DisplayValue: 'haaaai',
                                ExtraValue: null,
                                HTMLValue: '',
                                Help: 'STRING testveld: je mag hier alles invullen, voer getallen, vreemde tekens enz. in.',
                                Hidden: false,
                                ID: 980646,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'BTT: STRING',
                                SID: 'se152',
                                Tag: null,
                                UriScheme: null,
                                Value: 'haaaai',
                            },
                        ],
                    ],
                    ID: 980713,
                    LayoutSettings: null,
                    Name: 'Inhoud1: BaseType verzameling',
                    SID: 'ce23',
                    Tag: null,
                },
            ],
            Tag: null,
        },
        {
            Help: '',
            Hidden: false,
            ID: 980708,
            LayoutSettings: null,
            Name: 'Opmerkingen',
            SID: 'CE_Opmerkingen',
            SimpleElements: [
                {
                    BaseType: 'STRING',
                    DisplayValue: 'tralala',
                    ExtraValue: null,
                    HTMLValue: '',
                    Help: 'Te maken opmerking per bericht invulbaar. Bij een nieuw bericht wordt deze steeds leeggemaakt.',
                    Hidden: false,
                    ID: 980627,
                    IsLink: false,
                    LayoutSettings: null,
                    Name: 'Eenmalige opmerkingen',
                    SID: 'SE_Opmerkingen',
                    Tag: null,
                    UriScheme: null,
                    Value: 'tralala',
                },
            ],
            Tables: [],
            Tag: null,
        },
    ],
    ConceptID: 0,
    ConceptIsTakenOver: false,
    ConceptReplyToMessageID: 0,
    DateSend: '2021-09-10T12:12:59',
    Debug: false,
    DeletePossible: false,
    Direction: 'van 1 naar 2a',
    DirectionAddress: { From: '1', OnBehalf: null, To: '2a' },
    DirectionVisible: true,
    GebruikersID: 'pe_164bd47a-7789-463b-aded-0d56f6ab95a8',
    ID: 'Msg_47',
    Information: null,
    IsConcept: false,
    IsSetting: false,
    MITID: 2164302,
    MessageDirection: 0,
    MessageID: 776348,
    MessageNameVisible: true,
    Name: 'Inhoud 1: Basetypes',
    ReplyPossible: false,
    Revoke: null,
    SetReady: false,
    ShowCreateProject: false,
    ShowUpdateProject: false,
    Sjablonen: [],
    SubTransPossible: false,
    Subject: 'trlala 🎃',
    Tabs: {},
    Tag: null,
    TransactionID: 552170,
    TransactionInZip: false,
    TransactionNumber: '11087',
    TranstypeName: 'Inhoud 1: base type test',
    TranstypeSID: 'tra_10',
};

export const Inhoud3ElementconditionsData = {
    Appendices: [],
    ButtonReply: false,
    ComplexElements: [
        {
            Help: '',
            Hidden: false,
            ID: 405142,
            LayoutSettings: null,
            Name: 'Inhoud 3 element conditions basis',
            SID: 'ce25',
            SimpleElements: [
                {
                    BaseType: 'STRING',
                    DisplayValue: 'test??',
                    ExtraValue: null,
                    HTMLValue: '',
                    Help: '',
                    Hidden: false,
                    ID: 405099,
                    IsLink: false,
                    LayoutSettings: null,
                    Name: 'Element conditon SE regel',
                    SID: 'se153',
                    Tag: null,
                    UriScheme: null,
                    Value: 'test??',
                },
            ],
            Tables: [
                {
                    Columns: [
                        {
                            BaseType: 'STRING',
                            DisplayValue: null,
                            ExtraValue: null,
                            HTMLValue: null,
                            Help: null,
                            Hidden: false,
                            ID: 405100,
                            IsLink: false,
                            LayoutSettings: null,
                            Name: 'Element conditon SE kolom 1',
                            SID: 'se153_2',
                            Tag: null,
                            UriScheme: null,
                            Value: null,
                        },
                        {
                            BaseType: 'STRING',
                            DisplayValue: null,
                            ExtraValue: null,
                            HTMLValue: null,
                            Help: null,
                            Hidden: false,
                            ID: 405101,
                            IsLink: false,
                            LayoutSettings: null,
                            Name: 'Element conditon SE kolom 2 keuze',
                            SID: 'se153_2_2',
                            Tag: null,
                            UriScheme: null,
                            Value: null,
                        },
                    ],
                    Data: [
                        [
                            {
                                BaseType: 'STRING',
                                DisplayValue: '1',
                                ExtraValue: null,
                                HTMLValue: '',
                                Help: '',
                                Hidden: false,
                                ID: 405100,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'Element conditon SE kolom 1',
                                SID: 'se153_2',
                                Tag: null,
                                UriScheme: null,
                                Value: '1',
                            },
                            {
                                BaseType: 'STRING',
                                DisplayValue: 'Nee',
                                ExtraValue: null,
                                HTMLValue: '',
                                Help: '',
                                Hidden: false,
                                ID: 405101,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'Element conditon SE kolom 2 keuze',
                                SID: 'se153_2_2',
                                Tag: null,
                                UriScheme: null,
                                Value: 'Nee',
                            },
                        ],
                        [
                            {
                                BaseType: 'STRING',
                                DisplayValue: '2',
                                ExtraValue: null,
                                HTMLValue: '',
                                Help: '',
                                Hidden: false,
                                ID: 405100,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'Element conditon SE kolom 1',
                                SID: 'se153_2',
                                Tag: null,
                                UriScheme: null,
                                Value: '2',
                            },
                            {
                                BaseType: 'STRING',
                                DisplayValue: 'Nee',
                                ExtraValue: null,
                                HTMLValue: '',
                                Help: '',
                                Hidden: false,
                                ID: 405101,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'Element conditon SE kolom 2 keuze',
                                SID: 'se153_2_2',
                                Tag: null,
                                UriScheme: null,
                                Value: 'Nee',
                            },
                        ],
                        [
                            {
                                BaseType: 'STRING',
                                DisplayValue: '3',
                                ExtraValue: null,
                                HTMLValue: '',
                                Help: '',
                                Hidden: false,
                                ID: 405100,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'Element conditon SE kolom 1',
                                SID: 'se153_2',
                                Tag: null,
                                UriScheme: null,
                                Value: '3',
                            },
                            {
                                BaseType: 'STRING',
                                DisplayValue: 'Nee',
                                ExtraValue: null,
                                HTMLValue: '',
                                Help: '',
                                Hidden: false,
                                ID: 405101,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'Element conditon SE kolom 2 keuze',
                                SID: 'se153_2_2',
                                Tag: null,
                                UriScheme: null,
                                Value: 'Nee',
                            },
                        ],
                        [
                            {
                                BaseType: 'STRING',
                                DisplayValue: '4',
                                ExtraValue: null,
                                HTMLValue: '',
                                Help: '',
                                Hidden: false,
                                ID: 405100,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'Element conditon SE kolom 1',
                                SID: 'se153_2',
                                Tag: null,
                                UriScheme: null,
                                Value: '4',
                            },
                            {
                                BaseType: 'STRING',
                                DisplayValue: 'Nee',
                                ExtraValue: null,
                                HTMLValue: '',
                                Help: '',
                                Hidden: false,
                                ID: 405101,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'Element conditon SE kolom 2 keuze',
                                SID: 'se153_2_2',
                                Tag: null,
                                UriScheme: null,
                                Value: 'Nee',
                            },
                        ],
                        [
                            {
                                BaseType: 'STRING',
                                DisplayValue: '5',
                                ExtraValue: null,
                                HTMLValue: '',
                                Help: '',
                                Hidden: false,
                                ID: 405100,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'Element conditon SE kolom 1',
                                SID: 'se153_2',
                                Tag: null,
                                UriScheme: null,
                                Value: '5',
                            },
                            {
                                BaseType: 'STRING',
                                DisplayValue: 'Nee',
                                ExtraValue: null,
                                HTMLValue: '',
                                Help: '',
                                Hidden: false,
                                ID: 405101,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'Element conditon SE kolom 2 keuze',
                                SID: 'se153_2_2',
                                Tag: null,
                                UriScheme: null,
                                Value: 'Nee',
                            },
                        ],
                    ],
                    ID: 405143,
                    LayoutSettings: null,
                    Name: 'Inhoud 3 element conditions tabel',
                    SID: 'ce25_2',
                    Tag: null,
                },
            ],
            Tag: null,
        },
        {
            Help: '',
            Hidden: false,
            ID: 405136,
            LayoutSettings: null,
            Name: 'Opmerkingen',
            SID: 'CE_Opmerkingen',
            SimpleElements: [
                {
                    BaseType: 'STRING',
                    DisplayValue: '',
                    ExtraValue: null,
                    HTMLValue: '',
                    Help: 'Te maken opmerking per bericht invulbaar. Bij een nieuw bericht wordt deze steeds leeggemaakt.',
                    Hidden: false,
                    ID: 405082,
                    IsLink: false,
                    LayoutSettings: null,
                    Name: 'Eenmalige opmerkingen',
                    SID: 'SE_Opmerkingen',
                    Tag: null,
                    UriScheme: null,
                    Value: '',
                },
            ],
            Tables: [],
            Tag: null,
        },
    ],
    ConceptID: 0,
    ConceptIsTakenOver: false,
    ConceptReplyToMessageID: 0,
    DateSend: '2019-08-06T14:48:46',
    Debug: false,
    DeletePossible: false,
    Direction: 'van 1 naar 2',
    DirectionAddress: { From: '1', OnBehalf: null, To: '2' },
    DirectionVisible: true,
    GebruikersID: 'pe_164bd47a-7789-463b-aded-0d56f6ab95a8',
    ID: 'Msg_48_2',
    Information: null,
    IsConcept: false,
    IsSetting: false,
    MITID: 826067,
    MessageDirection: 0,
    MessageID: 380057,
    MessageNameVisible: true,
    Name: 'Inhoud 3: Element conditions default startbericht',
    ReplyPossible: false,
    Revoke: null,
    SetReady: false,
    ShowCreateProject: false,
    ShowUpdateProject: false,
    Sjablonen: [],
    SubTransPossible: false,
    Subject: 'test toevoegen regel',
    Tabs: {},
    Tag: null,
    TransactionID: 252859,
    TransactionInZip: false,
    TransactionNumber: '10693',
    TranstypeName: 'Inhoud3: Element conditions',
    TranstypeSID: 'tra18',
};

export const messageListData = [
    {
        ButtonCaption: null,
        ButtonIsSubtransaction: false,
        Direction: 3,
        HelpInfo: '',
        ID: 28851,
        IsButton: false,
        Messages: [
            {
                Disabled: false,
                DisabledTooltip: null,
                HelpInfo: '',
                ID: 131250,
                IsEditableByEveryone: false,
                MitID: 186883,
                MitSID: 'mit21256',
                Name: 'Inhoud 4: Default FIXED reactie User defined types',
                SID: 'Msg_49_5',
                Tooltip:
                    'Beantwoorden van het bericht:\n<B>Inhoud 4: User defined types</B>\naan:\n<B>1</B>\nmet het bericht:\n<B>Inhoud 4: Default FIXED reactie User defined types</B>\nin de transactie met als onderwerp:\n<B>df</B>\n',
                TransactionTypeID: 28851,
            },
            {
                Disabled: false,
                DisabledTooltip: null,
                HelpInfo: '',
                ID: 131248,
                IsEditableByEveryone: false,
                MitID: 186881,
                MitSID: 'mit21254',
                Name: 'Inhoud 4: EMPTY User defined types',
                SID: 'Msg_49_3',
                Tooltip:
                    'Beantwoorden van het bericht:\n<B>Inhoud 4: User defined types</B>\naan:\n<B>1</B>\nmet het bericht:\n<B>Inhoud 4: EMPTY User defined types</B>\nin de transactie met als onderwerp:\n<B>df</B>\n',
                TransactionTypeID: 28851,
            },
            {
                Disabled: false,
                DisabledTooltip: null,
                HelpInfo: '',
                ID: 131249,
                IsEditableByEveryone: false,
                MitID: 186882,
                MitSID: 'mit21255',
                Name: 'Inhoud 4: FREE User defined types',
                SID: 'Msg_49_4',
                Tooltip:
                    'Beantwoorden van het bericht:\n<B>Inhoud 4: User defined types</B>\naan:\n<B>1</B>\nmet het bericht:\n<B>Inhoud 4: FREE User defined types</B>\nin de transactie met als onderwerp:\n<B>df</B>\n',
                TransactionTypeID: 28851,
            },
        ],
        Name: 'Inhoud4: User Defined Types',
        OneExecutor: false,
        SID: 'tra18_2',
        Tooltip: null,
    },
];

export const containerData = {
    Appendices: [],
    ButtonReply: true,
    ComplexElements: [
        {
            Help: '',
            Hidden: false,
            ID: 1352269,
            LayoutSettings: null,
            Name: 'Contactgegevens aanvrager',
            RegExPattern: null,
            SID: 'CE_Contactgegevens_aanvrager',
            SimpleElements: [
                {
                    BaseType: 'STRING',
                    DisplayValue: 'testset',
                    ExtraValue: null,
                    HTMLValue: '',
                    Help: '',
                    Hidden: false,
                    ID: 1352161,
                    IsLink: false,
                    LayoutSettings: null,
                    Name: 'Naam',
                    SID: 'SE_naam_aanvrager',
                    Tag: null,
                    UriScheme: null,
                    Value: 'testset',
                },
                {
                    BaseType: 'STRING',
                    DisplayValue: 'testset',
                    ExtraValue: null,
                    HTMLValue: '',
                    Help: '',
                    Hidden: false,
                    ID: 1351924,
                    IsLink: false,
                    LayoutSettings: null,
                    Name: 'Functie',
                    SID: 'SE_Functie_aanvrager',
                    Tag: null,
                    UriScheme: null,
                    Value: 'testset',
                },
                {
                    BaseType: 'STRING',
                    DisplayValue: 'testset',
                    ExtraValue: null,
                    HTMLValue: '',
                    Help: '',
                    Hidden: false,
                    ID: 1352169,
                    IsLink: false,
                    LayoutSettings: null,
                    Name: 'Telefoonnummer',
                    SID: 'SE_telefoonnummer_aanvrager',
                    Tag: null,
                    UriScheme: null,
                    Value: 'testset',
                },
                {
                    BaseType: 'STRING',
                    DisplayValue: 'West',
                    ExtraValue: null,
                    HTMLValue: '',
                    Help: '',
                    Hidden: false,
                    ID: 1351934,
                    IsLink: false,
                    LayoutSettings: null,
                    Name: 'IB Windrichting',
                    SID: 'SE_IB_Windrichting',
                    Tag: null,
                    UriScheme: null,
                    Value: 'West',
                },
                {
                    BaseType: 'STRING',
                    DisplayValue: 'maaskant@bakkerspees.nl',
                    ExtraValue: null,
                    HTMLValue: '',
                    Help: 'Dit veld moet een geldig e-mailadres bevatten.',
                    Hidden: false,
                    ID: 1351919,
                    IsLink: false,
                    LayoutSettings: null,
                    Name: 'E-mailadres',
                    SID: 'SE_Email_aanvrager',
                    Tag: null,
                    UriScheme: null,
                    Value: 'maaskant@bakkerspees.nl',
                },
            ],
            Tables: [],
            Tag: null,
        },
        {
            Help: '',
            Hidden: false,
            ID: 1352206,
            LayoutSettings: null,
            Name: 'A. Projectgegevens',
            RegExPattern: null,
            SID: 'CE_Basisgegevens_aanmelding_deelproject',
            SimpleElements: [
                {
                    BaseType: 'STRING',
                    DisplayValue: 'AI 2019-0706',
                    ExtraValue: null,
                    HTMLValue: '',
                    Help: 'Contractnummer van de opdrachtgever waar het product of levering onder bekend staat',
                    Hidden: false,
                    ID: 1351864,
                    IsLink: false,
                    LayoutSettings: null,
                    Name: 'Besteksnummer / Projectnummer',
                    SID: 'SE_Besteksnummer',
                    Tag: null,
                    UriScheme: null,
                    Value: 'AI 2019-0706',
                },
                {
                    BaseType: 'STRING',
                    DisplayValue: 'AM04',
                    ExtraValue: null,
                    HTMLValue: '',
                    Help: '',
                    Hidden: false,
                    ID: 1352126,
                    IsLink: false,
                    LayoutSettings: null,
                    Name: 'Volgnummer aanvraag',
                    SID: 'SE_Volgnummer_aanvraag',
                    Tag: null,
                    UriScheme: null,
                    Value: 'AM04',
                },
                {
                    BaseType: 'STRING',
                    DisplayValue: 'testset',
                    ExtraValue: null,
                    HTMLValue: '',
                    Help: '',
                    Hidden: false,
                    ID: 1351962,
                    IsLink: false,
                    LayoutSettings: null,
                    Name: 'Projectnaam',
                    SID: 'SE_Naam_overkoepelend_deelproject',
                    Tag: null,
                    UriScheme: null,
                    Value: 'testset',
                },
                {
                    BaseType: 'STRING',
                    DisplayValue: 'testset',
                    ExtraValue: null,
                    HTMLValue: '',
                    Help: '',
                    Hidden: false,
                    ID: 1351988,
                    IsLink: false,
                    LayoutSettings: null,
                    Name: 'Opdrachtgever',
                    SID: 'SE_Opdrachtgever',
                    Tag: null,
                    UriScheme: null,
                    Value: 'testset',
                },
                {
                    BaseType: 'STRING',
                    DisplayValue: 'testset',
                    ExtraValue: null,
                    HTMLValue: '',
                    Help: '',
                    Hidden: false,
                    ID: 1352098,
                    IsLink: false,
                    LayoutSettings: null,
                    Name: 'Type project',
                    SID: 'SE_Type_project',
                    Tag: null,
                    UriScheme: null,
                    Value: 'testset',
                },
                {
                    BaseType: 'STRING',
                    DisplayValue: 'testset',
                    ExtraValue: null,
                    HTMLValue: '',
                    Help: '',
                    Hidden: false,
                    ID: 1351952,
                    IsLink: false,
                    LayoutSettings: null,
                    Name: 'Locatie project',
                    SID: 'SE_Locatie_project',
                    Tag: null,
                    UriScheme: null,
                    Value: 'testset',
                },
                {
                    BaseType: 'STRING',
                    DisplayValue: 'testset',
                    ExtraValue: null,
                    HTMLValue: '',
                    Help: '',
                    Hidden: false,
                    ID: 1352168,
                    IsLink: false,
                    LayoutSettings: null,
                    Name: 'Straatnaam',
                    SID: 'SE_straatnaam',
                    Tag: null,
                    UriScheme: null,
                    Value: 'testset',
                },
                {
                    BaseType: 'STRING',
                    DisplayValue: 'West (W)',
                    ExtraValue: null,
                    HTMLValue: '',
                    Help: '',
                    Hidden: false,
                    ID: 1352039,
                    IsLink: false,
                    LayoutSettings: null,
                    Name: 'Stadsdeel',
                    SID: 'SE_Stadsdeel',
                    Tag: null,
                    UriScheme: null,
                    Value: 'West (W)',
                },
                {
                    BaseType: 'STRING',
                    DisplayValue: 'testset',
                    ExtraValue: null,
                    HTMLValue: '',
                    Help: '',
                    Hidden: false,
                    ID: 1352032,
                    IsLink: false,
                    LayoutSettings: null,
                    Name: 'Routecode (voor kosten boomadviesbureau)',
                    SID: 'SE_Routecode',
                    Tag: null,
                    UriScheme: null,
                    Value: 'testset',
                },
            ],
            Tables: [],
            Tag: null,
        },
        {
            Help: '',
            Hidden: false,
            ID: 1352329,
            LayoutSettings: null,
            Name: 'B. Scope',
            RegExPattern: null,
            SID: 'CE_Scope',
            SimpleElements: [
                {
                    BaseType: 'STRING',
                    DisplayValue: 'testset',
                    ExtraValue: null,
                    HTMLValue: '',
                    Help: '',
                    Hidden: false,
                    ID: 1351837,
                    IsLink: false,
                    LayoutSettings: null,
                    Name: 'Aanleiding en doel van analyse (BEA, Taxatie of Kapaanvraag of combinatie):',
                    SID: 'SE_Aanleiding',
                    Tag: null,
                    UriScheme: null,
                    Value: 'testset',
                },
                {
                    BaseType: 'STRING',
                    DisplayValue: 'testset',
                    ExtraValue: null,
                    HTMLValue: '',
                    Help: '',
                    Hidden: false,
                    ID: 1352103,
                    IsLink: false,
                    LayoutSettings: null,
                    Name: 'Uitgangspunten project',
                    SID: 'SE_Uitgangspunten',
                    Tag: null,
                    UriScheme: null,
                    Value: 'testset',
                },
            ],
            Tables: [
                {
                    Columns: [
                        {
                            BaseType: 'STRING',
                            DisplayValue: null,
                            ExtraValue: null,
                            HTMLValue: null,
                            Help: null,
                            Hidden: false,
                            ID: 1351834,
                            IsLink: false,
                            LayoutSettings: null,
                            Name: 'Kies het type boomanalyse',
                            SID: 'SE_45',
                            Tag: null,
                            UriScheme: null,
                            Value: null,
                        },
                        {
                            BaseType: 'INTEGER',
                            DisplayValue: null,
                            ExtraValue: null,
                            HTMLValue: null,
                            Help: null,
                            Hidden: false,
                            ID: 1352152,
                            IsLink: false,
                            LayoutSettings: null,
                            Name: 'Aantal',
                            SID: 'SE_aantal',
                            Tag: null,
                            UriScheme: null,
                            Value: null,
                        },
                    ],
                    Data: [
                        [
                            {
                                BaseType: 'STRING',
                                DisplayValue: 'Taxatie',
                                ExtraValue: null,
                                HTMLValue: '',
                                Help: '',
                                Hidden: false,
                                ID: 1351834,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'Kies het type boomanalyse',
                                SID: 'SE_45',
                                Tag: null,
                                UriScheme: null,
                                Value: 'Taxatie',
                            },
                            {
                                BaseType: 'INTEGER',
                                DisplayValue: '3',
                                ExtraValue: null,
                                HTMLValue: '',
                                Help: '',
                                Hidden: false,
                                ID: 1352152,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'Aantal',
                                SID: 'SE_aantal',
                                Tag: null,
                                UriScheme: null,
                                Value: '3',
                            },
                        ],
                    ],
                    ID: 1352330,
                    LayoutSettings: null,
                    Name: 'Tabel scope',
                    SID: 'CE_Tabel_scope',
                    Tag: null,
                },
            ],
            Tag: null,
        },
        {
            Help: '',
            Hidden: false,
            ID: 1352295,
            LayoutSettings: null,
            Name: 'Nadere toelichting op Bomen Effect Analyse (optioneel uit te vragen en zie document Inventarisatie Programma van eisen):',
            RegExPattern: null,
            SID: 'CE_Nadere_toelichting_BEA',
            SimpleElements: [
                {
                    BaseType: 'STRING',
                    DisplayValue: 'testset',
                    ExtraValue: null,
                    HTMLValue: '',
                    Help: 'a) Bijvoorbeeld specifieke opnamegegevens',
                    Hidden: false,
                    ID: 1351941,
                    IsLink: false,
                    LayoutSettings: null,
                    Name: '1) Inventarisatie',
                    SID: 'SE_Inventarisatie',
                    Tag: null,
                    UriScheme: null,
                    Value: 'testset',
                },
                {
                    BaseType: 'STRING',
                    DisplayValue: 'testset',
                    ExtraValue: null,
                    HTMLValue: '',
                    Help: 'a) Bijvoorbeeld bijzondere aandachtspunten tbv. de inmeting',
                    Hidden: false,
                    ID: 1351938,
                    IsLink: false,
                    LayoutSettings: null,
                    Name: '2) Inmeting',
                    SID: 'SE_Inmeting',
                    Tag: null,
                    UriScheme: null,
                    Value: 'testset',
                },
                {
                    BaseType: 'STRING',
                    DisplayValue: 'testset',
                    ExtraValue: null,
                    HTMLValue: '',
                    Help: 'a) Bijvoorbeeld keuze in indicatieve of definitief verplantbaarheidsonderzoek',
                    Hidden: false,
                    ID: 1352113,
                    IsLink: false,
                    LayoutSettings: null,
                    Name: '3) Verplantbaarheidsonderzoek',
                    SID: 'SE_Verplantbaarheidsonderzoek',
                    Tag: null,
                    UriScheme: null,
                    Value: 'testset',
                },
                {
                    BaseType: 'STRING',
                    DisplayValue: 'testset',
                    ExtraValue: null,
                    HTMLValue: '',
                    Help: 'a) Bijvoorbeeld een specifieke indicatie van hoeveelheid aan proefsleuven en profielkuilen\nb) Zijn er bijzondere omstandigheden zoals verontreinigingen, aanwezige en toekomstige bemaling?\n',
                    Hidden: false,
                    ID: 1351875,
                    IsLink: false,
                    LayoutSettings: null,
                    Name: '4) Bodem en beworteling',
                    SID: 'SE_Bodem_en_beworteling',
                    Tag: null,
                    UriScheme: null,
                    Value: 'testset',
                },
                {
                    BaseType: 'STRING',
                    DisplayValue: 'testset',
                    ExtraValue: null,
                    HTMLValue: '',
                    Help: 'a) Bijvoorbeeld welke specifieke eisen worden gesteld aan het tekenwerk\nb) Optioneel een specifieke kaart met daarop weergegeven de resultaten van verplantbaarheid',
                    Hidden: false,
                    ID: 1351877,
                    IsLink: false,
                    LayoutSettings: null,
                    Name: '5) Bomentekeningen',
                    SID: 'SE_Bomentekeningen',
                    Tag: null,
                    UriScheme: null,
                    Value: 'testset',
                },
                {
                    BaseType: 'STRING',
                    DisplayValue: 'testset',
                    ExtraValue: null,
                    HTMLValue: '',
                    Help: 'a) Specifieke wensen bij de rapportage. Denk aan de behoefte van een “publieksversie” van de rapportage.',
                    Hidden: false,
                    ID: 1352017,
                    IsLink: false,
                    LayoutSettings: null,
                    Name: '6) Rapportage',
                    SID: 'SE_Rapportage',
                    Tag: null,
                    UriScheme: null,
                    Value: 'testset',
                },
            ],
            Tables: [],
            Tag: null,
        },
        {
            Help: '',
            Hidden: false,
            ID: 1352331,
            LayoutSettings: null,
            Name: 'Specifieke onderzoeksvragen:',
            RegExPattern: null,
            SID: 'CE_Specifieke_onderzoeksvragen',
            SimpleElements: [],
            Tables: [
                {
                    Columns: [
                        {
                            BaseType: 'STRING',
                            DisplayValue: null,
                            ExtraValue: null,
                            HTMLValue: null,
                            Help: null,
                            Hidden: false,
                            ID: 1351984,
                            IsLink: false,
                            LayoutSettings: null,
                            Name: 'Onderzoeksvraag',
                            SID: 'SE_Onderzoeksvraag',
                            Tag: null,
                            UriScheme: null,
                            Value: null,
                        },
                    ],
                    Data: [
                        [
                            {
                                BaseType: 'STRING',
                                DisplayValue: 'Besteksnummer invullen door BS2',
                                ExtraValue: null,
                                HTMLValue: '',
                                Help: '',
                                Hidden: false,
                                ID: 1351984,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'Onderzoeksvraag',
                                SID: 'SE_Onderzoeksvraag',
                                Tag: null,
                                UriScheme: null,
                                Value: 'Besteksnummer invullen door BS2',
                            },
                        ],
                    ],
                    ID: 1352332,
                    LayoutSettings: null,
                    Name: 'Tabelonderzoeksvragen',
                    SID: 'CE_Tabel_onderzoeksvragen',
                    Tag: null,
                },
            ],
            Tag: null,
        },
        {
            Help: '',
            Hidden: false,
            ID: 1352297,
            LayoutSettings: null,
            Name: 'Omschrijving van specifiek op te leveren producten:',
            RegExPattern: null,
            SID: 'CE_Omschrijving_opteleveren_producten',
            SimpleElements: [],
            Tables: [
                {
                    Columns: [
                        {
                            BaseType: 'STRING',
                            DisplayValue: null,
                            ExtraValue: null,
                            HTMLValue: null,
                            Help: null,
                            Hidden: false,
                            ID: 1351978,
                            IsLink: false,
                            LayoutSettings: null,
                            Name: 'Omschrijving product',
                            SID: 'SE_Omschrijving_product',
                            Tag: null,
                            UriScheme: null,
                            Value: null,
                        },
                    ],
                    Data: [
                        [
                            {
                                BaseType: 'STRING',
                                DisplayValue: 'testset',
                                ExtraValue: null,
                                HTMLValue: '',
                                Help: '',
                                Hidden: false,
                                ID: 1351978,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'Omschrijving product',
                                SID: 'SE_Omschrijving_product',
                                Tag: null,
                                UriScheme: null,
                                Value: 'testset',
                            },
                        ],
                    ],
                    ID: 1352298,
                    LayoutSettings: null,
                    Name: 'Tabel productomschrijvingen',
                    SID: 'CE_Tabel_productomschrijvingen',
                    Tag: null,
                },
            ],
            Tag: null,
        },
        {
            Help: '',
            Hidden: false,
            ID: 1352310,
            LayoutSettings: null,
            Name: 'C. Proces en planning',
            RegExPattern: null,
            SID: 'CE_Proces_planning',
            SimpleElements: [
                {
                    BaseType: 'STRING',
                    DisplayValue: 'testset',
                    ExtraValue: null,
                    HTMLValue: '',
                    Help: '',
                    Hidden: false,
                    ID: 1352003,
                    IsLink: false,
                    LayoutSettings: null,
                    Name: 'In welke PBI fase zit het project?',
                    SID: 'SE_PBI_fase',
                    Tag: null,
                    UriScheme: null,
                    Value: 'testset',
                },
                {
                    BaseType: 'STRING',
                    DisplayValue: 'Nee',
                    ExtraValue: null,
                    HTMLValue: '',
                    Help: '',
                    Hidden: false,
                    ID: 1352145,
                    IsLink: false,
                    LayoutSettings: null,
                    Name: 'WIOR Vergunning?',
                    SID: 'SE_WIOR_Vergunning',
                    Tag: null,
                    UriScheme: null,
                    Value: 'Nee',
                },
                {
                    BaseType: 'DATE',
                    DisplayValue: '2022-06-18',
                    ExtraValue: null,
                    HTMLValue: '',
                    Help: '',
                    Hidden: false,
                    ID: 1351926,
                    IsLink: false,
                    LayoutSettings: null,
                    Name: 'Wat is de geplande start uitvoering maaiveld inrichting?',
                    SID: 'SE_Geplande_datum__start_uitvoering',
                    Tag: null,
                    UriScheme: null,
                    Value: '2022-06-18',
                },
                {
                    BaseType: 'DATE',
                    DisplayValue: '2022-06-10',
                    ExtraValue: null,
                    HTMLValue: '',
                    Help: '',
                    Hidden: false,
                    ID: 1351905,
                    IsLink: false,
                    LayoutSettings: null,
                    Name: 'Wat is de geplande datum van oplevering?',
                    SID: 'SE_Datum_van_oplevering',
                    Tag: null,
                    UriScheme: null,
                    Value: '2022-06-10',
                },
                {
                    BaseType: 'STRING',
                    DisplayValue: 'Nee',
                    ExtraValue: null,
                    HTMLValue: '',
                    Help: '',
                    Hidden: false,
                    ID: 1351876,
                    IsLink: false,
                    LayoutSettings: null,
                    Name: 'Is er een bodemonderzoek beschikbaar',
                    SID: 'SE_Bodemonderzoek',
                    Tag: null,
                    UriScheme: null,
                    Value: 'Nee',
                },
                {
                    BaseType: 'STRING',
                    DisplayValue: 'Nee',
                    ExtraValue: null,
                    HTMLValue: '',
                    Help: '',
                    Hidden: false,
                    ID: 1352038,
                    IsLink: false,
                    LayoutSettings: null,
                    Name: 'Is er een shapefile van het plangebied met bomen?',
                    SID: 'SE_Shapefile',
                    Tag: null,
                    UriScheme: null,
                    Value: 'Nee',
                },
                {
                    BaseType: 'STRING',
                    DisplayValue: 'Nee',
                    ExtraValue: null,
                    HTMLValue: '',
                    Help: '',
                    Hidden: false,
                    ID: 1352167,
                    IsLink: false,
                    LayoutSettings: null,
                    Name: 'Is er een startnotitie of nota van uitgangspunten of gebiedsvisie van het plangebied?',
                    SID: 'SE_startnotitie',
                    Tag: null,
                    UriScheme: null,
                    Value: 'Nee',
                },
            ],
            Tables: [],
            Tag: null,
        },
        {
            Help: '',
            Hidden: false,
            ID: 1352337,
            LayoutSettings: null,
            Name: 'D. Uitvoeringsvragen',
            RegExPattern: null,
            SID: 'CE_Uitvoeringsvragen',
            SimpleElements: [
                {
                    BaseType: 'STRING',
                    DisplayValue: 'testset',
                    ExtraValue: null,
                    HTMLValue: '',
                    Help: '',
                    Hidden: false,
                    ID: 1351951,
                    IsLink: false,
                    LayoutSettings: null,
                    Name: 'Wat is de exacte locatie begrenzing van het plangebied?',
                    SID: 'SE_Locatie_begrenzing',
                    Tag: null,
                    UriScheme: null,
                    Value: 'testset',
                },
                {
                    BaseType: 'STRING',
                    DisplayValue: 'testset',
                    ExtraValue: null,
                    HTMLValue: '',
                    Help: '',
                    Hidden: false,
                    ID: 1352085,
                    IsLink: false,
                    LayoutSettings: null,
                    Name: 'Wat zijn de geplande werkzaamheden die getoetst moeten worden?',
                    SID: 'SE_Toetsing_Geplande_Werkzaamheden',
                    Tag: null,
                    UriScheme: null,
                    Value: 'testset',
                },
                {
                    BaseType: 'STRING',
                    DisplayValue: 'testset',
                    ExtraValue: null,
                    HTMLValue: '',
                    Help: '',
                    Hidden: false,
                    ID: 1351882,
                    IsLink: false,
                    LayoutSettings: null,
                    Name: 'Wie is de contactpersoon om toegang te regelen tot het perceel?',
                    SID: 'SE_Contactpersoon_toegang_perceel',
                    Tag: null,
                    UriScheme: null,
                    Value: 'testset',
                },
                {
                    BaseType: 'STRING',
                    DisplayValue: 'testset',
                    ExtraValue: null,
                    HTMLValue: '',
                    Help: '',
                    Hidden: false,
                    ID: 1351914,
                    IsLink: false,
                    LayoutSettings: null,
                    Name: 'Zijn er eventuele eerdere toetsen voor natuur i.h.k.v. dit project die noodzakelijke informatie bevatten?',
                    SID: 'SE_Eerdere_toetsen',
                    Tag: null,
                    UriScheme: null,
                    Value: 'testset',
                },
                {
                    BaseType: 'STRING',
                    DisplayValue: 'testset',
                    ExtraValue: null,
                    HTMLValue: '',
                    Help: '',
                    Hidden: false,
                    ID: 1351999,
                    IsLink: false,
                    LayoutSettings: null,
                    Name: 'Heeft u overige informatie waarvan u inschat dat deze noodzakelijk is voor natuurtoetsing of een veldbezoek?',
                    SID: 'SE_Overige_informatie',
                    Tag: null,
                    UriScheme: null,
                    Value: 'testset',
                },
            ],
            Tables: [],
            Tag: null,
        },
        {
            Help: 'Opmerkingen',
            Hidden: false,
            ID: 1352303,
            LayoutSettings: null,
            Name: 'Opmerkingen',
            RegExPattern: null,
            SID: 'CE_Opmerkingen',
            SimpleElements: [
                {
                    BaseType: 'STRING',
                    DisplayValue: 'testset',
                    ExtraValue: null,
                    HTMLValue: '',
                    Help: 'Te maken opmerking per bericht invulbaar. Bij een nieuw bericht wordt deze steeds leeggemaakt.',
                    Hidden: false,
                    ID: 1351991,
                    IsLink: false,
                    LayoutSettings: null,
                    Name: 'Eenmalige opmerkingen',
                    SID: 'SE_Opmerkingen',
                    Tag: null,
                    UriScheme: null,
                    Value: 'testset',
                },
            ],
            Tables: [],
            Tag: null,
        },
        {
            Help: '',
            Hidden: false,
            ID: 1352320,
            LayoutSettings: null,
            Name: 'Reactietermijn',
            RegExPattern: null,
            SID: 'CE_Reactietermijn',
            SimpleElements: [
                {
                    BaseType: 'DATE',
                    DisplayValue: '2022-06-16',
                    ExtraValue: null,
                    HTMLValue: '',
                    Help: 'Reactietermijn is uiterste datum waarop reactie op bericht wordt verlangd. Deze termijn is veelal korter dan de verplichte contractuele termijnen vanuit het contract. Deze reactietermijn geeft alleen aan wanneer de verzendende partij een reactie wenst',
                    Hidden: false,
                    ID: 1352020,
                    IsLink: false,
                    LayoutSettings: null,
                    Name: 'Reactietermijn',
                    SID: 'SE_Reactietermijn',
                    Tag: null,
                    UriScheme: null,
                    Value: '2022-06-16',
                },
            ],
            Tables: [],
            Tag: null,
        },
    ],
    ConceptID: 0,
    ConceptIsTakenOver: false,
    ConceptReplyToMessageID: 0,
    DateSend: '2022-06-23T14:01:25',
    Debug: false,
    DeletePossible: false,
    Direction: 'van Web formulier naar Opdrachtnemer selectie',
    DirectionAddress: { From: 'Web formulier', OnBehalf: null, To: 'Opdrachtnemer selectie' },
    DirectionVisible: true,
    GebruikersID: 'pe_299603f7-8b01-482c-a7d8-013395965aaa',
    ID: 'Msg_Invoeren_deelopdracht',
    Information: null,
    IsConcept: false,
    IsSetting: false,
    MITID: 2887997,
    MessageDirection: 0,
    MessageID: 893733,
    MessageNameVisible: true,
    Name: 'Invoeren: aanvraag Boomanalyse',
    ReplyPossible: false,
    Revoke: null,
    SetReady: false,
    ShowCreateProject: false,
    ShowUpdateProject: false,
    Sjablonen: [],
    SubTransPossible: false,
    Subject: 'AI 2019-0706 - AM04 - West (W) - testset',
    Tabs: {},
    Tag: null,
    TransactionID: 642837,
    TransactionInZip: false,
    TransactionNumber: 'OG0008',
    TranstypeName: 'Invoer: Intakeformulier Aanvraag Boomanalyse',
    TranstypeSID: 'TR_Invoer_verzoek_uitwerking_deelopdracht',
};

export const containerDataAppendice = {
    Appendices: [
        {
            Columns: [
                {
                    BaseType: 'string',
                    DisplayValue: null,
                    ExtraValue: null,
                    HTMLValue: null,
                    Help: null,
                    Hidden: false,
                    ID: 3,
                    IsLink: false,
                    LayoutSettings: null,
                    Name: 'Bestandsnaam',
                    SID: 'SE_Bestandsnaam',
                    Tag: null,
                    UriScheme: null,
                    Value: null,
                },
                {
                    BaseType: 'STRING',
                    DisplayValue: null,
                    ExtraValue: null,
                    HTMLValue: null,
                    Help: null,
                    Hidden: false,
                    ID: 90332,
                    IsLink: false,
                    LayoutSettings: null,
                    Name: 'Documenttitel',
                    SID: 'SE_Documenttitel',
                    Tag: null,
                    UriScheme: null,
                    Value: null,
                },
                {
                    BaseType: 'STRING',
                    DisplayValue: null,
                    ExtraValue: null,
                    HTMLValue: null,
                    Help: null,
                    Hidden: false,
                    ID: 90337,
                    IsLink: false,
                    LayoutSettings: null,
                    Name: 'Soort document',
                    SID: 'SE_Soort_document',
                    Tag: null,
                    UriScheme: null,
                    Value: null,
                },
                {
                    BaseType: 'STRING',
                    DisplayValue: null,
                    ExtraValue: null,
                    HTMLValue: null,
                    Help: null,
                    Hidden: false,
                    ID: 90376,
                    IsLink: false,
                    LayoutSettings: null,
                    Name: 'Kenmerk test',
                    SID: 'TestKenmerk',
                    Tag: null,
                    UriScheme: null,
                    Value: null,
                },
                {
                    BaseType: 'STRING',
                    DisplayValue: null,
                    ExtraValue: null,
                    HTMLValue: null,
                    Help: null,
                    Hidden: false,
                    ID: 90339,
                    IsLink: false,
                    LayoutSettings: null,
                    Name: 'Versie van document',
                    SID: 'SE_Versie_document',
                    Tag: null,
                    UriScheme: null,
                    Value: null,
                },
                {
                    BaseType: 'DATE',
                    DisplayValue: null,
                    ExtraValue: null,
                    HTMLValue: null,
                    Help: null,
                    Hidden: false,
                    ID: 90329,
                    IsLink: false,
                    LayoutSettings: null,
                    Name: 'dd bestand',
                    SID: 'Date_Bestand',
                    Tag: null,
                    UriScheme: null,
                    Value: null,
                },
                {
                    BaseType: 'string',
                    DisplayValue: null,
                    ExtraValue: null,
                    HTMLValue: null,
                    Help: null,
                    Hidden: false,
                    ID: 2,
                    IsLink: false,
                    LayoutSettings: null,
                    Name: 'BestandsID',
                    SID: 'SE_BestandsID',
                    Tag: null,
                    UriScheme: null,
                    Value: null,
                },
            ],
            Data: [
                [
                    {
                        BaseType: 'string',
                        DisplayValue: 'XtraReportReports.xlsx',
                        ExtraValue: null,
                        HTMLValue: null,
                        Help: null,
                        Hidden: false,
                        ID: 3,
                        IsLink: false,
                        LayoutSettings: null,
                        Name: 'Bestandsnaam',
                        SID: 'SE_Bestandsnaam',
                        Tag: null,
                        UriScheme: null,
                        Value: 'XtraReportReports.xlsx',
                    },
                    {
                        BaseType: 'STRING',
                        DisplayValue: '',
                        ExtraValue: null,
                        HTMLValue: null,
                        Help: null,
                        Hidden: false,
                        ID: 90332,
                        IsLink: false,
                        LayoutSettings: null,
                        Name: 'Documenttitel',
                        SID: 'SE_Documenttitel',
                        Tag: null,
                        UriScheme: null,
                        Value: '',
                    },
                    {
                        BaseType: 'STRING',
                        DisplayValue: 'Email',
                        ExtraValue: null,
                        HTMLValue: null,
                        Help: null,
                        Hidden: false,
                        ID: 90337,
                        IsLink: false,
                        LayoutSettings: null,
                        Name: 'Soort document',
                        SID: 'SE_Soort_document',
                        Tag: null,
                        UriScheme: null,
                        Value: 'Email',
                    },
                    {
                        BaseType: 'STRING',
                        DisplayValue: '',
                        ExtraValue: null,
                        HTMLValue: null,
                        Help: null,
                        Hidden: false,
                        ID: 90376,
                        IsLink: false,
                        LayoutSettings: null,
                        Name: 'Kenmerk test',
                        SID: 'TestKenmerk',
                        Tag: null,
                        UriScheme: null,
                        Value: '',
                    },
                    {
                        BaseType: 'STRING',
                        DisplayValue: '',
                        ExtraValue: null,
                        HTMLValue: null,
                        Help: null,
                        Hidden: false,
                        ID: 90339,
                        IsLink: false,
                        LayoutSettings: null,
                        Name: 'Versie van document',
                        SID: 'SE_Versie_document',
                        Tag: null,
                        UriScheme: null,
                        Value: '',
                    },
                    {
                        BaseType: 'DATE',
                        DisplayValue: '2016-08-20',
                        ExtraValue: null,
                        HTMLValue: null,
                        Help: null,
                        Hidden: false,
                        ID: 90329,
                        IsLink: false,
                        LayoutSettings: null,
                        Name: 'dd bestand',
                        SID: 'Date_Bestand',
                        Tag: null,
                        UriScheme: null,
                        Value: '2016-08-20',
                    },
                    {
                        BaseType: 'string',
                        DisplayValue: '40110',
                        ExtraValue: null,
                        HTMLValue: null,
                        Help: null,
                        Hidden: false,
                        ID: 2,
                        IsLink: false,
                        LayoutSettings: null,
                        Name: 'BestandsID',
                        SID: 'SE_BestandsID',
                        Tag: null,
                        UriScheme: null,
                        Value: '40110',
                    },
                ],
            ],
            ID: 0,
            LayoutSettings: null,
            Name: 'Standaard bijlage',
            SID: 'StandaardAppendix',
            Tag: null,
        },
    ],
    ButtonReply: true,
    ComplexElements: [
        {
            Help: '',
            Hidden: false,
            ID: 90385,
            LayoutSettings: null,
            Name: 'Inhoud 3 element conditions basis',
            RegExPattern: null,
            SID: 'ce25',
            SimpleElements: [
                {
                    BaseType: 'STRING',
                    DisplayValue: '13212341',
                    ExtraValue: null,
                    HTMLValue: '',
                    Help: '',
                    Hidden: false,
                    ID: 90349,
                    IsLink: false,
                    LayoutSettings: null,
                    Name: 'Element conditon SE regel',
                    SID: 'se153',
                    Tag: null,
                    UriScheme: null,
                    Value: '13212341',
                },
            ],
            Tables: [
                {
                    Columns: [
                        {
                            BaseType: 'STRING',
                            DisplayValue: null,
                            ExtraValue: null,
                            HTMLValue: null,
                            Help: null,
                            Hidden: false,
                            ID: 90350,
                            IsLink: false,
                            LayoutSettings: null,
                            Name: 'Element conditon SE kolom 1',
                            SID: 'se153_2',
                            Tag: null,
                            UriScheme: null,
                            Value: null,
                        },
                        {
                            BaseType: 'STRING',
                            DisplayValue: null,
                            ExtraValue: null,
                            HTMLValue: null,
                            Help: null,
                            Hidden: false,
                            ID: 90351,
                            IsLink: false,
                            LayoutSettings: null,
                            Name: 'Element conditon SE kolom 2 keuze',
                            SID: 'se153_2_2',
                            Tag: null,
                            UriScheme: null,
                            Value: null,
                        },
                    ],
                    Data: [
                        [
                            {
                                BaseType: 'STRING',
                                DisplayValue: 'HOI HOI HOI',
                                ExtraValue: null,
                                HTMLValue: '',
                                Help: '',
                                Hidden: false,
                                ID: 90350,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'Element conditon SE kolom 1',
                                SID: 'se153_2',
                                Tag: null,
                                UriScheme: null,
                                Value: 'HOI HOI HOI',
                            },
                            {
                                BaseType: 'STRING',
                                DisplayValue: 'Ja',
                                ExtraValue: null,
                                HTMLValue: '',
                                Help: '',
                                Hidden: false,
                                ID: 90351,
                                IsLink: false,
                                LayoutSettings: null,
                                Name: 'Element conditon SE kolom 2 keuze',
                                SID: 'se153_2_2',
                                Tag: null,
                                UriScheme: null,
                                Value: 'Ja',
                            },
                        ],
                    ],
                    ID: 90386,
                    LayoutSettings: null,
                    Name: 'Inhoud 3 element conditions tabel',
                    SID: 'ce25_2',
                    Tag: null,
                },
            ],
            Tag: null,
        },
        {
            Help: '',
            Hidden: false,
            ID: 90379,
            LayoutSettings: null,
            Name: 'Opmerkingen',
            RegExPattern: null,
            SID: 'CE_Opmerkingen',
            SimpleElements: [
                {
                    BaseType: 'STRING',
                    DisplayValue: '',
                    ExtraValue: null,
                    HTMLValue: '',
                    Help: 'Te maken opmerking per bericht invulbaar. Bij een nieuw bericht wordt deze steeds leeggemaakt.',
                    Hidden: false,
                    ID: 90336,
                    IsLink: false,
                    LayoutSettings: null,
                    Name: 'Eenmalige opmerkingen',
                    SID: 'SE_Opmerkingen',
                    Tag: null,
                    UriScheme: null,
                    Value: '',
                },
            ],
            Tables: [],
            Tag: null,
        },
    ],
    ConceptID: 0,
    ConceptIsTakenOver: false,
    ConceptReplyToMessageID: 0,
    DateSend: '2016-08-02T11:55:02',
    Debug: false,
    DeletePossible: false,
    Direction: 'van 1 naar 2',
    DirectionAddress: { From: '1', OnBehalf: null, To: '2' },
    DirectionVisible: true,
    GebruikersID: 'pe_b2638145-5d5d-48a4-92de-835b35d4cc14',
    ID: 'Msg_48_2',
    Information: null,
    IsConcept: false,
    IsSetting: false,
    MITID: 68657,
    MessageDirection: 0,
    MessageID: 123864,
    MessageNameVisible: true,
    Name: 'Inhoud 3: Element conditions default startbericht',
    ReplyPossible: false,
    Revoke: null,
    SetReady: false,
    ShowCreateProject: false,
    ShowUpdateProject: false,
    Sjablonen: [],
    SubTransPossible: false,
    Subject: 'Inhoud 3',
    Tabs: {},
    Tag: null,
    TransactionID: 80644,
    TransactionInZip: false,
    TransactionNumber: '10023',
    TranstypeName: 'Inhoud3: Element conditions',
    TranstypeSID: 'tra18',
};

export const writePageData = {
    Appendices: [
        {
            AutoUnzip: true,
            AutoUnzipPathElement: 'SE_Kenmerk_document',
            ButtonCaption: null,
            ButtonDetails: null,
            Columns: [
                {
                    BaseType: 'string',
                    ComboboxLocked: false,
                    DefaultValue: null,
                    DisplayValue: null,
                    FillWithFileDate: false,
                    Help: '',
                    Hidden: false,
                    ID: 3,
                    IsCalculation: false,
                    IsEmpty: false,
                    IsHTML: false,
                    LayoutSettings: null,
                    Locked: false,
                    MaxLength: null,
                    MinLength: null,
                    Name: 'Bestandsnaam',
                    Refresh: null,
                    RegExPattern: null,
                    ReloadPossible: false,
                    Required: true,
                    RowNumber: null,
                    SID: 'SE_Bestandsnaam',
                    TableCellLocked: false,
                    Tag: null,
                    TransactionListBasedOn: null,
                    Value: '',
                    Values: {},
                },
                {
                    BaseType: 'string',
                    ComboboxLocked: false,
                    DefaultValue: null,
                    DisplayValue: null,
                    FillWithFileDate: false,
                    Help: '',
                    Hidden: true,
                    ID: 2,
                    IsCalculation: false,
                    IsEmpty: false,
                    IsHTML: false,
                    LayoutSettings: null,
                    Locked: false,
                    MaxLength: null,
                    MinLength: null,
                    Name: 'BestandsID',
                    Refresh: null,
                    RegExPattern: null,
                    ReloadPossible: false,
                    Required: true,
                    RowNumber: null,
                    SID: 'SE_BestandsID',
                    TableCellLocked: false,
                    Tag: null,
                    TransactionListBasedOn: null,
                    Value: '',
                    Values: {},
                },
            ],
            Data: [],
            ID: 6189,
            IgnoreLock: false,
            IsCleared: false,
            LayoutSettings: null,
            Locked: false,
            MaxOccurs: null,
            MinOccurs: null,
            Name: 'Standaard bijlage',
            RefreshElementSIDs: null,
            SID: 'StandaardAppendix',
            Tag: null,
            TransactionList: null,
        },
    ],
    AppendixMandatory: true,
    ApplicationButtons: [],
    CalculationSettings: null,
    ComplexElements: [
        {
            Help: '',
            Hidden: false,
            ID: 1354864,
            LayoutSettings: null,
            Name: 'Contactgegevens aanvrager',
            SID: 'CE_Contactgegevens_aanvrager',
            SimpleElements: [
                {
                    BaseType: 'string',
                    ComboboxLocked: false,
                    DefaultValue: null,
                    DisplayValue: null,
                    FillWithFileDate: false,
                    Help: '',
                    Hidden: false,
                    ID: 1354756,
                    IsCalculation: false,
                    IsEmpty: false,
                    IsHTML: false,
                    LayoutSettings: null,
                    Locked: false,
                    MaxLength: null,
                    MinLength: 1,
                    Name: 'Naam',
                    Refresh: null,
                    RegExPattern: null,
                    ReloadPossible: false,
                    Required: true,
                    RowNumber: null,
                    SID: 'SE_naam_aanvrager',
                    TableCellLocked: false,
                    Tag: null,
                    TransactionListBasedOn: null,
                    Value: '',
                    Values: {},
                },
                {
                    BaseType: 'string',
                    ComboboxLocked: false,
                    DefaultValue: null,
                    DisplayValue: null,
                    FillWithFileDate: false,
                    Help: '',
                    Hidden: false,
                    ID: 1354518,
                    IsCalculation: false,
                    IsEmpty: false,
                    IsHTML: false,
                    LayoutSettings: null,
                    Locked: false,
                    MaxLength: null,
                    MinLength: 1,
                    Name: 'Functie',
                    Refresh: null,
                    RegExPattern: null,
                    ReloadPossible: false,
                    Required: true,
                    RowNumber: null,
                    SID: 'SE_Functie_aanvrager',
                    TableCellLocked: false,
                    Tag: null,
                    TransactionListBasedOn: null,
                    Value: '',
                    Values: {},
                },
                {
                    BaseType: 'string',
                    ComboboxLocked: false,
                    DefaultValue: null,
                    DisplayValue: null,
                    FillWithFileDate: false,
                    Help: '',
                    Hidden: false,
                    ID: 1354764,
                    IsCalculation: false,
                    IsEmpty: false,
                    IsHTML: false,
                    LayoutSettings: null,
                    Locked: false,
                    MaxLength: null,
                    MinLength: 1,
                    Name: 'Telefoonnummer',
                    Refresh: null,
                    RegExPattern: null,
                    ReloadPossible: false,
                    Required: true,
                    RowNumber: null,
                    SID: 'SE_telefoonnummer_aanvrager',
                    TableCellLocked: false,
                    Tag: null,
                    TransactionListBasedOn: null,
                    Value: '',
                    Values: {},
                },
                {
                    BaseType: 'select',
                    ComboboxLocked: false,
                    DefaultValue: null,
                    DisplayValue: null,
                    FillWithFileDate: false,
                    Help: '',
                    Hidden: false,
                    ID: 1354528,
                    IsCalculation: false,
                    IsEmpty: false,
                    IsHTML: false,
                    LayoutSettings: null,
                    Locked: false,
                    MaxLength: null,
                    MinLength: null,
                    Name: 'IB Windrichting',
                    Refresh: null,
                    RegExPattern: null,
                    ReloadPossible: false,
                    Required: true,
                    RowNumber: null,
                    SID: 'SE_IB_Windrichting',
                    TableCellLocked: false,
                    Tag: null,
                    TransactionListBasedOn: null,
                    Value: '',
                    Values: {
                        Centrum: 'Centrum',
                        Noord: 'Noord',
                        Oost: 'Oost',
                        Zuidoost: 'Zuidoost',
                        Zuid: 'Zuid',
                        West: 'West',
                        'Nieuw-west': 'Nieuw-west',
                    },
                },
                {
                    BaseType: 'string',
                    ComboboxLocked: false,
                    DefaultValue: null,
                    DisplayValue: null,
                    FillWithFileDate: false,
                    Help: 'Dit veld moet een geldig e-mailadres bevatten.',
                    Hidden: false,
                    ID: 1354513,
                    IsCalculation: false,
                    IsEmpty: false,
                    IsHTML: false,
                    LayoutSettings: null,
                    Locked: false,
                    MaxLength: null,
                    MinLength: null,
                    Name: 'E-mailadres',
                    Refresh: null,
                    RegExPattern:
                        '([A-Za-z0-9][-A-Za-z0-9_\\+\\.]*[A-Za-z0-9])@([A-Za-z0-9][-A-Za-z0-9\\.]*[A-Za-z0-9]\\.(arpa|root|aero|biz|cat|com|coop|edu|gov|info|int|jobs|mil|mobi|museum|name|net|org|pro|tel|travel|ac|ad|ae|af|ag|ai|al|am|an|ao|aq|ar|as|at|au|aw|ax|az|ba|bb|bd|be|bf|bg|bh|bi|bj|bm|bn|bo|br|bs|bt|bv|bw|by|bz|ca|cc|cd|cf|cg|ch|ci|ck|cl|cm|cn|co|cr|cu|cv|cx|cy|cz|de|dj|dk|dm|do|dz|ec|ee|eg|er|es|et|eu|fi|fj|fk|fm|fo|fr|ga|gb|gd|ge|gf|gg|gh|gi|gl|gm|gn|gp|gq|gr|gs|gt|gu|gw|gy|hk|hm|hn|hr|ht|hu|id|ie|il|im|in|io|iq|ir|is|it|je|jm|jo|jp|ke|kg|kh|ki|km|kn|kr|kw|ky|kz|la|lb|lc|li|lk|lr|ls|lt|lu|lv|ly|ma|mc|md|mg|mh|mk|ml|mm|mn|mo|mp|mq|mr|ms|mt|mu|mv|mw|mx|my|mz|na|nc|ne|nf|ng|ni|nl|no|np|nr|nu|nz|om|pa|pe|pf|pg|ph|pk|pl|pm|pn|pr|ps|pt|pw|py|qa|re|ro|ru|rw|sa|sb|sc|sd|se|sg|sh|si|sj|sk|sl|sm|sn|so|sr|st|su|sv|sy|sz|tc|td|tf|tg|th|tj|tk|tl|tm|tn|to|tp|tr|tt|tv|tw|tz|ua|ug|uk|um|us|uy|uz|va|vc|ve|vg|vi|vn|vu|wf|ws|ye|yt|yu|za|zm|zw)|([0-9]{1,3}\\.{3}[0-9]{1,3}))',
                    ReloadPossible: false,
                    Required: true,
                    RowNumber: null,
                    SID: 'SE_Email_aanvrager',
                    TableCellLocked: false,
                    Tag: null,
                    TransactionListBasedOn: null,
                    Value: '',
                    Values: {},
                },
            ],
            Tables: [],
        },
        {
            Help: '',
            Hidden: false,
            ID: 1354801,
            LayoutSettings: null,
            Name: 'A. Projectgegevens',
            SID: 'CE_Basisgegevens_aanmelding_deelproject',
            SimpleElements: [
                {
                    BaseType: 'string',
                    ComboboxLocked: false,
                    DefaultValue: null,
                    DisplayValue: null,
                    FillWithFileDate: false,
                    Help: 'Contractnummer van de opdrachtgever waar het product of levering onder bekend staat',
                    Hidden: false,
                    ID: 1354457,
                    IsCalculation: false,
                    IsEmpty: false,
                    IsHTML: false,
                    LayoutSettings: null,
                    Locked: true,
                    MaxLength: null,
                    MinLength: null,
                    Name: 'Besteksnummer / Projectnummer',
                    Refresh: null,
                    RegExPattern: null,
                    ReloadPossible: false,
                    Required: false,
                    RowNumber: null,
                    SID: 'SE_Besteksnummer',
                    TableCellLocked: false,
                    Tag: null,
                    TransactionListBasedOn: null,
                    Value: 'AI 2019-0706',
                    Values: {},
                },
                {
                    BaseType: 'string',
                    ComboboxLocked: false,
                    DefaultValue: null,
                    DisplayValue: null,
                    FillWithFileDate: false,
                    Help: '',
                    Hidden: false,
                    ID: 1354721,
                    IsCalculation: false,
                    IsEmpty: false,
                    IsHTML: false,
                    LayoutSettings: null,
                    Locked: true,
                    MaxLength: null,
                    MinLength: null,
                    Name: 'Volgnummer aanvraag',
                    Refresh: null,
                    RegExPattern: null,
                    ReloadPossible: false,
                    Required: false,
                    RowNumber: null,
                    SID: 'SE_Volgnummer_aanvraag',
                    TableCellLocked: false,
                    Tag: null,
                    TransactionListBasedOn: null,
                    Value: 'AM07',
                    Values: {},
                },
                {
                    BaseType: 'string',
                    ComboboxLocked: false,
                    DefaultValue: null,
                    DisplayValue: null,
                    FillWithFileDate: false,
                    Help: '',
                    Hidden: false,
                    ID: 1354556,
                    IsCalculation: false,
                    IsEmpty: false,
                    IsHTML: false,
                    LayoutSettings: null,
                    Locked: false,
                    MaxLength: null,
                    MinLength: 1,
                    Name: 'Projectnaam',
                    Refresh: null,
                    RegExPattern: null,
                    ReloadPossible: false,
                    Required: true,
                    RowNumber: null,
                    SID: 'SE_Naam_overkoepelend_deelproject',
                    TableCellLocked: false,
                    Tag: null,
                    TransactionListBasedOn: null,
                    Value: '',
                    Values: {},
                },
                {
                    BaseType: 'string',
                    ComboboxLocked: false,
                    DefaultValue: null,
                    DisplayValue: null,
                    FillWithFileDate: false,
                    Help: '',
                    Hidden: false,
                    ID: 1354582,
                    IsCalculation: false,
                    IsEmpty: false,
                    IsHTML: false,
                    LayoutSettings: null,
                    Locked: false,
                    MaxLength: null,
                    MinLength: 1,
                    Name: 'Opdrachtgever',
                    Refresh: null,
                    RegExPattern: null,
                    ReloadPossible: false,
                    Required: true,
                    RowNumber: null,
                    SID: 'SE_Opdrachtgever',
                    TableCellLocked: false,
                    Tag: null,
                    TransactionListBasedOn: null,
                    Value: '',
                    Values: {},
                },
                {
                    BaseType: 'string',
                    ComboboxLocked: false,
                    DefaultValue: null,
                    DisplayValue: null,
                    FillWithFileDate: false,
                    Help: '',
                    Hidden: false,
                    ID: 1354693,
                    IsCalculation: false,
                    IsEmpty: false,
                    IsHTML: false,
                    LayoutSettings: null,
                    Locked: false,
                    MaxLength: null,
                    MinLength: 1,
                    Name: 'Type project',
                    Refresh: null,
                    RegExPattern: null,
                    ReloadPossible: false,
                    Required: true,
                    RowNumber: null,
                    SID: 'SE_Type_project',
                    TableCellLocked: false,
                    Tag: null,
                    TransactionListBasedOn: null,
                    Value: '',
                    Values: {},
                },
                {
                    BaseType: 'string',
                    ComboboxLocked: false,
                    DefaultValue: null,
                    DisplayValue: null,
                    FillWithFileDate: false,
                    Help: '',
                    Hidden: false,
                    ID: 1354546,
                    IsCalculation: false,
                    IsEmpty: false,
                    IsHTML: false,
                    LayoutSettings: null,
                    Locked: false,
                    MaxLength: null,
                    MinLength: 1,
                    Name: 'Locatie project',
                    Refresh: null,
                    RegExPattern: null,
                    ReloadPossible: false,
                    Required: true,
                    RowNumber: null,
                    SID: 'SE_Locatie_project',
                    TableCellLocked: false,
                    Tag: null,
                    TransactionListBasedOn: null,
                    Value: '',
                    Values: {},
                },
                {
                    BaseType: 'string',
                    ComboboxLocked: false,
                    DefaultValue: null,
                    DisplayValue: null,
                    FillWithFileDate: false,
                    Help: '',
                    Hidden: false,
                    ID: 1354763,
                    IsCalculation: false,
                    IsEmpty: false,
                    IsHTML: false,
                    LayoutSettings: null,
                    Locked: false,
                    MaxLength: null,
                    MinLength: 1,
                    Name: 'Straatnaam',
                    Refresh: null,
                    RegExPattern: null,
                    ReloadPossible: false,
                    Required: true,
                    RowNumber: null,
                    SID: 'SE_straatnaam',
                    TableCellLocked: false,
                    Tag: null,
                    TransactionListBasedOn: null,
                    Value: '',
                    Values: {},
                },
                {
                    BaseType: 'select',
                    ComboboxLocked: false,
                    DefaultValue: null,
                    DisplayValue: null,
                    FillWithFileDate: false,
                    Help: '',
                    Hidden: false,
                    ID: 1354634,
                    IsCalculation: false,
                    IsEmpty: false,
                    IsHTML: false,
                    LayoutSettings: null,
                    Locked: false,
                    MaxLength: null,
                    MinLength: null,
                    Name: 'Stadsdeel',
                    Refresh: null,
                    RegExPattern: null,
                    ReloadPossible: false,
                    Required: true,
                    RowNumber: null,
                    SID: 'SE_Stadsdeel',
                    TableCellLocked: false,
                    Tag: null,
                    TransactionListBasedOn: null,
                    Value: '',
                    Values: {
                        'Noord (N)': 'Noord (N)',
                        'Oost (O)': 'Oost (O)',
                        'Zuidoost (ZO)': 'Zuidoost (ZO)',
                        'Zuid (Z)': 'Zuid (Z)',
                        'West (W)': 'West (W)',
                        'Nieuw West (NW)': 'Nieuw West (NW)',
                        'Centrum (C)': 'Centrum (C)',
                    },
                },
                {
                    BaseType: 'string',
                    ComboboxLocked: false,
                    DefaultValue: null,
                    DisplayValue: null,
                    FillWithFileDate: false,
                    Help: '',
                    Hidden: false,
                    ID: 1354627,
                    IsCalculation: false,
                    IsEmpty: false,
                    IsHTML: false,
                    LayoutSettings: null,
                    Locked: false,
                    MaxLength: null,
                    MinLength: 1,
                    Name: 'Routecode (voor kosten boomadviesbureau)',
                    Refresh: null,
                    RegExPattern: null,
                    ReloadPossible: false,
                    Required: true,
                    RowNumber: null,
                    SID: 'SE_Routecode',
                    TableCellLocked: false,
                    Tag: null,
                    TransactionListBasedOn: null,
                    Value: '',
                    Values: {},
                },
            ],
            Tables: [],
        },
        {
            Help: '',
            Hidden: false,
            ID: 1354925,
            LayoutSettings: null,
            Name: 'B. Scope',
            SID: 'CE_Scope',
            SimpleElements: [
                {
                    BaseType: 'string',
                    ComboboxLocked: false,
                    DefaultValue: null,
                    DisplayValue: null,
                    FillWithFileDate: false,
                    Help: '',
                    Hidden: false,
                    ID: 1354430,
                    IsCalculation: false,
                    IsEmpty: false,
                    IsHTML: false,
                    LayoutSettings: null,
                    Locked: false,
                    MaxLength: null,
                    MinLength: 1,
                    Name: 'Aanleiding en doel van analyse (BEA, Taxatie of Kapaanvraag of combinatie):',
                    Refresh: null,
                    RegExPattern: null,
                    ReloadPossible: false,
                    Required: true,
                    RowNumber: null,
                    SID: 'SE_Aanleiding',
                    TableCellLocked: false,
                    Tag: null,
                    TransactionListBasedOn: null,
                    Value: '',
                    Values: {},
                },
                {
                    BaseType: 'string',
                    ComboboxLocked: false,
                    DefaultValue: null,
                    DisplayValue: null,
                    FillWithFileDate: false,
                    Help: '',
                    Hidden: false,
                    ID: 1354698,
                    IsCalculation: false,
                    IsEmpty: false,
                    IsHTML: false,
                    LayoutSettings: null,
                    Locked: false,
                    MaxLength: null,
                    MinLength: 1,
                    Name: 'Uitgangspunten project',
                    Refresh: null,
                    RegExPattern: null,
                    ReloadPossible: false,
                    Required: true,
                    RowNumber: null,
                    SID: 'SE_Uitgangspunten',
                    TableCellLocked: false,
                    Tag: null,
                    TransactionListBasedOn: null,
                    Value: '',
                    Values: {},
                },
            ],
            Tables: [
                {
                    AutoUnzip: false,
                    AutoUnzipPathElement: null,
                    ButtonCaption: null,
                    ButtonDetails: null,
                    Columns: [
                        {
                            BaseType: 'select',
                            ComboboxLocked: false,
                            DefaultValue: null,
                            DisplayValue: null,
                            FillWithFileDate: false,
                            Help: '',
                            Hidden: false,
                            ID: 1354471,
                            IsCalculation: false,
                            IsEmpty: false,
                            IsHTML: false,
                            LayoutSettings: null,
                            Locked: false,
                            MaxLength: null,
                            MinLength: null,
                            Name: 'Kies het type boomanalyse',
                            Refresh: null,
                            RegExPattern: null,
                            ReloadPossible: false,
                            Required: true,
                            RowNumber: null,
                            SID: 'SE_Boomanalyse',
                            TableCellLocked: false,
                            Tag: null,
                            TransactionListBasedOn: null,
                            Value: '',
                            Values: { BEA: 'BEA', Taxatie: 'Taxatie', Kapaanvraag: 'Kapaanvraag' },
                        },
                        {
                            BaseType: 'integer',
                            ComboboxLocked: false,
                            DefaultValue: null,
                            DisplayValue: null,
                            FillWithFileDate: false,
                            Help: '',
                            Hidden: false,
                            ID: 1354747,
                            IsCalculation: false,
                            IsEmpty: false,
                            IsHTML: false,
                            LayoutSettings: null,
                            Locked: false,
                            MaxLength: null,
                            MinLength: null,
                            Name: 'Aantal',
                            Refresh: null,
                            RegExPattern: null,
                            ReloadPossible: false,
                            Required: true,
                            RowNumber: null,
                            SID: 'SE_aantal',
                            TableCellLocked: false,
                            Tag: null,
                            TransactionListBasedOn: null,
                            Value: '',
                            Values: {},
                        },
                    ],
                    Data: [],
                    ID: 1354926,
                    IgnoreLock: false,
                    IsCleared: false,
                    LayoutSettings: null,
                    Locked: false,
                    MaxOccurs: null,
                    MinOccurs: 1,
                    Name: 'Tabel scope',
                    RefreshElementSIDs: null,
                    SID: 'CE_Tabel_scope',
                    Tag: null,
                    TransactionList: null,
                },
            ],
        },
        {
            Help: '',
            Hidden: false,
            ID: 1354890,
            LayoutSettings: null,
            Name: 'Nadere toelichting op Bomen Effect Analyse (optioneel uit te vragen en zie document Inventarisatie Programma van eisen):',
            SID: 'CE_Nadere_toelichting_BEA',
            SimpleElements: [
                {
                    BaseType: 'string',
                    ComboboxLocked: false,
                    DefaultValue: null,
                    DisplayValue: null,
                    FillWithFileDate: false,
                    Help: 'a) Bijvoorbeeld specifieke opnamegegevens',
                    Hidden: false,
                    ID: 1354535,
                    IsCalculation: false,
                    IsEmpty: false,
                    IsHTML: false,
                    LayoutSettings: null,
                    Locked: false,
                    MaxLength: null,
                    MinLength: null,
                    Name: '1) Inventarisatie',
                    Refresh: null,
                    RegExPattern: null,
                    ReloadPossible: false,
                    Required: false,
                    RowNumber: null,
                    SID: 'SE_Inventarisatie',
                    TableCellLocked: false,
                    Tag: null,
                    TransactionListBasedOn: null,
                    Value: '',
                    Values: {},
                },
                {
                    BaseType: 'string',
                    ComboboxLocked: false,
                    DefaultValue: null,
                    DisplayValue: null,
                    FillWithFileDate: false,
                    Help: 'a) Bijvoorbeeld bijzondere aandachtspunten tbv. de inmeting',
                    Hidden: false,
                    ID: 1354532,
                    IsCalculation: false,
                    IsEmpty: false,
                    IsHTML: false,
                    LayoutSettings: null,
                    Locked: false,
                    MaxLength: null,
                    MinLength: null,
                    Name: '2) Inmeting',
                    Refresh: null,
                    RegExPattern: null,
                    ReloadPossible: false,
                    Required: false,
                    RowNumber: null,
                    SID: 'SE_Inmeting',
                    TableCellLocked: false,
                    Tag: null,
                    TransactionListBasedOn: null,
                    Value: '',
                    Values: {},
                },
                {
                    BaseType: 'string',
                    ComboboxLocked: false,
                    DefaultValue: null,
                    DisplayValue: null,
                    FillWithFileDate: false,
                    Help: 'a) Bijvoorbeeld keuze in indicatieve of definitief verplantbaarheidsonderzoek',
                    Hidden: false,
                    ID: 1354708,
                    IsCalculation: false,
                    IsEmpty: false,
                    IsHTML: false,
                    LayoutSettings: null,
                    Locked: false,
                    MaxLength: null,
                    MinLength: null,
                    Name: '3) Verplantbaarheidsonderzoek',
                    Refresh: null,
                    RegExPattern: null,
                    ReloadPossible: false,
                    Required: false,
                    RowNumber: null,
                    SID: 'SE_Verplantbaarheidsonderzoek',
                    TableCellLocked: false,
                    Tag: null,
                    TransactionListBasedOn: null,
                    Value: '',
                    Values: {},
                },
                {
                    BaseType: 'string',
                    ComboboxLocked: false,
                    DefaultValue: null,
                    DisplayValue: null,
                    FillWithFileDate: false,
                    Help: 'a) Bijvoorbeeld een specifieke indicatie van hoeveelheid aan proefsleuven en profielkuilen\nb) Zijn er bijzondere omstandigheden zoals verontreinigingen, aanwezige en toekomstige bemaling?\n',
                    Hidden: false,
                    ID: 1354468,
                    IsCalculation: false,
                    IsEmpty: false,
                    IsHTML: false,
                    LayoutSettings: null,
                    Locked: false,
                    MaxLength: null,
                    MinLength: null,
                    Name: '4) Bodem en beworteling',
                    Refresh: null,
                    RegExPattern: null,
                    ReloadPossible: false,
                    Required: false,
                    RowNumber: null,
                    SID: 'SE_Bodem_en_beworteling',
                    TableCellLocked: false,
                    Tag: null,
                    TransactionListBasedOn: null,
                    Value: '',
                    Values: {},
                },
                {
                    BaseType: 'string',
                    ComboboxLocked: false,
                    DefaultValue: null,
                    DisplayValue: null,
                    FillWithFileDate: false,
                    Help: 'a) Bijvoorbeeld welke specifieke eisen worden gesteld aan het tekenwerk\nb) Optioneel een specifieke kaart met daarop weergegeven de resultaten van verplantbaarheid',
                    Hidden: false,
                    ID: 1354470,
                    IsCalculation: false,
                    IsEmpty: false,
                    IsHTML: false,
                    LayoutSettings: null,
                    Locked: false,
                    MaxLength: null,
                    MinLength: null,
                    Name: '5) Bomentekeningen',
                    Refresh: null,
                    RegExPattern: null,
                    ReloadPossible: false,
                    Required: false,
                    RowNumber: null,
                    SID: 'SE_Bomentekeningen',
                    TableCellLocked: false,
                    Tag: null,
                    TransactionListBasedOn: null,
                    Value: '',
                    Values: {},
                },
                {
                    BaseType: 'string',
                    ComboboxLocked: false,
                    DefaultValue: null,
                    DisplayValue: null,
                    FillWithFileDate: false,
                    Help: 'a) Specifieke wensen bij de rapportage. Denk aan de behoefte van een “publieksversie” van de rapportage.',
                    Hidden: false,
                    ID: 1354611,
                    IsCalculation: false,
                    IsEmpty: false,
                    IsHTML: false,
                    LayoutSettings: null,
                    Locked: false,
                    MaxLength: null,
                    MinLength: null,
                    Name: '6) Rapportage',
                    Refresh: null,
                    RegExPattern: null,
                    ReloadPossible: false,
                    Required: false,
                    RowNumber: null,
                    SID: 'SE_Rapportage',
                    TableCellLocked: false,
                    Tag: null,
                    TransactionListBasedOn: null,
                    Value: '',
                    Values: {},
                },
            ],
            Tables: [],
        },
        {
            Help: '',
            Hidden: false,
            ID: 1354927,
            LayoutSettings: null,
            Name: 'Specifieke onderzoeksvragen:',
            SID: 'CE_Specifieke_onderzoeksvragen',
            SimpleElements: [
                {
                    BaseType: 'string',
                    ComboboxLocked: false,
                    DefaultValue: null,
                    DisplayValue: null,
                    FillWithFileDate: false,
                    Help: '',
                    Hidden: false,
                    ID: 1354578,
                    IsCalculation: false,
                    IsEmpty: false,
                    IsHTML: false,
                    LayoutSettings: null,
                    Locked: false,
                    MaxLength: null,
                    MinLength: null,
                    Name: 'Onderzoeksvragen',
                    Refresh: null,
                    RegExPattern: null,
                    ReloadPossible: false,
                    Required: false,
                    RowNumber: null,
                    SID: 'SE_Onderzoeksvraag',
                    TableCellLocked: false,
                    Tag: null,
                    TransactionListBasedOn: null,
                    Value: '',
                    Values: {},
                },
            ],
            Tables: [],
        },
        {
            Help: '',
            Hidden: false,
            ID: 1354892,
            LayoutSettings: null,
            Name: 'Omschrijving van specifiek op te leveren producten:',
            SID: 'CE_Omschrijving_opteleveren_producten',
            SimpleElements: [],
            Tables: [
                {
                    AutoUnzip: false,
                    AutoUnzipPathElement: null,
                    ButtonCaption: null,
                    ButtonDetails: null,
                    Columns: [
                        {
                            BaseType: 'string',
                            ComboboxLocked: false,
                            DefaultValue: null,
                            DisplayValue: null,
                            FillWithFileDate: false,
                            Help: '',
                            Hidden: false,
                            ID: 1354572,
                            IsCalculation: false,
                            IsEmpty: false,
                            IsHTML: false,
                            LayoutSettings: null,
                            Locked: false,
                            MaxLength: null,
                            MinLength: null,
                            Name: 'Omschrijving product',
                            Refresh: null,
                            RegExPattern: null,
                            ReloadPossible: false,
                            Required: false,
                            RowNumber: null,
                            SID: 'SE_Omschrijving_product',
                            TableCellLocked: false,
                            Tag: null,
                            TransactionListBasedOn: null,
                            Value: '',
                            Values: {},
                        },
                    ],
                    Data: [],
                    ID: 1354893,
                    IgnoreLock: false,
                    IsCleared: false,
                    LayoutSettings: null,
                    Locked: false,
                    MaxOccurs: null,
                    MinOccurs: 1,
                    Name: 'Tabel productomschrijvingen',
                    RefreshElementSIDs: null,
                    SID: 'CE_Tabel_productomschrijvingen',
                    Tag: null,
                    TransactionList: null,
                },
            ],
        },
        {
            Help: '',
            Hidden: false,
            ID: 1354905,
            LayoutSettings: null,
            Name: 'C. Proces en planning',
            SID: 'CE_Proces_planning',
            SimpleElements: [
                {
                    BaseType: 'string',
                    ComboboxLocked: false,
                    DefaultValue: null,
                    DisplayValue: null,
                    FillWithFileDate: false,
                    Help: '',
                    Hidden: false,
                    ID: 1354597,
                    IsCalculation: false,
                    IsEmpty: false,
                    IsHTML: false,
                    LayoutSettings: null,
                    Locked: false,
                    MaxLength: null,
                    MinLength: 1,
                    Name: 'In welke PBI fase zit het project?',
                    Refresh: null,
                    RegExPattern: null,
                    ReloadPossible: false,
                    Required: true,
                    RowNumber: null,
                    SID: 'SE_PBI_fase',
                    TableCellLocked: false,
                    Tag: null,
                    TransactionListBasedOn: null,
                    Value: '',
                    Values: {},
                },
                {
                    BaseType: 'select',
                    ComboboxLocked: false,
                    DefaultValue: null,
                    DisplayValue: null,
                    FillWithFileDate: false,
                    Help: '',
                    Hidden: false,
                    ID: 1354740,
                    IsCalculation: false,
                    IsEmpty: false,
                    IsHTML: false,
                    LayoutSettings: null,
                    Locked: false,
                    MaxLength: null,
                    MinLength: 1,
                    Name: 'WIOR Vergunning?',
                    Refresh: null,
                    RegExPattern: null,
                    ReloadPossible: false,
                    Required: true,
                    RowNumber: null,
                    SID: 'SE_WIOR_Vergunning',
                    TableCellLocked: false,
                    Tag: null,
                    TransactionListBasedOn: null,
                    Value: '',
                    Values: { Ja: 'Ja', Nee: 'Nee' },
                },
                {
                    BaseType: 'date',
                    ComboboxLocked: false,
                    DefaultValue: null,
                    DisplayValue: null,
                    FillWithFileDate: false,
                    Help: '',
                    Hidden: false,
                    ID: 1354520,
                    IsCalculation: false,
                    IsEmpty: false,
                    IsHTML: false,
                    LayoutSettings: null,
                    Locked: false,
                    MaxLength: null,
                    MinLength: null,
                    Name: 'Wat is de geplande start uitvoering maaiveld inrichting?',
                    Refresh: null,
                    RegExPattern: null,
                    ReloadPossible: false,
                    Required: true,
                    RowNumber: null,
                    SID: 'SE_Geplande_datum__start_uitvoering',
                    TableCellLocked: false,
                    Tag: null,
                    TransactionListBasedOn: null,
                    Value: '',
                    Values: {},
                },
                {
                    BaseType: 'date',
                    ComboboxLocked: false,
                    DefaultValue: null,
                    DisplayValue: null,
                    FillWithFileDate: false,
                    Help: '',
                    Hidden: false,
                    ID: 1354499,
                    IsCalculation: false,
                    IsEmpty: false,
                    IsHTML: false,
                    LayoutSettings: null,
                    Locked: false,
                    MaxLength: null,
                    MinLength: null,
                    Name: 'Wat is de geplande datum van oplevering?',
                    Refresh: null,
                    RegExPattern: null,
                    ReloadPossible: false,
                    Required: true,
                    RowNumber: null,
                    SID: 'SE_Datum_van_oplevering',
                    TableCellLocked: false,
                    Tag: null,
                    TransactionListBasedOn: null,
                    Value: '',
                    Values: {},
                },
                {
                    BaseType: 'select',
                    ComboboxLocked: false,
                    DefaultValue: null,
                    DisplayValue: null,
                    FillWithFileDate: false,
                    Help: '',
                    Hidden: false,
                    ID: 1354469,
                    IsCalculation: false,
                    IsEmpty: false,
                    IsHTML: false,
                    LayoutSettings: null,
                    Locked: false,
                    MaxLength: null,
                    MinLength: 1,
                    Name: 'Is er een bodemonderzoek beschikbaar',
                    Refresh: null,
                    RegExPattern: null,
                    ReloadPossible: false,
                    Required: true,
                    RowNumber: null,
                    SID: 'SE_Bodemonderzoek',
                    TableCellLocked: false,
                    Tag: null,
                    TransactionListBasedOn: null,
                    Value: '',
                    Values: { Ja: 'Ja', Nee: 'Nee' },
                },
                {
                    BaseType: 'select',
                    ComboboxLocked: false,
                    DefaultValue: null,
                    DisplayValue: null,
                    FillWithFileDate: false,
                    Help: '',
                    Hidden: false,
                    ID: 1354633,
                    IsCalculation: false,
                    IsEmpty: false,
                    IsHTML: false,
                    LayoutSettings: null,
                    Locked: false,
                    MaxLength: null,
                    MinLength: 1,
                    Name: 'Is er een shapefile van het plangebied met bomen?',
                    Refresh: null,
                    RegExPattern: null,
                    ReloadPossible: false,
                    Required: true,
                    RowNumber: null,
                    SID: 'SE_Shapefile',
                    TableCellLocked: false,
                    Tag: null,
                    TransactionListBasedOn: null,
                    Value: '',
                    Values: { Ja: 'Ja', Nee: 'Nee' },
                },
                {
                    BaseType: 'select',
                    ComboboxLocked: false,
                    DefaultValue: null,
                    DisplayValue: null,
                    FillWithFileDate: false,
                    Help: '',
                    Hidden: false,
                    ID: 1354762,
                    IsCalculation: false,
                    IsEmpty: false,
                    IsHTML: false,
                    LayoutSettings: null,
                    Locked: false,
                    MaxLength: null,
                    MinLength: 1,
                    Name: 'Is er een startnotitie of nota van uitgangspunten of gebiedsvisie van het plangebied?',
                    Refresh: null,
                    RegExPattern: null,
                    ReloadPossible: false,
                    Required: true,
                    RowNumber: null,
                    SID: 'SE_startnotitie',
                    TableCellLocked: false,
                    Tag: null,
                    TransactionListBasedOn: null,
                    Value: '',
                    Values: { Ja: 'Ja', Nee: 'Nee' },
                },
            ],
            Tables: [],
        },
        {
            Help: '',
            Hidden: false,
            ID: 1354933,
            LayoutSettings: null,
            Name: 'D. Uitvoeringsvragen',
            SID: 'CE_Uitvoeringsvragen',
            SimpleElements: [
                {
                    BaseType: 'string',
                    ComboboxLocked: false,
                    DefaultValue: null,
                    DisplayValue: null,
                    FillWithFileDate: false,
                    Help: '',
                    Hidden: false,
                    ID: 1354545,
                    IsCalculation: false,
                    IsEmpty: false,
                    IsHTML: false,
                    LayoutSettings: null,
                    Locked: false,
                    MaxLength: null,
                    MinLength: 1,
                    Name: 'Wat is de exacte locatie begrenzing van het plangebied?',
                    Refresh: null,
                    RegExPattern: null,
                    ReloadPossible: false,
                    Required: true,
                    RowNumber: null,
                    SID: 'SE_Locatie_begrenzing',
                    TableCellLocked: false,
                    Tag: null,
                    TransactionListBasedOn: null,
                    Value: '',
                    Values: {},
                },
                {
                    BaseType: 'string',
                    ComboboxLocked: false,
                    DefaultValue: null,
                    DisplayValue: null,
                    FillWithFileDate: false,
                    Help: '',
                    Hidden: false,
                    ID: 1354680,
                    IsCalculation: false,
                    IsEmpty: false,
                    IsHTML: false,
                    LayoutSettings: null,
                    Locked: false,
                    MaxLength: null,
                    MinLength: 1,
                    Name: 'Wat zijn de geplande werkzaamheden die getoetst moeten worden?',
                    Refresh: null,
                    RegExPattern: null,
                    ReloadPossible: false,
                    Required: true,
                    RowNumber: null,
                    SID: 'SE_Toetsing_Geplande_Werkzaamheden',
                    TableCellLocked: false,
                    Tag: null,
                    TransactionListBasedOn: null,
                    Value: '',
                    Values: {},
                },
                {
                    BaseType: 'string',
                    ComboboxLocked: false,
                    DefaultValue: null,
                    DisplayValue: null,
                    FillWithFileDate: false,
                    Help: '',
                    Hidden: false,
                    ID: 1354476,
                    IsCalculation: false,
                    IsEmpty: false,
                    IsHTML: false,
                    LayoutSettings: null,
                    Locked: false,
                    MaxLength: null,
                    MinLength: 1,
                    Name: 'Wie is de contactpersoon om toegang te regelen tot het perceel?',
                    Refresh: null,
                    RegExPattern: null,
                    ReloadPossible: false,
                    Required: true,
                    RowNumber: null,
                    SID: 'SE_Contactpersoon_toegang_perceel',
                    TableCellLocked: false,
                    Tag: null,
                    TransactionListBasedOn: null,
                    Value: '',
                    Values: {},
                },
                {
                    BaseType: 'string',
                    ComboboxLocked: false,
                    DefaultValue: null,
                    DisplayValue: null,
                    FillWithFileDate: false,
                    Help: '',
                    Hidden: false,
                    ID: 1354508,
                    IsCalculation: false,
                    IsEmpty: false,
                    IsHTML: false,
                    LayoutSettings: null,
                    Locked: false,
                    MaxLength: null,
                    MinLength: 1,
                    Name: 'Zijn er eventuele eerdere toetsen voor natuur i.h.k.v. dit project die noodzakelijke informatie bevatten?',
                    Refresh: null,
                    RegExPattern: null,
                    ReloadPossible: false,
                    Required: true,
                    RowNumber: null,
                    SID: 'SE_Eerdere_toetsen',
                    TableCellLocked: false,
                    Tag: null,
                    TransactionListBasedOn: null,
                    Value: '',
                    Values: {},
                },
                {
                    BaseType: 'string',
                    ComboboxLocked: false,
                    DefaultValue: null,
                    DisplayValue: null,
                    FillWithFileDate: false,
                    Help: '',
                    Hidden: false,
                    ID: 1354593,
                    IsCalculation: false,
                    IsEmpty: false,
                    IsHTML: false,
                    LayoutSettings: null,
                    Locked: false,
                    MaxLength: null,
                    MinLength: null,
                    Name: 'Heeft u overige informatie waarvan u inschat dat deze noodzakelijk is voor natuurtoetsing of een veldbezoek?',
                    Refresh: null,
                    RegExPattern: null,
                    ReloadPossible: false,
                    Required: false,
                    RowNumber: null,
                    SID: 'SE_Overige_informatie',
                    TableCellLocked: false,
                    Tag: null,
                    TransactionListBasedOn: null,
                    Value: '',
                    Values: {},
                },
            ],
            Tables: [],
        },
        {
            Help: 'Opmerkingen',
            Hidden: false,
            ID: 1354898,
            LayoutSettings: null,
            Name: 'Opmerkingen',
            SID: 'CE_Opmerkingen',
            SimpleElements: [
                {
                    BaseType: 'string',
                    ComboboxLocked: false,
                    DefaultValue: null,
                    DisplayValue: '',
                    FillWithFileDate: false,
                    Help: 'Te maken opmerking per bericht invulbaar. Bij een nieuw bericht wordt deze steeds leeggemaakt.',
                    Hidden: false,
                    ID: 1354585,
                    IsCalculation: false,
                    IsEmpty: true,
                    IsHTML: false,
                    LayoutSettings: null,
                    Locked: false,
                    MaxLength: null,
                    MinLength: null,
                    Name: 'Eenmalige opmerkingen',
                    Refresh: null,
                    RegExPattern: null,
                    ReloadPossible: false,
                    Required: false,
                    RowNumber: null,
                    SID: 'SE_Opmerkingen',
                    TableCellLocked: false,
                    Tag: null,
                    TransactionListBasedOn: null,
                    Value: '',
                    Values: {},
                },
            ],
            Tables: [],
        },
        {
            Help: '',
            Hidden: false,
            ID: 1354919,
            LayoutSettings: null,
            Name: 'Reactietermijn',
            SID: 'CE_Reactietermijn_offerte',
            SimpleElements: [
                {
                    BaseType: 'date',
                    ComboboxLocked: false,
                    DefaultValue: null,
                    DisplayValue: null,
                    FillWithFileDate: false,
                    Help: 'Reactietermijn is uiterste datum waarop reactie op bericht wordt verlangd. Deze termijn is veelal korter dan de verplichte contractuele termijnen vanuit het contract. Deze reactietermijn geeft alleen aan wanneer de verzendende partij een reactie wenst',
                    Hidden: false,
                    ID: 1354617,
                    IsCalculation: false,
                    IsEmpty: false,
                    IsHTML: false,
                    LayoutSettings: null,
                    Locked: false,
                    MaxLength: null,
                    MinLength: null,
                    Name: 'Reactietermijn offerte',
                    Refresh: null,
                    RegExPattern: null,
                    ReloadPossible: false,
                    Required: true,
                    RowNumber: null,
                    SID: 'SE_Reactietermijn_offerte',
                    TableCellLocked: false,
                    Tag: null,
                    TransactionListBasedOn: null,
                    Value: '',
                    Values: {},
                },
            ],
            Tables: [],
        },
    ],
    CurrentLineNumberInTable: 0,
    Executors: [{ ID: 'pir_a21d0d2f-7f53-4ed2-aa4a-6ffb2b0754ad', InitiatorSID: null, Name: 'Opdrachtnemer selectie' }],
    ExecutorsFromSimpleElementSID: null,
    ExecutorsHidden: false,
    FromRoleToRole: 'van Slim scherm naar Opdrachtnemer selectie',
    IncludeAppendices: false,
    Initiators: [
        {
            Executors: [{ ID: 'pir_a21d0d2f-7f53-4ed2-aa4a-6ffb2b0754ad', InitiatorSID: null, Name: 'Opdrachtnemer selectie' }],
            ID: 'pir_2423192d-e49a-4ee2-92fd-a500c4472bb8',
            Name: 'Web formulier',
            Selected: false,
        },
    ],
    InitiatorsVisible: false,
    LabelBericht: 'Bericht',
    LabelExecutors: 'Versturen naar',
    LuaSettings: null,
    MITID: 2888529,
    MessageTypeID: 2075038,
    Notification: null,
    Receiver: null,
    ReplyToMessageID: 0,
    RestoreContent: true,
    SettingTransaction: null,
    ShowFlowchartButton: true,
    Subject: 'AI 2019-0706 - AM07 - -',
    SubjectEnabled: false,
    SubjectGeneration: '[SE:SE_Besteksnummer] - [SE:SE_Volgnummer_aanvraag] - [SE:SE_Stadsdeel] - [SE:SE_Naam_overkoepelend_deelproject]',
    SubjectHidden: true,
    TransactionID: 0,
    TransactionTypeID: 518142,
    UpdateToLatestFramework: false,
};
