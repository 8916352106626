import { formatMessage } from 'devextreme/localization';

// SERVICE
import { Service } from './Service';
import APIService from './APIService';

// UTILS
import { regExMatch, regExPatternEqual } from '../Comparison/Comparison';

// REFERENCES
import {
    MessagePostContent,
    MessagePostContentComplexElement,
    MessagePostContentSimpleElement,
    MessagePostContentTable,
    SharepointFileInfo,
    ThinkprojectFileInfo,
} from './ServiceReference/MessagePostContent';
import {
    Executor,
    NewMessageContent,
    NewMessageContentComplexElement,
    NewMessageContentSimpleElement,
    NewMessageContentTable,
} from './ServiceReference/NewMessageContent';
import Guid from 'devextreme/core/guid';

// A list of dangerous html elements
const DANGEROUS_TAGS = ['script', 'iframe', 'object', 'embed', 'link', 'style'];
// A list of dangerous html attributes
const DANGEROUS_ATTRIBUTES = ['onerror', 'onload', 'onclick', 'onmouseover', 'onfocus', 'onblur'];

/**
 * Checks if a string contains potentially dangerous HTML elements or attributes.
 * This function parses the given HTML string to a document object and searches for
 * predefined dangerous tags and attributes. It is used to prevent XSS attacks by
 * ensuring that the content does not contain harmful HTML.
 *
 * @param {string} htmlString - The HTML string to check for dangerous content.
 * @returns {boolean} True if the string contains dangerous content, false otherwise.
 */
export const containsDangerousHTML = (htmlString: string): boolean => {
    // Parse the HTML string into a document object
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');

    // Check for dangerous tags
    for (const tagName of DANGEROUS_TAGS) {
        if (doc.querySelectorAll(tagName).length > 0) {
            return true; // Found a dangerous tag
        }
    }

    // Check for elements with dangerous attributes
    for (const attrName of DANGEROUS_ATTRIBUTES) {
        if (doc.querySelectorAll(`[${attrName}]`).length > 0) {
            return true; // Found a dangerous attribute
        }
    }

    return false; // No dangerous content found
};

/**
 * Checks if any property of a NewMessageContentSimpleElement object contains potentially
 * dangerous HTML elements or attributes. It uses the `containsDangerousHTML` function
 * to perform this check on the `DisplayValue`, `HTMLValue`, and `Value` properties of the object.
 * If any dangerous content is found, it calls a reject function with an error message
 * and logs the error using `APIService.handleErrors`.
 *
 * @param {NewMessageContentSimpleElement} simpleElement - The object containing the values to be checked.
 * @param {(reason?: any) => void} reject - The reject function from a Promise to be called if dangerous content is found.
 * @param {boolean} [tableSimpleElement=false] - Indicates whether the simple element is part of a table, to customize the error message.
 */
const checkIfValueContainsDangerousCharacters = (
    simpleElement: NewMessageContentSimpleElement,
    reject: (reason?: any) => void,
    tableSimpleElement: boolean = false
) => {
    const ERROR_MESSAGE = {
        response: {
            data: formatMessage(
                tableSimpleElement
                    ? 'vs-Message-Error-SendMessage-Contains-Dangerous-Characters-Table-Field'
                    : 'vs-Message-Error-SendMessage-Contains-Dangerous-Characters-Field',
                simpleElement.Name
            ),
        },
    };

    if (
        containsDangerousHTML(simpleElement?.DisplayValue) ||
        containsDangerousHTML(simpleElement?.HTMLValue) ||
        containsDangerousHTML(simpleElement?.Value)
    ) {
        APIService.handleErrors(ERROR_MESSAGE);
        return reject(ERROR_MESSAGE);
    }
};

/**
 * A function to prepare the Appendices and uploaded files Ids to be posted
 *
 * @function preparePostAppendices
 * @param {NewMessageContentTable[]} sourceAppendices - The source Appendices to be processed
 * @param {(reason?: any) => void} reject - The reject parameter function from Promise constructor
 * @returns {MessagePostContentTable[]} targetAppendices - The processed Appendices to be posted
 * @returns {string[]} uploadedFilesIds - The uploaded files Ids to be posted
 * @returns {SharepointFileInfo[]} sharepointFileInfos - SharePoint files be forwarded
 * @returns {ThinkprojectFileInfo[]} thinkprojectFileInfos - Thinkproject files be forwarded
 *
 */
export const preparePostAppendices = (sourceAppendices: NewMessageContentTable[], reject: (reason?: any) => void) => {
    const targetAppendices = [] as MessagePostContentTable[];
    const uploadedFilesIds: string[] = [];
    const sharepointFileInfos: SharepointFileInfo[] = [];
    const thinkprojectFileInfos: ThinkprojectFileInfo[] = [];

    sourceAppendices.forEach((ap) => {
        const postAppendices = {} as MessagePostContentTable;
        postAppendices.Name = ap.Name;
        postAppendices.ID = ap.ID;
        postAppendices.SID = ap.SID;

        const postAppendicesSimpleElements = [] as MessagePostContentSimpleElement[][];

        ap.Data.forEach((row) => {
            const postRow = [] as MessagePostContentSimpleElement[];

            row.forEach((r) => {
                const postColumn: MessagePostContentSimpleElement = Service.createMessagePostSimpleElement(r);
                if (r.SID !== '__KEY__' && r.SID !== 'ExtraSharePointFileInfo' && r.SID !== 'ExtraThinkprojectFileInfo') postRow.push(postColumn);

                if (r.SID === 'SE_BestandsID') uploadedFilesIds.push(`${r.Value}`);

                if (r.SID === 'ExtraSharePointFileInfo') {
                    const newSharepointFileInfo: SharepointFileInfo = { ...(r.Value as unknown as SharepointFileInfo) };
                    sharepointFileInfos.push(newSharepointFileInfo);

                    // add en empty, C:\work\BSVisiWinfront4\VisiWinControls\User Controls\XtraUcNewMessage.cs
                    const emptyThinkprojectFileInfo: ThinkprojectFileInfo = {
                        DocumentHref: '',
                        FileHref: '',
                    };
                    thinkprojectFileInfos.push(emptyThinkprojectFileInfo);

                    uploadedFilesIds.push(`${new Guid().valueOf()}`); // sharePointFileInfo krijgt een random guid
                }

                if (r.SID === 'ExtraThinkprojectFileInfo') {
                    const newThinkprojectFileInfo: ThinkprojectFileInfo = { ...(r.Value as unknown as ThinkprojectFileInfo) };

                    thinkprojectFileInfos.push(newThinkprojectFileInfo);

                    const emptySharepointFileInfo: SharepointFileInfo = {
                        ID: '',
                        Library: '',
                        Url: '',
                    };
                    sharepointFileInfos.push(emptySharepointFileInfo);

                    uploadedFilesIds.push(`${new Guid().valueOf()}`); // ThinkprojectFileInfo krijgt een random guid
                }
            });

            if (postRow.length > 0) postAppendicesSimpleElements.push(postRow);
        });

        postAppendices.SimpleElements = postAppendicesSimpleElements;

        targetAppendices.push(postAppendices);
    });

    return { targetAppendices, uploadedFilesIds, sharepointFileInfos, thinkprojectFileInfos };
};

/**
 * A function to prepare the Content to be posted
 *
 * @function preparePostContent
 * @param {NewMessageContentComplexElement[]} sourceComplexElements - The source ComplexElements to be processed
 * @param {boolean} lastCheckBeforeSend - The boolean to determine if to check before posting a message
 * @param {(reason?: any) => void} reject - The reject parameter function from Promise constructor
 * @returns {MessagePostContentComplexElement[]} The processed ComplexElements to be posted
 */
export const preparePostContent = (
    sourceComplexElements: NewMessageContentComplexElement[],
    lastCheckBeforeSend: boolean,
    reject: (reason?: any) => void
) => {
    const postComplexElements = [] as MessagePostContentComplexElement[];

    // TODO: Simplify nested loops and split in small functions.
    sourceComplexElements.forEach((ce) => {
        const postComplexElement = {} as MessagePostContentComplexElement;
        postComplexElement.ID = ce.ID;
        postComplexElement.Name = ce.Name;
        postComplexElement.SID = ce.SID;

        const postTables = [] as MessagePostContentTable[];

        ce.Tables.forEach((t) => {
            const postTable = {} as MessagePostContentTable;
            postTable.ID = t.ID;
            postTable.Name = t.Name;
            postTable.SID = t.SID;

            const tabelData = [] as MessagePostContentSimpleElement[][];

            t.Data.forEach((row, rowIndex) => {
                const postRow = [] as MessagePostContentSimpleElement[];

                t.Columns.forEach((column) => {
                    // Make a copy of the original column
                    const currentColumnCopy = { ...column };

                    const inputColumn = {
                        ...column,
                        ...row.find((r) => r.SID === column.SID),
                        BaseType: currentColumnCopy?.BaseType, // Get BaseType original value from Columns.
                        Required: currentColumnCopy?.Required, // Get Required original value from Columns.
                    };

                    // AP 11-10
                    // Checks if the createMessagePost was called by sendMessage()
                    // If true, we check if it contains a column field that is required and the value is null, undefined or an empty string.
                    // If that's the case we reject the promise, and the error message will be displayed.
                    if (
                        lastCheckBeforeSend &&
                        inputColumn.Required &&
                        (inputColumn.Value === null || inputColumn.Value === undefined || inputColumn.Value === '')
                    ) {
                        const columnName = inputColumn.Name ?? currentColumnCopy?.Name;
                        const errorMessageTable = {
                            response: {
                                data: formatMessage(
                                    'vs-Message-Error-SendMessage-Required-Table-Error',
                                    t.Name,
                                    (rowIndex + 1).toString(),
                                    columnName
                                ),
                            },
                        };

                        // Show table error message
                        APIService.handleErrors(errorMessageTable);
                        // Show a general reject message in the console because it shows the error for one element only (also if there are more required elements only).
                        return reject('Validation error: one or more required fields are empty during the attempt to send.');
                    }

                    checkIfValueContainsDangerousCharacters(inputColumn, reject, true);

                    const postColumn = Service.createMessagePostSimpleElement(inputColumn);
                    if (postColumn.SID !== '__KEY__') postRow.push(postColumn);
                });

                if (postRow.length > 0) tabelData.push(postRow);
            });
            postTable.SimpleElements = tabelData;
            postTables.push(postTable);
        });
        postComplexElement.Tables = postTables;

        const postSimpleElements = [] as MessagePostContentSimpleElement[];

        ce.SimpleElements.forEach((se) => {
            // AP 06-10
            // Checks if the createMessagePost was called by sendMessage()
            // If true, we check if it contains a SE field that is required and the value is null, undefined or an empty string.
            // If that's the case we reject the promise, and the error message will be displayed.
            if (lastCheckBeforeSend && se.Required && (se.Value === null || se.Value === undefined || se.Value === '')) {
                const errorMessage = {
                    response: {
                        data: formatMessage('vs-Message-Error-SendMessage-Required-Field-Empty', se.Name),
                    },
                };

                APIService.handleErrors(errorMessage);
                // Show a general reject message in the console because it shows the error for one element only (also if there are more required elements only).
                return reject('Validation error: one or more required fields are empty during the attempt to send.');
            }

            checkIfValueContainsDangerousCharacters(se, reject);

            const postSimpleElement = Service.createMessagePostSimpleElement(se);
            postSimpleElements.push(postSimpleElement);
        });

        postComplexElement.SimpleElements = postSimpleElements;
        postComplexElements.push(postComplexElement);
    });

    return postComplexElements;
};

/**
 * A function to prepare the initiatorPersoonRolIds Array
 *
 * @function prepareInitiatorPersoonRolSIDs
 * @param {Executor[]} sourceExecutors - The source Executors Array
 * @param {string[]} selectedExecutors - The selected Executors' SIDs array
 * @returns {string[]} The prepared initiatorPersoonRolIds
 */
export const prepareInitiatorPersoonRolSIDs = (sourceExecutors: Executor[], selectedExecutors: string[]): string[] => {
    const initiatorPersoonRolIds: string[] = [];

    selectedExecutors.forEach((executorId) => {
        const foundExecutor = sourceExecutors.find((executor) => executor.ID === executorId);

        if (foundExecutor && foundExecutor?.InitiatorSID) initiatorPersoonRolIds.push(foundExecutor.InitiatorSID);
    });

    return initiatorPersoonRolIds;
};

/**
 * A function that checks if input basetype is one of the expected calculation basetypes `STRING` or `DECIMAL` or `INTEGER`
 *
 * @function checkSimpleElementBaseType
 * @param {string} sourceSimpleElementBaseType - The source simple element basetype
 * @returns {boolean} A boolean of the input basetype
 */
const checkSimpleElementBaseType = (sourceSimpleElementBaseType: string): boolean => {
    const sourceSEToUpperCase = sourceSimpleElementBaseType.toUpperCase();
    return sourceSEToUpperCase === 'STRING' || sourceSEToUpperCase === 'DECIMAL' || sourceSEToUpperCase === 'INTEGER';
};

/**
 * A function that processes dat input to prepare it for calculation api
 *
 * @function processMessagePostCalculationData
 * @param {MessagePostContent} messageData - Message data to be processed
 * @param {NewMessageContent} newMessageContentData - The source message content data
 * @returns {Promise<MessagePostContent>} The processed message data
 */
export const processMessagePostCalculationData = (
    messageData: MessagePostContent,
    newMessageContentData: NewMessageContent
): Promise<MessagePostContent> => {
    messageData.ConceptID = null; // Reset unnecessary payload data in calculation payload
    for (const content of messageData.Content) {
        const sourceComplexElement = newMessageContentData.ComplexElements.find((complexElement) => complexElement.SID === content.SID);

        for (const targetSE of content.SimpleElements) {
            const sourceSimpleElement = sourceComplexElement.SimpleElements.find((sourceSimpleElement) => sourceSimpleElement.SID === targetSE.SID);

            if (
                sourceSimpleElement?.IsCalculation &&
                checkSimpleElementBaseType(sourceSimpleElement?.BaseType) &&
                targetSE?.Value != null &&
                targetSE?.Value !== ''
            ) {
                const { RegExPattern } = sourceSimpleElement;
                let value = targetSE?.Value?.toString();

                if (regExPatternEqual(RegExPattern, 'optionalDecimalString') && !regExMatch(value, 'optionalDecimalString')) {
                    value = value?.indexOf(',') === -1 ? value?.replace('.', ',') : value?.replaceAll('.', '');
                }

                targetSE.Value = value;
                targetSE.DisplayValue = null;
            }
        }

        for (const targetTable of content.Tables) {
            const sourceTableElement = sourceComplexElement.Tables.find((sourceTableElement) => sourceTableElement.SID === targetTable.SID);

            for (const targetTableSimpleElements of targetTable.SimpleElements) {
                for (const targetTableSE of targetTableSimpleElements) {
                    const sourceTableColumn = sourceTableElement.Columns.find((sourceTableColumn) => sourceTableColumn.SID === targetTableSE.SID);

                    if (
                        sourceTableColumn?.IsCalculation &&
                        checkSimpleElementBaseType(sourceTableColumn?.BaseType) &&
                        targetTableSE?.Value != null &&
                        targetTableSE?.Value !== ''
                    ) {
                        const { RegExPattern } = sourceTableColumn;
                        let value = targetTableSE?.Value?.toString();

                        if (regExPatternEqual(RegExPattern, 'optionalDecimalString') && !regExMatch(value, 'optionalDecimalString')) {
                            value = value?.indexOf(',') === -1 ? value?.replace('.', ',') : value?.replaceAll('.', '');
                        }

                        targetTableSE.Value = value;
                        targetTableSE.DisplayValue = null;
                    }
                }
            }
        }
    }
    // Reset unnecessary payload data in the calculation payload
    messageData.ExecutorPersoonRolSIDs = null;
    messageData.InitiatorPersoonRolSID = null;
    messageData.InitiatorPersoonRolSIDs = null;
    messageData.IsSubTransaction = null;
    messageData.MITID = 0;
    messageData.ReplyToMessageID = null;
    messageData.Subject = null;
    messageData.TransactionID = null;

    return Promise.resolve(messageData);
};

/**
 * Finds an executor based on the given simple element and executor list.
 *
 * @function findExecutor
 * @param {NewMessageContentSimpleElement} simpleElement - The simple element to search for.
 * @param {Executor[]} executors - The list of executors to search within.
 * @returns {string|null} The ID of the executor if found, otherwise null.
 */
const findExecutor = (simpleElement: NewMessageContentSimpleElement, executors: Executor[]) => {
    // Find the executor based on whether the executor's name is included in the simple element's value or display value.
    const executor = executors.find((executor) => {
        return simpleElement.Value.includes(executor.Name) || simpleElement.DisplayValue.includes(executor.Name);
    });

    return executor ? executor.ID : null;
};

/**
 * Sets executor IDs based on the provided `ExecutorsFromSimpleElementSID` in `NewMessageContent`.
 *
 * @function setExecutorsFromSimpleElements
 * @param {NewMessageContent} content - The message content containing complex elements, executors, and ExecutorsFromSimpleElementSID.
 * @returns {string[]} An array of executor IDs.
 */
export const setExecutorsFromSimpleElements = (content: NewMessageContent) => {
    // Extract required properties from the content object.
    const { ComplexElements, Executors, ExecutorsFromSimpleElementSID } = content;

    // Initialize an array to store unique executor IDs.
    const executorPersoonRolIds = ComplexElements.flatMap((complexElement) => [
        ...complexElement.SimpleElements.filter((simpleElement) => simpleElement.SID === ExecutorsFromSimpleElementSID),
        ...complexElement.Tables.flatMap((table) =>
            table.Data.flatMap((row) => row.filter((column) => column.SID === ExecutorsFromSimpleElementSID))
        ),
    ]).reduce((executorIds: string[], element: NewMessageContentSimpleElement) => {
        const personInRolId = findExecutor(element, Executors);

        if (personInRolId && !executorIds.includes(personInRolId)) {
            executorIds.push(personInRolId);
        }
        return executorIds;
    }, []);

    // Return the array of unique executor IDs.
    return executorPersoonRolIds;
};
