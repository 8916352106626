import React, { createContext } from 'react';
import { MessageContent } from '../../utils/Service/ServiceReference/MessageContent';
import FlowchartInfo from '../../utils/Service/ServiceReference/FlowchartInfo';
import { TransactionRecord } from '../../utils/Service/ServiceReference/TransactionRecord';

export interface TabItem {
    text: string;
    value: string;
    fullSize: boolean;
    icon: string;
    element?: any;
    closeable?: boolean;
    id?: string;
    linkID?: number;
    isLinkIdOpened?: boolean;
    type?:
        | 'flowChart'
        | 'help'
        | 'message'
        | 'messageInNewTab'
        | 'newMessage'
        | 'pendingActions'
        | 'report'
        | 'settings'
        | 'transactionComplete'
        | 'transactionOverview';
    messageContent?: MessageContent;
    totalCount?: number;
    flowchartInfo?: FlowchartInfo;
    name?: string;
    rootTransaction?: TransactionRecord;
    params?: string[] | null;
    originalLeafId?: string;
}

export interface TabsContextProps {
    TabList: TabItem[];
    SelectedTab: TabItem;
}

const sessionStorageData = JSON.parse(sessionStorage.getItem('tabsData'));

export const INITIAL_TABS_STATE: TabsContextProps = {
    TabList: sessionStorageData?.Tabs
        ? sessionStorageData?.Tabs
        : [
              {
                  fullSize: false,
                  icon: 'vs-icon-message-open',
                  closeable: false,
                  type: 'transactionOverview',
                  totalCount: null,
              },
          ],
    SelectedTab: sessionStorageData
        ? sessionStorageData?.SelectedTab
        : {
              fullSize: false,
              icon: 'vs-icon-message-open',
              closeable: false,
              type: 'transactionOverview',
              totalCount: null,
          },
};

export const dispatchTabsState: React.Dispatch<any> = () => {};

export const TabsContext = createContext<[TabsContextProps, React.Dispatch<any>]>([INITIAL_TABS_STATE, dispatchTabsState]);
