import { loadMessages, locale } from 'devextreme/localization';
import nlMessages from '../../assets/localization/nl.json';
import enMessages from '../../assets/localization/en.json';

export const setLanguage = (language?: string) => {
    switch (true) {
        case language?.includes('en'):
            loadMessages(enMessages);
            locale('en-GB');
            break;
        case language?.includes('nl'):
            loadMessages(nlMessages);
            locale('nl-NL');
            break;
        default:
            loadMessages(nlMessages);
            locale('nl-NL');
            break;
    }
};
