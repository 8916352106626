import { useState, useEffect } from 'react';
import useShouldShowPrompt from './useShouldShowPrompt';
import { useScreenSize, ScreenSizeProps } from '../ViewPorts/mediaQuery';

const androidInstallPromptedAt = 'androidInstallPromptedAt';

export const isAndroid = (screenSize?: ScreenSizeProps): boolean => {
    if ((navigator as any).standalone) {
        // user has already installed the app
        return false;
    }
    const ua = window.navigator.userAgent;
    const isAndroid = !!ua.match(/Android/i) && (screenSize?.isXSmall || screenSize?.isSmall);
    return isAndroid;
};

const useAndroidInstallPrompt = (): [any, () => void, () => void] => {
    const [installPromptEvent, setInstallPromptEvent] = useState(null);
    const [userShouldBePromptedToInstall, handleUserSeeingInstallPrompt] = useShouldShowPrompt(androidInstallPromptedAt);
    const screenSize = useScreenSize();

    useEffect(() => {
        const beforeInstallPromptHandler = (event) => {
            event.preventDefault();

            // check if user has already been asked
            if (isAndroid(screenSize) && userShouldBePromptedToInstall) {
                // store the event for later use
                setInstallPromptEvent(event);
            }
        };
        window.addEventListener('beforeinstallprompt', beforeInstallPromptHandler);
        return () => window.removeEventListener('beforeinstallprompt', beforeInstallPromptHandler);
    }, [userShouldBePromptedToInstall]);

    const handleInstallDeclined = () => {
        handleUserSeeingInstallPrompt();
        setInstallPromptEvent(null);
    };

    const handleInstallAccepted = () => {
        // show native prompt
        installPromptEvent?.prompt();

        // decide what to do after the user chooses
        installPromptEvent?.userChoice.then((choice) => {
            // if the user declined, we don't want to show the prompt again
            if (choice?.outcome !== 'accepted') {
                handleUserSeeingInstallPrompt();
            }
            setInstallPromptEvent(null);
        });
    };
    return [installPromptEvent, handleInstallDeclined, handleInstallAccepted];

    // return [isAndroid(screenSize) && userShouldBePromptedToInstall, handleUserSeeingInstallPrompt];
};
export default useAndroidInstallPrompt;
