import React, { useState, useMemo, useCallback, useRef } from 'react';
import DataSource from 'devextreme/data/data_source';
import { formatMessage, formatNumber, locale } from 'devextreme/localization';
import HtmlEditor, { MediaResizing } from 'devextreme-react/html-editor';
import { CheckBox, TextArea, TextBox, NumberBox, SelectBox, DateBox, RadioGroup } from 'devextreme-react';
import { Validator, RequiredRule, PatternRule } from 'devextreme-react/validator';
import moment from 'moment';

// REFERENCES
import { NewMessageContentSimpleElement } from '../../utils/Service/ServiceReference/NewMessageContent';
import { ValueChangedEvent } from 'devextreme/ui/html_editor_types';

// TEMPLATES
import DropDownWrapper from '../DropDownWrapper/DropDownWrapper';

// UTILS
import CreateLink from '../../utils/CreateLink/CreateLink';
import { formatToNLLocale } from '../../utils/ParseToLocaleString/parseToLocaleString';
import { parseToNumber } from '../../utils/ParseToNumber/parseToNumber';
import { regExPatternEqual, regExMatch } from '../../utils/Comparison/Comparison';
import toDataFormat from '../../utils/DataFormat/DataFormat';

// STYLE
import './BaseTypeSwitch.scss';

interface EditorProps {
    CE?: any;
    children?: React.ReactNode;
    data: {};
    defaultValue: string;
    disabled: boolean;
    frontEndUID: string;
    ID: number;
    messageValueChanged?: any;
    Name: string;
    readOnly: boolean;
    SID: string;
}

const Editor: React.FC<EditorProps> = ({ CE, data, disabled, frontEndUID, ID, messageValueChanged, Name, readOnly, SID }) => {
    const [htmlValue, setHTMLValue] = useState<null | ValueChangedEvent>(null);
    const htmlToolbar: any = useMemo(() => {
        return {
            items: [
                'undo',
                'redo',
                'separator',
                'bold',
                'italic',
                'strike',
                'superscript',
                'subscript',
                {
                    name: 'header',
                    acceptedValues: [false, 1, 2, 3, 4, 5],
                },
                {
                    name: 'size',
                    acceptedValues: ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'],
                },
                {
                    name: 'font',
                    acceptedValues: ['Arial', 'Courier New', 'Georgia', 'Impact', 'Lucida Console', 'Tahoma', 'Times New Roman', 'Verdana'],
                },
                'color',
                'background',
                'separator',
                'orderedList',
                'bulletList',
                'alignLeft',
                'alignCenter',
                'alignRight',
                'alignJustify',
                'link',
                'separator',
                'insertTable',
                'deleteTable',
                'insertRowAbove',
                'insertRowBelow',
                'deleteRow',
                'image',
                'separator',
                'clear',
                'codeBlock',
                'blockquote',
                'separator',
                'insertColumnLeft',
                'insertColumnRight',
                'deleteColumn',
            ],
            multiline: false,
        };
    }, []);

    const handleFocusOut = useCallback(() => {
        return messageValueChanged(htmlValue, CE, Name, SID);
    }, [htmlValue]);

    return (
        <HtmlEditor
            key={`htmleditor-component-${SID}-${ID}-${frontEndUID}`}
            defaultValue={data}
            onFocusOut={handleFocusOut}
            onValueChanged={(e: ValueChangedEvent) => setHTMLValue(e)}
            disabled={disabled}
            readOnly={disabled}
            toolbar={htmlToolbar}
        >
            <MediaResizing enabled={false} />
        </HtmlEditor>
    );
};

/**
 * Converts an object into an array of objects, where each object contains a `key` and `value` pair.
 *
 * @param {Record<string, any>} Values - The input object where each property key will become a `key` in the output array,
 * and the corresponding property value will become the `value`.
 * @returns {Array<{ key: string, value: any }>} An array of objects, each with a `key` and `value` pair.
 */
const toSelectList = (Values: Record<string, any>): Array<{ key: string; value: any }> => {
    const list = [];

    for (const key in Values) {
        list.push({
            key,
            value: Values[key],
        });
    }
    return list;
};

// het DateBox component verwacht een datum waar het iets mee kan, ik probeer ze zo goed mogelijk te parsen
export const parseDate = (value: string, basetype: string): any => {
    if (!value || value === '') return null;

    let parsedDate = null;

    const matchDate = (value: string, reg: RegExp) => {
        return reg.exec(value);
    };

    let tempTime = null;
    let tempTime2 = null;
    switch (basetype.toLowerCase()) {
        case 'time':
            // probeer de valuestring te parsen. we verwachten een formaat zoals dit: 14:54
            tempTime = matchDate(value, /^(\d\d):(\d\d)$/);
            if (tempTime != null) {
                parsedDate = new Date();
                parsedDate.setHours((tempTime as any[])[1]);
                parsedDate.setMinutes((tempTime as any[])[2]);
            }

            break;
        case 'datetime':
            // Number.isNaN(Date.parse(value)) is gebruikt om te checken of de waarde wel een datum is of niet
            if (Number.isNaN(Date.parse(value))) {
                console.log('isNaN not valid date?', Number.isNaN(Date.parse(value)), value, basetype);
                parsedDate = null;
                return;
            }

            // probeer de valuestring te parsen. we verwachten een formaat zoals dit: 2017-05-26T12:59:00+02:00

            // 2016-10-01T00:00:00
            // 2017-05-31T15:01:00+02:00
            // 2017-04-05T16:49:00+02:00
            tempTime = matchDate(value, /^(\d\d\d\d)-(\d\d)-(\d\d)T(\d\d):(\d\d):?(\d\d)?\+?(\d\d)?:?(\d\d)?$/);
            tempTime2 = matchDate(value, /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/);
            if (tempTime != null) {
                parsedDate = new Date();
                parsedDate.setFullYear((tempTime as any[])[1]);
                parsedDate.setMonth(parseInt((tempTime as any[])[2]) - 1); // setMonth is 0 based
                parsedDate.setDate((tempTime as any[])[3]);
                parsedDate.setHours((tempTime as any[])[4]);
                parsedDate.setMinutes((tempTime as any[])[5]);
            } else if (tempTime2 != null) {
                parsedDate = new Date(tempTime2[0]);
            } else {
                parsedDate = value;
            }

            break;
        case 'date':
            // probeer de valuestring te parsen. we verwachten een formaat zoals dit: 18-05-2017
            tempTime = matchDate(value, /^(\d\d)-(\d\d)-(\d\d\d\d)$/);
            if (tempTime != null) {
                parsedDate = new Date();
                parsedDate.setFullYear((tempTime as any[])[3]);
                parsedDate.setMonth(parseInt((tempTime as any[])[2]) - 1); // setMonth is 0 based
                parsedDate.setDate((tempTime as any[])[1]);
            }

            tempTime = null;
            tempTime = matchDate(value, /^(\d\d\d\d)-(\d\d)-(\d\d)$/);
            // probeer de valuestring te parsen. we verwachten een formaat zoals dit: 2016-10-01
            if (tempTime != null) {
                parsedDate = new Date();
                parsedDate.setFullYear((tempTime as any[])[1]);
                parsedDate.setMonth(parseInt((tempTime as any[])[2]) - 1); // setMonth is 0 based
                parsedDate.setDate((tempTime as any[])[3]);
            }

            if (/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(value)) {
                parsedDate = new Date(value);
            }

            if (parsedDate == null) {
                try {
                    parsedDate = new Date(value);
                } catch (error) {
                    console.log(error);
                }
            }

            break;
        default:
            parsedDate = null;
            break;
    }
    if (parsedDate == null && value != null && value !== '') {
        console.error('Kon de datum string niet parsen', parsedDate, value, basetype);
    }
    return parsedDate;
};

const BaseTypeSwitch: React.FC<NewMessageContentSimpleElement> = ({
    BaseType,
    berichtWaardeVeranderd,
    CE,
    Decimals,
    DisplayValue,
    frontEndUID,
    HTMLValue,
    ID,
    IsCalculation,
    IsHTML,
    IsLink,
    isWrite,
    Locked,
    MaxLength,
    MinLength,
    Name,
    RegExPattern,
    Required,
    SID,
    TransactionListBasedOn,
    Value,
    Values,
}) => {
    const refSimpleElementDateBox = useRef<DateBox>(null);
    const refSimpleElementSelectBox = useRef<SelectBox>(null);
    const basetype = BaseType?.toLowerCase();
    const disabled = isWrite && Locked ? isWrite : undefined;

    const valueChanged = useCallback((e: any) => berichtWaardeVeranderd({ e, ID, CE, SID }), []);

    /**
     * Memoized computation of select box options based on the `basetype` and `Values`.
     *
     * If `basetype` is 'select', it converts the `Values` object into an array of key-value pairs
     * using `toSelectList`. Otherwise, it returns an empty array.
     *
     * The result is memoized to avoid unnecessary recalculations unless `basetype` or `Values` change.
     *
     * @type {Array<{ key: string, value: any }>}
     */
    const memoSelectBoxOptions = useMemo(() => {
        return basetype === 'select' ? toSelectList(Values) : [];
    }, [basetype, Values]);

    /**
     * Determines if a BaseType 'select' should be displayed as a radio group component.
     * The component will be displayed as a radio group if:
     * - The basetype is equal to 'select'
     * - Locked is false
     * - The amount of options is between 1 and 6 (inclusive)
     * - TransactionListBasedOn is equal to null
     *
     * @constant
     * @type {boolean}
     * @param {string} basetype - The base type of the component.
     * @param {boolean} Locked - Indicates if the component is locked.
     * @param {Array} memoSelectBoxOptions - The list of options for the select box.
     * @param {any} TransactionListBasedOn - The basis for the transaction list, should be null.
     * @returns {boolean} - Returns true if the select box should be displayed as a radio group, otherwise false.
     */
    const memoShowBasetypeSelectAsRadioGroup = useMemo(() => {
        return (
            basetype === 'select' && !Locked && memoSelectBoxOptions.length > 0 && memoSelectBoxOptions.length < 7 && TransactionListBasedOn === null
        );
    }, [basetype, IsCalculation, Locked, memoSelectBoxOptions, TransactionListBasedOn]);

    if (isWrite) {
        if (HTMLValue || IsHTML) {
            const renderValue = HTMLValue || DisplayValue;

            return (
                <Editor
                    key={`basetypeswitch-write-htmleditor-${CE?.SID}-${SID}-${ID}-${frontEndUID}`}
                    data={renderValue}
                    defaultValue={renderValue}
                    disabled={disabled}
                    frontEndUID={frontEndUID}
                    ID={ID}
                    messageValueChanged={valueChanged}
                    Name={Name}
                    readOnly={Locked}
                    SID={SID}
                >
                    <Validator>
                        {Required && <RequiredRule message={`${Name}  ${formatMessage('vs-Message-Validation-BaseTypeSwitch')}`} />}
                        {RegExPattern && <PatternRule pattern={RegExPattern} message={`${Name} ${formatMessage('vs-WriteTable-Input-Mask')}`} />}
                    </Validator>
                </Editor>
            );
        }

        let format = null;
        let selectBoxDataSource;
        let numberValue: number;

        switch (basetype) {
            case 'integer':
            case 'decimal':
                format = {
                    type: 'fixedPoint',
                    precision: basetype === 'integer' ? 0 : (Decimals ?? 2),
                };

                // Save the number value
                if (typeof Value === 'number') numberValue = Value;

                return (
                    <NumberBox
                        key={`basetypeswitch-write-numberbox-${CE?.SID}-${SID}-${ID}-${frontEndUID}`}
                        defaultValue={
                            Value == null || Value === ''
                                ? null
                                : typeof Value === 'number'
                                  ? numberValue
                                  : basetype === 'integer'
                                    ? parseToNumber(Value, format)
                                    : Value?.indexOf(',') === -1
                                      ? parseFloat(Value)
                                      : parseToNumber(Value)
                        }
                        disabled={disabled}
                        elementAttr={{ id: `se-${frontEndUID}-${SID}` }}
                        format={format}
                        inputAttr={{ elementSID: `${SID}-${frontEndUID}` }}
                        max={MaxLength}
                        min={MinLength}
                        onValueChanged={valueChanged}
                        readOnly={Locked}
                        showClearButton={true}
                        step={0}
                    >
                        <Validator>
                            {Required && <RequiredRule message={`${Name}  ${formatMessage('vs-Message-Validation-BaseTypeSwitch')}`} />}
                            {/* 
                                De regulier expressie voor een numberbox is er compleet uitgehaald, 
                                dit slaat bij nader inzien nergens op want een reguliere expressie is voor strings 
                            */}
                        </Validator>
                    </NumberBox>
                );

            case 'time':
                return (
                    <DropDownWrapper
                        key={`basetypeswitch-write-dropdownwrapper-timebox-${CE?.SID}-${SID}-${ID}-${frontEndUID}`}
                        ref={refSimpleElementDateBox}
                    >
                        <DateBox
                            key={`basetypeswitch-write-timebox-${CE?.SID}-${SID}-${ID}-${frontEndUID}`}
                            ref={refSimpleElementDateBox}
                            defaultValue={parseDate(DisplayValue, 'time')}
                            displayFormat={toDataFormat('time')}
                            elementAttr={{ id: `se-${frontEndUID}-${SID}` }}
                            onValueChanged={valueChanged}
                            openOnFieldClick={true}
                            readOnly={Locked}
                            type='time'
                            useMaskBehavior={true}
                        >
                            <Validator>
                                {Required && <RequiredRule message={`${Name}  ${formatMessage('vs-Message-Validation-BaseTypeSwitch')}`} />}
                                {RegExPattern && (
                                    <PatternRule pattern={RegExPattern} message={`${Name} ${formatMessage('vs-WriteTable-Input-Mask')}`} />
                                )}
                            </Validator>
                        </DateBox>
                    </DropDownWrapper>
                );

            case 'datetime':
                return (
                    <DropDownWrapper
                        key={`basetypeswitch-write-dropdownwrapper-datetimebox-${CE?.SID}-${SID}-${ID}-${frontEndUID}`}
                        ref={refSimpleElementDateBox}
                    >
                        <DateBox
                            key={`basetypeswitch-write-datetimebox-${CE?.SID}-${SID}-${ID}-${frontEndUID}`}
                            ref={refSimpleElementDateBox}
                            calendarOptions={{ showWeekNumbers: true }}
                            defaultValue={parseDate(moment(DisplayValue).format('YYYY-MM-DDTHH:mm:ss'), 'datetime')}
                            displayFormat={toDataFormat('datetime')}
                            elementAttr={{ id: `se-${frontEndUID}-${SID}` }}
                            onValueChanged={valueChanged}
                            openOnFieldClick={true}
                            readOnly={Locked}
                            type='datetime'
                            useMaskBehavior={true}
                        >
                            <Validator>
                                {Required && <RequiredRule message={`${Name}  ${formatMessage('vs-Message-Validation-BaseTypeSwitch')}`} />}
                                {RegExPattern && (
                                    <PatternRule pattern={RegExPattern} message={`${Name} ${formatMessage('vs-WriteTable-Input-Mask')}`} />
                                )}
                            </Validator>
                        </DateBox>
                    </DropDownWrapper>
                );
            case 'date':
                return (
                    <DropDownWrapper
                        key={`basetypeswitch-write-dropdownwrapper-datebox-${CE?.SID}-${SID}-${ID}-${frontEndUID}`}
                        ref={refSimpleElementDateBox}
                    >
                        <DateBox
                            key={`basetypeswitch-write-datebox-${CE?.SID}-${SID}-${ID}-${frontEndUID}`}
                            ref={refSimpleElementDateBox}
                            calendarOptions={{ showWeekNumbers: true }}
                            defaultValue={parseDate(Value, 'date')}
                            displayFormat={toDataFormat('date')}
                            elementAttr={{ id: `se-${frontEndUID}-${SID}` }}
                            onValueChanged={valueChanged}
                            openOnFieldClick={true}
                            readOnly={Locked}
                            type='date'
                            useMaskBehavior={true}
                        >
                            <Validator>
                                {Required && <RequiredRule message={`${Name}  ${formatMessage('vs-Message-Validation-BaseTypeSwitch')}`} />}
                                {RegExPattern && (
                                    <PatternRule pattern={RegExPattern} message={`${Name} ${formatMessage('vs-WriteTable-Input-Mask')}`} />
                                )}
                            </Validator>
                        </DateBox>
                    </DropDownWrapper>
                );

            case 'boolean':
                return (
                    <div className='case-boolean'>
                        <CheckBox
                            key={`basetypeswitch-write-booleanbox-${CE?.SID}-${SID}-${ID}-${frontEndUID}`}
                            defaultValue={DisplayValue && DisplayValue.length > 0 ? DisplayValue === 'true' : false}
                            elementAttr={{ id: `se-${frontEndUID}-${SID}` }}
                            onValueChanged={valueChanged}
                            readOnly={Locked}
                        />
                    </div>
                );

            case 'select':
                selectBoxDataSource = new DataSource({
                    store: memoSelectBoxOptions,
                    paginate: true,
                    pageSize: 10,
                });

                return memoShowBasetypeSelectAsRadioGroup ? (
                    <RadioGroup
                        key={`basetypeswitch-write-radiogroup-${CE?.SID}-${SID}-${ID}-${frontEndUID}`}
                        className='basetypeswitch-radiogroup'
                        dataSource={selectBoxDataSource}
                        defaultValue={Value}
                        disabled={Locked}
                        displayExpr={'value'}
                        elementAttr={{ id: `se-${frontEndUID}-${SID}` }}
                        layout={memoSelectBoxOptions.length < 4 ? 'horizontal' : 'vertical'}
                        onValueChanged={valueChanged}
                        readOnly={Locked}
                        valueExpr={'key'}
                    >
                        {Required && (
                            <Validator>
                                <RequiredRule message={`${Name}  ${formatMessage('vs-Message-Validation-BaseTypeSwitch')}`} />
                            </Validator>
                        )}
                    </RadioGroup>
                ) : (
                    <DropDownWrapper
                        key={`basetypeswitch-write-dropdownwrapper-selectbox-${CE?.SID}-${SID}-${ID}-${frontEndUID}`}
                        ref={refSimpleElementSelectBox}
                    >
                        <SelectBox
                            key={`basetypeswitch-write-selectbox-${CE?.SID}-${SID}-${ID}-${frontEndUID}`}
                            ref={refSimpleElementSelectBox}
                            dataSource={selectBoxDataSource}
                            defaultValue={Value}
                            disabled={Locked}
                            displayExpr={'value'}
                            displayValue={Value}
                            elementAttr={{ id: `se-${frontEndUID}-${SID}` }}
                            onContentReady={(e) => {
                                // auto selecteer de value als er maar 1 waarde is gevonden
                                if (memoSelectBoxOptions.length === 1) {
                                    e.component.option('value', memoSelectBoxOptions[0]?.key);
                                    if (Value !== memoSelectBoxOptions[0]?.key) {
                                        const valueChangedObject = { ID, CE, SID, e: { value: memoSelectBoxOptions[0]?.key } };
                                        berichtWaardeVeranderd(valueChangedObject);
                                        e.component.blur();
                                    }
                                }
                            }}
                            onValueChanged={valueChanged}
                            openOnFieldClick={true}
                            placeholder={formatMessage('vs-Message-Select')}
                            searchEnabled={true}
                            valueExpr={'key'}
                        >
                            {Required && (
                                <Validator>
                                    <RequiredRule message={`${Name}  ${formatMessage('vs-Message-Validation-BaseTypeSwitch')}`} />
                                </Validator>
                            )}
                        </SelectBox>
                    </DropDownWrapper>
                );

            default:
                // JA 12-08-2024: In US 14094, some required fields were requested to be multiline.
                // These fields had a MinLength of 1 and were Required.
                // Therefore, fields with a MinLength of 1 are handled here and ignored for being a textbox.
                if (RegExPattern != null || MinLength > 1 || MaxLength != null) {
                    // eslint-disable-next-line no-case-declarations
                    let mask = null;

                    // kijk naar maskrules waar we iets mee kunnen
                    switch (RegExPattern) {
                        case '^(20\\d\\d)?$':
                        case '^(20\\d\\d)$':
                            if (Required) mask = '2\\000';
                            break;
                    }
                    if (MaxLength && MinLength && RegExPattern == null) {
                        // minlength werkt niet met devextreme we willen een min en een max length, dat kan met een regexp
                        RegExPattern = `^.{${MinLength},}$`;
                    }

                    return (
                        <TextBox
                            key={`basetypeswitch-write-textbox-${CE?.SID}-${SID}-${ID}-${frontEndUID}`}
                            defaultValue={Value}
                            elementAttr={{ id: `se-${frontEndUID}-${SID}` }}
                            inputAttr={{ elementSID: `${SID}-${frontEndUID}` }}
                            mask={mask}
                            maxLength={MaxLength}
                            onValueChanged={valueChanged}
                            readOnly={Locked}
                            useMaskedValue={true}
                        >
                            <Validator>
                                {Required && <RequiredRule message={`${Name}  ${formatMessage('vs-Message-Validation-BaseTypeSwitch')}`} />}
                                {RegExPattern && (
                                    <PatternRule pattern={RegExPattern} message={`${Name} ${formatMessage('vs-WriteTable-Input-Mask')}`} />
                                )}
                            </Validator>
                        </TextBox>
                    );
                } else
                    return (
                        <TextArea
                            key={`basetypeswitch-write-textarea-${CE?.SID}-${SID}-${ID}-${frontEndUID}`}
                            autoResizeEnabled={true}
                            defaultValue={Value}
                            elementAttr={{ id: `se-${frontEndUID}-${SID}` }}
                            inputAttr={{ elementSID: `${SID}-${frontEndUID}` }}
                            maxHeight={500}
                            minHeight={34}
                            onValueChanged={valueChanged}
                            readOnly={Locked}
                        >
                            <Validator key={`basetypeswitch-write-textarea-validator-${CE?.SID}-${SID}-${ID}-${frontEndUID}`}>
                                {Required && <RequiredRule message={`${Name}  ${formatMessage('vs-Message-Validation-BaseTypeSwitch')}`} />}
                                {RegExPattern && (
                                    <PatternRule pattern={RegExPattern} message={`${Name} ${formatMessage('vs-WriteTable-Input-Mask')}`} />
                                )}
                            </Validator>
                        </TextArea>
                    );
        }
    } else {
        const matchDate = (value: string, reg: RegExp) => {
            return reg.exec(value);
        };

        let formatedDate = null;
        if (BaseType.toLowerCase() === 'date') {
            if (Value) {
                formatedDate = parseDate(Value, 'date'); // matchDate(Value, /[0-9]{2}[-|/]{1}[0-9]{2}[-|/]{1}[0-9]{4}/);
                if (formatedDate == null) {
                    formatedDate = matchDate(Value, /([0-9]{2})[-|/]([0-9]{2})[-|/]([0-9]){4}/);
                }
            } else if (DisplayValue) {
                formatedDate = matchDate(DisplayValue, /[0-9]{2}[-|/]{1}[0-9]{2}[-|/]{1}[0-9]{4}/);
            } else {
                formatedDate = null;
            }
        }

        switch (basetype) {
            case 'string':
                // Check if the SE contains a regular expression, if the pattern is equal to the 'currencyNL' pattern and the DisplayValue is not an empty string
                if (RegExPattern != null && regExPatternEqual(RegExPattern, 'currencyNL') && DisplayValue?.length > 0) {
                    // Check if the DisplayValue does not match the 'currencyNL' pattern and if not, format it to the NL pattern
                    if (!regExMatch(DisplayValue, 'strictCurrencyFormatNL')) {
                        DisplayValue = formatToNLLocale(DisplayValue);
                    }
                }

                if (IsLink === true) {
                    // check of het een visi link is en een id
                    return (
                        <>
                            <CreateLink
                                key={`basetypeswitch-read-createlink-intern-${CE?.SID}-${SID}-${ID}-${frontEndUID}`}
                                Content={
                                    typeof HTMLValue === 'string' && HTMLValue.length > 0 ? HTMLValue : DisplayValue
                                } /* kijk of het een htmlvalue heeft */
                                Intern={true}
                                Value={Value}
                            />
                        </>
                    );
                } else {
                    // check of het een externe link is
                    return (
                        <>
                            <CreateLink
                                key={`basetypeswitch-read-createlink-extern-${CE?.SID}-${SID}-${ID}-${frontEndUID}`}
                                /* kijk of het een htmlvalue heeft */
                                Content={typeof HTMLValue === 'string' && HTMLValue.length > 0 ? HTMLValue : DisplayValue}
                                Intern={false}
                                Value={''}
                            />
                        </>
                    );
                }
            case 'integer':
            case 'decimal':
                // eslint-disable-next-line no-case-declarations
                const format = {
                    type: 'fixedPoint',
                    precision: BaseType?.toLowerCase() === 'integer' ? 0 : 2,
                };
                if (Decimals != null && Decimals > 0) {
                    format.precision = Decimals;
                }
                // eslint-disable-next-line no-case-declarations
                const returnValue = Value == null || '' ? '' : formatNumber(parseFloat(Value), format);
                // console.log('returnValue', returnValue);
                // GS dit is een ietwat rare constructie maar hij komt met decimal terug als 4444444.00
                return <div key={`basetypeswitch-read-${BaseType?.toLowerCase()}-${CE?.SID}-${SID}-${ID}-${frontEndUID}`}>{returnValue}</div>;

            case 'boolean':
                return (
                    <div className='case-boolean'>
                        <CheckBox
                            key={`basetypeswitch-read-boolean-${CE?.SID}-${SID}-${ID}-${frontEndUID}`}
                            defaultValue={Value.toString() === 'true'}
                            onValueChanged={valueChanged}
                            readOnly={Locked}
                        />
                    </div>
                );

            case 'date':
                if (formatedDate) {
                    return (
                        <div key={`basetypeswitch-read-date-${CE?.SID}-${SID}-${ID}-${frontEndUID}`}>
                            {!isNaN(formatedDate)
                                ? new Intl.DateTimeFormat(locale(), {
                                      year: 'numeric',
                                      month: '2-digit',
                                      day: '2-digit',
                                  }).format(formatedDate)
                                : ''}
                        </div>
                    );
                } else {
                    return (
                        // this happens when the value is empty
                        <div></div>
                    );
                }
            case 'datetime': {
                const dateValue = DisplayValue?.length > 0 ? DisplayValue : Value;

                if (dateValue != null && (dateValue as string).length > 0) {
                    const options: Intl.DateTimeFormatOptions = {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                    };

                    if (DisplayValue?.length > 0) {
                        options.hour12 = false;
                    }

                    return (
                        <div key={`basetypeswitch-read-datetime-${CE?.SID}-${SID}-${ID}-${frontEndUID}`}>
                            {new Intl.DateTimeFormat(locale(), options).format(new Date(dateValue)).replace(',', '')}
                        </div>
                    );
                } else {
                    return <div></div>;
                }
            }

            default:
                if (DisplayValue?.length > 0) {
                    return <div key={`basetypeswitch-read-default-${CE?.SID}-${SID}-${ID}-${frontEndUID}`}>{DisplayValue}</div>;
                } else if (Value != null && (Value as string).length > 0) {
                    return <div key={`basetypeswitch-read-default-${CE?.SID}-${SID}-${ID}-${frontEndUID}`}>{Value}</div>;
                } else {
                    return (
                        // this happens when the value is empty
                        <div></div>
                    );
                }
        }
    }
};
export default BaseTypeSwitch;
