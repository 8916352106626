import useShouldShowPrompt from './useShouldShowPrompt';

const iosInstallPromptedAt = 'iosInstallPromptedAt';

export const isIOS = (): boolean => {
    if ((navigator as any).standalone) {
        // user has already installed the app
        return false;
    }
    const ua = window.navigator.userAgent;
    const isIPad = !!ua.match(/iPad/i);
    const isIPhone = !!ua.match(/iPhone/i);
    const isIPod = !!ua.match(/iPod/i);

    return isIPad || isIPhone || isIPod;
};

export const isSafariIOS = (): boolean => {
    if ((navigator as any).standalone) {
        // user has already installed the app
        return false;
    }

    const ua = window.navigator.userAgent;
    // const uaData = (window.navigator as any)?.userAgentData;
    // if (uaData != null) console.log('uaData', uaData);

    const safariIOS = !!ua.match(/Safari/i) && !!ua.match(/Mac OS/i);

    return safariIOS;
};

const useIosInstallPrompt = (): [boolean, () => void] => {
    const [userShouldBePromptedToInstall, handleUserSeeingInstallPrompt] = useShouldShowPrompt(iosInstallPromptedAt);

    return [isIOS() && isSafariIOS() && userShouldBePromptedToInstall, handleUserSeeingInstallPrompt];
};
export default useIosInstallPrompt;
