import notify from 'devextreme/ui/notify';

export const showErrorMessage = (message: string, timeout = 5000) => {
    notify(
        {
            message,
            type: 'error',
            displayTime: timeout,
        },
        {
            position: 'top center',
            direction: 'down-push',
        }
    );
};

export const showSuccessMessage = (message: string, timeout = 5000) => {
    notify(
        {
            message,
            type: 'success',
            displayTime: timeout,
        },
        {
            position: 'top center',
            direction: 'down-push',
        }
    );
};

export const showWarningMessage = (message: string, timeout = 5000) => {
    notify(
        {
            message,
            type: 'warning',
            displayTime: timeout,
        },
        {
            position: 'top center',
            direction: 'down-push',
        }
    );
};
