import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { formatMessage } from 'devextreme/localization';

// REFERENCES
import { AuxTransactionList, ReportDocument, StartDownload } from './ServiceReference/reference';
import { CalculationContent } from './ServiceReference/CalculationContent';
import { LoginConfigProps } from './APIService.reference';
import { MessagePostContent } from './ServiceReference/MessagePostContent';
import { NewMessageContent, NewMessageContentTable } from './ServiceReference/NewMessageContent';
import { NewMessageContentRequest } from './ServiceReference/NewMessageContentRequest';
import { TransactionOverviewFilterData } from '../../panels/TransactionOverview/TransactionOverview';
import { TransactionRecord } from './ServiceReference/TransactionRecord';

// UTILS
import { showErrorMessage } from '../../utils/NotifyMessages/showNotifyMessages';
import { getLocalStorage } from '../JsonHelper/localStorageUtils';
import { RelaticsDocument } from './ServiceReference/RelaticsDocument';

// let url = 'https://test5b.bakkerspees.nl';
// let url = 'http://localhost:5188/api/v1';
// let url = process.env.REACT_APP_BACKEND_URL;
let url = '';
let transactionOverViewAbortController = null;

// Get the standard used language to send it by login methods
const userAppLanguage = getLocalStorage('interface_language') || 'nl-NL';

/**
 * Sets an item in localStorage and dispatches a 'localStorageAppVersionUpdated' event.
 *
 * @param {string} key - The key to set in localStorage.
 * @param {string} value - The value to set in localStorage.
 */
const setLocalStorageItem = (key, value) => {
    const event = new CustomEvent('localStorageAppVersionUpdated', {
        detail: {
            key,
            value,
        },
    });
    window.dispatchEvent(event);
    localStorage.setItem(key, value);
};

/**
 * Sets the current timestamp in localStorage.
 */
const setTimestamp = () => {
    const currentTimestamp = Date.now();
    setLocalStorageItem('vs-app-timestamp-update-check', currentTimestamp.toString());
};

/**
 * Checks if the stored timestamp in localStorage is older than two hours.
 *
 * @returns {boolean} - Returns true if the stored timestamp is older than two hours, otherwise false.
 */
const isTimestampOlderThanTwoHours = () => {
    const storedTimestamp = localStorage.getItem('vs-app-timestamp-update-check');
    if (!storedTimestamp) {
        return true;
    }
    const currentTimestamp = Date.now();
    const storedTimestampMs = parseInt(storedTimestamp);
    const twoHourMs = 2 * 60 * 60 * 1000;
    return currentTimestamp - storedTimestampMs > twoHourMs;
};

/**
 * Check if a string contains at least one of the specified values.
 * @param {string} str - The input string to be checked.
 * @param {string[]} checkValues - An array of values to check for.
 * @returns {boolean} True if at least one of the values is present in the string, false otherwise.
 */
function requestContainsValue(str, checkValues) {
    for (const value of checkValues) {
        if (str.includes(value)) {
            return true;
        }
    }
    return false;
}

/**
 * Compares two version strings.
 *
 * @param {string} v1 - The first version string to compare.
 * @param {string} v2 - The second version string to compare.
 * @returns {number} Returns 1 if v1 is greater than v2, -1 if v1 is less than v2, 0 if they are equal.
 */
const compareVersions = (v1: string, v2: string): number => {
    const parts1 = v1.split('.').map(Number);
    const parts2 = v2.split('.').map(Number);
    for (let i = 0; i < Math.max(parts1.length, parts2.length); i++) {
        const part1 = parts1[i] || 0;
        const part2 = parts2[i] || 0;
        if (part1 > part2) return 1;
        if (part1 < part2) return -1;
    }
    return 0;
};

// Intercepts all requests that are made via Axios and checks if the app version has changed.
axios?.interceptors?.response?.use(function (response: any) {
    setLocalStorageItem('vs-app-version-browser', process.env.REACT_APP_VERSION);

    // if one if the requests from checkValues are made, then skip the check for an app update and return the response
    const checkValues = ['api/v1/login/logon', 'api/v1/project/list', 'api/v1/usersetting/get'];
    if (requestContainsValue(response.request?.responseURL, checkValues)) return response;

    let isNewVersionAvailable = false;
    // This is used for local development environment
    if (response.headers['x-visi-app-version']) {
        setLocalStorageItem('vs-new-app-version', response.headers['x-visi-app-version']);

        isNewVersionAvailable = compareVersions(response.headers['x-visi-app-version'], localStorage.getItem('vs-app-version-browser')) !== 0;

        setLocalStorageItem('vs-app-new-version-available', isNewVersionAvailable.toString());
    }
    // The below logic is used for all other (dev, test, prod) environments
    else {
        if (isTimestampOlderThanTwoHours()) {
            setTimestamp();

            // requests the app version via a node.js endpoint
            axios
                .get('/app-version')
                .then((response) => {
                    const appVersion = response.data;
                    setLocalStorageItem('vs-new-app-version', appVersion);
                    isNewVersionAvailable = compareVersions(appVersion, localStorage.getItem('vs-app-version-browser')) !== 0;
                })
                .catch((error) => console.error({ error }))
                .finally(() => {
                    setLocalStorageItem('vs-app-new-version-available', isNewVersionAvailable.toString());
                });
        }
    }

    return response;
});

const APIService = {
    getAdditionalContent: async (url: string) => {
        const config: AxiosRequestConfig<any> = {
            method: 'GET',
            url,
        };

        try {
            return await axios(config);
        } catch (error) {
            // geen error in beeld
            console.log(error);
        }
    },
    getAdditionalMedia: async (url: string) => {
        const config: AxiosRequestConfig<any> = {
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'GET',
            url,
        };

        try {
            return await axios(config);
        } catch (error) {
            // geen error in beeld
            console.log(error);
        }
    },
    getBsToken: async (url) => {
        const config: AxiosRequestConfig<any> = {
            data: {
                password: process.env.REACT_APP_FEED_PWD,
                username: process.env.REACT_APP_FEED_USR,
            },
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'POST',
            url,
        };

        try {
            return await axios(config);
        } catch (error) {
            // geen error in beeld
            console.log(error);
        }
    },
    addSetting: async (settingName: string, settingValue: string, session: string, token: string) => {
        const config: AxiosRequestConfig<any> = {
            method: 'POST',
            url: url + '/api/v1/setting/add',
            data: {
                setting: settingName,
                value: settingValue,
            },
            headers: {
                'Content-Type': 'application/json',
                SessionID: session,
                TokenID: token,
            },
        };

        try {
            return await axios(config);
        } catch (error) {
            APIService.handleErrors(error);
            throw error;
        }
    },
    getUrl: () => {
        return url;
    },
    addUserSetting: async (settingName: string, settingValue: string, session: string, token: string) => {
        const config: AxiosRequestConfig<any> = {
            method: 'POST',
            url: url + '/api/v1/usersetting/add',
            data: {
                setting: settingName,
                value: settingValue,
            },
            headers: {
                'Content-Type': 'application/json',
                SessionID: session,
                TokenID: token,
            },
        };

        try {
            return await axios(config);
        } catch (error) {
            APIService.handleErrors(error);
            throw error;
        }
    },
    handleErrors: (error: any) => {
        switch (error?.code) {
            case 'ERR_NETWORK':
                if (!navigator?.onLine) {
                    // console.log('no internet connection - net::ERR_INTERNET_DISCONNECTED');
                    return showErrorMessage(formatMessage('vs-Error-ERR_NETWORK'));
                }
                // ERR_CONNECTION_REFUSED
                return showErrorMessage(formatMessage('vs-Error-ERR_CONNECTION_REFUSED'));

            case 'ERR_BAD_RESPONSE':
                if (!navigator?.onLine) {
                    return showErrorMessage(formatMessage('vs-Error-ERR_BAD_RESPONSE'));
                }
                return showErrorMessage(typeof error?.response?.data === 'string' ? error?.response?.data : error?.message);

            case 'ERR_CANCELED':
                return console.log('Request has been cancelled');

            default:
                return showErrorMessage(typeof error?.response?.data === 'string' ? error?.response?.data : error?.message);
        }
    },
    addProjectSetting: async (settingName: string, settingValue: string, session: string, token: string) => {
        const config: AxiosRequestConfig<any> = {
            method: 'GET',
            url: url + '/api/v1/projectsetting/add',
            headers: {
                'Content-Type': 'application/json',
                SessionID: session,
                TokenID: token,
            },
            params: {
                setting: settingName,
                value: settingValue,
            },
        };

        try {
            return await axios(config);
        } catch (error) {
            APIService.handleErrors(error);
            throw error;
        }
    },
    loginTwoStepTimebasedOneTimePassword: async (userName: string, password: string, token2FA: string) => {
        const config: AxiosRequestConfig<any> = {
            data: {
                communicationVersion: 13,
                culture: userAppLanguage,
                password,
                source: 'WEB',
                twoFactorAuthToken: token2FA,
                userName,
                version: process.env.REACT_APP_VERSION,
            },
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            url: url + '/api/v1/login/logonTwoFactorAuthenticationTimeBasedOneTimePasswordCheck',
        };

        try {
            return await axios(config);
        } catch (error) {
            APIService.handleErrors(error);
            throw error;
        }
    },
    getProjectSetting: async (setting: string, session: string, token: string) => {
        const config: AxiosRequestConfig<any> = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                SessionID: session,
                TokenID: token,
            },
            url: url + '/api/v1/projectsetting/read',
            params: {
                setting,
            },
        };
        try {
            return await axios(config);
        } catch (error) {
            APIService.handleErrors(error);
            throw error;
        }
    },
    setUrl: (backendUrl: string) => {
        url = backendUrl;
    },
    getSetting: async (setting: string, session: string, token: string) => {
        const config: AxiosRequestConfig<any> = {
            headers: {
                'Content-Type': 'application/json',
                SessionID: session,
                TokenID: token,
            },
            method: 'GET',
            params: {
                setting,
            },
            url: url + '/api/v1/setting/get',
        };
        try {
            return await axios(config);
        } catch (error) {
            APIService.handleErrors(error);
            throw error;
        }
    },
    addToFavorites: async (projectID: number, session: string, token: string) => {
        const config: AxiosRequestConfig<any> = {
            method: 'GET',
            url: url + '/api/v1/project/addtofavorites',
            headers: {
                'Content-Type': 'application/json',
                SessionID: session,
                TokenID: token,
            },
            params: {
                projectID,
            },
        };

        try {
            return await axios(config);
        } catch (error) {
            APIService.handleErrors(error);
            throw error;
        }
    },
    logon: async (username: string, password: string) => {
        const config: LoginConfigProps = {
            data: {
                userName: username,
                password,
                communicationVersion: 13,
                source: 'WEB',
                version: process.env.REACT_APP_VERSION,
                culture: userAppLanguage,
            },
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            url: url + '/api/v1/login/logon',
        };

        try {
            return await axios(config);
        } catch (error) {
            APIService.handleErrors(error);
            throw error;
        }
    },
    deleteFromFavorites: async (projectID: number, session: string, token: string) => {
        const config: AxiosRequestConfig<any> = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                SessionID: session,
                TokenID: token,
            },
            url: url + '/api/v1/project/deletefavorites',
            params: {
                projectID,
            },
        };

        try {
            return await axios(config);
        } catch (error) {
            APIService.handleErrors(error);
            throw error;
        }
    },
    logon2fa: async (username: string, password: string, token2FA: string) => {
        /* 
            TODO: When the 2FA imelementaion is done. Check here if this method not more used.
            DIT WIJST MOMENTEEL NAAR EEN ANDERE MANIER VAN 2FA IN BACKEND .NET CORE
            ER IS EEN NIEUWE METHODE 
         */
        const config: LoginConfigProps = {
            method: 'POST',
            url: url + '/api/v1/login/logon2fa',
            data: {
                userName: username,
                password,
                communicationVersion: 13,
                source: 'WEB',
                twoFactorAuthToken: token2FA,
                version: process.env.REACT_APP_VERSION,
                culture: userAppLanguage,
            },
            headers: {
                'Content-Type': 'application/json',
            },
        };

        try {
            return await axios(config);
        } catch (error) {
            APIService.handleErrors(error);
            throw error;
        }
    },
    getFavorites: async (session: string, token: string) => {
        const config: AxiosRequestConfig<any> = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                SessionID: session,
                TokenID: token,
            },
            url: url + '/api/v1/project/getFavorites',
        };

        try {
            return await axios(config);
        } catch (error) {
            APIService.handleErrors(error);
            throw error;
        }
    },
    logonssodirect: async (code: string, state: string) => {
        const config: AxiosRequestConfig<any> = {
            url: url + '/api/v1/login/logonssodirect',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            params: {
                code,
                state,
                communicationVersion: 13,
                source: 'WEB',
                version: process.env.REACT_APP_VERSION,
                culture: userAppLanguage,
            },
        };

        try {
            return await axios(config);
        } catch (error) {
            APIService.handleErrors(error);
            throw error;
        }
    },
    addNavItemToFavorites: async (branchId: string, leafId: string, leafName: string, session: string, token: string) => {
        const config: AxiosRequestConfig<any> = {
            headers: {
                'Content-Type': 'application/json',
                SessionID: session,
                TokenID: token,
            },
            method: 'GET',
            params: {
                branchID: branchId,
                leafID: leafId,
                name: leafName,
            },
            url: url + '/api/v1/tree/addfavorite',
        };

        try {
            return await axios(config);
        } catch (error) {
            APIService.handleErrors(error);
            throw error;
        }
    },
    mailNewPassword: async (username: string) => {
        const config: AxiosRequestConfig<any> = {
            method: 'GET',
            url: url + '/api/v1/mailnewpassword',
            headers: {
                'Content-Type': 'application/json',
            },
            params: {
                logInName: username,
            },
        };

        try {
            return await axios(config);
        } catch (error) {
            APIService.handleErrors(error);
            throw error;
        }
    },
    addPersonalFolder: async (folderName: string, session: string, token: string) => {
        const config: AxiosRequestConfig<any> = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                SessionID: session,
                TokenID: token,
            },
            url: url + '/api/v1/tree/addmap',
            params: {
                name: folderName,
            },
        };

        try {
            return await axios(config);
        } catch (error) {
            APIService.handleErrors(error);
            throw error;
        }
    },
    mailTwoStepTimebasedOneTimePasswordSecret: async (userName: string) => {
        const config: AxiosRequestConfig<any> = {
            url: url + '/api/v1/login/mailTwoStepTimebasedOneTimePasswordSecret',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            data: {
                userName,
            },
        };

        try {
            return await axios(config);
        } catch (error) {
            APIService.handleErrors(error);
            throw error;
        }
    },
    addToPersonalFolder: async (transactionID: number, personalFolderID: string, session: string, token: string) => {
        const config: AxiosRequestConfig<any> = {
            headers: {
                'Content-Type': 'application/json',
                SessionID: session,
                TokenID: token,
            },
            method: 'GET',
            params: {
                persoonlijkMapID: personalFolderID.match(/pers_(.*$)/)[1],
                transactionID,
            },
            url: url + '/api/v1/tree/addtransaction',
        };

        try {
            return await axios(config);
        } catch (error) {
            APIService.handleErrors(error);
        }
    },
    addRootFolder: async (branchId: string, leafId: string, leafName: string, session: string, token: string) => {
        const config: AxiosRequestConfig<any> = {
            method: 'GET',
            url: url + '/api/v1/tree/rootfolderadd',
            headers: {
                'Content-Type': 'application/json',
                SessionID: session,
                TokenID: token,
            },
            params: {
                branchID: branchId,
                leafID: leafId,
                name: leafName,
            },
        };

        try {
            return await axios(config);
        } catch (error) {
            APIService.handleErrors(error);
            throw error;
        }
    },
    getUserSetting: async (setting: string, session: string, token: string) => {
        const config: AxiosRequestConfig<any> = {
            method: 'GET',
            url: url + '/api/v1/usersetting/get',
            headers: {
                'Content-Type': 'application/json',
                SessionID: session,
                TokenID: token,
            },
            params: {
                setting,
            },
        };
        try {
            return await axios(config);
        } catch (error) {
            APIService.handleErrors(error);
            throw error;
        }
    },
    openProject: async (projectID: string, session: string, token: string) => {
        const config: AxiosRequestConfig<any> = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                SessionID: session,
                TokenID: token,
            },
            url: url + '/api/v1/project/open',
            params: {
                projectID,
            },
        };

        try {
            return await axios(config);
        } catch (error) {
            APIService.handleErrors(error);
            throw error;
        }
    },
    finalizeUpload: async (params: string, session: string, token: string) => {
        const config: AxiosRequestConfig<any> = {
            method: 'POST',
            url: url + '/api/v1/file/finalizeupload',
            data: {
                UploadID: params,
            },
            headers: {
                SessionID: session,
                TokenID: token,
            },
        };

        try {
            return await axios(config);
        } catch (error) {
            APIService.handleErrors(error);
            return error;
        }
    },
    projectList: async (session: string, token: string) => {
        const config: AxiosRequestConfig<any> = {
            method: 'GET',
            url: url + '/api/v1/project/list',
            headers: {
                'Content-Type': 'application/json',
                SessionID: session,
                TokenID: token,
            },
        };

        try {
            return await axios(config);
        } catch (error) {
            APIService.handleErrors(error);
            throw error;
        }
    },
    receiveOverviewPeriodically: async (transactionId: string, period: string, session: string, token: string) => {
        const config: AxiosRequestConfig<any> = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                SessionID: session,
                TokenID: token,
            },
            url: url + 'api/v1/report/setperiodic',
            params: {
                period,
                transactionID: transactionId.match(/report_(.*$)/)[1],
            },
        };

        try {
            return await axios(config);
        } catch (error) {
            APIService.handleErrors(error);
            throw error;
        }
    },
    removeFromPersonalFolder: async (transactionID: number, personalFolderID: string, session: string, token: string) => {
        const config: AxiosRequestConfig<any> = {
            headers: {
                'Content-Type': 'application/json',
                SessionID: session,
                TokenID: token,
            },
            method: 'GET',
            params: {
                persoonlijkMapID: personalFolderID.match(/pers_(.*$)/)[1],
                transactionID,
            },
            url: url + '/api/v1/tree/deletetransaction',
        };

        try {
            return await axios(config);
        } catch (error) {
            APIService.handleErrors(error);
            return error;
        }
    },
    getReport: async (params: any) => {
        const reportID: any = params.reportID.split('_')[1];
        const config: AxiosRequestConfig<any> = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                SessionID: params.SessionID,
                TokenID: params.TokenID,
            },
            url: url + '/api/v1/report/open?rapportID=' + reportID,
        };

        try {
            return await axios(config);
        } catch (error) {
            APIService.handleErrors(error);
        }
    },
    treeExpand: async (branchId: string, session: string, token: string) => {
        const config: AxiosRequestConfig<any> = {
            method: 'POST',
            url: url + '/api/v1/tree/expand',
            data: {
                BranchId: branchId,
            },
            headers: {
                'Content-Type': 'application/json',
                SessionID: session,
                TokenID: token,
            },
        };
        try {
            return await axios(config);
        } catch (error) {
            APIService.handleErrors(error);
            throw error;
        }
    },
    getReportTemplateFiles: async ({
        messageID,
        rapportID,
        SessionID,
        TokenID,
        transactionID,
    }: {
        messageID: number;
        rapportID: number;
        SessionID: string;
        TokenID: string;
        transactionID: number;
    }): Promise<{ data: ReportDocument }> => {
        const config: AxiosRequestConfig<any> = {
            method: 'GET',
            url: `${url}/api/v1/report/open?rapportID=${rapportID}&transactionID=${transactionID}&messageID=${messageID}`,
            headers: {
                'Content-Type': 'application/json',
                SessionID,
                TokenID,
            },
        };

        try {
            return await axios(config);
        } catch (error) {
            APIService.handleErrors(error);
            return error;
        }
    },
    getReportTransaction: async (TransactionID: number, SessionID: string, TokenID: string) => {
        const config: AxiosRequestConfig<any> = {
            method: 'POST',
            url: url + '/api/v1/report/transaction',
            data: TransactionID,
            headers: {
                'Content-Type': 'application/json',
                SessionID,
                TokenID,
            },
        };

        try {
            return await axios(config);
        } catch (error) {
            APIService.handleErrors(error);
            throw error;
        }
    },
    getApplicationReport: async (params: any) => {
        const reportID: any = params.reportID.split('_')[1];
        const config: AxiosRequestConfig<any> = {
            method: 'GET',
            url: url + '/api/v1/report/openapplicationreport?reportID=' + reportID,
            headers: {
                'Content-Type': 'application/json',
                SessionID: params.SessionID,
                TokenID: params.TokenID,
            },
        };

        try {
            return await axios(config);
        } catch (error) {
            APIService.handleErrors(error);
            return error;
        }
    },
    treeInitialize: async (session: string, token: string) => {
        const config: AxiosRequestConfig<any> = {
            url: url + '/api/v1/tree/init',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                SessionID: session,
                TokenID: token,
            },
        };
        try {
            return await axios(config);
        } catch (error) {
            APIService.handleErrors(error);
            throw error;
        }
    },
    convertTableToExcel: async (data: any, params: any) => {
        const config: AxiosRequestConfig<any> = {
            method: 'POST',
            url: url + '/api/v1/converttabletoexcel',
            // This tells axios to treat the response as binary data
            headers: {
                'Content-Type': 'application/json',
                SessionID: params.SessionID,
                TokenID: params.TokenID,
            },
            responseType: 'arraybuffer',
            data: JSON.stringify(data),
        };
        try {
            return await axios(config);
        } catch (error) {
            APIService.handleErrors(error);
        }
    },
    removeNavItemFromFavorites: async (itemId: string, session: string, token: string) => {
        const config: AxiosRequestConfig<any> = {
            method: 'GET',
            url: url + '/api/v1/tree/deletefavorite',
            headers: {
                'Content-Type': 'application/json',
                SessionID: session,
                TokenID: token,
            },
            params: {
                treeID: itemId,
            },
        };

        try {
            return await axios(config);
        } catch (error) {
            APIService.handleErrors(error);
            throw error;
        }
    },
    convertCsvToTable: async (bestand, params) => {
        // Creëer een nieuw FormData-object
        const formData = new FormData();

        // Voeg het bestand toe aan de FormData
        formData.append('file', bestand);

        const config: AxiosRequestConfig<any> = {
            method: 'POST',
            data: formData,
            url: url + '/api/v1/convertcsvtotable',
            headers: {
                'Content-Type': 'multipart/form-data', // Verander dit naar 'multipart/form-data'
                SessionID: params.SessionID,
                TokenID: params.TokenID,
            }, // Verander dit naar het FormData-object dat je wilt versturen
        };
        try {
            return await axios(config);
        } catch (error) {
            APIService.handleErrors(error);
        }
    },
    renameNavItemInFavorites: async (favoriteId: string, newName: string, session: string, token: string) => {
        const config: AxiosRequestConfig<any> = {
            url: url + '/api/v1/tree/renamefavorite',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                SessionID: session,
                TokenID: token,
            },
            params: {
                treeID: favoriteId,
                newName,
            },
        };

        try {
            return await axios(config);
        } catch (error) {
            APIService.handleErrors(error);
            throw error;
        }
    },
    getPdfExport: async (id: number, params: any) => {
        const config: AxiosRequestConfig<any> = {
            method: 'GET',
            headers: {
                SessionID: params.SessionID,
                TokenID: params.TokenID,
            },
            url: url + '/api/v1/exportaspdf?transactionids=' + id,
        };
        try {
            return await axios(config);
        } catch (error) {
            APIService.handleErrors(error);
        }
    },
    getReportExport: async (data: any, params: any) => {
        const config: AxiosRequestConfig<any> = {
            method: 'POST',
            data: JSON.stringify(data),
            url: url + '/api/v1/report/convert',
            headers: {
                'Content-Type': 'application/json',
                SessionID: params.SessionID,
                TokenID: params.TokenID,
            },
        };
        try {
            return await axios(config);
        } catch (error) {
            APIService.handleErrors(error);
        }
    },
    removePersonalFolder: async (itemId: string, session: string, token: string) => {
        const config: AxiosRequestConfig<any> = {
            method: 'GET',
            url: url + '/api/v1/tree/deletemap',
            headers: {
                'Content-Type': 'application/json',
                SessionID: session,
                TokenID: token,
            },
            params: {
                persoonlijkMapID: itemId.match(/pers_(.*$)/)[1],
            },
        };

        try {
            return await axios(config);
        } catch (error) {
            APIService.handleErrors(error);
            throw error;
        }
    },
    getReportMessage: async (MessageID: number, SessionID: string, TokenID: string) => {
        const config: AxiosRequestConfig<any> = {
            method: 'POST',
            data: MessageID,
            url: url + '/api/v1/report/message',
            headers: {
                'Content-Type': 'application/json',
                SessionID,
                TokenID,
            },
        };

        try {
            return await axios(config);
        } catch (error) {
            APIService.handleErrors(error);
            throw error;
        }
    },
    removeRootFolder: async (leafId: string, session: string, token: string) => {
        const config: AxiosRequestConfig<any> = {
            method: 'GET',
            url: url + '/api/v1/tree/rootfolderdelete',
            headers: {
                'Content-Type': 'application/json',
                SessionID: session,
                TokenID: token,
            },
            params: {
                treeID: leafId,
            },
        };

        try {
            return await axios(config);
        } catch (error) {
            APIService.handleErrors(error);
            throw error;
        }
    },
    getConcept: async (params: any, session: string, token: string) => {
        if (params?.ConceptID == null) return 'geen bericht param meegegeven';

        const config: AxiosRequestConfig<any> = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                SessionID: session,
                TokenID: token,
            },
            url: url + '/api/v1/concept/get',
            params: {
                conceptID: params?.ConceptID,
            },
        };

        try {
            return await axios(config);
        } catch (error) {
            APIService.handleErrors(error);
        }
    },
    startUpload: async (md5String: string, session: string, token: string) => {
        const config: AxiosRequestConfig<any> = {
            method: 'POST',
            url: url + '/api/v1/file/startupload',
            data: {
                MD5checksum: md5String,
            },
            headers: {
                SessionID: session,
                TokenID: token,
            },
        };

        try {
            return await axios(config);
        } catch (error) {
            APIService.handleErrors(error);
        }
    },
    getConceptId: async (replyMessageID: number, session: string, token: string) => {
        const requestParams: AxiosRequestConfig<any> = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                SessionID: session,
                TokenID: token,
            },
            params: {
                replyMessageID,
            },
            url: url + '/api/v1/calls/getconceptid',
        };

        try {
            return await axios(requestParams);
        } catch (error) {
            APIService.handleErrors(error);
        }
    },
    uploadChunk: async (params: string, chunk: File, session: string, token: string) => {
        const config: AxiosRequestConfig<any> = {
            url: url + '/api/v1/file/uploadchunk',
            method: 'POST',
            headers: {
                SessionID: session,
                TokenID: token,
                UploadID: params,
            },
            data: chunk,
        };

        try {
            return await axios(config);
        } catch (error) {
            APIService.handleErrors(error);
        }
    },
    getReportProcessReportQuestions: async (
        rapportID: number,
        transactionId: number,
        messageId: number,
        questions: string,
        SessionID: string,
        TokenID: string
    ) => {
        // Name	Description
        // rapportID
        // integer($int32)
        // (query)
        // rapportID
        // transactionId
        // integer($int32)
        // (query)
        // transactionId
        // messageId
        // integer($int32)
        // (query)
        //            return post<ReportDocument>($"report/processreportquestions?rapportID={rapportId}&transactionID={transactionId}&messageID={messageId}", reportQuestionAnswers);

        const config: AxiosRequestConfig<any> = {
            headers: {
                'Content-Type': 'application/json',
                SessionID,
                TokenID,
            },
            method: 'POST',
            data: questions,
            url: url + `/api/v1/report/processreportquestions`,
            params: {
                messageID: messageId,
                rapportID,
                transactionID: transactionId,
            },
        };

        try {
            return await axios(config);
        } catch (error) {
            APIService.handleErrors(error);
        }
    },

    getMessageDifference: async (messageID: number, session: string, token: string) => {
        const config: AxiosRequestConfig<any> = {
            headers: {
                method: 'GET',
                'Content-Type': 'application/json',
                SessionID: session,
                TokenID: token,
            },
            params: {
                messageID,
            },
        };

        try {
            return await axios(url + '/api/v1/message/diff', config);
        } catch (error) {
            APIService.handleErrors(error);
        }
    },
    getMessageId: async (transactionID: number, SessionID: string, TokenID: string) => {
        const config: AxiosRequestConfig<any> = {
            headers: {
                'Content-Type': 'application/json',
                method: 'GET',
                SessionID,
                TokenID,
            },
            params: {
                transactionID,
            },
        };

        try {
            return await axios(url + '/api/v1/calls/getmessageid', config);
        } catch (error) {
            APIService.handleErrors(error);
            throw error;
        }
    },
    lastMessage: async (params, session: string, token: string) => {
        const requestParams: AxiosRequestConfig<any> = {
            headers: {
                'Content-Type': 'application/json',
                SessionID: session,
                TokenID: token,
            },
            params: {
                transactionID: params,
            },
        };
        try {
            return await axios.get(url + '/api/v1/transactions/lastmessage', requestParams);
        } catch (error) {
            APIService.handleErrors(error);
        }
    },
    messageConceptWrite: async (params, session: string, token: string) => {
        console.log({ conceptParams: params });
        const requestParams: AxiosRequestConfig<any> = {
            method: 'POST',
            data: {
                ...params,
                transactionID: -1,
            },
            url: url + '/api/v1/concept/viewmessage',
            headers: {
                'Content-Type': 'application/json',
                SessionID: session,
                TokenID: token,
            },
        };
        if (params != null && params.cancelToken != null) {
            requestParams.cancelToken = requestParams.params.cancelToken;

            delete requestParams.params.cancelToken;

            console.log('delete token', requestParams);
        }

        try {
            return await axios(requestParams);
        } catch (error) {
            requestParams.cancelToken = requestParams.params.cancelToken;

            delete requestParams.params.cancelToken;

            APIService.handleErrors(error);
        }
    },
    messageList: async (params: any, session: string, token: string) => {
        const requestParams: AxiosRequestConfig<any> = {
            headers: {
                'Content-Type': 'application/json',
                SessionID: session,
                TokenID: token,
            },
            params: {
                transactionID: params,
            },
        };

        try {
            return await axios.get(url + '/api/v1/transactions/reply', requestParams);
        } catch (error) {
            APIService.handleErrors(error);
        }
    },
    canSetTransactionUnread: async (transactionID: number, session: string, token: string) => {
        const config: AxiosRequestConfig<any> = {
            method: 'GET',
            url: url + '/api/v1/transactions/cansetunread',
            headers: {
                'Content-Type': 'application/json',
                SessionID: session,
                TokenID: token,
            },
            params: {
                transactionID,
            },
        };
        try {
            return await axios(config);
        } catch (error) {
            APIService.handleErrors(error);
        }
    },
    newMessageContent: async (params: NewMessageContentRequest, session: string, token: string): Promise<NewMessageContent> => {
        const headers = {
            'Content-Type': 'application/json',
            SessionID: session,
            TokenID: token,
        };

        try {
            return await axios.post(url + '/api/v1/message/new', params, {
                headers,
            });
        } catch (error) {
            APIService.handleErrors(error);
            throw error;
        }
    },
    messageView: async (params, session: string, token: string) => {
        const requestParams: AxiosRequestConfig<any> = {
            headers: {
                'Content-Type': 'application/json',
                SessionID: session,
                TokenID: token,
            },
            params: {
                ...params,
            },
        };

        if (params != null && params.cancelToken != null) {
            requestParams.cancelToken = requestParams.params.cancelToken;

            delete requestParams.params.cancelToken;
        }
        try {
            // Geen geldig params of bericht te kunnen tonen
            if ((params?.ConceptID == null && params?.messageID === 0 && params?.transnr === 0) || Number.isNaN(params?.messageID)) return;

            if (params?.ConceptID == null) return await axios.get(url + '/api/v1/message/view', requestParams);
            else {
                // itt tot /message/view is dit een post?
                requestParams.method = 'post';
                requestParams.url = url + '/api/v1/concept/viewmessage';
                delete requestParams.params;
                requestParams.data = {};
                requestParams.data.conceptID = params.ConceptID;
                requestParams.data.transactionID = params.transactionID != null ? params.transactionID : -1;

                return await axios(requestParams);
            }
        } catch (error) {
            APIService.handleErrors(error);
        }
    },
    saveConcept: async (params: any, session: string, token: string) => {
        if (params.bericht == null) return 'geen bericht param meegegeven';
        // console.log(params);

        const config: AxiosRequestConfig<any> = {
            method: 'POST',
            url: url + '/api/v1/concept/save',
            data: {
                conceptID: params?.bericht?.ConceptID,
                messageContent: JSON.stringify(params?.bericht),
            },
            headers: {
                'Content-Type': 'application/json',
                SessionID: session,
                TokenID: token,
            },
        };

        try {
            return await axios(config);
        } catch (error) {
            APIService.handleErrors(error);
            throw error;
        }
    },
    getTransactionListBasedOn: async (basedOnData: AuxTransactionList, session: string, token: string) => {
        const config: AxiosRequestConfig<any> = {
            method: 'POST',
            url: url + '/api/v1/transactions/gettransactionlistbasedon',
            headers: {
                'Content-Type': 'application/json',
                SessionID: session,
                TokenID: token,
            },
            data: basedOnData,
        };
        try {
            return await axios(config);
        } catch (error) {
            APIService.handleErrors(error);
            throw error;
        }
    },
    getElementListBasedOn: async (
        sourceElementBasedOn: string[],
        filterElementID: number,
        filterElementValue: string,
        transactionSID: string,
        session: string,
        token: string
    ) => {
        const config: AxiosRequestConfig<any> = {
            method: 'POST',
            url: url + '/api/v1/message/getelementlistbasedon',
            headers: {
                'Content-Type': 'application/json',
                SessionID: session,
                TokenID: token,
            },
            params: {
                filterElementID,
                filterElementValue,
                transactionSID,
            },
            data: sourceElementBasedOn,
        };
        try {
            return await axios(config);
        } catch (error) {
            APIService.handleErrors(error);
            throw error;
        }
    },
    sendMessage: async (params: any, session: string, token: string) => {
        if (params.bericht == null) return 'geen bericht param meegegeven';

        const requestParams: AxiosRequestConfig<any> = {
            url: url + '/api/v1/message/post',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                SessionID: session,
                TokenID: token,
                TusUpload: 'true',
            },
            data: JSON.stringify(params.bericht),
        };
        try {
            return await axios(requestParams);
        } catch (error) {
            // throw error.response.data;
            APIService.handleErrors(error);
        }
    },
    calculateLuaScript: async (LuaScriptId: string, messageData: MessagePostContent, session: string, token: string) => {
        const config: AxiosRequestConfig<any> = {
            method: 'POST',
            url: url + '/api/v1/message/calculatescript',
            headers: {
                'Content-Type': 'application/json',
                SessionID: session,
                TokenID: token,
            },
            params: {
                LuaScriptId,
            },
            data: messageData,
        };
        try {
            return (await axios(config)) as AxiosResponse<CalculationContent>;
        } catch (error) {
            APIService.handleErrors(error);
            throw error;
        }
    },
    paramsData: async (params, session: string, token: string) => {
        let id;

        if (params.transnr) {
            await APIService.lastMessage(params.transnr, session, token).then((response) => {
                const data = (response as any)?.data.ID + '';
                id = data;
            });
        } else {
            for (const a in params) {
                id = params[a];
            }
        }

        return { messageID: parseInt(id) };
    },
    calculateMessage: async (lineNo: number, elementSID: string, messageData: MessagePostContent, session: string, token: string) => {
        const config: AxiosRequestConfig<any> = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                SessionID: session,
                TokenID: token,
            },
            url: url + '/api/v1/message/calculate',
            data: messageData,
            params: {
                lineNo,
                elementSID,
            },
        };
        try {
            return (await axios(config)) as AxiosResponse<CalculationContent>;
        } catch (error) {
            APIService.handleErrors(error);
            throw error;
        }
    },
    removeConcept: async (params, session: string, token: string) => {
        if (params.ConceptID == null) return new Error('Geen ConceptID meegegeven');

        const requestParams: AxiosRequestConfig<any> = {
            method: 'GET',
            params: {
                conceptID: params.ConceptID,
            },
            headers: {
                'Content-Type': 'application/json',
                SessionID: session,
                TokenID: token,
            },
            url: url + '/api/v1/concept/delete',
        };

        try {
            return await axios(requestParams);
        } catch (error) {
            APIService.handleErrors(error);
        }
    },
    getBackendInfo: async (session: string, token: string) => {
        const config: AxiosRequestConfig<any> = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                SessionID: session,
                TokenID: token,
            },
            url: url + '/api/v1/backendinfo',
        };

        try {
            return await axios(config);
        } catch (error) {
            APIService.handleErrors(error);
            throw error;
        }
    },
    setMessageRead: async (messageID: number, session: string, token: string) => {
        const config: AxiosRequestConfig<any> = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                SessionID: session,
                TokenID: token,
            },
            url: url + '/api/v1/message/read',
            params: {
                messageID,
            },
        };
        try {
            return await axios(config);
        } catch (error) {
            APIService.handleErrors(error);
        }
    },
    getDownloadFile: async (fileID: string, session: string, token: string) => {
        const config: AxiosRequestConfig<any> = {
            method: 'GET',
            url: url + '/api/v1/file/downloadbyid?inline=true&visifileid=' + fileID + '&embedded=true&toPDF=true',
            headers: {
                'Content-Type': 'application/json',
                SessionID: session,
                TokenID: token,
            },
        };

        try {
            const ret = await axios(config);
            console.log(ret);
            return ret;
        } catch (error) {
            APIService.handleErrors(error);
        }
    },
    tabTransactions: async (params, session: string, token: string) => {
        const headers = {
            'Content-Type': 'application/json',
            SessionID: session,
            TokenID: token,
        };

        const body = {
            TransactionId: params.transnr,
            TransactionTypeSiDs: params.transactiontypeid,
        };

        try {
            return await axios.post(url + '/api/v1/transactions/tabs', body, {
                headers,
            });
        } catch (error) {
            APIService.handleErrors(error);
            throw error;
        }
    },
    changePassword: async (username: string, oldPass: string, newPass: string, session: string, token: string) => {
        const config: AxiosRequestConfig<any> = {
            method: 'GET',
            headers: {
                SessionID: session,
                TokenID: token,
            },
            url: url + '/api/v1/login/setnewpassword',
            params: {
                currentPassword: oldPass,
                confirmedPassword: newPass,
                loginName: username,
                newPassword: newPass,
            },
        };

        try {
            return await axios(config);
        } catch (error) {
            APIService.handleErrors(error);
        }
    },
    transaction_filterlist: async (leafID: string, customZoekString: any, session: string, token: string) => {
        const filterParam = {
            leafID,
            orderBy: '',
            pageNumber: 0,
            searchText: customZoekString,
        };

        const config: AxiosRequestConfig<any> = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                SessionID: session,
                TokenID: token,
            },
            url: url + '/api/v1/tree/search',
            data: filterParam,
        };

        try {
            return await axios(config);
        } catch (error) {
            APIService.handleErrors(error);
        }
    },
    getFlowChar: async (transactionID: number, isCompact: boolean, session: string, token: string) => {
        const config: AxiosRequestConfig<any> = {
            headers: {
                'Content-Type': 'application/json',
                SessionID: session,
                TokenID: token,
            },
            method: 'GET',
            params: {
                isCompact,
                transactionID,
            },
            url: url + '/api/v1/calls/getflowchar',
        };

        try {
            return await axios(config);
        } catch (error) {
            APIService.handleErrors(error);
            throw error;
        }
    },
    transaction_list: async (leafID: string, filterString: any, session: string, token: string, pageNumber: number = 0, cancelNext: boolean) => {
        // maak filter string
        const filterParam = {
            leafID,
            pageNumber,
            orderBy: filterString == null ? '' : {},
        };

        const filter = filterString as TransactionOverviewFilterData;

        // als er geen sortering is ingesteld dan is het datetime
        // indien sortering ID is ingesteld, samen met desc dan is het standaard sortering
        // zet hem in dit geval hard op DateTimeSend
        if (
            filter.sort == null ||
            filter.sort.length === 0 ||
            (filter.sort.length === 1 && filter.sort[0].selector === 'ID' && filter.sort[0].desc === false)
        ) {
            filterString.sort = [{ selector: 'DateTimeSend', desc: true }];
        }
        // ({selector: 'DateTimeSend', desc: true})
        //
        for (const a in filterString) {
            if (filterString[a] != null && filterString[a].length > 0) {
                // console.log('filterString[a]', a, filterString[a]);
                filterParam.orderBy[a] = filterString[a];
            }
        }

        const arrayContainsGlobalSearch = (arr) => {
            const heeftOr = arr.filter((r) => r === 'or').length > 3; // we willen niet dat dit afgaat als iemand zoekt naar 'or'

            let globalSearch = '';
            if (heeftOr) {
                globalSearch = arr[0][2];
            }

            return globalSearch;
        };

        const createBackedWantedArray = (arr) => {
            // kijk of de array een array bevat, in dat geval hoef je niets te doen
            const arrayInArray = arr.filter((r) => Array.isArray(r));

            let globalSearch = arrayContainsGlobalSearch(arr);

            if (globalSearch === '' && arrayInArray.length > 0) {
                const wantedArray = [];

                // deze array bevat arrays; dit betekend dat hij een globale search kan bevatten
                // loop deze array
                arrayInArray.forEach((r) => {
                    globalSearch = arrayContainsGlobalSearch(r);
                    if (globalSearch === '') {
                        if (wantedArray.length) wantedArray.push('and');
                        wantedArray.push(r);
                    }
                });
                return { wantedArray: wantedArray.length === 1 ? wantedArray[0] : wantedArray, globalSearch };
            } else {
                return { wantedArray: globalSearch === '' ? (arr.length === 1 ? arr[0] : arr) : [], globalSearch };
            }
        };

        const wanted = createBackedWantedArray(filterString.filter);
        let nieuwFilter = wanted.wantedArray;
        let globalSearch = wanted.globalSearch;

        // kijk of er wordt gefilterd op ID, indien ja dan doen we hier niets mee
        // dit is om een rare bug te vermijden die opkomt wanneer je een resultaat terugkrijgt met maar 1 record
        // hij doet dan een filteractie met ID = blabla; deze is niet nodig omdat we de records al hebben en zorgt voor fouten
        if (nieuwFilter.length === 3 && nieuwFilter[0] === 'ID') return;

        if (nieuwFilter.length === 3 && nieuwFilter[0][0] === 'Name' && nieuwFilter[2][0] === 'IdColumn') {
            globalSearch = nieuwFilter[0][2];
            nieuwFilter = [];
        }

        // extyra check om te kijken of de filter input wel klopt
        if (!Array.isArray(nieuwFilter) || nieuwFilter.length === 1) nieuwFilter = [];

        // console.log('FILTER', JSON.stringify({ output: nieuwFilter, input: filterString.filter }));
        // set de waarde van filter (hiermee haal je globalsearch dingen weg)
        (filterParam.orderBy as any).filter = nieuwFilter;

        if (nieuwFilter.length === 0) {
            // Wat hieronder gebeurd slaat natuurlijk nergens op alleen dit is de default manier om op te vragen.
            (filterParam.orderBy as any).filter = ['Parent', '=', ''];
        }

        if (filterString != null && filterString?.filter != null) filterParam.orderBy = '?filter=' + JSON.stringify(filterParam.orderBy);

        const treeClickUrl = url + '/api/v1/tree/click';

        if (transactionOverViewAbortController != null && transactionOverViewAbortController.leafID !== leafID) {
            transactionOverViewAbortController.controller.cancel(
                'Request gecancelled transactieabortcontroller: ' + transactionOverViewAbortController.leafID
            );
        }
        // we geven een abort controller mee voor dit request, dit betekend dat het volgende request naar /api/v1/tree/click gecancelled zal worden indien deze nog actief is
        if (cancelNext) transactionOverViewAbortController = { controller: axios.CancelToken.source(), leafID };
        else {
            // we resetten de abort controller, indien er een token is set deze naar null zodat deze niet meer gecancelled kan worden
            if (transactionOverViewAbortController && transactionOverViewAbortController.controller)
                transactionOverViewAbortController.controller.token = null;
        }

        const config: AxiosRequestConfig<any> = {
            method: 'POST',
            url: treeClickUrl,
            headers: {
                'Content-Type': 'application/json',
                SessionID: session,
                TokenID: token,
            },
            cancelToken: transactionOverViewAbortController?.controller?.token,
            data: filterParam,
        };

        if (globalSearch !== '') {
            config.url = url + '/api/v1/tree/search';
            config.data = { ...filterParam, searchText: `${globalSearch}` };
        }

        try {
            return await axios(config).then(async (response) => {
                // if the response is OK but the TotalTransactions has a value bigger then 0 but the TransactionRecords array is empty, we make another request but then we reset the pageNumber to 1
                if (response.status === 200 && response.data?.TotalTransactions > 0 && response.data?.TransactionRecords.length === 0) {
                    config.data = { ...config.data, pageNumber: 1 };
                    return await axios(config);
                }
                return response;
            });
        } catch (error) {
            APIService.handleErrors(error);
        } finally {
            transactionOverViewAbortController = null;
        }
    },
    getFlowChart: async (transactionTypeID: number, isCompact: boolean, session: string, token: string) => {
        const config: any = {
            headers: {
                'Content-Type': 'application/json',
                SessionID: session,
                TokenID: token,
            },
            method: 'GET',
            params: {
                isCompact,
                transactionTypeID,
            },
            url: url + '/api/v1/calls/getflowchart',
        };

        try {
            const ret = await axios(config);
            return ret;
        } catch (error) {
            APIService.handleErrors(error);
        }
    },
    transaction_expand: async (BranchId: number, session: string, token: string) => {
        const config: AxiosRequestConfig<any> = {
            method: 'GET',
            url: url + '/api/v1/transactions/expand',
            headers: {
                'Content-Type': 'application/json',
                SessionID: session,
                TokenID: token,
            },
            params: {
                transactionID: BranchId,
            },
        };
        try {
            return (await axios(config)) as AxiosResponse<TransactionRecord[]>;
        } catch (error) {
            APIService.handleErrors(error);
        }
    },
    getElementList: async (elementFunction: string, session: string, token: string): Promise<Map<string, string>> => {
        const config: AxiosRequestConfig<any> = {
            url: url + '/api/v1/calls/getelementlist',
            method: 'GET',
            headers: {
                SessionID: session,
                TokenID: token,
            },
            params: {
                function: elementFunction,
            },
        };

        try {
            return (await axios(config)).data;
        } catch (error) {
            APIService.handleErrors(error);
            throw error;
        }
    },
    setTransactionUnread: async (transactionID: number, session: string, token: string) => {
        const config: AxiosRequestConfig<any> = {
            method: 'GET',
            url: url + '/api/v1/transactions/setunread',
            headers: {
                'Content-Type': 'application/json',
                SessionID: session,
                TokenID: token,
            },
            params: {
                transactionID,
            },
        };
        try {
            return await axios(config);
        } catch (error) {
            APIService.handleErrors(error);
        }
    },
    logout: async (session: string, token: string) => {
        const config: AxiosRequestConfig<any> = {
            data: {
                SessionID: session,
                TokenID: token,
            },
            headers: {
                'Content-Type': 'application/json',
                sessionId: session,
                tokenId: token,
            },
            method: 'POST',
            url: url + '/api/v1/login/logout',
        };

        try {
            return await axios(config);
        } catch (error) {
            APIService.handleErrors(error);
        }
    },
    setTransactionReady: async (transactionID: number, session: string, token: string) => {
        const config: AxiosRequestConfig<any> = {
            method: 'GET',
            url: url + '/api/v1/transactions/setready',
            headers: {
                'Content-Type': 'application/json',
                SessionID: session,
                TokenID: token,
            },
            params: {
                transactionID,
            },
        };
        try {
            return await axios(config);
        } catch (error) {
            APIService.handleErrors(error);
            throw error;
        }
    },
    relaticsDocument: async (relaticsDocument: RelaticsDocument): Promise<NewMessageContent> => {
        const config: AxiosRequestConfig<any> = {
            data: relaticsDocument,
            method: 'POST',
            url: url + '/api/v1/calls/relaticsdocument',
        };
        try {
            const result = await axios(config);
            return result.data;
        } catch (error) {
            APIService.handleErrors(error);
            throw error;
        }
    },

    relaticsDocuments: async (application: string): Promise<NewMessageContentTable> => {
        const config: AxiosRequestConfig<any> = {
            method: 'GET',
            params: {
                application,
            },
            url: url + '/api/v1/calls/relaticsdocuments',
        };
        try {
            return (await axios(config)).data;
        } catch (error) {
            APIService.handleErrors(error);
            throw error;
        }
    },
    sharePointAllFields: async (session: string, token: string): Promise<Map<string, string>> => {
        const config: AxiosRequestConfig<any> = {
            headers: {
                SessionID: session,
                TokenID: token,
            },
            method: 'GET',
            url: url + '/api/v1/calls/sharepointallfields',
        };

        try {
            return (await axios(config)).data;
        } catch (error) {
            APIService.handleErrors(error);
            throw error;
        }
    },
    showCompleteTransaction: async (transactionID: number, session: string, token: string, isRootTransaction: boolean) => {
        const config: AxiosRequestConfig<any> = {
            url: url + `/api/v1/transactions/${isRootTransaction ? 'getroottransaction' : 'gettransactionrecord'}`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                SessionID: session,
                TokenID: token,
            },
            params: {
                transactionID,
            },
        };

        try {
            return (await axios(config)) as AxiosResponse<TransactionRecord>;
        } catch (error) {
            APIService.handleErrors(error);
        }
    },
    sharePointDocuments: async (
        library: string,
        view: string,
        query: string,
        session: string,
        token: string
    ): Promise<Map<string, NewMessageContentTable>> => {
        const config: AxiosRequestConfig<any> = {
            headers: {
                SessionID: session,
                TokenID: token,
            },
            method: 'GET',
            params: {
                library,
                query,
                view,
            },
            url: url + '/api/v1/calls/sharepointdocuments',
        };

        try {
            return (await axios(config)).data;
        } catch (error) {
            APIService.handleErrors(error);
            throw error;
        }
    },
    sharePointLibraries: async (session: string, token: string): Promise<string[]> => {
        const config: AxiosRequestConfig<any> = {
            headers: {
                SessionID: session,
                TokenID: token,
            },
            method: 'GET',
            url: url + '/api/v1/calls/sharepointlibraries',
        };

        try {
            return (await axios(config)).data;
        } catch (error) {
            APIService.handleErrors(error);
            throw error;
        }
    },
    sharePointViews: async (library: string, session: string, token: string): Promise<Map<string, string>> => {
        const config: AxiosRequestConfig<any> = {
            headers: {
                SessionID: session,
                TokenID: token,
            },
            method: 'GET',
            params: {
                library,
            },
            url: url + '/api/v1/calls/sharepointviews',
        };

        try {
            return (await axios(config)).data;
        } catch (error) {
            APIService.handleErrors(error);
            throw error;
        }
    },
    saveTransactionInZip: async (messageID: number, sessionId: string, tokenId: string): Promise<string> => {
        const config: AxiosRequestConfig<any> = {
            headers: {
                SessionID: sessionId,
                TokenID: tokenId,
            },
            method: 'GET',
            url: `${url}/api/v1/transactioninzipex?transactionID=${messageID}`,
        };

        try {
            return (await axios(config)).data;
        } catch (error) {
            APIService.handleErrors(error);
            throw error;
        }
    },
    startDownload: async (startDownload: StartDownload): Promise<number> => {
        const config: AxiosRequestConfig<any> = {
            data: startDownload,
            method: 'POST',
            url: url + '/api/v1/file/startdownload',
        };
        try {
            return (await axios(config)).data;
        } catch (error) {
            APIService.handleErrors(error);
            throw error;
        }
    },
    finishDownload: async (DownloadId: string, SessionID: string, TokenID: string): Promise<void> => {
        const config: AxiosRequestConfig<any> = {
            headers: {
                SessionID,
                TokenID,
                'Content-Type': 'application/json; charset=utf-8',
            },
            data: DownloadId,
            method: 'POST',
            url: url + '/api/v1/file/finishdownload',
        };
        try {
            return (await axios(config)).data;
        } catch (error) {
            APIService.handleErrors(error);
            throw error;
        }
    },
    thinkProjectAuth: async (session: string, token: string): Promise<string> => {
        const config: AxiosRequestConfig<any> = {
            headers: {
                SessionID: session,
                TokenID: token,
            },
            method: 'GET',
            url: url + '/api/v1/calls/thinkprojectauth',
        };

        try {
            return (await axios(config)).data;
        } catch (error) {
            APIService.handleErrors(error);
            throw error;
        }
    },
    thinkProjectFields: async (tpHref: string, tpToken: string, session: string, tokenID: string): Promise<Map<string, string>> => {
        const config: AxiosRequestConfig<any> = {
            headers: {
                'Content-Type': 'application/json',
                SessionID: session,
                TokenID: tokenID,
            },
            method: 'GET',
            params: {
                href: tpHref,
                token: tpToken,
            },
            url: `${url}/api/v1/calls/thinkprojectfields`,
        };

        try {
            return (await axios(config)).data;
        } catch (error) {
            APIService.handleErrors(error);
            throw error;
        }
    },

    thinkProjectFilters: async (tpHref: string, tpToken: string, session: string, token: string): Promise<Map<string, string>> => {
        const config: AxiosRequestConfig<any> = {
            headers: {
                'Content-Type': 'application/json',
                SessionID: session,
                TokenID: token,
            },
            method: 'GET',
            params: {
                href: tpHref,
                token: tpToken,
            },
            url: `${url}/api/v1/calls/thinkprojectfilters`,
        };

        try {
            return (await axios(config)).data;
        } catch (error) {
            APIService.handleErrors(error);
            throw error;
        }
    },

    thinkProjectModules: async (tpHref: string, tpToken: string, session: string, token: string): Promise<Map<string, string>> => {
        const config: AxiosRequestConfig<any> = {
            headers: {
                'Content-Type': 'application/json',
                SessionID: session,
                TokenID: token,
            },
            method: 'GET',
            params: {
                href: tpHref,
                token: tpToken,
            },
            url: `${url}/api/v1/calls/thinkprojectmodules`,
        };

        try {
            const response = await axios(config);
            return response.data;
        } catch (error) {
            APIService.handleErrors(error);
            throw error;
        }
    },
    thinkProjectProjects: async (thinkprojectToken: string, session: string, token: string): Promise<Map<string, string>> => {
        const config: AxiosRequestConfig<any> = {
            headers: {
                'Content-Type': 'application/json',
                SessionID: session,
                TokenID: token,
            },
            method: 'GET',
            params: {
                token: thinkprojectToken,
            },
            url: `${url}/api/v1/calls/thinkprojectprojects`,
        };

        try {
            return (await axios(config)).data;
        } catch (error) {
            APIService.handleErrors(error);
            throw error;
        }
    },
    thinkprojectDocuments: async (href: string): Promise<NewMessageContentTable> => {
        const config: AxiosRequestConfig<any> = {
            method: 'GET',
            params: {
                href,
            },
            url: `${url}/api/v1/calls/thinkprojectdocuments`,
        };
        try {
            return (await axios(config)).data;
        } catch (error) {
            APIService.handleErrors(error);
            throw error;
        }
    },
    thinkprojectDownload: async (href: string): Promise<string> => {
        const config: AxiosRequestConfig<any> = {
            method: 'GET',
            params: {
                href,
            },
            url: `${url}/api/v1/calls/thinkprojectdownload`,
        };
        try {
            return (await axios(config)).data;
        } catch (error) {
            APIService.handleErrors(error);
            throw error;
        }
    },
    disconnectOrganisation: async (session: string, token: string, organisation: string): Promise<number> => {
        const config: AxiosRequestConfig<any> = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                SessionID: session,
                TokenID: token,
            },
            params: {
                organisatieSID: organisation /* Hij geeft een naam ipv een SID mee, dit klopt (verkeerde param naam in backend) */,
            },
            url: `${url}/api/v1/calls/ontkoppelorganisatie`,
        };
        try {
            return (await axios(config)).data;
        } catch (error) {
            APIService.handleErrors(error);
            throw error;
        }
    },
};
export default APIService;
