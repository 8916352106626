import React, { lazy, Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { LoadPanel } from 'devextreme-react';
import config from 'devextreme/core/config';

import RootRoutes from './routes/RootRoutes';

// UTILS
import { useScreenSizeClass } from './utils/ViewPorts/mediaQuery';

// CSS
import './App.scss';

// React lazy components imports
const AppVersionProvider = lazy(() => import('./state/hooks/useAppVersion' /* webpackChunkName: "AppVersionProvider" */));
const AuthProvider = lazy(() => import('./state/hooks/useAuth' /* webpackChunkName: "AuthProvider" */));
const InstallPWA = lazy(() => import('./components/InstallPWA/InstallPWA' /* webpackChunkName: "InstallPWA" */));
const RefreshAppPopup = lazy(() => import('./components/RefreshAppPopup/RefreshAppPopup' /* webpackChunkName: "RefreshAppPopup" */));

// Add the DevExtreme licenseKey in the config of DevExtreme
config({ licenseKey: process.env.REACT_APP_DEVEXTREME_LICENSE });

const App: React.FC = () => {
    const screenSizeClass = useScreenSizeClass();

    return (
        <Suspense key='app-suspense' fallback={<LoadPanel key='app-suspense-fallback-loadpanel' />}>
            <AuthProvider key='app-authprovider'>
                <BrowserRouter key='app-browserrouter'>
                    <AppVersionProvider key='app-versionprovider'>
                        <div className={`app ${screenSizeClass}`} key='app-div-wrapper'>
                            <InstallPWA key='app-installpwa' />
                            <RefreshAppPopup key='app-refreshapppopup-component' />
                            <RootRoutes key='app-rootroutes' />
                        </div>
                    </AppVersionProvider>
                </BrowserRouter>
            </AuthProvider>
        </Suspense>
    );
};

export default App;
