import React, { useEffect, useState, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import * as Sentry from '@sentry/react';

// GLOBAL STATE
import { TabsProvider } from '../state/hooks/useTabs';

// UTILS
import { isAndroid } from '../utils/PWA/useAndroidInstallPrompt';
import { isIOS } from '../utils/PWA/useIosInstallPrompt';
import { isIpad } from '../utils/DetectIpad/detectIpad';
import { useScreenSize } from '../utils/ViewPorts/mediaQuery';

// React lazy components imports
const BSRegistrationApp = lazy(() => import('../BSRegistrationApp/BSRegistrationApp' /* webpackChunkName: "BSRegistrationApp" */));
const ChangePassword = lazy(() => import('../pages/Login/ChangePassword' /* webpackChunkName: "ChangePassword" */));
const Code2FARequestEmail = lazy(() => import('../pages/Login/Code2FARequestEmail' /* webpackChunkName: "Code2FARequestEmail" */));
const CodeRequest2FA = lazy(() => import('../pages/Login/CodeRequest2FA' /* webpackChunkName: "CodeRequest2FA" */));
const Home = lazy(() => import('../pages/Home/Home' /* webpackChunkName: "Home" */));
const Login = lazy(() => import('../pages/Login/Login' /* webpackChunkName: "Login" */));
const NotFound = lazy(() => import('../pages/NotFound/NotFound' /* webpackChunkName: "NotFound" */));
const PasswordRequested = lazy(() => import('../pages/Login/PasswordRequested' /* webpackChunkName: "PasswordRequested" */));
const ProtectedRoute = lazy(() => import('./ProtectedRoute' /* webpackChunkName: "ProtectedRoute" */));
const RenderFilePreview = lazy(() => import('../panels/MessageContainer/RenderFilePreview' /* webpackChunkName: "RenderFilePreview" */));
const SplashScreen = lazy(() => import('../components/SplashScreen/SplashScreen' /* webpackChunkName: "SplashScreen" */));
const TemporaryPassword = lazy(() => import('../pages/Login/TemporaryPassword' /* webpackChunkName: "TemporaryPassword" */));

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const RootRoutes: React.FC = () => {
    const [loading, setLoading] = useState(true);
    const screenSize = useScreenSize();

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 3000);
    });

    if (loading) {
        return (
            <SplashScreen
                key='splashscreen'
                // TODO nu is er een moment dat het component wel geladen is, maar de vertaling nog niet
                // de text is daarom even uitgezet
                // text={formatMessage('vs-SplashScreen-Loading')}
                text=''
            />
        );
    }

    return (
        <SentryRoutes key='sentryroutes'>
            <Route
                key='sentryroutes-route-temporarypassword'
                path='/temporary-password'
                element={
                    <ProtectedRoute key='sentryroutes-route-protectedroute-temporarypassword'>
                        <TemporaryPassword key='sentryroutes-route-protectedroute-temporarypassword-page' />
                    </ProtectedRoute>
                }
            />
            <Route
                key='sentryroutes-route-change-password'
                path='/change-password'
                element={
                    <ProtectedRoute key='sentryroutes-route-protectedroute-changepassword'>
                        <ChangePassword key='sentryroutes-route-protectedroute-changepassword-page' />
                    </ProtectedRoute>
                }
            />
            <Route
                key='sentryroutes-route-home'
                path='/home'
                element={
                    <ProtectedRoute key='sentryroutes-route-protectedroute-home'>
                        <TabsProvider key='sentryroutes-route-protectedroute-tabsprovider-home'>
                            {isAndroid(screenSize) || isIpad() || isIOS() ? (
                                <BSRegistrationApp key='sentryroutes-route-protectedroute-tabsprovider-bsregistrationapp-page' />
                            ) : (
                                <Home key='sentryroutes-route-protectedroute-tabsprovider-home-page' />
                            )}
                        </TabsProvider>
                    </ProtectedRoute>
                }
            />
            <Route
                key='sentryroutes-route-code2farequestemail'
                path='/Code2FARequestEmail'
                element={<Code2FARequestEmail key='sentryroutes-route-code2farequestemail-page' />}
            />
            <Route key='sentryroutes-route-loginaspx' path='/Login.aspx' element={<Login key='sentryroutes-route-loginaspx-page' />} />
            <Route key='sentryroutes-route-login' path='/' element={<Login key='sentryroutes-route-login-page' />} />
            <Route
                key='sentryroutes-route-coderequest2fa'
                path='/2FAcode.aspx'
                element={<CodeRequest2FA key='sentryroutes-route-coderequest2fa-page' />}
            />
            <Route
                key='sentryroutes-route-passwordrequested'
                path='/password-requested'
                element={<PasswordRequested key='sentryroutes-route-passwordrequested-page' />}
            />
            <Route
                key='sentryroutes-route-renderfilepreview'
                path='api/v1/file'
                element={
                    <ProtectedRoute key='sentryroutes-route-protectedroute-renderfilepreview'>
                        <RenderFilePreview key='sentryroutes-route-protectedroute-renderfilepreview-page' />
                    </ProtectedRoute>
                }
            />
            <Route
                key='sentryroutes-route-renderfilepreview-download'
                path='download/:fileID/true/true/:fileName`'
                element={
                    <ProtectedRoute key='sentryroutes-route-protectedroute-renderfilepreview-download'>
                        <RenderFilePreview key='sentryroutes-route-protectedroute-renderfilepreview-download-page' />
                    </ProtectedRoute>
                }
            />

            <Route key='sentryroutes-route-notfound' path='*' element={<NotFound key='sentryroutes-route-notfound-page' />} />
        </SentryRoutes>
    );
};

export default RootRoutes;
